import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {ContentCategoriesService} from "@service/content/content-categories.service";

@Component({
  selector: 'app-add-content-category-modal',
  templateUrl: './add-content-category-modal.component.html',
  styleUrls: ['./add-content-category-modal.component.scss']
})
export class AddContentCategoryModalComponent implements OnInit {
  @Input() contentCategory: any;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public contentCategoryForm: UntypedFormGroup;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 500,
    height: 500
  };

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder, private contentCategoryService: ContentCategoriesService) {
  }

  get form() {
    return this.contentCategoryForm.controls;
  }

  get valid() {
    return this.contentCategoryForm.valid
  }

  ngOnInit(): void {
    this.adding$ = this.contentCategoryService.adding$;
    this.initForm();
  }

  public validSubmit() {
    if (this.contentCategoryForm.valid) {
      this.contentCategoryService.addCategory(this.contentCategoryForm.getRawValue());
    }
  }

  public checkNextActionBtn(): void {
    if (this.contentCategoryForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.contentCategoryForm.patchValue({
        icon: image?.originalUrl,
        imageName: image?.title,
        imageType: image?.mimeType,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.icon?.value) {
      return {
        name: this.form?.imageName?.value,
        originalUrl: this.form?.icon?.value,
        mimeType: this.form?.imageType?.value
      }
    } else {
      return null;
    }
  }

  public removeImage(): void {
    this.contentCategoryForm.patchValue({
      icon: null,
      imageName: null,
      imageType: null,
    });
  }

  public checkImageErrors(): string | undefined {
    if (this.formSubmitted && this.form.icon.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.contentCategoryForm = this.formBuilder.group({
      name: [!this.contentCategory || !this.contentCategory?.name ? null : this.contentCategory?.name, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      action: [!this.contentCategory || !this.contentCategory?.action ? null : this.contentCategory?.action, [Validators.required]],
      icon: [!this.contentCategory || !this.contentCategory?.icon ? null : this.contentCategory?.icon, [Validators.required]],
      imageName: [!this.contentCategory || !this.contentCategory?.icon ? null : this.contentCategory?.name],
      imageType: [null],
      id: [this.contentCategory?.id && !this.contentCategory.duplicate ? this.contentCategory?.id : null]
    });
  }

}
