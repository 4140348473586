<ng-container [formGroup]="parentForm">
  <div class="mb-3 single-ng-select">
    <label [class.mb-0]="description" class="font-weight-bold">Visible and automatic translation locales</label>
    <p *ngIf="description" class="card-title-desc mb-2">Select in which languages this content need to be
      translated.</p>
    <ng-select [clearable]="true" [items]="locales" [multiple]="true" [placeholder]="'Italian, French'"
               formControlName="restrictsLocales">
      <ng-template let-item="item" ng-label-tmp>
        <span>{{ item | translate}}</span>
      </ng-template>
      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
        <span class="font-weight-semibold ms-2">{{item | translate}}</span>
      </ng-template>
    </ng-select>
  </div>
  <div *ngIf="parentForm?.controls?.languageToTranslationUrl?.value?.length > 0" class="mb-3">
    <label class="font-weight-bold">Available translations</label>
    <div class="d-block">
      <img (click)="utils.openLink(lang?.translationUrl)"
           *ngFor="let lang of parentForm?.controls?.languageToTranslationUrl?.value" alt="Language"
           class="me-2 pointer" height="16" ngbTooltip="{{lang?.language | translate}} translation" placement="bottom"
           src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
    </div>
  </div>
</ng-container>

