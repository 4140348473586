<div class="row mt-3">
  <div class="col-12">
    <h4 class="card-title">Select the delivery options</h4>
    <p class="card-title-desc">Schedule when to deliver the message.</p>
  </div>
</div>
<div class="mb-4">
  <div class="form-check form-radio-primary mb-3">
    <input [(ngModel)]="deliveryOption" [value]="notificationDeliveryOptionEnum.NOW" checked
           class="form-check-input bigger-radio"
           id="formRadios1" name="formRadios"
           type="radio">
    <label class="form-check-label" for="formRadios1">
      <span class="d-block font-weight-bold">Schedule for now</span>
      <span class="d-block">Send an ad-hoc message once, now</span>
    </label>
  </div>
  <div class="form-check form-radio-primary">
    <input [(ngModel)]="deliveryOption" [value]="notificationDeliveryOptionEnum.FUTURE"
           class="form-check-input bigger-radio" id="formRadios2"
           name="formRadios" type="radio">
    <label class="form-check-label" for="formRadios2">
      <span class="d-block font-weight-bold">Schedule for future</span>
      <span class="d-block">Set up one or more future times to send this message</span>
    </label>
  </div>
</div>
<div *ngIf="deliveryOption === notificationDeliveryOptionEnum.FUTURE">
  <form (ngSubmit)="validateForm()" [autocomplete]="'off'" [formGroup]="notificationDeliveryForm" autocomplete="off">
    <div class="row">
      <div class="col-md-9">
        <div class="mb-3">
          <label class="font-weight-bold" for="startsAt">Notification send date</label>
          <div class="input-group clockpicker">
            <input #startsAt="ngbDatepicker" (click)="startsAt.toggle()"
                   [ngClass]="{'is-invalid': form.startsAt.errors}"
                   class="form-control"
                   formControlName="startsAt"
                   id="startsAt" ngbDatepicker placeholder="yyyy-mm-dd">
            <div (click)="startsAt.toggle()" class="input-group-append">
              <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
            </div>
            <div *ngIf="form.startsAt.errors" class="invalid-feedback">
              <span *ngIf="form.startsAt.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label class="font-weight-bold" for="startsAtHour">Notification send hour (24H Format)</label>
          <input [ngClass]="{'is-invalid': form.startsAtHour.errors}" class="form-control"
                 formControlName="startsAtHour" id="startsAtHour"
                 max="23"
                 min="0"
                 placeholder="0-24 H" type="number">
          <div *ngIf="form.startsAtHour.errors" class="invalid-feedback">
            <span *ngIf="form.startsAtHour.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form.startsAtHour.errors.max">This max value for hour is 24.</span>
            <span *ngIf="form.startsAtHour.errors.min">This min value for hour is 1.</span>
          </div>
        </div>
      </div>
    </div>
    <ul *ngIf="!massiveNotification" class="list-inline wizard mb-0">
      <li class="previous list-inline-item">
        <button awPreviousStep class="btn btn-outline-light">{{'BACK' | translate}}</button>
      </li>
      <li class="next list-inline-item float-end">
        <button awNextStep class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
      </li>
    </ul>
  </form>
</div>
<ul *ngIf="!massiveNotification && deliveryOption === notificationDeliveryOptionEnum.NOW"
    class="list-inline wizard mb-0">
  <li class="previous list-inline-item">
    <button (click)="goBackStep()" awPreviousStep class="btn btn-outline-light">{{'BACK' | translate}}</button>
  </li>
  <li class="next list-inline-item float-end">
    <button (click)="validateForm()" awNextStep class="btn btn-primary">{{'CONTINUE' | translate}}</button>
  </li>
</ul>
<div *ngIf="massiveNotification">
  <ul class="list-inline wizard mb-0">
    <li class="previous list-inline-item">
      <button (click)="goBackStep()" class="btn btn-outline-light">{{'BACK' | translate}}</button>
    </li>
    <li class="next list-inline-item float-end">
      <button (click)="validateForm()" class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
    </li>
  </ul>
</div>
