import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventEnum} from "@enum/event/event.enum";
import {Subscription} from "rxjs";
import {EventService} from "@service/common/event.service";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-qr-detail-modal',
  templateUrl: './qr-detail-modal.component.html',
  styleUrls: ['./qr-detail-modal.component.scss']
})
export class QrDetailModalComponent implements OnInit {
  @Input() qrCode: any;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService, private utils: UtilsService) {
  }

  ngOnInit(): void {
    this._closeModal();
  }

  public setQrCode(qrCode: any): void {
    this.qrCode = qrCode;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  public downloadQrCode(): void {
    this.utils.downloadQrCode(this.qrCode);
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_QR_DETAIL_MODAL, (reason: string | undefined) => {
      this.modalRef?.dismiss(reason);
    });
  }

}
