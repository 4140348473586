<div (click)="detail()" [class.active]="stats === true" class="card mini-stats-wid">
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="mr-3">
        <p *ngIf="title" class="text-muted font-weight-bold mb-2">{{title | translate}}</p>
        <h5 *ngIf="description" class="mb-0">{{description | translate}}</h5>
        <h5 *ngIf="number" class="mb-0">{{number}} <span *ngIf="unitOfMeasure"
                                                         class="stat-unit">{{unitOfMeasure | translate}}</span></h5>
        <h5 *ngIf="number === 0" class="mb-0">0 <span *ngIf="unitOfMeasure"
                                                      class="stat-unit">{{unitOfMeasure | translate}}</span></h5>
      </div>
      <div *ngIf="icon || image" class="avatar-sm ms-auto">
        <div class="avatar-title bg-light rounded-circle text-primary font-size-20">
          <i *ngIf="icon" class="{{icon}}"></i>
          <img *ngIf="image" [src]="image" alt="" class="img-thumbnail rounded-circle">
        </div>
      </div>
    </div>
  </div>
</div>
