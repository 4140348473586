import {Directive, Input} from '@angular/core';
import {EventService} from "@service/common/event.service";

@Directive({
  selector: '[closeModal]',
  host: {
    '(click)': 'closeModalEvent()'
  }
})
export class CloseModalDirective {
  @Input() closeModal: string;

  constructor(private eventService: EventService) {
  }

  public closeModalEvent() {
    this.eventService.broadcast(this.closeModal, null)
  }
}
