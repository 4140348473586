<div class="mb-3 row">
  <div class="col-md-4">
    <label class="col-form-label date-label">Specific date</label>
  </div>
  <div class="col-md-8">
    <app-datepicker (dateSelected)="setOnDate($event)" [name]="name + 'On'"
                    [selectedDate]="selectedOnDate"></app-datepicker>
  </div>
</div>
<div class="mb-3 row">
  <div class="col-md-4">
    <label class="col-form-label date-label">After</label>
  </div>
  <div class="col-md-8">
    <app-datepicker (dateSelected)="setFromDate($event)" [name]="name + 'From'"
                    [selectedDate]="selectedFromDate"></app-datepicker>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
    <label class="col-form-label date-label">Before</label>
  </div>
  <div class="col-md-8">
    <app-datepicker (dateSelected)="setToDate($event)" [name]="name + 'To'"
                    [selectedDate]="selectedToDate"></app-datepicker>
  </div>
</div>
