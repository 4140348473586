import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {CompaniesTeamsService} from "@service/companies/companies-teams.service";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {NotificationsService} from "@service/notifications/notifications.service";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";

@Component({
  selector: 'app-team-list-page',
  templateUrl: './team-list-page.component.html',
  styleUrls: ['./team-list-page.component.scss']
})
export class TeamListPageComponent implements OnInit {
  @ViewChild('deleteModal') private deleteModal: any
  @ViewChild('companyTeamsModal') private companyTeamsModal: any
  @ViewChild('editTeam') private editTeam: any
  @ViewChild('sourceDetail') private sourceDetailModal: any
  @Input() filters?: any;
  public teamLeaderboardList$: Observable<any[]>;
  public total$: Observable<number>;
  public deleteModalType = DeleteModalTypeEnum;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public notificationModalType = NotificationTypeEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPANY_TEAMS;

  constructor(public companiesTeamsService: CompaniesTeamsService, private cdr: ChangeDetectorRef,
              public utils: UtilsService, private toastService: ToastService, private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.teamLeaderboardList$ = this.companiesTeamsService.teams$;
    this.total$ = this.companiesTeamsService.totalRecords$;
    this.loading$ = this.companiesTeamsService.loading$;
    this.sort({column: "id", direction: "asc"});
    this.setVisibleColumns();
    this.companiesTeamsService.companyId = this.filters?.contextDetailsId;
    this.companiesTeamsService.initSearchTeamsListener();
    this.companiesTeamsService._search$.next();
    this.cdr.detectChanges();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.companiesTeamsService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.companiesTeamsService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.companiesTeamsService.page - 1) * this.companiesTeamsService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.companiesTeamsService.sortColumn = column;
    this.companiesTeamsService.sortDirection = direction;
  }

  async createTeam(event?: any) {
    return await this.companyTeamsModal.open({id: this.filters?.contextDetailsId});
  }

  async editTeamModal(team?: any) {
    if (team) {
      return await this.editTeam.open(team);
    }
  }

  async deleteTeam(team?: any) {
    const title = 'DELETE_TEAM';
    const description = 'DELETE_TEAM_DESC';
    return await this.deleteModal.open({team, companyId: this.filters?.contextDetailsId}, title, description)
  }


  async showUsers(team: any) {
    this.companiesTeamsService.extractTeamMembers(this.filters?.contextDetailsId, team?.teamId).subscribe(async (users: any) => {
      if (users?.data?.length > 0) {
        return await this.sourceDetailModal.open(users?.data, false, true)
      } else {
        this.toastService.show('There are no members on this team', {classname: 'bg-danger text-light'});
      }
    })
  }

  async sendUserNotificationModal(team: any) {
    this.companiesTeamsService.extractTeamMembers(this.filters?.contextDetailsId, team?.teamId).subscribe(async (users: any) => {
      if (users?.data?.length > 0) {
        const ids = users?.data?.map((user) => {return user?.userId});
        this.notificationService.sendNotification(ids);
      } else {
        this.toastService.show('There are no members on this team', {classname: 'bg-danger text-light'});
      }
    })
  }

  public extractUsersTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Show all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }

  public extractNotificationTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Send push notification to all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }


  ngOnDestroy() {
    this.companiesTeamsService.removeSearchTeamsListener();
  }

}
