import {ChangeDetectorRef, Component, Input, NgZone, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription, takeUntil} from "rxjs";
import {FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {EventEnum} from "@enum/event/event.enum";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CompetitionsService} from "@service/competitions/competitions.service";
import {SustainabilityPlacesApiService} from "@service/sustainability/places/sustainability-places-api.service";
import {SustainabilityPartnersApiService} from "@service/sustainability/partners/sustainability-partners-api.service";
import {SustainabilityBenefitsApiService} from "@service/sustainability/benefits/sustainability-benefits-api.service";
import {FormsService} from "@service/common/forms.service";
import {TranslateService} from "@ngx-translate/core";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";

@Component({
  selector: 'app-add-competition-modal',
  templateUrl: './add-competition-modal.component.html',
  styleUrls: ['./add-competition-modal.component.scss']
})
export class AddCompetitionModalComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  @Input() competition: any;
  public duplicateCompetition: any = undefined;
  public duplicatePrizes: boolean = false;
  public Editor = ClassicEditor
  public showAreYouSure: boolean = false;
  public creatingCompetition$: Observable<boolean>;
  public formSubmitted: boolean;
  public competitionForm: UntypedFormGroup;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public languages = this.utils.languages;
  public prizePreconditions = [];
  public teamScoreStrategies = ['AVG', 'SUM'];
  public prizeRestriction = ['none', 'company'];
  public copyPrizesFrom = ['none', 'competition'];
  public optionalJoinCondition = ['YES', 'NO'];
  public competitionType = ['INDIVIDUAL','INDIVIDUAL_AND_TEAMS','TEAMS'];
  public types = ['COMPETITIVE','COOPERATIVE'];
  public fitnessMetric = [
    'ALL',
    'CREDITS',
    'VIRTUOSITY',
    'STEPS',
    'SPORT_MINUTES',
    'CYCLING_MINUTES',
    'CYCLING_METERS',
    'RUNNING_MINUTES',
    'RUNNING_METERS'
  ];
	public configurableFitnessMetrics = [
		{
			label: this.translate.instant('TotalStepsCount'),
			value: 'TotalStepsCount'
		},
		{
			label: this.translate.instant('TotalSleepMinutes'),
			value: 'TotalSleepMinutes'
		},
		{
			label: this.translate.instant('TotalMeditationMinutes'),
			value: 'TotalMeditationMinutes'
		},
		{
			label: this.translate.instant('TotalSportMinutes'),
			value: 'TotalSportMinutes'
		},
		{
			label: this.translate.instant('TotalYogaMinutes'),
			value: 'TotalYogaMinutes'
		}
	];
  public places = [];
  public partners = [];
  public benefits = [];
  public selectedPrizeRestriction = 'none';
  public selectedCopyPrizeRestriction = 'none';
  public selectedCopyPrizeCompetition = undefined;
  public createListenerSubmitted = false;
  public companyTypes = undefined;
  public companyRestriction = undefined;
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private duplicatePrizesSubscription: Subscription;
  public activeId: number = 1;
  public prizes: any = undefined;
  public selectedPrizes: any[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
              public datepipe: DatePipe, public utils: UtilsService, private cdr: ChangeDetectorRef,
              private _formService: FormsService, private translate: TranslateService,
              private toastService: ToastService, private _benefitsApiService: SustainabilityBenefitsApiService,
              private _partnersApiService: SustainabilityPartnersApiService, private _placesApiService: SustainabilityPlacesApiService,
              public formBuilder: UntypedFormBuilder, private competitionService: CompetitionsService) {
  }

  get form() {
    return this.competitionForm.controls;
  }

  public get cardImage(): string | undefined {
    if (this.competition?.logoImageUrl) {
      return this.competition?.logoImageUrl;
    } else if (this.form?.logoImageUrl?.value) {
      return this.form?.logoImageUrl?.value;
    } else {
      return undefined;
    }
  }

  public checkSelectedCompanies(): any {
    if (this.form?.company?.value) {
      return [this.form?.company?.value]
    } else {
      return undefined;
    }
  }

  public selectCompany(event: any): void {
    if (event) {
      this.competitionForm.patchValue({
        company: event?.length > 0 ? event[0] : null,
      });
    } else {
      this.competitionForm.patchValue({
        company: null,
      });
    }
  }


  get valid() {
    return this.competitionForm.valid;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingCompetition$ = this.competitionService?.creating$;
  }

  public setCompetition(company: any): void {
    this.competition = company;
    this.patchFormWithEditingData();
  }

  private _loadBenefits(): void {
    this._benefitsApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.benefits = result?.data?.filter((benefit) => {return !benefit.multiplier})
    });
  }

  private _loadPartners(): void {
    this._partnersApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.partners = result?.data?.map((partner) => { partner.id = Number(partner.id); return partner});
    });
  }

  private _loadPlaces(): void {
    this._placesApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.places = result?.data;
    });
  }

  private _checkCompetitionType(): void {
    let competition = this.competitionForm.getRawValue();
    if (competition?.competitionType === "COMPETITIVE") {
      this._clearValuesAndRemoveValidators(['objectives']);
      this.competitionForm.removeControl('objectives')
    }
  }

  public validSubmit() {
    this._checkCompetitionType();
    if (this.competitionForm.valid) {
      this.updateDates();
      this.competitionService.initCreateListener();
      let formData = this.competitionForm.getRawValue();
      if (this.competition?.id) {
        formData.id = this.competition?.id;
      }
      formData.image = formData?.logoImageUrl;
      if (formData.company) {
        formData.companyData = formData.company;
        formData.company = formData.companyData?.id;
      }
      if (this.duplicateCompetition) {
        formData.duplicateCompetition = this.duplicateCompetition;
      }
      if (this.selectedPrizes?.length > 0) {
        formData.duplicatePrizes = this.selectedPrizes;
      }
      if (!formData.rules) {
        formData.rules = ' ';
      }
			if (formData.allowsOptionalJoin === 'YES') {
				formData.allowsOptionalJoin = true;
			} else if (formData.allowsOptionalJoin === 'NO') {
				formData.allowsOptionalJoin = false;
			}
	    this.competitionService.createEditCompetition(JSON.parse(JSON.stringify(formData)));
    } else {
      this._formService.findInvalidControlsRecursive(this.competitionForm);
    }
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      this.initForm();
      this._loadBenefits();
      this._loadPartners();
      this._loadPlaces();
      if (!data) {
        this.competition = undefined;
      } else if (data?.id) {
        this.setCompetition(data);
      } else if (data?.specificCompetition) {
        this.competitionForm.patchValue({['company']: data?.specificCompetition});
        this.selectedPrizeRestriction = 'company';
      }
    })
  }

  public setCardImage(image: any): void {
    this.competitionForm.patchValue({
      logoImageUrl: image ? image?.originalUrl : undefined,
      logoImageName: image ? image?.name : undefined,
    });
  }

  public checkCardImageError(): string | undefined {
    if (this.formSubmitted && this.form.logoImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedCardImage(): object | undefined {
    if (this.competition?.logoImageUrl && this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.competition?.logoImageUrl,
        name: [this.competition?.competitionName?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else if (this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.form?.logoImageUrl?.value,
        name: [this.competition?.competitionName?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  private initForm() {
    this.competitionForm = this.formBuilder.group({
      competitionName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      description: [null, [Validators.required]],
      rules: [' '],
      active: [false],
      allowsPowerbarLevelChange: [false],
      duplicatePrizes: [false],
      leaderboardByPowerLevel: [false],
      allowsOptionalJoin: [null, [Validators.required]],
      allowsTeamChange: [false],
      teamScoreStrategy: [null, [Validators.required]],
      rankingType: ['INDIVIDUAL', [Validators.required]],
      competitionType: ['BASIC', [Validators.required]],
      partnerId: [!this.competition || !this.competition?.partner?.id ? null : this.competition?.partner?.id],
      benefitIds: [!this.competition || !this.competition?.benefitIds ? null : this.competition?.benefitIds],
      placeId: [!this.competition || !this.competition?.place?.id ? null : this.competition?.place?.id],
      metric: [null, [Validators.required]],
      company: [null],
      startsAtHour: [null],
      objectiveDescription: [null],
      objectives: this.formBuilder.array([this.addObjective()]),
      endsAtHour: [null],
      visibleFromHour: [null],
      visibleUntilHour: [null],
      competition: [null],
      acceptsResultsUntilHour: [null],
      logoImageUrl: [null, [Validators.required]],
      visibleFromDay: [null, [Validators.required]],
      visibleUntilDay: [null, [Validators.required]],
      endsOn: [null, [Validators.required]],
      startsOn: [null, [Validators.required]],
      acceptsResultsUntilDay: [null, [Validators.required]],
      restrictsLocales: [null],
      languageToTranslationUrl: [null],
	    configurableFitnessMetrics: [[]],
      allowsChallengeCredit: [false]
    });
  }

  addObjective(objective?: any) {
    return this.formBuilder.group({
      title: [!objective ? null : objective.title, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      description: [!objective ? null : objective.description, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      target: [!objective ? null : objective.target, [Validators.required]],
      id: [!objective ? null : !this.competition.duplicate && objective.id ? objective.id : null],
    });
  }

  get objectives(){
    return this.competitionForm.get("objectives") as UntypedFormArray;
  }

  addObjectiveToForm() {
    this.objectives.push(this.addObjective());
  }

  removeObjectiveFromForm(index) {
    this.objectives.removeAt(index);
  }

  private updateDates(): void {
    const visibleFromDay = this.form?.visibleFromDay?.value;
    const visibleUntilDay = this.form?.visibleUntilDay?.value;
    const startsOn = this.form?.startsOn?.value;
    const endsOn = this.form?.endsOn?.value;
    const acceptsResultsUntilDay = this.form?.acceptsResultsUntilDay?.value;
    if (visibleFromDay?.year) {
      this.updateDate(visibleFromDay, 'visibleFromDay');
    }
    if (visibleUntilDay?.year) {
      this.updateDate(visibleUntilDay, 'visibleUntilDay');
    }
    if (startsOn?.year) {
      this.updateDate(startsOn, 'startsOn');
    }
    if (endsOn?.year) {
      this.updateDate(endsOn, 'endsOn');
    }
    if (acceptsResultsUntilDay?.year) {
      this.updateDate(acceptsResultsUntilDay, 'acceptsResultsUntilDay');
    }
  }

  public checkRestrictionValidators(event?: any, group?: any): any {
    switch (this.form.competitionType.value) {
      case 'COMPETITIVE':
        this._clearValuesAndRemoveValidators(['objectiveDescription', 'rules']);
        this.addRequiredValidators(['rules']);
        break;
      case 'COOPERATIVE':
        this._clearValuesAndRemoveValidators(['objectiveDescription', 'rules']);
        this.addRequiredAndMaxLengthValidators(['objectiveDescription']);
        break;
    }
  }

  private _clearValuesAndRemoveValidators(list: string[]) {
    list?.map((el: string) => {
      this.competitionForm.patchValue({[el]: null});
    });
    this.removeRequiredValidators(list);
  }

  public removeRequiredValidators(list: string[]) {
    list?.map((el: string) => {
      this.form[el]?.clearValidators();
      this.form[el]?.updateValueAndValidity();
    });
  }
	
	public addRequiredValidators(list: string[]) {
		list?.map((el: string) => {
			this.form[el]?.addValidators([Validators.required]);
			this.form[el]?.updateValueAndValidity();
		});
	}
	
	public addRequiredAndMaxLengthValidators(list: string[]) {
    list?.map((el: string) => {
      this.form[el]?.addValidators([Validators.required, Validators.maxLength(255)]);
      this.form[el]?.updateValueAndValidity();
    });
  }


  public updateDate(date: any, field: string): void {
    this.competitionForm.patchValue({[field]: this.utils.createDateFromDateTimePicker(date)});
    switch (field) {
      case 'startsOn':
        this.competitionForm.patchValue({['startsAtHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
      case 'endsOn':
        this.competitionForm.patchValue({['endsAtHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
      case 'visibleFromDay':
        this.competitionForm.patchValue({['visibleFromHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
      case 'visibleUntilDay':
        this.competitionForm.patchValue({['visibleUntilHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
      case 'acceptsResultsUntilDay':
        this.competitionForm.patchValue({['acceptsResultsUntilHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
    }
  }

  public checkSelectedCompetition(): any {
    if (this.form?.competition?.value) {
      return [this.form?.competition?.value]
    } else {
      return undefined;
    }
  }

  public selectCompetition(event: any): void {
    if (event) {
      this.competitionForm.patchValue({
        competition: event?.length > 0 ? event[0] : null,
      });
    } else {
      this.competitionForm.patchValue({
        competition: null,
      });
    }
    this.loadPrizeForCompetition(null);
  }


  public setSelectedPrizes(event: any): void {
    this.selectedPrizes = event;
  }

  private patchFormWithEditingData() {
    this.competitionForm.patchValue({
      competitionName: this.extractTitle(),
      description: this.competition?.description,
      rules: this.competition?.rules,
      objectiveDescription: this.competition?.objectiveDescription,
      active: this.competition?.duplicate ? false : this.competition?.active ,
      company: this.competition?.company,
      allowsPowerbarLevelChange: this.competition?.allowsPowerbarLevelChange,
      leaderboardByPowerLevel: this.competition?.leaderboardByPowerLevel,
      allowsTeamChange: this.competition?.allowsTeamChange,
      allowsOptionalJoin: this.competition.duplicate ? null : this.competition?.allowsOptionalJoin === true ? 'YES' : 'NO',
      teamScoreStrategy: this.competition?.teamScoreStrategy,
      allowsChallengeCredit: this.competition?.allowsChallengeCredit,
      metric: this.competition?.metric?.actualMetric,
      rankingType: this.competition?.type,
	    configurableFitnessMetrics: !this.competition || !this.competition?.configurableFitnessMetrics ? JSON.parse(JSON.stringify(this.configurableFitnessMetrics))?.map((metric) => metric?.value) : this.competition?.configurableFitnessMetrics,
      partnerId: !this.competition || !this.competition?.partner?.id ? null : this.competition?.partner?.id,
      benefitIds: !this.competition || !this.competition?.benefits ? null : this.competition?.benefits?.map((benefit) => benefit?.id),
      placeId: !this.competition || !this.competition?.place?.id ? null : this.competition?.place?.id,
      competitionType: this.competition?.competitionType,
      restrictsLocales: this.competition?.restrictsLocales ? this.competition?.restrictsLocales : null,
      logoImageUrl: this.competition?.image,
      competition: this.competition?.duplicate ? JSON.parse(JSON.stringify(this.competition)) : undefined,
      logoImageName: this.competition?.logoImageName ? this.competition?.logoImageName : [this.competition?.competitionName?.replace(/\s/g, "_"), 'logo_image'].join('_'),
      visibleFromDay: this.utils.splitDateStringForDateTimePicker(this.competition?.visibleFromDay, this.competition?.visibleFromHour),
      visibleUntilDay:  this.utils.splitDateStringForDateTimePicker(this.competition?.visibleUntilDay, this.competition?.visibleUntilHour),
      endsOn: this.utils.splitDateStringForDateTimePicker(this.competition?.endsOn, this.competition?.endsAtHour),
      startsOn: this.utils.splitDateStringForDateTimePicker(this.competition?.startsOn, this.competition?.startsAtHour),
      acceptsResultsUntilDay: this.utils.splitDateStringForDateTimePicker(this.competition?.acceptsResultsUntilDay, this.competition?.acceptsResultsUntilHour),
      languageToTranslationUrl: this.competition?.languageToTranslationUrl ? this.competition?.languageToTranslationUrl : undefined,
    });
    this._patchCompetitionObjectives();
    if (this.competition?.company) {
      this.selectedPrizeRestriction = 'company';
    }
    if (this.competition.duplicate) {
      this.selectedCopyPrizeRestriction = 'competition';
      this.duplicateCompetition = JSON.parse(JSON.stringify(this.competition));
      this.competition = undefined;
      this.loadPrizeForCompetition();
    }
		if (this.competition && !this.duplicateCompetition) {
			this.competitionForm.get('allowsOptionalJoin').disable();
		}
  }

  private _patchCompetitionObjectives() {
    if (this.competition?.competitionType === 'COOPERATIVE' && this.competition?.objectives?.length > 0) {
      this.objectives.reset()
      this.objectives.clear()
      this.competition?.objectives?.sort((a, b) => a.id - b.id)?.map((objective) => {
        this.objectives.push(this.addObjective(objective));
        return objective;
      });
    }
  }

  private extractTitle(): string | null {
    if (this.competition.duplicate && this.competition?.competitionName) {
      return [this.competition?.competitionName, ' Duplicate'].join(' -');
    } else if (!this.competition.duplicate && this.competition?.competitionName) {
      return this.competition?.competitionName;
    } else {
      return null;
    }
  }


  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_COMPETITION, (reason: string | undefined) => {
      this.competitionService?.removeCreateCompetitionSubscription();
      this.initForm();
      this.resetAllVariables();
      this.modalRef?.dismiss(reason);
    });
  }


  public loadPrizeForCompetition(event?: any): void {
    if (this.form?.competition?.value) {
      const competition = this.form?.competition?.value;
      this.competitionService.getPrizes(competition?.id).subscribe((result: any) => {
        this.prizes = result?.data ? result?.data : [];
      });
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    switch (type) {
      case 'BASE':
        return !!(this.form?.competitionName?.errors || this.form?.description?.errors || this.form?.rules?.errors || this.form?.logoImageUrl?.errors);
        break;
      case 'RESTRICTIONS':
        return !!(this.form?.rankingType?.errors || this.form?.teamScoreStrategy?.errors || this.form?.metric?.errors || this.form?.visibleFromDay?.errors
          || this.form?.visibleUntilDay?.errors || this.form?.startsOn?.errors || this.form?.endsOn?.errors || this.form?.acceptsResultsUntilDay?.errors
	        || this.form?.allowsOptionalJoin?.errors
        );
        break;
      case 'OBJECTIVES':
        return !!(this.form?.objectives?.errors || this.form?.partnerId?.errors || this.form?.placeId?.errors || this.form?.benefitIds?.errors);
        break;
      default:
        return false;
    }
  }

  public checkNextActionBtn(): void {
    if (this.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true
      if (this.checkAlert('BASE')) {
        this.activeId = 1;
        this.cdr.detectChanges();
      } else if (this.checkAlert('RESTRICTIONS')) {
        this.activeId = 2;
        this.cdr.detectChanges();
      }
    }
  }

  public checkAllowsChallengeCredit(event: any) {
    switch (this.form.metric.value) {
      case 'CREDITS':
        if (!this.competition?.id && this.form.allowsChallengeCredit.value === null) {
          this.competitionForm.patchValue({['allowsChallengeCredit']: true});
        }
        break;
      default:
        break;
    }
  }

  private resetAllVariables() {
    this.competition = undefined;
    this.formSubmitted = false;
    this.showAreYouSure = false;
    this.competition = undefined;
    this.activeId = 1;
    this.duplicateCompetition = undefined;
    this.selectedCopyPrizeCompetition = undefined;
    this.selectedPrizeRestriction = 'none';
    this.prizes = undefined;
    this.selectedPrizes = [];
    this.selectedCopyPrizeRestriction = 'none';
    this.createListenerSubmitted = false;
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
    if (this.duplicatePrizesSubscription) {
      this.duplicatePrizesSubscription.unsubscribe();
    }
    this.resetAllVariables();
  }

}
