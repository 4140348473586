<div class="input-group me-2">
  <label *ngIf="!hide" class="input-group-text">{{title | translate}}</label>
  <select (change)="getNumberValue($event)" [(ngModel)]="availableNumberOperator" class="form-select form-select-sm2"
          name="fitnessKit">
    <option *ngFor="let operator of operators" [selected]="operator.selected"
            [value]="operator.value">{{operator.label | translate}}</option>
  </select>
  <input (ngModelChange)="getNumberValue($event)" [(ngModel)]="availableNumber" class="form-control" id="credits"
         type="number">
  <label (click)="clearValue()" [class.datepicker-btn--light]="hide" class="input-group-text pointer"><i
    class="dripicons-cross"></i></label>
</div>
