<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>Ricalcolo punteggi</span>
    </h5>
    <button [disabled]="(working$ | async)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_ACTIVATE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">Ricalcola i punteggi per questa competizione</p>
    <div class="row modal-content-max">
      <div class="col-12 text-center">
        <div *ngIf="detail?.loading">
          <div class="spinner-border text-secondary m-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <ng-container *ngIf="detail?.users?.length > 0">
          <table class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
            <thead>
            <tr>
              <th>
                <span class="me-3">{{'USER_ID' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'EMAIL' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Pre ricalcolo' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Post ricalcolo' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Pre ricalcolo ( Provisionali )' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Post ricalcolo ( Provisionali )' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Pre ricalcolo ( Consolidati )' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'Post ricalcolo ( Consolidati )' | translate}}</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of detail?.users; let i=index;" class="pointer">
              <td align="center">
                <span class="badge badge-pill badge-soft-primary">{{data?.user?.id}}</span>
              </td>
              <td>
                <span *ngIf="data?.user?.email">{{data?.user?.email}}</span>
                <app-missing-label *ngIf="!data?.user?.email"></app-missing-label>
              </td>
              <td>
                <span>{{data?.from}}</span>
              </td>
              <td>
                <span>{{data?.to}}</span>
              </td>
              <td>
                <span>{{data?.fromProvisional}}</span>
              </td>
              <td>
                <span>{{data?.toProvisional}}</span>
              </td>
              <td>
                <span>{{data?.fromConsolidate}}</span>
              </td>
              <td>
                <span>{{data?.toConsolidate}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <div *ngIf="detail?.noResults">
          <ngb-alert [dismissible]="false" type="success">
            Non ci sono risultati
          </ngb-alert>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-danger">Conferma nuovi punteggi</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">Sei sicuro di voler confermare i nuovi punteggi? <span class="text-danger font-weight-bold">L'operazione è irreversibile</span></span>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                class="btn btn-outline-light ">{{'Annulla' | translate}}
        </button>
        <button (click)="persist()" *ngIf="showAreYouSure" [disabled]="(working$ | async)" class="btn btn-danger ms-2"
                type="submit">
          <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="working$ | async">
            Caricamento in corso...
          </span>
          <span [hidden]="(working$ | async)">
            {{'Confermo' | translate}}
          </span>
        </button>
      </li>
    </ul>
  </div>
</ng-template>
