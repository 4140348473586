import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, switchMap, tap} from "rxjs/operators";
import {ChallengesApiService} from "@service/challenges/challenges-api.service";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ChallengesService {
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.challengesTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.challengesTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchChallengesSubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private challengesApiService: ChallengesApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService, private router: Router) {
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _challengeList$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get challengeList$() {
    return this._challengeList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get metricLongDescription() {
    return this._currentTableState.metricLongDescription;
  }

  public set metricLongDescription(metricLongDescription: string) {
    this._setValue({metricLongDescription});
  }

  public get challengeStatus() {
    return this._currentTableState.challengeStatus;
  }

  public set challengeStatus(challengeStatus: string) {
    this._setValue({challengeStatus});
  }

  public get type() {
    return this._currentTableState.type;
  }

  public set type(type: string) {
    this._setValue({type});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public get status() {
    return this._currentTableState.status;
  }

  public set status(status: string[]) {
    this._setValue({status});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  get isPublic() {
    return this._currentTableState.isPublic;
  }

  set isPublic(isPublic: boolean | undefined | null) {
    this._setValue({isPublic});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get startsAt() {
    return this._currentTableState.startsAt;
  }

  set startsAt(startsAt: string[]) {
    this._setValue({startsAt});
  }

  get endsAt() {
    return this._currentTableState.endsAt;
  }

  set endsAt(endsAt: string[]) {
    this._setValue({endsAt});
  }

  get acceptsResultsUntil() {
    return this._currentTableState.acceptsResultsUntil;
  }

  set acceptsResultsUntil(acceptsResultsUntil: string[]) {
    this._setValue({acceptsResultsUntil});
  }

  get ante() {
    return this._currentTableState.ante;
  }

  set ante(ante: string[]) {
    this._setValue({ante});
  }

  get currentPool() {
    return this._currentTableState.currentPool;
  }

  set currentPool(currentPool: string[]) {
    this._setValue({currentPool});
  }

  get participantsCount() {
    return this._currentTableState.participantsCount;
  }

  set participantsCount(participantsCount: string[]) {
    this._setValue({participantsCount});
  }

  get maxParticipants() {
    return this._currentTableState.maxParticipants;
  }

  set maxParticipants(maxParticipants: string[]) {
    this._setValue({maxParticipants});
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public removeSearchChallengesSubscribe(): void {
    this.searchChallengesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchChallengesListener(): void {
    this.searchChallengesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.challengesApiService.getChallengeList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._challengeList$.next(result?.data);
      this._totalRecords$.next(result?.size);
    });
  }

  public challengeDetail(contestId: any): any {
    const params = {
      contestId: contestId,
      page: 1,
      size: 1000
    }
    return this.challengesApiService.getChallengeDetail(params);
  }

  public initForUserSearchChallengesListener(): void {
    this.searchChallengesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.challengesApiService.getUserChallengeList(this._extractSearchParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._challengeList$.next(result?.data);
      this._totalRecords$.next(result?.size);
    });
  }

  public clearFilters(): void {
    if (!this.router.url.includes('/users/detail')) {
      this.userId = undefined;
    }
    this.searchTerm = undefined;
    this.type = undefined;
    this.isPublic = undefined;
    this.ante = undefined;
    this.currentPool = undefined;
    this.participantsCount = undefined;
    this.maxParticipants = undefined;
    this.metricLongDescription = undefined;
    this.challengeStatus = undefined;
    this.createdAt = undefined;
    this.startsAt = undefined;
    this.endsAt = undefined;
    this.acceptsResultsUntil = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.challengesApiService.getChallengeList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private extractStatus(): any {
    if (this.status && !this.challengeStatus) {
      return this.status;
    } else if (this.status && this.challengeStatus) {
      return [this.challengeStatus];
    } else if (!this.status && this.challengeStatus && this.challengeStatus !== undefined && this.challengeStatus !== null) {
      return [this.challengeStatus];
    } else {
      return undefined;
    }
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    if (!this.status)
      return {
        filters: {
          query: this.searchTerm ? [this.searchTerm] : undefined,
          type: (this.type !== undefined && this.type !== null && this.type !== 'undefined') ? [this.type] : undefined,
          isPublic: (this.isPublic !== undefined && this.isPublic !== null) ? [this.isPublic] : undefined,
          ante: this.ante && this.ante?.length > 0 ? this.ante : undefined,
          currentPool: this.currentPool && this.currentPool?.length > 0 ? this.currentPool : undefined,
          participantsCount: this.participantsCount && this.participantsCount?.length > 0 ? this.participantsCount : undefined,
          maxParticipants: this.maxParticipants && this.maxParticipants?.length > 0 ? this.maxParticipants : undefined,
          metricLongDescription: (this.metricLongDescription !== undefined && this.metricLongDescription !== null) ? [this.metricLongDescription] : undefined,
          status: this.extractStatus(),
          createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
          startsAt: this.startsAt && this.startsAt?.length > 0 ? this.startsAt : undefined,
          endsAt: this.endsAt && this.endsAt?.length > 0 ? this.endsAt : undefined,
          acceptsResultsUntil: this.acceptsResultsUntil && this.acceptsResultsUntil?.length > 0 ? this.acceptsResultsUntil : undefined,
        },
        userId: this.userId ? this.userId : undefined,
        sort: this.extractSorting(),
        page: !customPage ? this.page : customPage,
        size: !customSize ? this.pageSize : customSize
      }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
