import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject, Subscription, takeUntil} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {BlacklistSourcesService} from "@service/utility/blacklist-sources.service";
import {EventEnum} from "@enum/event/event.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {ExportService} from "@service/export/export.service";
import {InvitesApiService} from "@service/utility/invites-api.service";
import {Router} from "@angular/router";
import {PrizesService} from "@service/prizes/prizes.service";

@Component({
  selector: 'app-user-uses-modal',
  templateUrl: './user-uses-modal.component.html',
  styleUrls: ['./user-uses-modal.component.scss']
})
export class UserUsesModalComponent implements OnInit {
  @Input() source: any;
  @Input() title: any;
  @Input() subtitle: any;
  @Input() showExtractResults?: boolean;
  @Input() showPagination?: boolean;
  @Input() type?: string;
  @Input() large?: boolean;
  @Input() leaderboard?: boolean;
  public banned: boolean = false;
  public deleteModalTypeEnum = DeleteModalTypeEnum;
  public detail: any = {
    loading: false,
    noResults: false,
    users: []
  }
  public pagination: any = {
    page: 1,
    size: 100,
    total: 0
  }
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private inviteId: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private modalService: NgbModal, private eventService: EventService, private invitesApiService: InvitesApiService,
              private router: Router, private prizesService: PrizesService,
              private blacklistSourceService: BlacklistSourcesService, private exportService: ExportService) {
  }

  ngOnInit(): void {
    this._closeModal();
  }

  public open(source: any, banned?: boolean, users?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {
        size: this.large ? 'xl' : 'lg',
        centered: true,
        backdrop: false
      })
      this.modalRef.result.then(resolve, resolve)
      switch (this.type) {
        case 'INVITES':
          this.inviteId = source?.id;
          this.title = [source?.code, '- clicks'].join(' ')
          this.subtitle = source?.deepLink
          this._loadInvites();
          break;
        case 'USERS_PRIZES':
          this.title = ['USERS_THAT_REDEEMED_THIS_PRICE'].join(' ')
          this.inviteId = source?.id;
          this._loadPrizes();
          break;
        default:
          this._genericMode(users, source, banned);
          break;
      }
    })
  }

  public userDetail(id: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(id)])
    );
    window.open(url, '_blank');
  }

  public loadPage(event: any): void {
    switch (this.type) {
      case 'INVITES':
        this._loadInvites();
        break;
      case 'USERS_PRIZES':
        this._loadPrizes();
        break;
      default:
        break;
    }
  }

  public extractResults(): any {
    const results = this.detail?.users?.map((result: any) => {
      return this.exportService.flattenObject(result);
    });
    this.exportService.exportAsExcelFile(results, this.title ? this.title : 'USER_LIST');
  }

  private _loadInvites(): void {
    const filters = {filters: {codeId: ['EQ', this.inviteId]}}
    const sort = {column: "usedAt", direction: "desc"};
    this.detail.loading = true;
    this.invitesApiService.getInviteUses({...filters, ...this.pagination, ...sort}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.detail.loading = false;
      this.detail.users = Array.isArray(result?.data) ? result?.data : undefined;
      this.pagination.total = result?.size;
    });
  }

  private _loadPrizes(): void {
    this.detail.loading = true;
    this.prizesService.getUsers({id: this.inviteId}, this.pagination).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.detail.loading = false;
      this.detail.users = Array.isArray(result?.data) ? result?.data : undefined;
      this.pagination.total = result?.size;
    });
  }

  private _genericMode(users: boolean, source: any, banned: boolean) {
    if (users) {
      this.setUsers(source);
    } else {
      this.source = source;
      this.banned = banned;
      if (this.banned) {
        this.loadBannedUsers();
      } else {
        this.loadUsers();
      }
    }
  }

  private loadUsers(): void {
    this.resetDetail();
    this.detail.loading = true;
    this.blacklistSourceService.sourceDetail(this.source).subscribe((result) => {
      this.setUsers(result);
    });
  }

  private setUsers(result) {
    this.detail.users = Array.isArray(result) ? result : undefined;
    this.detail.loading = false;
    this.detail.noResults = this.detail?.users?.length === 0 || !this.detail?.users;
  }

  private loadBannedUsers(): void {
    this.resetDetail();
    this.detail.loading = true;
    this.blacklistSourceService.automaticBannedUsers(this.source).subscribe((result) => {
      this.setUsers(result);
    });
  }

  private resetDetail(): void {
    this.detail = {
      loading: false,
      noResults: false,
      users: []
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL, (reason: string | undefined) => {
      this.resetDetail();
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
      this.source = undefined;
      this.modalRef?.dismiss(reason);
      this.pagination = {
        page: 1,
        size: 100
      }
    });
  }
}
