<div *ngIf="!single && (list$ | async) as competitions" class="row mb-3">
  <label class="col-md-3 col-form-label font-weight-bold">Select competitions</label>
  <div [ngClass]="{'is-invalid-select': error}" class="col-md-5 single-ng-select">
    <ng-select
      (change)="selectCompetitions($event)"
      [(ngModel)]="selected"
      [items]="competitions"
      [multiple]="false"
      bindLabel="bindLabel"></ng-select>
    <div *ngIf="error" class="invalid-feedback d-block mb-0">
      <span>{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="!basicSelect && parentForm && single && (list$ | async) as competitions" [formGroup]="parentForm" class="col-md-12">
  <div [ngClass]="{'is-invalid-select': formSubmitted && form.actionDetail.errors}" class="mb-3 single-ng-select">
    <label class="font-weight-bold">Select competitions</label>
    <p class="card-title-desc mb-1">
      Select the competition<span *ngIf="filtered"> of {{filtered}}</span> that the notification will open.
    </p>
    <ng-select (change)="selectCompetitions($event)" [items]="competitions"  bindLabel="competitionName"
               formControlName="actionDetail"></ng-select>
    <div *ngIf="formSubmitted && form.actionDetail.errors" class="invalid-feedback d-block">
      <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="single && basicSelect && (list$ | async) as competitions" [formGroup]="parentForm" class="col-md-12">
  <div [ngClass]="{'is-invalid-select': formSubmitted && form.competition.errors || pageFilterErrors}" class="mb-3 single-ng-select">
    <label class="font-weight-bold" *ngIf="!hideDescription">Select competition</label>
    <ng-select (change)="selectCompetitions($event)" [(ngModel)]="selected"
               [items]="competitions" bindLabel="competitionName"
               formControlName="competition"></ng-select>
    <div *ngIf="formSubmitted && form.competition.errors" class="invalid-feedback d-block">
      <span *ngIf="formSubmitted && form.competition.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="pageFilter && (list$ | async) as competitions" class="input-group me-2 page-filter-group" [ngClass]="{'is-invalid-select': pageFilterErrors}">
<!--  <label class="input-group-text">{{'SELECT_COMPETITION' | translate}}</label>-->
  <ng-select (change)="selectCompetitions($event)" [(ngModel)]="selected"
             [placeholder]="'Select competition'"
             [items]="competitions" bindLabel="competitionName"></ng-select>
</div>
