<div *ngIf="!!data" class="input-group me-2 page-filter-group single-ng-select" [ngClass]="{'is-invalid-select': error}">
  <ng-select (change)="selectCompany($event)"
             id="competition"
             (search)="competitionsService.searchTerm = $event.term"
             [multiple]="multiple"
             [searchFn]="search"
             [placeholder]="extractPlaceholder()"
             [clearable]="false"
             [items]="data">
    <ng-template let-item="item" ng-label-tmp>
      <span class="badge badge-pill badge-soft-primary font-size-11 mr-1">{{item?.competitionType}}</span>
      <span>{{ returnItemDescription(item) }}</span>
    </ng-template>
    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
      <span class="badge badge-pill badge-soft-primary font-size-11 mr-1">{{item?.competitionType}}</span>
      <span class="font-weight-semibold ms-2">{{ returnItemDescription(item) }}</span>
    </ng-template>
  </ng-select>
</div>
