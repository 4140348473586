import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {RecommendedAppsService} from "@service/app-config/recommended-apps/recommended-apps.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-recommended-app-modal',
  templateUrl: './recommended-app-modal.component.html',
  styleUrls: ['./recommended-app-modal.component.scss']
})
export class RecommendedAppModalComponent implements OnInit {
  @Input() app: any;
  public showAreYouSure: boolean = false;
  public addingApp$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public appForm: UntypedFormGroup;
  public limit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 500,
    height: 500
  };

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
              private _formService: FormsService,
              private recommendedAppsService: RecommendedAppsService) { }

  ngOnInit(): void {
    this.addingApp$ = this.recommendedAppsService.addingApp$;
    this.initForm();
  }

  public validSubmit() {
    if (this.appForm.valid) {
      this.recommendedAppsService.addApp(this.appForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.appForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.appForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.appForm.controls;
  }

  get valid() {
    return this.appForm.valid
  }

  public setImage(image: any): void {
    this.appForm.patchValue({
      iconUrl: image ? image?.originalUrl : undefined,
      imageName: image ? image?.name : undefined,
      imageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.iconUrl?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.iconUrl?.value) {
      return {originalUrl: this.form.iconUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'detail_image'].join('_')}
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.appForm = this.formBuilder.group({
      title: [!this.app || !this.app?.title ? null : this.app?.title, [Validators.required]],
      description: [!this.app || !this.app?.description ? null : this.app?.description],
      androidStore: [!this.app || !this.app?.androidStore ? null : this.app?.androidStore, [Validators.pattern(this.utils.validateUrlRegex)]],
      androidPackage: [!this.app || !this.app?.androidPackage ? null : this.app?.androidPackage, [Validators.pattern(this.utils.androidPackageRegex)]],
      iosStore: [!this.app || !this.app?.iosStore ? null : this.app?.iosStore, [Validators.pattern(this.utils.validateUrlRegex)]],
      iosPackage: [!this.app || !this.app?.iosPackage ? null : this.app?.iosPackage],
      iconUrl: [!this.app || !this.app?.iconUrl ? null : this.app?.iconUrl, [Validators.required]],
      imageName: [!this.app || !this.app?.iconUrl ? null : [this.app?.title?.replace(/\s/g, "_"), 'detail_image'].join('_')],
      imageType: [!this.app || !this.app?.iconUrl ? null : this.app?.iconUrl],
      id: [this.app?.id && !this.app.duplicate ? this.app?.id : null],
      active: [this.app?.active && !this.app.duplicate ? this.app?.active : false]
    });
  }
}
