<div class="operation-modal-header">
  <h5 *ngIf="!precondition">{{'CREATE_PRECONDITION' | translate}}</h5>
  <h5 *ngIf="precondition && !precondition?.duplicate">{{'EDIT_PRECONDITION' | translate}}: {{precondition?.title}}</h5>
  <h5 *ngIf="precondition && precondition?.duplicate">{{'DUPLICATE_PRECONDITION' | translate}}: {{precondition?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(addingPrecondition$ | async)" class="btn close-modal-button" closeModal="CLOSE_RECOMMENDED_APP_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="activityForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                 formControlName="title" id="title" type="text">
          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div [ngClass]="{'is-invalid-select': formSubmitted && form?.type?.errors}" class="mb-3">
          <label class="font-weight-bold" for="type">{{'TYPE' | translate}}</label>
          <select class="form-select" formControlName="type" id="type">
            <option *ngFor="let type of preconditionTypes" [value]="type">{{type | translate}}</option>
          </select>
          <div *ngIf="formSubmitted && form?.type?.errors" class="invalid-feedback d-block">
            <span *ngIf="formSubmitted && form?.type?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="description">Precondition description</label>
          <ckeditor [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }" [editor]="Editor"
                    data="<p>Description of the prize.</p>"
                    formControlName="description"></ckeditor>
          <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div *ngIf="this.form?.type?.value === 'POWER_LEVEL'" class="col-12">
        <label class="font-weight-bold">Valid power level</label>
        <div class="single-ng-select">
          <ng-select
            [items]="powerLevels"
            [multiple]="true"
            formControlName="validPowerLevels"></ng-select>
        </div>
      </div>
      <div *ngIf="this.form?.type?.value === 'BADGE'" class="col-12">
        <label class="font-weight-bold">Valid for badges</label>
        <div class="single-ng-select">
          <ng-select
              bindLabel="name" bindValue="badgeId"
              [items]="badges"
              [multiple]="true"
              formControlName="validForBadges"></ng-select>
        </div>
      </div>
      <div *ngIf="this.form?.type?.value === 'LEVEL'" class="col-12">
        <div class="row">
          <div class="col-2">
            <label class="font-weight-bold" for="minimumLevel">{{'MIN_LEVEL' | translate}}</label>
            <input class="form-control" formControlName="minimumLevel" id="minimumLevel" type="number">
          </div>
          <div class="col-2">
            <label class="font-weight-bold" for="maximumLevel">{{'MAX_LEVEL' | translate}}</label>
            <input class="form-control" formControlName="maximumLevel" id="maximumLevel" type="number">
          </div>
        </div>
      </div>
      <div *ngIf="this.form?.type?.value === 'USER_PROFILE'" class="col-12">
        <div class="row">
          <div class="col-2">
            <div [ngClass]="{'is-invalid-select': formSubmitted && form?.neededGender?.errors}" class="mb-3">
              <label class="font-weight-bold" for="neededGender">{{'GENDER' | translate}}</label>
              <select class="form-select" formControlName="neededGender" id="neededGender">
                <option *ngFor="let neededGender of genders"
                        [value]="neededGender">{{neededGender | translate}}</option>
              </select>
              <div *ngIf="formSubmitted && form?.neededGender?.errors" class="invalid-feedback d-block">
                <span
                  *ngIf="formSubmitted && form?.neededGender?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
              </div>
            </div>
          </div>
          <div class="col-2">
            <label class="font-weight-bold" for="minimumAge">{{'MIN_AGE' | translate}}</label>
            <input class="form-control" formControlName="minimumAge" id="minimumAge" type="number">
          </div>
          <div class="col-2">
            <label class="font-weight-bold" for="maximumAge">{{'MAX_AGE' | translate}}</label>
            <input class="form-control" formControlName="maximumAge" id="maximumAge" type="number">
          </div>
          <div class="col-2">
            <div class="mb-3">
              <label class="font-weight-bold font-size-11" for="pictureTaken">Profile picture must be present</label>
              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                <input [checked]="form?.pictureTaken?.value" autocomplete="off" class="form-check-input m-0 pointer"
                       formControlName="pictureTaken" id="pictureTaken" type="checkbox">
                <label class="form-check-label font-size-11 ms-3" for="pictureTaken">
                  <span *ngIf="form?.pictureTaken?.value"
                        class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                  <span *ngIf="!form?.pictureTaken?.value"
                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="mb-3">
              <label class="font-weight-bold font-size-11" for="profileCompleted">Profile must be completed</label>
              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                <input [checked]="form?.profileCompleted?.value" autocomplete="off" class="form-check-input m-0 pointer"
                       formControlName="profileCompleted" id="profileCompleted" type="checkbox">
                <label class="form-check-label font-size-11 ms-3" for="profileCompleted">
                  <span *ngIf="form?.profileCompleted?.value" class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                  <span *ngIf="!form?.profileCompleted?.value" class="badge badge-pill badge-soft-danger font-size-11">No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !precondition " class="btn mb-3 btn-warning">
          Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && precondition && precondition?.duplicate"
                class="btn mb-3 btn-warning">Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && precondition && !precondition?.duplicate"
                class="btn mb-3 btn-warning">Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingPrecondition$ | async)" class="btn mb-3 btn-warning me-2"
                type="submit">
          <i *ngIf="addingPrecondition$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingPrecondition$ | async) && !precondition">{{'CREATING' | translate}}</span>
          <span
            *ngIf="(addingPrecondition$ | async) && precondition && !precondition?.duplicate">{{'EDITING' | translate}}</span>
          <span
            *ngIf="(addingPrecondition$ | async) && precondition && precondition?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingPrecondition$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingPrecondition$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
