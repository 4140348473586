import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {CompaniesApiService} from "@service/companies/companies-api.service";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class CompaniesTeamsService {
  public _delete$ = new Subject<any>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.companyTeamsTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.companyTeamsTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchTeams: Subscription;
  private _exporting = new BehaviorSubject<any>(null);
  private deleteSubscription: Subscription;

  constructor(private companiesApiService: CompaniesApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _teams$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get teams$() {
    return this._teams$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public get companyId() {
    return this._currentTableState.companyId;
  }

  public set companyId(companyId: string) {
    this._setValue({companyId});
  }

  public removeSearchTeamsListener(): void {
    this.searchTeams?.unsubscribe();
    this._loading$.next(false);
  }

  public getTeamsById(filters): any {
    return this.companiesApiService.getCompanyTeams(filters);
  }

  public initSearchTeamsListener(): void {
    this.searchTeams = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.companiesApiService.getCompanyTeams(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let teams = result?.data?.map((template) => {
        template.languages = this.utils?.getAvailableLang(template?.localizableKey);
        return template;
      });
      this._teams$.next(teams);
      this._totalRecords$.next(result?.size);
    });
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public removeDeleteSubscribe(): void {
    this.deleteSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initDeleteListener(): void {
    this.deleteSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((team) => this.companiesApiService.deleteTeam(team).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Team deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public extractTeamMembers(companyId: any, teamId: any): any {
    return this.companiesApiService.getCompanyTeamsMembers(companyId, teamId)
  }

  public editCompanyTeam(params: any): any {
    return this.companiesApiService.editCompanyTeams(params);
  }

  public addCompanyTeams(params: any): any {
    return this.companiesApiService.addCompanyTeams(params);
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.companiesApiService.getCompanyTeams(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
      },
      companyId: (this.companyId !== undefined && this.companyId !== null) ? this.companyId : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
