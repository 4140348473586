<app-diagnostic-topbar (dateRangeSelected)="setDate($event)" [download]="true" (downloadEvent)="downloadResults($event)"></app-diagnostic-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div class="wrapper">
    <table [hidden]="streaks?.length === 0" class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables recorded-streaks" id="basic-datatable">
      <thead>
      <tr>
        <th>
          <span class="me-3">{{'USER_EMAIL' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'USER_FULLNAME' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'FIRST_REDEEMED_INSTANT' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'LAST_REDEEMED_INSTANT' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'REDEEMED_COUNT' | translate}}</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let streak of streaks; let i=index;" class="no-bg">
        <td align="center" class="fit-width">
          <span *ngIf="streak?.user?.email" ngbTooltip="{{streak?.user?.email}}"
                placement="bottom">{{streak?.user?.email | truncate:[25]}}</span>
          <app-missing-label *ngIf="!streak?.user?.email"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="streak?.user?.fullName" ngbTooltip="{{streak?.user?.fullName}}"
                placement="bottom">{{streak?.user?.fullName | truncate:[25]}}</span>
          <app-missing-label *ngIf="!streak?.user?.fullName"></app-missing-label>
        <td align="center" class="fit-width">
          <span *ngIf="streak?.firstReedimedInstan">{{streak?.firstReedimedInstan | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!streak?.firstReedimedInstan"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="streak?.lastReedimedInstan">{{streak?.lastReedimedInstan | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!streak?.lastReedimedInstan"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="streak?.redeemedGoalCount <= 0" class="badge badge-pill font-size-11 badge-soft-danger">{{streak?.redeemedGoalCount}}</span>
          <span *ngIf="streak?.redeemedGoalCount > 0" class="badge badge-pill font-size-11 badge-soft-success">{{streak?.redeemedGoalCount}}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="streaks?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
