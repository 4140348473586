<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!reward" class="modal-title" id="modal-title-1">Create new reward</h5>
    <h5 *ngIf="reward" class="modal-title" id="modal-title-2">Edit {{this.reward?.name | translate}} reward</h5>
    <button [disabled]="(creatingReward$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_REWARD">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="rewardForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="validSince">{{'STREAK_START_DATE' | translate}}</label>
                <app-simple-datepicker (dateSelected)="setSinceDate($event)"
                                       [isInvalid]="formSubmitted && form?.validSince?.errors?.required"
                                       [minDateLock]="false"
                                       [name]="'Monday'"
                                       [selectedEndDate]="form?.validUntil?.value"
                                       [selectedStartDate]="form?.validSince?.value"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validSince?.errors" class="invalid-feedback d-block">
                  <span
                    *ngIf="formSubmitted && form?.validSince?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="validUntil">{{'STREAK_END_DATE' | translate}}</label>
                <app-simple-datepicker (dateSelected)="setUpToDate($event)"
                                       [isInvalid]="formSubmitted && form?.validUntil?.errors?.required"
                                       [minDateLock]="false"
                                       [name]="'Sunday'"
                                       [selectedEndDate]="form?.validUntil?.value"
                                       [selectedStartDate]="form?.validSince?.value"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validUntil?.errors" class="invalid-feedback d-block">
                  <span
                    *ngIf="formSubmitted && form?.validUntil?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-2" for="credits">Credits</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.credits.errors}" class="form-control"
                       formControlName="credits" id="credits" min="1" type="number">
                <div *ngIf="formSubmitted && form.credits.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.credits.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createStreakTemplate()" *ngIf="!reward" [disabled]="(creatingReward$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingReward$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingReward$ | async) && !reward">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingReward$ | async) && !reward">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createStreakTemplate()" *ngIf="reward"
                  class="btn btn-warning">
            <i *ngIf="creatingReward$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingReward$ | async) && reward">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingReward$ | async) && reward">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
