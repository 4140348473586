<div class="card">
  <div class="card-body">
    <div class="media">
      <div class="me-3">
        <img alt="" class="avatar-md rounded-circle img-thumbnail"
             src="https://virtuoso-v2-dev-originals-healthyvirtuoso.s3.amazonaws.com/profiles/152-1629888718">
      </div>
      <div class="media-body align-self-center">
        <div class="text-muted">
          <h5>Manuel Fancello</h5>
          <p class="mb-1">mfancello@healthyvirtuoso.com</p>
          <p class="mb-0">Id no: 123435</p>
        </div>
      </div>
      <div class="dropdown" ngbDropdown>
        <button aria-expanded="false" aria-haspopup="true" class="btn btn-light dropdown-toggle"
                data-toggle="dropdown" type="button"><i class="mdi mdi-wallet me-1"></i> <span
          class="d-none d-sm-inline-block" ngbDropdownToggle>Actions<i
          class="mdi mdi-chevron-down"></i></span></button>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-md" ngbDropdownMenu>
          <div class="dropdown-item-text">
            <div>
              <p class="text-muted mb-2">Ban</p>
            </div>
          </div>

          <div class="dropdown-divider"></div>


          <div class="dropdown-divider"></div>

          <a class="dropdown-item text-primary text-center" href="javascript: void(0);">
            Detail
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
