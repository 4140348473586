import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {UtilsService} from "@service/utils/utils.service";
import {ContentApiService} from "@service/content/content-api.service";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";

@Injectable({
  providedIn: 'root'
})
export class ContentSearchService {
  public sorting: any | undefined;
  private searchFilters: any | undefined = {}
  private pagination: { page, size } = {page: 1, size: 25};
  private readonly activeFilters: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly observable: Observable<any> = this.activeFilters.asObservable();

  constructor(private _contentApiService: ContentApiService, private _utilsService: UtilsService, private _toastService: ToastService, private _eventService: EventService) {
  }

  private static get actions(): any[] {
    return [
      {
        clickAction: 'delete',
        disabledIf: 'sendAt',
        class: ['pointer', 'danger', 'font-size-18'],
        tooltip: 'DELETE',
        placement: 'bottom',
        icon: 'bx bx-trash'
      },
      {
        clickAction: 'duplicate',
        class: ['pointer', 'font-size-18'],
        tooltip: 'DUPLICATE',
        placement: 'bottom',
        icon: 'bx bx-copy'
      },
      {
        clickAction: 'edit',
        disabledIf: 'sendAt',
        class: ['pointer', 'font-size-18'],
        tooltip: 'EDIT',
        placement: 'bottom',
        icon: 'bx bx-pencil'
      },
    ]
  }

  private _adding$ = new BehaviorSubject<boolean>(false);

  public get adding$() {
    return this._adding$.asObservable();
  }

  public get api(): any {
    return this._contentApiService;
  }

  public get pageSize() {
    return this.pagination.size;
  }

  public set pageSize(pageSize: number) {
    this.pagination.page = 1;
    this.pagination.size = pageSize;
    this.activeFilters.next(this.searchFilters);
  }

  public get page() {
    return this.pagination.page;
  }

  public set page(page: number) {
    this.pagination.page = page;
    this.activeFilters.next(this.searchFilters);
  }

  public get getActiveFilters(): any {
    return this.activeFilters.getValue();
  }

  public get columns(): any[] {
    return [
      {id: 1, canHide: false, visible: true, checkbox: true, label: '', align: 'center', class: ['fit-width']},
      {
        id: 2,
        canHide: true,
        visible: true,
        text: true,
        label: 'IMAGE',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'image',
        sortable: true,
        sortKey: 'image',
        image: true
      },
	    {
		    id: 2,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'CATEGORY',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'contentCategory',
		    secondLevelElementKey: 'icon',
		    sortable: true,
		    sortKey: 'contentCategory.icon',
		    image: true
	    },
	    {
		    id: 2,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'PRIORITY',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'priority',
		    elementClasses: ['badge', 'badge-pill', 'badge-soft-dark'],
		    sortable: true,
		    sortKey: 'priority'
	    },
      {
        id: 3,
        canHide: true,
        visible: true,
        text: true,
        label: 'ID',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'id',
        sortable: true,
        sortKey: 'id',
        elementClasses: ['badge', 'badge-pill', 'badge-soft-primary'],
        clickAction: 'userDetail'
      },
	    {
		    id: 4,
		    canHide: true,
		    visible: false,
		    text: true,
		    label: 'ID_TEXT',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'idText',
		    sortable: true,
		    sortKey: 'idText',
		    elementClasses: ['badge', 'badge-pill', 'badge-soft-secondary'],
		    clickAction: 'userDetail'
	    },
	    {
		    id: 5,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'VALID_FOR_ALL_COMPANIES',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'validForAllCompanies',
		    sortable: true,
		    sortKey: 'validForAllCompanies',
		    boolean: true
	    },
	    {
		    id: 5,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'COMPANY_LIST',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'companies',
		    secondLevelElementKey: 'name',
		    sortable: false,
		    elementList: true
	    },
      {
        id: 6,
        canHide: true,
        visible: true,
        text: true,
        label: 'LABEL',
        align: 'center',
        class: ['pointer'],
        elementKey: 'label',
        sortable: true,
        sortKey: 'label'
      },
      {
        id: 6,
        canHide: true,
        visible: true,
        text: true,
        label: 'DESCRIPTION',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'description',
        sortable: true,
        sortKey: 'description',
        truncate: true,
        truncateValue: 40
      },
	    {
		    id: 9,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'ACTION',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'contentCategory',
		    secondLevelElementKey: 'action',
		    translatable: true,
		    multi: true,
		    sortable: true,
		    sortKey: 'contentCategory.action',
		    clickAction: 'userDetail'
	    },
	    // {
		  //   id: 10,
		  //   canHide: true,
		  //   visible: true,
		  //   text: true,
		  //   label: 'ACTION_DETAIL',
		  //   align: 'center',
		  //   class: ['pointer', 'fit-width'],
		  //   elementKey: 'destinationDescription',
		  //   sortable: true,
		  //   sortKey: 'destinationDescription',
		  //   truncate: true,
		  //   truncateValue: 25
	    // },
	    {
		    id: 10,
		    canHide: true,
		    visible: false,
		    text: true,
		    label: 'CATEGORY_NAME',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'contentCategory',
		    secondLevelElementKey: 'name',
		    multi: true,
		    sortable: true,
		    sortKey: 'contentCategory.name',
		    clickAction: 'userDetail'
	    },
	    {
		    id: 12,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'VALID_SINCE',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'validSinceInclusive',
		    sortable: true,
		    sortKey: 'validSinceInclusive',
		    date: true
	    },
	    {
		    id: 13,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'VALID_UNTIL',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'validUntilExclusive',
		    sortable: true,
		    sortKey: 'validUntilExclusive',
		    date: true
	    },
	    {
		    id: 14,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'LOCALE',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'languageToTranslationUrl',
		    sortable: true,
		    sortKey: 'languageToTranslationUrl',
		    locale: true,
		    multiLocale: true
	    },
      {
        id: 16, canHide: false, visible: true, label: '', isRowAction: true, rowActions: ContentSearchService.actions
      },
    ]
  }

  public setFilter(patch: any): void {
    Object.assign(this.searchFilters, patch);
    this.activeFilters.next(this.searchFilters);
  }

  public clearFilters(): void {
    this.searchFilters = {};
    this.activeFilters.next(this.searchFilters);
  }

  public filters(): any {
    return {
      filters: this.activeFilters.getValue(),
      sort: this.sorting,
      page: this.pagination.page,
      size: this.pagination.size
    }
  }

  public filterApplied(length?: boolean): boolean | number {
    const filters: any = this.activeFilters.getValue();
    if (!filters) return !length ? false : 0;
    if (Object.keys(filters)?.length > 0) {
      const obj = this._utilsService.clearObject(filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public addContent(data: any): any {
    this._adding$.next(true);
    this._contentApiService.add(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (typeof result === 'string') {
        this._toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this._toastService.show(!data?.id ? 'Content added' : 'Content edited', {classname: 'bg-success text-light'});
      }
      this._adding$.next(false);
      this._eventService.broadcast(EventEnum.CLOSE_CONTENT_MODAL, null)
    });
  }
}
