<div class="input-group me-2">
  <label *ngIf="title && !noLabel" class="input-group-text">{{title | translate}}</label>
  <ng-template #t let-date let-focused="focused">
  <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.focused]="focused" [class.range]="isRange(date)"
        class="custom-day">
  {{ date.day }}
  </span>
  </ng-template>
  <input (click)="hideRangeDatePicker=!hideRangeDatePicker"
         (ngModelChange)="onDateSelection($event)"
         [(ngModel)]="selectedDateRange"
         [ngModelOptions]="{ standalone: true }" autoClose="true" autocomplete="off"
         class="form-control date" name="daterange" placeholder="dd/mm/yyyy - dd/mm/yyyy"
         type="text"/>
  <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [dayTemplate]="t" [displayMonths]="2"
                  [hidden]="hideRangeDatePicker"
                  [maxDate]="!noMinMax && recurring?.startDate ? calendar.getNext(this.calendar.getToday(), 'd', 365) : null"
                  [minDate]="!noMinMax && recurring?.startDate ? calendar.getToday() : null" outsideDays="hidden">
  </ngb-datepicker>
</div>
