import {TranslateService} from "@ngx-translate/core";

export function translateFactory(translate: TranslateService): any {
  return async () => {
    translate.setDefaultLang('en');
    translate.use('en');
    return new Promise<void>(resolve => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
  };
}
