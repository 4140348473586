import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {FaqsService} from "@service/faqs/faqs.service";

@Component({
  selector: 'app-select-faq',
  templateUrl: './select-faq.component.html',
  styleUrls: ['./select-faq.component.scss']
})
export class SelectFaqComponent implements OnInit, OnDestroy {
  @Output() selectedFaq = new EventEmitter();
  @Input() formSubmitted: boolean;
  @Input() form: any;
  public subscription: Subscription;
  public selected: any;
  public faqs: any[] = [];
  public list$: Observable<any[]>;

  constructor(private faqsService: FaqsService) {
  }

  ngOnInit(): void {
    this.faqsService.initSearchFaqsListener();
    this.faqsService.pageSize = 1000;
    this.subscription = this.faqsService.faqList$.subscribe((list: any) => {
      this.faqs = list?.length > 0 ? list : [];
    });
  }

  public selectFaq(event: any): any {
    if (event) {
      this.selectedFaq.emit(event);
    } else {
      this.selectedFaq.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.selected = null;
    this.faqsService.removeSearchFaqsSubscribe();
  }

}
