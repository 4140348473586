import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  NgbAlertModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {DateRangePickerComponent} from './utils/date-range-picker/date-range-picker.component';
import {TranslateModule} from "@ngx-translate/core";
import {MissingLabelComponent} from './utils/missing-label/missing-label.component';
import {DiaryComponent} from './users/diary/diary.component';
import {LoaderComponent} from './utils/loader/loader.component';
import {TableSizeComponent} from './utils/table-size/table-size.component';
import {DirectiveModule} from "@app/directives/directive.module";
import {FitnessMetricsComponent} from './users/fitness-metrics/fitness-metrics.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {DataCardComponent} from './utils/data-card/data-card.component';
import {SimpleDatepickerComponent} from './utils/simple-datepicker/simple-datepicker.component';
import {FitnessMetricsChartsComponent} from './users/fitness-metrics-charts/fitness-metrics-charts.component';
import {UserDetailCardComponent} from './users/user-detail-card/user-detail-card.component';
import {ColumnPickerComponent} from './utils/column-picker/column-picker.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastsComponent} from './utils/toasts/toasts.component';
import {NumberPickerComponent} from './utils/number-picker/number-picker.component';
import {BanModalComponent} from './users/ban-modal/ban-modal.component';
import {ArchwizardModule} from "angular-archwizard";
import {BanInformationComponent} from './users/ban-modal/steps/ban-information/ban-information.component';
import {BanSummaryComponent} from './users/ban-modal/steps/ban-summary/ban-summary.component';
import {UsersTableComponent} from './users/users-table/users-table.component';
import {BanMotivationModalComponent} from './users/ban-motivation-modal/ban-motivation-modal.component';
import {DeleteModalComponent} from './utils/delete-modal/delete-modal.component';
import {CoreModule} from "@app/core.module";
import {BanRemoveModalComponent} from './users/ban-remove-modal/ban-remove-modal.component';
import {SendNotificationModalComponent} from './notifications/send-notification-modal/send-notification-modal.component';
import {UsersRecapComponent} from "@component/utils/steps/users-recap/users-recap.component";
import {NotificationOptionsComponent} from './notifications/send-notification-modal/steps/notification-options/notification-options.component';
import {NotificationSummaryComponent} from './notifications/send-notification-modal/steps/notification-summary/notification-summary.component';
import {RecordedActivitiesComponent} from './users/recorded-activities/recorded-activities.component';
import {TablePaginationComponent} from './utils/table-pagination/table-pagination.component';
import {TableTopbarComponent} from './utils/table-topbar/table-topbar.component';
import {SelectAudienceComponent} from './notifications/select-audience/select-audience.component';
import {SelectActionsComponent} from './notifications/select-actions/select-actions.component';
import {QrDetailModalComponent} from './utils/qr-detail-modal/qr-detail-modal.component';
import {QRCodeModule} from "angularx-qrcode";
import {QrCreateModalComponent} from './utils/qr-create-modal/qr-create-modal.component';
import {SelectCompanyComponent} from './companies/select-company/select-company.component';
import {SelectCompetitionComponent} from './competitions/select-competition/select-competition.component';
import {SelectChallengeComponent} from './challenges/select-challenge/select-challenge.component';
import {SelectPrizeComponent} from './prizes/select-prize/select-prize.component';
import {LinkValidatorComponent} from './utils/link-validator/link-validator.component';
import {NotificationDeliveryComponent} from './notifications/send-notification-modal/steps/notification-delivery/notification-delivery.component';
import {PrizesTableComponent} from './prizes/prizes-table/prizes-table.component';
import {ChallengesTableComponent} from './challenges/challenges-table/challenges-table.component';
import {CompetitionsTableComponent} from './competitions/competitions-table/competitions-table.component';
import {CompaniesTableComponent} from './companies/companies-table/companies-table.component';
import {NotificationAudienceComponent} from './notifications/send-notification-modal/steps/notification-audience/notification-audience.component';
import {ClipboardModule} from "ngx-clipboard";
import {AutomaticBanRuleModalComponent} from './utils/automatic-ban-rule-modal/automatic-ban-rule-modal.component';
import {AddRemoveWhitelistComponent} from './users/add-remove-whitelist/add-remove-whitelist.component';
import {AddBlacklistSourceModalComponent} from './utils/add-blacklist-source-modal/add-blacklist-source-modal.component';
import {UserUsesModalComponent} from './utils/user-uses-modal/user-uses-modal.component';
import {ImageUploaderComponent} from './utils/image-uploader/image-uploader.component';
import {UcWidgetModule} from "ngx-uploadcare-widget";
import {AddNewsModalComponent} from './news/add-news-modal/add-news-modal.component';
import {BanHistoryComponent} from './users/ban-history/ban-history.component';
import {DeactivateModalComponent} from './utils/deactivate-modal/deactivate-modal.component';
import {UserDiagnosticComponent} from './users/user-diagnostic/user-diagnostic.component';
import {DiagnosticTopbarComponent} from './utils/diagnostic-topbar/diagnostic-topbar.component';
import {UserFitnessChartsComponent} from './users/user-fitness-charts/user-fitness-charts.component';
import {FitnessMetricPickerComponent} from './utils/fitness-metric-picker/fitness-metric-picker.component';
import {UserChallengesComponent} from './users/user-challenges/user-challenges.component';
import {PaginationFooterComponent} from "@component/utils/pagination-footer/pagination-footer.component";
import {UserRedeemPrizesComponent} from './users/user-redeem-prizes/user-redeem-prizes.component';
import {UserUsedCodesComponent} from './users/user-used-codes/user-used-codes.component';
import {UserInvitationsSentComponent} from './users/user-invitations-sent/user-invitations-sent.component';
import {UserProfileComponent} from './users/user-profile/user-profile.component';
import {AddRemoveCreditsModalComponent} from './users/add-remove-credits-modal/add-remove-credits-modal.component';
import {AddStreakTemplateModalComponent} from './streaks/add-streak-template-modal/add-streak-template-modal.component';
import {AddStreakModalComponent} from './streaks/add-streak-modal/add-streak-modal.component';
import {AddPrizeModalComponent} from './prizes/add-prize-modal/add-prize-modal.component';
import {SelectFaqComponent} from './faqs/select-faq/select-faq.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {ActivateModalComponent} from "@component/utils/activate-modal/activate-modal.component";
import {AddFaqModalComponent} from './faqs/add-faq-modal/add-faq-modal.component';
import {AddCompanyModalComponent} from './companies/add-company-modal/add-company-modal.component';
import {AddCompetitionModalComponent} from './competitions/add-competition-modal/add-competition-modal.component';
import {PrizeCodesModalComponent} from './prizes/prize-codes-modal/prize-codes-modal.component';
import {CompanyCodesModalComponent} from './companies/company-codes-modal/company-codes-modal.component';
import {AddFtoModalComponent} from './rewards/fitness-to-credits/add-fto-modal/add-fto-modal.component';
import {ComplexDatePickerComponent} from './utils/complex-date-picker/complex-date-picker.component';
import {NgxMaskModule} from "ngx-mask";
import {DatepickerComponent} from './utils/datepicker/datepicker.component';
import {SelectBoxPickerComponent} from './utils/select-box-picker/select-box-picker.component';
import {AddRewardModalComponent} from './rewards/add-reward-modal/add-reward-modal.component';
import {ExportResultsModalComponent} from './utils/export-results-modal/export-results-modal.component';
import {DateTimePickerComponent} from './utils/date-time-picker/date-time-picker.component';
import {PostponeModalComponent} from './utils/postpone-modal/postpone-modal.component';
import {PrizeListPageComponent} from './prizes/prize-list-page/prize-list-page.component';
import {IndividualLeaderboardPageComponent} from './competitions/individual-leaderboard-page/individual-leaderboard-page.component';
import {TeamLeaderboardPageComponent} from './competitions/team-leaderboard-page/team-leaderboard-page.component';
import {StringPickerComponent} from './utils/string-picker/string-picker.component';
import {BannedListPageComponent} from './utils/banned-list-page/banned-list-page.component';
import {TeamListPageComponent} from './companies/team-list-page/team-list-page.component';
import {MembershipsListPageComponent} from './companies/memberships-list-page/memberships-list-page.component';
import {CodesListPageComponent} from './companies/codes-list-page/codes-list-page.component';
import {AddCompanyTeamsModalComponent} from './companies/add-company-teams-modal/add-company-teams-modal.component';
import {EditTeamComponent} from './companies/edit-team/edit-team.component';
import {CompetitionsListPageComponent} from './competitions/competitions-list-page/competitions-list-page.component';
import {TextPickerComponent} from './utils/text-picker/text-picker.component';
import {NotificationModalComponent} from './notifications/notification-modal/notification-modal.component';
import {RecurringEventPickerComponent} from "@component/notifications/recurring-form/recurring-form.component";
import {IMaskModule} from "angular-imask";
import {PrizeSelectComponent} from './prizes/prize-select/prize-select.component';
import {CompanySelectComponent} from './companies/company-select/company-select.component';
import {CompetitionSelectComponent} from './competitions/competition-select/competition-select.component';
import {TableComponent} from './refactoring/table/table.component';
import {DeleteComponent} from './common/delete/delete.component';
import {AddMembershipsModalComponent} from './companies/add-memberships-modal/add-memberships-modal.component';
import {RawDataModalComponent} from './utils/raw-data-modal/raw-data-modal.component';
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {AddPartnerModalComponent} from './prizes/add-partner-modal/add-partner-modal.component';
import {AddOperatorModalComponent} from './users/operators/add-operator-modal/add-operator-modal.component';
import {RecommendedAppModalComponent} from './app-config/recommended-app-modal/recommended-app-modal.component';
import {BenefitModalComponent} from './app-config/benefit-modal/benefit-modal.component';
import {HowToSyncModalComponent} from './app-config/how-to-sync-modal/how-to-sync-modal.component';
import {ActivityModalComponent} from './app-config/activity-modal/activity-modal.component';
import {PreconditionsModalComponent} from './prizes/preconditions/preconditions-modal/preconditions-modal.component';
import {SelectLocaleComponent} from './locale/select-locale/select-locale.component';
import {FitnessSlotsComponent} from './users/fitness-slots/fitness-slots.component';
import {WebappModalComponent} from './companies/webapp-modal/webapp-modal.component';
import {AddContentCategoryModalComponent} from './content/add-content-category-modal/add-content-category-modal.component';
import {AddSettingModalComponent} from './notifications/add-setting-modal/add-setting-modal.component';
import {AddContentModalComponent} from './content/add-content-modal/add-content-modal.component';
import {FileUploaderComponent} from './utils/file-uploader/file-uploader.component';
import {AddSustainabilityPartnerComponent} from './sustainability/add-sustainability-partner/add-sustainability-partner.component';
import {AddSustainabilityProjectComponent} from './sustainability/add-sustainability-project/add-sustainability-project.component';
import {CompaniesStreaksListComponent} from './companies/companies-streaks-list/companies-streaks-list.component';
import {AddSustainabilityBenefitComponent} from './sustainability/add-sustainability-benefit/add-sustainability-benefit.component';
import {AddSustainabilityPlaceComponent} from './sustainability/add-sustainability-place/add-sustainability-place.component';
import {AddSustainabilityObjectiveComponent} from './sustainability/add-sustainability-objective/add-sustainability-objective.component';
import {AddDeeplinkModalComponent} from './deeplinks/add-deeplink-modal/add-deeplink-modal.component';
import { DeeplinkActionSelectComponent } from './deeplinks/deeplink-action-select/deeplink-action-select.component';
import { FaqSelectComponent } from './faqs/faq-select/faq-select.component';
import { BadgeSelectComponent } from './common/badge-select/badge-select.component';
import { AddPrizeCategoryModalComponent } from './prizes/add-prize-category-modal/add-prize-category-modal.component';
import {
	AddPublicContentModalComponent
} from "@component/content/add-public-content-modal/add-public-content-modal.component";
import { RecalculateCountersModalComponent } from './utils/recalculate-counters-modal/recalculate-counters-modal.component';

@NgModule({
  declarations: [BreadcrumbComponent, DateRangePickerComponent,
    MissingLabelComponent, DiaryComponent, LoaderComponent,
    TableSizeComponent, FitnessMetricsComponent, DataCardComponent,
    SimpleDatepickerComponent, FitnessMetricsChartsComponent, UserDetailCardComponent,
    ColumnPickerComponent, ToastsComponent, NumberPickerComponent, BanModalComponent,
    UsersRecapComponent, BanInformationComponent, BanSummaryComponent, UsersTableComponent,
    BanMotivationModalComponent, DeleteModalComponent, BanRemoveModalComponent,
    SendNotificationModalComponent, NotificationOptionsComponent, NotificationSummaryComponent,
    RecordedActivitiesComponent, TablePaginationComponent, TableTopbarComponent,
    SelectAudienceComponent, SelectActionsComponent, QrDetailModalComponent, QrCreateModalComponent,
    SelectCompanyComponent, SelectCompetitionComponent, SelectChallengeComponent, SelectPrizeComponent,
    LinkValidatorComponent, NotificationDeliveryComponent, PrizesTableComponent, ChallengesTableComponent,
    CompetitionsTableComponent, CompaniesTableComponent, NotificationAudienceComponent, AutomaticBanRuleModalComponent,
    AddRemoveWhitelistComponent, AddBlacklistSourceModalComponent, UserUsesModalComponent, ImageUploaderComponent,
    AddNewsModalComponent, DeactivateModalComponent, ActivateModalComponent, BanHistoryComponent, UserDiagnosticComponent, DiagnosticTopbarComponent,
    UserFitnessChartsComponent, FitnessMetricPickerComponent, UserChallengesComponent, PaginationFooterComponent, UserRedeemPrizesComponent,
    UserUsedCodesComponent, UserInvitationsSentComponent, UserProfileComponent, AddRemoveCreditsModalComponent, AddStreakTemplateModalComponent,
    AddStreakModalComponent, AddPrizeModalComponent, SelectFaqComponent, AddFaqModalComponent, AddCompanyModalComponent, AddCompetitionModalComponent,
    PrizeCodesModalComponent, CompanyCodesModalComponent, AddFtoModalComponent, ComplexDatePickerComponent, DatepickerComponent,
    SelectBoxPickerComponent, AddRewardModalComponent, ExportResultsModalComponent, DateTimePickerComponent, PostponeModalComponent,
    PrizeListPageComponent,
    IndividualLeaderboardPageComponent,
    TeamLeaderboardPageComponent,
    StringPickerComponent,
    BannedListPageComponent,
    TeamListPageComponent,
    MembershipsListPageComponent,
    CodesListPageComponent,
    AddCompanyTeamsModalComponent,
    EditTeamComponent,
    CompetitionsListPageComponent,
    TextPickerComponent,
    NotificationModalComponent, RecurringEventPickerComponent, PrizeSelectComponent, CompanySelectComponent, CompetitionSelectComponent, TableComponent, DeleteComponent, AddMembershipsModalComponent, RawDataModalComponent, AddPartnerModalComponent, AddOperatorModalComponent, RecommendedAppModalComponent, BenefitModalComponent, HowToSyncModalComponent, ActivityModalComponent, PreconditionsModalComponent, SelectLocaleComponent, FitnessSlotsComponent, WebappModalComponent, AddContentCategoryModalComponent, AddSettingModalComponent, AddContentModalComponent, FileUploaderComponent, AddSustainabilityPartnerComponent, AddSustainabilityProjectComponent, CompaniesStreaksListComponent, AddSustainabilityBenefitComponent, AddSustainabilityPlaceComponent, AddSustainabilityObjectiveComponent, AddDeeplinkModalComponent, DeeplinkActionSelectComponent, FaqSelectComponent, BadgeSelectComponent, AddPrizeCategoryModalComponent, AddPublicContentModalComponent, RecalculateCountersModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TranslateModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    DirectiveModule,
    NgbTooltipModule,
    NgApexchartsModule,
    ArchwizardModule,
    NgbToastModule,
    NgSelectModule,
    CoreModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    ClipboardModule,
    NgbAlertModule,
    UcWidgetModule,
    CKEditorModule,
    NgbProgressbarModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    NgbModule,
    QRCodeModule,
    NgxMaskModule.forRoot(),
    IMaskModule,
    NgxJsonViewerModule,
    NgbCarouselModule,
  ],
  providers: [
    DatePipe
  ],
	exports: [BreadcrumbComponent, DateRangePickerComponent, MissingLabelComponent, DiaryComponent, LoaderComponent, TableSizeComponent,
		FitnessMetricsComponent, DataCardComponent, FitnessMetricsChartsComponent, UserDetailCardComponent, ColumnPickerComponent,
		ToastsComponent, NumberPickerComponent, BanModalComponent, BanMotivationModalComponent, DeleteModalComponent, BanRemoveModalComponent,
		SendNotificationModalComponent, UsersRecapComponent, NotificationOptionsComponent, NotificationSummaryComponent, RecordedActivitiesComponent,
		QrDetailModalComponent, QrCreateModalComponent, SelectActionsComponent, SelectAudienceComponent, SelectCompanyComponent,
		SelectCompetitionComponent, SelectChallengeComponent, NotificationDeliveryComponent, NotificationAudienceComponent,
		AutomaticBanRuleModalComponent, AddRemoveWhitelistComponent, AddBlacklistSourceModalComponent, UsersTableComponent,
		UserUsesModalComponent, AddNewsModalComponent, DeactivateModalComponent, ActivateModalComponent, BanHistoryComponent, UserDiagnosticComponent,
		UserFitnessChartsComponent, TableTopbarComponent, UserChallengesComponent, PaginationFooterComponent, UserRedeemPrizesComponent, UserUsedCodesComponent, UserInvitationsSentComponent, UserProfileComponent, AddRemoveCreditsModalComponent, AddStreakTemplateModalComponent, AddStreakModalComponent, AddPrizeModalComponent, AddFaqModalComponent, AddCompanyModalComponent, AddCompetitionModalComponent, PrizeCodesModalComponent, CompanyCodesModalComponent, AddFtoModalComponent, ComplexDatePickerComponent, SelectBoxPickerComponent, AddRewardModalComponent, ExportResultsModalComponent, PostponeModalComponent, PrizeListPageComponent, IndividualLeaderboardPageComponent, TeamLeaderboardPageComponent, StringPickerComponent, BannedListPageComponent, CodesListPageComponent, TeamListPageComponent, MembershipsListPageComponent, CompetitionsListPageComponent, TextPickerComponent, NotificationModalComponent, TableComponent, PrizeSelectComponent, FitnessSlotsComponent, ImageUploaderComponent, CompaniesStreaksListComponent, SimpleDatepickerComponent, RecalculateCountersModalComponent]
})
export class ComponentsModule {
}

//TODO CREARE N MODULI PER SEZIONE COMPONENTS
