import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {AuthenticationService} from "@service/common/auth.service";
import {USR_ROLES} from "../../configs/roles/roles";
import {UtilsService} from "@service/utils/utils.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  private usrRoles = USR_ROLES;

  constructor(protected readonly router: Router, protected readonly keycloak: KeycloakService,
              private auth: AuthenticationService, private _utilsService: UtilsService) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      const redirect = window.location.origin + state.url;
      await this.keycloak.login({
        redirectUri: redirect
      });
    }
    const allowed = this.keycloak.getUserRoles().some(role => this.usrRoles.includes(role))
    if (this.authenticated && allowed) {
			this._utilsService.getLanguages();
      this.auth.updateCurrentUser();
    } else {
      this.authenticated = false;
    }

    return this.authenticated;
  }
}
