import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InvitesService} from "@service/utility/invites.service";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {SortEventInterface} from "@interface/common/sort-event.interface";

@Component({
  selector: 'app-user-used-codes',
  templateUrl: './user-used-codes.component.html',
  styleUrls: ['./user-used-codes.component.scss']
})
export class UserUsedCodesComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public topBarType = TopbarTypeEnum.INVITES_USES;
  public inviteUses: any = [];

  constructor(public invitesService: InvitesService, private router: Router) {
  }

  ngOnInit(): void {
    this.invitesService.removeSearchListSubscribe();
    this.invitesService.clearFilters();
    this.total$ = this.invitesService.totalRecords$;
    this.loading$ = this.invitesService.loading$;
    this.sort({column: "usedAt", direction: "desc"});
    this.invitesService.userId = this.userId;
    this.invitesService.initSearchUsesListener();
    this.invitesService._searchUses$.next();
    this.invitesService.inviteUsesList$.subscribe((list: any) => {
      this.inviteUses = list;
    })
  }

  public extractRowNumber(index: any): string {
    return (this.invitesService.page - 1) * this.invitesService.pageSize + index + 1;
  }

  public userDetail(invite: any): void {
    if (invite?.inviter) {
      this.router.navigate(['users/detail', String(invite?.inviter?.userId)]);
    }
  }

  public sort({column, direction}: SortEventInterface) {
    this.invitesService.sortColumn = column;
    this.invitesService.sortDirection = direction;
  }

  public extractInviteTooltip(invite: any): string {
    if (invite?.inviter) {
      return 'Inviter detail';
    } else {
      return 'You can\'t see the inviter because there\'s no data'
    }
  }


  ngOnDestroy() {
    this.invitesService.removeSearchUsesSubscribe();
  }

}
