<app-table-topbar [type]="topBarType" [service]="competitionIndividualLeaderboardService" [showColumnPicker]="true"
                  (updateColumnsEvent)="selected = $event"
                  (emitSearchTerm)="competitionIndividualLeaderboardService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="individualLeaderboardList$ | async as individuals" class="wrapper">
    <table *ngIf="individuals?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_POSITION)" class="column-sortable"
            sortable="position">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_POSITION' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_USER_ID)" class="column-sortable"
            sortable="user.id">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_USER_ID' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.LOCALE)" class="column-sortable"
            sortable="user.locale">
          <span class="me-3">{{'LOCALE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_NICKNAME)" class="column-sortable"
            sortable="user.profile.nickname">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_NICKNAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_FIRST_NAME)" class="column-sortable"
            sortable="user.profile.firstName">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_FIRST_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_LAST_NAME)" class="column-sortable"
            sortable="user.profile.lastName">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_LAST_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_EMAIL)" class="column-sortable"
            sortable="user.email">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_EMAIL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_POWER_LEVEL)" class="column-sortable"
            sortable="powerLevel">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_POWER_LEVEL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START)" class="column-sortable"
            sortable="counterProvisionalStart">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_LAST_UPDATE)" class="column-sortable"
            sortable="counterLastUpdate">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_LAST_UPDATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT)" class="column-sortable" sortable="participantCreatedAt">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE)" class="column-sortable" sortable="participantLastUpdate">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_STATUS)" class="column-sortable" sortable="membershipStatus">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_STATUS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="membershipStatus">
          <span class="me-3">{{'BANNED' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_SCORE)" class="column-sortable" sortable="score">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_SCORE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL)" class="column-sortable" sortable="provisional">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED)" class="column-sortable" sortable="consolidated">
          <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let individual of individuals; let i=index;">
        <td (click)="userDetail(individual?.user)" align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_POSITION)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.position" class="badge badge-pill badge-soft-primary">{{individual?.position}}</span>
          <app-missing-label *ngIf="!individual?.position"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_USER_ID)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.user?.id">{{individual?.user?.id}}</span>
          <app-missing-label *ngIf="!individual?.user?.id"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.usermember)" *ngIf="checkIfColumnIsVisible(column.LOCALE)" align="center"
            class="pointer fit-width">
          <img alt="Header Language" class="me-2" height="16" src="{{utils.getSpecificLanguage(individual?.user?.locale)}}">
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_NICKNAME)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.user?.profile?.nickname">{{individual?.user?.profile?.nickname}}</span>
          <app-missing-label *ngIf="!individual?.user?.profile?.nickname"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_FIRST_NAME)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.user?.profile?.firstName">{{individual?.user?.profile?.firstName}} {{individual?.user?.profile?.lastName}}</span>
          <app-missing-label *ngIf="!individual?.user?.profile?.firstName"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_LAST_NAME)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.teamName">{{individual?.teamName}}</span>
          <app-missing-label *ngIf="!individual?.teamName"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_EMAIL)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.user?.email">{{individual?.user?.email}}</span>
          <app-missing-label *ngIf="!individual?.user?.email"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_POWER_LEVEL)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.powerLevel">{{individual?.powerLevel}}</span>
          <app-missing-label *ngIf="!individual?.powerLevel"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.counterProvisionalStart">{{individual?.counterProvisionalStart | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!individual?.counterProvisionalStart"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_LAST_UPDATE)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.counterLastUpdate">{{individual?.counterLastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!individual?.counterLastUpdate"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.participantCreatedAt">{{individual?.participantCreatedAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!individual?.participantCreatedAt"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.participantLastUpdate">{{individual?.participantLastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!individual?.participantLastUpdate"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_STATUS)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.membershipStatus">{{individual?.membershipStatus}}</span>
          <app-missing-label *ngIf="!individual?.membershipStatus"></app-missing-label>
        </td>
        <td (click)="userDetail(individual?.user)" align="center" class="pointer fit-width">
          <span *ngIf="individual?.membershipStatus === 'BANNED'" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="individual?.membershipStatus === 'MEMBER'" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_SCORE)" align="center" class="pointer fit-width">
          <span>{{individual?.score}}</span>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL)" align="center" class="pointer fit-width">
          <span>{{individual?.provisional}}</span>
        </td>
        <td (click)="userDetail(individual?.user)" *ngIf="checkIfColumnIsVisible(column.INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED)" align="center" class="pointer fit-width">
          <span>{{individual?.consolidated}}</span>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="removeUser(individual?.user?.id)" class="list-inline-item px-2">
              <span class="pointer danger" ngbTooltip="Remove this user from competition" placement="left"><i class="bx bx-x"></i></span>
            </li>
            <li (click)="openModal([individual?.user])" *ngIf="individual?.membershipStatus === 'MEMBER'" class="list-inline-item px-2">
                  <span class="pointer warning"
                        ngbTooltip="Ban"
                        placement="bottom"><i
                    class="bx bx bx-block"></i></span>
            </li>
            <li (click)="openUnbanModal([individual?.user])"  *ngIf="individual?.membershipStatus === 'BANNED'" class="list-inline-item px-2">
                    <span ngbTooltip="Unban from competition" class="pointer warning"
                          placement="left"><i
                      class="mdi mdi-close-circle-outline"></i></span>
            </li>
            <li (click)="sendUserNotificationModal([individual?.user?.id])" class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Send push notification"
                        placement="bottom"><i
                    class="bx bx-send"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="individuals?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="competitionIndividualLeaderboardService" [total$]="total$"></app-pagination-footer>
<app-ban-modal #banModal [banType]="banType.COMPETITION"></app-ban-modal>
<app-ban-remove-modal [filters]="filters" #banRemoveModal></app-ban-remove-modal>
