<div class="operation-modal-header">
  <h5 *ngIf="!benefit">{{'CREATE_BENEFIT' | translate}}</h5>
  <h5 *ngIf="benefit && !benefit?.duplicate">{{'EDIT_BENEFIT' | translate}}: {{benefit?.title}}</h5>
  <h5 *ngIf="benefit && benefit?.duplicate">{{'DUPLICATE_BENEFIT' | translate}}: {{benefit?.title}}</h5>
  <div class="d-flex">
    <button (click)="utils.openLink(help)" class="btn close-modal-button close-modal-button-mr-2">
      <i class="mdi mdi-information-outline"></i>
    </button>
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_SUSTAINABILITY_BENEFIT_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="common-modal-description">
  <p class="mb-0">{{'benefitModalDescription' | translate}}</p>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="benefitForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="mb-3 col-12">
        <label class="font-weight-bold mb-0" for="type">{{'TYPE' | translate}}</label>
        <p class="card-title-desc mb-2">Specify the type of the benefit. The basic che be used only for Competitions and the counter can be used only for projects</p>
        <select  class="form-select" formControlName="type" id="type">
          <option *ngFor="let type of types" [value]="type">{{type | translate}}</option>
        </select>
      </div>
      <div class="mb-3 col-12">
        <label class="font-weight-bold mb-0" for="title">{{'TITLE' | translate}} <span class="text-danger" *ngIf="form?.title?.errors?.required">*</span></label>
        <p class="card-title-desc mb-2">
          <span class="d-block">Specify the title of the benefit. It should have at least 5 characters and max 100.</span>
          <span class="d-block" *ngIf="form?.type?.value === 'COUNTER'" class="text-danger">Remember to insert {{'{0}'}} placeholder that will be replaced with objective result * multiplier</span>
        </p>
        <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"  [placeholder]="form?.type?.value === 'COUNTER' ? '{0} KG di CO2 risparmiati' : 'Improve people lifestyle'" formControlName="title" id="title" type="text">
        <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
          <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
          <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>
        </div>
      </div>
      <div class="col-12" *ngIf="form?.type?.value === 'BASIC'">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="description">{{'DESCRIPTION' | translate}} <span class="text-danger" *ngIf="form?.description?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">
            <span class="d-block">Specify the description of the benefit. It will be used to display de deatil of the benefit.</span>
          </p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control"  [placeholder]="'Improving the lifestyle of the people is a mission that...'"
                 formControlName="description" id="description" type="text">
          <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.description?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.description?.errors?.minlength">{{ 'errorMaxLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}</span>
          </div>
        </div>
      </div>
      <div class="mb-3 col-12" *ngIf="form?.type?.value === 'COUNTER'">
        <label class="font-weight-bold mb-0" for="multiplier">{{'MULTIPLIER' | translate}}</label>
        <p class="card-title-desc mb-2">
          <span class="d-block">Specify the multiplier of the benefit. It will be used to calculate the actual result of the benefit. For example, if you use 0.002 as multiplier, the {{'{0}'}} part of the title will be replaced with the current fitness metric result of the objective multiplied per 0.002.</span>
        </p>
        <input class="form-control" [placeholder]="'0.002'" formControlName="multiplier" id="multiplier" type="number">
      </div>
      <div class="col-12">
        <app-select-locale [description]="true" [parentForm]="benefitForm"></app-select-locale>
      </div>
      <div class="col-6" [class.col-12]="form?.type?.value === 'COUNTER'">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedIcon()"
                              [limit]="iconLimit"
                              [name]="'ICON'"
                              [imageErrors]="checkIconErrors()"
                              [showImagePreview]="true"
                              (imageUploaded)="setIcon($event)"></app-image-uploader>
        </div>
      </div>
      <div class="col-6" *ngIf="form?.type?.value === 'BASIC'">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedImage()"
                              [name]="'DETAIL_IMAGE'"
                              [limit]="imageLimit"
                              [imageErrors]="checkImageErrors()"
                              [showImagePreview]="true"
                              (imageUploaded)="setImage($event)"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !benefit " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && benefit && benefit?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && benefit && !benefit?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !benefit">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && benefit && !benefit?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && benefit && benefit?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
