import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class CompaniesApiService {

  constructor(private http: HttpService) {
  }

  public getCompanyList(filters: any, paged?: boolean): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    if (paged) {
      return this.http.get<any>(endpoints.companies.paged, params);
    } else {
      return this.http.get<any>(endpoints.companies.list, params);
    }
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.analytics, params);
  }

  public getUserCompanies(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.memberships, params);
  }

  public getCompanyData(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.detail, placeholders);
    return this.http.get(url, null);
  }

  public addCompanyTeams(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.companyId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.addTeams, placeholders);
    return this.http.post(url, filters);
  }

  public getWebapp(id: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.webapp, placeholders);
    return this.http.get(url, null);
  }

  public activateEditWebapp(id: any, config: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.webapp, placeholders);
    return this.http.post(url, config);
  }

  public editCompanyTeams(filters: any): Observable<any> {
    const placeholders = [{
      placeholder: EndpointParamEnum.ID,
      value: filters?.companyId
    }, {placeholder: EndpointParamEnum.PID, value: filters?.teamId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.editTeams, placeholders);
    return this.http.put(url, filters);
  }


  public getCompanyInviteCodes(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.codes, params);
  }

  public getCompanyMemberships(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.memberships, params);
  }
	
	public removeUser(user: any): Observable<any> {
		const placeholders = [{
			placeholder: EndpointParamEnum.ID,
			value: user?.companyId
		}, {placeholder: EndpointParamEnum.PID, value: user?.id}];
		const url = this.http.findAndReplacePlaceholders(endpoints.companies.removeUser, placeholders);
		return this.http.post(url, {});
	}

  public addMemberships(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.company?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.addMemberships, placeholders);
    return this.http.post(url, params);
  }

  public validateMembershipEmail(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.company?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.validateMembership, placeholders);
    return this.http.post(url, {codeEmail: params?.corporate?.uniqueCode, forceValidation: true});
  }

  public activateDeactivateCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(params?.active ? endpoints.invites.enable : endpoints.invites.disable, placeholders);
    return this.http.post(url, params);
  }

  public deactivateCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.invites.disable, placeholders);
    return this.http.post(url, params);
  }

  public deleteCode(code: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: code[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.invites.delete, placeholders);
    return this.http.delete(url, code);
  }

  public deleteTeam(team: any): Observable<any> {
    const placeholders = [{
      placeholder: EndpointParamEnum.ID,
      value: team?.companyId
    }, {placeholder: EndpointParamEnum.PID, value: team?.team?.teamId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.deleteTeam, placeholders);
    return this.http.delete(url, {body: {companyId: team?.companyId}});
  }

  public getCompanyTeams(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.companies.teams, params);
  }

  public getCompanyTeamsMembers(companyId: any, teamId: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: teamId}, {
      placeholder: EndpointParamEnum.PID,
      value: companyId
    }];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.usersInTeam, placeholders);
    return this.http.get<any>(url);
  }

  public getCompanyMembershipsStreaks(companyId: any, filters?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: companyId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.membershipsStreaks, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public addCompanyCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.companyId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.addCodes, placeholders);
    return this.http.post(url, params);
  }

  public createEditCompany(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.companies.edit, placeholders);
      params.id = null;
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.companies.create, params);
    }
  }

  public deleteCompany(faqs: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: faqs[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.companies.delete, placeholders);
    return this.http.delete(url, faqs);
  }
	
	public addLanguages(languages: string[], companyCode: string): Observable<any> {
		const params = {
			languages,
			companyCode
		}
		return this.http.post(endpoints.utility.addLanguages, params);
		
	}
	
	public removeLanguages(language: string[], companyCode: string): Observable<any> {
		const params = {
			language,
			companyCode
		}
		const url = endpoints.utility.removeLanguages + `?companyCode=${companyCode}&language=${language}`;
		console.log(url)
		return this.http.delete(url,{});
	}
}
