import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbDate, NgbDatepickerConfig, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-simple-datepicker',
  templateUrl: './simple-datepicker.component.html',
  styleUrls: ['./simple-datepicker.component.scss']
})
export class SimpleDatepickerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  model: NgbDateStruct;
  @Input() name: string = '';
  @Input() selectedDate?: SimpleChange | Date;
  @Input() minDateLock: boolean;
  @Input() isInvalid?: boolean;
  @Input() selectedStartDate: any;
  @Input() selectedEndDate: any;
  @Input() specificWeekday: string = '';
  @Output() dateSelected: EventEmitter<string> = new EventEmitter();
  @Output() resetEndDate: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('date') dp;

  constructor(private config: NgbDatepickerConfig, private _eref: ElementRef, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const inputElement = document.getElementById(`datepicker${this.specificWeekday}`) as HTMLInputElement
    inputElement.value = new Date().toISOString();
    if (this.minDateLock && this.dp) {
      const current = new Date();
      this.dp.minDate = {year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate()};
      this.dp.outsideDays = 'hidden';
      if (this.specificWeekday !== '') {
        this.dp.markDisabled = (date: NgbDate) => {
          return this.getDayOfWeek(new Date(date.year, date.month - 1, date.day)) !== this.specificWeekday;
        };
      }
    }
    this.checkSetDate();
  }

  public reset() {
    this.dateSelected.emit(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dp) {
      this.dp.maxDate = null;
      this.dp.outsideDays = 'hidden';
      if (this.selectedStartDate) {
        const startDate = this.convertSpecificDate(this.selectedStartDate);
        this.dp.minDate = {year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate()};
        this.dp.outsideDays = 'hidden';
      }
      if (this.selectedEndDate && !this.selectedStartDate) {
        const endDate = this.convertSpecificDate(this.selectedEndDate);
        this.dp.minDate = {year: endDate.getFullYear(), month: endDate.getMonth() + 1, day: endDate.getDate()};
        this.dp.outsideDays = 'hidden';
      }
      if (this.name === 'Sunday' && !this.selectedEndDate) {
        this.dateSelected.emit(null);
        this.dp._model = undefined;
        this.model = undefined;
      }
      if (this.name === 'Monday') {
        const current = new Date();
        this.dp.minDate = {year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate()};
        this.dp.outsideDays = 'hidden';
      }
      if (this.selectedEndDate && this.selectedStartDate) {
        const startDate = this.convertSpecificDate(this.selectedStartDate);
        const endDate = this.convertSpecificDate(this.selectedEndDate);
      }
      if (changes && changes?.selectedDate?.currentValue && !changes?.selectedDate?.previousValue) {
        this.selectedDate = changes?.selectedDate?.currentValue;
        this.checkSetDate();
      }
    } else if (this.selectedEndDate && this.selectedStartDate) {
      const startDate = this.convertSpecificDate(this.selectedStartDate);
      if (startDate && this.name === 'Monday') {
        this.model = {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate()
        };
        this.cdr.detectChanges();
      }
      const endDate = this.convertSpecificDate(this.selectedEndDate);
      if (endDate && this.name === 'Sunday') {
        this.model = {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate()
        };
        this.cdr.detectChanges();
      }
    } else if (this.selectedDate) {
      const startDate = this.convertSpecificDate(this.selectedDate);
      this.model = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate()
      };
      this.cdr.detectChanges();
    }
  }

  public getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
  }

  checkChanges(date: NgbDateStruct) {
    const finalDate = new Date(date.year, date.month - 1, date.day);
    this.dateSelected.emit(finalDate.toISOString());
  }

  ngOnDestroy() {
    this.config.minDate = null;
    this.config.outsideDays = 'visible';
  }

  private checkSetDate() {
    if (this.selectedDate && this.selectedDate instanceof Date) {
      this.model = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth() + 1,
        day: this.selectedDate.getDate()
      };
      this.cdr.detectChanges();
    }
  }

  private convertSpecificDate(selectedDate) {
    if (selectedDate && (selectedDate instanceof Date)) {
      return selectedDate
    } else if (selectedDate && (selectedDate instanceof String || typeof selectedDate === 'string')) {
      const newDate = selectedDate.replace(/(\d+[/])(\d+[/])/, '$2$1');
      return new Date(newDate);
    }
  }


}
