import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsSettingsApiService {

  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.notification.settings.list, params);
  }

  public edit(data: any): Observable<any> {
    return this.http.post(endpoints.notification.settings.edit, data);
  }

}
