export enum EventEnum {
  CLOSE_BAN_MODAL = 'CLOSE_BAN_MODAL',
  CLOSE_REMOVE_BAN_MODAL = 'CLOSE_REMOVE_BAN_MODAL',
  CLOSE_CREATE_BAN_MOTIVATION_MODAL = 'CLOSE_CREATE_BAN_MOTIVATION_MODAL',
  REMOVE_USER_FROM_BAN_LIST = 'REMOVE_USER_FROM_BAN_LIST',
  REMOVE_USER_FROM_DELETE_LIST = 'REMOVE_USER_FROM_DELETE_LIST',
  CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL',
  CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL',
  CLOSE_QR_DETAIL_MODAL = 'CLOSE_QR_DETAIL_MODAL',
  CLOSE_QR_CREATE_MODAL = 'CLOSE_QR_CREATE_MODAL',
  REMOVE_USER_FROM_NOTIFICATION_LIST = 'REMOVE_COMPANY_FROM_NOTIFICATION_LIST',
  REMOVE_COMPANY_FROM_NOTIFICATION_LIST = 'REMOVE_COMPANY_FROM_NOTIFICATION_LIST',
  REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST = 'REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST',
  REMOVE_COMPETITION_FROM_NOTIFICATION_LIST = 'REMOVE_COMPETITION_FROM_NOTIFICATION_LIST',
  CLOSE_AUTOMATIC_BAN_RULE_MODAL = 'CLOSE_AUTOMATIC_BAN_RULE_MODAL',
  CLOSE_ADD_REMOVE_WHITELIST_MODAL = 'CLOSE_ADD_REMOVE_WHITELIST_MODAL',
  CLOSE_ADD_BLACKLIST_SOURCE_MODAL = 'CLOSE_ADD_BLACKLIST_SOURCE_MODAL',
  CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL = 'CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL',
  CLOSE_ADD_NEWS_MODAL = 'CLOSE_ADD_NEWS_MODAL',
  CLOSE_DEACTIVATE_MODAL = 'CLOSE_DEACTIVATE_MODAL',
  CLOSE_ACTIVATE_MODAL = 'CLOSE_ACTIVATE_MODAL',
  CLOSE_ADD_REMOVE_CREDITS = 'CLOSE_ADD_REMOVE_CREDITS',
  CLOSE_CREATE_STREAK_TEMPLATE = 'CLOSE_CREATE_STREAK_TEMPLATE',
  CLOSE_CREATE_STREAK = 'CLOSE_CREATE_STREAK',
  CLOSE_EDIT_STREAK_TEMPLATE = 'CLOSE_EDIT_STREAK_TEMPLATE',
  CLOSE_EDIT_STREAK = 'CLOSE_EDIT_STREAK',
  CLOSE_CREATE_PRIZE = 'CLOSE_CREATE_PRIZE',
  CREATE_PRIZE_BUG = 'CREATE_PRIZE_BUG',
  CLOSE_CREATE_FAQ = 'CLOSE_CREATE_FAQ',
  CLOSE_CREATE_COMPANY = 'CLOSE_CREATE_COMPANY',
  CLOSE_CREATE_COMPETITION = 'CLOSE_CREATE_COMPETITION',
  CLOSE_CREATE_COMPETITION_BUG = 'CLOSE_CREATE_COMPETITION_BUG',
  CLOSE_PRIZE_CODES_MODAL = 'CLOSE_PRIZE_CODES_MODAL',
  CLOSE_COMPANY_CODES_MODAL = 'CLOSE_COMPANY_CODES_MODAL',
  CLOSE_COMPANY_TEAMS_MODAL = 'CLOSE_COMPANY_TEAMS_MODAL',
  CLOSE_CREATE_FTO = 'CLOSE_CREATE_FTO',
  UPDATE_FILTER_MENU = 'UPDATE_FILTER_MENU',
  UPDATE_FILTER_SERVICE = 'UPDATE_FILTER_SERVICE',
  CLOSE_CREATE_REWARD = 'CLOSE_CREATE_REWARD',
  CLOSE_EXPORT_MODAL = 'CLOSE_EXPORT_MODAL',
  SHOW_DUPLICATE_PRIZES = 'SHOW_DUPLICATE_PRIZES',
  CLOSE_ADD_MEMBERSHIPS_MODAL = 'CLOSE_ADD_MEMBERSHIPS_MODAL',
  CLOSE_RAW_DATA_MODAL = 'CLOSE_RAW_DATA_MODAL',
  CLOSE_PARTNER_MODAL = 'CLOSE_PARTNER_MODAL',
  CLOSE_OPERATOR_MODAL = 'CLOSE_OPERATOR_MODAL',
  CLOSE_RECOMMENDED_APP_MODAL = 'CLOSE_RECOMMENDED_APP_MODAL',
  CLOSE_BENEFIT_APP_MODAL = 'CLOSE_BENEFIT_APP_MODAL',
  CLOSE_WEBAPP_MODAL = 'CLOSE_WEBAPP_MODAL',
  CLOSE_CONTENT_CATEGORY_MODAL = 'CLOSE_CONTENT_CATEGORY_MODAL',
  CLOSE_NOTIFICATION_SETTING_MODAL = 'CLOSE_NOTIFICATION_SETTING_MODAL',
  CLOSE_CONTENT_MODAL = 'CLOSE_CONTENT_MODAL',
  CLOSE_SUSTAINABILITY_PARTNER_MODAL = 'CLOSE_SUSTAINABILITY_PARTNER_MODAL',
  CLOSE_SUSTAINABILITY_PROJECT_MODAL = 'CLOSE_SUSTAINABILITY_PROJECT_MODAL',
  CLOSE_SUSTAINABILITY_PLACE_MODAL = 'CLOSE_SUSTAINABILITY_PLACE_MODAL',
  CLOSE_SUSTAINABILITY_BENEFIT_MODAL = 'CLOSE_SUSTAINABILITY_BENEFIT_MODAL',
  CLOSE_SUSTAINABILITY_OBJECTIVE_MODAL = 'CLOSE_SUSTAINABILITY_OBJECTIVE_MODAL',
  CLOSE_DEEPLINK_MODAL = 'CLOSE_DEEPLINK_MODAL',
  DEEPLINK_CREATION_COMPLETED = 'DEEPLINK_CREATION_COMPLETED',
	BADGE_MODAL = 'BADGE_MODAL',
	PRIZE_CATEGORY_MODAL = 'PRIZE_CATEGORY_MODAL'
}
