import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableSortingDirective} from "@app/directives/common/table-sorting.directive";
import {TableResizableDirective} from "@app/directives/common/table-resizable.directive";
import {CloseModalDirective} from "@app/directives/common/close-modal.directive";
import {FullUserDirective} from "@app/directives/common/full-user.directive";
import {PlaceholderValidatorDirective} from "@app/directives/common/placeholder-validator.directive";


@NgModule({
  declarations: [TableSortingDirective, TableResizableDirective, CloseModalDirective, FullUserDirective, PlaceholderValidatorDirective],
  imports: [
    CommonModule
  ],
  exports: [TableSortingDirective, TableResizableDirective, CloseModalDirective, FullUserDirective, PlaceholderValidatorDirective]
})
export class DirectiveModule {
}
