import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {Observable, Subscription} from "rxjs";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {CompetitionsService} from "@service/competitions/competitions.service";
import {UntypedFormGroup} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-select-competition',
  templateUrl: './select-competition.component.html',
  styleUrls: ['./select-competition.component.scss']
})
export class SelectCompetitionComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Output() selectedCompetition = new EventEmitter();
  @Input() selectedAudience: any;
  @Input() single: boolean;
  @Input() users: any;
  @Input() editDetail: any;
  @Input() formSubmitted: boolean;
  @Input() pageFilter: boolean;
  @Input() pageFilterErrors: boolean;
  @Input() hideDescription: boolean;
  @Input() error: boolean;
  @Input() basicSelect: boolean;
  @Input() selectedItems: any;
  @Input() parentForm: UntypedFormGroup;
  public subscription: Subscription;
  public filtered: any;
  public selected: any;
  public list$: Observable<any[]>;
  public JSON = JSON;

  constructor(private competitionsService: CompetitionsService, private utils: UtilsService) {
  }

  get form() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm?.controls['actionDetail']?.disabled) {
      this.parentForm.controls['actionDetail'].enable();
    }
    if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_COMPANY && this.users?.data?.companies?.length > 0) {
      this.filtered = this.users?.data?.companies[0]?.name;
      this.competitionsService.initSearchCompetitionsListener(this.users?.data?.companies[0]?.id);
    } else {
      this.competitionsService.initSearchCompetitionsListener();
    }
    this.competitionsService.pageSize = 1000;
    this.list$ = this.competitionsService.competitionList$;
    this.subscription = this.list$.subscribe((data: any) => {
      if (data?.length > 0) {
        data = data?.map((el) => {
          if (el?.company?.name) {
            el.bindLabel = [el?.competitionName, el?.company?.name].join(' - ');
          } else {
            el.bindLabel = el?.competitionName;
          }
          return el;
        });
      }
      if (data?.length > 0 && this.selectedItems?.length > 0) {
        const result = this.utils.extractSelectedItems(data, this.selectedItems);
        if (result && result?.length > 0) {
          this.selectedItems = Array.from(new Set(result.map(a => a.id))).map(id => {
            return result.find(a => a.id === id)
          });
          if (this.selectedItems && this.selectedItems?.length > 0) {
            this.selected = this.selectedItems[0];
            this.selectCompetitions(this.selectedItems[0]);
          }
        }
      }
    });
    if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_COMPETITION && this.users?.data?.competitions?.length > 0) {
      this.parentForm.patchValue({['actionDetail']: this.users?.data?.competitions[0]});
      this.parentForm.controls['actionDetail'].disable();
    }
    if (this.selectedItems && this.selectedItems?.length > 0) {
      this.selected = this.selectedItems[0];
    }
    if (!!this.editDetail && this.editDetail?.id && !this.selected) {
      this.selected = this.editDetail;
      this.parentForm.patchValue({['actionDetail']: this.selected});
    } else if (!!this.editDetail && !this.selected) {
      this.subscription = this.list$.subscribe((data: any) => {
        const selected = data?.find((el) => el?.id === Number(this.editDetail));
        if (selected) {
          this.selected = selected;
          this.parentForm.patchValue({['actionDetail']: this.selected});
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const audience: AudienceEnum = changes?.selectedAudience?.currentValue;
    if (changes?.selectedItems?.currentValue && changes?.selectedItems?.currentValue[0]) {
      this.selected = changes?.selectedItems?.currentValue[0];
    } else {
      this.selected = undefined;
    }
  }

  public selectCompetitions(event: any): void {
    if (this.pageFilter) {
      if (event) {
        this.selectedCompetition.emit(JSON.stringify(event));
      } else if (!this.selected) {
        this.selectedCompetition.emit(null);
      }
    } else {
      if (event) {
        this.selectedCompetition.emit([event]);
      } else {
        this.selectedCompetition.emit(null);
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.selectedItems = null;
    this.selected = null;
    this.competitionsService.removeSearchCompetitionsSubscribe();
  }

}
