export enum TopbarTypeEnum {
  BAN_MOTIVATIONS = 'BAN_MOTIVATIONS',
  BANNED_LIST = 'BANNED_LIST',
  USER_LIST = 'USER_LIST',
  WHITE_LIST = 'WHITE_LIST',
  SCHEDULED_NOTIFICATIONS = 'SCHEDULED_NOTIFICATIONS',
  CALENDAR_NOTIFICATIONS = 'CALENDAR_NOTIFICATIONS',
  QR_CODES = 'QR_CODES',
  AUTOMATIC_BAN = 'AUTOMATIC_BAN',
  BLACKLIST_SOURCES = 'BLACKLIST_SOURCES',
  NEWS_LIST = 'NEWS_LIST',
  DIAGNOSTIC_COMPLETE = 'DIAGNOSTIC_COMPLETE',
  DIAGNOSTIC_FITNESS_CHARTS = 'DIAGNOSTIC_FITNESS_CHARTS',
  DIAGNOSTIC_RECORDED_ACTIVITIES = 'DIAGNOSTIC_RECORDED_ACTIVITIES',
  INVITES = 'INVITES',
  INVITES_USES = 'INVITES_USES',
  PRIZE_LIST = 'PRIZE_LIST',
  PRIZE_LIST_PAGE_DETAIL = 'PRIZE_LIST_PAGE_DETAIL',
  CHALLENGE_LIST = 'CHALLENGE_LIST',
  COMPANY_LIST = 'COMPANY_LIST',
  COMPETITION_LIST = 'COMPETITION_LIST',
  FAQS_LIST = 'FAQS_LIST',
  STREAK_TEMPLATES = 'STREAK_TEMPLATES',
  STREAKS = 'STREAKS',
  REWARDS = 'REWARDS',
  FTO = 'FTO',
  COMPETITION_TEAM_LEADERBOARD = 'COMPETITION_TEAM_LEADERBOARD',
  COMPETITION_INDIVIDUAL_LEADERBOARD = 'COMPETITION_INDIVIDUAL_LEADERBOARD',
  COMPANY_COMPETITION_BANS = 'COMPANY_COMPETITION_BANS',
  COMPANY_TEAMS = 'COMPANY_TEAMS',
  COMPANY_MEMBERSHIPS = 'COMPANY_MEMBERSHIPS',
  COMPANY_CODES = 'COMPANY_CODES',
  NOTIFICATIONS = 'NOTIFICATIONS',
  RECOMMENDED_APPS = 'RECOMMENDED_APPS',
  BENEFIT = 'BENEFIT',
  ACTIVITIES = 'ACTIVITIES',
  SPORT = 'SPORT',
  RAW_DATA = 'RAW_DATA',
  PARTNERS = 'PARTNERS',
  OPERATORS = 'OPERATOR',
  HOW_TO_SYNC = 'HOW_TO_SYNC',
  COMPANIES_ANALYTICS = 'COMPANIES_ANALYTICS',
  FITNESS_SLOTS = 'FITNESS_SLOTS',
  CONTENT_CATEGORIES = 'CONTENT_CATEGORIES',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  CONTENT_LIST = 'CONTENT_LIST',
  SUSTAINABILITY_PARTNERS = 'SUSTAINABILITY_PARTNERS',
  SUSTAINABILITY_PROJECTS = 'SUSTAINABILITY_PROJECTS',
  SUSTAINABILITY_PLACES = 'SUSTAINABILITY_PLACES',
	BADGES = 'BADGES',
	PRIZE_CATEGORIES = 'PRIZE_CATEGORIES',
}
