import {Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {UserDiagnosticService} from "@service/users/user-diagnostic.service";

@Component({
  selector: 'app-user-diagnostic',
  templateUrl: './user-diagnostic.component.html',
  styleUrls: ['./user-diagnostic.component.scss']
})
export class UserDiagnosticComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  public options: any = this.userDiagnosticService.getDefaultDiagnosticApexOptions(this.diagnosticHeight());
  public dates: Date[] = [];
  public series: any = [{data: []}];
  private chart: ApexCharts;

  constructor(private userDiagnosticService: UserDiagnosticService) {
  }

  ngOnInit(): void {
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !!date[2]) {
      this.dates = [date[1], date[2]];
    } else {
      this.dates = [];
    }
    this.search();
  }

  ngOnDestroy() {
    this.userId = undefined;
    this.dates = [];
    this.options = undefined;
    this.chart.destroy();
  }

  private search() {
    if (!this.chart) {
      this.chart = new ApexCharts(document.querySelector("#diagnostic-chart"), this.options);
      this.chart.render().then((result) => {
        this.updateSeries();
      }).catch((err) => {
      });
    } else {
      this.updateSeries();
    }
  }

  private updateSeries() {
    if (this.dates?.length === 2 && this.userId) {
      this.userDiagnosticService.getUserDiagnostic(this.dates[0], this.dates[1], String(this.userId)).subscribe((result: any) => {
        let activities = this.userDiagnosticService.extractFormattedActivities(result);
        let finalArray = [];
        activities.map((activity: any) => {
          finalArray = finalArray.concat(activity?.data);
          return activity;
        });
        document.getElementById("user-chart").style.width = "99%";
        setTimeout(() => {
          this.chart.updateSeries([{data: finalArray}])
          document.getElementById("user-chart").style.width = "100%";
        }, 100);
      });
    }
  }

  private diagnosticHeight(): number {
    const elements = Array.from(document.getElementsByClassName('tab-content') as HTMLCollectionOf<HTMLElement>)
    const height = elements[0]?.offsetHeight - 70;
    return height;
  }
}
