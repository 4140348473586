import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {CompaniesApiService} from "@service/companies/companies-api.service";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {AddMembershipsModalComponent} from "@component/companies/add-memberships-modal/add-memberships-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class CompaniesMembershipsService {
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.companyMembershipsTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.companyMembershipsTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchMemberships: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private companiesApiService: CompaniesApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService, private modalService: NgbModal) {
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  private _adding$ = new BehaviorSubject<boolean>(false);

  public get adding$() {
    return this._adding$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  public get working$() {
    return this._working$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _memberships$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get memberships$() {
    return this._memberships$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public get companyId() {
    return this._currentTableState.companyId;
  }

  public set companyId(companyId: string) {
    this._setValue({companyId});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get updatedAt() {
    return this._currentTableState.updatedAt;
  }

  set updatedAt(updatedAt: string[]) {
    this._setValue({updatedAt});
  }

  get code() {
    return this._currentTableState.email;
  }

  set code(email: string) {
    this._setValue({email});
  }

  get banned() {
    return this._currentTableState.banned;
  }

  set banned(banned: boolean | undefined | null) {
    this._setValue({banned});
  }

  public removeSearchMembershipsListener(): void {
    this.searchMemberships?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchMembershipsListener(): void {
    this.searchMemberships = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.companiesApiService.getCompanyMemberships(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._memberships$.next(result?.data);
      this._totalRecords$.next(result?.size);
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.createdAt = undefined;
    this.updatedAt = undefined;
    this.banned = undefined;
    this.code = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.companiesApiService.getCompanyMemberships(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  public addUsersModal(company?: any, users?: any): void {
    const edit = this.modalService.open(AddMembershipsModalComponent, {size: 'lg', centered: true, backdrop: false});
    edit.componentInstance.company = company;
    edit.componentInstance.users = users;
    edit.result.then((result) => {
    }, (reason) => {
    });
    const close = this.eventService.subscribe(EventEnum.CLOSE_ADD_MEMBERSHIPS_MODAL, (reason: string | undefined) => {
      edit.dismiss();
    });
  }
	
	public deactivate(user: any, modal: any): any {
		console.log(user)
		this._working$.next(true);
		this.companiesApiService.removeUser(user).subscribe((result) => {
			if (result?.length > 0) {
				result.map((error) => {
					this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
					return error;
				});
			} else {
				this.toastService.show('User removed from company', {classname: 'bg-success text-light'});
			}
			this._working$.next(false);
			this._search$.next();
			this.eventService.broadcast(modal, null)
		}, (error => {
			this.toastService.show(error, {classname: 'bg-danger text-light'});
			this._working$.next(false);
			this._search$.next();
			this.eventService.broadcast(modal, null)
		}));
	}
	
	
	public addUsers(data: any): any {
    this._adding$.next(true);
    this.companiesApiService.addMemberships(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (result) {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show('Memberships added', {classname: 'bg-success text-light'});
      }
      this._adding$.next(false);
      this.eventService.broadcast(EventEnum.CLOSE_ADD_MEMBERSHIPS_MODAL, null)
    });
  }

  public validate(data: any, modal): any {
    this._working$.next(true);
    this.companiesApiService.validateMembershipEmail(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (result) {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show('Validated', {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this.eventService.broadcast(modal, null)
    });
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        code: this.code ? ["CONTAINS", "IGNORE_CASE", this.code] : undefined,
        lastUpdate: this.updatedAt && this.updatedAt?.length > 0 ? this.updatedAt : undefined,
        status: (this.banned !== undefined && this.banned !== null) ? this.banned === true ? ['BANNED'] : ['INVITEE', 'MEMBER', 'REPUDIATED', 'INVITE_REFUSED', 'PASSER_BY'] : undefined,
      },
      companyId: (this.companyId !== undefined && this.companyId !== null) ? this.companyId : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
