import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-select-locale',
  templateUrl: './select-locale.component.html',
  styleUrls: ['./select-locale.component.scss']
})
export class SelectLocaleComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() description?: boolean;
  public locales = this.utils.languages?.map((lang) => lang?.value);

  constructor(public utils: UtilsService) {
  }

  ngOnInit(): void {
  }

}
