<div class="operation-modal-header">
  <h5 *ngIf="!content">{{'CREATE_CONTENT' | translate}}</h5>
  <h5 *ngIf="content">{{'EDIT_CONTENT' | translate}}: {{content?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(creatingContent$ | async)" class="btn close-modal-button" closeModal="CLOSE_CONTENT_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="contentForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column" ngbNav>
          <li (click)="activeId = 1" [ngbNavItem]="1">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('BASE') || checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block">Base and presentational</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                               formControlName="title" id="title" type="text">
                        <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
                          <span
                              *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span
                              *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                          <span
                              *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}
                            .</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="active">Active</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="active"
                             id="active" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.active?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}"
                           class="mb-3">
                        <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                        <ckeditor [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }" [editor]="Editor"
                                  data="<p>Description of the prize.</p>"
                                  formControlName="description"></ckeditor>
                        <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback d-block">
                          <span
                              *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span
                              *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                          <span
                              *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}
                            .</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-3">
                        <div [ngClass]="{'col-12': !cardImage}" class="col-12">
                          <app-image-uploader (imageUploaded)="setCardImage($event)"
                                              [imageErrors]="checkCardImageError()"
                                              [limit]="cardImageLimit"
                                              [name]="'CARD_IMAGE'"
                                              [previousSelectedImage]="checkSelectedCardImage()"
                                              [showImagePreview]="true"></app-image-uploader>

                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row mb-3">
                        <div [ngClass]="{'col-12': !detailImage}" class="col-12">
                          <app-image-uploader (imageUploaded)="setDetailImage($event)"
                                              [imageErrors]="checkDetailImageError()"
                                              [limit]="detailImageLimit"
                                              [name]="'DETAIL_IMAGE'"
                                              [previousSelectedImage]="checkSelectedDetailImage()"
                                              [showImagePreview]="true"></app-image-uploader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li (click)="activeId = 2" [ngbNavItem]="2">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('TYPE')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block">Type & Content</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="type">{{'TYPE' | translate}}</label>
                    <select (change)="checkType()" class="form-select" formControlName="type" id="type">
                      <option *ngFor="let contentType of contentTypes"
                              [value]="contentType">{{contentType | translate}}</option>
                    </select>
                    <div *ngIf="formSubmitted && form?.type?.errors" class="invalid-feedback">
                      <span
                          *ngIf="formSubmitted && form?.type?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="category">{{'CATEGORY' | translate}}</label>
                    <select [ngClass]="{'is-invalid-select': formSubmitted && form?.category?.errors}" class="form-select" formControlName="category"
                            id="category">
                      <option *ngFor="let category of categories"
                              [value]="category">{{category?.title | translate}}</option>
                    </select>
                    <div *ngIf="formSubmitted && form?.category?.errors" class="invalid-feedback d-block">
                      <span
                          *ngIf="formSubmitted && form?.category?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <ng-container *ngIf="form?.type?.value === ContentTemplate.PDF">
                    <app-file-uploader (fileUploaded)="uploadFile($event)"
                                       [previousSelectedFile]="checkSelectedPdfFile()"></app-file-uploader>
                  </ng-container>
                  <ng-container *ngIf="form?.type?.value === ContentTemplate.VIDEO">
                    <label class="font-weight-bold" for="dataContent">{{'VIDEO_URL' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.dataContent?.errors}" class="form-control"
                           formControlName="dataContent" id="dataContent" type="text">
                    <div *ngIf="formSubmitted && form?.dataContent?.errors" class="invalid-feedback">
                      <span
                          *ngIf="formSubmitted && form?.dataContent?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="formSubmitted && form?.dataContent?.errors?.pattern">Please insert a valid url</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="form?.type?.value === ContentTemplate.BLOG_ARTICLE">
                    <div [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}"
                         class="mb-3">
                      <label class="font-weight-bold" for="dataContent">{{'DESCRIPTION' | translate}}</label>
                      <div *ngIf="formSubmitted && form?.dataContent?.errors" class="invalid-feedback d-block">
                        <span
                            *ngIf="formSubmitted && form?.dataContent?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span
                            *ngIf="form?.dataContent?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.dataContent?.errors?.maxlength?.requiredLength} }}</span>
                        <span
                            *ngIf="form?.dataContent?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.dataContent?.errors?.minlength?.requiredLength} }}
                          .</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="form?.type?.value === ContentTemplate.LANDING_PAGE"
                                class="landing-step-container">
                    <div class="row landing-step-container" formArrayName="dataContentLanding">
                      <span (click)="addLandingStep()" class="btn btn-primary me-2">Add step</span>
                      <div *ngFor="let group of landingSteps.controls; let index = index; let first = first; let last = last;"
                           [formGroupName]="index"
                           class="col-12 step-container">
                        <div class="step p-20">
                          <div class="row">
                            <div class="col-2 mb-3">
                              <label class="font-weight-bold" for="numbering">{{'POSITION' | translate}}</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && group.controls['numbering']?.errors}" class="form-control" formControlName="numbering" id="numbering"
                                     type="number">
                              <div *ngIf="formSubmitted && group.controls['numbering']?.errors"
                                   class="invalid-feedback d-block">
                                <span
                                    *ngIf="formSubmitted && group.controls['numbering']?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="col-8">
                              <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && group.controls['title']?.errors}" class="form-control" formControlName="title" id="title"
                                     type="text">
                              <div *ngIf="formSubmitted && group.controls['title']?.errors"
                                   class="invalid-feedback d-block">
                                <span
                                    *ngIf="formSubmitted && group.controls['title']?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="font-weight-bold"
                                     for="imagePosition">{{'IMAGE_POSITION' | translate}}</label>
                              <select class="form-select" formControlName="imagePosition" id="imagePosition">
                                <option *ngFor="let position of imagePositions"
                                        [value]="position">{{position | translate}}</option>
                              </select>
                            </div>
                            <div class="col-12 mb-3">
                              <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
                              <textarea [ngClass]="{'is-invalid': formSubmitted && group.controls['description']?.errors}" class="form-control description-textarea"
                                        formControlName="description" id="description"
                                        rows="4"></textarea>
                              <div *ngIf="formSubmitted && group.controls['description']?.errors"
                                   class="invalid-feedback d-block">
                                <span
                                    *ngIf="formSubmitted && group.controls['description']?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="col-12 mb-3">
                              <app-image-uploader (imageUploaded)="setImage($event, group)"
                                                  [limit]="undefined"
                                                  [multiple]="false"
                                                  [previousSelectedImage]="checkSelectedGroupImage(group)"
                                                  [showImagePreview]="true"></app-image-uploader>
                            </div>
                          </div>
                          <span (click)="removeLandingStep(index)" *ngIf="!first"
                                class="btn btn-secondary">Remove</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="formSubmitted && form.dataContentLanding?.errors" class="invalid-feedback d-block">
                      <span
                          *ngIf="formSubmitted && form.dataContentLanding?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </li>
          <li (click)="activeId = 3" [ngbNavItem]="3">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block line-height-1-2">Restrictions & Translation & Locales</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div [ngClass]="{'col-12': noSpecificRestriction(), 'col-6': !noSpecificRestriction()}" class="">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="restriction">Restrict content to</label>
                    <select (change)="checkRestrictionValidators($event)" [(ngModel)]="selectedContentRestriction" [ngModelOptions]="{standalone: true}"
                            class="form-select" id="restriction">
                      <option *ngFor="let restriction of contentRestriction"
                              [value]="restriction">{{restriction | translate}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="selectedContentRestriction !== ContentAudience.ALL_USERS" class="col-6">
                  <ng-container *ngIf="selectedContentRestriction === ContentAudience.SPECIFIC_COMPANIES">
                    <label class="font-weight-bold">Companies</label>
                    <app-company-select (selectedCompany)="contentForm.controls['companies'].setValue($event);"
                                        [error]="formSubmitted && form?.companies?.errors?.required"
                                        [multiple]="true"
                                        [selected]="this.form?.companies?.value"></app-company-select>
                  </ng-container>
                  <ng-container *ngIf="selectedContentRestriction === ContentAudience.SPECIFIC_COMPETITIONS">
                    <label class="font-weight-bold">Competitions</label>
                    <app-competition-select
                        (selectedCompetition)="contentForm.controls['competitions'].setValue($event);"
                        [error]="formSubmitted && form?.competitions?.errors?.required"
                        [multiple]="true"
                        [selected]="this.form?.competitions?.value"></app-competition-select>
                  </ng-container>
                </div>
                <div class="col-12">
                  <app-select-locale [parentForm]="contentForm"></app-select-locale>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !content" class="btn btn-warning">Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && content" class="btn btn-warning">Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(creatingContent$ | async)" class="btn btn-warning me-2"
                type="submit">
          <i *ngIf="creatingContent$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(creatingContent$ | async) && !content">{{'CREATING' | translate}}</span>
          <span *ngIf="(creatingContent$ | async) && content">{{'EDITING' | translate}}</span>
          <span [hidden]="(creatingContent$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingContent$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
