<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="!!titleParams">{{ title | translate: {value1: titleParams} }}</span>
      <span *ngIf="!titleParams">{{ title | translate}}</span>
    </h5>
    <button [disabled]="(deleting$ | async)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_DELETE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="!titleParams" class="modal-description">{{ description | translate}}</p>
    <p *ngIf="!!titleParams" class="modal-description">{{ description | translate: {value1: titleParams} }}</p>
    <h4 *ngIf="type === deleteModalType.USER && data?.length > 0" class="card-title mb-4">List of users you are about to
      delete <span class="badge badge-pill badge-soft-secondary font-size-11">{{data?.length}}</span></h4>
    <div *ngIf="type === deleteModalType.USER && data?.length > 0" class="row mt-3 modal-content-max">
      <div class="col-12">
        <app-users-table [canBeRemoved]="data?.length > 1" [type]="deleteModalType.USER"
                         [users]="data"></app-users-table>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-danger">Delete</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="delete()" *ngIf="showAreYouSure" [disabled]="(deleting$ | async)" class="btn btn-danger me-2"
                type="submit">
          <i *ngIf="deleting$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="deleting$ | async">
            Deleting
          </span>
          <span [hidden]="(deleting$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(deleting$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>
