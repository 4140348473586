<div class="operation-modal-header">
  <h5 *ngIf="!activity">{{'CREATE_HOW_TO_SYNC_GUIDE' | translate}}</h5>
  <h5 *ngIf="activity && !activity?.duplicate">{{'EDIT_HOW_TO_SYNC_GUIDE' | translate}}: {{activity?.title}}</h5>
  <h5 *ngIf="activity && activity?.duplicate">{{'DUPLICATE_HOW_TO_SYNC_GUIDE' | translate}}: {{activity?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(addingApp$ | async)" class="btn close-modal-button" closeModal="CLOSE_RECOMMENDED_APP_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="activityForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-9">
        <div class="mb-3">
          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                 formControlName="title" id="title" type="text">
          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mb-3">
          <label class="font-weight-bold" for="active">Active</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="active"
                   id="active" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="active">
              <span *ngIf="form?.active?.value" class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.active?.value" class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control"
                 formControlName="description" id="description" type="text">
          <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <div class="mt-8">
            <div class="space-y-4">
              <ng-container *ngFor="let step of activityForm.get('steps')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
                <div class="flex">
                  <div class="input-group steps-group">
                    <label class="font-weight-bold">{{'POSITION' | translate}}</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [disabled]="true" [formControl]="step.get('index')" [placeholder]="'POSITION'" class="form-control" id="position" type="number">
                    </div>
                    <label class="font-weight-bold">{{'TITLE' | translate}}</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [disabled]="true" [formControl]="step.get('title')" [placeholder]="'TITLE'" class="form-control" id="title" type="text">
                    </div>
                    <label class="font-weight-bold">{{'DESCRIPTION' | translate}}</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [disabled]="true" [formControl]="step.get('description')" [placeholder]="'DESCRIPTION'" class="form-control" id="description" type="text">
                    </div>
                    <ng-container *ngIf="!(first && last)">
                      <div [ngClass]="{'mt-6': first}" class="flex items-center w-10 pl-2">
                        <button (click)="removeStep(i)" class="btn mb-3 btn-warning">
                          Rimuovi
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div (click)="addStep()" class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer">
              <span class="ml-2 font-medium text-secondary group-hover:underline">Add step</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !activity " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && activity && activity?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && activity && !activity?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingApp$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="addingApp$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingApp$ | async) && !activity">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingApp$ | async) && activity && !activity?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(addingApp$ | async) && activity && activity?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingApp$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingApp$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
