import {Component, Input, OnInit} from '@angular/core';
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";

@Component({
  selector: 'app-competitions-table',
  templateUrl: './competitions-table.component.html',
  styleUrls: ['./competitions-table.component.scss']
})
export class CompetitionsTableComponent implements OnInit {
  @Input() canBeRemoved: boolean;
  @Input() light: boolean;
  @Input() competitions: any[];

  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
    if (this.competitions?.length <= 1) {
      this.canBeRemoved = false;
    }
  }

  public removeCompetition(competition: any): void {
    if (this.competitions?.length > 1) {
      this.eventService.broadcast(EventEnum.REMOVE_COMPETITION_FROM_NOTIFICATION_LIST, competition)
    }
  }
}
