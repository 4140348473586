<div class="operation-modal-header">
  <h5 *ngIf="!prizeCategory">{{'CREATE_CATEGORY' | translate}}</h5>
  <h5 *ngIf="prizeCategory && !prizeCategory?.duplicate">{{'EDIT_CATEGORY' | translate}}: {{prizeCategory?.name}}</h5>
  <h5 *ngIf="prizeCategory && prizeCategory?.duplicate">{{'DUPLICATE_CATEGORY' | translate}}: {{prizeCategory?.name}}</h5>
  <div class="d-flex">
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="PRIZE_CATEGORY_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="prizeCategoryForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-12 mb-3">
        <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
        <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
               formControlName="name" id="name" type="text">
        <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
          <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          <span *ngIf="form?.name?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.name?.errors?.maxlength?.requiredLength} }}</span>
          <span *ngIf="form?.name?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.name?.errors?.minlength?.requiredLength} }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <app-image-uploader (imageUploaded)="setImage($event)"
                              [imageErrors]="checkImageErrors()"
                              [limit]="logoImageLimit"
                              [previousSelectedImage]="checkSelectedImage()"
                              [showImagePreview]="true"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !prizeCategory " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && prizeCategory && prizeCategory?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && prizeCategory && !prizeCategory?.duplicate" class="btn mb-3 btn-warning">
          Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !prizeCategory">{{'CREATING' | translate}}</span>
          <span
              *ngIf="(adding$ | async) && prizeCategory && !prizeCategory?.duplicate">{{'EDITING' | translate}}</span>
          <span
              *ngIf="(adding$ | async) && prizeCategory && prizeCategory?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
