import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {ContentAudience} from "@service/content/enums/content-audience.enum";
import {ContentSearchService} from "@service/content/content-search.service";
import {ContentCategoriesApiService} from "@service/content/content-categories-api.service";

@Component({
  selector: 'app-add-content-modal',
  templateUrl: './add-content-modal.component.html',
  styleUrls: ['./add-content-modal.component.scss']
})
export class AddContentModalComponent implements OnInit, OnDestroy {
  @Input() content: any;
  public ContentAudience = ContentAudience
  public showAreYouSure: boolean = false;
  public creatingContent$: Observable<boolean>;
  public formSubmitted: boolean;
  public contentForm: UntypedFormGroup;
  public activeId: number = 1;
  public cardImageLimit: any = {
    fixedSize: true,
    ratio: '4:3',
    width: 1920,
    height: 1440
  };
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	public selectedCategory: any | undefined;
  public categories: any[] | undefined;
  public contentRestriction = [ContentAudience.ALL_USERS, ContentAudience.ALL_COMPANIES, ContentAudience.SPECIFIC_COMPANIES];
  public selectedContentRestriction = ContentAudience.ALL_USERS;
  private closeModalSubscription: Subscription;
  private checkBugSubscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
              public datepipe: DatePipe, private utils: UtilsService, private cdr: ChangeDetectorRef,
              private toastService: ToastService, private _contentCategoriesApiService: ContentCategoriesApiService,
              public formBuilder: UntypedFormBuilder, private contentSearchService: ContentSearchService) {
  }

  get form() {
    return this.contentForm.controls;
  }

  public get cardImage(): string | undefined {
    if (this.content?.image) {
      return this.content?.image;
    } else if (this.form?.image?.value) {
      return this.form?.image?.value;
    } else {
      return undefined;
    }
  }

  get valid() {
    return this.contentForm.valid;
  }

  ngOnInit(): void {
    this.creatingContent$ = this.contentSearchService?.adding$;
    this.initForm();
    this._loadCategories();
  }

  public validSubmit() {
    if (this.contentForm.valid) {
			const data = this.contentForm.getRawValue();
			if (this.selectedContentRestriction === ContentAudience.ALL_USERS) {
				data.validForAllCompanies = false;
				data.companyIds = null;
			} else if (this.selectedContentRestriction === ContentAudience.ALL_COMPANIES) {
				data.validForAllCompanies = true;
				data.companyIds = null;
			} else if (this.selectedContentRestriction === ContentAudience.SPECIFIC_COMPANIES) {
				data.validForAllCompanies = false;
				data.companyIds = data?.companies?.map((company: any) => company?.id);
			}
			if (data?.actionDetail?.badgeId) {
				data.actionDetail = data?.actionDetail?.badgeId;
			} else if (data?.actionDetail?.id) {
				data.actionDetail = data?.actionDetail?.id;
			}
			if (!data.id) {
				data.idText = this.utils.generateRandomId(5);
			}
			data.contentCategoryId = Number(data.contentCategoryId);
      this.contentSearchService.addContent(data);
    }
  }

  public setCardImage(image: any): void {
    this.contentForm.patchValue({
      image: image ? image?.originalUrl : undefined,
      cardImageName: image ? image?.name : undefined,
    });
  }

  public checkCardImageError(): string | undefined {
    if (this.formSubmitted && this.form.image.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedCardImage(): object | undefined {
    if (this.content?.image && this.form?.image?.value) {
      return {
        originalUrl: this.content?.image,
        name: [this.content?.label.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else if (this.form?.image?.value) {
      return {
        originalUrl: this.form?.image?.value,
        name: [this.content?.label.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    switch (type) {
      case 'BASE':
        return !!(this.form?.label?.errors || this.form?.priority?.errors || this.form?.description?.errors || this.checkCardImageError());
        break;
      case 'TYPE':
        return !!(this.form?.actionDetail?.errors || this.form?.contentCategoryId?.errors || this.form?.contentCategoryAction?.errors);
        break;
      case 'RESTRICTIONS':
	      return !!(this.form?.validSinceInclusive?.errors || this.form?.validUntilExclusive?.errors);
      default:
        return false;
    }
  }

  public checkNextActionBtn(): void {
    if (this.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true
      if (this.checkAlert('BASE') || this.checkAlert('RESTRICTIONS')) {
        this.activeId = 1;
        this.cdr.detectChanges();
      } else if (this.checkAlert('TYPE')) {
        this.activeId = 2;
        this.cdr.detectChanges();
      } else if (this.checkAlert('RESTRICTIONS')) {
        this.activeId = 3;
        this.cdr.detectChanges();
      }
    }
  }

  public addStep(numbering?: number) {
    return this.formBuilder.group({
      numbering: [numbering, [Validators.required]],
      label: [null, [Validators.required]],
      description: [null, [Validators.required]],
      id: [],
      image: [],
      imagePosition: [],
    });
  }

  public setImage(image: any, group: any): void {
    group.controls['image'].patchValue(image?.originalUrl)
  }

  public noSpecificRestriction(): boolean {
    return this.selectedContentRestriction === ContentAudience.ALL_USERS || this.selectedContentRestriction === ContentAudience.ALL_COMPANIES;
  }

  public checkRestrictionValidators(event?: any): any {
    this.contentForm.patchValue({['restriction']: this.selectedContentRestriction});
    switch (this.selectedContentRestriction) {
      case ContentAudience.ALL_USERS:
        this._clearValuesAndRemoveValidators(['companies']);
        break;
      case ContentAudience.ALL_COMPANIES:
        this._clearValuesAndRemoveValidators(['companies']);
        break;
      case ContentAudience.SPECIFIC_COMPANIES:
        this._clearValuesAndRemoveValidators(['companies']);
        this.addRequiredValidators(['companies']);
        break;
    }
  }

  public addRequiredValidators(list: string[]) {
    list?.map((el: string) => {
      this.form[el].addValidators([Validators.required]);
      this.form[el].updateValueAndValidity();
    });
  }

  public removeRequiredValidators(list: string[]) {
    list?.map((el: string) => {
      this.form[el].clearValidators();
      this.form[el].updateValueAndValidity();
    });
  }

  ngOnDestroy() {
		console.log('Destroy')
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
    if (this.checkBugSubscription) {
      this.checkBugSubscription.unsubscribe();
    }
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
	
	public checkCategoryAction(): void {
		const categoryId = this.contentForm.get('contentCategoryId').value;
		this.contentForm.get('actionDetail').patchValue(null);
		if (categoryId) {
			this.selectedCategory = this.categories.find((category: any) => category?.id === Number(categoryId));
			this.contentForm.get('contentCategoryAction').patchValue(this.selectedCategory?.action);
		}
	}

  private initForm(): void {
    this.contentForm = this.formBuilder.group({
      label: [!this.content || !this.content?.label ? null : this.content?.label, [Validators.minLength(2), Validators.maxLength(65)]],
	    idText: [this.content?.idText && !this.content.duplicate ? this.content?.idText : null],
      description: [!this.content || !this.content?.description ? null : this.content?.description, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      restrictsLocales: [this.content?.restrictsLocales ? this.content?.restrictsLocales : null],
      image: [!this.content || !this.content?.image ? null : this.content?.image, [Validators.required]],
      contentCategoryId: [!this.content || !this.content?.contentCategory?.id ? null : this.content?.contentCategory?.id, [Validators.required]],
      contentCategoryAction: [null, [Validators.required]],
	    actionDetail: [!this.content || !this.content?.actionDetail ? null : this.content?.actionDetail],
	    restriction: [ContentAudience.ALL_USERS],
	    priority: [!this.content || !this.content?.priority ? null : this.content?.priority, [Validators.required]],
	    companies: [!this.content || !this.content?.companies ? null : this.content?.companies],
	    languageToTranslationUrl: [!this.content || !this.content?.languageToTranslationUrl ? null : this.content?.languageToTranslationUrl],
	    validForAllCompanies: [!this.content || !this.content?.validForAllCompanies ? null : this.content?.validForAllCompanies],
	    validSinceInclusive: [!this.content?.validSinceInclusive ? null : new Date(this.content?.validSinceInclusive), [Validators.required]],
	    validUntilExclusive: [!this.content?.validUntilExclusive ? null : new Date(this.content?.validUntilExclusive), [Validators.required]],
	    id: [this.content?.id && !this.content.duplicate ? this.content?.id : null]
    });
		this._checkRestriction();
  }
	
	private _loadCategories(): void {
		this._contentCategoriesApiService.all().pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
			this.categories = res;
			this.checkCategoryAction();
			if (this.content?.actionDetail) {
				this.contentForm.get('actionDetail').patchValue(this.content.actionDetail ? this.content.actionDetail : null);
			}
		});
	}
	
	private _checkRestriction(): void {
		if (this.content) {
			if (this.content?.validForAllCompanies && this.content?.companies?.length === 0) {
				this.selectedContentRestriction = ContentAudience.ALL_COMPANIES;
				this.contentForm.get('restriction').patchValue(ContentAudience.ALL_COMPANIES);
			} else if (!this.content?.validForAllCompanies && this.content?.companies?.length > 0) {
				this.selectedContentRestriction = ContentAudience.SPECIFIC_COMPANIES;
				this.contentForm.get('restriction').patchValue(ContentAudience.SPECIFIC_COMPANIES);
			} else {
				this.selectedContentRestriction = ContentAudience.ALL_USERS;
				this.contentForm.get('restriction').patchValue(ContentAudience.ALL_USERS);
			}
		}
	}

  private _clearValuesAndRemoveValidators(list: string[]) {
    list?.map((el: string) => {
      this.contentForm.patchValue({[el]: null});
    });
    this.removeRequiredValidators(list);
  }
	
	public setSinceDate(date: any): void {
		console.log(date);
		this.contentForm.patchValue({['validSinceInclusive']: date});
		const validUntilExclusive = this.contentForm?.getRawValue()?.validUntilExclusive;
		const sinceToDate = this.contentForm?.getRawValue()?.validSinceInclusive;
		if (validUntilExclusive) {
			const validUntilExclusiveString = validUntilExclusive.split("/");
			const validSinceInclusive = sinceToDate.split("/");
			const validUntilExclusiveObject = new Date(+validUntilExclusiveString[2], validUntilExclusiveString[1] - 1, +validUntilExclusiveString[0]);
			const validSinceInclusiveObject = new Date(+validSinceInclusive[2], validSinceInclusive[1] - 1, +validSinceInclusive[0]);
			if (validUntilExclusiveObject < validSinceInclusiveObject) {
				this.contentForm.patchValue({['validUntilExclusive']: null});
			}
		}
	}
	
	public setUpToDate(date: any): void {
		console.log(date);
		this.contentForm.patchValue({['validUntilExclusive']: date});
	}
	
}