import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject, Subscription} from "rxjs";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UsersApiService} from "@service/users/users-api.service";
import {DatePipe} from "@angular/common";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-add-remove-credits-modal',
  templateUrl: './add-remove-credits-modal.component.html',
  styleUrls: ['./add-remove-credits-modal.component.scss']
})
export class AddRemoveCreditsModalComponent implements OnInit {
  public user: any = undefined;
  public showAreYouSure: boolean = false;
  public title: string | undefined = undefined;
  public titleParams: string | undefined = undefined;
  public add: boolean = true;
  public creditForm: UntypedFormGroup;
  public formSubmitted: boolean;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              private toastService: ToastService,
              private formBuilder: UntypedFormBuilder, private userApiService: UsersApiService,
              public datepipe: DatePipe) {
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  public get working$() {
    return this._working$.asObservable();
  }

  get form() {
    return this.creditForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.initForm();
  }

  public open(user: any, add: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      this.user = user;
      this.add = add;
    })
  }

  public confirmSelection(): void {

  }

  public validSubmit() {
    if (this.creditForm.valid) {
      let formData = this.creditForm.getRawValue();
      let message: string | undefined = undefined;
      if (!this.add) {
        formData.amount = -formData.amount;
        message = 'Credits removed successfully';
      } else {
        message = 'Credits added successfully';
      }
      this.userApiService.assignCreditsToUser(formData, this.user?.id).subscribe((list: any) => {
        this.toastService.show(message, {classname: 'bg-success text-light'});
        this.eventService.broadcast(EventEnum.CLOSE_ADD_REMOVE_CREDITS, true);
      });
    }
  }

  public updateDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.creditForm.patchValue({['forDay']: convertedDate});
  }

  private initForm() {
    this.creditForm = this.formBuilder.group({
      amount: [null, [Validators.required]],
      forDay: [null, [Validators.required]],
      validForCreditsBasedContests: [false],
      reason: [null, [Validators.maxLength(255)]],
    });
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ADD_REMOVE_CREDITS, (reason: string | undefined) => {
      this.showAreYouSure = false;
      this.user = undefined;
      this.add = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}
