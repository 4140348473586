<label *ngIf="!name" class="form-label font-weight-semibold mb-1 image-label"
       for="message">{{'IMAGE' | translate}}</label>
<label *ngIf="name" class="form-label font-weight-semibold mb-1 image-label" for="message">{{name | translate}}</label>
<div class="row">
  <div [ngClass]="{'col-7': showImagePreview && (selectedImage || selectedImages), 'col-12': !showImagePreview}">
    <p *ngIf="!limit" class="card-title-desc mb-3">
      The correct aspect-ratio is <span class="badge badge-pill badge-soft-danger font-size-11">2:1</span>
      The min-width for the image is <span class="badge badge-pill badge-soft-danger font-size-11">300px</span> and the
      min-height is <span class="badge badge-pill badge-soft-danger font-size-11">200px</span>
      The max-width for the image is <span class="badge badge-pill badge-soft-danger font-size-11">2000px</span> and the
      max-height is <span class="badge badge-pill badge-soft-danger font-size-11">1000px</span>
    </p>
    <p *ngIf="limit?.fixedSize && limit?.ratio && limit?.height && limit?.width" class="specific-width">
      The correct aspect-ratio is <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.ratio}}</span>
      The width for the image must be <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.width}}
      px</span> and the height <span class="badge badge-pill badge-soft-danger font-size-11">{{limit?.height}}px</span>
    </p>
  </div>
  <div *ngIf="!multiple && showImagePreview && selectedImage" class="col-5 text-right">
    <img [src]="selectedImage?.originalUrl" alt="" class="image-preview">
  </div>
  <div *ngIf="multiple && showImagePreview && selectedImages?.length > 0" class="col-5">
    <!--    <img class="image-preview" [src]="selectedImages[0]" alt="">-->
  </div>
  <div class="col-md-12">
    <div class="input-group mt-1">
      <input (click)="openUploader()" [ngClass]="{'is-invalid': !!imageErrors}" [placeholder]="selectedImage?.name ? selectedImage?.name : 'Please choose image'"
             class="form-control bg-white"
             disabled id="title">
      <div *ngIf="!multiple" class="input-group-append">
        <span (click)="openUploader()" *ngIf="!selectedImage"
              class="input-group-text btn btn-primary pointer remove-radius-left">Choose image</span>
        <span (click)="openUploader(true)" *ngIf="selectedImage"
              class="input-group-text btn-primary pointer remove-radius-left remove-radius-right">Show image</span>
      </div>
      <div *ngIf="multiple" class="input-group-append">
        <span (click)="openUploader()" *ngIf="!selectedImages"
              class="input-group-text btn btn-primary pointer remove-radius-left">Choose images</span>
        <span (click)="openUploader(true)" *ngIf="selectedImages"
              class="input-group-text btn-primary pointer remove-radius-left remove-radius-right">Add images ({{selectedImages?.length}}
          )</span>
      </div>
      <div *ngIf="!multiple && selectedImage" class="input-group-append">
        <span (click)="clearImage()" *ngIf="selectedImage" class="input-group-text pointer remove-radius-left">Remove image</span>
      </div>
      <div *ngIf="multiple && selectedImages" class="input-group-append">
        <span (click)="clearImages()" *ngIf="selectedImages"
              class="input-group-text pointer remove-radius-left">Remove all images</span>
      </div>
      <div *ngIf="imageErrors" class="invalid-feedback">
        <span>{{imageErrors}}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="multiple && selectedImages?.length > 0" class="row">
  <label class="form-label font-weight-semibold mb-1 image-label mt-3">{{'SELECTED_IMAGES' | translate}}</label>
  <div *ngFor="let image of selectedImages; let i=index;" class="col mt-3">
    <img [src]="image" alt="" class="rounded max-height-5-rem">
    <p (click)="removeImage(image, i)"
       class="mt-2 ms-2 mb-lg-0 pointer badge badge-pill badge-soft-danger">{{'X' | translate}}</p>
  </div>
</div>
<ngx-uploadcare-widget
  #uploader
  (on-upload-complete)="upload($event)"
  [class.d-none]="true"
  clearable="true"
  data-file-types="jpg,png,gif"
  images-only="true"
  input-accept-types="image/png, image/jpeg"
  multiple="{{multiple}}"
  preview-step="true"
  public-key="demopublickey"
  tabs="file gdrive gphotos dropbox">
</ngx-uploadcare-widget>
