import {Component, Input, OnInit} from '@angular/core';
import {UserDiagnosticService} from "@service/users/user-diagnostic.service";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";

@Component({
  selector: 'app-recorded-activities',
  templateUrl: './recorded-activities.component.html',
  styleUrls: ['./recorded-activities.component.scss']
})
export class RecordedActivitiesComponent implements OnInit {
  @Input() userId: number;
  public loading: boolean = false;
  public activities: any[] = [];
  public dates: Date[] = [];
  public type = TopbarTypeEnum.DIAGNOSTIC_RECORDED_ACTIVITIES;

  constructor(public userDiagnosticService: UserDiagnosticService) {
  }

  ngOnInit(): void {
    if (this.userId) {
      this.search();
    }
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !!date[2]) {
      this.dates = [date[1], date[2]];
    } else {
      this.dates = [];
    }
    this.search();
  }

  private search() {
    if (this.dates?.length === 2 && this.userId) {
      this.loading = true;
      this.userDiagnosticService.getUserDiagnostic(this.dates[0], this.dates[1], String(this.userId)).subscribe((result: any) => {
        let activities = this.userDiagnosticService.extractFormattedActivities(result);
        this.activities = this.userDiagnosticService.aggregateActivitiesForTable(activities);
        this.loading = false;
      }, (error: any) => {
        this.loading = false;
      });
    }
  }

}
