import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";
import {OperatorsApiService} from "@service/users/operators/operators-api.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private toastService: ToastService, private eventService: EventService, private operatorApiService: OperatorsApiService) {
  }

  private _addingOperator$ = new BehaviorSubject<boolean>(false);

  public get addingOperator$() {
    return this._addingOperator$.asObservable();
  }

  public addOperator(data: any): any {
    this._addingOperator$.next(true);
    this.operatorApiService.addEditOperator(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      this._addingOperator$.next(false);
      if (typeof result === 'string') {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show(!data?.id ? 'Operator added' : 'Operator edited', {classname: 'bg-success text-light'});
        this.eventService.broadcast(EventEnum.CLOSE_OPERATOR_MODAL, null)
      }
    });
  }


}
