import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-table-size',
  templateUrl: './table-size.component.html',
  styleUrls: ['./table-size.component.scss']
})
export class TableSizeComponent implements OnInit {
  @Input() updateMethod: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
