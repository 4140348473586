import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription, takeUntil} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {UtilsService} from "@service/utils/utils.service";
import {EventEnum} from "@enum/event/event.enum";

@Component({
  selector: 'app-recalculate-counters-modal',
  templateUrl: './recalculate-counters-modal.component.html',
  styleUrls: ['./recalculate-counters-modal.component.scss']
})
export class RecalculateCountersModalComponent implements OnInit {
	@Input() service: any;
	public data: any | undefined = undefined;
	public title: string | undefined = undefined;
	public titleParams: string | undefined = undefined;
	public description: string | undefined = undefined;
	public working$: Observable<boolean>;
	public showAreYouSure: boolean = false;
	public detail: any = {
		loading: false,
		noResults: false,
		users: []
	}
	@ViewChild('modal') private modalContent: TemplateRef<any>
	private closeModalSubscription: Subscription;
	private modalRef: NgbModalRef;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	
	constructor(private modalService: NgbModal, private eventService: EventService, private utils: UtilsService) {
	}
	
	ngOnInit(): void {
	}
	
	public open(data?: any, title?: string, description?: string, titleParams?: string): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
			this.modalRef.result.then(resolve, resolve)
			this.setModalData(data, title, description, titleParams);
			this._loadUserData();
			this._closeModal();
		})
	}
	
	public persist(): void {
		this.service.persistCounters(Number(this.data?.id), EventEnum.CLOSE_ACTIVATE_MODAL);
	}
	
	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
		this.closeModalSubscription?.unsubscribe();
	}
	
	private setModalData(data: any, title: string, description: string, titleParams: string) {
		this.data = data;
		this.title = title;
		this.description = description;
		this.titleParams = titleParams;
		this.working$ = this.service.working$;
	}
	
	private _loadUserData(): any {
		this.detail.loading = true;
		this.service.recalculateCounters(Number(this.data?.id), false).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
			this.detail.users = res;
			this.detail.loading = false;
		})
	}
	
	private _closeModal(): void {
		this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ACTIVATE_MODAL, (reason: string | undefined) => {
			this.resetModalData();
			this.closeModalSubscription?.unsubscribe();
			this.modalRef?.dismiss(reason);
		});
	}
	
	private resetModalData(): void {
		this.title = undefined;
		this.description = undefined;
		this.titleParams = undefined;
		this.showAreYouSure = false;
	}

}
