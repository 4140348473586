<div class="operation-modal-header">
  <h5 *ngIf="!app">{{'CREATE_APP' | translate}}</h5>
  <h5 *ngIf="app && !app?.duplicate">{{'EDIT_APP' | translate}}: {{app?.title}}</h5>
  <h5 *ngIf="app && app?.duplicate">{{'DUPLICATE_APP' | translate}}: {{app?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(addingApp$ | async)" class="btn close-modal-button" closeModal="CLOSE_RECOMMENDED_APP_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="appForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-9">
        <div class="mb-3">
          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                 formControlName="title" id="title" type="text">
          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mb-3">
          <label class="font-weight-bold" for="active">Active</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="active"
                   id="active" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="active">
              <span *ngIf="form?.active?.value" class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.active?.value" class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control"
                 formControlName="description" id="description" type="text">
          <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="androidStore">{{'ANDROID_STORE' | translate}}</label>
          <p class="card-title-desc mb-1">
            The link of the app in the play store
          </p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.androidStore?.errors}" class="form-control"
                 formControlName="androidStore" id="androidStore" type="text">
          <div *ngIf="formSubmitted && form?.androidStore?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.androidStore?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form?.androidStore?.errors?.pattern">Please insert a valid url.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="androidPackage">{{'ANDROID_PACKAGE' | translate}}</label>
          <p class="card-title-desc mb-1" ngbTooltip="{{'FIND_ANDROID_PACKAGE' | translate}}" placement="top">
            {{'FIND_ANDROID_PACKAGE' | translate  | truncate:[50]}}
          </p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.androidPackage?.errors}" class="form-control"
                 formControlName="androidPackage" id="androidPackage" type="text">
          <div *ngIf="formSubmitted && form?.androidPackage?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form.androidPackage.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form.androidPackage.errors.pattern">Please insert a valid package.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="iosStore">{{'IOS_STORE' | translate}}</label>
          <p class="card-title-desc mb-1">
            The link of the app in the app store
          </p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.iosStore?.errors}" class="form-control"
                 formControlName="iosStore" id="iosStore" type="text">
          <div *ngIf="formSubmitted && form?.iosStore?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.iosStore?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form?.iosStore?.errors?.pattern">Please insert a valid url.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="iosPackage">{{'IOS_PACKAGE' | translate}}</label>
          <p class="card-title-desc mb-1">
            Not required
          </p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.iosPackage?.errors}" class="form-control"
                 formControlName="iosPackage" id="iosPackage" type="text">
          <div *ngIf="formSubmitted && form?.iosPackage?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.iosPackage?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <app-image-uploader [name]="'ICON'"
                              (imageUploaded)="setImage($event)"
                              [showImagePreview]="true"
                              [imageErrors]="checkLockedImageError()"
                              [limit]="limit" [previousSelectedImage]="checkSelectedLockedImage()"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !app " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && app && app?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && app && !app?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingApp$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="addingApp$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingApp$ | async) && !app">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingApp$ | async) && app && !app?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(addingApp$ | async) && app && app?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingApp$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingApp$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
