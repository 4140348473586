import {Injectable} from '@angular/core';
import {UsersApiService} from "@service/users/users-api.service";
import {Observable} from "rxjs";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";
import {UtilsService} from "@service/utils/utils.service";

@Injectable({
  providedIn: 'root'
})
export class UserDiagnosticService {
  public selectedDates: any = undefined;
  private dates: any = undefined;

  constructor(private usersApiService: UsersApiService, private utils: UtilsService) {
  }

  private static convertTimeStampToItalian(timestamp: number): string {
    return [new Date(timestamp).toLocaleDateString("it-IT"), new Date(timestamp).toLocaleTimeString("it-IT")].join(' ')
  }

  public getDefaultDiagnosticApexOptions(height: number): object {
    return {
      series: [{data: []}],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      chart: {
        height: height,
        type: 'rangeBar',
        width: '100%',
        redrawOnWindowResize: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true,
          type: 'x',
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
          },
          selection: {
            background: '#90CAF9',
            border: '#0D47A1'
          }
        },
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 1000,
          animateGradually: {
            enabled: true,
            delay: 50
          },
          dynamicAnimation: {
            enabled: true,
            speed: 1000
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            hideOverflowingLabels: false
          }
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          format: 'dd/MM/yyyy HH:mm',
        }
      },
      noData: {
        text: 'We are sorry there are no results for the selected dates',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '2rem',
          fontFamily: undefined
        }
      },
      fill: {
        type: 'solid'
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: 'dd/MM/yyyy HH:mm',
          formatter: (value: any) => {
            if (typeof value === "string") {
              return;
            } else {
              return UserDiagnosticService.convertTimeStampToItalian(value);
            }
          }
        },
        y: {
          show: true,
          formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
            const data = w?.globals?.initialSeries[seriesIndex]?.data[dataPointIndex];
            return [data?.x, data?.info?.key?.deviceIdentifier].join(' - ');
          },
          title: {
            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
              const data = w?.globals?.initialSeries[seriesIndex]?.data[dataPointIndex];
              return [data?.x, data?.info?.key?.deviceIdentifier].join(' - ');
            }
          }
        },
        marker: {
          show: true,
        },
        custom: ({series, seriesIndex, dataPointIndex, w}) => {
          const data = w?.globals?.initialSeries[seriesIndex]?.data[dataPointIndex];
          if (data) {
            let tooltip: string[] = ['<ul class="series-tooltip">'];
            tooltip.push(`<li class="series-tooltip-title"> ${data?.info?.key?.activityType?.description} </li>`);
            tooltip.push(`<li class="series-tooltip-subtitle"> ${data?.info?.key?.deviceIdentifier} </li>`);
            const start = UserDiagnosticService.convertTimeStampToItalian(data?.y[0]);
            if (start) {
              tooltip.push(`<li><b>Start</b>: ${start} </li>`);
            }
            const end = UserDiagnosticService.convertTimeStampToItalian(data?.y[1]);
            if (end) {
              tooltip.push(`<li><b>End</b>: ${end} </li>`);
            }
            tooltip.push('</ul>')
            return tooltip.join('')
          } else {
            return null;
          }
        }
      }
    };
  }

  public getDefaultFitnessChartApexOptions(height: number): object {
    return {
      series: [{data: []}],
      chart: {
        height: height,
        type: 'line',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 50
          },
          dynamicAnimation: {
            enabled: true,
            speed: 100
          }
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%'
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          format: 'dd/MM/yyyy HH:mm',
        }
      },
      noData: {
        text: 'We are sorry there are no results for the selected dates',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '2rem',
          fontFamily: undefined
        }
      },
      fill: {
        type: 'solid',
        opacity: 0.6
      },
      legend: {
        position: 'bottom',
      }
    };
  }

  public saveDates(): any {

  }

  public extractFormattedActivities(result: any) {
    let activities = result.reduce((items, item) => items.find(x => x?.data?.key?.activityType?.id === item?.data?.key?.activityType?.id) ? [...items] : [...items, item], [])
    activities = activities?.map((activity, index) => {
      const color = this.utils.getDiagnosticColors(index);
      let data = result.filter((activities: any) => {
        return activities?.data?.key?.activityType?.id === activity?.data?.key?.activityType?.id
      })?.map((result) => {
        return {
          x: activity?.data?.key?.activityType?.description,
          y: [
            new Date(result?.activityStart).getTime(),
            new Date(result?.activityEnd).getTime()
          ],
          fillColor: color ? color : '#fff',
          info: result?.data
        }
      });
      return {
        data: data.filter((activities) => {
          return activities?.y?.length === 2
        }),
        name: activity?.data?.key?.activityType?.description,
        info: activity?.data
      }
    })
    return activities;
  }

  public aggregateActivitiesForTable(activities: any[]): any[] {
    return activities?.map((activity: any) => {
      let aggregatedData = {};
      aggregatedData = this.calculateData(activity?.data, 'activeKiloCalories', aggregatedData);
      aggregatedData = this.calculateData(activity?.data, 'distanceMeters', aggregatedData);
      aggregatedData = this.calculateData(activity?.data, 'durationMinutes', aggregatedData);
      return {...aggregatedData, ...activity};
    })
  }

  public aggregateKpisForTopbar(activities: any[]): any {
    const consolidated = activities?.map((activity) => {
      return activity?.consolidate
    });
    const provisional = activities?.map((activity) => {
      return activity?.provisional
    });
    return {
      consolidate: consolidated.reduce((partialSum, a) => partialSum + a, 0),
      provisional: provisional.reduce((partialSum, a) => partialSum + a, 0)
    }
  }

  public getUserDiagnostic(periodStartInclusive: string | Date, periodEndExclusive: string | Date, userId: string): Observable<any> {
    const startDate = periodStartInclusive instanceof Date ? periodStartInclusive.toISOString() : periodStartInclusive;
    const toDate = periodEndExclusive instanceof Date ? periodEndExclusive.toISOString() : periodEndExclusive;
    return this.usersApiService.getUserFitnessActivities(startDate, toDate, userId);
  }

  public getUserFitnessCharts(periodStartInclusive: string | Date, periodEndExclusive: string | Date, userId: string, fitnessMetric: FitnessMetricEnum): Observable<any> {
    const startDate = periodStartInclusive instanceof Date ? periodStartInclusive.toISOString() : periodStartInclusive;
    const toDate = periodEndExclusive instanceof Date ? periodEndExclusive.toISOString() : periodEndExclusive;
    return this.usersApiService.getUserFitnessCharts(startDate, toDate, userId, fitnessMetric);
  }

  private calculateData(data: any[], field: string, aggregatedData: {}): {} {
    const listToSum = data?.map((activity) => {
      return activity?.info?.kpis?.[field]
    });
    return {...aggregatedData, ...{[field]: listToSum.reduce((partialSum, a) => partialSum + a, 0)}};
  }

}
