export enum FitnessMetricEnum {
  STEPS = 'TotalStepsCount',
  SLEEP = 'TotalSleepMinutes',
  MEDITATION = 'TotalMeditationMinutes',
  SPORT = 'TotalSportMinutes',
  CYCLING_MINUTES = 'TotalCyclingMinutes',
  CYCLING_METERS = 'TotalCyclingMeters',
  RUNNING_MINUTES = 'TotalRunningMinutes',
  RUNNING_METERS = 'TotalRunningMeters',
  YOGA_MINUTES = 'TotalYogaMinutes',
  TOTAL_METERS = 'TotalMeters',
}
