import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {forkJoin, Observable, of} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService {

  constructor(private http: HttpService) {
  }

  public sendPushNotification(data: any): Observable<any> {
    return this.http.post(endpoints.notification.sendPush, data).pipe(catchError(error => of(error)));
  }

  public getNotificationList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.notification.list, params);
  }

  public deleteNotifications(notification: any): any {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: notification[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.notification.delete, placeholders);
    return this.http.delete(url, {});
  }

  public getNotificationActionsList(): Observable<any> {
    return this.http.get<any>(endpoints.notification.actions);
  }

  public getNotificationAudienceList(): Observable<any> {
    return this.http.get<any>(endpoints.notification.audience);
  }

  public getAllNotifications(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.notification.all, params);
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.notification.list, params);
  }

  public deleteSingleNotification(id: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.notification.delete, placeholders);
    return this.http.delete(url, {});
  }

  public deleteRecurringNotification(id: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.notification.deleteRecurring, placeholders);
    return this.http.delete(url, {});
  }

  public deleteMultipleNotification(ids: any[]): Observable<any> {
    const observables: Observable<any[]>[] = ids.map(id => this.deleteSingleNotification(id));
    return forkJoin(observables);
  }

  public delete(notification?: { data: any, deleteOthers: boolean, deleteList: boolean }): Observable<any> {
    if (!notification?.deleteOthers && !notification?.deleteList) return this.deleteSingleNotification(notification?.data?.id);
    else if (!notification?.deleteOthers && notification?.deleteList) return this.deleteMultipleNotification(notification?.data);
    else if (notification?.deleteOthers && !notification?.deleteList) return this.deleteRecurringNotification(notification?.data?.recurringGroupId);
  }

}
