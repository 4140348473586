import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from "rxjs";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ContentAudience} from "@service/content/enums/content-audience.enum";
import {ContentSearchService} from "@service/content/content-search.service";
import {ContentTemplate} from "@service/content/enums/content-template.enum";
import {categories} from "@service/content/data";
import {ContentImagePosition} from "@service/content/enums/content-image-position.enum";

@Component({
	selector: 'app-add-public-content-modal',
	templateUrl: './add-public-content-modal.component.html',
	styleUrls: ['./add-public-content-modal.component.scss']
})
export class AddPublicContentModalComponent implements OnInit {
	@Input() content: any;
	public Editor = ClassicEditor
	public ContentAudience = ContentAudience
	public ContentTemplate = ContentTemplate
	public showAreYouSure: boolean = false;
	public creatingContent$: Observable<boolean>;
	public formSubmitted: boolean;
	public contentForm: UntypedFormGroup;
	public activeId: number = 1;
	public cardImageLimit: any = {
		fixedSize: true,
		ratio: '3:4',
		width: 1440,
		height: 1920
	};
	public detailImageLimit: any = {
		fixedSize: true,
		ratio: '21:9',
		width: 1440,
		height: 616
	};
	public categories = categories.data;
	public imagePositions = [ContentImagePosition.LEFT, ContentImagePosition.RIGHT, ContentImagePosition.TOP, ContentImagePosition.BOTTOM]
	public contentTypes = [ContentTemplate.PDF, ContentTemplate.VIDEO, ContentTemplate.BLOG_ARTICLE, ContentTemplate.LANDING_PAGE];
	public contentRestriction = [ContentAudience.ALL_USERS, ContentAudience.ALL_COMPANIES, ContentAudience.SPECIFIC_COMPANIES, ContentAudience.SPECIFIC_COMPETITIONS];
	public selectedContentRestriction = ContentAudience.ALL_USERS;
	public createListenerSubmitted = false;
	editorStyle = {
		height: '200px'
	};
	modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			[{'header': 1}, {'header': 2}], // custom button values
			[{'list': 'ordered'}, {'list': 'bullet'}],
			[{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
			[{'indent': '-1'}, {'indent': '+1'}], // outdent/indent
			[{'direction': 'rtl'}], // text direction
			[{'size': ['small', false, 'large', 'huge']}], // custom dropdown
			[{'header': [1, 2, 3, 4, 5, 6, false]}],
			[{'color': []}, {'background': []}], // dropdown with defaults from theme
			[{'font': ['Inter']}],
			[{'align': []}],
			['clean'], // remove formatting button
			['link', 'image'], // link and image, video
		],
		imageResize: true // for image resize
	};
	private closeModalSubscription: Subscription;
	private checkBugSubscription: Subscription;
	private duplicate = false;
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	
	constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
	            public datepipe: DatePipe, private utils: UtilsService, private cdr: ChangeDetectorRef,
	            private toastService: ToastService,
	            public formBuilder: UntypedFormBuilder, private contentSearchService: ContentSearchService) {
	}
	
	get form() {
		return this.contentForm.controls;
	}
	
	public get cardImage(): string | undefined {
		if (this.content?.cardImageUrl) {
			return this.content?.cardImageUrl;
		} else if (this.form?.cardImageUrl?.value) {
			return this.form?.cardImageUrl?.value;
		} else {
			return undefined;
		}
	}
	
	public get detailImage(): string | undefined {
		if (this.content?.detailsImageUrl) {
			return this.content?.detailsImageUrl;
		} else if (this.form?.detailsImageUrl?.value) {
			return this.form?.detailsImageUrl?.value;
		} else {
			return undefined;
		}
	}
	
	get valid() {
		return this.contentForm.valid;
	}
	
	get landingSteps() {
		return this.contentForm?.get("dataContentLanding") as UntypedFormArray;
	}
	
	ngOnInit(): void {
		this.creatingContent$ = this.contentSearchService?.adding$;
		this.initForm();
	}
	
	public validSubmit() {
		if (this.contentForm.valid) {
			this.contentSearchService.addContent(this.contentForm.getRawValue());
		}
	}
	
	public setCardImage(image: any): void {
		this.contentForm.patchValue({
			cardImageUrl: image ? image?.originalUrl : undefined,
			cardImageName: image ? image?.name : undefined,
		});
	}
	
	public setDetailImage(image: any): void {
		this.contentForm.patchValue({
			detailsImageUrl: image ? image?.originalUrl : undefined,
			detailsImageName: image ? image?.name : undefined,
		});
	}
	
	public checkCardImageError(): string | undefined {
		if (this.formSubmitted && this.form.cardImageUrl.errors?.required) {
			return 'This value is required';
		} else {
			return undefined;
		}
	}
	
	public checkDetailImageError(): string | undefined {
		if (this.formSubmitted && this.form.detailsImageUrl.errors?.required) {
			return 'This value is required';
		} else {
			return undefined;
		}
	}
	
	public checkSelectedCardImage(): object | undefined {
		if (this.content?.cardImageUrl && this.form?.cardImageUrl?.value) {
			return {
				originalUrl: this.content?.cardImageUrl,
				name: [this.content?.title.replace(/\s/g, "_"), 'card_image'].join('_')
			}
		} else if (this.form?.cardImageUrl?.value) {
			return {
				originalUrl: this.form?.cardImageUrl?.value,
				name: [this.content?.title.replace(/\s/g, "_"), 'card_image'].join('_')
			}
		} else {
			return undefined;
		}
	}
	
	public checkSelectedPdfFile(): object | undefined {
		if (this.form?.['dataContent']?.value) {
			return {
				originalUrl: this.form?.['dataContent']?.value,
				name: [this.content?.title.replace(/\s/g, "_"), '_pdf_file'].join('_')
			}
		}
	}
	
	public uploadFile(file: any): void {
		this.contentForm.patchValue({
			dataContent: file?.originalUrl
		});
	}
	
	public checkSelectedDetailImage(): object | undefined {
		if (this.content?.detailsImageUrl && this.form.detailsImageUrl?.value) {
			return {
				originalUrl: this.content?.detailsImageUrl,
				name: [this.content?.title.replace(/\s/g, "_"), 'detail_image'].join('_')
			}
		} else if (this.form.detailsImageUrl?.value) {
			return {
				originalUrl: this.form.detailsImageUrl?.value,
				name: [this.content?.title.replace(/\s/g, "_"), 'card_image'].join('_')
			}
		} else {
			return undefined;
		}
	}
	
	public selectPreconditions(event: any): void {
		if (event) {
			this.contentForm.patchValue({
				precondition: event?.length > 0 ? event : null,
			});
		} else {
			this.contentForm.patchValue({
				precondition: null,
			});
		}
	}
	
	public selectCompetition(event: any): void {
		if (event) {
			this.contentForm.patchValue({
				competition: event?.length > 0 ? event[0] : null,
			});
		} else {
			this.contentForm.patchValue({
				competition: null,
			});
		}
	}
	
	public checkSelectedCompetition(): any {
		if (this.form?.competition?.value) {
			return [this.form?.competition?.value]
		} else {
			return undefined;
		}
	}
	
	public checkAlert(type: string): boolean {
		if (!this.formSubmitted) {
			return false;
		}
		switch (type) {
			case 'BASE':
				return !!(this.form?.title?.errors || this.form?.expiresOn?.errors || this.form?.description?.errors || this.checkCardImageError() || this.checkDetailImageError());
				break;
			case 'TYPE':
				return !!(this.form?.prizeType?.errors || this.form?.priority?.errors || this.form?.price?.errors ||
					this.form?.totalUnits?.errors || this.form?.creditsAmount?.errors || this.form?.websiteActionText?.errors || this.form?.websiteActionUrl?.errors
					|| this.form?.redemptionUrl?.errors || this.form?.precondition?.errors);
				break;
			case 'RESTRICTIONS':
				if (this.selectedContentRestriction === ContentAudience.ALL_USERS) {
					return false;
				} else if (this.selectedContentRestriction === ContentAudience.SPECIFIC_COMPANIES && this.form?.companies?.errors) {
					return true;
				} else if (this.selectedContentRestriction === ContentAudience.SPECIFIC_COMPETITIONS && this.form?.competitions?.errors) {
					return true;
				}
				return false;
				break;
			default:
				return false;
		}
	}
	
	public checkNextActionBtn(): void {
		if (this.valid) {
			this.showAreYouSure = true;
		} else {
			this.formSubmitted = true
			if (this.checkAlert('BASE') || this.checkAlert('RESTRICTIONS')) {
				this.activeId = 1;
				this.cdr.detectChanges();
			} else if (this.checkAlert('TYPE')) {
				this.activeId = 2;
				this.cdr.detectChanges();
			} else if (this.checkAlert('RESTRICTIONS')) {
				this.activeId = 3;
				this.cdr.detectChanges();
			}
		}
	}
	
	public addStep(numbering?: number) {
		return this.formBuilder.group({
			numbering: [numbering, [Validators.required]],
			title: [null, [Validators.required]],
			description: [null, [Validators.required]],
			id: [],
			image: [],
			imagePosition: [],
		});
	}
	
	public addLandingStep() {
		if (!!this.landingSteps) {
			const list = this.landingSteps?.getRawValue()
			this.landingSteps.push(this.addStep(!!list ? list.length + 1 : 1));
		} else {
			this.formBuilder.array([this.addStep(1)])
		}
	}
	
	public populateDataLanding() {
		const steps = this.contentForm.get('dataContentLanding') as UntypedFormArray;
		steps.clear();
		this.content['dataContentLanding'].forEach(step => {
			const group = this.addStep();
			Object.keys(group.controls).forEach(key => {
				group.patchValue({
					[key]: step[key]
				});
			});
			steps.controls.push(group);
		});
	}
	
	public checkSelectedGroupImage(group: any): any {
		if (group.controls['image']?.value) {
			return {
				originalUrl: group.controls['image']?.value,
				name: [group.controls['title']?.value?.replace(/\s/g, "_"), '_image'].join('_')
			}
		}
	}
	
	public removeLandingStep(index) {
		this.landingSteps.removeAt(index);
	}
	
	public setImage(image: any, group: any): void {
		group.controls['image'].patchValue(image?.originalUrl)
	}
	
	public noSpecificRestriction(): boolean {
		return this.selectedContentRestriction === ContentAudience.ALL_USERS || this.selectedContentRestriction === ContentAudience.ALL_COMPANIES;
	}
	
	public checkRestrictionValidators(event?: any): any {
		this.contentForm.patchValue({['restriction']: this.selectedContentRestriction});
		switch (this.selectedContentRestriction) {
			case ContentAudience.ALL_USERS:
				this._clearValuesAndRemoveValidators(['companies', 'competitions']);
				break;
			case ContentAudience.ALL_COMPANIES:
				this._clearValuesAndRemoveValidators(['companies', 'competitions']);
				break;
			case ContentAudience.SPECIFIC_COMPANIES:
				this._clearValuesAndRemoveValidators(['companies', 'competitions']);
				this.addRequiredValidators(['companies']);
				break;
			case ContentAudience.SPECIFIC_COMPETITIONS:
				this._clearValuesAndRemoveValidators(['companies', 'competitions']);
				this.addRequiredValidators(['competitions']);
				break;
		}
	}
	
	public checkType(): any {
		if (this.form.type?.value === ContentTemplate.LANDING_PAGE) {
			this._clearValuesAndRemoveValidators(['dataContent']);
			this._clearValuesAndRemoveValidators(['dataContentLanding']);
			this.addRequiredValidators(['dataContentLanding']);
		} else {
			this._clearValuesAndRemoveValidators(['dataContent']);
			this._clearValuesAndRemoveValidators(['dataContentLanding']);
			this.addRequiredValidators(['dataContent']);
		}
	}
	
	public addRequiredValidators(list: string[]) {
		list?.map((el: string) => {
			this.form[el].addValidators([Validators.required]);
			this.form[el].updateValueAndValidity();
		});
	}
	
	public removeRequiredValidators(list: string[]) {
		list?.map((el: string) => {
			this.form[el].clearValidators();
			this.form[el].updateValueAndValidity();
		});
	}
	
	ngOnDestroy() {
		if (this.closeModalSubscription) {
			this.closeModalSubscription.unsubscribe();
		}
		if (this.checkBugSubscription) {
			this.checkBugSubscription.unsubscribe();
		}
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
	
	private initForm(): void {
		this.contentForm = this.formBuilder.group({
			title: [!this.content || !this.content?.title ? null : this.content?.title, [Validators.required, Validators.minLength(5), Validators.maxLength(65)]],
			description: [!this.content || !this.content?.description ? null : this.content?.description, [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
			restrictsLocales: [this.content?.restrictsLocales ? this.content?.restrictsLocales : null],
			note: [!this.content || !this.content?.note ? null : this.content?.note, [Validators.required, Validators.minLength(5), Validators.maxLength(65)]],
			cardImageUrl: [!this.content || !this.content?.cardImageUrl ? null : this.content?.cardImageUrl, [Validators.required]],
			detailsImageUrl: [!this.content || !this.content?.detailsImageUrl ? null : this.content?.detailsImageUrl, [Validators.required]],
			type: [!this.content || !this.content?.type ? ContentTemplate.PDF : this.content?.type, [Validators.required]],
			active: [!this.content || !this.content?.active ? null : this.content?.active],
			category: [!this.content || !this.content?.category ? null : this.content?.category, [Validators.required]],
			restriction: [!this.content || !this.content?.restriction ? ContentAudience.ALL_USERS : this.content?.restriction],
			companies: [!this.content || !this.content?.companies ? null : this.content?.companies],
			competitions: [!this.content || !this.content?.competitions ? null : this.content?.competitions],
			dataContent: [!this.content || !this.content?.dataContent ? null : this.content?.dataContent],
			dataContentLanding: this.formBuilder.array([this.addStep(1)]),
			id: [this.content?.id && !this.content.duplicate ? this.content?.id : null]
		});
		if (!!this.content?.dataContentLanding) {
			this.populateDataLanding();
		}
	}
	
	private _clearValuesAndRemoveValidators(list: string[]) {
		list?.map((el: string) => {
			this.contentForm.patchValue({[el]: null});
		});
		this.removeRequiredValidators(list);
	}
}
