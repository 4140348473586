import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = request.clone({
      headers: request.headers.set('accept-Language', 'en-US,en;q=0.5').set('Accept-Language', 'en-US,en;q=0.5').set('Content-Language', 'en-US,en;q=0.5')
    });
    return next.handle(req);
  }
}

