import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TruncatePipe} from "@app/pipes/truncate/truncate.pipe";
import {SearchPipe} from "@app/pipes/search/search.pipe";
import {RemoveCompaniesPipe} from "@app/pipes/remove-companies/remove-companies.pipe";
import {HourPipe} from "@app/pipes/hour/hour.pipe";
import {CompanyPipe} from "@app/pipes/company/company.pipe";
import {DurationMaskDirective} from "@app/directives/common/mask.directive";

@NgModule({
  declarations: [
    TruncatePipe,
    SearchPipe,
    CompanyPipe,
    HourPipe,
    RemoveCompaniesPipe,
    DurationMaskDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TruncatePipe,
    SearchPipe,
    CompanyPipe,
    HourPipe,
    RemoveCompaniesPipe,
    DurationMaskDirective
  ]
})
export class CoreModule {
}
