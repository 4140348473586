<div class="input-group mr-2">
  <input
    (blur)="inputBlur($event)"
    (change)="onInputChange($event)"
    [disabled]="disabled"
    [ngClass]="invalid ? 'is-invalid' : ''"
    [ngModel]="dateString | date: 'dd/MM/yyyy HH:mm' : '0'"
    class="form-control"
    placeholder="dd/MM/yyyy"
  />
  <div class="input-group-prepend">
    <button [ngbPopover]="calendarContent" class="btn btn-light datepicker-btn picker-btn-with-hour" type="button">
      <i class="bx bx-calendar"></i>
    </button>
    <button [disabled]="checkTimeDisabled()" [ngbPopover]="timePickerContent" class="btn btn-light datepicker-btn"
            type="button">
      <i class="bx bx-time-five"></i>
    </button>
  </div>
</div>

<ng-template #calendarContent>
  <div class="time-picker">
    <ngb-datepicker #dp (ngModelChange)="onDateChange($event, dp)" [ngModel]="datetime" id="dp"
                    name="datepicker"></ngb-datepicker>
  </div>
</ng-template>
<ng-template #timePickerContent>
  <div class="time-picker">
    <ngb-timepicker #tp (ngModelChange)="onTimeChange($event)" [hourStep]="hourStep" [minuteStep]="minuteStep"
                    [ngModel]="datetime" name="timepicker">
    </ngb-timepicker>
  </div>
</ng-template>
