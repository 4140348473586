import {Injectable} from '@angular/core';
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {UtilsService} from "@service/utils/utils.service";
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {InvitesApiService} from "@service/utility/invites-api.service";
import {Router} from "@angular/router";
import {UtilityApiService} from "@service/utility/utility-api.service";

@Injectable({
  providedIn: 'root'
})
export class InvitesService {
  public _search$ = new Subject<void>();
  public _delete$ = new Subject<any>();
  public _searchUses$ = new Subject<void>();
  public listVisibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.invitesListColumns;
  public usesVisibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.invitesUsesColumns;
  public listVisibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.invitesListFilters;
  public usesVisibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.invitesUsesFilters;
  private searchInvitesSubscription: Subscription;
  private searchInvitesUsesSubscription: Subscription;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private _exporting = new BehaviorSubject<any>(null);
  private deleteSubscription: Subscription;

  constructor(private utils: UtilsService, private invitesApiService: InvitesApiService, private eventService: EventService,
              private _eventService: EventService, private _toastService: ToastService,
              private toastService: ToastService, private router: Router, private utilityApiService: UtilityApiService) {
  }

  private _adding$ = new BehaviorSubject<boolean>(false);

  public get adding$() {
    return this._adding$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _inviteList$ = new BehaviorSubject<any[]>([]);

  public get inviteList$() {
    return this._inviteList$.asObservable();
  }

  private _inviteUsesList$ = new BehaviorSubject<any[]>([]);

  public get inviteUsesList$() {
    return this._inviteUsesList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get id() {
    return this._currentTableState.id;
  }

  set id(id: number) {
    this._setValue({id});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  get listColumns() {
    return this.listVisibleColumns;
  }

  get listFilters() {
    return this.listVisibleFilters;
  }

  get usesColumns() {
    return this.usesVisibleColumns;
  }

  get usesFilters() {
    return this.usesVisibleFilters;
  }

  get numberOfUses() {
    return this._currentTableState.numberOfUses;
  }

  set numberOfUses(numberOfUses: string[]) {
    this._setValue({numberOfUses});
  }

  public get type() {
    return this._currentTableState.type;
  }

  public set type(type: string) {
    this._setValue({type});
  }

  get contextDetailsId() {
    return this._currentTableState.contextDetailsId;
  }

  set contextDetailsId(contextDetailsId: string | undefined) {
    this._setValue({contextDetailsId});
  }

  get registrationDate() {
    return this._currentTableState.registrationDate;
  }

  set registrationDate(registrationDate: string[]) {
    this._setValue({registrationDate});
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public delete(data?: any): void {
    this._delete$.next(data);
  }

  public removeSearchListSubscribe(): void {
    this.searchInvitesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public removeSearchUsesSubscribe(): void {
    this.searchInvitesUsesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchListListener(): void {
    this.searchInvitesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.invitesApiService.getInvitesList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._inviteList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public initSearchUsesListener(): void {
    this.searchInvitesUsesSubscription = this._searchUses$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.invitesApiService.getInviteUses(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._inviteUsesList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public removeDeleteSubscribe(): void {
    this.deleteSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public activate(code: any, modal: any): any {
    code.active = true;
    this.activateDeactivateCode(code, modal);
  }

  public deactivate(code: any, modal: any): any {
    code.active = false;
    this.activateDeactivateCode(code, modal);
  }

  public initDeleteListener(): void {
    this.deleteSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((code) => this.utilityApiService.deleteCode(code).pipe(
        map((result) => {
          this._deleting$.next(false);
          this._search$.next();
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Code deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          this._search$.next();
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public getInviteUses(invite: any): any {
    const params = {
      filters: {
        codeId: ["EQ", Number(invite?.id)]
      },
      page: 1,
      size: 1000
    }
    return this.invitesApiService.getInviteUses(params);
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (Object.keys(obj)?.length === 1 && (Object.keys(obj)[0] === 'whiteList' || Object.keys(obj)[0] === 'codeId' || Object.keys(obj)[0] === 'userId')) {
        return false;
      } else {
        if (!length) {
          return Object.keys(obj)?.length > 0;
        } else {
          return Object.keys(obj)?.length
        }
      }
    } else {
      return false;
    }
  }

  public clearFilters(): void {
    if (!this.router.url.includes('/invites/uses')) {
      this.id = undefined;
    }
    this.userId = undefined;
    this.searchTerm = undefined;
    this.sortColumn = undefined;
    this.sortDirection = undefined;
    this.createdAt = undefined;
    this.numberOfUses = undefined;
    this.registrationDate = undefined;
    this.type = undefined;
    this.contextDetailsId = undefined;
  }

  public exportData(uses?: boolean): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(uses ? this.invitesApiService.getInviteUses(this._extractSearchParams(pages, 100)) : this.invitesApiService.getInvitesList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  public addDeeplink(data: any): any {
    this._adding$.next(true);
    this.invitesApiService.add(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (typeof result === 'string') {
        this._toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this._toastService.show(!data?.id ? 'Deeplink added' : 'Deeplink edited', {classname: 'bg-success text-light'});
      }
      this._adding$.next(false);
      this._eventService.broadcast(EventEnum.CLOSE_DEEPLINK_MODAL, null)
    });
  }

  private activateDeactivateCode(code: any, modal: any) {
    this._working$.next(true);
    this.utilityApiService.activateDeactivateCode(code).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = code?.active ? 'Code activated successfully' : 'Code deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    // this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    // this._search$.next();
    return err;
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        codeId: this.id ? ["EQ", Number(this.id)] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        usedAt: this.registrationDate && this.registrationDate?.length > 0 ? this.registrationDate : undefined,
        userId: this.userId ? ["EQ", Number(this.userId)] : undefined,
        usesCount: this.numberOfUses && this.numberOfUses?.length > 0 ? this.numberOfUses : undefined,
      },
      destinationId: (this.contextDetailsId !== undefined && this.contextDetailsId !== null && this.contextDetailsId !== 'undefined') ? this.contextDetailsId : undefined,
      type: (this.type !== undefined && this.type !== null && this.type !== 'undefined') ? this.type : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
    this._searchUses$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
