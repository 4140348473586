import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserDiaryService} from "@service/users/user-diary.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {Observable} from "rxjs";

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss']
})
export class DiaryComponent implements OnInit, OnDestroy {
  @Input() userId;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public diaryActivities$: Observable<any[]>;

  constructor(public userDiaryService: UserDiaryService) {
  }

  ngOnInit(): void {
    this.total$ = this.userDiaryService.totalRecords$;
    this.loading$ = this.userDiaryService.loading$;
    if (this.userId) {
      this.userDiaryService.clearFilters();
      this.userDiaryService.userId = this.userId;
      this.sort({column: "createdAt", direction: "desc"});
      this.userDiaryService.initUserDiaryListener();
      this.userDiaryService.pageSize = 100;
      this.diaryActivities$ = this.userDiaryService.userDiary$;
    }
  }

  public extractRowNumber(index: any): string {
    return (this.userDiaryService.page - 1) * this.userDiaryService.pageSize + index + 1;
  }

  public activityDetail(activity: any): void {

  }

  public sort({column, direction}: SortEventInterface) {
    this.userDiaryService.sortColumn = column;
    this.userDiaryService.sortDirection = direction;
  }

  ngOnDestroy() {
    this.userDiaryService.clearFilters();
    this.userDiaryService.removeSearchSubscribe();
  }

}
