import {Component, Input, OnInit} from '@angular/core';
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";

@Component({
  selector: 'app-challenges-table',
  templateUrl: './challenges-table.component.html',
  styleUrls: ['./challenges-table.component.scss']
})
export class ChallengesTableComponent implements OnInit {
  @Input() canBeRemoved: boolean;
  @Input() light: boolean;
  @Input() challenges: any[];

  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
    if (this.challenges?.length <= 1) {
      this.canBeRemoved = false;
    }
  }

  public removeChallenge(challenge: any): void {
    if (this.challenges?.length > 1) {
      this.eventService.broadcast(EventEnum.REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST, challenge)
    }
  }

}
