<ng-template #contentTemplate>
  <div id="sidebar-menu">
    <ul #sideMenu class="metismenu list-unstyled" id="side-menu">
      <ng-container *ngFor="let item of menuItems">
        <li *ngIf="item.isTitle" class="menu-title">{{ item.label | translate }}</li>
        <li *ngIf="!item.isTitle && !item.isLayout">
          <a *ngIf="hasItems(item)" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" (click)="item.expanded = !item.expanded;"
             [routerLink]="isCondensed ? item.link : null" class="is-parent pointer"
             href="javascript:void(0);">
            <i *ngIf="item.icon" class="bx {{item.icon}}"></i>
            <span> {{ item.label | translate }}</span>
            <span *ngIf="item.badge" class="badge rounded-pill float-end bg-{{item.badge.variant}}">{{item.badge.text |
              translate}}</span>
          </a>
          <a *ngIf="!hasItems(item)" [routerLink]="item.link" class="side-nav-link-ref" routerLinkActive="active">
            <i *ngIf="item.icon" class="bx {{ item.icon }}"></i>
            <span> {{ item.label | translate }}</span>
            <span *ngIf="item.badge" class="badge rounded-pill bg-{{item.badge.variant}} float-end">{{item.badge.text |
              translate}}</span>
          </a>

          <ul *ngIf="hasItems(item) && item.expanded" aria-expanded="false" class="sub-menu">
            <li *ngFor="let subitem of item.subItems">
              <a *ngIf="!hasItems(subitem)" [attr.data-parent]="subitem.parentId" [routerLink]="subitem.link"
                 class="side-nav-link-ref" routerLinkActive="active">
                {{ subitem.label | translate}}
              </a>
              <a *ngIf="hasItems(subitem)" [attr.data-parent]="subitem.parentId" class="side-nav-link-a-ref has-arrow pointer" (click)="subitem.expanded = !subitem.expanded">
                {{ subitem.label | translate}}
              </a>
              <ul *ngIf="hasItems(subitem) && subitem.expanded" aria-expanded="false" class="sub-menu">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" class="side-nav-link-ref"
                     routerLinkActive="active">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<div class="vertical-menu" #componentRef>
  <div *ngIf="!isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
  <div *ngIf="isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
