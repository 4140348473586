<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span
        *ngIf="add && user && user?.length === 1">{{ 'ADD_USER_WHITELIST' | translate: {value1: user[0]?.fullName} }}</span>
      <span *ngIf="add && user && user?.length > 1">{{ 'ADD_SELECTED_USER_WHITELIST' | translate}}</span>
      <span
        *ngIf="!add && user && user?.length === 1">{{ 'REMOVE_USER_WHITELIST' | translate: {value1: user[0]?.fullName} }}</span>
      <span *ngIf="!add && user && user?.length > 1">{{ 'REMOVE_SELECTED_USER_WHITELIST' | translate}}</span>
    </h5>
    <button [disabled]="(working$ | async)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_ADD_REMOVE_WHITELIST_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <span *ngIf="add" class="modal-description">{{ 'ADD_USER_WHITELIST_DESC' | translate }}</span>
    <span *ngIf="!add" class="modal-description">{{ 'REMOVE_USER_WHITELIST_DESC' | translate }}</span>
    <div class="row mt-3 modal-content-max">
      <div class="col-12">
        <app-users-table *ngIf="user" [canBeRemoved]="false" [users]="user"></app-users-table>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && add" class="btn btn-warning">Add</button>
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && !add" class="btn btn-warning">Remove</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="submit()" *ngIf="showAreYouSure" [disabled]="(working$ | async)" class="btn btn-warning me-2"
                type="submit">
          <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="working$ | async">
            <span *ngIf="add">Adding</span>
            <span *ngIf="!add">Removing</span>
          </span>
          <span [hidden]="(working$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>

  </div>
</ng-template>
