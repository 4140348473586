<header id="page-topbar-filters">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
      </div>
      <div class="row ms-2">
        <div class="col-sm-12 col-lg-12">
          <form class="d-xl-inline-block">
            <div class="position-relative">
              <div *ngIf="!hideSearch" class="input-group me-2">
                <input (ngModelChange)="search($event)" [(ngModel)]="searchTerm" class="form-control form-control-dark"
                       name="searchTerm" placeholder="{{'SEARCH' | translate}}" type="text">
                <label (click)="search(this.searchTerm)" class="input-group-text pointer search-btn"><i
                  class="bx bx-search"></i></label>
              </div>
            </div>
          </form>
          <div *ngIf="service && !hideFilters" class="d-inline-block">
            <button [attr.aria-controls]="id" [attr.data-bs-target]="'#' + id" class="btn btn-dark mb-0 ms-2"
                    data-bs-toggle="offcanvas" type="button">
              <i *ngIf="!service?.filterApplied()" class="bx bx bx-filter me-1"></i>
              <span *ngIf="service?.filterApplied && service?.filterApplied()" class="badge bg-danger me-2">
                <i class="bx bx bx-filter me-1"></i>
                {{service?.filterApplied(true)}}
              </span>
              <span class="me-2">{{total$ | async}} results</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
<!--      <button (click)="emit.emit('showHideCalendar')"-->
<!--              *ngIf="type === TopbarTypeEnum.NOTIFICATIONS && service?.alreadySent === undefined" class="btn btn-secondary mb-0 me-2">-->
<!--        <i class="bx bx-calendar mr-2"></i>-->
<!--        Show calendar-->
<!--      </button>-->
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.NOTIFICATIONS"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Send new notification
      </button>
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.PARTNERS" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        New partner
      </button>
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.OPERATORS" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        New operator
      </button>
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.RECOMMENDED_APPS"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        New app
      </button>
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.BENEFIT" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        New benefit
      </button>
      <button (click)="emit.emit('new')" *ngIf="type === TopbarTypeEnum.SPORT" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        New sport
      </button>
      <button (click)="emit.emit('save')" *ngIf="type === TopbarTypeEnum.HOW_TO_SYNC"
              class="btn btn-primary mb-0 me-2 text-uppercase font-weight-bold">
        Save
      </button>
      <button
        (click)="emit.emit('new')"
        *ngIf="type === TopbarTypeEnum.CONTENT_CATEGORIES || type === TopbarTypeEnum.BADGES || type === TopbarTypeEnum.PRIZE_CATEGORIES || type === TopbarTypeEnum.CONTENT_LIST || type === TopbarTypeEnum.ACTIVITIES || type === TopbarTypeEnum.SUSTAINABILITY_PLACES || type === TopbarTypeEnum.SUSTAINABILITY_PARTNERS  || type === TopbarTypeEnum.SUSTAINABILITY_PROJECTS" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Add
      </button>
      <button *ngIf="type === TopbarTypeEnum.CONTENT_LIST"
          (click)="emit.emit('newPublic')" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Add public
      </button>
      <app-column-picker (updateVisibleColumns)="updateColumns($event)" *ngIf="!hideColumns"
                         [availableColumns]="availableColumns" [visibleColumns]="columns"></app-column-picker>
    </div>
  </div>
</header>
