<div class="input-group">
  <select [(ngModel)]="updateMethod.pageSize" class="form-select form-select-sm" name="pageSize">
    <option [ngValue]="5">5</option>
    <option [ngValue]="10">10</option>
    <option [ngValue]="25">25</option>
    <option [ngValue]="50">50</option>
    <option [ngValue]="100">100</option>
  </select>
  <label class="input-group-text">Entries</label>
</div>
