import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-missing-label',
  templateUrl: './missing-label.component.html',
  styleUrls: ['./missing-label.component.scss']
})
export class MissingLabelComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
