<app-table-topbar [type]="topBarType" [service]="companiesCodesService" [showColumnPicker]="true"
                  (updateColumnsEvent)="selected = $event"
                  (openAddCodes)="addCodes($event)"
                  (emitSearchTerm)="companiesCodesService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="codesList$ | async as codes" class="wrapper">
    <table *ngIf="codes?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_ID)" class="column-sortable"
            sortable="id">
          <span class="me-3">{{'COMPANY_CODES_ID' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_CODE)" class="column-sortable"
            sortable="code">
          <span class="me-3">{{'COMPANY_CODES_CODE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_USES)" class="column-sortable"
            sortable="usesCount">
          <span class="me-3">{{'COMPANY_CODES_USES' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_ACTIVE)" class="column-sortable"
            sortable="sportHours">
          <span class="me-3">{{'COMPANY_CODES_ACTIVE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_DEEPLINK)" class="column-sortable"
            sortable="deepLink">
          <span class="me-3">{{'COMPANY_CODES_DEEPLINK' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_INVITER)" class="column-sortable"
            sortable="inviter">
          <span class="me-3">{{'COMPANY_CODES_INVITER' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_CREATED_AT)">
          <span class="me-3">{{'COMPANY_CODES_CREATED_AT' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let code of codes; let i=index;">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_ID)" align="center" class="pointer fit-width">
          <span *ngIf="code?.id" class="badge badge-pill badge-soft-primary">{{code?.id}}</span>
          <app-missing-label *ngIf="!code?.id"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_CODE)" align="center" class="pointer">
          <span *ngIf="code?.code">{{code?.code}}</span>
          <app-missing-label *ngIf="!code?.code"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_USES)" align="center" class="pointer fit-width">
          <span *ngIf="!!code?.usesCount">{{code?.usesCount}}</span>
          <app-missing-label *ngIf="!code?.usesCount"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_ACTIVE)" align="center" class="pointer fit-width">
          <span *ngIf="code?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!code?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td  *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_DEEPLINK)" align="center" class="pointer fit-width" (cbOnSuccess)="copied($event)" (mouseenter)="code.copy = true;" (mouseleave)="code.copy = false;"
            [cbContent]="code?.deepLink"
            align="center" class="pointer fit-width" ngxClipboard>
              <span *ngIf="code?.deepLink" class="link" ngbTooltip="{{code?.deepLink}}"
                    placement="bottom">{{code?.deepLink | truncate:[50]}}</span>
          <i [ngClass]="code.copy ? 'opacity-1' : 'opacity-0'" class="mdi mdi-content-copy ms-1"
             ngbTooltip="Click to copy" placement="bottom"></i>
          <app-missing-label *ngIf="!code?.deepLink"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_INVITER)" align="center" class="pointer fit-width">
          <span *ngIf="code?.inviter?.email" ngbTooltip="User detail" class="pointer" placement="left" (click)="userDetail(code?.inviter)">{{code?.inviter?.email}}</span>
          <app-missing-label *ngIf="!code?.inviter?.email"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.COMPANY_CODES_CREATED_AT)" align="center" class="pointer fit-width">
          <span *ngIf="code?.createdAt">{{code?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!code?.createdAt"></app-missing-label>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="deleteCode(code)"
                [ngClass]="{'disabled-opacity': code?.usesCount > 0}"
                class="list-inline-item px-2">
                  <span [ngbTooltip]="extractDeleteTootlip(code)" class="pointer danger" placement="left"><i
                    class="bx bx bx-trash"></i></span>
            </li>
            <li (click)="codeActivateDeactivate(code, !code?.active)" class="list-inline-item px-2">
                  <span *ngIf="!code?.active" class="pointer success"
                        ngbTooltip="Activate code" placement="left"><i class="bx bx-check"></i></span>
              <span *ngIf="code?.active" class="pointer danger"
                    ngbTooltip="Deactivate code" placement="left"><i class="bx bx-x"></i></span>
            </li>
            <li (cbOnSuccess)="copied($event)" (mouseenter)="code.copy = true;" (mouseleave)="code.copy = false;"
                [cbContent]="code?.deepLink" align="center" class="list-inline-item px-2" ngxClipboard>
                    <span class="pointer" ngbTooltip="Copy deeplink"
                          placement="left"><i
                      class="bx bx-link"></i></span>
            </li>
            <li (click)="utils.downloadQrCode(code)" class="list-inline-item px-2">
              <qrcode *ngIf="code?.deepLink" [allowEmptyString]="false" [attr.id]="'qrCode' + code?.id"
                      [elementType]="'url'"
                      [errorCorrectionLevel]="'M'"
                      [qrdata]="code?.deepLink" [width]="1024"
                      class="d-none"></qrcode>
              <span class="pointer"
                    ngbTooltip="Download"
                    placement="left"><i
                class="mdi mdi-download"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="codes?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="companiesCodesService" [total$]="total$" [showOnlyResultTotal]="false"></app-pagination-footer>
<app-company-codes-modal #companyCodesModal [showOnlyAdd]="true"></app-company-codes-modal>
<app-deactivate-modal #deactivateModal [service]="companiesCodesService"></app-deactivate-modal>
<app-activate-modal #activateModal [service]="companiesCodesService"></app-activate-modal>
<app-delete-modal #deleteModal [service]="companiesCodesService" [type]="deleteModalType.CODE"></app-delete-modal>
