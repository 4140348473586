import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {firstValueFrom, forkJoin, Observable, of, Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationsService} from "@service/notifications/notifications.service";
import {UtilsService} from "@service/utils/utils.service";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {CompaniesService} from "@service/companies/companies.service";
import {CompetitionsService} from "@service/competitions/competitions.service";
import {PrizesService} from "@service/prizes/prizes.service";
import {ToastService} from "@service/toast.service";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {catchError} from "rxjs/operators";
import {FormsService} from "@service/common/forms.service";
import {deeplinkActions} from "../../../shared/data";
import {ActionOptions} from "@component/deeplinks/add-deeplink-modal/deeplink-options.enum";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  @ViewChild('recurringComponent', {static: false}) private recurringComponent;
  @Input() notification: any;
  @Input() specificDate: any;
  @Input() specificUsers: any;
  public showAreYouSure: boolean = false;
  public creatingNotification$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public notificationForm: UntypedFormGroup;
  public activeId: number = 1;
  public audience$: any;
  public availableActions: any = deeplinkActions;
  public allActions: any;
  public languages = this.utils.languages;
  public deliveryOptions = this.utils.deliveryOptions;
  public deliveryEveryOptions = this.utils.deliveryEveryOptions;
  public recurring: any | undefined = undefined;
  public DeleteModalTypeEnum = DeleteModalTypeEnum;
  public AudienceEnum = AudienceEnum;
  public ActionsEnum = ActionsEnum;
  public DeeplinkOptions = ActionOptions;
  public tmpUserIds: any | undefined = undefined;
  public showSelectedUsers: boolean = false;
  public selectedUsers: any[] = [];
  public chunkSize = 500;
  private destroy$: any = new Subject();

  constructor(private modalService: NgbModal, private notificationsService: NotificationsService, private toastService: ToastService,
              public utils: UtilsService, private formBuilder: UntypedFormBuilder, private companiesService: CompaniesService, private _formService: FormsService,
              private competitionsService: CompetitionsService, private prizesService: PrizesService){}

  ngOnInit(): void {
    this.creatingNotification$ = this.notificationsService.sending$;
    this.audience$ = this.notificationsService.getNotificationAudience();
    // this.notificationsService.getNotificationActions().subscribe((result: any) => {
    //   this.availableActions = result;
    //   this.allActions = JSON.parse(JSON.stringify(result));
    // });
    this.initForm();
  }

  public validSubmit() {
    if (this.recurringComponent) {
      this.recurringComponent.getCurrentRecurring();
    }
    if (this.notificationForm.valid) {
      const convertedDates = this.notificationsService.extractSelectedDates(this.form?.delivery?.value, this.form?.scheduledAt?.value, this.form?.deliveryDateHour?.value, this.form?.deliveryDateMinute?.value, this.recurring);
      const actionDetail = this.notificationsService.extractNotificationActionDetail(this.form?.action?.value, this.form?.actionDetail?.value);
      const recurringGroupId = this.form?.delivery?.value === 'RECURRING' ? this.utils.generateRandomId(10) : undefined;
      if (this.selectedUsers?.length > this.chunkSize) {
        const splitUsers = this.utils.chunk(this.notificationsService.extractNotificationDestinationIds(this.form?.audienceIds?.value), this.chunkSize);
        let notifications: any[] = [];
        for (let users of splitUsers) {
          notifications.push(this._getNotification(actionDetail, users, recurringGroupId));
        }
        if (!convertedDates?.date) {
          this.notificationsService.sendMultipleSingleNotification(notifications);
        } else if (typeof convertedDates?.date === "string") {
          this.notificationsService.sendScheduledNotification([convertedDates?.date], convertedDates?.hour, convertedDates?.minutes, notifications);
        } else if (Array.isArray(convertedDates?.date)) {
          this.notificationsService.sendScheduledNotification(convertedDates?.date, convertedDates?.hour, convertedDates?.minutes, notifications, this.notification && !this.notification?.duplicate ? this.notification?.recurringGroupId : undefined);
        }
      } else {
        const destinationIds = this.form?.audience?.value !== AudienceEnum.ALL_USERS ? this.notificationsService.extractNotificationDestinationIds(this.form?.audienceIds?.value) : null;
        const notification = this._getNotification(actionDetail, destinationIds, recurringGroupId);
        this._sendBasicNotification(convertedDates, notification);
      }
    } else {
      this._formService.findInvalidControlsRecursive(this.notificationForm);
    }
  }

  private _getNotification(actionDetail: string, destinationIds: number[], recurringGroupId: string) {
    return {
      action: this.form?.action?.value !== ActionsEnum.OPEN_LINK ? this.form?.action?.value : null,
      actionDetail: this.form?.action?.value !== ActionsEnum.OPEN_LINK ? actionDetail : null,
      link: this.form?.action?.value === ActionsEnum.OPEN_LINK ? actionDetail : null,
      audience: this.form?.audience?.value,
      destinationIds: destinationIds,
      recurringGroupId: recurringGroupId,
      recurringInfo: this.utils.encryptData(JSON.stringify(this.recurring)),
      locale: this.form?.locale?.value,
      id: this.notification && !this.notification?.duplicate && !this.notification?.recurringGroupId ? this.notification?.id : null,
      title: this.form?.title?.value,
      body: this.form?.body?.value,
      imageUrl: this.form?.imageUrl?.value,
    }
  }

  private _sendBasicNotification(convertedDates, notification: { destinationIds: number[]; audience: any; imageUrl: any; link: string; action: any; recurringInfo: string; recurringGroupId: string; id: any; locale: any; title: any; body: any; actionDetail: string }) {
    if (!convertedDates?.date) {
      this.notificationsService.sendSingleNotification(notification);
    } else if (typeof convertedDates?.date === "string") {
      this.notificationsService.sendScheduledNotification([convertedDates?.date], convertedDates?.hour, convertedDates?.minutes, notification);
    } else if (Array.isArray(convertedDates?.date)) {
      this.notificationsService.sendScheduledNotification(convertedDates?.date, convertedDates?.hour, convertedDates?.minutes, notification, this.notification && !this.notification?.duplicate ? this.notification?.recurringGroupId : undefined);
    }
  }

  public checkNextActionBtn(): void {
    if (this.notificationForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
      const invalid = this._invalidFields();
      if (invalid?.length > 0) {
        const errorFields = invalid.join(' - ');
        this.toastService.show(['Insert all the fields, missing: ', errorFields].join(' '), {classname: 'bg-danger text-light'});
      }
    }
  }

  private _invalidFields() {
    const invalid = [];
    const controls = this.notificationForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    const invalid = this._invalidFields();
    switch (type) {
      case 'AUDIENCE_ACTION':
        const audienceFields = ['audience', 'audienceIds', 'action'];
        return invalid.some(r=> audienceFields.includes(r))
        break;
      case 'CONTENT':
        const contentFields = ['title', 'locale', 'body', 'imageUrl'];
        return invalid.some(r=> contentFields.includes(r))
        break;
      default:
        return false;
    }
  }

  get form() {
    return this.notificationForm.controls;
  }

  get valid() {
    return this.notificationForm.valid
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.notificationForm.patchValue({
        imageUrl: image?.originalUrl,
        imageName: image?.name,
        imageType: image?.mimeType,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.imageUrl?.value) {
      return {name: this.form?.imageName?.value, originalUrl: this.form?.imageUrl?.value, mimeType: this.form?.imageType?.value}
    } else {
      return null;
    }
  }

  public removeImage(): void {
    this.notificationForm.patchValue({
      imageUrl: null,
      imageName: null,
      imageType: null,
    });
  }

  public addUserIds(): void {
    const userIdsList = this.tmpUserIds.split('\n');
    const userIds = userIdsList?.map((code: string) => {return Number(code)})?.filter((code) => !!code)?.filter((v, i, a) => a.indexOf(v) === i);
    this.getUserIds(userIds);
  }

  private getUserIds(userIds) {
    let requests: Observable<any>[] = [];
    const chunks = this.utils.chunk(userIds, this.chunkSize);
    for (let chunk of chunks) {
      requests.push(this.utils.getUsersByIdList(chunk?.join()));
    }
    forkJoin(requests).pipe(catchError(error => of(error))).subscribe((result: any) => {
      const audience = result?.flat();
      const audienceIds = audience?.map(user => user?.userId);
      if (audience?.length > 0 && audienceIds?.length > 0) {
        this.notificationForm.patchValue({['audienceIds']: audienceIds});
        this.selectedUsers = audience;
        this.showSelectedUsers = true;
      } else {
        this.toastService.show('No users where found with provided ids', {classname: 'bg-danger text-light'});
      }
    });
  }

  public removeUser(selectedUser: any): void {
    this.selectedUsers = this.selectedUsers.filter((user) => user?.userId !== selectedUser?.userId);
    if (!this.selectedUsers || this.selectedUsers?.length === 0) {
      this.notificationForm.patchValue({['audienceIds']: null});
      this.showSelectedUsers = false;
    } else {
      this.notificationForm.patchValue({['audienceIds']: this.selectedUsers.map((user) => { return user?.userId})});
    }
  }

  public addMoreUsers(): void {
    const userIds = this.selectedUsers?.map((user: any) => {return user?.userId})
    this.tmpUserIds = userIds?.join('\n');
    this.showSelectedUsers = false;
  }

  public checkSelectedAudience(): boolean {
    return !(this.form?.audience?.value === AudienceEnum.SINGLE_USERS || this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPETITION || this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPANY || this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_CHALLENGE);
  }

  public checkSelectedActions(): boolean {
    return !(this.form?.action?.value === ActionsEnum.OPEN_HOME || this.form?.action?.value === ActionsEnum.OPEN_PROFILE);
  }

  public showActionSelect(action: ActionOptions): boolean {
    switch (action) {
      case ActionOptions.COMPANY:
        return this.form?.action?.value === ActionsEnum.OPEN_COMPANY && this.form?.audience?.value !== AudienceEnum.ALL_USERS_IN_COMPANY &&
        this.form?.audience?.value !== AudienceEnum.ALL_USERS_IN_COMPETITION;
        break;
      case ActionOptions.COMPETITION:
        return this.form?.action?.value === ActionsEnum.OPEN_COMPETITION && this.form?.audience?.value !== AudienceEnum.ALL_USERS_IN_COMPETITION;
        break;
      default:
        return true;
        break;
    }
  }

  public checkActionDetail() {
    if ([ActionsEnum.OPEN_COMPANY, ActionsEnum.OPEN_PRIZE, ActionsEnum.OPEN_COMPETITION, ActionsEnum.OPEN_CHALLENGE, ActionsEnum.OPEN_ALL_TOGETHER_CHALLENGE].includes(this.form.action.value)) {
      this.notificationForm.patchValue({['actionDetail']: null});
    }
  }

  public checkAudienceValidators(): void {
    this.notificationForm.patchValue({['audienceIds']: null});
    this.checkActionDetail();
    if (!this.checkSelectedAudience()) {
      this.form["audienceIds"].addValidators([Validators.required]);
      this.form["audienceIds"].updateValueAndValidity();
    } else {
      this.form["audienceIds"].clearValidators();
      this.form["audienceIds"].updateValueAndValidity();
    }
  }

  public setRecurringSelection(event: any): void {
    this.recurring = event?.dates?.length > 0 ? event : undefined;
  }

  private initForm(): void {
    this.notificationForm = this.formBuilder.group({
      title: [!this.notification || !this.notification?.title ? 'Healthy Virtuoso' : this.notification?.title, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      body: [!this.notification || !this.notification?.body ? null : this.notification?.body, [Validators.required, Validators.minLength(3), Validators.maxLength(178)]],
      imageUrl: [!this.notification || !this.notification?.imageUrl ? null : this.notification?.imageUrl],
      audience: [!this.notification || !this.notification?.audience ? 'ALL_USERS' : this.notification?.audience, [Validators.required]],
      audienceIds: [null],
      locale: [!this.notification || !this.notification?.locale ? 'it' : this.notification?.locale, [Validators.required]],
      action: [this._extractCorrectAction(this.notification), [Validators.required]],
      actionDetail: [!this.notification || !this.notification?.actionDetail ? null : {id: Number(this.notification?.actionDetail)}],
      delivery: ['NOW'],
      scheduledAt: [new Date()?.toISOString()],
      deliveryDateHour: ['09'],
      deliveryDateMinute: ['00'],
      imageName: [!this.notification || !this.notification?.imageUrl ? null : this.notification?.title],
      imageType: [null],
    });
    this.patchDestinationIds();
    this.patchActionDetail();
    this.patchDeliveryData();
  }

  private _extractCorrectAction(notification: any): ActionsEnum {
    if (!notification || !notification?.action && !notification?.link) {
      return ActionsEnum.OPEN_HOME;
    } else if (!notification || !notification?.action && notification?.link) {
      if (this.notification) {
        this.notification.action = ActionsEnum.OPEN_LINK;
      }
      return ActionsEnum.OPEN_LINK;
    } else if (!!notification?.action) {
      return notification?.action;
    }
  }

  private async patchDestinationIds() {
    if(!this.notification && this.specificUsers) {
      this.notificationForm.patchValue({['audience']: AudienceEnum.SINGLE_USERS});
      this.notificationForm.patchValue({['audienceIds']: this.specificUsers});
      this.getUserIds(this.specificUsers);
    }
    if (!this.notification || !this.notification?.audience || !this.notification?.destinationIds || this.notification?.destinationIds?.length === 0) return null;
    switch (this.notification?.audience) {
      case AudienceEnum.ALL_USERS_IN_COMPETITION:
        const competition =  await firstValueFrom(this.competitionsService.getCompetitionData(this.notification?.destinationIds[0]));
        this.notificationForm.patchValue({['audienceIds']: competition});
        break;
      case AudienceEnum.ALL_USERS_IN_COMPANY:
        const company =  await firstValueFrom(this.companiesService.getCompanyData(this.notification?.destinationIds[0]));
        this.notificationForm.patchValue({['audienceIds']: company});
        break;
      case AudienceEnum.SINGLE_USERS:
        this.notificationForm.patchValue({['audienceIds']: this.notification?.destinationIds});
        this.getUserIds(this.notification?.destinationIds);
        break;
    }
  }

  private async patchActionDetail() {
    if (this.notification?.link && this.notification?.action === ActionsEnum.OPEN_LINK) this.notificationForm.patchValue({['actionDetail']: this.notification?.link});
    if (!this.notification || !this.notification?.action || !this.notification?.actionDetail) return null;
    switch (this.notification?.audience) {
      case ActionsEnum.OPEN_COMPETITION:
        const competition =  await firstValueFrom(this.competitionsService.getCompetitionData(Number(this.notification?.actionDetail)));
        this.notificationForm.patchValue({['actionDetail']: competition});
        break;
      case ActionsEnum.OPEN_COMPANY:
        const company =  await firstValueFrom(this.companiesService.getCompanyData(Number(this.notification?.actionDetail)));
        this.notificationForm.patchValue({['actionDetail']: company});
        break;
      case ActionsEnum.OPEN_PRIZE:
        const prize =  await firstValueFrom(this.prizesService.getPrizeData(Number(this.notification?.actionDetail)));
        this.notificationForm.patchValue({['actionDetail']: prize});
        break;
    }
  }

  private patchDeliveryData() {
    if(!this.notification && this.specificDate) {
      this.notificationForm.patchValue({['delivery']: 'SPECIFIC_DATE'});
      this.notificationForm.patchValue({['scheduledAt']: this.specificDate?.toISOString()});
      const hour = this.specificDate.getHours();
      this.notificationForm.patchValue({['deliveryDateHour']: hour ? (hour < 10) ? "0"+hour : hour : '09'});
    }
    if (!this.notification || !this.notification?.scheduledAt) return null;
    const hour = this.notification?.scheduledAtHour;
    this.notificationForm.patchValue({['deliveryDateHour']: hour ? (hour < 10) ? "0"+hour : hour : '09'});
    if (this.notification?.scheduledAt && !this.notification?.recurringGroupId) {
      this.notificationForm.patchValue({['delivery']: 'SPECIFIC_DATE'});
      this.notificationForm.patchValue({['scheduledAt']: new Date(this.notification?.scheduledAt)?.toISOString()});
    } else if (this.notification?.scheduledAt && this.notification?.recurringGroupId && this.notification?.recurringInfo) {
      this.recurring = JSON.parse(this.utils.decryptData(this.notification?.recurringInfo));
      this.notificationForm.patchValue({['delivery']: 'RECURRING'});
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
