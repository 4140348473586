import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {UsersApiService} from "@service/users/users-api.service";
import {UserDiaryElementInterface} from "@interface/user/user-diary-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "../utils/utils.service";
import {SortDirection} from "../../../shared/types/common/sort-direction.type";

@Injectable({
  providedIn: 'root'
})
export class UserDiaryService {
  public _userDiarySearch$ = new Subject<void>();
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchSubscription: Subscription;
  private _userDiaryTotalRecords$ = new BehaviorSubject<number>(0);

  constructor(private usersApiService: UsersApiService, private utils: UtilsService) {
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _userDiary$ = new BehaviorSubject<UserDiaryElementInterface[]>([]);

  get userDiary$() {
    return this._userDiary$.asObservable();
  }

  get totalRecords$() {
    return this._userDiaryTotalRecords$.asObservable();
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public removeSearchSubscribe(): void {
    this.searchSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initUserDiaryListener(): void {
    if (this.userId) {
      this.searchSubscription = this._userDiarySearch$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(50),
        switchMap(() => this.usersApiService.getUserDiary(this._extractDiarySearchParams())),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._userDiary$.next(result.data);
        this._userDiaryTotalRecords$.next(result.size);
      });
    }
  }

  public clearFilters(): void {
    this.userId = undefined;
    this.searchTerm = undefined;
  }

  private _extractDiarySearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
      },
      userId: this.userId,
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._userDiarySearch$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

}
