import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {catchError, Observable, Subject, takeUntil, throwError} from "rxjs";
import {CompaniesService} from "@service/companies/companies.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-webapp-modal',
  templateUrl: './webapp-modal.component.html',
  styleUrls: ['./webapp-modal.component.scss']
})
export class WebappModalComponent implements OnInit {
  @Input() companyId: any;
  public showAreYouSure: boolean = false;
  public formSubmitted: boolean;
  public activeId: number = 1;
  public color: any | undefined;
  public webappForm: UntypedFormGroup;
  public working$: Observable<boolean>;
  public webappImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public translations: any[] = [];
  public codes: any[] | undefined;
  private destroy$: any = new Subject();

  constructor(private _formBuilder: UntypedFormBuilder,
              public utils: UtilsService,
              public companiesService: CompaniesService,
              private _formService: FormsService,
              private _toastService: ToastService,
              private _utilsService: UtilsService) {
  }

  get valid() {
    return this.webappForm.valid;
  }

  get form() {
    return this.webappForm.controls;
  }

  ngOnInit(): void {
    this._fetchWebappConfiguration();
    this._getInviteCodes();
  }

  public validSubmit() {
    if (this.webappForm.valid) {
      this._editWebapp();
    } else {
      this._formService.findInvalidControlsRecursive(this.webappForm);
    }
  }

  public companyWebappLink(lang: string): string {
    return [this._utilsService.webappEndpoint, lang, 'company', this.form?.uniqueCode?.value].join('/');
  }

  public navigateToWebapp(lang: string): void {
    window.open(this.companyWebappLink(lang), '_blank');
  }

  public setImage(image: any, control: string): void {
    this.webappForm.patchValue({
      [control]: image ? image?.originalUrl : undefined,
    });
  }

  public checkWebappImageError(image): string | undefined {
    if (this.form[image]?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkWebappImageValue(image): object | undefined {
    if (this.form[image]?.value) {
      return {
        originalUrl: this.form[image]?.value,
        name: [this.form.uniqueCode?.value?.replace(/\s/g, "_"), 'detail_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  private _editWebapp() {
    const form = this.webappForm.getRawValue();
    form.theme = this.color;
    this.companiesService.editCompanyWebappConfigOptions(this.companyId, form).pipe(takeUntil(this.destroy$), catchError(this.handleError)).subscribe((result: any) => {
      this._toastService.show('Webapp edited successfully', {classname: 'bg-success text-light'});
      this.webappForm.patchValue(result);
      this.showAreYouSure = false;
      this.formSubmitted = false;
    });
  }

  private _fetchWebappConfiguration(): void {
    this.companiesService.getWebapp(this.companyId).pipe(takeUntil(this.destroy$), catchError(this.handleError)).subscribe((config: any) => {
      if (config?.uniqueCode && config?.enabled) {
        this.companiesService.addCompanyCodes([{
          id: Math.random().toString(36).substr(2, 9),
          code: config?.uniqueCode,
          totalUnits: 1,
          currentUses: 0
        }], this.companyId).pipe(takeUntil(this.destroy$), catchError(this.handleError)).subscribe((result: any) => {
        });
        this.initForm(config)
      } else {
        this.companiesService.editCompanyWebappConfigOptions(this.companyId, {
          enabled: true,
          title: 'Health Program'
        }).pipe(takeUntil(this.destroy$), catchError(this.handleError)).subscribe((result: any) => {
          this.companiesService.addCompanyCodes([{
            id: Math.random().toString(36).substr(2, 9),
            code: result?.uniqueCode,
            totalUnits: 1,
            currentUses: 0
          }], this.companyId).pipe(takeUntil(this.destroy$), catchError(this.handleError)).subscribe((result: any) => {
          });
          this.initForm(result);
        });
      }
    })
  }

  private _getInviteCodes(): void {
    this.companiesService.getInviteCodes(this.companyId).subscribe((result) => {
      this.codes = result?.data;
    });
  }

  private initForm(config: any) {
    this.translations = config?.languageToTranslationUrl ? config?.languageToTranslationUrl : [];
    this.webappForm = this._formBuilder.group({
      enabled: [!config || !config?.enabled ? false : config?.enabled],
      showLeaderboards: [!config || !config?.showLeaderboards ? false : config?.showLeaderboards],
      showTeams: [!config || !config?.showTeams ? false : config?.showTeams],
      showMemberships: [!config || !config?.showMemberships ? false : config?.showMemberships],
      showPrizes: [!config || !config?.showPrizes ? false : config?.showPrizes],
      showStats: [!config || !config?.showStats ? false : config?.showStats],
      showCompetitions: [!config || !config?.showCompetitions ? false : config?.showCompetitions],
      showMonitorSection: [!config || !config?.showMonitorSection ? false : config?.showMonitorSection],
      showNews: [!config || !config?.showNews ? false : config?.showNews],
      showFaqs: [!config || !config?.showFaqs ? false : config?.showFaqs],
      uniqueCode: [!config || !config?.uniqueCode ? null : config?.uniqueCode],
      webImageLight: [!config || !config?.webImageLight ? null : config?.webImageLight],
      webImageDark: [!config || !config?.webImageDark ? null : config?.webImageDark],
      joinCode: [!config || !config?.joinCode ? null : config?.joinCode],
      message: [!config || !config?.message ? null : config?.message],
      title: [!config || !config?.title ? 'Health Program' : config?.title],
      theme: [!config || !config?.theme ? '#2196F3' : config?.theme],
    });
    this.color = this.form?.theme?.value;
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    return throwError(() => err);
  }

}
