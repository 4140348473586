import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {PrizesService} from "@service/prizes/prizes.service";
import {Subject, takeUntil} from "rxjs";
import {AudienceEnum} from "@enum/audience/audience.enum";

@Component({
  selector: 'app-prize-select',
  templateUrl: './prize-select.component.html',
  styleUrls: ['./prize-select.component.scss']
})
export class PrizeSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Output() selectedPrize = new EventEmitter();
  @Input() error: boolean = false;
  @Input() restriction: AudienceEnum = AudienceEnum.ALL_USERS;
  @Input() restrictionId: any | undefined;
  @Input() selected: any | undefined;
  @Input() complete: boolean = false;
  @Input() multiple: boolean = false;
  public prizes: any[] = [];
  private destroy$: any = new Subject();

  constructor(private prizesService: PrizesService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.prizesService?.clearFilters();
    this.prizesService.prizeList$.pipe(takeUntil(this.destroy$)).subscribe((list: any) => {
      this.prizes = [...list];
      this.prizes = [...this.prizes]
      this.cdr.detectChanges();
    });
    this.prizesService.pageSize = 25;
    this.prizesService.initSearchPrizesListener();
    this.setPrizeFilters();
  }

  public setPrizeFilters() {
    this.prizesService.clearFilters();
    switch (this.restriction) {
      case AudienceEnum.ALL_USERS:
      case AudienceEnum.SINGLE_USERS:
        this.prizesService._search$.next();
        break;
      case AudienceEnum.ALL_USERS_IN_COMPANY:
        if (!this.restrictionId) {
          this.prizesService._search$.next();
        } else {
          this.prizesService.setCompanyFilter(!this.restrictionId?.id ? this.restrictionId : this.restrictionId?.id)
        }
        break;
      case AudienceEnum.ALL_USERS_IN_COMPETITION:
        if (!this.restrictionId) {
          this.prizesService._search$.next();
        } else {
          this.prizesService.setCompetitionFilter(!this.restrictionId?.id ? this.restrictionId : this.restrictionId?.id)
        }
        break;
	    case AudienceEnum.TYPE:
		    if (!this.restrictionId) {
			    this.prizesService._search$.next();
		    } else {
			    this.prizesService.prizeType = this.restrictionId;
		    }
		    break;
    }
  }

  public selectPrize(event: any): void {
    if (event) {
      this.selectedPrize.emit(event);
    } else {
      this.selectedPrize.emit(null);
    }
  }

  public stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  public returnItemDescription(item): string {
    if (!item?.contextType) {
      return this.extractContextTypeAndDescription(item, item?.context?.type, item?.context?.detailsDescription);
    } else {
      return this.extractContextTypeAndDescription(item, item?.contextType, item?.contextDetailsDescription);
    }
  }

  public search(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item?.context?.detailsDescription?.toString()?.toLocaleLowerCase()?.indexOf(term) > -1 || item?.contextDetailsDescription?.toLocaleLowerCase().indexOf(term) > -1 || item?.title?.toLocaleLowerCase().indexOf(term) > -1 || item?.prizeType?.toLocaleLowerCase().indexOf(term) > -1 || item?.contextType?.toLocaleLowerCase().indexOf(term) > -1;
  }

  public setSearchTerm(event: any): void {
    this.prizesService.searchTerm = event?.term;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setPrizeFilters();
  }

  ngOnDestroy() {
    this.destroy$?.next();
    this.destroy$?.complete();
    this.prizesService?.clearFilters();
    this.prizesService?.clearFilter();
    this.prizesService?.removeSearchPrizesSubscribe();
    this.selected = null;
  }

  private extractContextTypeAndDescription(item: any, contextType: any, contextDetailsDescription: any): string {
    switch (contextType?.toUpperCase()) {
      case 'GLOBAL':
        return [`[${item?.id}]`, `[${contextType?.toUpperCase()}]`, item?.title, `(${this.stripHtml(item?.description)})`].join(' - ');
        break;
      case 'COMPETITION':
      case 'COMPANY':
        return [`[${item?.id}]`, `[${contextType?.toUpperCase()}]`, `[${contextDetailsDescription?.toUpperCase()}]`, item?.title, `(${this.stripHtml(item?.description)})`].join(' - ');
        break;
    }
  }

}
