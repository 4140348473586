import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'companies'
})

export class RemoveCompaniesPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return undefined;
    }
    if (value?.length > 0) {
      return undefined;
    }
    let finalCompanies = [];
    const companies = value.split(',');
    companies.map((company) => {
      const lastIndex = company.lastIndexOf('|');
      finalCompanies.push(company.substring(0, lastIndex === -1 ? company.length : lastIndex + 1)?.replace('|', ''));
      return company;
    });
    return finalCompanies.join(', ');
  }
}
