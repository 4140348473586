import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {NotificationsService} from "@service/notifications/notifications.service";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {Router} from "@angular/router";
import {NotificationAudienceEnum} from "@enum/notification-audience/notification-audience.enum";
import {UserListService} from "@service/users/user-list.service";
import {UtilsService} from "@service/utils/utils.service";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {NotificationDeliveryOptionEnum} from "@enum/notifications/notification-delivery-option/notification-delivery-option.enum";

@Component({
  selector: 'app-send-notification-modal',
  templateUrl: './send-notification-modal.component.html',
  styleUrls: ['./send-notification-modal.component.scss']
})
export class SendNotificationModalComponent implements OnInit, OnDestroy {
  @Input() type: NotificationTypeEnum;
  public sendingPush$: Observable<boolean>;
  public notificationUsers: any = undefined;
  public notificationData: any = undefined;
  public notificationDelivery: any = undefined;
  public users: UserElementInterface[];
  public edit: boolean;
  public editData: any;
  public duplicate: boolean;
  public notificationAudience: NotificationAudienceEnum = NotificationAudienceEnum.SPECIFIC;
  public notificationAudienceEnum = NotificationAudienceEnum;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              public userListService: UserListService, private utils: UtilsService,
              private notificationsService: NotificationsService, private router: Router) {
  }

  ngOnInit(): void {
    this._closeModal();
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  public setNotificationAudience(data: any): void {
    this.notificationUsers = data;
  }

  public setNotificationData(data: any): void {
    this.notificationData = data;
  }

  public setNotificationDelivery(data: any): void {
    this.notificationDelivery = data;
  }

  public open(users: any[], edit?: boolean, editData?: any, duplicate?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      this.sendingPush$ = this.notificationsService.sending$;
      this.edit = edit;
      this.editData = editData;
      this.duplicate = duplicate;
      if (!edit || edit && editData?.audience === AudienceEnum.SINGLE_USERS) {
        this.notificationAudience = NotificationAudienceEnum.SPECIFIC;
        if (this.utils.isArrayOfNumbers(users)) {
          this.getUserDetailAndSetup(users);
        } else {
          this.users = users;
          this.notificationUsers = {audience: AudienceEnum.SINGLE_USERS, data: {users}};
        }
      } else if (edit && editData?.audience !== AudienceEnum.SINGLE_USERS) {
        this.notificationAudience = NotificationAudienceEnum.OTHER;
        this.setNotificationEditUsers(editData);
      }
      this.setNotificationEditData(editData);
      this.setNotificationEditDelivery(editData);
    })
  }

  private setNotificationEditUsers(data: any): void {
    this.notificationUsers = {audience: data?.audience, data: {companies: [], challenges: [], competitions: [], destinationIds: data?.destinationIds}};
  }

  private setNotificationEditData(data: any): void {
    this.notificationData = {
      title: data?.title,
      message: data?.body,
      image: data?.imageUrl,
      locale: data?.locale,
      imageName: data?.imageName,
      imageType: data?.imageType,
      fileSource: data?.fileSource,
      link: data?.link,
      action: data?.action as ActionsEnum,
      actionDetail: data?.actionDetail,
      note: data?.note,
    }
  }

  private setNotificationEditDelivery(data: any): void {
    if (data?.scheduledAt && data?.scheduledAtHour) {
      this.notificationDelivery = {startsAt: data?.scheduledAt, startsAtHour: data?.scheduledAtHour, deliveryOption: NotificationDeliveryOptionEnum.FUTURE};
    } else {
      this.notificationDelivery = {deliveryOption: NotificationDeliveryOptionEnum.NOW};
    }
  }

  public sendPush(data: any): void {
    this.notificationsService.sendPushNotification(data);
  }

  public openMassiveNotification(): void {
    this.eventService.broadcast(EventEnum.CLOSE_NOTIFICATION_MODAL, null)
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['notifications/send'])
    );
    window.open(url, '_blank');
  }

  private getUserDetailAndSetup(users: any[]) {
    this.notificationUsers = {audience: AudienceEnum.SINGLE_USERS, data: {}};
    const users$ = this.userListService.userDetailArray(users);
    users$.subscribe((users) => {
      this.users = users;
      this.notificationUsers = {audience: AudienceEnum.SINGLE_USERS, data: {users}};
    });
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_NOTIFICATION_MODAL, (reason: string | undefined) => {
      this.notificationData = null;
      this.notificationDelivery = null;
      this.notificationUsers = null;
      this.modalRef?.dismiss(reason);
    });
  }

}
