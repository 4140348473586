import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class UtilityApiService {

  constructor(private http: HttpService) {
  }

  public getQrList(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.qr.list, params);
  }

  public getTagsList(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.tags.list, params);
  }

  public getContestMetricsList(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.contestMetrics.list, params);
  }

  public createQrCode(params: any): Observable<any> {
    return this.http.post(endpoints.utility.qr.create, params);
  }

  public editAutomaticBanRule(params: any): Observable<any> {
    return this.http.post(endpoints.utility.automaticBanRules.edit, params);
  }

  public getAutomaticBanRules(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.automaticBanRules.list, params);
  }

  public getBlacklistSourceDetail(data: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.utility.blackListSources.detail, placeholders);
    return this.http.get(url);
  }

  public getAutomaticBanUsers(data: any) {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.utility.automaticBanRules.detail, placeholders);
    return this.http.get(url);
  }

  public getBlacklistSources(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.blackListSources.list, params);
  }

  public createEditBlacklistSource(params: any): Observable<any> {
    return this.http.post(endpoints.utility.blackListSources.add, params);
  }

  public activateDeactivateCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(params?.active ? endpoints.invites.enable : endpoints.invites.disable, placeholders);
    return this.http.post(url, params);
  }

  public deactivateCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.invites.disable, placeholders);
    return this.http.post(url, params);
  }

  public deleteCode(code: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: code[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.invites.delete, placeholders);
    return this.http.delete(url, code);
  }

  public deleteBlacklistSource(source: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: source[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.utility.blackListSources.delete, placeholders);
    return this.http.delete(url, {});
  }

}
