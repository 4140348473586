import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {NotificationDeliveryOptionEnum} from "@enum/notifications/notification-delivery-option/notification-delivery-option.enum";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-notification-summary',
  templateUrl: './notification-summary.component.html',
  styleUrls: ['./notification-summary.component.scss']
})
export class NotificationSummaryComponent implements OnInit, OnDestroy {
  @Output() goBackStepEvent = new EventEmitter();
  @Input() edit: any;
  @Input() massiveNotification: boolean;
  @Input() notificationUsers: any;
  @Input() notificationData: any;
  @Input() notificationDelivery: any;
  @Input() sendingPush$: Observable<boolean>;
  @Output() confirmSend: EventEmitter<any> = new EventEmitter();
  public actionsEnum = ActionsEnum;
  public audienceEnum = AudienceEnum;
  public deliveryEnum = NotificationDeliveryOptionEnum;
  public showAreYouSure: boolean = false;
  public notificationNotes: string = undefined;
  public usersCollapsed: boolean = true;
  private removeUserSubscription: Subscription;
  private removeCompanySubscription: Subscription;
  private removeChallengeSubscription: Subscription;
  private removeCompetitionSubscription: Subscription;

  constructor(private eventService: EventService, public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.subscribeRemoveEvents();
    if (this.notificationData?.note) {
      this.notificationNotes = this.notificationData?.note;
    }
  }

  public goBackStep(): void {
    this.goBackStepEvent.emit();
  }

  public confirmSendNotification(): void {
    let notificationData = {...this.notificationData, ...{note: this.notificationNotes}, ...{id: this.edit?.id}};
    const push = {data: notificationData, audience: this.notificationUsers, delivery: this.notificationDelivery}
    this.confirmSend.emit(push);
  }

  public extractUsersLength(): any {
    switch (this.notificationUsers?.audience) {
      case AudienceEnum.SINGLE_USERS:
        return this.notificationUsers?.data?.users?.length;
        break;
      case AudienceEnum.ALL_USERS_IN_CHALLENGE:
        return this.notificationUsers?.data?.challenges?.length;
        break;
      case AudienceEnum.ALL_USERS_IN_COMPANY:
        return this.notificationUsers?.data?.companies?.length;
        break;
      case AudienceEnum.ALL_USERS_IN_COMPETITION:
        return this.notificationUsers?.data?.competitions?.length;
        break;
    }
  }

  public showImage(): void {
    window.open(this.notificationData?.imageUrl, '_blank');
  }

  public extractStartHourDate(): string {
    const seconds = this.notificationDelivery?.startsAtHour * 60 * 60;
    const hh = new Date(seconds * 1000).toISOString().substr(11, 8).substring(0,5);
    return [this.notificationDelivery?.startsAt, 'at ', hh].join(' ');
  }

  ngOnDestroy() {
    this.removeUserSubscription?.unsubscribe();
    this.removeCompanySubscription?.unsubscribe();
    this.removeChallengeSubscription?.unsubscribe();
    this.removeCompetitionSubscription?.unsubscribe();
  }

  private subscribeRemoveEvents(): void {
    this.removeUserSubscription = this.eventService.subscribe(EventEnum.REMOVE_USER_FROM_NOTIFICATION_LIST, (selectedUser: any) => {
      if (this.notificationUsers?.data?.users?.length > 0) {
        this.notificationUsers.data.users = this.notificationUsers?.data?.users.filter((user) => user !== selectedUser);
      }
    });
    this.removeCompanySubscription = this.eventService.subscribe(EventEnum.REMOVE_COMPANY_FROM_NOTIFICATION_LIST, (selectedCompany: any) => {
      if (this.notificationUsers?.data?.companies?.length > 0) {
        this.notificationUsers.data.companies = this.notificationUsers?.data?.companies.filter((company) => company !== selectedCompany);
      }
    });
    this.removeChallengeSubscription = this.eventService.subscribe(EventEnum.REMOVE_CHALLENGE_FROM_NOTIFICATION_LIST, (selectedChallenge: any) => {
      if (this.notificationUsers?.data?.challenges?.length > 0) {
        this.notificationUsers.data.challenges = this.notificationUsers?.data?.challenges.filter((challenge) => challenge !== selectedChallenge);
      }
    });
    this.removeCompetitionSubscription = this.eventService.subscribe(EventEnum.REMOVE_COMPETITION_FROM_NOTIFICATION_LIST, (selectedCompetition: any) => {
      if (this.notificationUsers?.data?.competitions?.length > 0) {
        this.notificationUsers.data.competitions = this.notificationUsers?.data?.competitions.filter((competition) => competition !== selectedCompetition);
      }
    });
  }

}
