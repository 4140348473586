import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-link-validator',
  templateUrl: './link-validator.component.html',
  styleUrls: ['./link-validator.component.scss']
})
export class LinkValidatorComponent implements OnInit, OnDestroy {
	public link: any;
  @Input() parentForm: UntypedFormGroup;
  @Input() editDetail: any;
  @Input() error: boolean;
  @Input() selected: string;
	@Output() selectedLink = new EventEmitter();

  constructor(private utils: UtilsService) {
  }

  get form() {
    return this.parentForm.controls;
  }
	
	public selectLink(event: any): void {
		this.selectedLink.emit(this.link || null);
	}
	
	
	ngOnInit(): void {
		if (this.selected) {
			this.link = this.selected;
		}
  }

  ngOnDestroy() {
  }

}
