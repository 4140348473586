<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!editingMotivation">{{'ADD_BAN_MOTIVATION' | translate}}</h5>
    <h5 *ngIf="editingMotivation">{{'EDIT_BAN_MOTIVATION' | translate}}</h5>
    <div class="d-flex">
      <button [disabled]="(creating$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_BAN_MOTIVATION_MODAL">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="submitForm()" [formGroup]="motivationForm">
      <div class="mb-3">
        <label class="form-label font-weight-semibold" for="description">Motivation description</label>
        <div>
          <textarea [ngClass]="{'is-invalid': formSubmitted && controls.reason.errors}"
                    class="form-control description-textarea"
                    formControlName="reason"
                    id="description"
                    rows="4"></textarea>
          <div *ngIf="controls.reason.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && controls.reason.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="controls?.reason?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: controls?.reason?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="controls?.reason?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: controls?.reason?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-2 flex-row-reverse">
        <button [disabled]="(creating$ | async)" class="btn btn-primary waves-effect waves-light" type="submit">
          <i *ngIf="creating$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="creating$ | async">
            <span *ngIf="!editingMotivation">Creating</span>
            <span *ngIf="editingMotivation">Saving</span>
          </span>
          <span [hidden]="(creating$ | async)">
            <span *ngIf="!editingMotivation">Create</span>
            <span *ngIf="editingMotivation">Save</span>
          </span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
