import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {NotificationsService} from "@service/notifications/notifications.service";

@Component({
  selector: 'app-select-audience',
  templateUrl: './select-audience.component.html',
  styleUrls: ['./select-audience.component.scss']
})
export class SelectAudienceComponent implements OnInit {
  @Input() selectedAudience: any;
  @Input() massiveNotification: boolean;
  @Output() selectedAction = new EventEmitter();
  public list$: any;
  public selected: any = 'ALL_USERS';

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {
    this.getList();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selected = (changes?.selectedAudience?.currentValue);
  }

  public updateSelectedAction(event: any): void {
    this.selectedAction.emit(this.selected);
  }

  private getList(): void {
    this.list$ = this.notificationsService.getNotificationAudience();
  }

}
