<header class="table-topbar" id="page-topbar-filters">
  <div [class.align-items-center]="type === topbarTypeEnum.FITNESS_SLOTS" class="navbar-header">
    <div class="d-flex">
      <div class="row ms-2">
        <div class="col-sm-12 col-lg-12">
          <form *ngIf="type !== topbarTypeEnum.FITNESS_SLOTS" class="app-search d-none d-xl-inline-block">
            <div class="position-relative">
              <input (ngModelChange)="fieldsChange($event)" [(ngModel)]="searchTerm"
                     class="form-control form-control-dark"
                     name="searchTerm" placeholder="{{'SEARCH' | translate}}" type="text">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>
          <div *ngIf="service && type" class="d-inline-block">
            <ng-container *ngIf="type !== topbarTypeEnum.RAW_DATA">
              <button aria-controls="offcanvasNavbar" class="btn btn-dark mb-0 ms-2" data-bs-target="#offcanvasNavbar"
                      data-bs-toggle="offcanvas" type="button">
                <i *ngIf="!service?.isFilterApplied()" class="bx bx bx-filter me-1"></i>
                <span *ngIf="service?.isFilterApplied()" class="badge bg-danger me-2">
                <i class="bx bx bx-filter me-1"></i>
                  {{service?.isFilterApplied(true)}}
              </span>
                <span *ngIf="service.totalRecords$ | async as records" class="me-2">{{records}} results</span>
                <span *ngIf="!(service.totalRecords$ | async)" class="me-2">0 results</span>
              </button>
            </ng-container>
            <ng-container *ngIf="type === topbarTypeEnum.RAW_DATA" >
              <button [attr.aria-controls]="id" [attr.data-bs-target]="'#' + id" class="btn btn-dark mb-0 ms-2"
                      data-bs-toggle="offcanvas" type="button">
                <i *ngIf="!service?.filterApplied()" class="bx bx bx-filter me-1"></i>
                <span *ngIf="service?.filterApplied && service?.filterApplied()" class="badge bg-danger me-2">
                <i class="bx bx bx-filter me-1"></i>
                  {{service?.filterApplied(true)}}
              </span>
                <span class="me-2">{{total$ | async}} results</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="type === topbarTypeEnum.FITNESS_SLOTS" class="d-flex">
      <app-datepicker (dateSelected)="emitSearchTerm.emit($event)"></app-datepicker>
    </div>
    <div class="d-flex align-items-center">
      <button (click)="createNewPrize()" *ngIf="type === topbarTypeEnum.PRIZE_LIST_PAGE_DETAIL"
              class="btn btn-primary mb-0 me-2">
        Create new prize
      </button>
      <button (click)="createNewTeam()" *ngIf="type === topbarTypeEnum.COMPANY_TEAMS" class="btn btn-primary mb-0 me-2">
        Add teams
      </button>
      <button (click)="addCodes()" *ngIf="type === topbarTypeEnum.COMPANY_CODES" class="btn btn-primary mb-0 me-2">
        Add codes
      </button>
      <button (click)="createNewCompetition()" *ngIf="type === topbarTypeEnum.COMPETITION_LIST"
              class="btn btn-primary mb-0 me-2">
        Create new competition
      </button>
      <button (click)="addMembers()" *ngIf="type === topbarTypeEnum.COMPANY_MEMBERSHIPS"
              class="btn btn-primary mb-0 me-2">
        Add members
      </button>
      <ng-container *ngIf="type === topbarTypeEnum.RAW_DATA;">
        <button *ngIf="!showAreYouSure" (click)="showAreYouSure = true;"
                class="btn btn-primary mb-0 me-2">
          Force process error data
        </button>
        <ng-container *ngIf="showAreYouSure">
          <span class="me-3 font-weight-bold">{{'FORCE_PROCESS_LAST_24_HOURS' | translate}} </span>
          <button *ngIf="showAreYouSure" class="btn btn-primary me-2" (click)="processErrorData.emit(false); showAreYouSure = false;">
            <span>
              {{'ALL' | translate}}
            </span>
          </button>
          <button *ngIf="showAreYouSure" class="btn btn-secondary me-2" (click)="processErrorData.emit(true); showAreYouSure = false;">
            <span>
              {{'JUST_IN_ERROR' | translate}}
            </span>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showColumnPicker" >
        <app-column-picker (updateVisibleColumns)="updateColumns($event)" [availableColumns]="availableColumns"
                           [visibleColumns]="selected"></app-column-picker>
      </ng-container>
    </div>
  </div>
</header>
