import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {UtilsService} from "@service/utils/utils.service";
import {ContentCategoriesApiService} from "@service/content/content-categories-api.service";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class ContentCategoriesService {
  public sorting: any | undefined;
  private searchFilters: any | undefined = {}
  private pagination: { page, size } = {page: 1, size: 25};
  private readonly activeFilters: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly observable: Observable<any> = this.activeFilters.asObservable();

  constructor(private contentCategoriesApiService: ContentCategoriesApiService, private _utilsService: UtilsService, private _eventService: EventService, private _toastService: ToastService) {
  }

  private static get actions(): any[] {
    return [
      {
        clickAction: 'delete',
        disabledIf: 'sendAt',
        class: ['pointer', 'danger', 'font-size-18'],
        tooltip: 'DELETE',
        placement: 'bottom',
        icon: 'bx bx-trash'
      },
      {
        clickAction: 'duplicate',
        class: ['pointer', 'font-size-18'],
        tooltip: 'DUPLICATE',
        placement: 'bottom',
        icon: 'bx bx-copy'
      },
      {
        clickAction: 'edit',
        disabledIf: 'sendAt',
        class: ['pointer', 'font-size-18'],
        tooltip: 'EDIT',
        placement: 'bottom',
        icon: 'bx bx-pencil'
      },
    ]
  }

  private _adding$ = new BehaviorSubject<boolean>(false);

  public get adding$() {
    return this._adding$.asObservable();
  }

  public get api(): any {
    return this.contentCategoriesApiService;
  }

  public get pageSize() {
    return this.pagination.size;
  }

  public set pageSize(pageSize: number) {
    this.pagination.page = 1;
    this.pagination.size = pageSize;
    this.activeFilters.next(this.searchFilters);
  }

  public get page() {
    return this.pagination.page;
  }

  public set page(page: number) {
    this.pagination.page = page;
    this.activeFilters.next(this.searchFilters);
  }

  public get getActiveFilters(): any {
    return this.activeFilters.getValue();
  }

  public get columns(): any[] {
    return [
      {id: 1, canHide: false, visible: true, checkbox: true, label: '', align: 'center', class: ['fit-width']},
      {
        id: 2,
        canHide: true,
        visible: true,
        text: true,
        label: 'ID',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'id',
        sortable: true,
        sortKey: 'id',
        elementClasses: ['badge', 'badge-pill', 'badge-soft-primary'],
        clickAction: 'userDetail'
      },
	    {
		    id: 3,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'IMAGE',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'icon',
		    sortable: true,
		    sortKey: 'icon',
		    image: true
	    },
      {
        id: 9,
        canHide: true,
        visible: true,
        text: true,
        label: 'NAME',
        align: 'center',
        class: ['pointer'],
        elementKey: 'name',
        sortable: true,
        sortKey: 'name'
      },
	    {
		    id: 10,
		    canHide: true,
		    visible: true,
		    text: true,
		    label: 'ACTION',
		    align: 'center',
		    class: ['pointer', 'fit-width'],
		    elementKey: 'action',
		    sortable: true,
		    sortKey: 'action'
	    },
      {
        id: 16,
        canHide: false,
        visible: true,
        label: '',
	      class: ['pointer', 'fit-width'],
        isRowAction: true,
        rowActions: ContentCategoriesService.actions
      },
    ]
  }

  public setFilter(patch: any): void {
    Object.assign(this.searchFilters, patch);
    this.activeFilters.next(this.searchFilters);
  }

  public clearFilters(): void {
    this.searchFilters = {};
    this.activeFilters.next(this.searchFilters);
  }

  public filters(): any {
    return {
      filters: this.activeFilters.getValue(),
      sort: this.sorting,
      page: this.pagination.page,
      size: this.pagination.size
    }
  }

  public filterApplied(length?: boolean): boolean | number {
    const filters: any = this.activeFilters.getValue();
    if (!filters) return !length ? false : 0;
    if (Object.keys(filters)?.length > 0) {
      const obj = this._utilsService.clearObject(filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public addCategory(data: any): any {
    this._adding$.next(true);
    this.contentCategoriesApiService.add(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (typeof result === 'string') {
        this._toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this._toastService.show(!data?.id ? 'Category added' : 'Category edited', {classname: 'bg-success text-light'});
      }
      this._adding$.next(false);
      this._eventService.broadcast(EventEnum.CLOSE_CONTENT_CATEGORY_MODAL, null)
    });
  }
}
