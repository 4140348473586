<div *ngIf="checkIfFilterIsVisible(filter.REGISTRATION_DATE)" class="input-group me-2">
  <app-date-range-picker (dateRangeSelected)="service.registrationDate = $event"
                         [clearAll]="clearFilters.asObservable()"
                         [noLabel]="true"
                         [title]="'UTILS.REGISTRATION.DATE.RANGE'"></app-date-range-picker>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.LAST_APP_OPEN)" class="input-group me-2">
  <app-date-range-picker (dateRangeSelected)="service.lastAppAccessTs = $event"
                         [clearAll]="clearFilters.asObservable()"
                         [noLabel]="true"
                         [title]="'LAST_APP_OPEN'"></app-date-range-picker>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.LAST_SYNC)" class="input-group me-2">
  <app-date-range-picker (dateRangeSelected)="service.lastSync = $event"
                         [clearAll]="clearFilters.asObservable()"
                         [noLabel]="true"
                         [title]="'LAST_SYNC'"></app-date-range-picker>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.PROFILE_COMPLETED)" class="input-group me-2 scrollable-content">
  <label class="input-group-text">Profile completed</label>
  <select (change)="service.profileComplete = getValue($event, true)" class="form-select form-select-sm2"
          name="status">
    <option *ngFor="let status of searchFilters.boolean" [selected]="status.selected"
            [value]="status.value">{{status.label | translate}}</option>
  </select>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.USER_DELETED)" class="input-group me-2 scrollable-content">
  <label class="input-group-text">User Deleted</label>
  <select (change)="service.banned = getValue($event, true)" class="form-select form-select-sm2"
          name="status">
    <option *ngFor="let status of searchFilters.status" [selected]="status.selected"
            [value]="status.value">{{status.label | translate}}</option>
  </select>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.AVAILABLE_CREDITS)" class="input-group me-2 scrollable-content">
  <app-number-picker (numberSelected)="service.availableCredits = $event"
                     [clearAll]="clearFilters.asObservable()"
                     [title]="'AVAILABLE_CREDITS'"></app-number-picker>
</div>
<div *ngIf="checkIfFilterIsVisible(filter.PLATFORM)" class="input-group me-2 scrollable-content">
  <label class="input-group-text">Platform</label>
  <select (change)="service.platform = getValue($event)" class="form-select form-select-sm2"
          name="fitnessKit">
    <option *ngFor="let status of searchFilters.platform" [selected]="status.selected"
            [value]="status.value">{{status.label | translate}}</option>
  </select>
</div>
