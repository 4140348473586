import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-select-box-picker',
  templateUrl: './select-box-picker.component.html',
  styleUrls: ['./select-box-picker.component.scss']
})
export class SelectBoxPickerComponent implements OnInit {
  @Input() clearAll: Observable<boolean>;
  @Input() title: string | undefined = undefined;
  @Input() filters: any = undefined;
  @Input() boolean: boolean = false;
  @Input() multiple: boolean = false;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  private copiedFilters: any = undefined;
  private eventsSubscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    this.checkCopiedFilters();
    this.checkClearFilter();
  }

  public select(choice: Event): void {
    if (this.multiple) {
      this.checkCopiedFilters();
      this.copiedFilters.map((filter: any) => {
        if (filter.label === (choice.target as HTMLInputElement).id) {
          filter.selected = (choice.target as HTMLInputElement).checked;
        }
      });
      const selected = this.copiedFilters.filter((filter) => {
        return filter.selected;
      });
      if (selected?.length > 0) {
        const filtered = selected.map((filter: any) => {
          return filter.value;
        });
        this.selected.emit(filtered);
      } else {
        this.selected.emit(undefined);
      }
    } else {
      this.selected.emit(this.getValue(choice, this.boolean));
    }
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  private checkClearFilter(): void {
    if (this.clearAll) {
      this.eventsSubscription = this.clearAll.subscribe((clear) => {
        if (clear) {
          this.filters?.map((el: any) => {
            el.selected = !el.value;
            return el;
          });
        }
      });
    }
  }

  private checkCopiedFilters(): void {
    if (this.multiple && this.filters?.length > 0) {
      this.copiedFilters = JSON.parse(JSON.stringify(this.filters));
    }
  }


}
