import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class SustainabilityPlacesApiService {

  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.projects.places.list, params);
  }

  public delete(data: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.projects.places.delete, placeholders);
    return this.http.delete(url);
  }

  public edit(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.projects.places.edit, placeholders);
    return this.http.put(url, data);
  }

  public add(data: any): Observable<any> {
    return this.http.post(endpoints.projects.places.add, data);
  }
}
