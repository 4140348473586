import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {RecommendedAppsApiService} from "@service/app-config/recommended-apps/recommended-apps-api.service";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";

@Injectable({
  providedIn: 'root'
})
export class RecommendedAppsService {

  constructor(private recommendedAppsApiService: RecommendedAppsApiService, private toastService: ToastService, private eventService: EventService) {
  }

  private _addingApp$ = new BehaviorSubject<boolean>(false);

  public get addingApp$() {
    return this._addingApp$.asObservable();
  }

  public addApp(data: any): any {
    this._addingApp$.next(true);
    let action: Observable<any> = !data?.id ? this.recommendedAppsApiService.add(data) : this.recommendedAppsApiService.edit(data);
    action.pipe(catchError(error => of(error))).subscribe((result: any) => {
      this._addingApp$.next(false);
      if (typeof result === 'string') {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show(!data?.id ? 'App added' : 'App edited', {classname: 'bg-success text-light'});
        this.eventService.broadcast(EventEnum.CLOSE_RECOMMENDED_APP_MODAL, null)
      }
    });
  }
}
