import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {UtilsService} from "@service/utils/utils.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {CompaniesMembershipsService} from "@service/companies/companies-memberships.service";
import {BanTypeEnum} from "@enum/ban-type/ban-type.enum";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {Router} from "@angular/router";
import {NotificationsService} from "@service/notifications/notifications.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-memberships-list-page',
  templateUrl: './memberships-list-page.component.html',
  styleUrls: ['./memberships-list-page.component.scss']
})
export class MembershipsListPageComponent implements OnInit {
  @ViewChild('banRemoveModal') private banRemoveModal: any
  @ViewChild('banModal') private modalComponent: any
  @ViewChild('validateModal') private validateModal: any
  @ViewChild('deactivateModal') private deactivateModal: any
  @Input() company?: any;
  @Input() filters?: any;
  public membershipsList$: Observable<any[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public notificationModalType = NotificationTypeEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPANY_MEMBERSHIPS;
  public banType = BanTypeEnum;
  private closeModalSubscription: Subscription;
  private closeUnbanModalSubscription: Subscription;

  constructor(public companiesMembershipsService: CompaniesMembershipsService, private cdr: ChangeDetectorRef, public utils: UtilsService,
              private eventService: EventService, private router: Router, private modalService: NgbModal, private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.membershipsList$ = this.companiesMembershipsService.memberships$;
    this.total$ = this.companiesMembershipsService.totalRecords$;
    this.loading$ = this.companiesMembershipsService.loading$;
    this.sort({column: "createdAt", direction: "asc"});
    this.setVisibleColumns();
    this.companiesMembershipsService.companyId = this.filters?.contextDetailsId;
    this.companiesMembershipsService.initSearchMembershipsListener();
    this.companiesMembershipsService._search$.next();
    this.cdr.detectChanges();
    this._closeModal();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.companiesMembershipsService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.companiesMembershipsService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.companiesMembershipsService.page - 1) * this.companiesMembershipsService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.companiesMembershipsService.sortColumn = column;
    this.companiesMembershipsService.sortDirection = direction;
  }

  async validateEmail(user: any) {
    const title = 'VALIDATE_EMAIL';
    const description = 'VALIDATE_EMAIL_DESCRIPTION';
    user.validateAction = true;
    return await this.validateModal.open(user, title, description)
  }

  async openModal(users?: any[]) {
    if (users?.length < 1) {
      return;
    } else {
      this.modalComponent.setUsers(users);
      this.modalComponent.setCompetition(this.filters?.contextDetailsId);
      return await this.modalComponent.open()
    }
  }

  async openUnbanModal(users: any[]) {
    if (users?.length < 1) {
      return;
    } else {
      return await this.banRemoveModal.open(users);
    }
  }
	
	async removeUser(user: any) {
		const title = 'REMOVE_USER_FROM_COMPANY';
		const description = 'REMOVE_USER_FROM_COMPANY_DESCRIPTION';
		user.companyId = this.company?.id;
		return await this.deactivateModal.open(user, title, description)
	}

  sendUserNotificationModal(user: any[]) {
    this.notificationService.sendNotification(user);
  }

  async editTeam(team: any) {

  }

  public extractUsersTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Show all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }

  public extractNotificationTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Send push notification to all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_BAN_MODAL, (reason: string | undefined) => {
      this.companiesMembershipsService._search$.next();
    });
    this.closeUnbanModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_REMOVE_BAN_MODAL, (reason: string | undefined) => {
      this.companiesMembershipsService._search$.next();
    });
  }

  public userDetail(user: UserElementInterface) {
    this.router.navigate(['users/detail', String(user?.id)]);
  }

  public addMembers(): void {
    this.companiesMembershipsService.addUsersModal(this.company);
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
    if (this.closeUnbanModalSubscription) {
      this.closeUnbanModalSubscription.unsubscribe();
    }
    this.companiesMembershipsService.removeSearchMembershipsListener();
  }

}
