<div class="operation-modal-header">
  <h5 *ngIf="!notification">{{'CREATE_NOTIFICATION' | translate}}</h5>
  <h5 *ngIf="notification && !notification?.duplicate">{{'EDIT_NOTIFICATION' | translate}}: {{notification?.title}}</h5>
  <h5 *ngIf="notification && notification?.duplicate">{{'DUPLICATE_NOTIFICATION' | translate}}: {{notification?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(creatingNotification$ | async)" class="btn close-modal-button" closeModal="CLOSE_NOTIFICATION_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="notificationForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
          <li [ngbNavItem]="1" (click)="activeId = 1">
            <a ngbNavLink class="mb-2">
             <span class="d-inline-block danger me-2" *ngIf="checkAlert('AUDIENCE_ACTION')">
                  <i class="mdi mdi-alert-circle-outline"></i>
              </span>
              <span class="d-inline-block">Audience & Action</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="row" *ngIf="audience$ | async as audience">
                    <div class="col-12 mb-3">
                      <label class="font-weight-bold">{{'AUDIENCE' | translate}}</label>
                      <select (change)="checkAudienceValidators()" class="form-select" formControlName="audience" id="audience">
                        <option *ngFor="let audienceElement of audience" [selected]="audienceElement.selected" [hidden]="audienceElement.audience === 'ALL_USERS_IN_CHALLENGE'"
                                [value]="audienceElement.audience">{{audienceElement.label | translate}}</option>
                      </select>
                    </div>
                    <div class="col-12 mb-3" *ngIf="!checkSelectedAudience()">
                      <label *ngIf="this.form?.audience?.value === AudienceEnum.SINGLE_USERS" class="font-weight-bold">
                        {{'USERS' | translate}}
                        <span *ngIf="selectedUsers?.length > 0" class="badge badge-pill badge-soft-success font-size-11">{{selectedUsers?.length}}</span>
                        <span *ngIf="selectedUsers?.length > chunkSize" class="badge badge-pill badge-soft-warning ms-2 font-size-11">Because there are a lot of users this notification will be splitted into {{selectedUsers?.length / chunkSize}} notifications</span>
                      </label>
                      <div *ngIf="this.form?.audience?.value === AudienceEnum.SINGLE_USERS && !showSelectedUsers">
                        <textarea [(ngModel)]="tmpUserIds" [ngModelOptions]="{standalone: true}"
                                  [ngClass]="{'is-invalid': formSubmitted && form?.audienceIds?.errors?.required}"
                          class="form-control codes-textarea"
                          placeholder="Insert all the user ids separated with a space"
                          id="userIds"
                          rows="4"></textarea>
                        <button (click)="addUserIds()" type="button" class="btn btn-warning mt-3">Add users</button>
                      </div>
                      <div *ngIf="this.form?.audience?.value === AudienceEnum.SINGLE_USERS && showSelectedUsers && selectedUsers?.length > 0">
                        <div class="user-table-notification">
                          <app-users-table (removedUser)="removeUser($event)" [type]="DeleteModalTypeEnum.NOTIFICATION" [canBeRemoved]="true" [light]="true" [users]="selectedUsers"></app-users-table>
                        </div>
                        <button (click)="addMoreUsers()" class="btn btn-warning mt-3">Add more users</button>
                      </div>
                      <label *ngIf="this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPANY" class="font-weight-bold">{{'COMPANY' | translate}}</label>
                      <app-company-select *ngIf="this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPANY"
                                          (selectedCompany)="this.notificationForm.controls['audienceIds'].setValue($event); checkActionDetail();"
                                          [error]="formSubmitted && form?.audienceIds?.errors?.required"
                                          [selected]="this.form?.audienceIds?.value"></app-company-select>
                      <label *ngIf="this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPETITION" class="font-weight-bold">{{'COMPETITION' | translate}}</label>
                      <app-competition-select *ngIf="this.form?.audience?.value === AudienceEnum.ALL_USERS_IN_COMPETITION"
                                              (selectedCompetition)="this.notificationForm.controls['audienceIds'].setValue($event); checkActionDetail();"
                                              [error]="formSubmitted && form?.audienceIds?.errors?.required"
                                              [selected]="this.form?.audienceIds?.value"></app-competition-select>
                      <div *ngIf="formSubmitted && form.audienceIds.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form.audienceIds.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <app-deeplink-action-select
                        [parentForm]="notificationForm"
                        [control]="'action'"
                        [childControl]="'actionDetail'"
                        [submitted]="formSubmitted"
                        [audience]="notificationForm.get('audience').value"
                        [audienceIds]="notificationForm.get('audienceIds').value"
                    ></app-deeplink-action-select>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" (click)="activeId = 2">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block danger me-2" *ngIf="checkAlert('CONTENT')">
                  <i class="mdi mdi-alert-circle-outline"></i>
              </span>
              <span class="d-inline-block">Content</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-8">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form.title.errors}" class="form-control"
                           formControlName="title" id="title" type="text">
                    <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.title?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.title?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label class="font-weight-bold">{{'LANGUAGE' | translate}}</label>
                  <select class="form-select" formControlName="locale" id="locale"  [ngClass]="{'is-invalid': formSubmitted && form.locale.errors}">
                    <option *ngFor="let lang of languages" [value]="lang.value">
                      {{lang.text}}
                    </option>
                  </select>
                  <div *ngIf="formSubmitted && form.locale.errors" class="invalid-feedback d-block">
                    <span *ngIf="formSubmitted && form.locale.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label font-weight-semibold mb-1" for="body">{{'MESSAGE' | translate}}</label>
                    <div>
                      <textarea [ngClass]="{'is-invalid': formSubmitted && form.body.errors}" class="form-control description-textarea"
                                formControlName="body" id="body" rows="4"></textarea>
                      <div *ngIf="formSubmitted && form?.body?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.body?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="form?.body?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.body?.errors?.maxlength?.requiredLength} }}</span>
                        <span *ngIf="form?.body?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.body?.errors?.minlength?.requiredLength} }}.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <app-image-uploader [previousSelectedImage]="checkSelectedImage()"
                                        [showImagePreview]="true"
                                        (imageUploaded)="setImage($event)"></app-image-uploader>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" (click)="activeId = 3">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Delivery</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold">{{'SEND_NOTIFICATION' | translate}}</label>
                    <select class="form-select" formControlName="delivery" id="delivery">
                      <option *ngFor="let option of deliveryOptions" [selected]="option.selected"
                              [value]="option.value">{{option.value | translate}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-8" *ngIf="this.form?.delivery?.value === 'SPECIFIC_DATE'">
                  <label class="font-weight-bold">{{'SELECT_DATE' | translate}}</label>
                  <app-simple-datepicker [isInvalid]="formSubmitted && form?.scheduledAt?.value?.required"
                                         [selectedDate]="form?.scheduledAt?.value"
                                         (dateSelected)="notificationForm.get('scheduledAt').setValue($event)"></app-simple-datepicker>
                </div>
                <div class="col-2"  *ngIf="this.form?.delivery?.value === 'SPECIFIC_DATE'">
                  <div class="mb-3">
                    <label class="font-weight-bold">{{'HOUR' | translate}}</label>
                    <select class="form-select" formControlName="deliveryDateHour" id="deliveryDateHour" [ngClass]="{'is-invalid': form.deliveryDateHour.errors}">
                      <option *ngFor="let option of utils.hourOptions" [selected]="option.selected"
                              [value]="option.value">{{option.value | translate}}</option>
                    </select>
                    <div *ngIf="form.deliveryDateHour.errors" class="invalid-feedback">
                      <span *ngIf="form.deliveryDateHour.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form.deliveryDateHour.errors.max">This max value for hour is 24.</span>
                      <span *ngIf="form.deliveryDateHour.errors.min">This min value for hour is 1.</span>
                    </div>
                  </div>
                </div>
                <div class="col-2"  *ngIf="this.form?.delivery?.value === 'SPECIFIC_DATE'">
                  <div class="mb-3">
                    <label class="font-weight-bold">{{'MINUTES' | translate}}</label>
                    <select class="form-select" formControlName="deliveryDateMinute" id="deliveryDateMinute" [ngClass]="{'is-invalid': form.deliveryDateMinute.errors}">
                      <option *ngFor="let option of utils.minutesOptions" [selected]="option.selected"
                              [value]="option.value">{{option.value | translate}}</option>
                    </select>
                    <div *ngIf="form.deliveryDateMinute.errors" class="invalid-feedback">
                      <span *ngIf="form.deliveryDateMinute.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form.deliveryDateMinute.errors.max">This max value for hour is 24.</span>
                      <span *ngIf="form.deliveryDateMinute.errors.min">This min value for hour is 1.</span>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="this.form?.delivery?.value === 'RECURRING'">
                  <app-recurring-event-chooser #recurringComponent (selectedDates)="setRecurringSelection($event)" [selection]="recurring"></app-recurring-event-chooser>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !notification " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && notification && notification?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && notification && !notification?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(creatingNotification$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="creatingNotification$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(creatingNotification$ | async) && !notification">{{'CREATING' | translate}}</span>
          <span *ngIf="(creatingNotification$ | async) && notification && !notification?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(creatingNotification$ | async) && notification && notification?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(creatingNotification$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingNotification$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
