import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SustainabilityPartnersService} from "@service/sustainability/partners/sustainability-partners.service";
import {PARTNERS} from "../../../configs/documentation/clickup";

@Component({
  selector: 'app-add-sustainability-partner',
  templateUrl: './add-sustainability-partner.component.html',
  styleUrls: ['./add-sustainability-partner.component.scss']
})
export class AddSustainabilityPartnerComponent implements OnInit, OnDestroy {
  public help = PARTNERS;
  @Input() partner: any;
  public activeId: number = 1;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public partnerForm: UntypedFormGroup;
  public places = []
  public benefits = []
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 1000,
    height: 1000
  };
  public detailImageLimit: any = {
    fixedSize: true,
    ratio: '16:9',
    width: 1920,
    height: 1080
  };
  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
              private sustainabilityPartnersService: SustainabilityPartnersService) { }

  ngOnInit(): void {
    this.adding$ = this.sustainabilityPartnersService.adding$;
    this.initForm();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public validSubmit() {
    if (this.partnerForm.valid) {
      this.sustainabilityPartnersService.addPartner(this.partnerForm.getRawValue());
    }
  }

  public checkNextActionBtn(): void {
    if (this.partnerForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.partnerForm.controls;
  }

  get valid() {
    return this.partnerForm.valid
  }

  public setImage(image: any, field?: string): void {
    if (!image) {
      this.removeImage(field);
    } else {
      this.partnerForm.patchValue({
        [field]: image?.originalUrl,
      });
    }
  }

  public checkSelectedImage(field: string): object | undefined {
    if (this.form?.[field]?.value) {
      return {name: field, originalUrl: this.form?.[field]?.value}
    } else {
      return null;
    }
  }

  public removeImage(field?: string): void {
    this.partnerForm.patchValue({
      [field]: null
    });
  }

  public checkImageErrors(field: string): string | undefined {
    if (this.formSubmitted && this.form?.[field]?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.partnerForm = this.formBuilder.group({
      title: [!this.partner || !this.partner?.title ? null : this.partner?.title, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: [!this.partner || !this.partner?.description ? null : this.partner?.description, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      restrictsLocales: [this.partner?.restrictsLocales ? this.partner?.restrictsLocales : []],
      website: [!this.partner || !this.partner?.website ? null : this.partner?.website, [Validators.required, Validators.pattern(this.utils.validateUrlRegex)]],
      logo: [!this.partner || !this.partner?.logo ? null : this.partner?.logo, [Validators.required]],
      backgroundImage: [!this.partner || !this.partner?.backgroundImage ? null : this.partner?.backgroundImage, [Validators.required]],
      id: [this.partner?.id && !this.partner.duplicate ? this.partner?.id : null],
    });
  }

}
