import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {RecommendedAppsService} from "@service/app-config/recommended-apps/recommended-apps.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-how-to-sync-modal',
  templateUrl: './how-to-sync-modal.component.html',
  styleUrls: ['./how-to-sync-modal.component.scss']
})
export class HowToSyncModalComponent implements OnInit {
  @Input() activity: any;
  public showAreYouSure: boolean = false;
  public addingApp$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public activityForm: UntypedFormGroup;

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
              private _formService: FormsService,
              private recommendedAppsService: RecommendedAppsService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.addingApp$ = this.recommendedAppsService.addingApp$;
    this.initForm();
    (this.activityForm.get('steps') as UntypedFormArray)?.clear();
    if (this.activity) {
      this.activityForm.patchValue(this.activity);
    }
  }

  public validSubmit() {
    if (this.activityForm.valid) {
      this.recommendedAppsService.addApp(this.activityForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.activityForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.activityForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.activityForm.controls;
  }

  get valid() {
    return this.activityForm.valid
  }

  public setImage(image: any): void {
    this.activityForm.patchValue({
      iconUrl: image ? image?.originalUrl : undefined,
      imageName: image ? image?.name : undefined,
      imageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.iconUrl?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.iconUrl?.value) {
      return {originalUrl: this.form.iconUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'detail_image'].join('_')}
    } else {
      return undefined;
    }
  }

  addStep(): void {
    const stepFormGroup = this.formBuilder.group({
      index: [1],
      title: [''],
      description: [''],
    });
    (this.activityForm.get('steps') as UntypedFormArray).push(stepFormGroup);
    this._changeDetectorRef.markForCheck();
  }

  removeStep(index: number): void {
    const stepArray = this.activityForm.get('steps') as UntypedFormArray;
    stepArray.removeAt(index);
    this._changeDetectorRef.markForCheck();
  }

  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  private manageSteps(activity: any) {
    const steps = [];
    if (activity?.steps?.length > 0) {
      activity.steps.forEach((step) => {
        steps.push(
          this.formBuilder.group({
            index: [step.index],
            title: [step.title],
            description: [step.description],
          })
        );
      });
    } else {
      steps.push(
        this.formBuilder.group({
          index: [1],
          title: [''],
          description: [''],
        })
      );
    }
    steps.forEach((stepFormGroup) => {
      (this.activityForm.get('steps') as UntypedFormArray).push(stepFormGroup);
    });
  }

  private initForm(): void {
    this.activityForm = this.formBuilder.group({
      title: [!this.activity || !this.activity?.title ? null : this.activity?.title, [Validators.required]],
      description: [!this.activity || !this.activity?.description ? null : this.activity?.description, [Validators.required]],
      category: [!this.activity || !this.activity?.category ? null : this.activity?.category, [Validators.required]],
      steps: this.formBuilder.array([]),
      id: [this.activity?.id && !this.activity.duplicate ? this.activity?.id : null],
      active: [this.activity?.active && !this.activity.duplicate ? this.activity?.active : false]
    });
    if (this.activity) {
      this.manageSteps(this.activity);
    }
  }
}
