<div class="table-topbar diagnostic-search" id="diagnostic-topbar-filters">
  <div class="navbar-header">
    <div *ngIf="type === topbarTypeEnum.DIAGNOSTIC_RECORDED_ACTIVITIES" class="d-flex ms-2 align-self-center credits">
      <span *ngFor="let element of returnOnlyFilteredFitnessMetrics()"
            [hidden]="element?.total?.consolidate < 1" class="badge mb-1 counter badge-pill badge-soft-dark font-size-11 me-2">
        <span class="text-uppercase">{{element?.viewValue}}</span>
        [<span class="consolidated me-2"><i class="bx bx-check"></i> {{element?.total?.consolidate}}</span>
        <span class="provisional"><i class="bx bx-loader-alt"></i>{{element?.total?.provisional}}</span>]
      </span>
    </div>
    <div *ngIf="type === topbarTypeEnum.DIAGNOSTIC_FITNESS_CHARTS && totalResults?.length > 0"
         class="d-flex ms-2 align-self-center credits">
      <span *ngFor="let element of totalResults"
            class="badge mb-1 counter badge-pill badge-soft-dark font-size-11 me-2">
        <span class="text-uppercase">{{element?.name}}</span>
        [<span class="consolidated">{{element?.total}}</span>]
      </span>
    </div>
    <div class="d-flex w-100 justify-content-end">
      <div class="row ms-2">
        <div class="col-sm-12 d-flex">
          <app-fitness-metric-picker (selectedFitnessMetric)="metricSelected.emit($event); selectedMetric = $event"
                                     *ngIf="type === topbarTypeEnum.DIAGNOSTIC_FITNESS_CHARTS"
                                     class="me-2"></app-fitness-metric-picker>
          <div class="d-none d-xl-block diagnostic">
            <app-date-range-picker (dateRangeSelected)="dateRangeSelected.emit($event); setDate($event);"
                                   [clearAll]="clearFilters.asObservable()"
                                   [init]="true"
                                   [noLabel]="true"
                                   [noMinMax]="true"
                                   [recurring]="selectedDates"
                                   [title]="'ACTIVITY_DATE_RANGE'"></app-date-range-picker>
          </div>
          <button (click)="clearFilters.next(true)" class="btn btn-secondary ms-2"
                  type="button">
            <i class="mdi mdi-progress-close"></i>
            Clear filters
          </button>
          <button (click)="downloadEvent.next(true)" *ngIf="download" class="btn btn-primary ms-2" type="button">
            <i class="mdi mdi-database-export-outline"></i>
            Export xlsx
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
