import {AfterContentInit, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-column-picker',
  templateUrl: './column-picker.component.html',
  styleUrls: ['./column-picker.component.scss']
})
export class ColumnPickerComponent implements AfterContentInit {
  @Input() availableColumns: any[];
  @Input() visibleColumns: any[];
  @Output() updateVisibleColumns: EventEmitter<any[]> = new EventEmitter();

  constructor() {
  }

  ngAfterContentInit(): void {
    if (this.visibleColumns) {
      this.updateVisibleColumns.emit(this.visibleColumns);
    }
  }

  public updateColumns(): void {
    this.updateVisibleColumns.emit(this.visibleColumns);
  }

}
