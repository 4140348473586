import { Injectable } from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable, of} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class PrizesCategoriesApiService {
	constructor(private http: HttpService) {
	}
	
	public search(filters?: any): Observable<any> {
		const params = this.http.generateQueryParams(filters);
		return this.http.get<any>(endpoints.prizeCategories.search, params);
		// return of({
		// 	data: [
		// 		{
		// 			id: 1,
		// 			name: 'Category 1',
		// 			iconUrl: 'https://virtuoso-v2-dev-healthyvirtuoso.s3.amazonaws.com/assets/badges/4x/badge-1564-locked-1686149953895',
		// 			prizesCount: 20,
		// 			prizeIds: [467, 1118]
		// 		}
		// 	],
		// 	size: 10
		// })
	}
	
	public add(data?: any): Observable<any> {
		return this.http.post(endpoints.prizeCategories.create, data);
	}
	
	public edit(data?: any): Observable<any> {
		const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
		const url = this.http.findAndReplacePlaceholders(endpoints.prizeCategories.edit, placeholders);
		return this.http.put(url, data);
	}
	
	public delete(data?: any): Observable<any> {
		const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.data?.id}];
		const url = this.http.findAndReplacePlaceholders(endpoints.prizeCategories.delete, placeholders);
		return this.http.delete(url, {});
	}
}
