import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {UtilsService} from "@service/utils/utils.service";
import {PrizesCategoriesApiService} from "@service/prizes/categories/prizes-categories-api.service";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class PrizesCategoriesSearchService {
	public sorting: any | undefined;
	private searchFilters: any | undefined = {}
	private pagination: { page, size } = {page: 1, size: 25};
	private readonly activeFilters: BehaviorSubject<any> = new BehaviorSubject(null);
	public readonly observable: Observable<any> = this.activeFilters.asObservable();
	
	constructor(private prizeCategoriesApi: PrizesCategoriesApiService, private utils: UtilsService, private _eventService: EventService, private _toastService: ToastService) {
	}
	
	private _adding$ = new BehaviorSubject<boolean>(false);
	
	public get adding$() {
		return this._adding$.asObservable();
	}
	
	
	private static get actions(): any[] {
		return [
			{
				clickAction: 'delete',
				disabledIf: 'sendAt',
				class: ['pointer', 'danger', 'font-size-18'],
				tooltip: 'DELETE',
				placement: 'bottom',
				icon: 'bx bx-trash'
			},
			{
				clickAction: 'duplicate',
				class: ['pointer', 'font-size-18'],
				tooltip: 'DUPLICATE',
				placement: 'bottom',
				icon: 'bx bx-copy'
			},
			{
				clickAction: 'edit',
				disabledIf: 'sendAt',
				class: ['pointer', 'font-size-18'],
				tooltip: 'EDIT',
				placement: 'bottom',
				icon: 'bx bx-pencil'
			},
		]
	}
	
	public get api(): any {
		return this.prizeCategoriesApi;
	}
	
	public get pageSize() {
		return this.pagination.size;
	}
	
	public set pageSize(pageSize: number) {
		this.pagination.page = 1;
		this.pagination.size = pageSize;
		this.activeFilters.next(this.searchFilters);
	}
	
	public get page() {
		return this.pagination.page;
	}
	
	public set page(page: number) {
		this.pagination.page = page;
		this.activeFilters.next(this.searchFilters);
	}
	
	public get getActiveFilters(): any {
		return this.activeFilters.getValue();
	}
	
	public get columns(): any[] {
		return [
			{
				id: 2,
				canHide: true,
				visible: true,
				text: true,
				label: 'ID',
				align: 'center',
				class: ['pointer', 'fit-width'],
				elementKey: 'id',
				sortable: true,
				sortKey: 'id',
				elementClasses: ['badge', 'badge-pill', 'badge-soft-primary'],
				clickAction: 'userDetail'
			},
			{
				id: 3,
				canHide: true,
				visible: true,
				text: true,
				label: 'ICON',
				align: 'center',
				class: ['pointer', 'fit-width'],
				elementKey: 'iconUrl',
				sortable: true,
				sortKey: 'iconUrl',
				image: true
			},
			{
				id: 4,
				canHide: true,
				visible: true,
				text: true,
				label: 'NAME',
				align: 'center',
				class: ['pointer'],
				elementKey: 'name',
				sortable: true,
				sortKey: 'name'
			},
			{
				id: 5,
				canHide: true,
				visible: true,
				text: true,
				label: 'PRIZES_NUMBER',
				align: 'center',
				class: ['pointer', 'fit-width'],
				elementKey: 'prizesCount',
				sortable: true,
				sortKey: 'prizesCount',
				number: true
			},
			{
				id: 8,
				canHide: false,
				visible: true,
				label: '',
				class: ['fit-width'],
				isRowAction: true,
				rowActions: PrizesCategoriesSearchService.actions
			},
		]
	}
	
	public setFilter(patch: any): void {
		Object.assign(this.searchFilters, patch);
		this.activeFilters.next(this.searchFilters);
	}
	
	public clearFilters(): void {
		this.searchFilters = {};
		this.activeFilters.next(this.searchFilters);
	}
	
	public filters(): any {
		return {
			filters: this.activeFilters.getValue(),
			sort: this.sorting,
			page: this.pagination.page,
			size: this.pagination.size
		}
	}
	
	public filterApplied(length?: boolean): boolean | number {
		const filters: any = this.activeFilters.getValue();
		if (!filters) return !length ? false : 0;
		if (Object.keys(filters)?.length > 0) {
			const obj = this.utils.clearObject(filters);
			if (!length) {
				return Object.keys(obj)?.length > 0;
			} else {
				return Object.keys(obj)?.length
			}
		} else {
			return false;
		}
	}
	
	public addCategory(data: any): any {
		this._adding$.next(true);
		this.prizeCategoriesApi.add(data).pipe(catchError(error => of(error))).subscribe((result: any) => {
			if (typeof result === 'string') {
				this._toastService.show(result, {classname: 'bg-danger text-light'});
			} else {
				this._toastService.show(!data?.id ? 'Category added' : 'Category edited', {classname: 'bg-success text-light'});
			}
			this._adding$.next(false);
			this._eventService.broadcast(EventEnum.PRIZE_CATEGORY_MODAL, null)
		});
	}

}
