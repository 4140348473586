import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {CompetitionIndividualLeaderboardService} from "@service/competitions/competition-individual-leaderboard.service";
import {BanTypeEnum} from "@enum/ban-type/ban-type.enum";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {Router} from "@angular/router";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {NotificationsService} from "@service/notifications/notifications.service";
import {UtilsService} from "@service/utils/utils.service";
import {DeleteComponent} from "@component/common/delete/delete.component";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CompetitionsService} from "@service/competitions/competitions.service";

@Component({
  selector: 'app-individual-leaderboard-page',
  templateUrl: './individual-leaderboard-page.component.html',
  styleUrls: ['./individual-leaderboard-page.component.scss']
})
export class IndividualLeaderboardPageComponent implements OnInit {
  @ViewChild('banRemoveModal') private banRemoveModal: any
  @ViewChild('banModal') private modalComponent: any
  @Input() filters?: any;
  public individualLeaderboardList$: Observable<any[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPETITION_INDIVIDUAL_LEADERBOARD;
  public notificationModalType = NotificationTypeEnum;
  public banType = BanTypeEnum;
  private closeModalSubscription: Subscription;
  private closeUnbanModalSubscription: Subscription;

  constructor(public competitionIndividualLeaderboardService: CompetitionIndividualLeaderboardService, private cdr: ChangeDetectorRef,
              public utils: UtilsService, private modalService: NgbModal, private competitionsService: CompetitionsService,
              private eventService: EventService, private router: Router, private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.individualLeaderboardList$ = this.competitionIndividualLeaderboardService.competitionIndividualLeaderboard$;
    this.total$ = this.competitionIndividualLeaderboardService.totalRecords$;
    this.loading$ = this.competitionIndividualLeaderboardService.loading$;
    this.sort({column: "position", direction: "asc"});
    this.setVisibleColumns();
    this.competitionIndividualLeaderboardService.contestId = this.filters?.contextDetailsId;
    this.competitionIndividualLeaderboardService.initSearchCompetitionsIndividualLeaderboardListener();
    this.competitionIndividualLeaderboardService._search$.next();
    this.cdr.detectChanges();
    this._closeModal();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.competitionIndividualLeaderboardService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.competitionIndividualLeaderboardService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.competitionIndividualLeaderboardService.page - 1) * this.competitionIndividualLeaderboardService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.competitionIndividualLeaderboardService.sortColumn = column;
    this.competitionIndividualLeaderboardService.sortDirection = direction;
  }

  async openModal(users?: any[]) {
    if (users?.length < 1) {
      return;
    } else {
      this.modalComponent.setUsers(users);
      this.modalComponent.setCompetition(this.filters?.contextDetailsId);
      return await this.modalComponent.open()
    }
  }

  async openUnbanModal(users: any[]) {
    if (users?.length < 1) {
      return;
    } else {
      return await this.banRemoveModal.open(users);
    }
  }

  public userDetail(user: any): void {
    this.router.navigate(['users/detail', String(user?.id)]);
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_BAN_MODAL, (reason: string | undefined) => {
      this.competitionIndividualLeaderboardService._search$.next();
    });
    this.closeUnbanModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_REMOVE_BAN_MODAL, (reason: string | undefined) => {
      this.competitionIndividualLeaderboardService._search$.next();
    });
  }

  sendUserNotificationModal(user: any[]) {
    this.notificationService.sendNotification(user);
  }



  public removeUser(userId: any): void {
    const edit = this.modalService.open(DeleteComponent, {size: 'md', centered: true, backdrop: false});
    edit.componentInstance.modalData = {title: 'REMOVE_USER_FROM_THIS_COMPETITION', name: null, description: 'LOREM', obj: {...{id: this.filters?.contextDetailsId}, ...{userId: userId}}};
    edit.componentInstance.service = this.competitionsService;
    edit.componentInstance.type = DeleteModalTypeEnum.USER_FROM_COMPETITION;
    edit.result.then((result) => {}, (reason)=>{});
    const close = this.eventService.subscribe(EventEnum.CLOSE_DELETE_MODAL, (reason: string | undefined) => {
      this.competitionIndividualLeaderboardService._search$.next();
      edit.dismiss();
    });
  }


  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
    if (this.closeUnbanModalSubscription) {
      this.closeUnbanModalSubscription.unsubscribe();
    }
    this.competitionIndividualLeaderboardService.removeSearchCompetitionsIndividualLeaderboardSubscribe();
  }

}
