import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-prizes-table',
  templateUrl: './prizes-table.component.html',
  styleUrls: ['./prizes-table.component.scss']
})
export class PrizesTableComponent implements OnInit, OnDestroy {
  @Input() prizes: any[];
  @Output() selectedPrizes = new EventEmitter();
  public selectedItems: any[] = [];
  public allSelected = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public selectAllResults(): void {
    this.allSelected = !this.allSelected;
    this.prizes = this.prizes.map((prize) => {
      prize.checked = this.allSelected;
      return prize;
    });
    this.extractSelectedItems();
  }

  public extractSelectedItems(): any {
    this.selectedItems = this.prizes?.filter((prize) => {
      return prize.checked;
    });
    this.selectedPrizes.emit(this.selectedItems);
  }

  ngOnDestroy() {
    this.prizes = [];
    this.selectedItems = [];
    this.allSelected = false;
  }
}
