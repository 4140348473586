<div class="card">
  <div class="card-body">
    <div class="row mb-4 mt-2">
      <div class="col-lg-6">
        <div class="toolbar button-items text-start">
          <button class="btn btn-light btn-sm active" ngbTooltip="Day of selected date" placement="bottom"
                  type="button">
            DAY
          </button>
          <button class="btn btn-light btn-sm active" ngbTooltip="Day of selected date" placement="bottom"
                  type="button">
            DAY
          </button>
          <button class="btn btn-light btn-sm active" ngbTooltip="Day of selected date" placement="bottom"
                  type="button">
            DAY
          </button>
          <button class="btn btn-light btn-sm active" ngbTooltip="Day of selected date" placement="bottom"
                  type="button">
            DAY
          </button>
        </div>
      </div>
      <div class="col-lg-6 ms-auto">
        <app-date-range-picker [noLabel]="true"></app-date-range-picker>
      </div>
    </div>
  </div>
</div>
