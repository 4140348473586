import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Observable, Subscription} from "rxjs";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {ExportService} from "@service/export/export.service";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-export-results-modal',
  templateUrl: './export-results-modal.component.html',
  styleUrls: ['./export-results-modal.component.scss']
})
export class ExportResultsModalComponent implements OnInit {
  public service: any;
  public type: any;
  public fileName: string = 'export_list';
  public working: boolean;
  public showAreYouSure: boolean = false;
  public progress: any = 0;
  public remainingTime: any = undefined;
  public totalExecutionTime: any = undefined;
  public exporting$: Observable<any>;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private exportingSubscription: Subscription;
  private _totalRecords: any;

  constructor(private modalService: NgbModal, private eventService: EventService, private exportService: ExportService) {
  }

  ngOnInit(): void {
    this._closeModal();
  }

  public open(data?: any, type?: any, totalRecords?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.service = data;
      this.type = type;
      this._totalRecords = totalRecords;
      this.exporting$ = this.service.exporting$;
    })
  }

  public export(): any {
    if (this.type === TopbarTypeEnum.INVITES_USES) {
      this.service.exportData(true);
    } else if (this.type === TopbarTypeEnum.COMPANIES_ANALYTICS) {
      this.service.exportData(this._totalRecords);
    } else {
      this.service.exportData();
    }
    this.working = true;
    this.progress = 0;
    this.remainingTime = undefined;
    this.totalExecutionTime = undefined;
    this.exportingSubscription = this.exporting$.subscribe((data: any) => {
      switch (data?.type) {
        case 'CONT':
          this.progress = data?.value;
          break;
        case 'RESULT':
          if (this.progress >= 100) {
            this.exportingSubscription?.unsubscribe();
            const results = data?.value?.map((result: any) => {
              return this.exportService.flattenObject(result);
            });
            this.exportService.exportAsExcelFile(results, this.fileName ? this.fileName : this.type);
            this.working = false;
            this.showAreYouSure = false;
          }
          break;
        case 'TOTAL_EXECUTION_TIME':
          if (!this.totalExecutionTime) {
            this.totalExecutionTime = data?.value;
          }
          break;
        case 'REMAINING_EXECUTION_TIME':
          this.remainingTime = data?.value;
          break;
      }
    });

  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_EXPORT_MODAL, (reason: string | undefined) => {
      this.working = false;
      this.showAreYouSure = false;
      this.progress = 0;
      this.service = undefined;
      this.type = undefined;
      this.fileName = undefined;
      this.working = undefined;
      this.showAreYouSure = undefined;
      this.remainingTime = undefined;
      this.totalExecutionTime = undefined;
      this.exporting$.subscribe()
      this.modalRef?.dismiss(reason);
    });
  }

}
