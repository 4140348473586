<app-table-topbar [type]="topBarType" [service]="companiesMembershipsService" [showColumnPicker]="true"
                  (updateColumnsEvent)="selected = $event" (openAddMembership)="addMembers()"
                  (emitSearchTerm)="companiesMembershipsService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="membershipsList$ | async as members" class="wrapper">
    <table *ngIf="members?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_USER_ID)" class="column-sortable"
            sortable="member.id">
          <span class="me-3">{{'MEMBERSHIP_USER_ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.LOCALE)" class="column-sortable"
            sortable="member.locale">
          <span class="me-3">{{'LOCALE' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_NICKNAME)" class="column-sortable"
            sortable="member.member.profile.nickname">
          <span class="me-3">{{'MEMBERSHIP_NICKNAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_FULL_NAME)" class="column-sortable"
            sortable="member.profile.firstName">
          <span class="me-3">{{'MEMBERSHIP_FULL_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_EMAIL)" class="column-sortable"
            sortable="member.corporate.email">
          <span class="me-3">{{'MEMBERSHIP_EMAIL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_UNIQUE_CODE)" class="column-sortable"
            sortable="member.corporate.uniqueCode">
          <span class="me-3">{{'MEMBERSHIP_UNIQUE_CODE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_VALIDATED)" class="column-sortable"
            sortable="member.corporate.validated">
          <span class="me-3">{{'MEMBERSHIP_VALIDATED' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CORRECT_DOMAIN)" class="column-sortable"
            sortable="member.corporate.correctDomain">
          <span class="me-3">{{'MEMBERSHIP_CORRECT_DOMAIN' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_VALIDABLE_UNTIL)" class="column-sortable"
            sortable="member.corporate.validableUntil">
          <span class="me-3">{{'MEMBERSHIP_VALIDABLE_UNTIL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_BANNED)" class="column-sortable"
            sortable="member.banned">
          <span class="me-3">{{'MEMBERSHIP_BANNED' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CREATED_AT)" class="column-sortable"
            sortable="createdAt">
          <span class="me-3">{{'MEMBERSHIP_CREATED_AT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_LAST_UPDATE)" class="column-sortable"
            sortable="member.lastUpdate">
          <span class="me-3">{{'MEMBERSHIP_LAST_UPDATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_LAST_ACCESS)" class="column-sortable"
            sortable="member.lastAccess.atTs">
          <span class="me-3">{{'MEMBERSHIP_LAST_ACCESS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_TEAM)" class="column-sortable"
            sortable="teams">
          <span class="me-3">{{'MEMBERSHIP_TEAM' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CODE)">
          <span class="me-3">{{'MEMBERSHIP_CODE' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let singleMember of members; let i=index;">
        <td (click)="userDetail(singleMember?.member)" align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_USER_ID)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.member?.id" class="badge badge-pill badge-soft-primary">{{singleMember?.member?.id}}</span>
          <app-missing-label *ngIf="!singleMember?.member?.id"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.LOCALE)" align="center"
            class="pointer fit-width">
          <img alt="Header Language" class="me-2" height="16" src="{{utils.getSpecificLanguage(singleMember?.member?.locale)}}">
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_NICKNAME)" align="center" class="pointer">
          <span *ngIf="singleMember?.member?.profile?.nickname">{{singleMember?.member?.profile?.nickname}}</span>
          <app-missing-label *ngIf="!singleMember?.member?.profile?.nickname"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_FULL_NAME)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.member?.profile?.firstName">{{singleMember?.member?.profile?.firstName}} {{singleMember?.member?.profile?.lastName}}</span>
          <app-missing-label *ngIf="!singleMember?.member?.profile?.firstName"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_EMAIL)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.corporate?.email">{{singleMember?.corporate?.email}}</span>
          <app-missing-label *ngIf="!singleMember?.corporate?.email"></app-missing-label>
          <span (click)="validateEmail(singleMember)" ngbTooltip="Validate membership email" class="pointer success ms-2" placement="left"><i class="mdi mdi-lock-open-check-outline"></i></span>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_UNIQUE_CODE)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.corporate?.uniqueCode">{{singleMember?.corporate?.uniqueCode}}</span>
          <app-missing-label *ngIf="!singleMember?.corporate?.uniqueCode"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_VALIDATED)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.corporate?.validated" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!singleMember?.corporate?.validated" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CORRECT_DOMAIN)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.corporate?.correctDomain" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!singleMember?.corporate?.correctDomain" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_VALIDABLE_UNTIL)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.corporate?.validableUntil">{{singleMember?.corporate?.validableUntil | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!singleMember?.corporate?.validableUntil"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_BANNED)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.banned" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!singleMember?.banned" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CREATED_AT)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.createdAt">{{singleMember?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!singleMember?.createdAt"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_LAST_UPDATE)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.lastUpdate">{{singleMember?.lastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!singleMember?.lastUpdate"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_LAST_ACCESS)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.member?.lastAccess?.atTs">{{singleMember?.member?.lastAccess?.atTs | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!singleMember?.member?.lastAccess?.atTs"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_TEAM)" align="center" class="pointer fit-width">
          <span *ngIf="singleMember?.teams && singleMember?.teams[0] &&  singleMember?.teams[0]?.name">{{singleMember?.teams[0]?.name}}</span>
          <app-missing-label *ngIf="!singleMember?.teams"></app-missing-label>
        </td>
        <td (click)="userDetail(singleMember?.member)" *ngIf="checkIfColumnIsVisible(column.MEMBERSHIP_CODE)" align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-primary" *ngIf="singleMember?.companyInviteCode && singleMember?.companyInviteCode?.code">{{singleMember?.companyInviteCode?.code}}</span>
          <app-missing-label *ngIf="!singleMember?.companyInviteCode?.code"></app-missing-label>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="openModal([singleMember?.member])" *ngIf="!singleMember?.banned" class="list-inline-item px-2">
                  <span class="pointer warning"
                        ngbTooltip="Ban"
                        placement="bottom"><i
                    class="bx bx bx-block"></i></span>
            </li>
            <li (click)="openUnbanModal([singleMember?.member])"  *ngIf="singleMember?.banned" class="list-inline-item px-2">
                    <span ngbTooltip="Unban" class="pointer warning"
                          placement="left"><i
                      class="mdi mdi-close-circle-outline"></i></span>
            </li>
            <li (click)="removeUser(singleMember?.member)"  class="list-inline-item px-2">
                    <span ngbTooltip="Remove user" class="pointer danger"
                          placement="left"><i
                        class="mdi mdi-close-circle-outline"></i></span>
            </li>
            <li (click)="sendUserNotificationModal([singleMember?.member?.id])" class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Send push notification"
                        placement="bottom"><i
                    class="bx bx-send"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="members?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="companiesMembershipsService" [total$]="total$"></app-pagination-footer>
<app-ban-modal #banModal [banType]="banType.COMPANY"></app-ban-modal>
<app-ban-remove-modal [filters]="filters" #banRemoveModal></app-ban-remove-modal>
<app-activate-modal #validateModal [service]="companiesMembershipsService" [buttonLabel]="'VALIDATE'" [buttonWorkingLabel]="'VALIDATING'"></app-activate-modal>
<app-deactivate-modal #deactivateModal [service]="companiesMembershipsService" [remove]="true"></app-deactivate-modal>
