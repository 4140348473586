import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {UsersService} from "@service/users/users.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-add-operator-modal',
  templateUrl: './add-operator-modal.component.html',
  styleUrls: ['./add-operator-modal.component.scss']
})
export class AddOperatorModalComponent implements OnInit {
  @Input() operator: any;
  public showAreYouSure: boolean = false;
  public addingOperator$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public operatorForm: UntypedFormGroup;
	public roles = ['SUPER_USER', 'ADMIN', 'OPERATION', 'MARKETING']
	public roleLevels = ['R', 'RW']

  constructor(private toastService: ToastService, public utils: UtilsService,
              private _formService: FormsService,
              private formBuilder: UntypedFormBuilder,
              private usersService: UsersService) {
  }

  get form() {
    return this.operatorForm.controls;
  }

  get valid() {
    return this.operatorForm.valid
  }

  ngOnInit(): void {
    this.addingOperator$ = this.usersService.addingOperator$;
    this.initForm();
  }

  public validSubmit() {
    if (this.operatorForm.valid) {
      this.usersService.addOperator(this.operatorForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.operatorForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.operatorForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  private initForm(): void {
    this.operatorForm = this.formBuilder.group({
      email: [!this.operator || !this.operator?.user?.email ? null : this.operator?.user?.email, [Validators.required]],
      promoChallenger: [!this.operator ? null : this.operator?.promoChallenger],
      prizeAdmin: [!this.operator ? null : this.operator?.prizeAdmin],
      competitionAdmin: [!this.operator ? null : this.operator?.competitionAdmin],
	    roleCode: [!this.operator ? null : this.operator?.role, [Validators.required]],
      roleLevel: [!this.operator ? null : this.operator?.roleLevel, [Validators.required]],
	    companies: [!this.operator ? null : this.operator?.companies],
	    id: [this.operator?.user?.userId && !this.operator.duplicate ? this.operator?.user?.userId : null]
    });
  }
}
