<div class="operation-modal-header">
  <h5 *ngIf="!benefit" class="modal-title" id="modal-title-1">
    <span *ngIf="type === BenefitType.BENEFIT">{{'CREATE_BENEFIT' | translate}}</span>
    <span *ngIf="type === BenefitType.SPORT">{{'CREATE_SPORT' | translate}}</span>
  </h5>
  <h5 *ngIf="benefit && !benefit?.duplicate" class="modal-title" id="modal-title-2">
    <span *ngIf="type === BenefitType.BENEFIT">{{'EDIT_BENEFIT' | translate}}: {{benefit?.title}}</span>
    <span *ngIf="type === BenefitType.SPORT">{{'EDIT_SPORT' | translate}}: {{benefit?.title}}</span>
  </h5>
  <h5 *ngIf="benefit && benefit?.duplicate" class="modal-title" id="modal-title-3">
    <span *ngIf="type === BenefitType.BENEFIT">{{'DUPLICATE_BENEFIT' | translate}}: {{benefit?.title}}</span>
    <span *ngIf="type === BenefitType.SPORT">{{'DUPLICATE_SPORT' | translate}}: {{benefit?.title}}</span>
  </h5>
  <div class="d-flex">
    <button [disabled]="(addingBenefit$ | async)" class="btn close-modal-button" closeModal="CLOSE_BENEFIT_APP_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="benefitForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-9">
        <div class="mb-3">
          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                 formControlName="title" id="title" type="text">
          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="mb-3">
          <label class="font-weight-bold" for="active">Active</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="active"
                   id="active" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="active">
              <span *ngIf="form?.active?.value" class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.active?.value" class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="androidStore">{{'LINK' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.link?.errors}" class="form-control"
                 formControlName="link" id="link" type="text">
          <div *ngIf="formSubmitted && form?.link?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form.link.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form.link.errors.pattern">Please insert a valid url.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="priority">
            Priority
            <span *ngIf="maxPriority" class="badge badge-pill badge-soft-warning font-size-11">Current max ({{maxPriority}})</span>
          </label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                 formControlName="priority" id="priority"
                 min="0"
                 placeholder="Priorità" type="number">
          <div *ngIf="formSubmitted && form?.priority?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.priority?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <app-image-uploader [name]="'CARD_IMAGE'"
                              (imageUploaded)="setImage($event)"
                              [showImagePreview]="true"
                              [imageErrors]="checkLockedImageError()"
                              [limit]="limit" [previousSelectedImage]="checkSelectedLockedImage()"></app-image-uploader>
        </div>
      </div>
      <div class="col-md-12">
        <app-select-locale [parentForm]="benefitForm"></app-select-locale>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !benefit " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && benefit && benefit?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && benefit && !benefit?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingBenefit$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="addingBenefit$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingBenefit$ | async) && !benefit">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingBenefit$ | async) && benefit && !benefit?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(addingBenefit$ | async) && benefit && benefit?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingBenefit$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingBenefit$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
