<div class="row mt-3">
  <div class="col-12">
    <h4 class="card-title">{{'REVIEW_YOUR_SELECTIONS' | translate}}</h4>
    <p class="card-title-desc">{{'SURE_HOPE' | translate}}
    </p>
    <div *ngIf="banData &&  banData.usersToBan" class="clearfix">
      <h4 class="card-title d-inline-block mb-1">List of users you are about to ban <span
        class="badge badge-pill badge-soft-secondary font-size-11">{{banData.usersToBan?.length}}</span></h4>
      <div (click)="usersCollapsed = !usersCollapsed" class="float-end pointer">
        <span class="btn btn-secondary font-size-11 show-hide-users">
          <i *ngIf="!usersCollapsed" class="mdi mdi-close-circle-outline me-2"></i>
          <i *ngIf="usersCollapsed" class="bx bx-plus-circle me-2"></i>
          <span *ngIf="usersCollapsed">{{'OPEN_USER_LIST' | translate}}</span>
          <span *ngIf="!usersCollapsed">{{'CLOSE_USER_LIST' | translate}}</span>
        </span>
      </div>
    </div>
    <div [ngbCollapse]="usersCollapsed" class="collapse" id="collapseUsers">
      <div *ngIf="banData && banData.usersToBan" class="row mt-3 modal-content-max modal-content-small-height">
        <div class="col-12">
          <app-users-table *ngIf="banData.usersToBan.length > 0" [canBeRemoved]="false" [light]="true"
                           [users]="banData.usersToBan"></app-users-table>
        </div>
      </div>
    </div>
    <div *ngIf="banData" class="row mt-3">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <div class="mb-3">
              <label class="font-weight-bold" for="startsAt">Ban start date</label>
              <div class="input-group clockpicker">
                <input [(ngModel)]="banData.startsAt" [disabled]="true" class="form-control" id="startsAt">
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="font-weight-bold" for="startsAtHour">Ban start date hour</label>
              <div class="input-group clockpicker">
                <input [(ngModel)]="banData.startsAtHour" [disabled]="true" class="form-control" id="startsAtHour">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="banData?.endsAt" class="row">
          <div class="col-md-9">
            <div class="mb-3">
              <label class="font-weight-bold" for="endsAt">Ban expiration date</label>
              <div class="input-group clockpicker">
                <input [(ngModel)]="banData.endsAt" [disabled]="true" class="form-control" id="endsAt">
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="font-weight-bold" for="endsAtHour">Ban expiration date hour</label>
              <div class="input-group clockpicker">
                <input [(ngModel)]="banData.endsAtHour" [disabled]="true" class="form-control" id="endsAtHour">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 basic-select">
              <label class="font-weight-bold" for="motivation">Motivation</label>
              <div class="input-group">
                <input [(ngModel)]="banData.motivation.reason" [disabled]="true" class="form-control" id="motivation">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <h5 class="font-size-14 mb-1 font-weight-bold">Alerts</h5>
            <p class="card-title-desc mb-2">These notifications will be sent to the user when they are actually
              banned</p>
            <div class="form-check form-switch form-switch-md mb-3">
              <input [(ngModel)]="banData.sendMail" [disabled]="true" class="form-check-input" id="emailNotification"
                     type="checkbox">
              <label class="form-check-label font-weight-semibold" for="emailNotification">Send email</label>
            </div>
            <div class="form-check form-switch form-switch-md mb-3">
              <input [(ngModel)]="banData.sendPushNotification" [disabled]="true" class="form-check-input"
                     id="pushNotification" type="checkbox">
              <label class="form-check-label font-weight-semibold" for="pushNotification">Send push notification</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3">
        <label class="form-label font-weight-semibold" for="description">Ban notes</label>
        <p class="card-title-desc">
          {{'PRIVATE_NOTES' | translate}}
        </p>
        <div>
          <textarea [(ngModel)]="banNotes" [disabled]="(banning$ | async)" class="form-control description-textarea"
                    id="description"
                    rows="4"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<ul class="list-inline wizard mb-0">
  <li class="previous list-inline-item">
    <button [disabled]="(banning$ | async)" awPreviousStep class="btn btn-outline-light">{{'BACK' | translate}}</button>
  </li>
  <li class="next list-inline-item float-end">
    <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-warning">Confirm ban</button>
    <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
    <button (click)="confirmBanAction()" *ngIf="showAreYouSure" [disabled]="(banning$ | async)"
            class="btn btn-warning me-2"
            type="submit">
      <i *ngIf="banning$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
      <span *ngIf="banning$ | async">
            Banning
          </span>
      <span [hidden]="(banning$ | async)">
            {{'YES' | translate}}
          </span>
    </button>
    <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(banning$ | async)"
            class="btn btn-outline-light ">{{'NO' | translate}}
    </button>
  </li>
</ul>
