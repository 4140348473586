<div [formGroup]="recurringForm" class="row">
  <div class="col-12">
    <div class="input-group mb-2 d-block">
      <label class="font-weight-bold mb-2 d-block mt-0">{{'REPEAT' | translate}}</label>
      <div class="form-check select-check">
        <input class="form-check-input" type="radio" formControlName="frequency" [value]="Frequency.DAILY">
        <label class="form-check-label"> {{'DAILY' | translate}}</label>
      </div>
      <div class="form-check select-check">
        <input class="form-check-input" type="radio" formControlName="frequency" [value]="Frequency.WEEKLY">
        <label class="form-check-label">
          {{'WEEKLY' | translate}}
        </label>
      </div>
      <div class="form-check select-check">
        <input class="form-check-input" type="radio" formControlName="frequency" [value]="Frequency.MONTHLY">
        <label class="form-check-label">
          {{'MONTHLY' | translate}}
        </label>
      </div>
    </div>
  </div>
  <div class="col-8">
    <label class="font-weight-bold mb-2 d-block mt-0">{{'START_AND_END_DATE' | translate}}</label>
    <app-date-range-picker (dateRangeSelected)="setDateRange($event)" [recurring]="{startDate: form?.startDate?.value, endDate: form?.endDate?.value}" [init]="true" [noLabel]="true"></app-date-range-picker>
  </div>
  <div class="col-2">
    <div class="mb-3">
      <label class="font-weight-bold">{{'HOUR' | translate}}</label>
      <select class="form-select" formControlName="hour" id="hour" [ngClass]="{'is-invalid': form.hour.errors}">
        <option *ngFor="let option of utils.hourOptions" [selected]="option.selected"
                [value]="option.value">{{option.value | translate}}</option>
      </select>
      <div *ngIf="form.hour.errors" class="invalid-feedback">
        <span *ngIf="form.hour.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
        <span *ngIf="form.hour.errors.max">This max value for hour is 24.</span>
        <span *ngIf="form.hour.errors.min">This min value for hour is 1.</span>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="mb-3">
      <label class="font-weight-bold">{{'MINUTES' | translate}}</label>
      <select class="form-select" formControlName="minutes" id="minutes" [ngClass]="{'is-invalid': form.minutes.errors}">
        <option *ngFor="let option of utils.minutesOptions" [selected]="option.selected"
                [value]="option.value">{{option.value | translate}}</option>
      </select>
      <div *ngIf="form.minutes.errors" class="invalid-feedback">
        <span *ngIf="form.minutes.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
        <span *ngIf="form.minutes.errors.max">This max value for hour is 24.</span>
        <span *ngIf="form.minutes.errors.min">This min value for hour is 1.</span>
      </div>
    </div>
  </div>
  <div class="" *ngIf="form.frequency.value === Frequency.WEEKLY">
    <label class="font-weight-bold mb-2 d-block mt-2"  *ngIf="form.frequency.value === Frequency.WEEKLY">{{'REPEAT_ON' | translate}}</label>
    <div class="mb-2" formArrayName="onWeekday"  *ngIf="form.frequency.value === Frequency.WEEKLY">
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="0" ngbButton> Monday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="1" ngbButton> Tuesday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="2" ngbButton> Wednesday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="3" ngbButton> Thursday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="4" ngbButton> Friday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="5" ngbButton> Saturday
          </label>
        </div>
        <div class="form-check mb-3 select-check select-check-edit col-3">
          <label class="form-check-label" ngbButtonLabel>
            <input class="form-check-input" type="checkbox" formControlName="6" ngbButton> Sunday
          </label>
        </div>
      </div>
  </div>
  <ng-container *ngIf="form.frequency.value === Frequency.MONTHLY">
    <div class="form-group">
      <label class="font-weight-bold mb-0">On day</label>
      <p class="card-title-desc mb-2">
        Choose the day of the month when the notification will be sent. For example on the 15th of every month
      </p>
      <app-datepicker [selectedDate]="form?.onMonthday?.value" [name]="'MonthDay'" (dateSelected)="setOnMonthDay($event)"></app-datepicker>
    </div>
  </ng-container>
  <div class="form-group">
    <div class="form-control-plaintext dates-container">
      <p class="font-weight-bold mb-2">Number of notification that will be scheduled: {{ dates.length }}</p>
      <span class="d-inline-block date-pill" *ngFor="let date of dates">
        {{ date | date:'dd/MM/YYYY' }} {{form?.hour?.value}}:{{form?.minutes?.value}}
      </span>
    </div>
  </div>
</div>
