import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UserDiagnosticService} from "@service/users/user-diagnostic.service";
import {UtilsService} from "@service/utils/utils.service";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";

@Component({
  selector: 'app-user-fitness-charts',
  templateUrl: './user-fitness-charts.component.html',
  styleUrls: ['./user-fitness-charts.component.scss']
})
export class UserFitnessChartsComponent implements OnInit {
  @Input() userId: number;
  public results: any;
  public error: any = undefined;
  public loadingChart: boolean = false;
  public type = TopbarTypeEnum.DIAGNOSTIC_FITNESS_CHARTS;
  public selectedMetric = FitnessMetricEnum.STEPS;
  public options: any = this.userDiagnosticService.getDefaultFitnessChartApexOptions(this.diagnosticHeight());
  public dates: Date[] = [];
  public _series: any = [{data: []}];
  private chart: ApexCharts;

  constructor(private userDiagnosticService: UserDiagnosticService,
              private utils: UtilsService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }


  public setMetric(metric: any): void {
    this.selectedMetric = metric;
    this.search();
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !!date[2]) {
      this.dates = [date[1], date[2]];
    } else {
      this.dates = [];
    }
    this.search();
  }

  private search() {
    if (!this.chart) {
      this.chart = new ApexCharts(document.querySelector("#fitness-chart"), this.options);
      this.chart.render().then((result) => {
        this.updateSeries();
      }).catch((err) => {
      });
    } else {
      this.updateSeries();
    }
  }

  private updateSeries() {
    if (this.dates?.length === 2 && this.userId) {
      this.userDiagnosticService.getUserFitnessCharts(this.dates[0], this.dates[1], String(this.userId), this.selectedMetric).subscribe((result: any) => {
        let data: any = this.extractFormattedActivities(result);
        this.results = data;
        document.getElementById("user-chart").style.width = "99%";
        setTimeout(() => {
          this.chart.updateSeries(data);
          document.getElementById("user-chart").style.width = "100%";
        }, 100);
      }, (error => {
      }));
    }
  }


  private extractFormattedActivities(result: any): any {
    const sorted = result.sort(function (x, y) {
      return new Date(x?.day).getTime() - new Date(y?.day).getTime();
    });
    const consolidate = sorted?.map((activity) => {
      return [new Date(activity?.day).getTime(), activity?.consolidate];
    })
    const totalConsolidated = sorted?.map((activity) => {
      return activity?.consolidate;
    })?.reduce((partialSum, a) => partialSum + a, 0);
    const provisional = sorted?.map((activity) => {
      return [new Date(activity?.day).getTime(), activity?.provisional];
    })
    const totalProvisional = sorted?.map((activity) => {
      return activity?.provisional;
    })?.reduce((partialSum, a) => partialSum + a, 0);
    return [
      {
        data: consolidate,
        name: this.utils.getTranslatedValue(this.selectedMetric) + ' Consolidate',
        total: totalConsolidated
      },
      {
        data: provisional,
        name: this.utils.getTranslatedValue(this.selectedMetric) + ' Provisional',
        total: totalProvisional
      }
    ];
  }


  private diagnosticHeight(): number {
    const elements = Array.from(document.getElementsByClassName('tab-content') as HTMLCollectionOf<HTMLElement>)
    const height = elements[0]?.offsetHeight - 70;
    return height;
  }
}
