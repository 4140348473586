import {ActionOptions} from "@component/deeplinks/add-deeplink-modal/deeplink-options.enum";

export const deeplinkActions = [
	{label: 'DEEPLINK_HOME', value: ActionOptions.HOME},
	{label: 'DEEPLINK_COMPETITION', value: ActionOptions.COMPETITION},
	{label: 'DEEPLINK_CHALLENGE', value: ActionOptions.CHALLENGE},
	{label: 'DEEPLINK_COMPANY', value: ActionOptions.COMPANY},
	{label: 'DEEPLINK_COMPANY_SECTION', value: ActionOptions.COMPANY_SECTION},
	{label: 'DEEPLINK_USER_PROFILE', value: ActionOptions.USER_PROFILE},
	{label: 'DEEPLINK_PRIZE', value: ActionOptions.PRIZE},
	{label: 'DEEPLINK_PRIZE_SECTION', value: ActionOptions.PRIZE_SECTION},
	{label: 'DEEPLINK_OPEN_CLOSABLE_WEBVIEW', value: ActionOptions.OPEN_CLOSABLE_WEBVIEW},
	{label: 'DEEPLINK_OPEN_FIXED_WEBVIEW', value: ActionOptions.OPEN_FIXED_WEBVIEW},
	{label: 'LINK', value: ActionOptions.LINK},
	{label: 'DEEPLINK_FAQ', value: ActionOptions.FAQ},
	{label: 'DEEPLINK_FAQ_SECTION', value: ActionOptions.FAQ_SECTION},
	{label: 'DEEPLINK_ARCADE', value: ActionOptions.ARCADE},
	{label: 'DEEPLINK_LEADERBOARD', value: ActionOptions.LEADERBOARD},
	{label: 'DEEPLINK_DIARY', value: ActionOptions.DIARY}
]