<div class="row mt-3">
  <div class="col-12">
    <h4 class="card-title">{{'REVIEW_YOUR_SELECTIONS' | translate}}</h4>
    <p class="card-title-desc">{{'SURE_HOPE' | translate}}
    </p>
    <div *ngIf="notificationUsers" class="row">
      <div [ngClass]="{'col-md-12': notificationUsers?.audience === audienceEnum.ALL_USERS}" class="col-md-10">
        <div class="">
          <label class="font-weight-bold" for="title">{{'SELECTED_USERS' | translate}}</label>
          <input [disabled]="true" [placeholder]="notificationUsers.audience | translate" class="form-control"
                 id="title">
        </div>
      </div>
      <div *ngIf="notificationUsers?.audience !== audienceEnum.ALL_USERS" class="col-md-2 list-open-close">
        <span (click)="usersCollapsed = !usersCollapsed" class="btn btn-secondary show-hide-users">
          <i *ngIf="!usersCollapsed" class="mdi mdi-close-circle-outline me-2"></i>
          <i *ngIf="usersCollapsed" class="bx bx-plus-circle me-2"></i>
          <span *ngIf="usersCollapsed">{{'OPEN_LIST' | translate}}</span>
          <span *ngIf="!usersCollapsed">{{'CLOSE_LIST' | translate}}</span>
        </span>
      </div>
    </div>
    <div [ngbCollapse]="usersCollapsed" class="collapse" id="collapseUsers">
      <div *ngIf="notificationUsers && notificationUsers?.data"
           class="row mt-3 modal-content-max modal-content-small-height">
        <div class="col-12">
          <app-users-table *ngIf="notificationUsers?.data?.users?.length > 0" [canBeRemoved]="true" [light]="true"
                           [users]="notificationUsers?.data?.users"></app-users-table>
          <app-companies-table *ngIf="notificationUsers?.data?.companies?.length > 0" [canBeRemoved]="true"
                               [companies]="notificationUsers?.data?.companies"
                               [light]="true"></app-companies-table>
          <app-challenges-table *ngIf="notificationUsers?.data?.challenges?.length > 0" [canBeRemoved]="true"
                                [challenges]="notificationUsers?.data?.challenges"
                                [light]="true"></app-challenges-table>
          <app-competitions-table *ngIf="notificationUsers?.data?.competitions?.length > 0" [canBeRemoved]="true"
                                  [competitions]="notificationUsers?.data?.competitions"
                                  [light]="true"></app-competitions-table>
        </div>
      </div>
    </div>
    <div *ngIf="notificationData" class="row mt-3">
      <div class="col-12">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-3">
              <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
              <input [(ngModel)]="notificationData.title" [disabled]="true" class="form-control" id="title">
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label class="font-weight-bold" for="lang">{{'LANGUAGE' | translate}}</label>
              <input [placeholder]="utils.getSpecificLanguageName(notificationData.locale)" [disabled]="true" class="form-control" id="lang">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label class="form-label font-weight-semibold mb-1" for="message">{{'MESSAGE' | translate}}</label>
            <div>
              <textarea [(ngModel)]="notificationData.message" [disabled]="true"
                        class="form-control description-textarea" id="message" rows="4"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label class="font-weight-bold" for="action">Selected actions</label>
              <input [disabled]="true" [placeholder]="notificationData.action | translate" class="form-control"
                     id="action">
            </div>
          </div>
          <div class="col-md-4">
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_PROFILE" class="font-weight-bold"
                   for="link">{{'ACTION' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_LINK" class="font-weight-bold"
                   for="link">{{'LINK' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_PRIZE" class="font-weight-bold"
                   for="link">{{'PRIZE' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_ALL_TOGETHER_CHALLENGE" class="font-weight-bold"
                   for="link">{{'ALL_TOGETHER_CHALLENGE' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_CHALLENGE" class="font-weight-bold"
                   for="link">{{'CHALLENGE' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_COMPETITION" class="font-weight-bold"
                   for="link">{{'COMPETITION' | translate}}</label>
            <label *ngIf="notificationData?.action === actionsEnum.OPEN_COMPANY" class="font-weight-bold"
                   for="link">{{'COMPANY' | translate}}</label>
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_PROFILE" [disabled]="true" class="form-control"
                   id="link" placeholder="{{'WILL_OPEN_USER_PROFILE' | translate}}">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_LINK" [disabled]="true" [placeholder]="notificationData.actionDetail"
                   class="form-control" id="link">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_PRIZE" [disabled]="true" [placeholder]="notificationData.actionDetail?.description"
                   class="form-control" id="link">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_ALL_TOGETHER_CHALLENGE" [disabled]="true"
                   [placeholder]="notificationData.actionDetail?.challengeName" class="form-control" id="link">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_CHALLENGE" [disabled]="true" [placeholder]="notificationData.actionDetail?.challengeName"
                   class="form-control" id="link">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_COMPETITION" [disabled]="true" [placeholder]="notificationData.actionDetail?.competitionName"
                   class="form-control" id="link">
            <input *ngIf="notificationData?.action === actionsEnum.OPEN_COMPANY" [disabled]="true" [placeholder]="notificationData.actionDetail?.name"
                   class="form-control" id="link">
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label class="font-weight-bold" for="delivery">Scheduled for</label>
              <input *ngIf="notificationDelivery?.deliveryOption === deliveryEnum.NOW" [disabled]="true"
                     [placeholder]="notificationDelivery?.deliveryOption | translate"
                     class="form-control" id="delivery">
              <input *ngIf="notificationDelivery?.deliveryOption === deliveryEnum.FUTURE" [disabled]="true"
                     [placeholder]="extractStartHourDate()"
                     class="form-control" id="delivery">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="notificationData?.imageUrl">
      <div class="col-md-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="image">Image</label>
          <div class="input-group">
            <input type="text" id="image" class="form-control" [(ngModel)]="notificationData.imageName"  [disabled]="true"/>
            <label (click)="showImage()" class="input-group-text pointer image-detail-eye"><i class="bx bx-show"></i></label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label class="form-label font-weight-semibold" for="description">Notification notes</label>
        <p class="card-title-desc mb-1">
          {{'PRIVATE_NOTES' | translate}}
        </p>
        <div>
          <textarea [(ngModel)]="notificationNotes" [disabled]="(sendingPush$ | async)"
                    class="form-control description-textarea"
                    id="description"
                    rows="4"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<ul class="list-inline wizard mb-0">
  <li *ngIf="!massiveNotification" class="previous list-inline-item">
    <button [disabled]="(sendingPush$ | async)" awPreviousStep
            class="btn btn-outline-light">{{'BACK' | translate}}</button>
  </li>
  <li *ngIf="massiveNotification" class="previous list-inline-item">
    <button (click)="goBackStep()" class="btn btn-outline-light">{{'BACK' | translate}}</button>
  </li>
  <li class="next list-inline-item float-end">
    <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-warning">Confirm send</button>
    <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
    <button (click)="confirmSendNotification()" *ngIf="showAreYouSure" [disabled]="(sendingPush$ | async)"
            class="btn btn-warning me-2"
            type="submit">
      <i *ngIf="sendingPush$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
      <span *ngIf="sendingPush$ | async">
            Sending
          </span>
      <span [hidden]="(sendingPush$ | async)">
            {{'YES' | translate}}
          </span>
    </button>
    <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(sendingPush$ | async)"
            class="btn btn-outline-light ">{{'NO' | translate}}
    </button>

<!--    <button (click)="confirmSendNotification()" class="btn btn-warning" type="submit">-->
<!--      <i *ngIf="sendingPush$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>-->
<!--      <span *ngIf="!(sendingPush$ | async)"> Confirm </span>-->
<!--      <span *ngIf="sendingPush$ | async"> Working </span>-->
<!--    </button>-->
<!--    <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>-->
<!--    <button *ngIf="showAreYouSure" [disabled]="(sendingPush$ | async)" class="btn btn-warning me-2" type="submit" (click)="confirmSendNotification()">-->
<!--      <i *ngIf="sendingPush$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>-->
<!--   <span  [hidden]="(sendingPush$ | async)">-->
<!--            {{'YES' | translate}}-->
<!--          </span>-->
<!--    </button>-->
<!--    <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(sendingPush$ | async)"-->
<!--            class="btn btn-outline-light ">{{'NO' | translate}}-->
<!--    </button>-->
  </li>
</ul>
