import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ToastService {
  toasts: any[] = [];
  private _updated = new BehaviorSubject<boolean>(false);

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
    this._updated.next(true);
  }

  get toastUpdate$() {
    return this._updated.asObservable();
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
    this._updated.next(true);
  }
}
