import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss']
})
export class NumberPickerComponent implements OnInit, OnDestroy {
  @Input() title: string = 'AVAILABLE_CREDITS';
  @Input() clearAll: Observable<boolean>;
  @Input() hide: boolean;
  @Input() selected: any | undefined;
  @Output() numberSelected: EventEmitter<string[]> = new EventEmitter();
  public availableNumber: any = null;
  public availableNumberOperator: any = "EQ";
  public operators: any[] = [
    {value: "EQ", label: "EQUAL_TO", selected: true},
    {value: "GT", label: "GREATER_THAN", selected: false},
    {value: "GTE", label: "GREATER_EQUAL_THAN", selected: false},
    {value: "LT", label: "LESS_THAN", selected: false},
    {value: "LTE", label: "LESS_EQUAL_THAN", selected: false},
  ];
  private eventsSubscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    if (this.clearAll) {
      this.eventsSubscription = this.clearAll.subscribe((clear) => {
        if (clear) {
          this.clear();
        }
      });
    }
    if (this.selected?.operator && this.selected?.number) {
      this.availableNumber = this.selected?.number;
      this.availableNumberOperator = this.selected?.operator;
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  public getNumberValue(event: Event): any {
    if (typeof event === 'number' && this.availableNumberOperator) {
      this.numberSelected.emit([this.availableNumberOperator, event]);
    } else if (typeof event !== 'number' && event?.type === 'change' && !!this.availableNumber) {
      this.numberSelected.emit([this.availableNumberOperator, this.availableNumber]);
    } else if (typeof event !== 'number') {
      this.numberSelected.emit(undefined);
    }
  }

  public clearValue(): void {
    if (this.availableNumber && this.availableNumberOperator) {
      this.clear();
    }
  }

  private clear() {
    this.availableNumber = undefined;
    this.availableNumberOperator = "EQ";
    this.numberSelected.emit(undefined);
  }

}
