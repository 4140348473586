export enum TableFilterEnum {
  REGISTRATION_DATE = 'REGISTRATION_DATE',
  LAST_APP_OPEN = 'LAST_APP_OPEN',
  LAST_SYNC = 'LAST_SYNC',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  USER_DELETED = 'USER_DELETED',
  AVAILABLE_CREDITS = 'AVAILABLE_CREDITS',
  PLATFORM = 'PLATFORM',
  BAN_TYPE = 'BAN_TYPE',
  BAN_STATUS = 'BAN_STATUS',
  BAN_START_DATE = 'BAN_START_DATE',
  BAN_END_DATE = 'BAN_END_DATE',
  BY_SYSTEM = 'BY_SYSTEM',
  SEND_PUSH = 'SEND_PUSH',
  SEND_EMAIL = 'SEND_EMAIL',
  NOTIFICATION_START_DATE = 'NOTIFICATION_START_DATE',
  ACTIVE = 'ACTIVE',
  SINGLE_USE_FOR_USER = 'SINGLE_USE_FOR_USER',
  ASSIGNED_CREDITS = 'ASSIGNED_CREDITS',
  NUMBER_OF_USES = 'NUMBER_OF_USES',
  NUMBER_OF_USERS = 'NUMBER_OF_USES',
  // AUTOMATIC BAN FILTERS
  BAN_THRESHOLD = 'BAN_THRESHOLD',
  BAN_THRESHOLD_FOR_WHITELIST = 'BAN_THRESHOLD_FOR_WHITELIST',
  FITNESS_METRIC = 'FITNESS_METRIC',
  VALID_FOR_ALL_COMPANIES = 'VALID_FOR_ALL_COMPANIES',
  PRIORITY = 'PRIORITY',
  CREATION_DATE = 'CREATION_DATE',
  UPDATED_AT = 'UPDATED_AT',
  // INVITE
  INVITE_ID = 'INVITE_ID',
  INVITE_USER_ID = 'INVITE_USER_ID',
  INVITE_ACTIVE = 'INVITE_ACTIVE',
  INVITE_CREATED_AT = 'INVITE_CREATED_AT',
  INVITE_USES_COUNT = 'INVITE_USES_COUNT',
  // INVITE USES = 'INVITE USES
  INVITE_USE_ID = 'INVITE_USE_ID',
  INVITE_USE_USER_ID = 'INVITE_USE_USER_ID',
  INVITE_USE_CODE_ID = 'INVITE_USE_CODE_ID',
  INVITE_USE_USED_AT = 'INVITE_USE_USED_AT',
  // PRIZES
  PRIZE_PRICE = 'PRIZE_PRICE',
  PRIZE_TOTAL_UNITS = 'PRIZE_TOTAL_UNITS',
  PRIZE_REDEEMED_UNITS = 'PRIZE_REDEEMED_UNITS',
  PRIZE_TYPE = 'PRIZE_TYPE',
  PRIZE_CONTEXT_TYPE = 'PRIZE_CONTEXT_TYPE',
  PRIZE_AVAILABLE_UNITS = 'PRIZE_AVAILABLE_UNITS',
  PRIZE_ACTIVE = 'PRIZE_ACTIVE',
  PRIZE_CREATION_DATE = 'PRIZE_CREATION_DATE',
  PRIZE_EXPIRATION_DATE = 'PRIZE_EXPIRATION_DATE',
  // CHALLENGES
  CHALLENGE_TYPE = 'CHALLENGE_TYPE',
  CHALLENGE_PUBLIC = 'CHALLENGE_PUBLIC',
  CHALLENGE_ANTE = 'CHALLENGE_ANTE',
  CHALLENGE_POOL = 'CHALLENGE_POOL',
  CHALLENGE_PARTICIPANTS = 'CHALLENGE_PARTICIPANTS',
  CHALLENGE_MAX_PARTICIPANTS = 'CHALLENGE_MAX_PARTICIPANTS',
  CHALLENGE_METRIC = 'CHALLENGE_METRIC',
  CHALLENGE_STATUS = 'CHALLENGE_STATUS',
  CHALLENGE_CREATED_AT = 'CHALLENGE_CREATED_AT',
  CHALLENGE_STARTS_AT = 'CHALLENGE_STARTS_AT',
  CHALLENGE_ENDS_AT = 'CHALLENGE_ENDS_AT',
  CHALLENGE_ACCEPT_RESULTS_UNTIL = 'CHALLENGE_ACCEPT_RESULTS_UNTIL',
  // COMPANIES,
  COMPANY_PRIORITY = 'COMPANY_PRIORITY',
  COMPANY_ENABLED = 'COMPANY_ENABLED',
  COMPANY_DISCOVERABLE = 'COMPANY_DISCOVERABLE',
  COMPANY_ACTIVE = 'COMPANY_ACTIVE',
  COMPANY_SHOW_USERS_COUNT = 'COMPANY_SHOW_USERS_COUNT',
  COMPANY_SHOW_LEADERBOARDS = 'COMPANY_SHOW_LEADERBOARDS',
  COMPANY_MUST_INSERT_CODE = 'COMPANY_MUST_INSERT_CODE',
  COMPANY_CREATED_AT = 'COMPANY_CREATED_AT',
  COMPANY_LAST_UPDATE = 'COMPANY_LAST_UPDATE',
  // COMPETITIONS
  COMPETITION_ACTIVE = 'COMPETITION_ACTIVE',
  COMPETITION_BY_POWER_LEVEL = 'COMPETITION_BY_POWER_LEVEL',
  COMPETITION_TYPE = 'COMPETITION_TYPE',
  COMPETITION_STATUS = 'COMPETITION_STATUS',
  COMPETITION_CREATED_AT = 'COMPETITION_CREATED_AT',
  // FAQS
  FAQ_PRIORITY = 'FAQ_PRIORITY',
  FAQ_ACTIVE = 'FAQ_ACTIVE',
  FAQ_CREATED_AT = 'FAQ_CREATED_AT',
  FAQ_UPDATED_AT = 'FAQ_UPDATED_AT',
  // STREAKS TEMPLATES
  STREAK_TEMPLATE_FITNESS_SCORING = 'STREAK_TEMPLATE_FITNESS_SCORING',
  // STREAKS
  STREAK_VALIDITY_DATE = 'STREAK_VALIDITY_DATE',
  STREAK_CREDITS = 'STREAK_CREDITS',
  STREAK_VALID_FOR_COMPETITIONS = 'STREAK_VALID_FOR_COMPETITIONS',
  STREAK_ACTION = 'STREAK_ACTION'
}
