import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {Observable, Subscription} from "rxjs";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {ChallengesService} from "@service/challenges/challenges.service";
import {UntypedFormGroup} from "@angular/forms";
import {ChallengeTypeEnum} from "@enum/challenge-type/challenge-type.enum";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-select-challenge',
  templateUrl: './select-challenge.component.html',
  styleUrls: ['./select-challenge.component.scss']
})
export class SelectChallengeComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Output() selectedChallenge = new EventEmitter();
  @Input() pageFilter: boolean;
  @Input() pageFilterErrors: boolean;
  @Input() users: any;
  @Input() selectedAudience: any;
  @Input() editDetail: any;
  @Input() single: boolean;
  @Input() allTogether: boolean;
  @Input() selectedItems: any;
  @Input() hideDescription: boolean;
  @Input() error: boolean;
  @Input() formSubmitted: boolean;
  @Input() parentForm: UntypedFormGroup;
  public selected: any;
  public list$: Observable<any[]>;
  public subscription: Subscription;

  constructor(private challengesService: ChallengesService, private utils: UtilsService) {
  }

  get form() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm?.controls['actionDetail']?.disabled) {
      this.parentForm.controls['actionDetail'].enable();
    }
    this.challengesService.sortColumn = 'startsAt';
    this.challengesService.sortDirection = 'desc';
    this.challengesService.status = ['AWAITING_START','ACTIVE','ACTIVE_LAST_HALF','AWAITING_RESULTS'];
    this.challengesService.pageSize = 1000;
    this.challengesService.initSearchChallengesListener();
    if (this.allTogether) {
      this.challengesService.type = ChallengeTypeEnum.ALL_TOGETHER;
    }
    this.list$ = this.challengesService.challengeList$;
    this.subscription = this.list$.subscribe((data: any) => {
      if (data?.length > 0) {
        data = data?.map((el) => {
          if (el?.id) {
            el.bindLabel = [el?.id, el?.challengeName].join(' - ');
          } else {
            el.bindLabel = el?.challengeName;
          }
          return el;
        });
      }
      if (data?.length > 0 && this.selectedItems?.length > 0) {
        const result = this.utils.extractSelectedItems(data, this.selectedItems);
        if (result && result?.length > 0) {
          this.selectedItems = Array.from(new Set(result.map(a => a.id))).map(id => {
            return result.find(a => a.id === id)
          });
          if (this.selectedItems && this.selectedItems?.length > 0) {
            this.selected = this.selectedItems[0];
            this.selectChallenge(this.selectedItems[0]);
          }
        }
      }
    });
    if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_CHALLENGE && this.users?.data?.challenges?.length > 0) {
      this.parentForm.patchValue({['actionDetail']: this.users?.data?.challenges[0]});
      this.parentForm.controls['actionDetail'].disable();
    }
    if (this.selectedItems && this.selectedItems?.length > 0) {
      this.selected = this.selectedItems[0];
    }
    this.preselectActionForEdit();
  }

  private preselectActionForEdit() {
    if (!!this.editDetail && this.editDetail?.id && !this.selected) {
      this.selected = this.editDetail;
      this.parentForm.patchValue({['actionDetail']: this.selected});
    } else if (!!this.editDetail && !this.selected) {
      this.subscription = this.list$.subscribe((data: any) => {
        const selected = data?.find((el) => el?.id === Number(this.editDetail));
        if (selected) {
          this.selected = selected;
          this.parentForm.patchValue({['actionDetail']: this.selected});
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const audience: AudienceEnum = changes?.selectedAudience?.currentValue;
    if (changes?.selectedItems?.currentValue && changes?.selectedItems?.currentValue[0]) {
      this.selected = changes?.selectedItems?.currentValue[0];
    } else {
      this.selected = undefined;
    }
  }

  public searchChallenge(term: any): void {
    this.challengesService.searchTerm = term?.term;
  }

  public selectChallenge(event: any): void {
    if (event) {
      this.selectedChallenge.emit([event]);
    } else {
      this.selectedChallenge.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.selectedItems = null;
    this.selected = null;
    this.challengesService.removeSearchChallengesSubscribe();
  }
}
