import {Directive, ElementRef, OnInit} from "@angular/core";
import {KeycloakService} from "keycloak-angular";
import {USR_ROLES} from "../../../configs/roles/roles";

@Directive({
  selector: '[fullUser]'
})
export class FullUserDirective implements OnInit {
  private usrRoles = USR_ROLES;

  constructor(private elementRef: ElementRef, protected readonly keycloak: KeycloakService) {
  }

  ngOnInit(): void {
    const allowed = this.keycloak.getUserRoles().includes(this.usrRoles[1]);
    if (!allowed) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
