import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {CompaniesTeamsService} from "@service/companies/companies-teams.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CompaniesMembershipsService} from "@service/companies/companies-memberships.service";

@Component({
  selector: 'app-add-memberships-modal',
  templateUrl: './add-memberships-modal.component.html',
  styleUrls: ['./add-memberships-modal.component.scss']
})
export class AddMembershipsModalComponent implements OnInit {
  @Input() company: any;
  @Input() users: any;
  public addingMemberships$: Observable<boolean>;
  public tmpUserIds: any | undefined = undefined;
  public team: any | undefined = undefined;
  public code: any | undefined = undefined;
  public showSelectedUsers: boolean = false;
  public selectedUsers: any[] = [];
  public showAreYouSure: boolean = false;
  public membershipForm: UntypedFormGroup;
  public formSubmitted: boolean = false;
  public DeleteModalTypeEnum = DeleteModalTypeEnum;
  public teams: any[] = [];

  constructor(public utils: UtilsService, private toastService: ToastService, private companiesMembershipsService: CompaniesMembershipsService,
              private formBuilder: UntypedFormBuilder, private companyTeamService: CompaniesTeamsService) { }

  ngOnInit(): void {
    this.addingMemberships$ = this.companiesMembershipsService.adding$;
    this.initForm();
    if (this.company) {
      this.extractTeams();
    }
    if (this.users) {
      this.selectedUsers = this.users?.map((user: any) => {user.userId = user?.id; return user;});
      const userIds = this.selectedUsers?.map((user: any) => {return user?.userId})
      this.tmpUserIds = userIds?.join('\n');
      this.showSelectedUsers = true;
      this.membershipForm.patchValue({
        ['userIds']: this.selectedUsers.map((user) => {
          return user?.userId
        })
      });
    }
  }

  public addUserIds(): void {
    const userIdsList = this.tmpUserIds.split('\n');
    const userIds = userIdsList?.map((code: string) => {return Number(code)})?.filter((code) => !!code)?.filter((v, i, a) => a.indexOf(v) === i);
    this.getUserIds(userIds);
  }

  private getUserIds(userIds) {
    this.utils.getUsersByIdList(userIds?.join()).subscribe((result: any) => {
      if (result?.length > 0) {
        this.selectedUsers = result;
        this.membershipForm.patchValue({
          ['userIds']: this.selectedUsers.map((user) => {
            return user?.userId
          })
        });
        this.showSelectedUsers = true;
      } else {
        this.toastService.show('No users where found with provided ids', {classname: 'bg-danger text-light'});
      }
    });
  }

  public extractTeams(): void {
    this.companyTeamService.getTeamsById({companyId: this.company?.id, page: 1, size: 1000}).subscribe((teams: any) => {
      this.teams = teams?.data?.length > 0 ? teams?.data : [];
    });
  }


  public removeUser(selectedUser: any): void {
    this.selectedUsers = this.selectedUsers.filter((user) => user?.userId !== selectedUser?.userId);
    if (!this.selectedUsers || this.selectedUsers?.length === 0) {
      this.showSelectedUsers = false;
    } else {
    }
  }

  public addMoreUsers(): void {
    const userIds = this.selectedUsers?.map((user: any) => {return user?.userId})
    this.tmpUserIds = userIds?.join('\n');
    this.showSelectedUsers = false;
  }

  get form() {
    return this.membershipForm.controls;
  }

  get valid() {
    return this.membershipForm.valid
  }

  public checkNextActionBtn(): void {
    if (this.membershipForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  public validSubmit() {
    if (this.membershipForm.valid) {
      const form = this.membershipForm.getRawValue();
      const data = {
        ids: form?.userIds,
        company: form?.company,
        membershipRequest: {
          joinCode: form?.code,
          team: String(form?.team)
        }
      }
      this.companiesMembershipsService.addUsers(data);
    }
  }

  private initForm(): void {
    this.membershipForm = this.formBuilder.group({
      company: [this.company ? this.company : null, [Validators.required]],
      userIds: [null, [Validators.required]],
      team: [null],
      code: [null],
    });
  }


}
