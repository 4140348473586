import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UcWidgetComponent} from "ngx-uploadcare-widget";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() previousSelectedFile: any = undefined;
  @Output() fileUploaded = new EventEmitter();
  public selectedFile: any = undefined;
  @ViewChild('uploader') private uploader: UcWidgetComponent;

  constructor() {
  }

  ngOnInit(): void {
    if (!!this.previousSelectedFile) {
      this.selectedFile = this.previousSelectedFile;
    }
  }

  public showFile(): void {
    window.open(this.previousSelectedFile?.originalUrl, '_blank');
  }

  public openUploader(): void {
    this.uploader.openDialog();
  }

  public upload(event: any): void {
    this.selectedFile = event
    this.fileUploaded.emit(event)
  }

  public clearFile(): void {
    this.selectedFile = undefined;
    this.uploader.clearUploads();
    this.fileUploaded.emit(null)
  }
}
