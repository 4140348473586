import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {CompetitionsService} from "@service/competitions/competitions.service";
import {FaqsService} from "@service/faqs/faqs.service";

@Component({
  selector: 'app-faq-select',
  templateUrl: './faq-select.component.html',
  styleUrls: ['./faq-select.component.scss']
})
export class FaqSelectComponent implements OnInit {
	@Output() selectedFaq = new EventEmitter();
	@Input() error: boolean = false;
	@Input() restrictionId: any | undefined;
	@Input() selected: any | undefined;
	@Input() multiple: boolean;
	public data: any[] | undefined;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	constructor(public faqsService: FaqsService, private _changeDetectorRef: ChangeDetectorRef) { }
	
	ngOnInit(): void {
		this.faqsService.pageSize = 25;
		this.faqsService.initSearchFaqsListener();
		this.faqsService._search$.next();
		this.faqsService.faqList$.pipe(takeUntil(this._unsubscribeAll)).subscribe((faqs) => {
			this.data = faqs;
			this._changeDetectorRef.markForCheck();
		});
	}
	
	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
		this.faqsService.removeSearchFaqsSubscribe();
		this.selected = null;
	}
	
	public selectFaq(event: any): void {
		if (event) {
			this.selectedFaq.emit(event);
		} else {
			this.selectedFaq.emit(null);
		}
	}
	
	public extractPlaceholder(): string {
		if (this.selected) {
			return this.returnItemDescription(this.data?.find((item) => item.id === this.selected?.id));
		} else {
			return 'Select faq'
		}
	}
	
	public returnItemDescription(item): string {
		return item?.title
	}
	
	public search(term: string, item: any) {
		return item;
	}

}
