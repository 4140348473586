import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbDatepickerConfig} from "@ng-bootstrap/ng-bootstrap";
import {UtilsService} from "@service/utils/utils.service";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {AudienceEnum} from "@enum/audience/audience.enum";

@Component({
  selector: 'app-notification-options',
  templateUrl: './notification-options.component.html',
  styleUrls: ['./notification-options.component.scss']
})
export class NotificationOptionsComponent implements OnInit, OnDestroy {
  @Input() notificationUsers: any;
  @Input() massiveNotification: boolean;
  @Input() notificationData: any;
  @Output() selectedNotificationInformation = new EventEmitter();
  @Output() goBackStepEvent = new EventEmitter();
  public notificationForm: UntypedFormGroup;
  public formSubmitted: boolean;
  public languages = this.utils.languages;
  public selectedLanguage = null;
  public limitToOpenLink: boolean = false;
  imageSrc: string;
  width: number;
  height: number;

  constructor(public formBuilder: UntypedFormBuilder, private config: NgbDatepickerConfig, private utils: UtilsService) {
    const current = new Date();
    config.minDate = {year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate()};
    config.outsideDays = 'hidden';
  }

  get form() {
    return this.notificationForm.controls;
  }

  get valid() {
    return this.notificationForm.valid
  }

  ngOnInit(): void {
    const today = new Date()
    this.notificationForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      message: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(178)]],
      imageUrl: [null],
      locale: [null, [Validators.required]],
      action: [ActionsEnum.OPEN_LINK, [Validators.required]],
      actionDetail: [null, [Validators.required]],
      imageName: [null],
      imageType: [null],
    });
    if (this.notificationData) {
      this.setFormData();
    }
    this.checkNotificationUsersActions();
    this.subscribeChanges();
  }

  private checkNotificationUsersActions() {
    if (this.notificationUsers && this.notificationUsers?.audience !== AudienceEnum.ALL_USERS) {
      switch (this.notificationUsers?.audience) {
        case AudienceEnum.ALL_USERS_IN_CHALLENGE:
          this.limitToOpenLink = this.notificationUsers?.data?.challenges?.length > 1;
          break;
        case AudienceEnum.ALL_USERS_IN_COMPETITION:
          this.limitToOpenLink = this.notificationUsers?.data?.competitions?.length > 1;
          break;
        case AudienceEnum.ALL_USERS_IN_COMPANY:
          this.limitToOpenLink = this.notificationUsers?.data?.companies?.length > 1;
          break;
      }
    }
  }

  ngOnDestroy() {
    this.config.minDate = null;
    this.config.outsideDays = 'visible';
  }

  public goBackStep(): void {
    this.notificationForm.patchValue({['actionDetail']: null});
    this.goBackStepEvent.emit();
  }

  public validateForm() {
    if (this.notificationForm.valid) {
      let notificationForm = this.notificationForm.getRawValue();
      this.selectedNotificationInformation.emit(notificationForm);
    }
    this.formSubmitted = true;
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.notificationForm.patchValue({
        imageUrl: image?.originalUrl,
        imageName: image?.name,
        imageType: image?.mimeType,
      });
    }
  }

  public logForm(evt: any): void {
    let notificationForm = this.notificationForm.getRawValue();
  }

  public removeImage(): void {
    this.notificationForm.patchValue({
      imageUrl: null,
      imageName: null,
      imageType: null,
    });
  }

  private subscribeChanges(): void {
    this.notificationForm.valueChanges.subscribe(value => {
      this.checkLinkValidation();
    });
  }

  private checkLinkValidation(): void {
    if (this.notificationForm?.controls?.action?.value === ActionsEnum.OPEN_LINK) {
      this.notificationForm?.controls['actionDetail']?.setValidators([Validators.required, Validators.pattern(this.utils.validateUrlRegex)])
    } else if (this.notificationForm?.controls?.action?.value === ActionsEnum.OPEN_HOME) {
      this.notificationForm?.controls['actionDetail']?.clearValidators();
    } else {
      this.notificationForm?.controls['actionDetail']?.clearValidators();
      this.notificationForm?.controls['actionDetail']?.setValidators([Validators.required])
    }
  }

  private setFormData(): void {
    this.notificationForm.patchValue({
      title: this.notificationData?.title,
      message: this.notificationData?.message,
      imageUrl: this.notificationData?.imageUrl,
      locale: this.notificationData?.locale,
      imageName: this.notificationData?.imageName,
      imageType: this.notificationData?.imageType,
      link: this.notificationData?.link,
      action: this.notificationData?.action ? this.notificationData?.action as ActionsEnum : ActionsEnum.OPEN_LINK,
      actionDetail: this.notificationData?.actionDetail,
    });
    this.selectedLanguage = this.notificationData?.locale;
    if (this.notificationData?.action) {
      this.notificationForm.patchValue({['action']: this.notificationData.action});
    }
  }


}
