import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationDeliveryOptionEnum} from "@enum/notifications/notification-delivery-option/notification-delivery-option.enum";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UtilsService} from "@service/utils/utils.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-notification-delivery',
  templateUrl: './notification-delivery.component.html',
  styleUrls: ['./notification-delivery.component.scss']
})
export class NotificationDeliveryComponent implements OnInit {
  @Input() notificationDelivery: any;
  @Input() massiveNotification: boolean;
  @Output() selectedNotificationDelivery = new EventEmitter();
  @Output() goBackStepEvent = new EventEmitter();
  public deliveryOption: NotificationDeliveryOptionEnum = NotificationDeliveryOptionEnum.NOW;
  public notificationDeliveryForm: UntypedFormGroup;
  public notificationDeliveryOptionEnum = NotificationDeliveryOptionEnum;

  constructor(private utils: UtilsService, private formBuilder: UntypedFormBuilder) {
  }

  public get form() {
    return this.notificationDeliveryForm?.controls;
  }

  public get valid() {
    return this.notificationDeliveryForm.valid;
  }

  ngOnInit(): void {
    this.setNotificationForm();
    if (this.notificationDelivery && this.notificationDelivery?.deliveryOption === NotificationDeliveryOptionEnum.FUTURE) {
      this.setNotificationDelivery();
    }
  }

  public setNotificationDelivery(): void {
    const date = new Date(this.notificationDelivery?.startsAt);
    this.deliveryOption = NotificationDeliveryOptionEnum.FUTURE;
    this.notificationDeliveryForm.patchValue({
      startsAt: new NgbDate(date.getFullYear(), date.getMonth() +1 , date.getDate()),
      startsAtHour: this.notificationDelivery?.startsAtHour,
    });
  }

  public setNotificationForm(): void {
    const today = new Date()
    this.notificationDeliveryForm = this.formBuilder.group({
      startsAt: [new NgbDate(today.getFullYear(), today.getMonth() +1 , today.getDate()), [Validators.required]],
      startsAtHour: [1, [Validators.required]],
    });
  }

  public validateForm(): void {
    if (this.deliveryOption === NotificationDeliveryOptionEnum.FUTURE && this.notificationDeliveryForm.valid) {
      let notificationForm = this.notificationDeliveryForm.getRawValue();
      notificationForm.startsAt = this.utils.formatDateFromNgb(notificationForm.startsAt);
      const startsAt = notificationForm.startsAt;
      const startsAtHour = notificationForm.startsAtHour;
      this.selectedNotificationDelivery.emit({startsAt, startsAtHour, deliveryOption: this.deliveryOption})
    } else if (this.deliveryOption === NotificationDeliveryOptionEnum.NOW) {
      this.selectedNotificationDelivery.emit({deliveryOption: this.deliveryOption})
    }
  }

  public goBackStep(): void {
    this.goBackStepEvent.emit();
  }

}
