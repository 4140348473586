import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class OperatorsApiService {
  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.user.operators.list, params);
  }

  public addEditOperator(params: any): Observable<any> {
    return this.http.post(endpoints.user.operators.create, params);
  }
}
