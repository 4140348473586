<app-table-topbar (emitSearchTerm)="service.searchTerm = $event" (updateColumnsEvent)="selected = $event" [service]="service"
                  [showColumnPicker]="false"
                  [type]="topBarType"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="bans$ | async as bans" class="wrapper">
    <table [hidden]="bans?.length === 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped-bg mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.USER_ID)" class="column-sortable"
            sortable="id">
          <span class="me-3">{{'USER_ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.EMAIL)" class="column-sortable"
            sortable="email">
          <span class="me-3">{{'EMAIL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPLETE_NAME)"
            class="column-sortable" sortable="fullName">
          <span class="me-3">{{'COMPLETE_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.KEYCLOAK_ID)"
            class="column-sortable" sortable="idmid" style="max-width: 15%!important;">
          <span class="me-3">{{'KEYCLOAK_ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)"
            class="column-sortable" sortable="banned">
          <span class="me-3">{{'BAN_STATUS' | translate}}</span>
        </th>
        <!--            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)"-->
        <!--                class="column-sortable" sortable="systemBan">-->
        <!--              <span class="me-3">{{'BY_SYSTEM' | translate}}</span>-->
        <!--            </th>-->
        <th *ngIf="checkIfColumnIsVisible(column.TOTAL_BAN_RECEIVED)">
          <span class="me-3">{{'TOTAL_BAN_RECEIVED' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngFor="let ban of bans; let i=index;">
      <tr>
        <td align="center" class="pointer fit-width">
                <span (click)="ban.expand = !ban.expand" class="btn btn-secondary font-size-11 show-hide-users">
                  <i *ngIf="ban.expand" class="mdi mdi-close-circle-outline me-2"></i>
                  <i *ngIf="!ban.expand" class="bx bx-plus-circle me-2"></i>
                  <span *ngIf="!ban.expand">Show user bans</span>
                  <span *ngIf="ban.expand">Hide user bans</span>
                </span>
        </td>
        <td (click)="userDetail(ban)" align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.USER_ID)" align="center"
            class="pointer fit-width">
          <ngb-highlight [highlightClass]="'term-highlight'" [result]="ban?.user?.id"
                         [term]="service.searchTerm"
                         class="badge badge-pill badge-soft-primary"></ngb-highlight>
        </td>
        <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.EMAIL)" class="pointer">
          <app-missing-label *ngIf="!ban?.user?.email"></app-missing-label>
          <ngb-highlight *ngIf="ban?.user?.email" [highlightClass]="'term-highlight'" [result]="ban?.user?.email"
                         [term]="userListService.searchTerm"></ngb-highlight>
        </td>
        <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.COMPLETE_NAME)" class="pointer">
          <span *ngIf="ban?.user?.profile?.firstName">{{extractUserFullName(ban?.user)}}</span>
          <app-missing-label *ngIf="!ban?.user?.profile?.firstName"></app-missing-label>
        </td>
        <td (cbOnSuccess)="copied($event)" (click)="userDetail(ban)" (mouseenter)="ban.copyKcId = true;"
            (mouseleave)="ban.copyKcId = false;"
            *ngIf="checkIfColumnIsVisible(column.KEYCLOAK_ID)" [cbContent]="ban?.user?.idmid"
            class="pointer" ngxClipboard>
          <span ngbTooltip="{{ban?.user?.idmid}}" placement="bottom">{{ban?.user?.idmid | truncate:[10]}}</span>
          <i [ngClass]="ban.copyKcId ? 'opacity-1' : 'opacity-0'" class="mdi mdi-content-copy me-1"
             ngbTooltip="Click to copy" placement="bottom"></i>
        </td>
        <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)" align="center"
            class="pointer fit-width">
              <span *ngIf="!ban?.banned"
                    class="badge badge-pill font-size-11 badge-soft-danger">{{'EXPIRED' | translate | uppercase}}</span>
          <span *ngIf="ban?.banned"
                class="badge badge-pill font-size-11 badge-soft-success">{{'ACTIVE' | translate | uppercase}}</span>
        </td>
        <!--            <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)" align="center"-->
        <!--                class="pointer fit-width">-->
        <!--              <span *ngIf="ban?.bySystem">{{'YES' | translate}}</span>-->
        <!--              <span *ngIf="!ban?.bySystem">{{'NO' | translate}}</span>-->
        <!--            </td>-->
        <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.TOTAL_BAN_RECEIVED)" align="center"
            class="pointer">
          <span>{{ban?.bans?.length}}</span>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="sendUserNotificationModal([ban?.userId])" class="list-inline-item px-2">
                    <span [ngClass]="{'disabled-opacity': selectedItems?.length > 0}" class="pointer"
                          ngbTooltip="Send push notification"
                          placement="left"><i
                      class="bx bx-send"></i></span>
            </li>
            <li (click)="openUnbanModal([ban], true)" class="list-inline-item px-2">
                    <span [ngClass]="{'disabled-opacity': selectedItems?.length > 0 || !ban?.banned}"
                          [ngbTooltip]="extractUnbanTooltip(ban)" class="pointer warning"
                          placement="left"><i
                      class="mdi mdi-close-circle-outline"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="ban.expand" class="sub-table no-bg">
        <td align="center"></td>
        <td class="no-padding" colspan="15">
          <table class="table table-bordered align-middle table-nowrap mb-0 datatables no-bg">
            <thead class="bg-dark text-white">
            <tr>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_ID)">
                <span class="me-3">{{'BAN_ID' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_START_DATE)">
                <span class="me-3">{{'BAN_START_DATE' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_START_HOUR)">
                <span class="me-3">{{'BAN_START_HOUR' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_END_DATE)">
                <span class="me-3">{{'BAN_END_DATE' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_END_HOUR)">
                <span class="me-3">{{'BAN_END_HOUR' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_MOTIVATION)">
                <span class="me-3">{{'BAN_MOTIVATION' | translate}}</span>
              </th>
              <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)">
                <span class="me-3">{{'BY_SYSTEM' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)">
                <span class="me-3">{{'BAN_STATUS' | translate}}</span>
              </th>
              <th *ngIf="checkIfColumnIsVisible(column.BAN_NOTES)">
                <span class="me-3">{{'BAN_NOTES' | translate}}</span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody class="no-bg">
            <tr *ngFor="let banDetail of ban?.bans" class="no-bg">
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_ID)" align="center"
                  class="pointer fit-width">
                <ngb-highlight [highlightClass]="'term-highlight'" [result]="banDetail?.id"
                               [term]="service.searchTerm"
                               class="badge badge-pill badge-soft-primary"></ngb-highlight>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_START_DATE)" class="pointer">
                <span>{{banDetail?.startsAt}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_START_HOUR)" class="pointer">
                <span>{{banDetail?.startsAtHour}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_END_DATE)" class="pointer">
                <span>{{banDetail?.endsAt}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_END_HOUR)" class="pointer">
                <span>{{banDetail?.endsAtHour}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_MOTIVATION)"
                  class="pointer wrap-single-cell">
                <span>{{banDetail?.banMotivation?.reason}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BY_SYSTEM)" align="center"
                  class="pointer fit-width">
                <span *ngIf="banDetail?.bySystem">{{'YES' | translate}}</span>
                <span *ngIf="!banDetail?.bySystem">{{'NO' | translate}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_STATUS)" align="center"
                  class="pointer">
                    <span *ngIf="banDetail?.status === banStatus.EXPIRED"
                          class="badge badge-pill badge-soft-danger">{{banDetail?.status}}</span>
                <span *ngIf="banDetail?.status === banStatus.ACTIVE"
                      class="badge badge-pill badge-soft-success">{{banDetail?.status}}</span>
              </td>
              <td (click)="userDetail(ban)" *ngIf="checkIfColumnIsVisible(column.BAN_NOTES)"
                  class="pointer wrap-single-cell">
                <span>{{banDetail?.note}}</span>
              </td>
              <td class="min-large-width">
                <ul class="list-inline font-size-20 contact-links mb-0 fit-width">
                  <li (click)="editBan(ban, banDetail)" class="list-inline-item px-2">
                            <span [ngClass]="{'disabled-opacity': banDetail?.status === banStatus.EXPIRED}"
                                  [ngbTooltip]="extractEditTooltip(banDetail)" class="pointer"

                                  placement="bottom"><i
                              class="bx bx-pencil"></i></span>
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="bans?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="service" [total$]="total$"></app-pagination-footer>
<app-ban-remove-modal #banRemoveModal></app-ban-remove-modal>
<app-ban-modal #banEditModal [banType]="banType.COMPETITION"></app-ban-modal>
