<app-table-topbar (emitSearchTerm)="invitesService.searchTerm = $event" [service]="invitesService"
                  [type]="topBarType"></app-table-topbar>
<div *ngIf="!(loading$ | async) && invites?.length > 0" class="wrapper">
  <table [hidden]="invites?.length === 0"
         class="table table-bordered align-middle table-nowrap table-hover table-striped-bg mb-0 datatables"
         id="invites-datatable">
    <thead>
    <tr>
      <th></th>
      <th></th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="id">
        <span class="me-3">{{'INVITE_ID' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="createdAt">
        <span class="me-3">{{'INVITE_CODE_TYPE' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="code">
        <span class="me-3">{{'INVITE_CODE' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="amount">
        <span class="me-3">{{'INVITE_ACTIVE' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="active">
        <span class="me-3">{{'INVITE_DEEPLINK' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="usesCount">
        <span class="me-3">{{'CREATED_AT' | translate}}</span>
      </th>
      <th (sort)="sort($event)" class="column-sortable"
          sortable="singleUseForUser">
        <span class="me-3">{{'USES_COUNT' | translate}}</span>
      </th>
    </tr>
    </thead>
    <tbody *ngFor="let invite of invites; let i=index;">
    <tr>
      <td align="center" class="pointer fit-width">
        <span (click)="expandInvite(invite)"
              [ngClass]="{'disabled-opacity': !invite?.usesCount || invite?.usesCount === 0}" [ngbTooltip]="extractInviteTooltip(invite)" class="btn btn-secondary font-size-11 show-hide-users"
              placement="top">
          <i *ngIf="invite.expand" class="mdi mdi-close-circle-outline me-2"></i>
          <i *ngIf="!invite.expand" class="bx bx-plus-circle me-2"></i>
          <span *ngIf="!invite.expand">{{'SHOW_USERS' | translate}}</span>
          <span *ngIf="invite.expand">{{'HIDE_USERS' | translate}}</span>
        </span>
      </td>
      <td align="center" class="pointer fit-width">
        <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.id" class="badge badge-pill badge-soft-primary">{{invite?.id}}</span>
        <app-missing-label *ngIf="!invite?.id"></app-missing-label>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.codeType">{{invite?.codeType}}</span>
        <app-missing-label *ngIf="!invite?.codeType"></app-missing-label>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.code">{{invite?.code}}</span>
        <app-missing-label *ngIf="!invite?.code"></app-missing-label>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
        <span *ngIf="!invite?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
      </td>
      <td (cbOnSuccess)="copied($event)" (mouseenter)="invite.copy = true;" (mouseleave)="invite.copy = false;"
          [cbContent]="invite?.deepLink"
          align="center" class="pointer fit-width" ngxClipboard>
              <span *ngIf="invite?.deepLink" class="link" ngbTooltip="{{invite?.deepLink}}"
                    placement="bottom">{{invite?.deepLink | truncate:[50]}}</span>
        <i [ngClass]="invite.copy ? 'opacity-1' : 'opacity-0'" class="mdi mdi-content-copy ms-1"
           ngbTooltip="Click to copy" placement="bottom"></i>
        <app-missing-label *ngIf="!invite?.deepLink"></app-missing-label>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.createdAt">{{invite?.createdAt | date:'dd/MM/yyyy'}}</span>
        <app-missing-label *ngIf="!invite?.createdAt"></app-missing-label>
      </td>
      <td align="center" class="pointer fit-width">
        <span *ngIf="invite?.usesCount >= 0">{{invite?.usesCount}}</span>
        <app-missing-label *ngIf="!invite?.usesCount && invite?.usesCount !== 0"></app-missing-label>
      </td>
    </tr>
    <tr *ngIf="invite.expand && invite?.users?.length > 0" class="sub-table no-bg">
      <td class="no-padding" colspan="18">
        <table class="table table-bordered align-middle table-nowrap mb-0 datatables no-bg">
          <thead class="bg-dark text-white">
          <tr>
            <th (sort)="sort($event)" class="column-sortable"
                sortable="id">
              <span class="me-3">{{'INVITE_USE_ID' | translate}}</span>
            </th>
            <th (sort)="sort($event)"
                class="column-sortable"
                sortable="createdAt">
              <span class="me-3">{{'INVITE_USE_CODE_TYPE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" class="column-sortable"
                sortable="code">
              <span class="me-3">{{'INVITE_USE_CODE_ID' | translate}}</span>
            </th>
            <th (sort)="sort($event)">
              <span class="me-3">{{'USER_EMAIL' | translate}}</span>
            </th>
            <th (sort)="sort($event)">
              <span class="me-3">{{'USER_FULLNAME' | translate}}</span>
            </th>
            <th (sort)="sort($event)" class="column-sortable"
                sortable="usesCount">
              <span class="me-3">{{'USED_AT' | translate}}</span>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody class="no-bg">
          <tr *ngFor="let user of invite?.users" class="no-bg pointer">
            <td align="center" class="pointer fit-width">
              <span *ngIf="user?.id" class="badge badge-pill badge-soft-primary">{{user?.id}}</span>
              <app-missing-label *ngIf="!user?.id"></app-missing-label>
            </td>
            <td align="center" class="pointer fit-width">
              <span *ngIf="user?.codeType">{{user?.codeType}}</span>
              <app-missing-label *ngIf="!user?.codeType"></app-missing-label>
            </td>
            <td align="center" class="pointer fit-width">
              <span *ngIf="user?.codeId">{{user?.codeId}}</span>
              <app-missing-label *ngIf="!user?.codeId"></app-missing-label>
            </td>
            <td align="center" class="pointer wrap-single-cell">
              <span *ngIf="user?.usedBy?.email">{{user?.usedBy?.email}}</span>
              <app-missing-label *ngIf="!user?.usedBy?.email"></app-missing-label>
            </td>
            <td align="center" class="pointer wrap-single-cell">
              <span *ngIf="user?.usedBy?.fullName">{{user?.usedBy?.fullName}}</span>
              <app-missing-label *ngIf="!user?.usedBy?.fullName"></app-missing-label>
            </td>
            <td align="center" class="pointer fit-width">
              <span *ngIf="user?.usedAt">{{user?.usedAt | date:'dd/MM/yyyy'}}</span>
              <app-missing-label *ngIf="!user?.usedAt"></app-missing-label>
            </td>
            <td class="fit-width">
              <ul class="list-inline font-size-20 contact-links mb-0">
                <li (click)="usedByDetail(user?.usedBy)" class="list-inline-item px-2">
                  <span [ngClass]="{'disabled-opacity': !user?.usedBy}" class="pointer"
                        ngbTooltip="View user profile"
                        placement="left"><i
                    class="bx bxs-user-detail"></i></span>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!(loading$ | async) && invites?.length === 0" class="col-lg-12">
  <div class="">
    <div class="text-center mt-5">
      <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper-uses">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="invitesService" [total$]="total$"></app-pagination-footer>
