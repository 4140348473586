<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>{{ 'REMOVE_BAN' | translate}}</span>
    </h5>
    <button [disabled]="(removingBan$ | async)" class="btn close-modal-button" closeModal="CLOSE_REMOVE_BAN_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{ 'REMOVE_BAN_DESCRIPTION' | translate}}</p>
    <div class="row mt-3 modal-content-max">
      <div class="col-12">
        <app-users-table [canBeRemoved]="selectedUsers?.length > 1" [light]="true"
                         [users]="selectedUsers"></app-users-table>
        <div *ngIf="showWhiteList" class="mt-3 whitelist-container">
          <input [(ngModel)]="whiteList" class="form-check-input" id="customCheckcolor1" type="checkbox">
          <label class="form-check-label ms-2 font-weight-bold" for="customCheckcolor1">
            Add in ban whitelist
          </label>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-warning">Remove ban</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="removeBan()" *ngIf="showAreYouSure" [disabled]="(removingBan$ | async)"
                class="btn btn-warning me-2"
                type="submit">
          <i *ngIf="removingBan$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="removingBan$ | async">
            Remving ban
          </span>
          <span [hidden]="(removingBan$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(removingBan$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>
