import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {SortDirection} from "@type/common/sort-direction.type";
import {SortEventInterface} from "@interface/common/sort-event.interface";

const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: '', '': 'asc'};

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class TableSortingDirective {
  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEventInterface>();

  constructor() {
  }

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}
