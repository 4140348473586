<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 *ngIf="!edit && !duplicate" class="modal-title" id="modal-title">{{'SEND_PUSH_NOTIFICATION' | translate}}</h5>
    <h5 *ngIf="edit && !duplicate" class="modal-title" id="modal-title">{{'EDIT_PUSH_NOTIFICATION' | translate}}</h5>
    <h5 *ngIf="edit && duplicate" class="modal-title" id="modal-title">{{'SEND_PUSH_NOTIFICATION' | translate}}</h5>
    <button [disabled]="(sendingPush$ | async)" class="btn close-modal-button" closeModal="CLOSE_NOTIFICATION_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <aw-wizard #wizard [navBarLayout]="'small'">
      <aw-wizard-step stepTitle="Audience">
        <app-users-recap (sendMassive)="openMassiveNotification()"
                         *ngIf="notificationAudience === notificationAudienceEnum.SPECIFIC"
                         [description]="'USER_RECAP_NOTIFICATION_DESCRIPTION'" [massiveAction]="true"
                         [users]="users"></app-users-recap>
        <app-notification-audience (selectedNotificationAudience)="setNotificationAudience($event)" *ngIf="notificationAudience === notificationAudienceEnum.OTHER"
                                   [massiveAction]="false"
                                   [notificationUsers]="notificationUsers"
                                   class="mt-3"></app-notification-audience>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Content">
        <app-notification-options (selectedNotificationInformation)="setNotificationData($event)" [massiveNotification]="false"
                                  [notificationData]="notificationData"></app-notification-options>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Delivery">
        <app-notification-delivery (selectedNotificationDelivery)="setNotificationDelivery($event)" [notificationDelivery]="notificationDelivery"
                                   [massiveNotification]="false"></app-notification-delivery>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Review">
        <app-notification-summary (confirmSend)="sendPush($event)" [edit]="!duplicate && edit && editData ? editData : null" [massiveNotification]="false"
                                  [notificationData]="notificationData" [notificationDelivery]="notificationDelivery"
                                  [notificationUsers]="notificationUsers"
                                  [sendingPush$]="sendingPush$"></app-notification-summary>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</ng-template>
