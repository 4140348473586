import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class RewardsApiService {

  constructor(private http: HttpService) {
  }

  public getFtoList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.rewards.fitnessToCredits.list, params);
  }

  public getRewardsList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.rewards.list, params);
  }

  public getRewardDetail(id: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.rewards.detail, placeholders);
    return this.http.get<any>(url);
  }

  public getMetricDetail(metric: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: metric}];
    const url = this.http.findAndReplacePlaceholders(endpoints.rewards.fitnessToCredits.detail, placeholders);
    return this.http.get<any>(url);
  }

  public deleteRewards(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.rewards.edit, placeholders);
    return this.http.delete(url, {});
  }

  public deleteFtoSlot(ftos: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: ftos[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.rewards.fitnessToCredits.delete, placeholders);
    return this.http.delete(url, ftos);
  }

  public createFtoSlot(params: any): Observable<any> {
    if (params?.update) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.rewards.fitnessToCredits.update, placeholders);
      return this.http.post(url, params);
    } else {
      return this.http.post(endpoints.rewards.fitnessToCredits.new, params);
    }
  }

  public createRewards(params: any): Observable<any> {
    if (params?.update) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.rewards.edit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.rewards.new, params);
    }
  }

}
