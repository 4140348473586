import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CompetitionTeamLeaderboardService} from "@service/competitions/competition-team-leaderboard.service";
import {Observable} from "rxjs";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {NotificationsService} from "@service/notifications/notifications.service";

@Component({
  selector: 'app-team-leaderboard-page',
  templateUrl: './team-leaderboard-page.component.html',
  styleUrls: ['./team-leaderboard-page.component.scss']
})
export class TeamLeaderboardPageComponent implements OnInit, OnDestroy {
  @ViewChild('sourceDetail') private sourceDetailModal: any
  @Input() filters?: any;
  public teamLeaderboardList$: Observable<any[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public notificationModalType = NotificationTypeEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPETITION_TEAM_LEADERBOARD;

  constructor(public competitionTeamLeaderboardService: CompetitionTeamLeaderboardService,
              private cdr: ChangeDetectorRef, private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.teamLeaderboardList$ = this.competitionTeamLeaderboardService.competitionTeamLeaderboard$;
    this.total$ = this.competitionTeamLeaderboardService.totalRecords$;
    this.loading$ = this.competitionTeamLeaderboardService.loading$;
    this.sort({column: "position", direction: "asc"});
    this.setVisibleColumns();
    this.competitionTeamLeaderboardService.contestId = this.filters?.contextDetailsId;
    this.competitionTeamLeaderboardService.initSearchCompetitionsTeamLeaderboardListener();
    this.competitionTeamLeaderboardService._search$.next();
    this.cdr.detectChanges();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.competitionTeamLeaderboardService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.competitionTeamLeaderboardService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.competitionTeamLeaderboardService.page - 1) * this.competitionTeamLeaderboardService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.competitionTeamLeaderboardService.sortColumn = column;
    this.competitionTeamLeaderboardService.sortDirection = direction;
  }

  async showUsers(team: any) {
    if (team?.membersCount > 0) {
      this.competitionTeamLeaderboardService.extractTeamMembers(team?.teamId).subscribe(async (users: any) => {
        if (users?.data?.length > 0) {
          const flatUsers = users?.data?.map((user: any) => {
            user.user.teamName = user?.teamName;
            user.user.powerLevel = user?.powerLevel;
            user.user.position = user?.position;
            user.user.banned = user?.membershipStatus === 'BANNED';
            return user?.user
          });
          return await this.sourceDetailModal.open(flatUsers, false, true)
        }
      })
    }
  }

  sendUserNotificationModal(team: any) {
    if (team?.membersCount > 0) {
      this.competitionTeamLeaderboardService.extractTeamMembers(team?.teamId).subscribe(async (users: any) => {
        if (users?.data?.length > 0) {
          const flatUsers = users?.data?.map((user: any) => {return user?.user?.id});
          this.notificationService.sendNotification(flatUsers);
        }
      })
    }
  }

  public extractUsersTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Show all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }

  public extractNotificationTooltip(team: any): string {
    if (team?.membersCount > 0) {
      return 'Send push notification to all members of the team';
    } else {
      return 'There are no members on this team';
    }
  }


  ngOnDestroy() {
    this.competitionTeamLeaderboardService.removeSearchCompetitionsTeamLeaderboardSubscribe();
  }

}
