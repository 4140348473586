<app-table-topbar [type]="topBarType" [service]="competitionTeamLeaderboardService" [showColumnPicker]="true"
                  (updateColumnsEvent)="selected = $event"
                  (emitSearchTerm)="competitionTeamLeaderboardService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="teamLeaderboardList$ | async as teams" class="wrapper">
    <table *ngIf="teams?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_POSITION)" class="column-sortable"
            sortable="position">
          <span class="me-3">{{'TEAM_LEADERBOARD_POSITION' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_TEAM_NAME)" class="column-sortable"
            sortable="teamName">
          <span class="me-3">{{'TEAM_LEADERBOARD_TEAM_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_TEAM_ID)" class="column-sortable"
            sortable="teamId">
          <span class="me-3">{{'TEAM_LEADERBOARD_TEAM_ID' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_MEMBERS_COUNT)" class="column-sortable"
            sortable="membersCount">
          <span class="me-3">{{'TEAM_LEADERBOARD_MEMBERS_COUNT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_COUNTER_PROVISIONAL_START)" class="column-sortable"
            sortable="counterProvisionalStart">
          <span class="me-3">{{'TEAM_LEADERBOARD_COUNTER_PROVISIONAL_START' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_COUNTER_LAST_UPDATE)" class="column-sortable"
            sortable="counterLastUpdate">
          <span class="me-3">{{'TEAM_LEADERBOARD_COUNTER_LAST_UPDATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_CREATED_AT)" class="column-sortable"
            sortable="participantCreatedAt">
          <span class="me-3">{{'TEAM_LEADERBOARD_PARTICIPANT_CREATED_AT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_LAST_UPDATE)" class="column-sortable"
            sortable="participantLastUpdate">
          <span class="me-3">{{'TEAM_LEADERBOARD_PARTICIPANT_LAST_UPDATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_SCORE)" class="column-sortable"
            sortable="score">
          <span class="me-3">{{'TEAM_LEADERBOARD_PARTICIPANT_SCORE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_PROVISIONAL)" class="column-sortable" sortable="provisional">
          <span class="me-3">{{'TEAM_LEADERBOARD_PARTICIPANT_PROVISIONAL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_CONSOLIDATED)" class="column-sortable" sortable="consolidated">
          <span class="me-3">{{'TEAM_LEADERBOARD_PARTICIPANT_CONSOLIDATED' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of teams; let i=index;">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_POSITION)" align="center" class="pointer fit-width">
          <span *ngIf="team?.position" class="badge badge-pill badge-soft-primary">{{team?.position}}</span>
          <app-missing-label *ngIf="!team?.position"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_TEAM_NAME)" align="center" class="pointer fit-width">
          <span *ngIf="team?.teamName">{{team?.teamName}}</span>
          <app-missing-label *ngIf="!team?.teamName"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_TEAM_ID)" align="center" class="pointer fit-width">
          <span *ngIf="team?.teamId">{{team?.teamId}}</span>
          <app-missing-label *ngIf="!team?.teamId"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_MEMBERS_COUNT)" align="center" class="pointer fit-width">
          <span *ngIf="team?.membersCount">{{team?.membersCount}}</span>
          <app-missing-label *ngIf="!team?.membersCount"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_COUNTER_PROVISIONAL_START)" align="center" class="pointer fit-width">
          <span *ngIf="team?.counterProvisionalStart">{{team?.counterProvisionalStart | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!team?.counterProvisionalStart"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_COUNTER_LAST_UPDATE)" align="center" class="pointer fit-width">
          <span *ngIf="team?.counterLastUpdate">{{team?.counterLastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!team?.counterLastUpdate"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_CREATED_AT)" align="center" class="pointer fit-width">
          <span *ngIf="team?.participantCreatedAt">{{team?.participantCreatedAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!team?.participantCreatedAt"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_LAST_UPDATE)" align="center" class="pointer fit-width">
          <span *ngIf="team?.participantLastUpdate">{{team?.participantLastUpdate | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!team?.participantLastUpdate"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_SCORE)" align="center" class="pointer fit-width">
          <span>{{team?.score}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_PROVISIONAL)" align="center" class="pointer fit-width">
          <span>{{team?.provisional}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_LEADERBOARD_PARTICIPANT_CONSOLIDATED)" align="center" class="pointer fit-width">
          <span>{{team?.consolidated}}</span>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="showUsers(team)" class="list-inline-item px-2"  [ngClass]="{'disabled-opacity': team?.membersCount === 0}">
                  <span class="pointer"  [ngbTooltip]="extractUsersTooltip(team)" placement="left">
                    <i class="bx bxs-user-detail"></i>
                  </span>
            </li>
            <li (click)="sendUserNotificationModal(team)" class="list-inline-item px-2" [ngClass]="{'disabled-opacity': team?.membersCount === 0}">
                  <span class="pointer"
                        [ngbTooltip]="extractNotificationTooltip(team)"
                        ngbTooltip="Send push notification to all members of the team"
                        placement="left"><i
                    class="bx bx-send"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="teams?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="competitionTeamLeaderboardService" [total$]="total$"></app-pagination-footer>
<app-user-uses-modal [showExtractResults]="false" [large]="true" [leaderboard]="true" [title]="'USERS_IN_THIS_TEAM'" #sourceDetail></app-user-uses-modal>
