import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() name: string = '';
  @Input() invalid?: boolean;
  @Input() selectedDate?: SimpleChange | Date;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();
  public model: NgbDateStruct;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes?.selectedDate?.currentValue && !changes?.selectedDate?.previousValue) {
      this.selectedDate = changes?.selectedDate?.currentValue;
      this.checkSetDate();
    }
  }

  public reset() {
    this.selectedDate = null;
    this.model = null;
    this.dateSelected.emit(null);
  }

  checkChanges(date: NgbDateStruct) {
    const finalDate = new Date(date.year, date.month - 1, date.day);
    this.dateSelected.emit(finalDate);
  }

  private checkSetDate() {
    if (this.selectedDate && this.selectedDate instanceof Date) {
      this.model = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth() + 1,
        day: this.selectedDate.getDate()
      };
      this.cdr.detectChanges();
    }
  }

}
