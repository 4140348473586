import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClipboardService} from "ngx-clipboard";
import {ToastService} from "@service/toast.service";
import {RawDataApiService} from "@service/utility/raw-data/raw-data-api.service";
import {b} from "@fullcalendar/core/internal-common";
import {Subject, takeUntil} from "rxjs";
import {tap} from "rxjs/operators";
import {EventService} from "@service/common/event.service";

@Component({
  selector: 'app-raw-data-modal',
  templateUrl: './raw-data-modal.component.html',
  styleUrls: ['./raw-data-modal.component.scss']
})
export class RawDataModalComponent implements OnInit, OnDestroy {
  @Input() rawData: any | undefined;
  public expanded: boolean = false;
  public parsedJson: any | undefined;
  public working: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private clipboardService: ClipboardService, private _rawDataApiService: RawDataApiService,
              private eventService: EventService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.parsedJson = this.parseUnitTimeStamps(JSON.parse(this.rawData?.originalRequest));
    this.rawData.isGarmin = this.rawData.source?.toLowerCase()?.includes('garmin');
  }

  public parseUnitTimeStamps(jsonObject) {
    if (Array.isArray(jsonObject)) {
      for (const el of jsonObject) {
        this.parseUnitTimeStamps(el)
      }
      return
    } else if (typeof jsonObject === 'object' && jsonObject.constructor === Object) {
      for (const key of Object.keys(jsonObject)) {
        let value = jsonObject[key];
        let toDisplay;
        if (value && typeof value === 'object' && value.constructor === Object) {
          toDisplay = this.parseUnitTimeStamps(value);
        } else if (Array.isArray(value)) {
          toDisplay = JSON.stringify(value);
          this.parseUnitTimeStamps(value);
        } else {
          toDisplay = value;
        }
        const isDate = (new Date(toDisplay)).getTime() > 0;
        if (isDate && Number.isInteger(toDisplay) && toDisplay?.toString()?.length === 13) {
          jsonObject[key] = new Date(toDisplay).toISOString();
        }
      }
    }

    return jsonObject;
  }

  public processErrorData(justInError: boolean): void {
    this.working = true;
    this.toastService.show('Request accepted, you can verify the operation by keeping an eye on the Raw Data page. If it hasn\'t completed yet you can still close the modal and continue working.', {classname: 'bg-success text-light'})
    this._rawDataApiService.process(this.rawData?.user?.id, justInError).subscribe((result) => {
      if (!result) {
        this.toastService.show('Error', {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show('Everything processed correctly', {classname: 'bg-success text-light'});
      }
      this.working = false;
    });
    this.eventService.broadcast('CLOSE_RAW_DATA_MODAL', null)
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public copyJson(): void {
    this.clipboardService.copyFromContent(JSON.stringify(this.rawData?.originalRequest));
    this.toastService.show('Copied', {classname: 'bg-success text-light'});
  }

}
