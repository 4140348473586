import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SustainabilityBenefitsService} from "@service/sustainability/benefits/sustainability-benefits.service";
import {BENEFITS} from "../../../configs/documentation/clickup";

@Component({
  selector: 'app-add-sustainability-benefit',
  templateUrl: './add-sustainability-benefit.component.html',
  styleUrls: ['./add-sustainability-benefit.component.scss']
})
export class AddSustainabilityBenefitComponent implements OnInit {
  public help = BENEFITS;
  @Input() benefit: any;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public benefitForm: UntypedFormGroup;
  public types = ['BASIC', 'COUNTER'];
  public imageLimit: any = {
    fixedSize: true,
    ratio: '16:9',
    width: 1920,
    height: 1080
  };
  public iconLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 1000,
    height: 1000
  };


  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder, private benefitsService: SustainabilityBenefitsService) { }

  ngOnInit(): void {
    this.adding$ = this.benefitsService.adding$;
    this.initForm();
  }

  public validSubmit() {
    if (this.benefitForm.valid) {
      const data = this.benefitForm.getRawValue();
      data.id = !data?.id ? undefined : data.id;
      data.type = undefined;
      this.benefitsService.addBenefit(data);
    }
  }

  public checkNextActionBtn(): void {
    if (this.benefitForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.benefitForm.controls;
  }

  get valid() {
    return this.benefitForm.valid
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.benefitForm.patchValue({
        image: image?.originalUrl,
        imageName: image?.title,
      });
    }
  }

  public setIcon(icon: any): void {
    console.log(icon)
    if (!icon) {
      this.removeIcon();
    } else {
      this.benefitForm.patchValue({
        icon: icon?.originalUrl,
        iconName: icon?.title,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.image?.value) {
      return {name: [this.form?.title?.value, 'image'].join('_'), originalUrl: this.form?.image?.value}
    } else {
      return null;
    }
  }

  public checkSelectedIcon(): object | undefined {
    if (this.form?.icon?.value) {
      return {name: [this.form?.title?.value, 'icon'].join('_'), originalUrl: this.form?.icon?.value}
    } else {
      return null;
    }
  }

  public removeIcon(): void {
    this.benefitForm.patchValue({
      icon: null,
      iconName: null,
    });
  }

  public removeImage(): void {
    this.benefitForm.patchValue({
      image: null,
      imageName: null,
    });
  }

  public checkIconErrors(): string | undefined {
    if (this.formSubmitted && this.form.icon.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkImageErrors(): string | undefined {
    if (this.formSubmitted && this.form.image.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.benefitForm = this.formBuilder.group({
      title: [!this.benefit || !this.benefit?.title ? null : this.benefit?.title, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      restrictsLocales: [this.benefit?.restrictsLocales ? this.benefit?.restrictsLocales : []],
      description: [!this.benefit || !this.benefit?.description ? null : this.benefit?.description, [Validators.minLength(3), Validators.maxLength(255)]],
      multiplier: [!this.benefit || !this.benefit?.multiplier ? null : this.benefit?.multiplier],
      icon: [!this.benefit || !this.benefit?.icon ? null : this.benefit?.icon, [Validators.required]],
      type: [!this.benefit || !this.benefit?.multiplier ? 'BASIC' : 'COUNTER'],
      iconName: [!this.benefit || !this.benefit?.icon ? null : this.benefit?.name],
      image: [!this.benefit || !this.benefit?.image ? null : this.benefit?.image],
      imageName: [!this.benefit || !this.benefit?.image ? null : this.benefit?.name],
      id: [this.benefit?.id && !this.benefit.duplicate ? this.benefit?.id : undefined]
    });
  }

}
