import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbDropdownModule, NgbModule, NgbPaginationModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

import {ComponentsModule} from '@component/components.module';
import {LayoutComponent} from './container/layout.component';
import {SidebarComponent} from './main-components/sidebar/sidebar.component';
import {TopbarComponent} from './main-components/topbar/topbar.component';
import {FooterComponent} from './main-components/footer/footer.component';
import {LanguageService} from '@service/common/language.service';
import {TranslateModule} from '@ngx-translate/core';
import {UserListFiltersComponent} from './main-components/page-topbar/user-list-filters/user-list-filters.component';
import {PageTopBarComponent} from "./main-components/page-topbar/page-topbar.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectiveModule} from "@app/directives/directive.module";
import {FiltersComponent} from './main-components/filters/filters.component';
import {PageSearchComponent} from './main-components/page-search/page-search.component';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    UserListFiltersComponent,
    PageTopBarComponent,
    FiltersComponent,
    PageSearchComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ComponentsModule,
    NgbPaginationModule,
    NgbPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DirectiveModule,
    NgbModule
  ],
  exports: [
    TopbarComponent,
    FooterComponent,
    UserListFiltersComponent,
    PageTopBarComponent,
    PageSearchComponent,
    FiltersComponent
  ],
  providers: [LanguageService]
})
export class LayoutModule {
}
