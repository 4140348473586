<app-table-topbar (emitSearchTerm)="userDiaryService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="diaryActivities$ | async as activities" class="wrapper">
    <table
      [hidden]="activities?.length === 0"
      class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables recorded-activities"
      id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" class="column-sortable" sortable="forDay">
          <span class="me-3">{{'FOR_DAY' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="createdAt">
          <span class="me-3">{{'ACTIVITY_DATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="entryType">
          <span class="me-3">{{'ACTIVITY_TYPE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="title">
          <span class="me-3">{{'TITLE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" class="column-sortable" sortable="body">
          <span class="me-3">{{'DESCRIPTION' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'CREDITS' | translate}}</span>
        </th>
        <th>
          <span class="me-3">{{'STREAK_VALID_FOR_COMPETITIONS' | translate}}</span>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let activity of activities; let i=index;" class="no-bg">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.forDay">{{activity?.forDay | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!activity?.forDay"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.createdAt">{{activity?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
          <app-missing-label *ngIf="!activity?.createdAt"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.entryType">{{activity?.entryType}}</span>
          <app-missing-label *ngIf="!activity?.entryType"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.title" ngbTooltip="{{activity?.title}}"
                placement="bottom">{{activity?.title | truncate:[25]}}</span>
          <app-missing-label *ngIf="!activity?.title"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.body" ngbTooltip="{{activity?.body}}"
                placement="bottom">{{activity?.body | truncate:[25]}}</span>
          <app-missing-label *ngIf="!activity?.body"></app-missing-label>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.creditsDelta <= 0"
                class="badge badge-pill font-size-11 badge-soft-danger">{{activity?.creditsDelta}}</span>
          <span *ngIf="activity?.creditsDelta > 0"
                class="badge badge-pill font-size-11 badge-soft-success">+ {{activity?.creditsDelta}}</span>
        </td>
        <td align="center" class="fit-width">
          <span *ngIf="activity?.validForCreditsBasedContests"
                class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!activity?.validForCreditsBasedContests"
                class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="activities?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="userDiaryService" [total$]="total$"></app-pagination-footer>
