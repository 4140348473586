import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SustainabilityProjectsService} from "@service/sustainability/projects/sustainability-projects.service";
import {DatePipe} from "@angular/common";
import {SustainabilityObjectivesApiService} from "@service/sustainability/objectives/sustainability-objectives-api.service";
import {SustainabilityPlacesApiService} from "@service/sustainability/places/sustainability-places-api.service";
import {SustainabilityBenefitsApiService} from "@service/sustainability/benefits/sustainability-benefits-api.service";
import {SustainabilityPartnersApiService} from "@service/sustainability/partners/sustainability-partners-api.service";
import {PROJECTS} from "../../../configs/documentation/clickup";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-add-sustainability-project',
  templateUrl: './add-sustainability-project.component.html',
  styleUrls: ['./add-sustainability-project.component.scss']
})
export class AddSustainabilityProjectComponent implements OnInit, OnDestroy {
  public help = PROJECTS;
  @Input() project: any;
  public activeId: number = 1;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public projectForm: UntypedFormGroup;
  public cardImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
	public objectiveImageLimit: any = {
		fixedSize: true,
		ratio: '16:9',
		width: 255,
		height: 225
	};
  public backgroundImageLimit: any = {
    fixedSize: true,
    ratio: '16:9',
    width: 1920,
    height: 1080
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public places = [];
  public partners = [];
  public benefits = [];
  public rules = ['ON_REACH', 'ON_GOING'];
  public metrics = this.utils.metrics;
  constructor(private _toastService: ToastService, public utils: UtilsService, private _formBuilder: UntypedFormBuilder,
              private _sustainabilityObjectivesApiService: SustainabilityObjectivesApiService,
              private _placesApiService: SustainabilityPlacesApiService, private _benefitsApiService: SustainabilityBenefitsApiService,
              private _partnersApiService: SustainabilityPartnersApiService, private _formService: FormsService,
              private _sustainabilityProjectsService: SustainabilityProjectsService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.adding$ = this._sustainabilityProjectsService.adding$;
    this.initForm();
    this._loadBenefits();
    this._loadPartners();
    this._loadPlaces();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public validSubmit() {
    if (this.projectForm.valid) {
      const data = this.projectForm.getRawValue();
      data.color = 'TO_BE_REMOVED';
      data.startDate = new Date(data.startDate).setHours(data.startDateHour);
      data.endDate = new Date(data.endDate).setHours(data.endDateHour);
      data.companyId = !!data?.company && !!data?.company?.id ? data.company.id : undefined;
      data.company = undefined;
      data.startDateHour = undefined;
      data.endDateHour = undefined;
      this._sustainabilityProjectsService.addProject(data);
    } else {
      this._formService.findInvalidControlsRecursive(this.projectForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.projectForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    const invalid = this._invalidFields();
    switch (type) {
      case 'PROJECT':
        const projectFields = ['title', 'active', 'message', 'shortDescription', 'longDescription', 'cardImage', 'backgroundImage'];
        return invalid.some(r=> projectFields.includes(r))
        break;
      case 'OBJECTIVES':
        const objectivesFields = ['objectives'];
        return invalid.some(r=> objectivesFields.includes(r))
        break;
      case 'CONFIGURATION':
        const configurationFields = ['company', 'privateDeepLink', 'publicDeepLink', 'startDate', 'endDate'];
        return invalid.some(r=> configurationFields.includes(r))
        break;
      default:
        return false;
    }
  }

  private _invalidFields() {
    const invalid = [];
    const controls = this.projectForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid
  }

  get form() {
    return this.projectForm.controls;
  }

  get valid() {
    return this.projectForm.valid
  }

  public setImage(image: any, field?: string): void {
    if (!image) {
      this.removeImage(field);
    } else {
      this.projectForm.patchValue({
        [field]: image?.originalUrl,
      });
    }
  }
	
	public setObjectiveImage(image: any, field?: string, group?: any): void {
		if (!image) {
			group.patchValue({
				[field]: null
			});
		} else {
			group.patchValue({
				[field]: image?.originalUrl,
			});
		}
	}

  public checkSelectedImage(field: string): object | undefined {
    if (this.form?.[field]?.value) {
      return {name: field, originalUrl: this.form?.[field]?.value}
    } else {
      return null;
    }
  }
	
	public checkObjectiveSelectedImage(group: any, field: string): object | undefined {
		if (group?.controls[field]?.value) {
			return {name: field, originalUrl: group?.controls[field]?.value}
		} else {
			return null;
		}
	}

  public removeImage(field?: string): void {
    this.projectForm.patchValue({
      [field]: null
    });
  }

  public checkImageErrors(field: string): string | undefined {
    if (this.formSubmitted && this.form?.[field]?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }
	
	public checkObjectiveImageErrors(group: any, field: string): string | undefined {
		if (this.formSubmitted &&  group?.controls[field]?.errors?.required) {
			return 'This value is required';
		} else {
			return undefined;
		}
	}

  private initForm(): void {
    this.projectForm = this._formBuilder.group({
      title: [!this.project || !this.project?.title ? null : this.project?.title, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      shortDescription: [!this.project || !this.project?.shortDescription ? null : this.project?.shortDescription, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      longDescription: [!this.project || !this.project?.longDescription ? null : this.project?.longDescription, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      restrictsLocales: [this.project?.restrictsLocales ? this.project?.restrictsLocales : null],
      active: [this.project?.active ? this.project?.active : false],
      showLeaderboard: [this.project?.showLeaderboard ? this.project?.showLeaderboard : false],
      cardImage: [!this.project || !this.project?.cardImage ? null : this.project?.cardImage, [Validators.required]],
      backgroundImage: [!this.project || !this.project?.backgroundImage ? null : this.project?.backgroundImage, [Validators.required]],
      company: [!this.project || !this.project?.company ? null : this.project?.company, [Validators.required]],
      objectives: !this.project?.objectives ? this._formBuilder.array([this.addObjectiveToForm()]) : this._formBuilder.array([]),
      startDate: [!this.project || !this.project?.startDate ? null : new Date(this.project?.startDate).toLocaleDateString('fr-CA'), [Validators.required]],
      startDateHour: [!this.project || !this.project?.startDate ? null : new Date(this.project?.startDate).getHours()],
      endDate: [!this.project || !this.project?.endDate ? null : new Date(this.project?.endDate).toLocaleDateString('fr-CA'), [Validators.required]],
      endDateHour: [!this.project || !this.project?.endDate ? null : new Date(this.project?.endDate).getHours()],
      message: [!this.project || !this.project?.message ? null : this.project?.message, [Validators.required]],
      partnerId: [!this.project || !this.project?.partner?.id ? null : this.project?.partner?.id],
      placeId: [!this.project || !this.project?.place?.id ? null : this.project?.place?.id],
      privateDeepLink: [!this.project || !this.project?.privateDeepLink ? null : this.project?.privateDeepLink, [Validators.required, Validators.pattern(this.utils.validateUrlRegex)]],
      publicDeepLink: [!this.project || !this.project?.publicDeepLink ? null : this.project?.publicDeepLink, [Validators.required, Validators.pattern(this.utils.validateUrlRegex)]],
      id: [this.project?.id && !this.project.duplicate ? this.project?.id : null],
    });
    if (this.project?.objectives?.length > 0) {
      this.project?.objectives?.sort((a, b) => a.id - b.id)?.map((objective) => {
        this.addObjective(objective);
        return objective;
      });
    }
  }

  private _loadBenefits(): void {
    this._benefitsApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.benefits = result?.data?.filter((benefit) => {return benefit.multiplier})
    });
  }

  private _loadPartners(): void {
    this._partnersApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.partners = result?.data?.map((partner) => { partner.id = Number(partner.id); return partner});
    });
  }

  private _loadPlaces(): void {
    this._placesApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.places = result?.data;
    });
  }

  public setDate(date: any, field?: string): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.projectForm.patchValue({[field]: convertedDate});
  }

  public get objectives(): UntypedFormArray {
    return this.projectForm.get("objectives") as UntypedFormArray;
  }

  public addObjective(objective?: any): any {
    this.objectives.push(this.addObjectiveToForm(objective));
  }

  public removeObjective(index): void {
    this.objectives.removeAt(index);
  }

  public addObjectiveToForm(objective?: any): any {
    return this._formBuilder.group({
      title: [!objective ? undefined : objective.title, [Validators.required]],
      description: [!objective ? undefined : objective.description, [Validators.required]],
	    image: [!objective ? undefined : objective.image, [Validators.required]],
      id: [!objective ? null : !this.project.duplicate && objective.id ? objective.id : null],
      metric: [!objective ? undefined : objective.metric, [Validators.required]],
      type: [!objective ? 'PROJECT' : objective.type, [Validators.required]],
      rule: [!objective ? 'ON_REACH' : objective.rule, [Validators.required]],
      multiplier: [!objective ? undefined : objective.multiplier],
      multiplierText: [!objective ? undefined : objective.multiplierText],
      benefits: [!objective ? undefined : objective.benefits?.map((benefit) => {return benefit.id})],
      target: [!objective ? undefined : objective.target],
    });
  }

  public checkRestrictionValidators(event?: any, group?: any): any {
    switch (group.controls.rule.value) {
      case 'ON_REACH':
        this._clearValuesAndRemoveValidators(['multiplier', 'multiplierText'], group);
        this.addRequiredValidators(['target'], group);
        break;
      case 'ON_GOING':
        this._clearValuesAndRemoveValidators(['target'], group);
        this.addRequiredValidators(['multiplier', 'multiplierText'], group);
        break;
    }
  }

  private _clearValuesAndRemoveValidators(list: string[], group: any) {
    list?.map((el: string) => {
      group.patchValue({[el]: null});
    });
    this.removeRequiredValidators(list, group);
  }

  public addRequiredValidators(list: string[], group: any) {
    list?.map((el: string) => {
      group?.controls[el].addValidators([Validators.required]);
      group?.controls[el].updateValueAndValidity();
    });
  }

  public removeRequiredValidators(list: string[], group: any) {
    list?.map((el: string) => {
      group?.controls[el].clearValidators();
      group?.controls[el].updateValueAndValidity();
    });
  }

  public updateDate(date: any, field: string): void {
    this.projectForm.patchValue({[field]: this.utils.createDateFromDateTimePicker(date)});
    switch (field) {
      case 'startDate':
        this.projectForm.patchValue({['startDateHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
      case 'endDate':
        this.projectForm.patchValue({['endDateHour']: this.utils.createHourFromDateTimePicker(date)});
        break;
    }
  }


}
