<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!prize">{{'CREATE_PRIZE' | translate}}</h5>
    <h5 *ngIf="prize">{{'EDIT_PRIZE' | translate}}: {{prize?.title}}</h5>
    <div class="d-flex">
      <button [disabled]="(creatingPrize$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_PRIZE">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="prizeForm" autocomplete="off"
          class="needs-validation">
      <div class="row mb-3">
        <div class="col-md-3">
          <ul #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column" ngbNav>
            <li (click)="activeId = 1" [ngbNavItem]="1">
              <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('BASE') || checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Base and presentational</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                                 formControlName="title" id="title" type="text">
                          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
                            <span
                              *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                            <span
                              *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                            <span
                              *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}
                              .</span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-9">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="expiresOn">{{'EXPIRATION_DATE' | translate}}</label>
                          <app-simple-datepicker (dateSelected)="setExpirationDate($event)"
                                                 [isInvalid]="formSubmitted && form?.expiresOn?.errors?.required"
                                                 [selectedDate]="form?.expiresOn?.value"></app-simple-datepicker>
                          <div *ngIf="formSubmitted && form?.expiresOn?.errors" class="invalid-feedback d-block">
                            <span
                              *ngIf="formSubmitted && form?.expiresOn?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="active">Active</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.active?.value" autocomplete="off"
                                   class="form-check-input m-0 pointer"
                                   formControlName="active"
                                   id="active" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.active?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <div [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}"
                             class="mb-3">
                          <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                          <ckeditor [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }" [editor]="Editor"
                                    data="<p>Description of the prize.</p>"
                                    formControlName="description"></ckeditor>
                          <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback d-block">
                            <span
                              *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                            <span
                              *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                            <span
                              *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}
                              .</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="row mb-3">
                          <div [ngClass]="{'col-12': !cardImage}" class="col-12">
                            <app-image-uploader (imageUploaded)="setCardImage($event)"
                                                [imageErrors]="checkCardImageError()"
                                                [limit]="cardImageLimit"
                                                [name]="'CARD_IMAGE'"
                                                [previousSelectedImage]="checkSelectedCardImage()"
                                                [showImagePreview]="true"></app-image-uploader>

                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row mb-3">
                          <div [ngClass]="{'col-12': !detailImage}" class="col-12">
                            <app-image-uploader (imageUploaded)="setDetailImage($event)"
                                                [imageErrors]="checkDetailImageError()"
                                                [limit]="detailImageLimit"
                                                [name]="'DETAIL_IMAGE'"
                                                [previousSelectedImage]="checkSelectedDetailImage()"
                                                [showImagePreview]="true"></app-image-uploader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li (click)="activeId = 2" [ngbNavItem]="2">
              <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('TYPE')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Type <span *ngIf="form?.prizeType?.value === 'code_redemption'">& Codes</span></span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="type">{{'PRIZE_TYPE' | translate}}</label>
                      <select (change)="checkTypeValidators($event)" class="form-select" formControlName="prizeType"
                              id="type">
                        <option *ngFor="let prizeType of prizeTypes"
                                [value]="prizeType">{{prizeType | translate}}</option>
                      </select>
                      <div *ngIf="formSubmitted && form?.prizeType?.errors" class="invalid-feedback">
                        <span
                            *ngIf="formSubmitted && form?.prizeType?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="form?.prizeType?.value !== 'charity'">
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="codeType">Code type</label>
                        <select (change)="checkPrizeCodes()" [(ngModel)]="selectedPrizeCodeType" [ngModelOptions]="{standalone: true}"
                                class="form-select" id="codeType">
                          <option *ngFor="let codeType of prizeCodeType"
                                  [value]="codeType">{{codeType | translate}}</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-6 text-right button-code">
                      <div class="mb-3">
                        <button (click)="showAddCodes = !showAddCodes"
                                *ngIf="!showAddCodes && selectedPrizeCodeType === 'individual'" class="btn btn-primary">Add multiple individual
                          code
                        </button>
                        <button (click)="showAddCodes = !showAddCodes"
                                *ngIf="!showAddCodes && selectedPrizeCodeType === 'shared'" [disabled]="prizeCodes?.length > 0"
                                class="btn btn-primary">Add single shared code
                        </button>
                        <button (click)="addCodes()" *ngIf="showAddCodes" class="btn btn-primary">Add</button>
                        <button (click)="removeSelectedCodes()" *ngIf="selectedItems?.length > 0"
                                class="btn btn-danger ms-2">Remove selected
                        </button>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" [hidden]="showAddCodes || prizeCodes?.length === 0" class="col-12">
                      <div class="codes-table mt-3 mb-3">
                        <table class="table table-bordered align-middle table-hover table-striped table-nowrap mt-0 mb-0">
                          <thead>
                          <tr>
                            <th>
                              <div (click)="selectAllResults()"
                                   class="form-check form-check-custom font-size-16 align-middle p-0">
                                <input [checked]="allCodesSelected" class="form-check-input m-0" id="userSelection01"
                                       type="checkbox">
                              </div>
                            </th>
                            <th>
                              <span class="me-3">{{'CODE' | translate}}</span>
                            </th>
                            <th>
                              <span class="me-3">{{'PRIZE_AVAILABLE_UNITS' | translate}}</span>
                            </th>
                            <th>
                              <span class="me-3">{{'CURRENT_USES' | translate}}</span>
                            </th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let code of prizeCodes; let id=index;">
                            <td align="center" class="fit-width">
                              <div class="form-check form-check-custom font-size-16 p-0 m-0">
                                <input (click)="code.selected = !code.selected; extractSelectedItems()"
                                       [checked]="code.selected"
                                       [disabled]="code?.currentUses > 0"
                                       [value]="code.selected"
                                       class="form-check-input p-0 m-0" id="userSelection{{id}}"
                                       type="checkbox">
                              </div>
                            </td>
                            <td (blur)="updateList(id, 'code', $event)" (keyup)="changeValue(id, 'code', $event)"
                                align="center" contenteditable="true">
                              <span>{{code?.code}}</span>
                            </td>
                            <td (blur)="updateList(id, 'totalUnits', $event)" (keypress)="checkNumberMaxUses($event)"
                                (keyup)="changeValue(id, 'totalUnits', $event)"
                                *ngIf="selectedPrizeCodeType === 'shared'" align="center"
                                class="fit-width" contenteditable="true" inputmode="decimal">
                              <span>{{code?.totalUnits}}</span>
                            </td>
                            <td *ngIf="selectedPrizeCodeType === 'individual'" align="center" class="fit-width">
                              <span>{{code?.totalUnits}}</span>
                            </td>
                            <td align="center" class="fit-width">
                              <span>{{code?.currentUses}}</span>
                            </td>
                            <td class="pointer fit-width">
                            <span (click)="removeCode(code)"
                                  [ngClass]="{'disabled-opacity': selectedItems?.length > 0}" class="pointer danger font-size-16"
                                  ngbTooltip="Delete" placement="left"><i
                                class="bx bx bx-trash"></i></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" [hidden]="!showAddCodes" class="col-12">
                      <div *ngIf="selectedPrizeCodeType === 'individual'" class="mt-3 mb-3">
                      <textarea [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}"
                                class="form-control codes-textarea"
                                id="description"
                                placeholder="Insert all the codes separated with a space"
                                rows="4"></textarea>
                      </div>
                      <div *ngIf="selectedPrizeCodeType === 'shared'" class="mt-3 mb-3">
                        <input [(ngModel)]="newPrizeCodes" [ngModelOptions]="{standalone: true}" class="form-control"
                               id="description" placeholder="Insert one single code" type="text">
                      </div>
                    </div>
                    <div [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}" class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="priority">Priority
                          <span *ngIf="maxPriority" class="badge badge-pill badge-soft-warning font-size-11">
                          Current max for applied filters({{maxPriority}})
                        </span>
                        </label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                               formControlName="priority" id="priority"
                               min="0"
                               placeholder="Priority" type="number">
                        <div *ngIf="formSubmitted && form?.priority?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}" class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="price">Price</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.price?.errors}" class="form-control"
                               formControlName="price" id="price"
                               min="0"
                               placeholder="Price" type="number">
                        <div *ngIf="formSubmitted && form?.price?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value !== 'code_redemption'" [ngClass]="{'col-md-4': form?.prizeType?.value === 'simple'}" class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="totalUnits">Total uses</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.totalUnits?.errors}" class="form-control"
                               formControlName="totalUnits" id="totalUnits"
                               min="0"
                               placeholder="Total uses" type="number">
                        <div *ngIf="formSubmitted && form?.totalUnits?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'credits'" class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="creditsAmount">Credits amount</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.creditsAmount?.errors}"
                               class="form-control"
                               formControlName="creditsAmount" id="creditsAmount"
                               min="0"
                               placeholder="Credits amount" type="number">
                        <div *ngIf="formSubmitted && form?.creditsAmount?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-md-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="websiteActionText">Website title</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.websiteActionText?.errors}"
                               class="form-control"
                               formControlName="websiteActionText" id="websiteActionText"
                               min="0" type="text">
                        <div *ngIf="formSubmitted && form?.websiteActionText?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-md-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="websiteActionUrl">Website url</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.websiteActionUrl?.errors}"
                               class="form-control"
                               formControlName="websiteActionUrl" id="websiteActionUrl" type="text">
                        <div *ngIf="formSubmitted && form?.websiteActionUrl?.errors" class="invalid-feedback">
                        <span
                            *ngIf="formSubmitted && form?.websiteActionUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span *ngIf="formSubmitted && form?.websiteActionUrl?.errors?.pattern">Please insert a valid url.</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="form?.prizeType?.value === 'code_redemption'" class="col-md-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="redemptionUrl">Redemption url</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.redemptionUrl?.errors}"
                               class="form-control"
                               formControlName="redemptionUrl" id="redemptionUrl" type="text">
                        <div *ngIf="formSubmitted && form?.redemptionUrl?.errors" class="invalid-feedback">
                        <span
                            *ngIf="formSubmitted && form?.redemptionUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span
                              *ngIf="formSubmitted && form?.redemptionUrl?.errors?.pattern">Please insert a valid url.</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="form?.prizeType?.value === 'charity'">
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="priority">Credit goal</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.creditGoal?.errors}" class="form-control"
                               formControlName="creditGoal" id="creditGoal" min="0" placeholder="Credit goal" type="number">
                        <div *ngIf="formSubmitted && form?.creditGoal?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="priority">Default donation credit amount</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.defaultDonationCreditsAmount?.errors}" class="form-control"
                               formControlName="defaultDonationCreditsAmount" id="defaultDonationCreditsAmount" min="0" placeholder="Default amount" type="number">
                        <div *ngIf="formSubmitted && form?.defaultDonationCreditsAmount?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="priority">Credits to € Multiplier</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.multiplier?.errors}" class="form-control"
                               formControlName="multiplier" id="multiplier" min="0" placeholder="Multiplier" type="number">
                        <div *ngIf="formSubmitted && form?.multiplier?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="startVisibility">Start visibility</label>
                        <app-simple-datepicker (dateSelected)="setDate($event, 'startVisibility')"
                                               [isInvalid]="formSubmitted && form?.startVisibility?.errors?.required"
                                               [selectedDate]="form?.startVisibility?.value"></app-simple-datepicker>
                        <div *ngIf="formSubmitted && form?.startVisibility?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.startVisibility?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="endVisibility">End visibility</label>
                        <app-simple-datepicker (dateSelected)="setDate($event, 'endVisibility')"
                                               [isInvalid]="formSubmitted && form?.endVisibility?.errors?.required"
                                               [selectedDate]="form?.endVisibility?.value"></app-simple-datepicker>
                        <div *ngIf="formSubmitted && form?.endVisibility?.errors" class="invalid-feedback d-block">
                          <span *ngIf="formSubmitted && form?.endVisibility?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="priority">
                          Priority
                          <span *ngIf="maxPriority" class="badge badge-pill badge-soft-warning font-size-11">Current max for applied filters({{maxPriority}})</span>
                        </label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                               formControlName="priority" id="priority" min="0" placeholder="Priority" type="number">
                        <div *ngIf="formSubmitted && form?.priority?.errors?.required" class="invalid-feedback">
                          <span>{{'VALUE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </li>
            <li (click)="activeId = 3" [ngbNavItem]="3">
              <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block line-height-1-2">Restrictions & Preconditions</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div [ngClass]="{'col-12': selectedPrizeRestriction === 'none', 'col-6': selectedPrizeRestriction !== 'none'}"
                       class="">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="restriction">Restrict prize to</label>
                      <select (change)="checkRestrictionValidators($event)" [(ngModel)]="selectedPrizeRestriction" [ngModelOptions]="{standalone: true}"
                              class="form-select" id="restriction">
                        <option *ngFor="let restriction of prizeRestriction"
                                [value]="restriction">{{restriction | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="selectedPrizeRestriction !== 'none'" class="col-6">
                    <app-select-company (selectedCompany)="selectCompany($event)" *ngIf="selectedPrizeRestriction === 'company'"
                                        [basicSelect]="true"
                                        [formSubmitted]="formSubmitted"
                                        [parentForm]="prizeForm"
                                        [selectedItems]="checkSelectedCompanies()" [single]="true"></app-select-company>
                    <app-select-competition (selectedCompetition)="selectCompetition($event)"
                                            *ngIf="selectedPrizeRestriction === 'competition'"
                                            [basicSelect]="true" [formSubmitted]="formSubmitted"
                                            [parentForm]="prizeForm"
                                            [selectedItems]="checkSelectedCompetition()"
                                            [single]="true"></app-select-competition>
                  </div>
                </div>
                <div class="row">
                  <label class="font-weight-bold">Preconditions</label>
                  <div [ngClass]="{'is-invalid-select': formSubmitted && form?.precondition?.errors?.required}"
                       class="single-ng-select">
                    <ng-select
                      (change)="selectPreconditions($event)"
                      [items]="prizePreconditions"
                      [multiple]="true"
                      bindLabel="title"
                      formControlName="precondition"></ng-select>
                    <div *ngIf="formSubmitted && form?.precondition?.errors?.required"
                         class="invalid-feedback d-block mb-0">
                      <span>{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedPrizeRestriction === 'competition' || selectedPrizeRestriction === 'company'" class="mt-3 competition-preconditions">
                  <div class="">
                    <div class="row">
                      <span class="font-weight-bold mb-3" *ngIf="selectedPrizeRestriction === 'competition'">Competition preconditions</span>
                      <span class="font-weight-bold mb-3" *ngIf="selectedPrizeRestriction === 'company'">Company preconditions</span>
                      <div class="col-7" [class.col-12]="selectedPrizeRestriction === 'company'">
                        <div class="row">
                          <div class="col-12">
                            <div class="mb-3">
                              <label class="font-weight-bold" for="prizeContextTitle">Precondition title</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form?.prizeContextTitle?.errors}"
                                     class="form-control"
                                     formControlName="prizeContextTitle" id="prizeContextTitle" type="text">
                              <div *ngIf="form?.prizeContextTitle?.errors" class="invalid-feedback d-block">
                                <span *ngIf="form?.prizeContextTitle?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-3 precondition-editor">
                              <label class="font-weight-bold" for="prizeContextDescription">Precondition
                                description</label>
                              <ckeditor [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }" [editor]="Editor"
                                        data="<p>Description of the prize.</p>"
                                        formControlName="prizeContextDescription"></ckeditor>
                              <div *ngIf="form.prizeContextDescription?.errors" class="invalid-feedback d-block">
                                <span
                                  *ngIf="form.prizeContextDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                <span
                                  *ngIf="form?.prizeContextDescription?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.prizeContextDescription?.errors?.maxlength?.requiredLength} }}</span>
                                <span
                                  *ngIf="form?.prizeContextDescription?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.prizeContextDescription?.errors?.minlength?.requiredLength} }}
                                  .</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mb-3" *ngIf="selectedPrizeRestriction === 'company'">
                            <div class=" d-inline-block">
                              <label class="font-weight-bold" for="sinceDays">{{'REGISTRATION_SINCE_DAYS' | translate}}</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.sinceDays.errors}" placeholder="Value in days" class="form-control" formControlName="sinceDays" id="sinceDays" min="1" type="number">
                              <div *ngIf="formSubmitted && form.sinceDays.errors" class="invalid-feedback">
                                <span *ngIf="formSubmitted && form.sinceDays.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="selectedPrizeRestriction === 'competition'" class="col-5">
                        <div class="row">
                          <div class="col-8">
                            <div class="mb-3">
                              <label class="font-weight-bold" for="bracket">Valid for bracket</label>
                              <select (change)="checkTypeValidators($event)" [ngClass]="{'is-invalid-select': form?.bracket?.errors?.required}"
                                      class="form-select" formControlName="bracket"
                                      id="bracket">
                                <option *ngFor="let bracket of brackets"
                                        [value]="bracket">{{bracket | translate}}</option>
                              </select>
                              <div *ngIf="form?.bracket?.errors" class="invalid-feedback d-block">
                                <span *ngIf="form?.bracket?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3">
                              <label class="font-weight-bold">Team prize</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input [checked]="form.teamPrize.value" autocomplete="off"
                                       class="form-check-input m-0 pointer" formControlName="teamPrize"
                                       id="teamPrize" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="form?.teamPrize?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!form?.teamPrize?.value"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div [ngClass]="{'d-inline-block': byLeaderboardPosition}" class="mb-3">
                              <label class="font-weight-bold">By leaderboard</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input (change)="checkBracketRequired()" [(ngModel)]="byLeaderboardPosition"
                                       [checked]="byLeaderboardPosition"
                                       [ngModelOptions]="{standalone: true}" autocomplete="off"
                                       class="form-check-input m-0 pointer"
                                       id="byLeaderboardPosition" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="byLeaderboardPosition"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!byLeaderboardPosition"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div *ngIf="byLeaderboardPosition" class="mb-3 d-inline-block">
                              <label class="font-weight-bold" for="fromPositionInclusive">From position</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.fromPositionInclusive.errors}"
                                     class="form-control"
                                     formControlName="fromPositionInclusive" id="fromPositionInclusive" min="1"
                                     type="number">
                              <div *ngIf="form.fromPositionInclusive.errors" class="invalid-feedback">
                                <span
                                  *ngIf="form.fromPositionInclusive.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div *ngIf="byLeaderboardPosition" class="mb-3 d-inline-block">
                              <label class="font-weight-bold" for="toPositionInclusive">To position</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.toPositionInclusive.errors}"
                                     class="form-control"
                                     formControlName="toPositionInclusive" id="toPositionInclusive" min="1"
                                     type="number">
                              <div *ngIf="form.toPositionInclusive.errors" class="invalid-feedback">
                                <span
                                  *ngIf="form.toPositionInclusive.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div class="mb-3">
                              <label class="font-weight-bold">By metric value</label>
                              <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                                <input [(ngModel)]="byMetricValue" [checked]="byMetricValue" [ngModelOptions]="{standalone: true}"
                                       autocomplete="off"
                                       class="form-check-input m-0 pointer"
                                       id="teamPrize" type="checkbox">
                                <label class="form-check-label font-size-11 ms-3" for="teamPrize">
                              <span *ngIf="byMetricValue"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                                  <span *ngIf="!byMetricValue"
                                        class="badge badge-pill badge-soft-danger font-size-11">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div *ngIf="byMetricValue" class="mb-3 d-inline-block">
                              <label class="font-weight-bold" for="minValue">Min value</label>
                              <input [ngClass]="{'is-invalid': formSubmitted && form.minValue.errors}"
                                     class="form-control"
                                     formControlName="minValue" id="minValue" min="1" type="number">
                              <div *ngIf="formSubmitted && form.minValue.errors" class="invalid-feedback">
                                <span
                                  *ngIf="formSubmitted && form.minValue.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="prizeForm.get('competition')?.value?.competitionType === 'COOPERATIVE'">
                          <div class="col-12">
                            <div class="mb-3">
                              <label class="font-weight-bold" for="bracket">Regola in base agli obiettivi</label>
                              <select [ngClass]="{'is-invalid-select': form?.objectivesReachedStrategy?.errors?.required}"
                                      class="form-select" formControlName="objectivesReachedStrategy"
                                      id="objectivesReachedStrategy">
                                <option *ngFor="let strategy of objectivesReachedStrategy"
                                        [value]="strategy">{{'PRIZE_COOP_PRECONDITION_'+strategy | translate}}</option>
                              </select>
                              <div *ngIf="form?.objectivesReachedStrategy?.errors" class="invalid-feedback d-block">
                                <span *ngIf="form?.objectivesReachedStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li (click)="activeId = 4" [ngbNavItem]="4">
              <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('PARTNERS_EXTRA_IMAGES')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Category & Partners & Extra images</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3 single-ng-select">
                      <label class="font-weight-bold" for="restriction">Select prize category</label>
                      <ng-select (clear)="resetCategories()" [clearable]="true"
                                 [items]="categories"
                                 [searchFn]="searchCategories"
                                 [multiple]="true"
                                 formControlName="categoryIds">
                        <ng-template let-item="item" ng-label-tmp>
                          <span>{{ item?.name}}</span>
                        </ng-template>
                        <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                          <span class="font-weight-semibold ms-2">{{item?.name}}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3 single-ng-select">
                      <label class="font-weight-bold" for="restriction">Select prize partner</label>
                      <ng-select (clear)="resetPartners()" [clearable]="true"
                                 [items]="partners"
                                 [searchFn]="searchPartner"
                                 formControlName="partner">
                        <ng-template let-item="item" ng-label-tmp>
                          <span>{{ item?.name}}</span>
                        </ng-template>
                        <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                          <span class="font-weight-semibold ms-2">{{item?.name}}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <app-image-uploader (multipleImageUploaded)="setExtraImages($event)"
                                          [limit]="detailImageLimit"
                                          [multiple]="true"
                                          [previousSelectedImage]="form?.extraImages?.value"
                                          [showImagePreview]="true"></app-image-uploader>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li (click)="activeId = 5" [ngbNavItem]="5">
              <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('PARTNERS_EXTRA_IMAGES')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Translation & Locales</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <app-select-locale [parentForm]="prizeForm"></app-select-locale>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div [ngbNavOutlet]="verticalNav"></div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !prize" class="btn btn-warning">Create
          </button>
          <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && prize" class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingPrize$ | async)" class="btn btn-warning me-2"
                  type="submit">
            <i *ngIf="creatingPrize$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingPrize$ | async) && !prize">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingPrize$ | async) && prize">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingPrize$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingPrize$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
