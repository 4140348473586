import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {NotificationsSettingsSearchService} from "@service/notifications/notifications-settings-search.service";

@Component({
  selector: 'app-add-setting-modal',
  templateUrl: './add-setting-modal.component.html',
  styleUrls: ['./add-setting-modal.component.scss']
})
export class AddSettingModalComponent implements OnInit {
  @Input() setting: any;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public settingForm: UntypedFormGroup;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 120,
    height: 120
  };

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder, private notificationsSettingsSearchService: NotificationsSettingsSearchService) { }

  ngOnInit(): void {
    this.adding$ = this.notificationsSettingsSearchService.adding$;
    this.initForm();
  }

  public validSubmit() {
    if (this.settingForm.valid) {
      this.notificationsSettingsSearchService.editSetting(this.settingForm.getRawValue());
    }
  }

  public checkNextActionBtn(): void {
    if (this.settingForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.settingForm.controls;
  }

  get valid() {
    return this.settingForm.valid
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.settingForm.patchValue({
        image: image?.originalUrl,
        imageName: image?.name,
        imageType: image?.mimeType,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.image?.value) {
      return {name: this.setting?.type, originalUrl: this.form?.image?.value}
    } else {
      return null;
    }
  }

  public removeImage(): void {
    this.settingForm.patchValue({
      image: null,
      imageName: null,
      imageType: null,
    });
  }

  public checkImageErrors(): string | undefined {
    if (this.formSubmitted && this.form.image.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.settingForm = this.formBuilder.group({
      image: [!this.setting || !this.setting?.image ? null : this.setting?.image, [Validators.required]],
      id: [this.setting?.id ? this.setting?.id : null],
      type: [this.setting?.type ? this.setting?.type : null]
    });
  }
}
