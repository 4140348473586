import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable, of, Subject, Subscription, take, takeUntil} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {CompetitionsApiService} from "@service/competitions/competitions-api.service";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventEnum} from "@enum/event/event.enum";
import {Router} from "@angular/router";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class CompetitionsService {
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<any>();
  public _removeUser$ = new Subject<any>();
  public _destroyRemove$ = new Subject<void>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.competitionsTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.competitionsTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchCompetitionsSubscription: Subscription;
  private removeUserFromCompetitionsSubscription: Subscription;
  private deleteCompetitionsSubscription: Subscription;
  private createCompetitionSubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private competitionsApiService: CompetitionsApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService, private router: Router) {
  }

  private _removed$ = new BehaviorSubject<any | undefined>(undefined);

  public get removed$(): Observable<any | undefined> {
    return this._removed$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _competitionList$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get competitionList$() {
    return this._competitionList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get byPowerLevel() {
    return this._currentTableState.byPowerLevel;
  }

  set byPowerLevel(byPowerLevel: boolean | undefined | null) {
    this._setValue({byPowerLevel});
  }

  public get competitionStatus() {
    return this._currentTableState.competitionStatus;
  }

  public set competitionStatus(competitionStatus: string) {
    this._setValue({competitionStatus});
  }

  public get competitionType() {
    return this._currentTableState.competitionType;
  }

  public set competitionType(competitionType: string) {
    this._setValue({competitionType});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get startsAt() {
    return this._currentTableState.startsAt;
  }

  set startsAt(startsAt: string[]) {
    this._setValue({startsAt});
  }

  get acceptsResultsUntil() {
    return this._currentTableState.acceptsResultsUntil;
  }

  set acceptsResultsUntil(acceptsResultsUntil: string[]) {
    this._setValue({acceptsResultsUntil});
  }
	
	get companyName() {
		return this._currentTableState.companyName;
	}
	
	set companyName(companyName: string) {
		this._setValue({companyName});
	}
	
	get companyId() {
		return this._currentTableState.companyId;
	}
	
	set companyId(companyId: string | undefined) {
		this._setValue({companyId});
	}
	
	get visibleFrom() {
    return this._currentTableState.visibleFrom;
  }

  set visibleFrom(visibleFrom: string[]) {
    this._setValue({visibleFrom});
  }

  get visibleUntil() {
    return this._currentTableState.visibleUntil;
  }

  set visibleUntil(visibleUntil: string[]) {
    this._setValue({visibleUntil});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  get endsAt() {
    return this._currentTableState.endsAt;
  }

  set endsAt(endsAt: string[]) {
    this._setValue({endsAt});
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public removeSearchCompetitionsSubscribe(): void {
    this.searchCompetitionsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public delete(data?: any): void {
    this._delete$.next(data);
  }

  public removeUser(data?: any): void {
    this._removeUser$.next(data);
  }

  public removeDeleteSubscribe(): void {
    this.deleteCompetitionsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initRemoveSubscription(): void {
    this.removeUserFromCompetitionsSubscription = this._removeUser$.pipe(
      tap(() => this._loading$.next(true)),
      switchMap((data) => this.competitionsApiService.removeUser(data).pipe(catchError(error => {
        return of(error)
      }))),
      tap(() => this._loading$.next(false)),
      takeUntil(this._destroyRemove$)
    ).subscribe((data: any) => {
      this._removed$.next('DELETED');
    });
  }

  public removeUserDeleteSubscription(): void {
    this._removed$.next(undefined);
    this.removeUserFromCompetitionsSubscription?.unsubscribe();
    this.removeUserFromCompetitionsSubscription = undefined;
    this._destroyRemove$.next();
    this._destroyRemove$.complete();
  }

  public initDeleteListener(): void {
    this.deleteCompetitionsSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((competition) => this.competitionsApiService.deleteCompetition(competition).pipe(
        map((result) => {
          this._deleting$.next(false);
          this.router.navigate(['competitions/list']);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Competition deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public getCompetitionData(id: any): Observable<any> {
    return this.competitionsApiService.getCompetitionData({id: id});
  }

  public initSearchCompetitionsListener(companyId?: any, paged?: boolean): void {
    this.searchCompetitionsSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.competitionsApiService.getCompetitionList(this._extractSearchParams(), paged).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      if (companyId) {
        result = result.filter((competition) => competition?.company?.id === companyId);
      }
      if (paged) {
        this._competitionList$.next(result?.data);
        this._totalRecords$.next(result?.size);
      } else {
        this._competitionList$.next(result);
        this._totalRecords$.next(result?.length);
      }
    });
  }

  public postpone(competition: any, modal: any): any {
    this.postponeCompetition({
      id: competition?.id,
      newStartsAt: this.utils.convertDateToItalian(competition?.startsOn)
    }, modal);
  }

  public activate(competition: any, modal: any): any {
    competition.active = true;
    this.sanitizeFormData(competition);
    this.activateDeactivateCompetition(competition, modal);
  }

  public deactivate(competition: any, modal: any): any {
    competition.active = false;
    this.sanitizeFormData(competition);
    this.activateDeactivateCompetition(competition, modal);
  }

  public createEditCompetition(data: any): void {
    this._create$.next(data);
  }

  public removeCreateCompetitionSubscription(): void {
    this.createCompetitionSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initCreateListener(): void {
    this.createCompetitionSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.competitionsApiService.createEditCompetition(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this._search$.next();
            return result;
          } else {
            if (data?.duplicatePrizes) {
              this._creating$.next(true);
              this.toastService.show('Creating prizes', {classname: 'bg-warning text-light'});
              let requests: Observable<any>[] = [];
              for (let prize of data?.duplicatePrizes) {
                prize.newCompetitionId = result?.id;
                requests.push(this.competitionsApiService.createPrize(prize).pipe(tap((res) => {
                  if (typeof res !== 'string') {
                    this.toastService.show(['Prize', res?.title, 'created successfully'].join(' '), {classname: 'bg-success text-light'});
                  } else {
                    this.toastService.show('Error while creating one prize with message ' + res, {classname: 'bg-danger text-light'});
                  }
                })));
              }
              const MAX_CONCURRENT_REQUESTS = 3;
              merge(...requests, MAX_CONCURRENT_REQUESTS).subscribe({
                next: data => {
                },
                error: err => console.log(err),
                complete: () => {
                  this._creating$.next(false);
                  return this.modalSuccess(result, EventEnum.CLOSE_CREATE_COMPETITION, 'Competition created successfully');
                }
              });
              //
              // zip(requests).subscribe((res: any) => {
              //   console.log(res)
              //   this._creating$.next(false);
              //   return this.modalSuccess(result, EventEnum.CLOSE_CREATE_COMPETITION, 'Competition created successfully');
              // })
            } else {
              this._creating$.next(false);
              const message = data?.id ? 'Competition edited successfully' : 'Competition created successfully';
              return this.modalSuccess(result, EventEnum.CLOSE_CREATE_COMPETITION, message);
            }
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          this.removeCreateCompetitionSubscription();
          this.toastService.show(err, {classname: 'bg-danger text-light'});
          return of(err)
        })
      ))
    ).subscribe((result) => {
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.byPowerLevel = undefined;
    this.competitionStatus = undefined;
    this.competitionType = undefined;
    this.active = undefined;
    this.createdAt = undefined;
    this.startsAt = undefined;
    this.endsAt = undefined;
    this.visibleFrom = undefined;
    this.visibleUntil = undefined;
    this.acceptsResultsUntil = undefined;
    this.companyName = undefined;
    this.companyId = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public getPrizes(competitionId: any): any {
    return this.competitionsApiService.getPrizeList(null, {competitionId});
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.competitionsApiService.getCompetitionList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private postponeCompetition(competition: any, modal: any) {
    this._working$.next(true);
    this.competitionsApiService.postponeCompetition(competition).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        this.toastService.show('Competition postponed successfully', {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private sanitizeFormData(competition: any) {
    competition.metric = competition?.metric?.actualMetric;
    if (competition.company) {
      competition.companyData = competition.company;
      competition.company = competition.companyData?.id;
    }
  }

  private activateDeactivateCompetition(company: any, modal: any) {
    this._working$.next(true);
    this.competitionsApiService.createEditCompetition(company).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = company?.active ? 'Competition activated successfully' : 'Competition deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._creating$.next(false);
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._creating$.next(false);
    this._search$.next();
    return err;
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        byPowerLevel: (this.byPowerLevel !== undefined && this.byPowerLevel !== null) ? [this.byPowerLevel] : undefined,
        status: (this.competitionStatus !== undefined && this.competitionStatus !== null) ? [this.competitionStatus] : undefined,
        type: (this.competitionType !== undefined && this.competitionType !== null) ? [this.competitionType] : undefined,
        companyName: (this.companyName !== undefined && this.companyName !== null) ? this.companyName : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        startsAt: this.startsAt && this.startsAt?.length > 0 ? this.startsAt : undefined,
        endsAt: this.endsAt && this.endsAt?.length > 0 ? this.endsAt : undefined,
        visibleFrom: this.visibleFrom && this.visibleFrom?.length > 0 ? this.visibleFrom : undefined,
        visibleUntil: this.visibleUntil && this.visibleUntil?.length > 0 ? this.visibleUntil : undefined,
        acceptsResultsUntil: this.acceptsResultsUntil && this.acceptsResultsUntil?.length > 0 ? this.acceptsResultsUntil : undefined,
      },
      companyId: this.companyId ? this.companyId : undefined,
      userId: this.userId ? this.userId : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }
	
	public recalculateCounters(competitionId: number, persist: boolean): Observable<any> {
		return this.competitionsApiService.recalculateCounters(competitionId, persist);
	}
	
	private persistCounters(competition: any, modal: any) {
		this._working$.next(true);
		this.competitionsApiService.recalculateCounters(competition, true).subscribe((result) => {
			if (result?.length > 0) {
				result.map((error) => {
					this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
					return error;
				});
			} else {
				this.toastService.show('Punteggi confermati', {classname: 'bg-success text-light'});
			}
			this._working$.next(false);
			this._search$.next();
			this.eventService.broadcast(modal, null)
		}, (error => {
			this.toastService.show(error, {classname: 'bg-danger text-light'});
			this._working$.next(false);
			this._search$.next();
			this.eventService.broadcast(modal, null)
		}));
	}
	
	private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
