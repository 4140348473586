import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class RawDataApiService {

  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.utility.rawData.list, params);
  }

  public process(userId?: any, justInError?: boolean): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.utility.rawData.process, placeholders);
    return this.http.post(url, {justInError});
  }

}
