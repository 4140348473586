import {Injectable} from '@angular/core';
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {ActivitiesApiService} from "@service/app-config/activities/activities-api.service";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private activitiesApiService: ActivitiesApiService, private toastService: ToastService, private eventService: EventService) {
  }

  private _addingActivity$ = new BehaviorSubject<boolean>(false);

  public get addingActivity$() {
    return this._addingActivity$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  public addActivity(data: any): any {
    this._addingActivity$.next(true);
    data.benefitIds = data.benefitIds?.map((benefit: any) => {
      return benefit?.id;
    });
    data.sportIds = data.sportIds?.map((sport: any) => {
      return sport?.id;
    });
    data.recommendedAppIds = data.recommendedAppIds?.map((app: any) => {
      return app?.id;
    });
    data.syncStepsAndroid = data.syncStepsAndroid?.map((sync: any) => {
      sync.userPlatform = 'ANDROID'
      return sync;
    });
    data.syncStepsIos = data.syncStepsIos?.map((sync: any) => {
      sync.userPlatform = 'IOS'
      return sync;
    });
    let action: Observable<any> = !data?.id ? this.activitiesApiService.add(data) : this.activitiesApiService.edit(data);
    action.pipe(catchError(error => of(error))).subscribe((result: any) => {
      this._addingActivity$.next(false);
      if (typeof result === 'string') {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show(!data?.id ? 'Activity added' : 'Activity edited', {classname: 'bg-success text-light'});
        this.eventService.broadcast(EventEnum.CLOSE_RECOMMENDED_APP_MODAL, null)
      }
    });
  }

}
