import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {FaqsApiService} from "@service/faqs/faqs-api.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  public _delete$ = new Subject<any>();
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.faqsTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.faqsTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchFaqsSubscription: Subscription;
  private createFaqSubscription: Subscription;
  private deleteFaqSubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private faqsApiService: FaqsApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _faqList$ = new BehaviorSubject<any[]>([]);

  public get faqList$() {
    return this._faqList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get updatedAt() {
    return this._currentTableState.updatedAt;
  }

  set updatedAt(updatedAt: string[]) {
    this._setValue({updatedAt});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get priority() {
    return this._currentTableState.priority;
  }

  set priority(priority: string[]) {
    this._setValue({priority});
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public initSearchFaqsListener(): void {
    this.searchFaqsSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.faqsApiService.getFaqsList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let faqs = result?.map((faq) => {
        faq.languages = this.utils?.getAvailableLang(faq?.localizableKey);
        return faq;
      });
      this._faqList$.next(faqs);
      this._totalRecords$.next(result?.length);
    });
  }

  public removeSearchFaqsSubscribe(): void {
    this.searchFaqsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public removeFaqCreateSubscribe(): void {
    this.createFaqSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public createEditFaq(data: any): void {
    this._create$.next(data);
  }

  public activate(faq: any, modal: any): any {
    faq.active = true;
    this.activateDeactivateFaq(faq, modal);
  }

  public deactivate(faq: any, modal: any): any {
    faq.active = false;
    this.activateDeactivateFaq(faq, modal);
  }

  public removeDeleteSubscribe(): void {
    this.deleteFaqSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initCreateListener(): void {
    this.createFaqSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.faqsApiService.createEditFaq(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_FAQ, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Faq edited successfully' : 'Faq created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_CREATE_FAQ, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_FAQ);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public initDeleteListener(): void {
    this.deleteFaqSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((faqs) => this.faqsApiService.deleteFaq(faqs).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Faq deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.priority = undefined;
    this.active = undefined;
    this.createdAt = undefined;
    this.updatedAt = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.faqsApiService.getFaqsList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  public getMaxPriority() {
    return this.faqsApiService.getFaqsList({page: 1, size: 1, sort: 'priority,desc'})
  }

  private activateDeactivateFaq(faq: any, modal: any) {
    this._working$.next(true);
    this.faqsApiService.createEditFaq(faq).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = faq?.active ? 'Faq activated successfully' : 'Faq deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        priority: this.priority && this.priority?.length > 0 ? this.priority : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        updatedAt: this.updatedAt && this.updatedAt?.length > 0 ? this.updatedAt : undefined,
      },
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

}
