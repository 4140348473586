<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!edit">{{'ADD_SOURCE_TO_BLACKLIST' | translate}}</h5>
    <h5 *ngIf="edit">{{'EDIT_SOURCE_TO_BLACKLIST' | translate}}</h5>
    <div class="d-flex">
      <button [disabled]="(creatingSource$ | async)" class="btn close-modal-button" closeModal="CLOSE_ADD_BLACKLIST_SOURCE_MODAL">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="blacklistSourceForm" autocomplete="off"
          class="needs-validation">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="fitnessKit">{{'FITNESS_KIT' | translate}}</label>
                <select class="form-select" formControlName="fitnessKit" id="fitnessKit">
                  <option *ngFor="let fitnessKits of fitnessKits"
                          [value]="fitnessKits">{{fitnessKits | translate}}</option>
                </select>
                <div *ngIf="formSubmitted && form.fitnessKit.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.fitnessKit.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.name.errors}" class="form-control"
                       formControlName="name" id="name" type="text">
                <div *ngIf="formSubmitted && form.name.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.name.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="sourcePath">{{'SOURCE_PATH' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.sourcePath.errors}" class="form-control"
                       formControlName="sourcePath" id="sourcePath" type="text">
                <div *ngIf="formSubmitted && form.sourcePath.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.sourcePath.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold" for="description">{{'NOTES' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.description.errors}" class="form-control"
                       formControlName="description" id="description" type="text">
                <div *ngIf="formSubmitted && form.description.errors" class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form.description.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="row">-->
          <!--            <div class="col-md-12">-->
          <!--              <div class="mb-3">-->
          <!--                <label class="font-weight-bold" for="sourcePathForWhiteList">{{'BAN_THRESHOLD_FOR_WHITELIST' | translate}}</label>-->
          <!--                <div class="input-group">-->
          <!--                  <input [ngClass]="{'is-invalid': formSubmitted && form.banThresholdForWhiteList.errors}" class="form-control"-->
          <!--                         formControlName="banThresholdForWhiteList" id="banThresholdForWhiteList" type="number" [min]="0">-->
          <!--                  <div class="input-group-append">-->
          <!--                    <label class="input-group-text input-append-btn">{{extractRuleMetric() | translate}}</label>-->
          <!--                  </div>-->
          <!--                  <div *ngIf="formSubmitted && form.banThresholdForWhiteList.errors" class="invalid-feedback">-->
          <!--                    <span *ngIf="formSubmitted && form.banThresholdForWhiteList.errors.required">{{'VALUE_REQUIRED' | translate}}</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && !edit"
                  class="btn btn-warning">Add source
          </button>
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && edit"
                  class="btn btn-warning">Edit source
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingSource$ | async)" class="btn btn-warning me-2"
                  type="submit">
            <i *ngIf="creatingSource$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingSource$ | async) && !edit">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingSource$ | async) && edit">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingSource$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingSource$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
