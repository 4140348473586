import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {CompaniesService} from "@service/companies/companies.service";

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySelectComponent implements OnInit, OnDestroy {
  @Output() selectedCompany = new EventEmitter();
  @Input() error: boolean = false;
  @Input() restrictionId: any | undefined;
  @Input() selected: any | undefined;
  @Input() multiple: boolean;
	public data: any[] | undefined;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	
	public companies$: Observable<any[]>;

  constructor(public companiesService: CompaniesService, private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.companiesService.pageSize = 25;
    this.companiesService.initSearchCompaniesListener(true);
    this.companiesService._search$.next();
	  this.companiesService.companyList$.pipe(takeUntil(this._unsubscribeAll)).subscribe((companies) => {
		  this.data = companies;
		  this._changeDetectorRef.markForCheck();
	  });
  }
	
	public extractPlaceholder(): string {
		if (this.selected && !this.multiple) {
			return this.returnItemDescription(this.data?.find((item) => item.id === this.selected?.id));
		} else {
			return 'Select company'
		}
	}

  public selectCompany(event: any): void {
    if (event) {
      this.selectedCompany.emit(event);
    } else {
      this.selectedCompany.emit(null);
    }
  }

  public returnItemDescription(item): string {
    return [`[${item?.id?.toString()?.toUpperCase()}]`, item?.name].join(' - ');
  }
	
	public search(term: string, item: any) {
		return item;
	}

  ngOnDestroy() {
	  this._unsubscribeAll.next(null);
	  this._unsubscribeAll.complete();
	  this.companiesService.removeSearchCompaniesSubscribe();
	  this.selected = null;
  }

}
