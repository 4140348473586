import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {UtilityApiService} from "@service/utility/utility-api.service";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class BlacklistSourcesService {
  public _search$ = new Subject<void>();
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<any>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.blacklistSourceColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.blacklistSourceFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private deleteBlacklistSourceSubscription: Subscription;
  private createBlacklistSourceSubscription: Subscription;
  private searchBlacklistSourcesSubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private utils: UtilsService, private utilityApiService: UtilityApiService,
              private toastService: ToastService, private eventService: EventService) {
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _blacklistSourceList$ = new BehaviorSubject<any[]>([]);

  get blacklistSourceList$() {
    return this._blacklistSourceList$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public createBlacklistSource(data: any): void {
    this._create$.next(data);
  }

  public removeCreateSubscribe(): void {
    this.createBlacklistSourceSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public removeDeleteSubscribe(): void {
    this.deleteBlacklistSourceSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public removeSearchSubscribe(): void {
    this.searchBlacklistSourcesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public initDeleteListener(): void {
    this.deleteBlacklistSourceSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((source) => this.utilityApiService.deleteBlacklistSource(source).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Source deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public initCreateListener(): void {
    this.createBlacklistSourceSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.utilityApiService.createEditBlacklistSource(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_ADD_BLACKLIST_SOURCE_MODAL, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Blacklist source edited successfully' : 'Blacklist source created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_ADD_BLACKLIST_SOURCE_MODAL, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_ADD_BLACKLIST_SOURCE_MODAL);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public initSearchListener(): void {
    this.searchBlacklistSourcesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.utilityApiService.getBlacklistSources(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe((result) => {
      if (result?.data instanceof Array) {
        this._blacklistSourceList$.next(result?.data);
      } else {
        this._blacklistSourceList$.next([]);
      }
      this._totalRecords$.next(result?.size);

    });
  }

  public sourceDetail(data: any): any {
    return this.utilityApiService.getBlacklistSourceDetail(data);
  }

  public automaticBannedUsers(data: any): any {
    return this.utilityApiService.getAutomaticBanUsers(data);
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.utilityApiService.getBlacklistSources(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
      },
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }
}
