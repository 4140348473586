<div class="modal-header border-bottom-0">
  <h5 *ngIf="setting" class="modal-title" id="modal-title-2">{{'EDIT_SETTING' | translate}}: {{setting?.type}}</h5>
  <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_NOTIFICATION_SETTING_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="settingForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="type">{{'TYPE' | translate}}</label>
          <input [(ngModel)]="setting.type" [ngModelOptions]="{standalone: true}" class="form-control" disabled id="type" type="text">
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedImage()"
                              [limit]="logoImageLimit"
                              [imageErrors]="checkImageErrors()"
                              [showImagePreview]="true"
                              (imageUploaded)="setImage($event)"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && setting" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && setting">{{'EDITING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
