import {Injectable} from '@angular/core';
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";
import {BenefitsApiService} from "@service/app-config/benefits/benefits-api.service";

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  constructor(private benefitsApiService: BenefitsApiService, private toastService: ToastService, private eventService: EventService) {
  }

  private _addingBenefit$ = new BehaviorSubject<boolean>(false);

  public get addingBenefit$() {
    return this._addingBenefit$.asObservable();
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  public addBenefit(data: any): any {
    this._addingBenefit$.next(true);
    data.category = data.type;
    let action: Observable<any> = !data?.id ? this.benefitsApiService.add(data) : this.benefitsApiService.edit(data);
    action.pipe(catchError(error => of(error))).subscribe((result: any) => {
      this._addingBenefit$.next(false);
      if (typeof result === 'string') {
        this.toastService.show(result, {classname: 'bg-danger text-light'});
      } else {
        this.toastService.show(!data?.id ? 'Benefit added' : 'Benefit edited', {classname: 'bg-success text-light'});
        this.eventService.broadcast(EventEnum.CLOSE_BENEFIT_APP_MODAL, null)
      }
    });
  }

  public getMaxPriority() {
    return this.benefitsApiService.search({page: 1, size: 1, sort: 'priority,desc', type: 'BENEFIT'})
  }

  public activate(benefit: any, modal: any): any {
    benefit.active = true;
    this._activateDeactivateBenefit(benefit, modal);
  }

  public deactivate(benefit: any, modal: any): any {
    benefit.active = false;
    this._activateDeactivateBenefit(benefit, modal);
  }

  private _activateDeactivateBenefit(benefit: any, modal: any): void {
    this._working$.next(true);
    let action: Observable<any> = benefit?.activate ? this.benefitsApiService.activate(benefit) : this.benefitsApiService.deactivate(benefit);
    action.subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = benefit?.active ? 'Activated successfully' : 'Deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this.eventService.broadcast(modal, null)
    }));
  }
}
