<div class="operation-modal-header">
  <h5 *ngIf="!content || content?.duplicate">{{'CREATE_CONTENT' | translate}}</h5>
  <h5 *ngIf="content && !content?.duplicate">{{'EDIT_CONTENT' | translate}}: {{content?.title}}</h5>
  <div class="d-flex">
    <button [disabled]="(creatingContent$ | async)" class="btn close-modal-button" closeModal="CLOSE_CONTENT_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="contentForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column" ngbNav>
          <li (click)="activeId = 1" [ngbNavItem]="1">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('BASE') || checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block">Base and presentational</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="label">{{'LABEL' | translate}}</label>
                        <input [ngClass]="{'is-invalid': formSubmitted && form?.label?.errors}" class="form-control"
                               formControlName="label" id="label" type="text">
                        <div *ngIf="formSubmitted && form?.label?.errors" class="invalid-feedback">
                          <span *ngIf="formSubmitted && form?.label?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span *ngIf="form?.label?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.label?.errors?.maxlength?.requiredLength} }}</span>
                          <span *ngIf="form?.label?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.label?.errors?.minlength?.requiredLength} }}.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="priority">Priority</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control" formControlName="priority" id="priority" min="0" placeholder="Priority" type="number">
                    <div *ngIf="formSubmitted && form?.priority?.errors?.required" class="invalid-feedback">
                      <span>{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}"
                           class="mb-3">
                        <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                        <textarea [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}"
                                  placeholder="Insert the description.. "
                                  class="form-control description-textarea" formControlName="description" id="description" rows="4"></textarea>
                        <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback d-block">
                          <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                          <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="row mb-3">
                        <div [ngClass]="{'col-12': !cardImage}" class="col-12">
                          <app-image-uploader (imageUploaded)="setCardImage($event)"
                                              [imageErrors]="checkCardImageError()"
                                              [limit]="cardImageLimit"
                                              [name]="'CARD_IMAGE'"
                                              [previousSelectedImage]="checkSelectedCardImage()"
                                              [showImagePreview]="true"></app-image-uploader>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li (click)="activeId = 2" [ngbNavItem]="2">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('TYPE')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block">Category & action</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="category">{{'CATEGORY' | translate}}</label>
                    <ng-container *ngIf="categories?.length > 0">
                      <select (change)="checkCategoryAction()"  [ngClass]="{'is-invalid-select': formSubmitted && form?.category?.errors}" class="form-select" formControlName="contentCategoryId" id="category">
                        <option *ngFor="let category of categories" [value]="category.id">{{category?.name | translate}}</option>
                      </select>
                      <div *ngIf="formSubmitted && form?.category?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.category?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-12" *ngIf="!!contentForm.get('contentCategoryAction').value">
                  <app-deeplink-action-select
                      [parentForm]="contentForm"
                      [control]="'contentCategoryAction'"
                      [childControl]="'actionDetail'"
                      [hideActionSelect]="true"
                      [submitted]="formSubmitted"
                  ></app-deeplink-action-select>
                </div>
              </div>
            </ng-template>
          </li>
          <li (click)="activeId = 3" [ngbNavItem]="3">
            <a class="mb-2" ngbNavLink>
                <span *ngIf="checkAlert('RESTRICTIONS')" class="d-inline-block danger me-2">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
              <span class="d-inline-block line-height-1-2">Restrictions</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div [ngClass]="{'col-12': noSpecificRestriction(), 'col-6': !noSpecificRestriction()}" class="">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="restriction">Restrict content to</label>
                    <select (change)="checkRestrictionValidators($event)" [(ngModel)]="selectedContentRestriction" [ngModelOptions]="{standalone: true}"
                            class="form-select" id="restriction">
                      <option *ngFor="let restriction of contentRestriction"
                              [value]="restriction">{{restriction | translate}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="selectedContentRestriction !== ContentAudience.ALL_USERS" class="col-6">
                  <ng-container *ngIf="selectedContentRestriction === ContentAudience.SPECIFIC_COMPANIES">
                    <label class="font-weight-bold">Companies</label>
                    <app-company-select (selectedCompany)="contentForm.controls['companies'].setValue($event);"
                                        [error]="formSubmitted && form?.companies?.errors?.required"
                                        [multiple]="true"
                                        [selected]="this.form?.companies?.value"></app-company-select>
                  </ng-container>
                  <ng-container *ngIf="selectedContentRestriction === ContentAudience.SPECIFIC_COMPETITIONS">
                    <label class="font-weight-bold">Competitions</label>
                    <app-competition-select
                      (selectedCompetition)="contentForm.controls['competitions'].setValue($event);"
                      [error]="formSubmitted && form?.competitions?.errors?.required"
                      [multiple]="true"
                      [selected]="this.form?.competitions?.value"></app-competition-select>
                  </ng-container>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-1" for="validSinceInclusive">{{'VALID_SINCE' | translate}}</label>
                    <app-simple-datepicker (dateSelected)="setSinceDate($event)"
                                           [isInvalid]="formSubmitted && form?.validSinceInclusive?.errors?.required"
                                           [minDateLock]="false"
                                           [name]="'Monday'"
                                           [selectedEndDate]="form?.validUntilExclusive?.value"
                                           [selectedStartDate]="form?.validSinceInclusive?.value"></app-simple-datepicker>
                    <div *ngIf="formSubmitted && form?.validSinceInclusive?.errors" class="invalid-feedback d-block">
                  <span
                      *ngIf="formSubmitted && form?.validSinceInclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-1" for="validUntilExclusive">{{'VALID_UNTIL' | translate}}</label>
                    <app-simple-datepicker (dateSelected)="setUpToDate($event)"
                                           [isInvalid]="formSubmitted && form?.validUntilExclusive?.errors?.required"
                                           [minDateLock]="false"
                                           [name]="'Sunday'"
                                           [selectedEndDate]="form?.validUntilExclusive?.value"
                                           [selectedStartDate]="form?.validSinceInclusive?.value"></app-simple-datepicker>
                    <div *ngIf="formSubmitted && form?.validUntilExclusive?.errors" class="invalid-feedback d-block">
                  <span
                      *ngIf="formSubmitted && form?.validUntilExclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <app-select-locale [parentForm]="contentForm"></app-select-locale>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item pb-16 float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && (!content || content?.duplicate)" class="btn btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && (content && !content?.duplicate)" class="btn btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(creatingContent$ | async)" class="btn btn-warning me-2" type="submit">
          <i *ngIf="creatingContent$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(creatingContent$ | async) && !content">{{'CREATING' | translate}}</span>
          <span *ngIf="(creatingContent$ | async) && content && !content?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(creatingContent$ | async) && content && content?.duplicate">{{'CREATING' | translate}}</span>
          <span [hidden]="(creatingContent$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingContent$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
