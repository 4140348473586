<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!faq">{{'CREATE_FAQ' | translate}}</h5>
    <h5 *ngIf="faq">{{'EDIT_FAQ' | translate}}</h5>
    <div class="d-flex">
      <button [disabled]="(creatingFaq$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_FAQ">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="faqForm" autocomplete="off"
          class="needs-validation">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-10">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="title">{{'TITLE' | translate}}</label>
                <p class="card-title-desc mb-2">{{'FAQ_TITLE_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form.title.errors}" class="form-control"
                       formControlName="title" id="title" type="text">
                <div *ngIf="formSubmitted && form.title.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.title.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="form?.title?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                  <span
                    *ngIf="form?.title?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}
                    .</span>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="mb-3">
                <label class="font-weight-bold mb-4" for="active">Active</label>
                <p class="card-title-desc mb-2"></p>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                         formControlName="active"
                         id="active" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.active?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="mb-3">
                <label class="font-weight-bold" for="priority">Priority
                  <span *ngIf="maxPriority" class="badge badge-pill badge-soft-warning font-size-11">
                    Current max ({{maxPriority}})
                  </span>
                </label>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                       formControlName="priority" id="priority"
                       min="0"
                       placeholder="Priority" type="number">
                <div *ngIf="formSubmitted && form?.priority?.errors?.required" class="invalid-feedback">
                  <span>{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="font-weight-bold" for="linkText">Link title</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.linkText?.errors}" class="form-control"
                       formControlName="linkText" id="linkText"
                       min="0" type="text">
                <div *ngIf="formSubmitted && form?.linkText?.errors?.required" class="invalid-feedback">
                  <span>{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="font-weight-bold" for="linkUrl">Link url</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form?.linkUrl?.errors}" class="form-control"
                       formControlName="linkUrl" id="linkUrl" type="text">
                <div *ngIf="formSubmitted && form?.linkUrl?.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form?.linkUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span *ngIf="formSubmitted && form?.linkUrl?.errors?.pattern">Please insert a valid url.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div [ngClass]="{'precondition-editor-invalid': formSubmitted && form.htmlText.errors}"
                   class="mb-3 precondition-editor">
                <label class="font-weight-bold" for="htmlText">Faq content</label>
                <ckeditor [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }" [editor]="Editor"
                          data="<p>Description of the prize.</p>"
                          formControlName="htmlText"></ckeditor>
                <div *ngIf="formSubmitted && form.htmlText?.errors" class="invalid-feedback d-block">
                  <span *ngIf="formSubmitted && form.htmlText?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="form?.htmlText?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.htmlText?.errors?.maxlength?.requiredLength} }}</span>
                  <span
                    *ngIf="form?.htmlText?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.htmlText?.errors?.minlength?.requiredLength} }}
                    .</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createFaq()" *ngIf="!faq" [disabled]="(creatingFaq$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingFaq$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFaq$ | async) && !faq">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingFaq$ | async) && !faq">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createFaq()" *ngIf="faq"
                  class="btn btn-warning">
            <i *ngIf="creatingFaq$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFaq$ | async) && faq">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingFaq$ | async) && faq">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
