<form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="banForm" autocomplete="off"
      class="needs-validation">
  <div class="row mt-3">
    <div class="col-12">
      <h4 class="card-title">Select the ban options</h4>
      <p class="card-title-desc">Remember to fill all required fields</p>
      <div class="row">
        <div class="col-md-9">
          <div class="mb-3">
            <label class="font-weight-bold" for="startsAt">Ban start date</label>
            <div class="input-group clockpicker">
              <input #startsAt="ngbDatepicker" (click)="startsAt.toggle()"
                     [ngClass]="{'is-invalid': formSubmitted && form.startsAt.errors}"
                     class="form-control"
                     formControlName="startsAt"
                     id="startsAt" ngbDatepicker placeholder="yyyy-mm-dd">
              <div (click)="startsAt.toggle()" class="input-group-append">
                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
              </div>
              <div *ngIf="formSubmitted && form.startsAt.errors" class="invalid-feedback">
                <span *ngIf="formSubmitted && form.startsAt.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-3">
          <div class="mb-3">
            <label class="font-weight-bold" for="startsAtHour">Ban start date hour</label>
            <input [ngClass]="{'is-invalid': formSubmitted && form.startsAtHour.errors}" class="form-control"
                   formControlName="startsAtHour" id="startsAtHour"
                   max="23"
                   min="0"
                   placeholder="0-24 H" type="number">
            <div *ngIf="formSubmitted && form.startsAtHour.errors" class="invalid-feedback">
              <span *ngIf="formSubmitted && form.startsAtHour.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              <span *ngIf="formSubmitted && form.startsAtHour.errors.max">This max value for hour is 24.</span>
              <span *ngIf="formSubmitted && form.startsAtHour.errors.min">This min value for hour is 1.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="mb-3">
            <label class="font-weight-bold" for="endsAt">Ban expiration date</label>
            <div class="input-group clockpicker">
              <input #endsAt="ngbDatepicker" (click)="endsAt.toggle()"
                     [ngClass]="{'is-invalid': formSubmitted && form.endsAt.errors}"
                     class="form-control"
                     formControlName="endsAt"
                     id="endsAt" ngbDatepicker placeholder="No expiration date">
              <div (click)="endsAt.toggle()" class="input-group-append">
                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
              </div>
              <div *ngIf="formSubmitted && form.endsAt.errors" class="invalid-feedback">
                <span *ngIf="form.endsAt.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="mb-3">
            <label class="font-weight-bold" for="endsAtHour">Ban expiration date hour</label>
            <input [ngClass]="{'is-invalid': formSubmitted && form.endsAtHour.errors}" class="form-control"
                   formControlName="endsAtHour" id="endsAtHour"
                   max="23"
                   min="0"
                   placeholder="0-24 H" type="number">
            <div *ngIf="formSubmitted && form.endsAtHour.errors" class="invalid-feedback">
              <span *ngIf="form.endsAtHour.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
              <span *ngIf="form.endsAtHour.errors.max">This max value for hour is 24.</span>
              <span *ngIf="form.endsAtHour.errors.min">This min value for hour is 1.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div [ngClass]="{'is-invalid': formSubmitted && !!form.motivation.errors}" class="mb-3 basic-select">
            <label class="font-weight-bold ">Motivation</label>
            <div class="d-flex">
              <ng-template #loading>
                <input class="form-control w-90" type="text">
              </ng-template>
              <ng-select *ngIf="motivations$ | async as motivationList; else loading" [items]="motivationList"
                         bindLabel="reason"
                         class="w-90" formControlName="motivation" id="motivation"></ng-select>
              <div (click)="addNewMotivation()" class="input-group-append pointer w-5"
                   ngbTooltip="Create new motivation" placement="bottom">
                <span class="input-group-text b-radius-0 br-0"><i class="mdi mdi-plus"></i></span>
              </div>
              <div (click)="reloadMotivationList()" class="input-group-append pointer w-5"
                   ngbTooltip="Reload motivation list" placement="bottom">
                <span class="input-group-text b-radius-0"><i class="mdi mdi-reload"></i></span>
              </div>
            </div>
            <div *ngIf="formSubmitted && !!form.motivation.errors" class="invalid-feedback d-block">
              <span *ngIf="form.motivation.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <h5 class="font-size-14 mb-1 font-weight-bold">Alerts</h5>
          <p class="card-title-desc mb-2">These notifications will be sent to the user when they are actually banned</p>
          <div class="form-check form-switch form-switch-md mb-3">
            <input class="form-check-input" formControlName="sendMail" id="emailNotification" type="checkbox">
            <label class="form-check-label font-weight-semibold" for="emailNotification">Send email</label>
          </div>
          <div class="form-check form-switch form-switch-md mb-3">
            <input class="form-check-input" formControlName="sendPushNotification" id="pushNotification"
                   type="checkbox">
            <label class="form-check-label font-weight-semibold" for="pushNotification">Send push notification</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ul class="list-inline wizard mb-0">
    <li class="previous list-inline-item">
      <button awPreviousStep class="btn btn-outline-light">{{'BACK' | translate}}</button>
    </li>
    <li class="next list-inline-item float-end">
      <button #nextStep awNextStep class="btn btn-primary" type="submit">{{'CONTINUE' | translate}}</button>
    </li>
  </ul>
</form>
