<div aria-labelledby="offcanvasNavbarLabel" class="offcanvas offcanvas-end" data-bs-scroll="true" id="offcanvasNavbar"
     tabindex="-1">
  <div class="offcanvas-header">
    <p class="d-block filter-title">{{type | translate}}</p>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav">
      <div *ngIf="type === topbarTypeEnum.COMPANY_MEMBERSHIPS" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPANY_CREATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPANY_CREATED_AT'))}}"
                     id="COMPANY_CREATED_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPANY_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'companyMembershipCreationDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPANY_LAST_UPDATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPANY_LAST_UPDATE'))}}"
                     id="COMPANY_LAST_UPDATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPANY_LAST_UPDATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.updatedAt = $event" [date]="service.updatedAt"
                                       [name]="'companyMembershipUpdatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.banned = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'BAN_ACTIVE'"></app-select-box-picker>
        <div class="input-group d-block mb-3">
          <app-text-picker (selected)="service.code = $event" [title]="'MEMBERSHIP_CODE'"></app-text-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.COMPANY_LIST" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPANY_CREATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPANY_CREATED_AT'))}}"
                     id="COMPANY_CREATED_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPANY_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'companyCreationDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPANY_LAST_UPDATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPANY_LAST_UPDATE'))}}"
                     id="COMPANY_LAST_UPDATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPANY_LAST_UPDATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.updatedAt = $event" [date]="service.updatedAt"
                                       [name]="'companyUpdatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.enabled = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'COMPANY_ENABLED'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.discoverable = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'COMPANY_DISCOVERABLE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'COMPANY_ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.showUsersCount = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'COMPANY_SHOW_USERS_COUNT'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.showCompanyLeaderboards = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'COMPANY_SHOW_LEADERBOARDS'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.mustInsertCode = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'COMPANY_MUST_INSERT_CODE'"></app-select-box-picker>
        <div class="input-group d-block ">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'COMPANY_PRIORITY' | translate}}</label>
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'COMPANY_PRIORITY'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.COMPETITION_LIST" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPETITION_CREATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPETITION_CREATED_AT'))}}"
                     id="COMPETITION_CREATED_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPETITION_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'competitionCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPETITION_START_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPETITION_START_DATE'))}}"
                     id="COMPETITION_START_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPETITION_START_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.startsAt = $event" [date]="service.startsAt"
                                       [name]="'competitionStartsAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('COMPETITION_END_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('COMPETITION_END_DATE'))}}"
                     id="COMPETITION_END_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'COMPETITION_END_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.endsAt = $event" [date]="service.endsAt"
                                       [name]="'competitionEndsAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('VISIBLE_FROM')}"
                     cardClass="{{extractCardClass(acc.isExpanded('VISIBLE_FROM'))}}"
                     id="VISIBLE_FROM">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'VISIBLE_FROM' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.visibleFrom = $event" [date]="service.visibleFrom"
                                       [name]="'competitionVisibleFrom'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('VISIBLE_UNTIL')}"
                     cardClass="{{extractCardClass(acc.isExpanded('VISIBLE_UNTIL'))}}"
                     id="VISIBLE_UNTIL">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'VISIBLE_UNTIL' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.visibleUntil = $event" [date]="service.visibleUntil"
                                       [name]="'competitionVisibleUntil'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('ACCEPT_RESULTS_UNTIL')}"
                     cardClass="{{extractCardClass(acc.isExpanded('ACCEPT_RESULTS_UNTIL'))}}"
                     id="ACCEPT_RESULTS_UNTIL">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'ACCEPT_RESULTS_UNTIL' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.acceptsResultsUntil = $event"
                                       [date]="service.acceptsResultsUntil"
                                       [name]="'competitionAcceptsResultsUntil'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div class="input-group d-block mt-3 mb-3">
          <app-text-picker
              (selected)="service.companyName = $event && !emptyString($event) ?  ['CONTAINS', 'IGNORE_CASE', $event] : undefined"
              [clearAll]="clearFilters.asObservable()" [id]="'COMPANY'"
              [text]="service?.companyName ? service?.companyName : undefined" [title]="'COMPANY'"
          ></app-text-picker>
        </div>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'COMPETITION_ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.byPowerLevel = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'COMPETITION_BY_POWER_LEVEL'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.type = $event" [boolean]="false"
                               [filters]="searchFilters.competitionType"
                               [title]="'COMPETITION_TYPE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.status = $event" [boolean]="false"
                               [filters]="searchFilters.competitionStatus"
                               [title]="'COMPETITION_STATUS'"></app-select-box-picker>
      </div>
      <div *ngIf="type === topbarTypeEnum.FAQS_LIST" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('FAQ_CREATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('FAQ_CREATED_AT'))}}"
                     id="FAQ_CREATED_AT">
            <ng-template class="text-black" ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'FAQ_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'faqCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('FAQ_UPDATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('FAQ_UPDATED_AT'))}}"
                     id="FAQ_UPDATED_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'FAQ_UPDATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.updatedAt = $event" [date]="service.updatedAt"
                                       [name]="'faqUpdatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'FAQ_ACTIVE'"></app-select-box-picker>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'FAQ_PRIORITY' | translate}}</label>
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'FAQ_PRIORITY'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.CHALLENGE_LIST" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">Dates</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CHALLENGE_CREATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CHALLENGE_CREATED_AT'))}}"
                     id="CHALLENGE_CREATED_AT">
            <ng-template class="text-black" ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'CHALLENGE_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'challengeCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CHALLENGE_STARTS_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CHALLENGE_STARTS_AT'))}}"
                     id="CHALLENGE_STARTS_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'CHALLENGE_STARTS_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.startsAt = $event" [date]="service.startsAt"
                                       [name]="'challengeStartsAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CHALLENGE_ENDS_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CHALLENGE_ENDS_AT'))}}"
                     id="CHALLENGE_ENDS_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'CHALLENGE_ENDS_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.endsAt = $event" [date]="service.endsAt"
                                       [name]="'challengeEndsAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CHALLENGE_ACCEPT_RESULTS_UNTIL')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CHALLENGE_ACCEPT_RESULTS_UNTIL'))}}"
                     id="CHALLENGE_ACCEPT_RESULTS_UNTIL">
            <ng-template ngbPanelTitle>
              <label
                class="font-weight-bold body-color mb-0 mt-0">{{'CHALLENGE_ACCEPT_RESULTS_UNTIL' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.acceptsResultsUntil = $event"
                                       [date]="service.acceptsResultsUntil"
                                       [name]="'challengeAcceptsResultsUntil'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

        <app-select-box-picker (selected)="service.type = $event" [boolean]="false"
                               [filters]="searchFilters.challengeType"
                               [title]="'CHALLENGE_TYPE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.isPublic = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'CHALLENGE_PUBLIC'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.metricLongDescription = $event" [boolean]="false"
                               [filters]="searchFilters.fitnessMetric"
                               [title]="'CHALLENGE_METRIC'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.challengeStatus = $event" [boolean]="false"
                               [filters]="searchFilters.challengeStatus"
                               [title]="'CHALLENGE_STATUS'"></app-select-box-picker>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'CHALLENGE_ANTE' | translate}}</label>
          <app-number-picker (numberSelected)="service.ante = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'CHALLENGE_ANTE'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'CHALLENGE_POOL' | translate}}</label>
          <app-number-picker (numberSelected)="service.currentPool = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'CHALLENGE_POOL'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'CHALLENGE_PARTICIPANTS' | translate}}</label>
          <app-number-picker (numberSelected)="service.participantsCount = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'CHALLENGE_PARTICIPANTS'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'CHALLENGE_MAX_PARTICIPANTS' | translate}}</label>
          <app-number-picker (numberSelected)="service.maxParticipants = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'CHALLENGE_MAX_PARTICIPANTS'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.PRIZE_LIST || type === topbarTypeEnum.PRIZE_LIST_PAGE_DETAIL"
           class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('PRIZE_CREATION_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('PRIZE_CREATION_DATE'))}}"
                     id="PRIZE_CREATION_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'PRIZE_CREATION_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'prizeCreationDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('PRIZE_EXPIRATION_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('PRIZE_EXPIRATION_DATE'))}}"
                     id="PRIZE_EXPIRATION_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'PRIZE_EXPIRATION_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.expiresAt = $event" [date]="service.expiresAt"
                                       [name]="'prizeExpirationDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.prizeType = $event" [boolean]="false"
                               [filters]="searchFilters.prizeType"
                               [title]="'PRIZE_TYPE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'PRIZE_ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.contextType = $event"
                               *ngIf="type !== topbarTypeEnum.PRIZE_LIST_PAGE_DETAIL" [boolean]="false"
                               [filters]="searchFilters.prizeContextType"
                               [title]="'PRIZE_CONTEXT_TYPE'"></app-select-box-picker>
        <div *ngIf="type !== topbarTypeEnum.PRIZE_LIST_PAGE_DETAIL && service.contextType === 'COMPANY'"
             class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'COMPANY' | translate}}</label>
          <app-select-company (selectedCompany)="selectContextDetailId($event)" [pageFilter]="true"
                              [single]="true"></app-select-company>
        </div>
        <div *ngIf="type !== topbarTypeEnum.PRIZE_LIST_PAGE_DETAIL && service.contextType === 'COMPETITION'"
             class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'COMPETITION' | translate}}</label>
          <app-select-competition (selectedCompetition)="selectContextDetailId($event)" [pageFilter]="true"
                                  [single]="true"></app-select-competition>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'PRIZE_PRICE' | translate}}</label>
          <app-number-picker (numberSelected)="service.price = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'PRIZE_PRICE'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'PRIZE_TOTAL_UNITS' | translate}}</label>
          <app-number-picker (numberSelected)="service.totalUnits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'PRIZE_TOTAL_UNITS'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'PRIZE_REDEEMED_UNITS' | translate}}</label>
          <app-number-picker (numberSelected)="service.redeemedUnits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'PRIZE_REDEEMED_UNITS'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'PRIZE_AVAILABLE_UNITS' | translate}}</label>
          <app-number-picker (numberSelected)="service.availableUnits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'PRIZE_AVAILABLE_UNITS'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.BAN_MOTIVATIONS" class="d-flex d-flex-column">
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'BANNED_USERS' | translate}}</label>
          <app-number-picker (numberSelected)="service.numberOfUsers = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'BANNED_USERS'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.BANNED_LIST" class="d-flex d-flex-column ">
        <label class="font-weight-bold mb-2 d-block mt-0">Dates</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('BAN_START_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('BAN_START_DATE'))}}"
                     id="BAN_START_DATE">
            <ng-template class="text-black" ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'BAN_START_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.banStartDate = $event" [date]="service.banStartDate"
                                       [name]="'banStartDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('BAN_END_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('BAN_END_DATE'))}}"
                     id="BAN_END_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'BAN_END_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.banEndDate = $event" [date]="service.banEndDate"
                                       [name]="'banEndDate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.banned = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'BAN_ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.bannedBySystem = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'BANNED_BY_SYSTEM'"></app-select-box-picker>
      </div>
      <div *ngIf="type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST"
           class="d-flex d-flex-column ">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('DATERANGE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('DATERANGE'))}}"
                     id="DATERANGE">
            <ng-template class="text-black" ngbPanelTitle>
              <label
                class="font-weight-bold body-color mb-0 mt-0">{{'UTILS.REGISTRATION.DATE.RANGE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.registrationDate = $event"
                                       [date]="service.registrationDate"
                                       [name]="'userRegistration'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('LAST_APP_OPEN')}"
                     cardClass="{{extractCardClass(acc.isExpanded('LAST_APP_OPEN'))}}"
                     id="LAST_APP_OPEN">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'LAST_APP_OPEN' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.lastAppAccessTs = $event"
                                       [date]="service.lastAppAccessTs"
                                       [name]="'userLastAppOpen'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('LAST_SYNC')}"
                     cardClass="{{extractCardClass(acc.isExpanded('LAST_SYNC'))}}"
                     id="LAST_SYNC">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'LAST_SYNC' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.lastSync = $event" [date]="service.lastSync"
                                       [name]="'userLastAppSync'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.profileComplete = $event" [boolean]="true"
                               [filters]="searchFilters.boolean"
                               [title]="'PROFILE_COMPLETED'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.banned = $event" [boolean]="true" [filters]="searchFilters.boolean"
                               [title]="'USER_BANNED'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.platform = $event" [boolean]="false"
                               [filters]="searchFilters.platform"
                               [title]="'PLATFORM'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.lastKit = $event" [boolean]="false"
                               [filters]="searchFilters.fitnessKit"
                               [title]="'FITNESS_KIT'"></app-select-box-picker>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'AVAILABLE_CREDITS' | translate}}</label>
          <app-number-picker (numberSelected)="service.availableCredits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'AVAILABLE_CREDITS'"></app-number-picker>
        </div>
        <div class="input-group d-block mt-3">
          <app-select-box-picker (selected)="service.locale = $event" [boolean]="false" [filters]="utils.langs"
                                 [multiple]="false" [title]="'LOCALE'"></app-select-box-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.QR_CODES" class="d-flex d-flex-column">
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'LINK_ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.singleUseForUser = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'SINGLE_USE_FOR_USER'"></app-select-box-picker>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'ASSIGNED_CREDITS' | translate}}</label>
          <app-number-picker (numberSelected)="service.assignedCredits = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'ASSIGNED_CREDITS'"></app-number-picker>
        </div>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'NUMBER_OF_USES' | translate}}</label>
          <app-number-picker (numberSelected)="service.numberOfUses = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'NUMBER_OF_USES'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.NEWS_LIST" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CREATION_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CREATION_DATE'))}}"
                     id="CREATION_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'CREATION_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'newsCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('UPDATED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('UPDATED_AT'))}}"
                     id="UPDATED_AT">
            <ng-template class="text-black" ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'UPDATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.updatedAt = $event" [date]="service.updatedAt"
                                       [name]="'newsUpdatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.validForAllCompanies = $event" [boolean]="true"
                               [filters]="searchFilters.status"
                               [title]="'VALID_FOR_ALL_COMPANIES'"></app-select-box-picker>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'PRIORITY' | translate}}</label>
          <app-number-picker (numberSelected)="service.priority = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'PRIORITY'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.INVITES" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('CREATION_DATE')}"
                     cardClass="{{extractCardClass(acc.isExpanded('CREATION_DATE'))}}"
                     id="CREATION_DATE">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'CREATION_DATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.createdAt = $event" [date]="service.createdAt"
                                       [name]="'newsCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <app-select-box-picker (selected)="service.active = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'ACTIVE'"></app-select-box-picker>
        <app-select-box-picker (selected)="service.type = $event" [boolean]="false"
                               [filters]="searchFilters.deeplinkType"
                               [title]="'TYPE'"></app-select-box-picker>
        <div *ngIf="service.type === 'COMPANY'"
             class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'COMPANY' | translate}}</label>
          <app-select-company (selectedCompany)="selectContextDetailId($event)" [pageFilter]="true"
                              [single]="true"></app-select-company>
        </div>
        <div *ngIf="service.type === 'COMPETITION'"
             class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'COMPETITION' | translate}}</label>
          <app-select-competition (selectedCompetition)="selectContextDetailId($event)" [pageFilter]="true"
                                  [single]="true"></app-select-competition>
        </div>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'NUMBER_OF_USES' | translate}}</label>
          <app-number-picker (numberSelected)="service.numberOfUses = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INVITE_USES_COUNT'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.INVITES_USES" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel [ngClass]="{'filter-accordion-closed': !acc.isExpanded('INVITE_USE_USED_AT')}"
                     cardClass="{{extractCardClass(acc.isExpanded('INVITE_USE_USED_AT'))}}"
                     id="INVITE_USE_USED_AT">
            <ng-template ngbPanelTitle>
              <label class="font-weight-bold body-color mb-0 mt-0">{{'INVITE_USE_USED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.registrationDate = $event"
                                       [date]="service.registrationDate"
                                       [name]="'inivitesUsedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div class="input-group d-block">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'INVITE_USES_COUNT' | translate}}</label>
          <app-number-picker (numberSelected)="service.numberOfUses = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INVITE_USES_COUNT'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.COMPETITION_INDIVIDUAL_LEADERBOARD" class="d-flex d-flex-column">
        <app-select-box-picker (selected)="service.banned = $event" [boolean]="true" [filters]="searchFilters.status"
                               [title]="'BAN_ACTIVE'"></app-select-box-picker>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_POSITION' | translate}}</label>
          <app-number-picker (numberSelected)="service.position = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_POSITION'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'USER_ID' | translate}}</label>
          <app-number-picker (numberSelected)="service.userId = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'USER_ID'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'NICKNAME' | translate}}</label>
          <app-string-picker (numberSelected)="service.nickname = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'NICKNAME'"></app-string-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'FIRST_NAME' | translate}}</label>
          <app-string-picker (numberSelected)="service.firstName = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'FIRST_NAME'"></app-string-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'LAST_NAME' | translate}}</label>
          <app-string-picker (numberSelected)="service.lastName = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'LAST_NAME'"></app-string-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'EMAIL' | translate}}</label>
          <app-string-picker (numberSelected)="service.email = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'EMAIL'"></app-string-picker>
        </div>
        <app-select-box-picker (selected)="service.powerLevel = $event" [boolean]="false" [filters]="searchFilters.powerLevel"
                               [multiple]="true" [title]="'POWER_LEVEL'"></app-select-box-picker>
        <div class="input-group d-block mb-3">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_SCORE' | translate}}</label>
          <app-number-picker (numberSelected)="service.score = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_SCORE'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL' | translate}}</label>
          <app-number-picker (numberSelected)="service.provisional = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL'"></app-number-picker>
        </div>
        <div class="input-group d-block">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED' | translate}}</label>
          <app-number-picker (numberSelected)="service.consolidated = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED'"></app-number-picker>
        </div>
      </div>
      <div *ngIf="type === topbarTypeEnum.COMPETITION_TEAM_LEADERBOARD" class="d-flex d-flex-column">
        <label class="font-weight-bold mb-2 d-block mt-0">{{'DATES' | translate}}</label>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false" class="filter-accordion">
          <ngb-panel
            [ngClass]="{'filter-accordion-closed': !acc.isExpanded('INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START')}"
            cardClass="{{extractCardClass(acc.isExpanded('INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START'))}}"
            id="INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START">
            <ng-template ngbPanelTitle>
              <label
                class="font-weight-bold body-color mb-0 mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL_START' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.counterProvisionalStart = $event"
                                       [date]="service.counterProvisionalStart"
                                       [name]="'provisionalStart'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel
            [ngClass]="{'filter-accordion-closed': !acc.isExpanded('INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT')}"
            cardClass="{{extractCardClass(acc.isExpanded('INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT'))}}"
            id="INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT">
            <ng-template class="text-black" ngbPanelTitle>
              <label
                class="font-weight-bold body-color mb-0 mt-0">{{'INDIVIDUAL_LEADERBOARD_PARTICIPANT_CREATED_AT' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.participantCreatedAt = $event"
                                       [date]="service.participantCreatedAt"
                                       [name]="'participantCreatedAt'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
          <ngb-panel
            [ngClass]="{'filter-accordion-closed': !acc.isExpanded('INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE')}"
            cardClass="{{extractCardClass(acc.isExpanded('INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE'))}}"
            id="INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE">
            <ng-template class="text-black" ngbPanelTitle>
              <label
                class="font-weight-bold body-color mb-0 mt-0">{{'INDIVIDUAL_LEADERBOARD_PARTICIPANT_LAST_UPDATE' | translate}}</label>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-complex-date-picker (selectedDate)="service.participantLastUpdate = $event"
                                       [date]="service.participantLastUpdate"
                                       [name]="'participantLastUpdate'"></app-complex-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_POSITION' | translate}}</label>
          <app-number-picker (numberSelected)="service.position = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_POSITION'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'TEAM' | translate}}</label>
          <app-string-picker (numberSelected)="service.team = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'TEAM'"></app-string-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label class="font-weight-bold mb-0 d-block mt-0">{{'MEMBERS_COUNT' | translate}}</label>
          <app-number-picker (numberSelected)="service.membersCount = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'MEMBERS_COUNT'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_SCORE' | translate}}</label>
          <app-number-picker (numberSelected)="service.score = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_SCORE'"></app-number-picker>
        </div>
        <div class="input-group d-block mb-3">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL' | translate}}</label>
          <app-number-picker (numberSelected)="service.provisional = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_PROVISIONAL'"></app-number-picker>
        </div>
        <div class="input-group d-block">
          <label
            class="font-weight-bold mb-0 d-block mt-0">{{'INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED' | translate}}</label>
          <app-number-picker (numberSelected)="service.consolidated = $event"
                             [clearAll]="clearFilters.asObservable()"
                             [hide]="true"
                             [title]="'INDIVIDUAL_LEADERBOARD_COUNTER_CONSOLIDATED'"></app-number-picker>
        </div>
      </div>
    </ul>
    <div *ngIf="noFilters()">
      <img alt="" class="max-width-100" src="../../../../assets/images/shared/404.webp">
      <p class="no-filters">Sorry, no filters here</p>
    </div>
  </div>
  <div class="offcanvas-actions">
    <div class="btn-group">
      <button aria-controls="offcanvasNavbar" class="btn btn-primary font-weight-bold  offcanvas-actions-results"
              data-bs-target="#offcanvasNavbar" data-bs-toggle="offcanvas" type="submit">
        <i class="bx bx-chevron-left font-size-16 me-1 align-middle"></i>
        View <span *ngIf="service?.totalRecords$ | async as records" class="me-1 ms-1"> {{records}} </span>results
      </button>
      <button (click)="openExportModal()" *ngIf="service?.exporting$" class="btn btn-dark" ngbTooltip="Export results"
              placement="top"
              type="button"><i class="mdi mdi-download d-block font-size-16"></i></button>
    </div>
    <div class="d-flex clear-btn">
      <button (click)="clearSearchFilters()" *ngIf="service?.isFilterApplied && service?.isFilterApplied()"
              class="btn btn-outline-light offcanvas-actions-results me-2"
              type="button">
        Clear all filters ({{service.isFilterApplied(true)}})
      </button>
    </div>
  </div>
</div>
<app-export-results-modal #exportModal></app-export-results-modal>
