import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-fitness-metrics-charts',
  templateUrl: './fitness-metrics-charts.component.html',
  styleUrls: ['./fitness-metrics-charts.component.scss']
})
export class FitnessMetricsChartsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
