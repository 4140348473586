import {Component, Input, OnInit} from '@angular/core';
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";

@Component({
  selector: 'app-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent implements OnInit {
  @Input() canBeRemoved: boolean;
  @Input() light: boolean;
  @Input() companies: any[];

  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
    if (this.companies?.length <= 1) {
      this.canBeRemoved = false;
    }
  }

  public removeCompany(company: any): void {
    if (this.companies?.length > 1) {
      this.eventService.broadcast(EventEnum.REMOVE_COMPANY_FROM_NOTIFICATION_LIST, company)
    }
  }

}
