import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-text-picker',
  templateUrl: './text-picker.component.html',
  styleUrls: ['./text-picker.component.scss']
})
export class TextPickerComponent implements OnInit, OnDestroy {
  @Input() title: string | undefined = undefined;
  @Input() clearAll: Observable<boolean>;
  @Input() filters: any = undefined;
  @Input() boolean: boolean = false;
  @Input() id: string | undefined = undefined;
  @Input() text: string | undefined = undefined;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  private eventsSubscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    if (this.text) {
      (document.getElementById(this.id) as HTMLInputElement).value = this.text;
    }
    if (this.clearAll) {
      this.eventsSubscription = this.clearAll.subscribe((clear) => {
        if (clear) {
          (document.getElementById(this.id) as HTMLInputElement).value = null;
        }
      });
    }
  }

  public select(choice: any): void {
    this.selected.emit(this.getValue(choice, this.boolean));
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (value === '') {
      return null;
    }
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

}
