import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {UtilsService} from "@service/utils/utils.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {CompaniesCodesService} from "@service/companies/companies-codes.service";
import {ToastService} from "@service/toast.service";
import {Router} from "@angular/router";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";

@Component({
  selector: 'app-codes-list-page',
  templateUrl: './codes-list-page.component.html',
  styleUrls: ['./codes-list-page.component.scss']
})
export class CodesListPageComponent implements OnInit {
  @ViewChild('deleteModal') private deleteModal: any
  @ViewChild('deactivateModal') private deactivateModal: any
  @ViewChild('activateModal') private activateModal: any
  @ViewChild('companyCodesModal') private companyCodesModal: any
  @Input() filters?: any;
  public codesList$: Observable<any[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public notificationModalType = NotificationTypeEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPANY_CODES;
  public deleteModalType = DeleteModalTypeEnum;

  constructor(public companiesCodesService: CompaniesCodesService, private cdr: ChangeDetectorRef, public utils: UtilsService,
              private toastService: ToastService, private router: Router) { }

  ngOnInit(): void {
    this.codesList$ = this.companiesCodesService.codes$;
    this.total$ = this.companiesCodesService.totalRecords$;
    this.loading$ = this.companiesCodesService.loading$;
    this.sort({column: "id", direction: "asc"});
    this.setVisibleColumns();
    this.companiesCodesService.companyId = this.filters?.contextDetailsId;
    this.companiesCodesService.initSearchCodesListener();
    this.companiesCodesService._search$.next();
    this.cdr.detectChanges();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.companiesCodesService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.companiesCodesService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.companiesCodesService.page - 1) * this.companiesCodesService.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.companiesCodesService.sortColumn = column;
    this.companiesCodesService.sortDirection = direction;
  }

  public copied(event: any): void {
    this.toastService.show('Copied - ' + event?.content, {classname: 'bg-secondary text-light'});
  }

  public userDetail(user: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(user?.id)])
    );
    window.open(url, '_blank');
  }

  async addCodes(event?: any) {
    return await this.companyCodesModal.open({id: this.filters?.contextDetailsId});
  }

  async codeActivateDeactivate(code: any, activate: boolean) {
    if (!activate) {
      const title = 'DEACTIVATE_CODE';
      const description = 'DEACTIVATE_CODE_DESC';
      return await this.deactivateModal.open(code, title, description)
    } else if (activate) {
      const title = 'ACTIVATE_CODE';
      const description = 'ACTIVATE_CODE_DESC';
      return await this.activateModal.open(code, title, description)
    }
  }

  async deleteCode(code) {
    if (code?.usesCount > 0) {
      return;
    } else {
      const title = 'DELETE_CODE';
      const description = 'LOREM';
      return await this.deleteModal.open([code], title, description)
    }
  }


  public extractDeleteTootlip(code: any): string {
    if (code?.usesCount > 0) {
      return 'This code can\'t be deleted because it\'s used by ' + code?.usesCount + ' people';
    } else {
      return 'Delete code';
    }
  }

  ngOnDestroy() {
    this.companiesCodesService.removeSearchCodesListener();
  }
}
