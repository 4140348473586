<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between pb-2">
          <h4 class="card-title card-header card-header-section"><i
            class="bx bx-building-house me-2"></i> {{'UTILS.COMMON.FITNESS_METRICS' | translate}}</h4>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-2">
      <div class="col-lg-6">
        <div class="toolbar button-items text-start">
          <button (click)="updateOptions( periodOfInterestEnum.DAY )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.DAY" class="btn btn-light btn-sm active"
                  ngbTooltip="Day of selected date"
                  placement="bottom"
                  type="button">
            DAY
          </button>
          <button (click)="updateOptions( periodOfInterestEnum.WEEK )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.WEEK" class="btn btn-light btn-sm"
                  ngbTooltip="Week of selected date"
                  placement="bottom"
                  type="button">
            WEEK
          </button>
          <button (click)="updateOptions(  periodOfInterestEnum.MONTH )"
                  [class.active]="activeOptionButton == periodOfInterestEnum.MONTH" class="btn btn-light btn-sm"
                  ngbTooltip="Month of selected date"
                  placement="bottom"
                  type="button">
            MONTH
          </button>
        </div>
      </div>
      <div class="col-lg-3 ms-auto">
        <app-simple-datepicker (dateSelected)="userFitnessMetrics.aroundInstant = $event"></app-simple-datepicker>
      </div>
    </div>
    <div *ngIf="metrics$ | async as metrics" class="row">
      <div *ngFor="let metric of metrics;" class="col-lg-4">
        <app-data-card [id]="userId" [image]="metric?.metricImage" [number]="metric?.confirmedAmount"
                       [stats]="true" [title]="metric?.confirmedDescription"
                       [unitOfMeasure]="metric?.unitOfMeasure"></app-data-card>
      </div>
    </div>
  </div>
</div>
