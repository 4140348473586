import { Injectable } from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable, of} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class BadgesApiService {

  constructor(private _http: HttpService) {}
	
	public search(filters?: any): Observable<any> {
		const params = this._http.generateQueryParams(filters);
		return this._http.get<any>(endpoints.badges.search, params);
	}
	
	public fitness(filters?: any): Observable<any> {
		const params = this._http.generateQueryParams(filters);
		return this._http.get<any>(endpoints.badges.fitness, params);
	}
	
	public events(filters?: any): Observable<any> {
		const params = this._http.generateQueryParams(filters);
		return this._http.get<any>(endpoints.badges.events, params);
	}
	
	public add(data: any): Observable<any> {
		return this._http.post(endpoints.badges.create, data);
	}
	
	public edit(data: any): Observable<any> {
		return this._http.put(endpoints.badges.edit, data);
	}
	
	public get badgeTypes(): any[] {
		return [
			{value: 'event', label: 'BADGE_EVENT'},
			{value: 'fitness', label: 'BADGE_FITNESS'},
		]
	}
	
	public get aggregationFunctions(): any[] {
		return [
			{value: 'FIRST_SCORE', label: 'FIRST_SCORE'},
			{value: 'COUNT_SCORES_OVER', label: 'COUNT_SCORES'},
		]
	}
	
	public get windowsTypes(): any[] {
		return [
			{value: 'HOUR', label: 'HOUR'},
			{value: 'DAY', label: 'DAY_EXPLAINED'},
			{value: 'SHIFTED_DAY', label: 'SHIFTED_DAY_EXPLAINED'},
			{value: 'WEEK', label: 'WEEK'},
			{value: 'MONTH', label: 'MONTH'},
			{value: 'CENTURY', label: 'CENTURY'}
		]
	}
}
