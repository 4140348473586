<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="!title && !banned">{{ 'USERS_USING_THIS_SOURCE' | translate}}</span>
      <span *ngIf="!title && banned">{{ 'USERS_BANNED_BY_RULE' | translate}}</span>
      <span *ngIf="title">{{ title | translate}}</span>
    </h5>
    <button class="btn close-modal-button" closeModal="CLOSE_DETAIL_BLACKLIST_SOURCE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row modal-content-max">
      <div class="col-12 text-center">
        <h6 *ngIf="subtitle" class="d-block text-left mb-3" style="text-align: left!important;">
          <span *ngIf="type === 'INVITES'">Deeplink: <span class="link">{{subtitle}}</span></span>
          <span *ngIf="type !== 'INVITES'">{{subtitle}}</span>
        </h6>
        <div *ngIf="detail?.loading">
          <div class="spinner-border text-secondary m-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <app-users-table *ngIf="type !== 'INVITES' && detail?.users?.length > 0" [canBeRemoved]="false"
                         [hideBannedColumn]="banned" [leaderboard]="leaderboard" [light]="true"
                         [type]="deleteModalTypeEnum.BLACKLIST_SOURCE" [users]="detail?.users"></app-users-table>
        <ng-container *ngIf="type === 'INVITES' && detail?.users?.length > 0">
          <table class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
            <thead>
            <tr>
              <th>
                <span class="me-3">{{'USER_ID' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'EMAIL' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'NICKNAME' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'USED_AT' | translate}}</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of detail?.users; let i=index;" class="pointer">
              <td (click)="userDetail(user?.usedBy?.userId)" align="center">
                <span class="badge badge-pill badge-soft-primary">{{user?.usedBy?.userId}}</span>
              </td>
              <td (click)="userDetail(user?.usedBy?.userId)">
                <span *ngIf="user?.usedBy?.email">{{user?.usedBy?.email}}</span>
                <app-missing-label *ngIf="!user?.usedBy?.email"></app-missing-label>
              </td>
              <td (click)="userDetail(user?.usedBy?.userId)">
                <span *ngIf="user?.usedBy?.nickname">{{user?.usedBy?.nickname}}</span>
                <app-missing-label *ngIf="!user?.usedBy?.nickname"></app-missing-label>
              </td>
              <td>
                <span *ngIf="user?.usedAt">{{user?.usedAt | date:'dd/MM/yyyy'}}</span>
                <app-missing-label *ngIf="!user?.usedAt"></app-missing-label>
              </td>
            </tr>
            </tbody>
          </table>

        </ng-container>
        <div *ngIf="detail?.noResults && !banned">
          <ngb-alert [dismissible]="false" type="success">
            There are no results
          </ngb-alert>
        </div>
        <div *ngIf="detail?.noResults && banned">
          <ngb-alert [dismissible]="false" type="success">
            There are no results
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="detail?.users?.length > 0" class="modal-footer">
    <ul class="list-inline wizard mb-0">
      <li *ngIf="showPagination" class="next list-inline-item float-start">
        <ngb-pagination (pageChange)="loadPage($event)" [(page)]="pagination.page" [class.custom-pagination]="true"
                        [collectionSize]="pagination.total" [maxSize]="3"
                        [pageSize]="pagination.size">
        </ngb-pagination>
      </li>
      <li class="next list-inline-item float-end">
        <button (click)="extractResults()" class="btn btn-dark">Export results</button>
      </li>
    </ul>
  </div>
</ng-template>
