import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {
  @Input() service: any;
  @Input() companyId: any;
  public team: any | undefined = undefined;
  public expectedMembers: any | undefined = undefined;
  public title: string | undefined = undefined;
  public titleParams: string | undefined = undefined;
  public description: string | undefined = undefined;
  public loading: boolean;
  public showAreYouSure: boolean = false;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private closeModalSubscription: Subscription;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, private eventService: EventService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  public open(team?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.setTeam(team);
      this._closeModal();
    })
  }

  public editTeam(): void {
    this.loading = true;
    this.service.editCompanyTeam({...this.team, companyId: this.companyId}).subscribe((result: any) => {
      this.toastService.show('Teams edited successfully', {classname: 'bg-success text-light'});
      this.loading = false;
      this.forceCloseModal(result);
    });
  }

  ngOnDestroy() {
    this.closeModalSubscription?.unsubscribe();
  }

  private setTeam(team: any) {
    this.team = team;
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ACTIVATE_MODAL, (reason: string | undefined) => {
      this.forceCloseModal(reason);
    });
  }

  private forceCloseModal(reason: string) {
    this.resetTeam();
    this.closeModalSubscription?.unsubscribe();
    this.modalRef?.dismiss(reason);
  }

  private resetTeam(): void {
    this.title = undefined;
    this.description = undefined;
    this.titleParams = undefined;
    this.showAreYouSure = false;
  }
}
