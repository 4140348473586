<div *ngIf="!table" class="spinner-border text-primary m-1" role="status">
  <span class="sr-only">{{'LOADING' | translate}}</span>
</div>
<table *ngIf="table">
  <tbody>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  <tr>
    <td class="td-1"><span></span></td>
    <td class="td-2"><span></span></td>
    <td class="td-3"><span></span></td>
    <td class="td-4"></td>
    <td class="td-5"><span></span></td>
  </tr>
  </tbody>
</table>
