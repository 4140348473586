import {Component, Input, OnInit} from '@angular/core';
import {UserFitnessMetricsService} from "@service/users/user-fitness-metrics.service";
import {Observable} from "rxjs";
import {FitnessMetricLightInterface} from "@interface/metrics/fitness-metric-light.interface";
import {PeriodOfInterestEnum} from "@enum/period-of-interest/period-of-interest.enum";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-fitness-metrics',
  templateUrl: './fitness-metrics.component.html',
  styleUrls: ['./fitness-metrics.component.scss']
})
export class FitnessMetricsComponent implements OnInit {
  @Input() userId;
  public activeOptionButton = PeriodOfInterestEnum.DAY;
  public periodOfInterestEnum = PeriodOfInterestEnum;
  public metrics$: Observable<FitnessMetricLightInterface[]>;
  public aroundInstant: Date = new Date();
  model: NgbDateStruct;
  public total$: Observable<number>;
  date: { year: number, month: number };

  constructor(public userFitnessMetrics: UserFitnessMetricsService) {
    this.metrics$ = userFitnessMetrics.userFitnessMetrics$;
    this.total$ = userFitnessMetrics.userFitnessMetricsTotalRecords$;
  }

  ngOnInit(): void {
    this.userFitnessMetrics.userId = this.userId;
    this.userFitnessMetrics.period = this.activeOptionButton;
    this.userFitnessMetrics._userFitnessMetricsSearch$.next();
  }

  updateOptions(period: PeriodOfInterestEnum) {
    this.activeOptionButton = period;
    this.userFitnessMetrics.period = period;
  }

  public changedValue(value: any) {
  }

}
