import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-complex-date-picker',
  templateUrl: './complex-date-picker.component.html',
  styleUrls: ['./complex-date-picker.component.scss']
})
export class ComplexDatePickerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() name?: string;
  @Input() date: string[] | undefined;
  @Output() selectedDate: EventEmitter<string[]> = new EventEmitter();
  public selectedOnDate?: SimpleChange | Date;
  public selectedFromDate?: SimpleChange | Date;
  public selectedToDate?: SimpleChange | Date;

  constructor(private cdr: ChangeDetectorRef) {
  }

  private static clearOtherFields(id: string): void {
    if (id && document.getElementById(id)) {
      (<HTMLInputElement>document.getElementById(id)).value = '';
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.preselectDates();
  }

  ngAfterViewInit() {
    this.preselectDates();
  }

  public setOnDate(date: any): void {
    if (!date) {
      this.selectedDate.emit(null);
    } else {
      ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'From');
      ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'To');
      const fromDateTimestamp = date.setHours(0, 0, 0, 0)
      const toDateTimestamp = date.setHours(23, 59, 59, 0);
      this.selectedDate.emit(["BETWEEN", new Date(fromDateTimestamp).toISOString(), new Date(toDateTimestamp).toISOString()]);
    }
  }

  public setFromDate(date: any): void {
    if (!date) {
      this.selectedDate.emit(null);
    } else {
      ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'On');
      if (this.getOtherFieldsValue('datepicker' + this.name + 'To') && this.getOtherFieldsValue('datepicker' + this.name + 'To') > date) {
        const toDateTimestamp = this.getOtherFieldsValue('datepicker' + this.name + 'To').setHours(23, 59, 59, 0);
        const fromDateTimestamp = date.setHours(0, 0, 0, 0)
        this.selectedDate.emit(["BETWEEN", new Date(fromDateTimestamp).toISOString(), new Date(toDateTimestamp).toISOString()]);
      } else {
        ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'To');
        const fromDateTimestamp = date.setHours(0, 0, 0, 0)
        this.selectedDate.emit(["FROM", new Date(fromDateTimestamp).toISOString()]);
      }
    }
  }

  public setToDate(date: any): void {
    if (!date) {
      this.selectedDate.emit(null);
    } else {
      ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'On');
      if (this.getOtherFieldsValue('datepicker' + this.name + 'From') && this.getOtherFieldsValue('datepicker' + this.name + 'From') < date) {
        const fromDateTimestamp = this.getOtherFieldsValue('datepicker' + this.name + 'From').setHours(0, 0, 0, 0);
        const toDateTimestamp = date.setHours(23, 59, 59, 0);
        this.selectedDate.emit(["BETWEEN", new Date(fromDateTimestamp).toISOString(), new Date(toDateTimestamp).toISOString()]);
      } else {
        ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'From');
        const toDateTimestamp = date.setHours(23, 59, 59, 0);
        this.selectedDate.emit(["BEFORE", new Date(toDateTimestamp).toISOString()]);
      }
    }
  }

  private setOtherFields(id: string, value: any): void {
    if (id && document.getElementById(id) && value) {
      (<HTMLInputElement>document.getElementById(id)).value = value;
      this.cdr.detectChanges();
    }
  }

  private getOtherFieldsValue(id: string): Date | undefined {
    if (id && document.getElementById(id) && (<HTMLInputElement>document.getElementById(id)).value && (<HTMLInputElement>document.getElementById(id)).value !== '') {
      const dateString = (<HTMLInputElement>document.getElementById(id)).value;
      const dateMomentObject = moment(dateString, "DD/MM/YYYY");
      return dateMomentObject.toDate();
    } else {
      return undefined;
    }
  }

  private preselectDates(): void {
    if (this.date?.length > 0) {
      if (this.date.includes("BETWEEN")) {

      } else if (this.date.includes("FROM")) {
        const date = moment(new Date(this.date[1])).format("DD/MM/YYYY")
        this.setOtherFields('datepicker' + this.name + 'From', date);
      } else if (this.date.includes("BEFORE")) {
        const date = moment(new Date(this.date[1])).format("DD/MM/YYYY");
        this.setOtherFields('datepicker' + this.name + 'To', date);
      } else {
        this.clearAllFields();
      }
    } else {
      this.clearAllFields();
    }
  }

  private clearAllFields(): void {
    ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'On');
    ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'From');
    ComplexDatePickerComponent.clearOtherFields('datepicker' + this.name + 'To');
  }

}
