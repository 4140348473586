<div *ngIf="list$ | async as audience" class="mb-3 row">
  <label class="col-md-3 col-form-label font-weight-bold">Select audience</label>
  <div class="col-md-5">
    <select (change)="updateSelectedAction($event)" [(ngModel)]="selected" class="form-select">
      <option *ngFor="let audienceElement of audience" [disabled]="massiveNotification && audienceElement.audience === 'SINGLE_USERS'"
              [selected]="audienceElement.selected"
              [value]="audienceElement.audience">{{audienceElement.label | translate}}</option>
    </select>
  </div>
</div>
