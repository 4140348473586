import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SustainabilityBenefitsApiService} from "@service/sustainability/benefits/sustainability-benefits-api.service";
import {SustainabilityPlacesApiService} from "@service/sustainability/places/sustainability-places-api.service";
import {PartnersApiService} from "@service/prizes/partners/partners-api.service";
import {DatePipe} from "@angular/common";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";
import {SustainabilityObjectivesService} from "@service/sustainability/objectives/sustainability-objectives.service";

@Component({
  selector: 'app-add-sustainability-objective',
  templateUrl: './add-sustainability-objective.component.html',
  styleUrls: ['./add-sustainability-objective.component.scss']
})
export class AddSustainabilityObjectiveComponent implements OnInit {
  @Input() objective: any;
  public activeId: number = 1;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public objectiveForm: UntypedFormGroup;
  public places = [];
  public partners = [];
  public benefits = [];
  public fitnessMetricList = [
    FitnessMetricEnum.STEPS,
    FitnessMetricEnum.SLEEP,
    FitnessMetricEnum.MEDITATION,
    FitnessMetricEnum.SPORT,
    FitnessMetricEnum.YOGA_MINUTES
  ];
  public types = ['PROJECT'];
  public rules = ['ON_REACH', 'ON_GOING'];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 1000,
    height: 1000
  };
  public detailImageLimit: any = {
    fixedSize: true,
    ratio: '16:9',
    width: 1920,
    height: 1080
  };
  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder, public datepipe: DatePipe, private _placesApiService: SustainabilityPlacesApiService,
              private sustainabilityObjectivesService: SustainabilityObjectivesService, private _benefitsApiService: SustainabilityBenefitsApiService, private partnersApiService: PartnersApiService) { }

  ngOnInit(): void {
    this.adding$ = this.sustainabilityObjectivesService.adding$;
    this.initForm();
    this._loadBenefits();
    this._loadPartners();
    this._loadPlaces();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public validSubmit() {
    if (this.objectiveForm.valid) {
      const data = this.objectiveForm.getRawValue();
      data.partnerId = !!data?.partnerId ? Number(data.partnerId) : undefined;
      this.sustainabilityObjectivesService.addObjective(data);
    }
  }

  public checkNextActionBtn(): void {
    if (this.objectiveForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.objectiveForm.controls;
  }

  get valid() {
    return this.objectiveForm.valid
  }

  public setImage(image: any, field?: string): void {
    if (!image) {
      this.removeImage(field);
    } else {
      this.objectiveForm.patchValue({
        [field]: image?.originalUrl,
      });
    }
  }

  public checkSelectedImage(field: string): object | undefined {
    if (this.form?.[field]?.value) {
      return {name: field, originalUrl: this.form?.[field]?.value}
    } else {
      return null;
    }
  }

  public removeImage(field?: string): void {
    this.objectiveForm.patchValue({
      [field]: null
    });
  }

  public checkImageErrors(field: string): string | undefined {
    if (this.formSubmitted && this.form?.[field]?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.objectiveForm = this.formBuilder.group({
      title: [!this.objective || !this.objective?.title ? null : this.objective?.title, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      description: [!this.objective || !this.objective?.description ? null : this.objective?.description, [Validators.required, Validators.minLength(3), Validators.maxLength(400)]],
      image: [!this.objective || !this.objective?.image ? null : this.objective?.image, [Validators.required]],
      type: [!this.objective || !this.objective?.type ? 'PROJECT' : this.objective?.type],
      rule: [!this.objective || !this.objective?.rule ? null : this.objective?.rule],
      multiplier: [!this.objective || !this.objective?.multiplier ? null : this.objective?.multiplier],
      multiplierText: [!this.objective || !this.objective?.multiplierText ? null : this.objective?.multiplierText],
      metric: [!this.objective || !this.objective?.metric ? null : this.objective?.metric],
      target: [!this.objective || !this.objective?.target ? null : this.objective?.target],
      startDate: [!this.objective || !this.objective?.startDate ? null : this.objective?.startDate],
      endDate: [!this.objective || !this.objective?.endDate ? null : this.objective?.endDate],
      partnerId: [!this.objective || !this.objective?.partnerId ? null : this.objective?.partnerId],
      benefitsIds: [!this.objective || !this.objective?.benefitsIds ? null : this.objective?.benefitsIds],
      placeId: [!this.objective || !this.objective?.placeId ? null : this.objective?.placeId],
      active: [this.objective?.active && !this.objective.duplicate ? this.objective?.active : false],
      id: [this.objective?.id && !this.objective.duplicate ? this.objective?.id : undefined],
      restrictsLocales: [this.objective?.restrictsLocales ? this.objective?.restrictsLocales : null],
    });
  }

  private _loadBenefits(): void {
    this._benefitsApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.benefits = result?.data;
    });
  }

  private _loadPartners(): void {
    this.partnersApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.partners = result?.data;
    });
  }

  private _loadPlaces(): void {
    this._placesApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
      this.places = result?.data;
    });
  }

  public setDate(date: any, field?: string): void {
    let convertedDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.objectiveForm.patchValue({[field]: new Date(date).getTime()});
  }

  public checkCompetitionRestriction(): void {
    switch (this.form.type.value) {
      case 'COMPETITION':
        this.form.rule.patchValue('ON_REACH');
        break;
      default:
        break;
    }
  }

}
