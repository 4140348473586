import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-string-picker',
  templateUrl: './string-picker.component.html',
  styleUrls: ['./string-picker.component.scss']
})
export class StringPickerComponent implements OnInit {
  @Input() title: string = 'AVAILABLE_CREDITS';
  @Input() clearAll: Observable<boolean>;
  @Input() hide: boolean;
  @Output() numberSelected: EventEmitter<string[]> = new EventEmitter();
  public availableNumber: any = null;
  public availableNumberOperator: any = "CONTAINS";
  public operators: any[] = [
    {value: "CONTAINS", secondValue: "IGNORE_CASE", label: "CONTAINS", selected: false}
  ];
  private eventsSubscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    if (this.clearAll) {
      this.eventsSubscription = this.clearAll.subscribe((clear) => {
        if (clear) {
          this.availableNumber = null;
          this.availableNumberOperator = "CONTAINS";
          this.numberSelected.emit(null);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  public getNumberValue(event: any): any {
    if (typeof event === 'string' && this.availableNumberOperator && event?.length > 0) {
      this.numberSelected.emit([this.availableNumberOperator, "IGNORE_CASE", event]);
    } else {
      this.numberSelected.emit([]);
    }
  }

  public clearValue(): void {
    if (this.availableNumber && this.availableNumberOperator) {
      this.availableNumber = null;
      this.availableNumberOperator = null;
      this.numberSelected.emit(null);
    }
  }
}
