import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class StreakApiService {

  constructor(private http: HttpService) {
  }

  public getStreakList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.streak.goal.list, params);
  }

  public deleteStreak(streaks: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: streaks[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.streak.goal.delete, placeholders);
    return this.http.delete(url);
  }

  public createStreak(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.streak.goal.edit, placeholders);
      return this.http.post(url, params);
    } else {
      return this.http.put(endpoints.streak.goal.add, params);
    }
  }

  public getTemplateFitnessScoringFunctions(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.streak.template.fitnessScoring, params);
  }

  public editStreak(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.streak.goal.edit, placeholders);
    return this.http.post(url, params);
  }

}
