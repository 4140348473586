<div class="input-group mb-2 d-block">
  <label *ngIf="title" class="font-weight-bold mb-2 d-block mt-0">{{title | translate}}</label>
  <div *ngFor="let status of filters" class="form-check mb-3 select-check">
    <input (change)="select($event)" [checked]="status.selected" [id]="status.value" [type]="multiple ? 'checkbox' : 'radio'"
           [value]="status.value" class="form-check-input"
           id="{{title}}{{status.label}}" name="{{title}}">
    <label class="form-check-label" for="{{title}}{{status.label}}">
      {{status.label | translate}}
    </label>
  </div>
</div>
