<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>Add new teams</span>
    </h5>
    <button class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_COMPANY_TEAMS_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div *ngIf="loading" class="col-12 p-0">
        <div class="wrapper">
          <app-loader [table]="false"></app-loader>
        </div>
      </div>
      <div class="col-12" *ngIf="showAddTeams && !loading">
        <div class="mt-3 mb-3">
                      <textarea [(ngModel)]="newTeams"
                                class="form-control codes-textarea"
                                placeholder="Insert all the team names separated with a space"
                                id="description"
                                rows="4"></textarea>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button [disabled]="loading || checkTextarea()" *ngIf="showAddTeams" (click)="addCodes()" class="btn btn-primary" type="submit">Save new teams</button>
      </li>
    </ul>
  </div>
</ng-template>
