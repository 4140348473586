<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!company">{{'CREATE_COMPANY' | translate}}</h5>
    <h5 *ngIf="company">{{'EDIT_COMPANY' | translate}}</h5>
    <button [disabled]="(creatingCompany$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_COMPANY">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="companyForm" autocomplete="off"
          class="needs-validation">
      <div class="row mb-3">
        <div class="col-md-2">
          <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
            <li [ngbNavItem]="1" (click)="activeId = 1">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2" *ngIf="checkAlert('BASE') || checkAlert('RESTRICTIONS')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block font-size-11">Base and presentational</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row mb-3">
                  <div class="col-7">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-3">
                                  <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
                                  <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
                                         formControlName="name" id="name" type="text">
                                  <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
                                    <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                    <span *ngIf="form?.name?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.name?.errors?.maxlength?.requiredLength} }}</span>
                                    <span *ngIf="form?.name?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.name?.errors?.minlength?.requiredLength} }}.</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.description.errors}">
                                <label class="font-weight-bold" for="title">{{'DESCRIPTION' | translate}}</label>
                                <ckeditor [editor]="Editor" formControlName="description" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                                          data="<p>Description of the company.</p>"></ckeditor>
                                <div *ngIf="formSubmitted && form.description?.errors" class="invalid-feedback d-block">
                                  <span *ngIf="formSubmitted && form.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                  <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                                  <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                          <label class="font-weight-bold" for="teamScoreStrategy">Team score strategy</label>
                          <select   class="form-select" formControlName="teamScoreStrategy" id="teamScoreStrategy">
                            <option *ngFor="let teamScoreStrategy of teamScoreStrategies" [value]="teamScoreStrategy">{{teamScoreStrategy | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.teamScoreStrategy?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.teamScoreStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="priority">Priority</label>
                          <input [ngClass]="{'is-invalid': formSubmitted && form?.priority?.errors}" class="form-control"
                                 formControlName="priority" id="priority"
                                 min="0"
                                 placeholder="Priority" type="number">
                          <div class="invalid-feedback"  *ngIf="formSubmitted && form?.priority?.errors?.required">
                            <span>{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <!--            <div class="col-12">-->
                      <!--              <p class="font-weight-bold mb-1">Stats configuration</p>-->
                      <!--              <p class="text-muted">This fields is used for company stats in app</p>-->
                      <!--            </div>-->
                      <div class="col-6">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeWhen?.errors}">
                          <label class="font-weight-bold" for="countChallengeWhen">Count challenge when</label>
                          <select class="form-select" formControlName="countChallengeWhen" id="countChallengeWhen">
                            <option *ngFor="let challengeWhen of countChallengeWhen" [value]="challengeWhen">{{challengeWhen | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.countChallengeWhen?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.countChallengeWhen?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3"  [ngClass]="{'is-invalid-select': formSubmitted && form?.countChallengeStrategy?.errors}">
                          <label class="font-weight-bold" for="countChallengeStrategy">Count challenge strategy</label>
                          <select class="form-select" formControlName="countChallengeStrategy" id="countChallengeStrategy">
                            <option *ngFor="let challengeStrategy of countChallengeStrategy" [value]="challengeStrategy">{{challengeStrategy | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.countChallengeStrategy?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.countChallengeStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="co2Improvement">CO2 Improvement</label>
                          <input [ngClass]="{'is-invalid': formSubmitted && form?.co2Improvement?.errors}" class="form-control"
                                 formControlName="co2Improvement" id="co2Improvement"
                                 min="0" type="number">
                          <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.co2Improvement?.errors?.required">
                            <span>{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.chatRule?.errors}">
                          <label class="font-weight-bold" for="chatRule">Chat rule</label>
                          <select class="form-select" formControlName="chatRule" id="chatRule">
                            <option *ngFor="let chatRule of chatRuleOptions" [value]="chatRule">{{chatRule | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.chatRule?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.chatRule?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <app-select-locale [parentForm]="companyForm"></app-select-locale>
                      </div>
                      <div class="col-12">
                        <div class="mb-3 single-ng-select">
                          <label class="font-weight-bold">Enabled languages</label>
                          <p class="card-title-desc mb-2">Select wich languages this company has enabled.</p>
                          <ng-select [clearable]="true" [items]="availableLanguages" [multiple]="true" [placeholder]="'Italian, French'"
                                     [(ngModel)]="languages" [ngModelOptions]="{standalone: true}">
                            <ng-template let-item="item" ng-label-tmp>
                              <span>{{ item | translate}}</span>
                            </ng-template>
                            <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                              <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <div class="row mb-3">
                          <div class="col-12"  [ngClass]="{'col-12': !cardImage}">
                            <app-image-uploader (imageUploaded)="setCardImage($event)"
                                                [showImagePreview]="true"
                                                [name]="'LOGO'"
                                                [imageErrors]="checkCardImageError()"
                                                [limit]="logoImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>

                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row mb-3">
                          <div class="col-12"  [ngClass]="{'col-12': !detailImage}">
                            <app-image-uploader (imageUploaded)="setDetailImage($event)"
                                                [name]="'DETAIL_IMAGE'"
                                                [showImagePreview]="true"
                                                [imageErrors]="checkDetailImageError()"
                                                [limit]="detailImageLimit" [previousSelectedImage]="checkSelectedDetailImage()"></app-image-uploader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" (click)="activeId = 3">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block font-size-11">Configuration</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row mb-3">
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="expectedMembers">{{'EXPECTED_MEMBERS' | translate}}</label>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.expectedMembers?.errors}" class="form-control"
                             formControlName="expectedMembers" id="expectedMembers" type="number" min="1">
                      <div *ngIf="formSubmitted && form?.expectedMembers?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.expectedMembers?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" [class.col-6]="!form?.mustInsertCorporateMail?.value === true">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="mustInsertCorporateMail">{{'EMAIL_VALIDATION' | translate}}</label>
                      <select class="form-select" formControlName="mustInsertCorporateMail" id="mustInsertCorporateMail" (change)="convertBool('mustInsertCorporateMail')">
                        <option [value]="false">{{'NOT_REQUIRED' | translate}}</option>
                        <option [value]="true">{{'REQUIRED' | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-2" *ngIf="form?.mustInsertCorporateMail?.value === true">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="mustConfirmCorporateMail">Must confirm email</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.mustConfirmCorporateMail?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="mustConfirmCorporateMail"
                               id="mustConfirmCorporateMail" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="mustConfirmCorporateMail">
                              <span *ngIf="form?.mustConfirmCorporateMail?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.mustConfirmCorporateMail?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="form?.mustInsertCorporateMail?.value === true">
                    <div class="col-8 mb-3">
                      <label class="font-weight-bold">Email domains</label>
                      <p class="card-title-desc">
                        Please insert only the single domain for example: virtuoso.com
                      </p>
                    </div>
                    <div class="col-4 mb-3" *ngIf="form?.mustConfirmCorporateMail?.value === true">
                      <label class="font-weight-bold" for="emailCodeExpireInDays">Email confirm expiration days</label>
                      <input class="form-control" formControlName="emailCodeExpireInDays" id="emailCodeExpireInDays" type="number">
                    </div>
                    <div class="col-12 mb-3">
                      <textarea [(ngModel)]="validEmails" [ngModelOptions]="{standalone: true}"
                                class="form-control codes-textarea"
                                placeholder="Insert all the email domains separated with a space"
                                id="description"
                                rows="4"></textarea>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="active">Active</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="active"
                                   id="active" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.active?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="enabled">Enabled</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.enabled?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="enabled"
                                   id="enabled" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="enabled">
                              <span *ngIf="form?.enabled?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.enabled?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="discoverable">Discoverable</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.discoverable?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="discoverable"
                                   id="discoverable" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="discoverable">
                              <span *ngIf="form?.discoverable?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.discoverable?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="showMembersCount">Show members count</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.showMembersCount?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="showMembersCount"
                                   id="showMembersCount" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="showMembersCount">
                              <span *ngIf="form?.showMembersCount?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.showMembersCount?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="showCompanyLeaderboards">Show leaderboard</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.showCompanyLeaderboards?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="showCompanyLeaderboards"
                                   id="showCompanyLeaderboards" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="showCompanyLeaderboards">
                              <span *ngIf="form?.showCompanyLeaderboards?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.showCompanyLeaderboards?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="mustInsertCode">Access code mandatory</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.mustInsertCode?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="mustInsertCode"
                                   id="mustInsertCode" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="mustInsertCode">
                              <span *ngIf="form?.mustInsertCode?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.mustInsertCode?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="mustInsertCode">Enable company challenge</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.enableCompanyChallenge?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="enableCompanyChallenge"
                                   id="mustInsertCode" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="mustInsertCode">
                              <span *ngIf="form?.enableCompanyChallenge?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.enableCompanyChallenge?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" for="mustInsertName">Complete name required</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.mustInsertName?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="mustInsertName"
                                   id="mustInsertCode" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="mustInsertCode">
                              <span *ngIf="form?.mustInsertName?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.mustInsertName?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-3">
                          <label class="font-weight-bold font-size-11" id="showFixedTreesCard">Show only trees</label>
                          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                            <input [checked]="form?.showFixedTreesCard?.value" autocomplete="off" class="form-check-input m-0 pointer"
                                   formControlName="showFixedTreesCard"
                                   id="mustInsertCode" type="checkbox">
                            <label class="form-check-label font-size-11 ms-3" for="showFixedTreesCard">
                              <span *ngIf="form?.showFixedTreesCard?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                              <span *ngIf="!form?.showFixedTreesCard?.value"
                                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="col-md-10">
          <div [ngbNavOutlet]="verticalNav"></div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && !company"
                  class="btn btn-warning">Create
          </button>
          <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure && company"
                  class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingCompany$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingCompany$ | async) && !company">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingCompany$ | async) && company">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingCompany$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingCompany$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
