import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {StreakService} from "@service/streak/streak.service";
import {DatePipe} from "@angular/common";
import {StreakTemplateService} from "@service/streak/streak-template.service";
import {SimpleDatepickerComponent} from "@component/utils/simple-datepicker/simple-datepicker.component";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-add-streak-modal',
  templateUrl: './add-streak-modal.component.html',
  styleUrls: ['./add-streak-modal.component.scss']
})
export class AddStreakModalComponent implements OnInit, AfterViewInit {
  @ViewChild('upToDateComponent', {
    static: true,
    read: SimpleDatepickerComponent
  }) upToDateComponent: SimpleDatepickerComponent;
  @Input() streak: any;
  public creatingStreak$: Observable<boolean>;
  public formSubmitted: boolean;
  public streakForm: UntypedFormGroup;
  public types: string[] = ['FIXED', 'PERSONAL'];
  public limit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 24,
    height: 24
  };
  public templates = [];
  public selected = undefined;
  public template = undefined;
  public actions = ['NO_ACTION', 'openFaq', 'openLink', 'openClosableWebView'];
  public action: any = 'NO_ACTION';
  public resetUpToDate: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private searchTemplatesSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              public datepipe: DatePipe, public cdr: ChangeDetectorRef,
              private utils: UtilsService,
              public formBuilder: UntypedFormBuilder, private streakService: StreakService,
              private streakTemplateService: StreakTemplateService) {
  }

  get form() {
    return this.streakForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingStreak$ = this.streakService?.creating$;
    this.initForm();
    this.extractTemplates();
  }

  ngAfterViewInit(): void {
    this.resetUpToDate.subscribe((reset) => {
      if (reset) {
        this.upToDateComponent.reset();
      }
    })
  }

  public setStreak(streak: any): void {
    this.streak = streak;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.streakForm.valid) {
      let formData = this.streakForm.getRawValue();
      this.streakService?.initCreateListener();
      if (formData?.action?.label && formData?.action?.label !== 'NO_ACTION') {
        formData.action = formData?.action?.value;
      } else if (formData?.action?.label && formData?.action?.label === 'NO_ACTION') {
        formData.action = undefined;
      }
      if (formData?.action === 'NO_ACTION') {
        formData.action = undefined;
        formData.actionDetail = undefined;
      }
      if ((formData?.action !== 'NO_ACTION' && !formData?.actionDetail) || (formData?.actionDetail && formData === 'NO_ACTION')) {
        formData.action = undefined;
        formData.actionDetail = undefined;
      }
      formData.sinceDate = this.utils.fromStringDateToIso(formData?.sinceDate);
      formData.upToDate = this.utils.fromStringDateToIso(formData?.upToDate, 1);
      if (this.streak) {
        formData.id = this.streak?.id;
      }
      this.streakService.createStreak(formData);
    }
  }

  public createQrValidation() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        if (this.templates?.length > 0) {
          this.selected = this.templates?.find(a => a.id === data?.goalTemplateId);
          this.streakForm.patchValue({['goalTemplateId']: data?.goalTemplateId});
        }
        this.setStreak(data);
      }
    })
  }

  public setFaq(event: any): void {
    this.streakForm.patchValue({['actionDetail']: event?.id ? String(event?.id) : null});
  }

  public selectTemplate(event: any): void {
    this.streakForm.patchValue({['goalTemplateId']: event?.id});
  }

  public selectAction(event: any): void {
    const action = this.streakForm?.getRawValue()?.action;
    if (!!action) {
      this.action = this.actions.find((listAction: any) => listAction === action);
      this.form.actionDetail?.setValidators([Validators.required])
      this.streakForm.patchValue({['action']: action});
    } else {
      this.action = 'NO_ACTION';
      this.streakForm.patchValue({['action']: 'NO_ACTION'});
      this.form.actionDetail?.clearValidators();
    }
  }

  public setSinceDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.streakForm.patchValue({['sinceDate']: convertedDate});
    const upToDate = this.streakForm?.getRawValue()?.upToDate;
    const sinceToDate = this.streakForm?.getRawValue()?.sinceDate;
    if (upToDate) {
      const upToDateString = upToDate.split("/");
      const sinceDate = sinceToDate.split("/");
      const upToDateObject = new Date(+upToDateString[2], upToDateString[1] - 1, +upToDateString[0]);
      const sinceDateObject = new Date(+sinceDate[2], sinceDate[1] - 1, +sinceDate[0]);
      if (upToDateObject < sinceDateObject) {
        this.streakForm.patchValue({['upToDate']: null});
      }
    }
  }

  public setUpToDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.streakForm.patchValue({['upToDate']: convertedDate});
  }

  public setImage(image: any): void {
    this.streakForm.patchValue({
      lockedImageUrl: image ? image?.originalUrl : undefined,
      lockedImageName: image ? image?.name : undefined,
      lockedImageType: image ? image?.mimeType : undefined,
    });
  }

  public setUnlockedImage(image: any): void {
    this.streakForm.patchValue({
      unlockedImageUrl: image ? image?.originalUrl : undefined,
      unlockedImageName: image ? image?.name : undefined,
      unlockedImageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.lockedImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkUnlockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.unlockedImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.streak?.imageUrl) {
      return {
        originalUrl: this.streak?.lockedImageUrl,
        name: [this.streak?.title.replace(/\s/g, "_"), 'image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkSelectedUnlockedImage(): object | undefined {
    if (this.streak?.imageUrl) {
      return {
        originalUrl: this.streak?.unlockedImageUrl,
        name: [this.streak?.title.replace(/\s/g, "_"), 'image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkGoalType(): void {
    if (this.form?.goalType?.value === 'FIXED') {
      this.form["target"].addValidators([Validators.required]);
      this.form["target"].updateValueAndValidity();
    } else {
      this.form["target"].clearValidators();
      this.form["target"].updateValueAndValidity();
    }
  }

  private initForm() {
    this.streakForm = this.formBuilder.group({
      goalType: ['FIXED'],
      action: ['NO_ACTION'],
      actionDetail: [null],
      goalTemplateId: [null, [Validators.required]],
      target: [null, [Validators.required]],
      creditsReward: [null, [Validators.required]],
      sinceDate: [null, [Validators.required]],
      upToDate: [null, [Validators.required]],
      autoRedeem: [false],
      validForCreditsBasedContests: [false],
    });
  }

  private patchFormWithEditingData() {
    this.streakForm.patchValue({
      goalType: this.streak?.goalType ? this.streak?.goalType : 'FIXED',
      action: this.streak?.action ? this.streak?.action : 'NO_ACTION',
      actionDetail: this.streak?.action && this.streak?.actionDetail ? this.streak?.actionDetail : null,
      goalTemplateId: this.streak?.templateId,
      target: this.streak?.target,
      creditsReward: this.streak?.creditsReward,
      sinceDate: this.datepipe.transform(this.streak?.sinceDate, 'dd/MM/yyyy'),
      upToDate: this.datepipe.transform(this.streak?.upToDate, 'dd/MM/yyyy'),
      autoRedeem: this.streak?.autoRedeem,
      validForCreditsBasedContests: this.streak?.validForCreditsBasedContests,
    });
    if (this.streak?.templateId && this.templates) {
      this.selected = this.templates?.find((template: any) => template.id === this.streak?.templateId);
    }
    if (!this.streak?.action) {
      this.action = 'NO_ACTION'
    } else if (this.streak?.action !== 'NO_ACTION') {
      this.action = this.streak?.action
    }
    this.checkGoalType();
    if (this.streak.duplicate) {
      this.streak = undefined;
    }
  }

  private extractTemplates(): void {
    this.searchTemplatesSubscription = this.streakTemplateService.getTemplates().subscribe((list: any) => {
      this.templates = list?.data?.length > 0 ? list?.data : [];
      if (this.streak?.templateId) {
        this.selected = this.templates?.find((template: any) => template.id === this.streak?.templateId);
      }
    });
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_STREAK, (reason: string | undefined) => {
      this.streakService?.removeStreakCreateSubscribe();
      this.initForm();
      this.formSubmitted = false;
      this.streak = undefined;
      this.action = undefined;
      this.selected = undefined;
      this.modalRef?.dismiss(reason);
    });
  }

}
