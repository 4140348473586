import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class NewsApiService {

  constructor(private http: HttpService) {
  }

  public deleteNews(news: any): Observable<any> {
    const newsToDelete = news?.map((news) => {
      return news.id
    });
    return this.http.delete(endpoints.news.delete, {body: {newsToDelete}});
  }

  public createNews(data: any): Observable<any> {
    if (!data?.id) {
      return this.http.post(endpoints.news.create, data);
    } else {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.news.edit, placeholders);
      return this.http.put(url, data);
    }
  }

  public getNewsList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.news.list, params);
  }
}
