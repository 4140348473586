import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class PartnersApiService {
  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.partners.list, params);
  }

}
