<app-table-topbar [type]="topBarType" [service]="companiesTeamsService" [showColumnPicker]="true"
                  (updateColumnsEvent)="selected = $event"
                  (openTeamCreate)="createTeam($event)"
                  (emitSearchTerm)="companiesTeamsService.searchTerm = $event"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="teamLeaderboardList$ | async as teams" class="wrapper">
    <table *ngIf="teams?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_ID)" class="column-sortable"
            sortable="id">
          <span class="me-3">{{'TEAM_ID' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.TEAM_NAME)" class="column-sortable"
            sortable="name">
          <span class="me-3">{{'TEAM_NAME' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_MEMBERS_COUNT)" class="column-sortable"
            sortable="userCount">
          <span class="me-3">{{'TEAM_MEMBERS_COUNT' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_EXPECTED_MEMBERS)" class="column-sortable"
            sortable="expectedMembers">
          <span class="me-3">{{'TEAM_EXPECTED_MEMBERS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_EXPECTED_MEMBERS_PERCENTAGE)" class="column-sortable"
            sortable="adhesionPercentage">
          <span class="me-3">{{'TEAM_EXPECTED_MEMBERS_PERCENTAGE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_SPORT_HOURS)" class="column-sortable"
            sortable="sportHours">
          <span class="me-3">{{'TEAM_SPORT_HOURS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_STEPS)" class="column-sortable"
            sortable="steps">
          <span class="me-3">{{'TEAM_STEPS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_KM_COVERED)" class="column-sortable"
            sortable="kmCovered">
          <span class="me-3">{{'TEAM_KM_COVERED' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_CHALLENGES)" class="column-sortable"
            sortable="challenges">
          <span class="me-3">{{'TEAM_CHALLENGES' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.TEAM_TRANSLATIONS)">
          <span class="me-3">{{'TEAM_TRANSLATIONS' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of teams; let i=index;">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_ID)" align="center" class="pointer fit-width">
          <span *ngIf="team?.teamId" class="badge badge-pill badge-soft-primary">{{team?.teamId}}</span>
          <app-missing-label *ngIf="!team?.teamId"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_NAME)" align="center" class="pointer">
          <span *ngIf="team?.name">{{team?.name}}</span>
          <app-missing-label *ngIf="!team?.name"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_MEMBERS_COUNT)" align="center" class="pointer fit-width">
          <span *ngIf="!!team?.userCount">{{team?.userCount}}</span>
          <app-missing-label *ngIf="!team?.userCount"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_EXPECTED_MEMBERS)" align="center" class="pointer">
          <span *ngIf="team?.expectedMembers">{{team?.expectedMembers}}</span>
          <app-missing-label *ngIf="!team?.expectedMembers"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_EXPECTED_MEMBERS_PERCENTAGE)" align="center" class="pointer">
          <span *ngIf="team?.adhesionPercentage">{{team?.adhesionPercentage}}</span>
          <app-missing-label *ngIf="!team?.adhesionPercentage"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_SPORT_HOURS)" align="center" class="pointer fit-width">
          <span *ngIf="!!team?.sportHours">{{team?.sportHours}}</span>
          <app-missing-label *ngIf="!team?.sportHours && team?.sportHours !== 0"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_STEPS)" align="center" class="pointer fit-width">
          <span *ngIf="!!team?.steps">{{team?.steps}}</span>
          <app-missing-label *ngIf="!team?.steps && team?.steps !== 0"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_KM_COVERED)" align="center" class="pointer fit-width">
          <span *ngIf="!!team?.kmCovered">{{team?.kmCovered}}</span>
          <app-missing-label *ngIf="!team?.kmCovered"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_CHALLENGES)" align="center" class="pointer fit-width">
          <span *ngIf="!!team?.challenges">{{team?.challenges + 1}}</span>
          <app-missing-label *ngIf="!team?.challenges"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.TEAM_TRANSLATIONS)" align="center" class="pointer fit-width">
          <img (click)="utils.openLink(lang.translationUrl)" *ngFor="let lang of team?.languageToTranslationUrl"
               alt="Header Language"
               class="me-2" height="16" ngbTooltip="{{lang.language}} translation" placement="bottom"
               src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="deleteTeam(team)" class="list-inline-item px-2">
                  <span ngbTooltip="Delete this team" class="pointer danger" placement="left"><i
                    class="bx bx bx-trash"></i></span>
            </li>
            <li (click)="showUsers(team)" class="list-inline-item px-2">
                  <span class="pointer" placement="left">
                    <i class="bx bxs-user-detail"></i>
                  </span>
            </li>
            <li (click)="sendUserNotificationModal(team)" class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Send push notification to all members of the team"
                        placement="left"><i
                    class="bx bx-send"></i></span>
            </li>
            <li (click)="editTeamModal(team)" class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Edit"
                        placement="left"><i
                    class="bx bx-pencil"></i></span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="teams?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="companiesTeamsService" [total$]="total$"
                       [showOnlyResultTotal]="false"></app-pagination-footer>
<app-user-uses-modal [showExtractResults]="false" [large]="true" [leaderboard]="false" [title]="'USERS_IN_THIS_TEAM'"
                     #sourceDetail></app-user-uses-modal>
<app-add-company-teams-modal #companyTeamsModal></app-add-company-teams-modal>
<app-edit-team #editTeam [companyId]="filters?.contextDetailsId" [service]="companiesTeamsService"></app-edit-team>
<app-delete-modal #deleteModal [service]="companiesTeamsService" [type]="deleteModalType.TEAM"></app-delete-modal>
