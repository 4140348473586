import {Component, Input, OnInit} from '@angular/core';
import {UnitOfMeasure} from "@type/common/unit-of-measure.type";
import {Router} from "@angular/router";

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent implements OnInit {
  @Input() title: string;
  @Input() link: string;
  @Input() icon: string;
  @Input() id: string;
  @Input() image: string;
  @Input() number: string | number;
  @Input() description: string;
  @Input() stats: boolean;
  @Input() button: boolean;
  @Input() unitOfMeasure: UnitOfMeasure;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  public detail(): void {
    if (this.stats) {
      this.router.navigate(['user-fitness-metrics', String(this.id)]);
    }
  }

}
