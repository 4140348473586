<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!competition && !duplicateCompetition">{{'CREATE_COMPETITION' | translate}}</h5>
    <h5 *ngIf="competition && !duplicateCompetition">{{'EDIT_COMPETITION' | translate}}</h5>
    <h5 *ngIf="!competition && duplicateCompetition">{{'DUPLICATE_COMPETITION' | translate}}</h5>
    <button [disabled]="(creatingCompetition$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_COMPETITION">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="competitionForm" autocomplete="off"
          class="needs-validation">
      <div class="row mb-3">
        <div class="col-md-3">
          <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
            <li [ngbNavItem]="1" (click)="activeId = 1">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2"  *ngIf="checkAlert('BASE')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Base and presentational</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-5">
                    <div class="row">
                      <div class="col-12" [ngClass]="{'col-12': !competition}">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="competitionName">{{'NAME' | translate}}</label>
                          <input [ngClass]="{'is-invalid': formSubmitted && form?.competitionName?.errors}" class="form-control"
                                 formControlName="competitionName" id="competitionName" type="text">
                          <div *ngIf="formSubmitted && form?.competitionName?.errors" class="invalid-feedback">
                            <span *ngIf="formSubmitted && form?.competitionName?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                            <span *ngIf="form?.competitionName?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.competitionName?.errors?.maxlength?.requiredLength} }}</span>
                            <span *ngIf="form?.competitionName?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.competitionName?.errors?.minlength?.requiredLength} }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="competitionType">Type</label>
                      <select class="form-select" id="competitionType" formControlName="competitionType" (change)="checkRestrictionValidators($event)" >
                        <option *ngFor="let type of types" [value]="type">{{type | translate}}</option>
                      </select>
                      <div *ngIf="formSubmitted && form?.competitionType?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.competitionType?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="active">Active</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="active"
                               id="active" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.active?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="form?.competitionType?.value !== 'COOPERATIVE'">
                    <div class="mb-3" [ngClass]="{'precondition-editor-invalid': formSubmitted && form.rules.errors}">
                      <label class="font-weight-bold" for="title">{{'RULES' | translate}}</label>
                      <ckeditor [editor]="Editor" formControlName="rules" [config]="{ removePlugins: ['EasyImage','ImageUpload','MediaEmbed'], mediaEmbed: {} }"
                                data="<p>Description of the company.</p>"></ckeditor>
                      <div *ngIf="formSubmitted && form.rules?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form.rules?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="form?.rules?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.rules?.errors?.maxlength?.requiredLength} }}</span>
                        <span *ngIf="form?.rules?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.rules?.errors?.minlength?.requiredLength} }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3" *ngIf="form?.competitionType?.value === 'COOPERATIVE'">
                    <label class="font-weight-bold mb-0" for="objectiveDescription">{{'DESCRIPTION' | translate}}</label>
                    <p class="card-title-desc mb-2">Describe the main purpose of this competition.</p>
                    <textarea [ngClass]="{'is-invalid': formSubmitted && form?.objectiveDescription?.errors}"
                              placeholder="With this competition we are trying to plant at least 10000 trees in madagascar because.. "
                              class="form-control description-textarea" formControlName="objectiveDescription" id="objectiveDescription" rows="4"></textarea>
                    <div *ngIf="formSubmitted && form?.objectiveDescription?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.objectiveDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.objectiveDescription?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.objectiveDescription?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.objectiveDescription?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.objectiveDescription?.errors?.minlength?.requiredLength} }}.</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="font-weight-bold mb-0" for="description">{{'SHORT_DESCRIPTION' | translate}}</label>
                      <p class="card-title-desc mb-2">Add a short description for this competition used in the web preview.</p>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control"
                             formControlName="description" id="description" type="text">
                      <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                        <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" [ngClass]="{'col-12': !duplicateCompetition}">
                    <div class="row mb-3">
                      <div class="col-12"  [ngClass]="{'col-12': !cardImage}">
                        <app-image-uploader (imageUploaded)="setCardImage($event)"
                                            [showImagePreview]="true"
                                            [name]="'IMAGE'"
                                            [imageErrors]="checkCardImageError()"
                                            [limit]="logoImageLimit" [previousSelectedImage]="checkSelectedCardImage()"></app-image-uploader>

                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" (click)="activeId = 2">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2"  *ngIf="checkAlert('RESTRICTIONS')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Restrictions and dates</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="" [ngClass]="{'col-12': selectedPrizeRestriction === 'none', 'col-6': selectedPrizeRestriction !== 'none'}">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="restriction">Restrict competition to</label>
                          <select class="form-select" id="restriction" [(ngModel)]="selectedPrizeRestriction" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let restriction of prizeRestriction" [value]="restriction">{{restriction | translate}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6" *ngIf="selectedPrizeRestriction === 'company'">
                        <app-select-company [formSubmitted]="formSubmitted"
                                            [basicSelect]="true"
                                            [selectedItems]="checkSelectedCompanies()"
                                            (selectedCompany)="selectCompany($event)"
                                            [parentForm]="competitionForm" [single]="true"></app-select-company>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <div class="mb-3">
                          <label class="font-weight-bold" for="rankingType">Type</label>
                          <select class="form-select" id="rankingType" formControlName="rankingType">
                            <option *ngFor="let type of competitionType" [value]="type">{{type | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.type?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.type?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                          <label class="font-weight-bold" for="teamScoreStrategy">Team score strategy</label>
                          <select   class="form-select" formControlName="teamScoreStrategy" id="teamScoreStrategy">
                            <option *ngFor="let teamScoreStrategy of teamScoreStrategies" [value]="teamScoreStrategy">{{teamScoreStrategy | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.teamScoreStrategy?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.teamScoreStrategy?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.teamScoreStrategy?.errors}">
                          <label class="font-weight-bold" for="metric">Metric selection</label>
                          <select  formControlName="metric" class="form-select" id="metric" (change)="checkAllowsChallengeCredit($event)">
                            <option *ngFor="let metric of fitnessMetric" [value]="metric">{{metric | translate}}</option>
                          </select>
                          <div *ngIf="formSubmitted && form?.metric?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.metric?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-3" [ngClass]="{'is-invalid-select': formSubmitted && form?.allowsOptionalJoin?.errors}">
                          <label class="font-weight-bold" for="allowsOptionalJoin">Allows optional join</label>
                          <select [disabled]="competition && !duplicateCompetition" formControlName="allowsOptionalJoin" class="form-select" id="allowsOptionalJoin">
                            <option *ngFor="let optional of optionalJoinCondition" [value]="optional">
                              {{optional | lowercase}}
                            </option>
                          </select>
                          <div *ngIf="formSubmitted && form?.allowsOptionalJoin?.errors" class="invalid-feedback d-block">
                            <span *ngIf="formSubmitted && form?.allowsOptionalJoin?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="teamScoreStrategy">Visible from</label>
                      <app-date-time-picker [disableTimePicker]="form?.metric?.value === 'VIRTUOSITY'" [datetime]="form?.visibleFromDay?.value" [hour]="form?.visibleFromHour?.value" [invalid]="formSubmitted && form?.visibleFromDay?.errors" (dateSelected)="updateDate($event, 'visibleFromDay')"></app-date-time-picker>
                      <div *ngIf="formSubmitted && form?.visibleFromDay?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.visibleFromDay?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="teamScoreStrategy">Visible until</label>
                      <app-date-time-picker [disableTimePicker]="form?.metric?.value === 'VIRTUOSITY'" [datetime]="form?.visibleUntilDay?.value" [hour]="form?.visibleUntilHour?.value" [invalid]="formSubmitted && form?.visibleUntilDay?.errors"  (dateSelected)="updateDate($event, 'visibleUntilDay')"></app-date-time-picker>
                      <div *ngIf="formSubmitted && form?.visibleUntilDay?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.visibleUntilDay?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="teamScoreStrategy">Starts at</label>
                      <app-date-time-picker [disableTimePicker]="form?.metric?.value === 'VIRTUOSITY'" [datetime]="form?.startsOn?.value" [hour]="form?.startsAtHour?.value" [invalid]="formSubmitted && form?.startsOn?.errors" (dateSelected)="updateDate($event, 'startsOn')"></app-date-time-picker>
                      <div *ngIf="formSubmitted && form?.startsOn?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.startsOn?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="teamScoreStrategy">Ends at</label>
                      <app-date-time-picker [disableTimePicker]="form?.metric?.value === 'VIRTUOSITY'" [datetime]="form?.endsOn?.value" [hour]="form?.endsAtHour?.value" [invalid]="formSubmitted && form?.endsOn?.errors"  (dateSelected)="updateDate($event, 'endsOn')"></app-date-time-picker>
                      <div *ngIf="formSubmitted && form?.endsOn?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.endsOn?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="teamScoreStrategy">Accept results until</label>
                      <app-date-time-picker [disableTimePicker]="form?.metric?.value === 'VIRTUOSITY'" [datetime]="form?.acceptsResultsUntilDay?.value" [hour]="form?.acceptsResultsUntilHour?.value" [invalid]="formSubmitted && form?.acceptsResultsUntilDay?.errors"  (dateSelected)="updateDate($event, 'acceptsResultsUntilDay')"></app-date-time-picker>
                      <div *ngIf="formSubmitted && form?.acceptsResultsUntilDay?.errors" class="invalid-feedback d-block">
                        <span *ngIf="formSubmitted && form?.acceptsResultsUntilDay?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-3">
                    <app-select-locale [parentForm]="competitionForm"></app-select-locale>
                  </div>
                  <div class="col-3" [hidden]="form?.competitionType?.value === 'COOPERATIVE'">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="leaderboardByPowerLevel">Split leaderboards by power level</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.leaderboardByPowerLevel?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="leaderboardByPowerLevel"
                               id="leaderboardByPowerLevel" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="leaderboardByPowerLevel">
                              <span *ngIf="form?.leaderboardByPowerLevel?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.leaderboardByPowerLevel?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="allowsTeamChange">Allow team change</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.allowsTeamChange?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="allowsTeamChange"
                               id="allowsTeamChange" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="allowsTeamChange">
                              <span *ngIf="form?.allowsTeamChange?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.allowsTeamChange?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="allowsTeamChange">Allows power bar level change ( Virtuosity )</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.allowsPowerbarLevelChange?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="allowsPowerbarLevelChange"
                               id="allowsPowerbarLevelChange" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="allowsTeamChange">
                              <span *ngIf="form?.allowsPowerbarLevelChange?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.allowsPowerbarLevelChange?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" *ngIf="form?.metric?.value === 'CREDITS'">
                    <div class="mb-3">
                      <label class="font-weight-bold font-size-11" for="allowsChallengeCredit">Allows challenge credits</label>
                      <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                        <input [checked]="form?.allowsChallengeCredit?.value" autocomplete="off" class="form-check-input m-0 pointer"
                               formControlName="allowsChallengeCredit"
                               id="allowsChallengeCredit" type="checkbox">
                        <label class="form-check-label font-size-11 ms-3" for="allowsTeamChange">
                              <span *ngIf="form?.allowsChallengeCredit?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                          <span *ngIf="!form?.allowsChallengeCredit?.value"
                                class="badge badge-pill badge-soft-danger font-size-11">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="form?.metric?.value === 'CREDITS' || form?.metric?.value === 'VIRTUOSITY'">
                    <div class="mb-3">
                      <label class="font-weight-bold">Configurable fitness metrics</label>
                      <div class="single-ng-select">
                        <ng-select
                            bindLabel="label"
                            bindValue="value"
                            [items]="configurableFitnessMetrics"
                            [multiple]="true"
                            formControlName="configurableFitnessMetrics"></ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" (click)="activeId = 3" *ngIf="form?.competitionType?.value === 'COOPERATIVE'">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block danger me-2"  *ngIf="checkAlert('OBJECTIVES')">
                  <i class="mdi mdi-alert-circle-outline"></i>
                </span>
                <span class="d-inline-block">Objectives - Place - Partner</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-4 mb-3">
                    <label class="font-weight-bold" for="partnerId">{{'PARTNER' | translate}}</label>
                    <select  class="form-select" formControlName="partnerId" id="partnerId">
                      <option *ngFor="let partner of partners" [value]="partner?.id">{{partner?.title | translate}}</option>
                    </select>
                    <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.partner?.errors?.required">
                      <span>{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-3 single-ng-select">
                      <label class="font-weight-bold">Places</label>
                      <ng-select [clearable]="true" [items]="places"  bindLabel="name"
                                 bindValue="id" [multiple]="false" formControlName="placeId">
                        <ng-template let-item="item" ng-label-tmp>
                          <span>{{ item?.name }}</span>
                        </ng-template>
                        <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                          <span class="font-weight-semibold ms-2">{{item?.name}}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-3 single-ng-select">
                      <label class="font-weight-bold">Benefits</label>
                      <ng-select [clearable]="true" [items]="benefits"  bindLabel="title"
                                 bindValue="id" [multiple]="true" formControlName="benefitIds">
                        <ng-template let-item="item" ng-label-tmp>
                          <span>{{ item?.title }}</span>
                        </ng-template>
                        <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                          <span class="font-weight-semibold ms-2">{{item?.title}}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex objective-header">
                      <label class="font-weight-bold" for="partnerId">{{'OBJECTIVES' | translate}}</label>
                      <span class="btn btn-primary" (click)="addObjectiveToForm()">Add an objective</span>
                    </div>
                    <div formArrayName="objectives">
                      <div class="row max-height-50">
                        <div class="col-12 " *ngFor="let group of objectives.controls; let i = index; let first = first;" [formGroupName]="i">
                          <div class="step objective-section">
                            <div class="row">
                              <div class="col-8 mb-3">
                                <p class="font-weight-bold mb-0">#{{i+1}} Objective</p>
                              </div>
                              <div class="col-4 mb-3 d-flex justify-content-end" *ngIf="!first">
                                <button (click)="removeObjectiveFromForm(i)" class="btn close-modal-button">
                                  <i class="mdi mdi-close-circle-outline"></i>
                                </button>
                              </div>
                              <div class="col-12 mb-3">
                                <label class="font-weight-bold mb-0" for="title">{{'TITLE' | translate}}</label>
                                <p class="card-title-desc mb-2">The title of the objective. Specify the target for the on reach ones</p>
                                <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.title?.errors}" placeholder="Plant 1000 trees in Madagascar" class="form-control" formControlName="title" id="title" type="text">
                                <div *ngIf="formSubmitted && group?.controls?.title?.errors" class="invalid-feedback d-block">
                                  <span *ngIf="group?.controls?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                  <span *ngIf="group?.controls?.title?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: group?.controls?.title?.errors?.maxlength?.requiredLength} }}</span>
                                  <span *ngIf="group?.controls?.title?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: group?.controls?.title?.errors?.minlength?.requiredLength} }}.</span>
                                </div>
                              </div>
                              <div class="col-12 mb-3">
                                <label class="font-weight-bold mb-0" for="description">{{'DESCRIPTION' | translate}}</label>
                                <p class="card-title-desc mb-2">Describe the main purpose of this objective.</p>
                                <textarea [ngClass]="{'is-invalid': formSubmitted && group?.controls?.description?.errors}" placeholder="With this objective we are trying to plant at least 10000 trees in madagascar because.. " class="form-control description-textarea" formControlName="description" id="description" rows="4"></textarea>
                                <div *ngIf="formSubmitted && group.controls?.description?.errors" class="invalid-feedback d-block">
                                  <span *ngIf="formSubmitted && group.controls?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                  <span *ngIf="group?.controls?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: group?.controls?.description?.errors?.maxlength?.requiredLength} }}</span>
                                  <span *ngIf="group?.controls?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: group?.controls?.description?.errors?.minlength?.requiredLength} }}.</span>
                                </div>
                              </div>
                              <div class="col-12 mb-3">
                                <label class="font-weight-bold mb-0" for="target">{{'Target' | translate}}</label>
                                <p class="card-title-desc mb-2">It will match with the fitness metric result that need to bee reached</p>
                                <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.target?.errors}" placeholder="1000000" class="form-control" formControlName="target" id="target" type="number">
                                <div *ngIf="formSubmitted && group.controls?.target?.errors" class="invalid-feedback d-block">
                                  <span *ngIf="formSubmitted && group.controls?.target?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4" (click)="activeId = 4" *ngIf="form?.competitionType?.value !== 'COOPERATIVE'">
              <a ngbNavLink class="mb-2">
                <span class="d-inline-block">Prizes</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="font-weight-bold" for="restriction">Copy prizes from</label>
                      <select class="form-select" id="restriction" (change)="loadPrizeForCompetition($event)" [(ngModel)]="selectedCopyPrizeRestriction" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let restriction of copyPrizesFrom" [value]="restriction">{{restriction | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <app-select-competition *ngIf="selectedCopyPrizeRestriction === 'competition'" [formSubmitted]="formSubmitted"
                                            [basicSelect]="true" [single]="true"
                                            [selectedItems]="checkSelectedCompetition()"
                                            (selectedCompetition)="selectCompetition($event)"
                                            [parentForm]="competitionForm"></app-select-competition>
                  </div>
                  <div class="col-12" *ngIf="selectedCopyPrizeRestriction === 'competition' && prizes?.length > 0">
                    <div class="mb-3">
                      <label class="font-weight-bold mb-1" for="restriction">Prize list <span class="font-size-11">({{prizes?.length}})</span></label>
                      <p class="card-title-desc mb-3">
                        Select the prize that you want to duplicate in the new competition. Remember all the prizes will be duplicated
                        as <span class="badge badge-pill badge-soft-danger">{{'INACTIVE' | translate | uppercase}}</span> and if the type
                        is <span class="badge badge-pill badge-soft-danger">{{'CODE_REDEMPTION' | translate | uppercase}}</span>
                        without any code.
                      </p>
                      <div class="modal-content-medium">
                        <app-prizes-table (selectedPrizes)="setSelectedPrizes($event)" [prizes]="prizes"></app-prizes-table>
                      </div>
                      <p class="card-title-desc font-weight-bold">
                        Selected prizes: <span class="badge badge-pill badge-soft-primary">{{selectedPrizes?.length}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="selectedCopyPrizeRestriction === 'competition' && prizes?.length === 0">
                    <div class="text-center mt-5">
                      <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div [ngbNavOutlet]="verticalNav"></div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="checkNextActionBtn();" *ngIf="!showAreYouSure && !competition"
                  class="btn btn-warning">Create
          </button>
          <button (click)="checkNextActionBtn();" *ngIf="!showAreYouSure && competition"
                  class="btn btn-warning">Edit
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button *ngIf="showAreYouSure" [disabled]="(creatingCompetition$ | async)" class="btn btn-warning me-2" type="submit">
            <i *ngIf="creatingCompetition$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingCompetition$ | async) && !competition">{{'CREATING' | translate}}</span>
            <span *ngIf="(creatingCompetition$ | async) && competition">{{'EDITING' | translate}}</span>
            <span [hidden]="(creatingCompetition$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingCompetition$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
