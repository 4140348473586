import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})

export class FormsService {

  constructor(private translate: TranslateService) {}

  public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray, translateField?: string): string[] {
    var invalidControls:string[] = [];
    let recursiveFunc = (form:FormGroup|FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(!!translateField ? this.translate.instant(translateField+field) : field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    console.error(invalidControls);
    return invalidControls;
  }

}

