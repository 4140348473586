import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserElementInterface} from "@interface/user/user-element.interface";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  @Input() type?: DeleteModalTypeEnum;
  @Input() hideBannedColumn: boolean;
  @Input() canBeRemoved: boolean;
  @Input() leaderboard?: boolean;
  @Input() light: boolean;
  @Input() users: UserElementInterface[];
  @Output() removedUser = new EventEmitter();
  public deleteModalTypeEnum = DeleteModalTypeEnum;

  constructor(private eventService: EventService, private router: Router) {
  }

  ngOnInit(): void {
  }

  public removeUser(selectedUser: UserElementInterface): void {
    if (this.type && this.type === DeleteModalTypeEnum.NOTIFICATION) {
      this.removedUser.emit(selectedUser);
    }
    if (this.users?.length > 1) {
      if (this.type && this.type === DeleteModalTypeEnum.USER) {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_DELETE_LIST, selectedUser)
      } else if (this.type && this.type === DeleteModalTypeEnum.SCHEDULED_NOTIFICATION) {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_NOTIFICATION_LIST, selectedUser)
      } else {
        this.eventService.broadcast(EventEnum.REMOVE_USER_FROM_BAN_LIST, selectedUser)
      }
    }
  }

  public extractUserId(user: any): string {
    if (user?.userId) {
      return user?.userId;
    } else if (user?.profile?.id) {
      return user?.profile?.id;
    } else if (user?.id) {
      return user?.id;
    }
  }

  public extractUserNickname(user: any): string {
    if (user?.nickname) {
      return user?.nickname;
    } else if (user?.profile?.nickname) {
      return user?.profile?.nickname;
    }
  }

  public extractUserFullName(user: UserElementInterface): string {
    if (user?.fullName) {
      return user?.fullName;
    } else {
      let name: string[] = [];
      name.push(user?.profile?.firstName);
      name.push(user?.profile?.lastName);
      name = name.filter(function (string) {
        return !!string;
      });
      return name.join(" ");
    }
  }

  public userDetail(user: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(this.extractUserId(user))])
    );
    window.open(url, '_blank');
  }

}
