import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {EventEnum} from "@enum/event/event.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {UserListService} from "@service/users/user-list.service";

@Component({
  selector: 'app-add-remove-whitelist',
  templateUrl: './add-remove-whitelist.component.html',
  styleUrls: ['./add-remove-whitelist.component.scss']
})
export class AddRemoveWhitelistComponent implements OnInit {
  public showAreYouSure: boolean = false;
  public title: string | undefined = undefined;
  public titleParams: string | undefined = undefined;
  public working$: Observable<boolean>;
  public add: boolean = false;
  public user: UserElementInterface[] | undefined = undefined;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private closeModalSubscription: Subscription;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, private eventService: EventService, private userListService: UserListService) {
  }

  ngOnInit(): void {
    this._closeModal();
  }

  public open(data: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.add = data?.add;
      if (Array.isArray(data?.user)) {
        this.user = data?.user;
      } else {
        this.user = [data?.user];
      }
      this.working$ = this.userListService.working$;
    })
  }

  public submit(): void {
    if (this.add) {
      this.userListService.initAddToWhiteListListener();
      this.userListService.addRemoveToWhiteList(this.user);
    } else {
      this.userListService.initRemoveFromWhiteListListener();
      this.userListService.addRemoveToWhiteList(this.user);
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_ADD_REMOVE_WHITELIST_MODAL, (reason: string | undefined) => {
      this.showAreYouSure = false;
      this.user = undefined;
      this.add = undefined;
      this.working$ = undefined;
      this.userListService.removeWorkingSubscription();
      this.modalRef?.dismiss(reason);
    });
  }
}
