import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {catchError, Observable, of} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class CompetitionsApiService {

  constructor(private http: HttpService) {
  }

  public removeUser(data: any): Observable<any> {
    const placeholders = [{
      placeholder: EndpointParamEnum.ID,
      value: data?.data?.id
    }, {placeholder: EndpointParamEnum.PID, value: data?.data?.userId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.competitions.removeUser, placeholders);
    return this.http.post(url, {});
  }

  public deleteCompetition(competition: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: competition?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.competitions.delete, placeholders);
    return this.http.delete(url);
  }

  public getCompetitionList(filters: any, paged?: boolean): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    if (paged) {
      return this.http.get<any>(endpoints.competitions.paged, params);
    } else {
      return this.http.get<any>(endpoints.competitions.list, params);
    }
  }

  public getCompetitionData(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.competitions.detail, placeholders);
    return this.http.get(url, null);
  }

  public getCompetitionTeamLeaderboard(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.competitions.teamLeaderboard, params);
  }

  public getCompetitionBans(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.competitions.bans, params);
  }

  public getCompetitionIndividualLeaderboard(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.competitions.individualLeaderboard, params);
  }

  public getPrizeList(filters: any, companyCompetitionFilter?: any): Observable<any> {
    filters = {...filters, competitionId: companyCompetitionFilter?.competitionId};
    filters.size = 1000;
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.competition, params);
  }

  public createPrize(params: any): Observable<any> {
    params.expiresOn = new Date(params?.expiresAt);
    if (params?.precondition?.length > 0) {
      params.preconditions = params?.precondition?.map((precondition: any) => {
        return precondition?.id;
      });
    }
    if (params?.expiresOn && !(params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn.split("/").reverse().join("-");
    } else if (params?.expiresOn && (params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn?.toISOString()?.split('T')[0];
    }
    if (params?.expiresAt && (params?.expiresAt instanceof String || typeof params?.expiresAt === 'string') && params?.expiresAt?.includes('T')) {
      params.expiresAt = params?.expiresAt?.split('T')[0];
    } else if (params?.expiresAt && (typeof params?.expiresAt === 'number' || params?.expiresAt instanceof Number)) {
      const date = new Date(params.expiresAt);
      params.expiresAt = date?.toISOString()?.split('T')[0];
    }
    if (params.prizeContextDescription && params.prizeContextTitle) {
      params.prizeContext = {
        title: params.prizeContextTitle,
        description: params.prizeContextDescription
      }
    }
    params.id = null;
    params.prizeContextTitle = params?.prizeContext?.title;
    params.fromPositionInclusive = params?.leaderboardPosition?.fromPositionInclusive;
    params.toPositionInclusive = params?.leaderboardPosition?.toPositionInclusive;
    params.bracket = params?.bracket?.charAt(0)?.toUpperCase() + params?.bracket?.slice(1)?.toLowerCase();
    params.active = false;
    params.createdAt = null;
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.newCompetitionId}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.createForCompetition, placeholders);
    return this.http.post(url, params).pipe(catchError(error => of(error)));
  }

  public postponeCompetition(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.competitions.postpone, placeholders);
    return this.http.post(url, params);
  }

  public createEditCompetition(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.competitions.edit, placeholders);
      params.id = null;
      return this.http.put(url, this.http.removeNullValues(params));
    } else {
      return this.http.post(endpoints.competitions.create, this.http.removeNullValues(params));
    }
  }
	
	public recalculateCounters(competitionId: number, persist: boolean): Observable<any> {
		return this.http.post(endpoints.competitions.recalculate, {itemId: competitionId, persistResult: persist});
	}
}
