import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from "rxjs";
import {EventService} from "@service/common/event.service";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {Router} from "@angular/router";
import {EventEnum} from "@enum/event/event.enum";
import {CompaniesTeamsService} from "@service/companies/companies-teams.service";

@Component({
  selector: 'app-add-company-teams-modal',
  templateUrl: './add-company-teams-modal.component.html',
  styleUrls: ['./add-company-teams-modal.component.scss']
})
export class AddCompanyTeamsModalComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  public teams: any[] = [];
  public newTeams: string | undefined;
  public company: any = undefined;
  public showAddTeams = true;
  public loading = false;
  private closeModalSubscription: Subscription;
  constructor(private companiesTeamsService: CompaniesTeamsService, private modalService: NgbModal, private eventService: EventService,
              public utils: UtilsService, private toastService: ToastService, private router: Router) { }

  ngOnInit(): void {
    this._closeModal();
  }


  public open(company: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.company = company;
    })
  }

  public userDetail(user: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(user?.id)])
    );
    window.open(url, '_blank');
  }


  public checkTextarea() {
    const list = this.newTeams?.split('\n')?.filter(String);
    return !list || list?.length === 0;
  }

  public addCodes() {
    this.loading = true;
    this.companiesTeamsService.addCompanyTeams({names: this.newTeams.split('\n'), companyId: this.company?.id}).subscribe((result: any) => {
      if (result?.length > 0) {
        this.toastService.show('Teams added successfully', {classname: 'bg-success text-light'});
        this.closeModalAndSearch(null);
      } else {
        this.loading = false;
        this.toastService.show('There was an error adding the Teams', {classname: 'bg-danger text-light'});
      }
    });
  }

  public copied(event: any): void {
    this.toastService.show('Copied - ' + event?.content, {classname: 'bg-secondary text-light'});
  }

  private resetAllVariables() {
    this.company = undefined;
    this.newTeams = undefined;
    this.loading = false;
    this.showAddTeams = true;
    this.teams = [];
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_COMPANY_TEAMS_MODAL, (reason: string | undefined) => {
      this.closeModalAndSearch(reason);
    });
  }

  private closeModalAndSearch(reason: string) {
    this.resetAllVariables();
    this.companiesTeamsService._search$.next();
    this.modalRef?.dismiss(reason);
  }
}
