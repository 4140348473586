export const categories = {
  data: [
    {
      id: 1,
      title: 'Sostenibilità',
      description: 'Una categoria di sostenibilità',
      restrictsLocales: ['it'],
      note: 'Questa è una categoria di test'
    }
  ],
  size: 1
}

export const content = {
  data: [
    {
      "title": "FILE PDF",
      "description": "Export content from your designs for translation into Crowdin in a click. Get translations back to preview how different languages fit into your designs",
      "restrictsLocales": ["it"],
      "note": "Questa è una pagina per snam di test",
      "cardImageUrl": "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      "detailsImageUrl": "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      "type": "PDF",
      "active": true,
      "category": "[object Object]",
      "restriction": "ALL_USERS",
      "companies": null,
      "competitions": null,
      "dataContent": "https://ucarecdn.com/5c672e0e-5aeb-4613-b145-78fde46acc4b/",
      "dataContentLanding": null,
      "id": 1
    },
    {
      "title": "FILE VIDEO",
      "description": "Export content from your designs for translation into Crowdin in a click. Get translations back to preview how different languages fit into your designs",
      "restrictsLocales": ["it"],
      "note": "Questa è una pagina per snam di test",
      "cardImageUrl": "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      "detailsImageUrl": "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      "type": "VIDEO",
      "active": true,
      "category": "[object Object]",
      "restriction": "ALL_USERS",
      "companies": null,
      "competitions": null,
      "dataContent": "https://www.youtube.com/watch?v=G3O-zG_9sWk",
      "dataContentLanding": null,
      "id": 2
    },
    {
      active: true,
      cardImageUrl: "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      category: "[object Object]",
      companies: null,
      competitions: null,
      dataContent: "<h1 class=\"ql-align-center\">Quill Rich Text Editor</h1><p><br></p><p>Quill is a free, <a href=\"https://github.com/quilljs/quill/\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: transparent;\">open source</a> WYSIWYG editor built for the modern web. With its <a href=\"https://quilljs.com/docs/modules/\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: transparent;\">modular architecture</a> and expressive <a href=\"https://quilljs.com/docs/api/\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: transparent;\">API</a>, it is completely customizable to fit any need.</p><p><br></p><iframe class=\"ql-video ql-align-center\" frameborder=\"0\" allowfullscreen=\"true\" src=\"https://player.vimeo.com/video/253905163\" height=\"280\" width=\"500\"></iframe><p><br></p><h2 class=\"ql-align-center\">Getting Started is Easy</h2><p><br></p>",
      dataContentLanding: null,
      description: "Export content from your designs for translation into Crowdin in a click. Get translations back to preview how different languages fit into your designs",
      detailsImageUrl: "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      id: 3,
      note: "Questa è una pagina per snam di test",
      restriction: "ALL_USERS",
      restrictsLocales: ['it'],
      title: "ARTICOLO DI BLOG",
      type: "BLOG_ARTICLE"
    },
    {
      active: true,
      cardImageUrl: "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      category: "[object Object]",
      companies: null,
      competitions: null,
      dataContent: null,
      dataContentLanding: [{
        "numbering": 1,
        "title": "Testadssadsadsa",
        "description": "asdsaadsadsads",
        "id": null,
        "image": "https://ucarecdn.com/4da8940b-9522-4e29-b939-bd21077b3869",
        "imagePosition": "LEFT"
      }, {
        "numbering": 2,
        "title": "sdasdasddasadsadsadsads",
        "description": "dsfdsffsdsfdsfsfdsdfsfdsdf",
        "id": null,
        "image": "https://ucarecdn.com/4da8940b-9522-4e29-b939-bd21077b3869",
        "imagePosition": "RIGHT"
      }, {
        "numbering": 3,
        "title": "fdfdggfddfggdfdgfdgf",
        "description": "rteertrteetretretrert",
        "id": null,
        "image": "https://ucarecdn.com/4da8940b-9522-4e29-b939-bd21077b3869",
        "imagePosition": "BOTTOM"
      }],
      description: "Export content from your designs for translation into Crowdin in a click. Get translations back to preview how different languages fit into your designs",
      detailsImageUrl: "https://web.healthyvirtuoso.com/assets/images/common/forest-bg.jpg",
      id: 4,
      note: "Questa è una pagina per snam di test",
      restriction: "ALL_USERS",
      restrictsLocales: ['it'],
      title: "LANDING PAGE",
      type: "LANDING_PAGE"
    }
  ],
  size: 2
}
