<div *ngIf="!single && (list$ | async) as companies" class="row mb-3">
  <label class="col-md-2 col-form-label font-weight-bold">Select prizes</label>
  <div class="col-md-4">
    <ng-select
      (change)="selectCompanies($event)"
      [(ngModel)]="selected"
      [items]="companies"
      [multiple]="!single"
      bindLabel="description"></ng-select>
    <ng-template let-item="item" ng-label-tmp>
      <span>{{ item.id + ' - ' + item.description }}</span>
    </ng-template>
  </div>
</div>
<div *ngIf="parentForm && single && (list$ | async) as companies" [formGroup]="parentForm" class="col-md-12">
  <div class="mb-3 single-ng-select">
    <label class="font-weight-bold">Select prizes</label>
    <p class="card-title-desc mb-1">
      Select the prize<span *ngIf="filtered"> of {{filtered}}</span> that the notification will open.
    </p>
    <ng-select (change)="selectCompanies($event)" [items]="companies" bindLabel="description"
               formControlName="actionDetail"></ng-select>
    <div *ngIf="formSubmitted && form.actionDetail.errors" class="invalid-feedback">
      <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
