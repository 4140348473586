import {AfterViewInit, Component, EventEmitter, Inject, Output} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {AuthenticationService} from '@service/common/auth.service';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '@service/common/language.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {KeycloakService} from "keycloak-angular";
import {ClipboardService} from "ngx-clipboard";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements AfterViewInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  listLang = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it'}
  ];
  openMobileMenu: boolean;
  public user$: Observable<UserElementInterface>;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
              private authService: AuthenticationService,
              public languageService: LanguageService,
              protected readonly keycloak: KeycloakService,
              private _clipboardService: ClipboardService,
              private toastService: ToastService,
              public translate: TranslateService,
              public _cookiesService: CookieService) {
    this.user$ = authService.currentUser$;
    this.initializeCurrentLanguage();
  }

  ngAfterViewInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  public copyToken(): void {
    this.keycloak.getToken().then((token: string) => {
      this._clipboardService.copy(token)
      this.toastService.show('Token copied', {classname: 'bg-secondary text-light'});
    });
  }

  public logout() {
    this.authService.logout();
  }

  public openUrl(url: string): void {
    window.open(url, '_blank');
  }

  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }

  private initializeCurrentLanguage() {
    this.cookieValue = this._cookiesService.get('lang');
    if (!this.cookieValue || this.cookieValue === "") {
      this.setLanguage('English', 'en', 'assets/images/flags/us.jpg')
    }
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }
}
