<app-diagnostic-topbar (dateRangeSelected)="setDate($event)" [type]="type" [userId]="userId"></app-diagnostic-topbar>
<div class="col-12 p-0 full-height-table">
  <div *ngIf="!loading && activities?.length > 0" class="wrapper">
    <table
      class="table table-bordered align-middle table-nowrap table-hover table-striped-bg mb-0 datatables recorded-activities"
      id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th class="column-sortable"
            sortable="id">
          <span class="me-3">{{'ACTIVITY' | translate}}</span>
        </th>
        <th class="column-sortable"
            sortable="email">
          <span class="me-3">{{'DURATION_MINUTES' | translate}}</span>
        </th>
        <th
          class="column-sortable" sortable="fullName">
          <span class="me-3">{{'DISTANCE_METERS' | translate}}</span>
        </th>
        <th class="column-sortable">
          <span class="me-3">{{'BURNED_KCAL' | translate}}</span>
        </th>
      </tr>
      </thead>
      <tbody *ngFor="let activity of activities; let i=index;">
      <tr (click)="activity.expand = !activity.expand">
        <td align="center" class="pointer fit-width">
                <span class="btn btn-primary font-size-11 show-hide-users">
                  <i *ngIf="activity.expand" class="mdi mdi-close-circle-outline me-2"></i>
                  <i *ngIf="!activity.expand" class="bx bx-plus-circle me-2"></i>
                  <span *ngIf="!activity.expand">Show single hours</span>
                  <span *ngIf="activity.expand">Hide single hours</span>
                </span>
        </td>
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">{{activity?.name}}</span>
        </td>
        <td class="pointer">
          <span>{{activity?.durationMinutes}}</span>
        </td>
        <td class="pointer">
          <span>{{activity?.distanceMeters}}</span>
        </td>
        <td class="pointer">
          <span>{{activity?.activeKiloCalories}}</span>
        </td>
      </tr>
      <tr *ngIf="activity.expand" class="sub-table no-bg">
        <td align="center"></td>
        <td class="no-padding" colspan="15">
          <table class="table table-bordered align-middle table-nowrap mb-0 datatables no-bg">
            <thead class="bg-dark text-white">
            <tr>
              <th>
                <span class="me-3">{{'START_DATE_HOUR' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'END_DATE_HOUR' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'DURATION_MINUTES' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'DISTANCE_METERS' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'BURNED_KCAL' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'DEVICE' | translate}}</span>
              </th>
              <th>
                <span class="me-3">{{'SOURCE' | translate}}</span>
              </th>
            </tr>
            </thead>
            <tbody class="no-bg">
            <tr *ngFor="let slot of activity?.data" class="no-bg">
              <td align="center" class=" fit-width">
                <span *ngIf="slot?.y?.length === 2">{{slot?.y[0] | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="slot?.y?.length !== 2"></app-missing-label>
              </td>
              <td align="center" class=" fit-width">
                <span *ngIf="slot?.y?.length === 2">{{slot?.y[1] | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="slot?.y?.length !== 2"></app-missing-label>
              </td>
              <td class="pointer">
                <span>{{slot?.info?.kpis?.durationMinutes}}</span>
              </td>
              <td>
                <span>{{slot?.info?.kpis?.distanceMeters}}</span>
              </td>
              <td>
                <span>{{slot?.info?.kpis?.activeKiloCalories}}</span>
              </td>
              <td>
                <span>{{slot?.info?.key?.deviceIdentifier}}</span>
              </td>
              <td>
                <span>{{slot?.info?.key?.sourceIdentifier}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!loading && activities?.length === 0" class="col-lg-12">
    <div class="">
      <div class="text-center mt-5">
        <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="col-12 p-0">
    <div class="wrapper">
      <app-loader [table]="true"></app-loader>
    </div>
  </div>
</div>
