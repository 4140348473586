<div class="operation-modal-header">
  <h5 *ngIf="!operator">{{'CREATE_OPERATOR' | translate}}</h5>
  <h5 *ngIf="operator && !operator?.duplicate">{{'EDIT_OPERATOR' | translate}}: {{operator?.email}}</h5>
  <h5 *ngIf="operator && operator?.duplicate">{{'DUPLICATE_OPERATOR' | translate}}: {{operator?.email}}</h5>
  <div class="d-flex">
    <button [disabled]="(addingOperator$ | async)" class="btn close-modal-button" closeModal="CLOSE_OPERATOR_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="operatorForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold" for="email">{{'EMAIL' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.email?.errors}" class="form-control"
                 formControlName="email" id="email" type="text">
          <div *ngIf="formSubmitted && form?.email?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.email?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.email?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.email?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.email?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.email?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold">{{'ROLE' | translate}}</label>
          <select class="form-select" formControlName="roleCode" id="roleCode" [ngClass]="{'is-invalid': form.roleCode.errors}">
            <option *ngFor="let option of roles" [selected]="option"
                    [value]="option">{{option | translate}}</option>
          </select>
          <div *ngIf="form.roleCode.errors" class="invalid-feedback">
            <span *ngIf="form.roleCode.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form.roleCode.errors.max">This max value for hour is 24.</span>
            <span *ngIf="form.roleCode.errors.min">This min value for hour is 1.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold">{{'ROLE_LEVEL' | translate}}</label>
          <select class="form-select" formControlName="roleLevel" id="roleLevel" [ngClass]="{'is-invalid': form.roleLevel.errors}">
            <option *ngFor="let option of roleLevels" [selected]="option"
                    [value]="option">{{option | translate}}</option>
          </select>
          <div *ngIf="form.roleLevel.errors" class="invalid-feedback">
            <span *ngIf="form.roleLevel.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form.roleLevel.errors.max">This max value for hour is 24.</span>
            <span *ngIf="form.roleLevel.errors.min">This min value for hour is 1.</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold">Companies</label>
          <app-company-select (selectedCompany)="form['companies'].setValue($event);"
                              [error]="formSubmitted && form?.companies?.errors?.required"
                              [multiple]="true"
                              [selected]="this.form?.companies?.value"></app-company-select>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label class="font-weight-bold" for="prizeAdmin">Prize admin</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.prizeAdmin?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="prizeAdmin" id="prizeAdmin" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="prizeAdmin">
              <span *ngIf="form?.prizeAdmin?.value" class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.prizeAdmin?.value" class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label class="font-weight-bold" for="prizeAdmin">Competition admin</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.competitionAdmin?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="competitionAdmin"
                   id="competitionAdmin" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="promoChallenger">
                              <span *ngIf="form?.competitionAdmin?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.competitionAdmin?.value"
                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">
          <label class="font-weight-bold" for="prizeAdmin">Promo challenger</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.promoChallenger?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="promoChallenger"
                   id="promoChallenger" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="promoChallenger">
                              <span *ngIf="form?.promoChallenger?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.promoChallenger?.value"
                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !operator " class="btn mb-3 btn-warning">
          Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && operator && operator?.duplicate"
                class="btn mb-3 btn-warning">Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && operator && !operator?.duplicate"
                class="btn mb-3 btn-warning">Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingOperator$ | async)" class="btn mb-3 btn-warning me-2"
                type="submit">
          <i *ngIf="addingOperator$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingOperator$ | async) && !operator">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingOperator$ | async) && operator && !operator?.duplicate">{{'EDITING' | translate}}</span>
          <span
            *ngIf="(addingOperator$ | async) && operator && operator?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingOperator$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingOperator$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
