import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {HttpService} from "@service/http/http.service";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class FaqsApiService {

  constructor(private http: HttpService) {
  }

  public getFaqsList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.faqs.list, params);
  }

  public createEditFaq(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.faqs.edit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.faqs.create, params);
    }
  }

  public deleteFaq(faqs: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: faqs[0]?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.faqs.delete, placeholders);
    return this.http.delete(url, faqs);
  }
}
