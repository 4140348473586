import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable, of} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";
import {content} from "@service/content/data";

@Injectable({
  providedIn: 'root'
})
export class ContentApiService {
  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.content.list, params);
  }

  public delete(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.content.delete, placeholders);
    return this.http.delete(url, {});
  }

  public edit(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.content.edit, placeholders);
    return this.http.put(url, data);
  }

  public add(data: any): Observable<any> {
		if (data.id) {
			return this.edit(data);
		} else {
			return this.http.post(endpoints.content.add, data);
		}
  }
}
