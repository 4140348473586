<div *ngIf="prizes" [ngClass]="{'is-invalid-select': error}"
     class="input-group me-2 page-filter-group single-ng-select refresh-group">
  <ng-select (change)="selectPrize($event)" (search)="setSearchTerm($event)"
             [(ngModel)]="selected"
             [multiple]="multiple"
             [placeholder]="'Select prize'">
    <ng-option *ngFor="let prize of prizes" [value]="prize">{{returnItemDescription(prize)}}</ng-option>
  </ng-select>
  <button (click)="setPrizeFilters()" *ngIf="complete" class="btn btn-primary btn-default">
    <i class="bx bx-search-alt-2"></i>
  </button>
</div>
