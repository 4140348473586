import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UcWidgetComponent} from "ngx-uploadcare-widget";

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  @Output() imageUploaded = new EventEmitter();
  @Output() multipleImageUploaded = new EventEmitter();
  @Input() previousSelectedImage: any = undefined;
  @Input() name: any = undefined;
  @Input() showImagePreview: boolean;
  @Input() limit: any = undefined;
  @Input() multiple: boolean = false;
  public selectedImage: any = undefined;
  public selectedImages: any = undefined;
  @Input() imageErrors: any = undefined;
  @ViewChild('uploader') private uploader: UcWidgetComponent;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.multiple) {
      if (!!this.previousSelectedImage?.name && !!this.previousSelectedImage?.originalUrl) {
        this.selectedImage = this.previousSelectedImage;
      }
    } else {
      this.selectedImages = this.previousSelectedImage;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.multiple) {
      if (!!changes?.previousSelectedImage?.currentValue?.name && !!changes?.previousSelectedImage?.currentValue?.name && this.previousSelectedImage) {
        this.selectedImage = changes?.previousSelectedImage?.currentValue;
      }
    } else {
      this.selectedImages = changes?.previousSelectedImage?.currentValue;
    }
  }

  public openUploader(show?: boolean): void {
    if (show && !!this.previousSelectedImage?.name && !!this.previousSelectedImage?.originalUrl) {
      window.open(this.previousSelectedImage?.originalUrl, '_blank');
    } else {
      if (this.previousSelectedImage?.length > 0) {
        this.uploader.value = this.previousSelectedImage[0]
      }
      this.imageErrors = undefined;
      this.uploader.openDialog();
    }
  }

  public clearImage(): void {
    this.selectedImage = undefined;
    this.uploader.clearUploads();
    this.imageUploaded.emit(null)
  }

  public clearImages(): void {
    this.selectedImages = undefined;
    this.uploader.clearUploads();
    this.multipleImageUploaded.emit(null)
  }

  public async upload(event: any) {
    if (this.multiple && event?.cdnUrl && event?.count) {
      this.uploadMultipleImages(event);
    } else {
      this.uploadSingleImage(event);
    }
  }

  public addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({height: img.height, width: img.width})
      img.onerror = reject
      img.src = src
    })
  }

  public removeImage(image, index) {
    this.selectedImages.splice(index, 1);
    this.multipleImageUploaded.emit(this.selectedImages);
  }

  private uploadSingleImage(event: any) {
    this.selectedImage = event;
    this.checkImageType();
    if (this.selectedImage) {
      this.previousSelectedImage = undefined;
      this.imageUploaded.emit(this.selectedImage)
    }
  }

  private uploadMultipleImages(event: any) {
    let images = [];
    const promises = [];
    for (const [index, value] of [...Array(event?.count).keys()].entries()) {
      const imageUrl = [event?.cdnUrl, 'nth', index, ''].join('/');
      promises.push(this.addImageProcess(imageUrl).then(image => {
        if (!this.checkLimitFixedSize(image)) {
          images.push(imageUrl)
        }
      }));
    }
    Promise.all(promises).then((results) => {
      if (images?.length === 0) {
        this.imageErrors = 'Attention! None of the images that you loaded respected the correct width and height.';
      } else if (event?.count === images?.length) {
        this.selectedImages = images;
        this.multipleImageUploaded.emit(images);
      } else if (event?.count !== images?.length) {
        this.selectedImages = images;
        this.multipleImageUploaded.emit(images);
        this.imageErrors = 'Attention! Only the images that respected the correct width and height where loaded.';
      }
    }).catch((e) => {
    });
  }

  private checkImageType() {
    const allowedFormats = ["PNG", "GIF", "JPG", "JPEG"];
    if (!allowedFormats?.includes(this.selectedImage?.originalImageInfo?.format)) {
      this.imageErrors = 'This format is not allowed. You can only upload JPG-JPEG-PNG-GIF files';
      this.clearImage();
    } else if (this.limit && this.limit?.fixedSize) {
      this.checkLimitFixedSize(this.selectedImage?.originalImageInfo);
    } else {
      this.checkNotificationCabledSize();
    }
  }

  private checkNotificationCabledSize() {
    if (this.selectedImage?.originalImageInfo?.height < 200 || this.selectedImage?.originalImageInfo?.width < 300) {
      this.imageErrors = 'The image don\'t respect the minimum size';
      this.clearImage();
    } else if (this.selectedImage?.originalImageInfo?.height > 1000 || this.selectedImage?.originalImageInfo?.width > 2000) {
      this.imageErrors = 'The image don\'t respect the maximum size';
      this.clearImage();
    }
  }

  private checkLimitFixedSize(image?: any): any {
    if (image?.height !== this.limit?.height && image?.width === this.limit?.width) {
      return this.error('The image don\'t respect the required height size');
    } else if (image?.width !== this.limit?.width && image?.height === this.limit?.height) {
      return this.error('The image don\'t respect the required width size');
    } else if (image?.width !== this.limit?.width && image?.height !== this.limit?.height) {
      return this.error('The image don\'t respect the required width and height size');
    } else {
      if (this.multiple) return false;
    }
  }

  private error(message: string) {
    if (this.multiple) {
      return true;
    } else {
      this.imageErrors = message;
      this.clearImage();
    }
  }
}
