import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {AutomaticBanService} from "@service/utility/automatic-ban.service";
import {EventEnum} from "@enum/event/event.enum";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-automatic-ban-rule-modal',
  templateUrl: './automatic-ban-rule-modal.component.html',
  styleUrls: ['./automatic-ban-rule-modal.component.scss']
})
export class AutomaticBanRuleModalComponent implements OnInit {
  @Input() rule: any;
  public creatingRule$: Observable<boolean>;
  public automaticBanRuleForm: UntypedFormGroup;
  public formSubmitted: boolean;
  public showAreYouSure: boolean = false;
  public edit: boolean = false;
  public working$: Observable<boolean>;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService,
              private _formService: FormsService,
              private automaticBanService: AutomaticBanService,
              public formBuilder: UntypedFormBuilder) {
  }

  get form() {
    return this.automaticBanRuleForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingRule$ = this.automaticBanService?.creating$;
  }

  public open(rule: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'md', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.rule = rule;
      this.edit = !!(this.rule?.banThreshold || this.rule?.banThresholdForWhiteList);
      this.initForm();
      this.working$ = this.automaticBanService.working$;
    })
  }

  public validSubmit() {
    if (this.automaticBanRuleForm.valid) {
      let formData = this.automaticBanRuleForm.getRawValue();
      this.automaticBanService?.initCreateListener();
      this.automaticBanService.createAutomaticBanRule(formData);
    } else {
      this._formService.findInvalidControlsRecursive(this.automaticBanRuleForm);
      this.formSubmitted = true;
    }
  }

  public deactivateRule(): void {
    this.automaticBanService.deactivate(this.rule, EventEnum.CLOSE_AUTOMATIC_BAN_RULE_MODAL);
  }

  public extractRuleMetric(): string {
    if (this.rule?.fitnessMetric !== 'TotalStepsCount') {
      return 'MINUTES';
    } else {
      return 'STEPS';
    }
  }

  private initForm() {
    this.automaticBanRuleForm = this.formBuilder.group({
      rule: [this.rule?.fitnessMetric],
      id: [this.rule?.id, [Validators.required]],
      banThreshold: [this?.edit && this.rule?.banThreshold ? this.rule?.banThreshold : null],
      banWarningThreshold: [this?.edit && this.rule?.banWarningThreshold ? this.rule?.banWarningThreshold : null],
      banThresholdForWhiteList: [this?.edit && this.rule?.banThresholdForWhiteList ? this.rule?.banThresholdForWhiteList : null],
    });
    this.automaticBanRuleForm.controls['rule'].disable();
  }

  private patchForm() {

  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_AUTOMATIC_BAN_RULE_MODAL, (reason: string | undefined) => {
      this.formSubmitted = false;
      this.showAreYouSure = false;
      this.edit = false;
      this.rule = undefined;
      this.automaticBanService.removeCreateSubscribe();
      this.modalRef?.dismiss(reason);
    });
  }

}
