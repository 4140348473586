<table *ngIf="challenges?.length > 0"
       class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
  <thead>
  <tr>
    <th *ngIf="canBeRemoved"></th>
    <th>
      <span class="me-3">{{'ID' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'NAME' | translate}}</span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let challenge of challenges; let i=index;">
    <td (click)="removeChallenge(challenge)" *ngIf="canBeRemoved" class="pointer fit-width">
      <i class="dripicons-cross"></i>
    </td>
    <td align="center" class="fit-width">
      <span class="badge badge-pill badge-soft-primary">{{challenge?.id}}</span>
    </td>
    <td>
      <span>{{challenge?.challengeName}} - {{challenge?.description}}</span>
    </td>
  </tr>
  </tbody>
</table>
