import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page404',
  templateUrl: './404-not-found.component.html',
  styleUrls: ['./404-not-found.component.scss']
})

export class Page404Component implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
