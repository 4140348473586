import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, take} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {CompaniesApiService} from "@service/companies/companies-api.service";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  public _delete$ = new Subject<any>();
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.companiesTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.companiesTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchCompaniesSubscription: Subscription;
  private createCompanySubscription: Subscription;
  private deleteCompanySubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private companiesApiService: CompaniesApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _companyList$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get companyList$() {
    return this._companyList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get priority() {
    return this._currentTableState.priority;
  }

  set priority(priority: string[]) {
    this._setValue({priority});
  }

  get discoverable() {
    return this._currentTableState.discoverable;
  }

  set discoverable(discoverable: boolean | undefined | null) {
    this._setValue({discoverable});
  }

  get enabled() {
    return this._currentTableState.enabled;
  }

  set enabled(enabled: boolean | undefined | null) {
    this._setValue({enabled});
  }

  get showUsersCount() {
    return this._currentTableState.showUsersCount;
  }

  set showUsersCount(showUsersCount: boolean | undefined | null) {
    this._setValue({showUsersCount});
  }

  get showCompanyLeaderboards() {
    return this._currentTableState.showCompanyLeaderboards;
  }

  set showCompanyLeaderboards(showCompanyLeaderboards: boolean | undefined | null) {
    this._setValue({showCompanyLeaderboards});
  }

  get mustInsertCode() {
    return this._currentTableState.mustInsertCode;
  }

  set mustInsertCode(mustInsertCode: boolean | undefined | null) {
    this._setValue({mustInsertCode});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get updatedAt() {
    return this._currentTableState.updatedAt;
  }

  set updatedAt(updatedAt: string[]) {
    this._setValue({updatedAt});
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public removeSearchCompaniesSubscribe(): void {
    this.searchCompaniesSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchCompaniesListener(paged?: boolean): void {
    this.searchCompaniesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.companiesApiService.getCompanyList(this._extractSearchParams(), paged).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._companyList$.next(paged ? result?.data : result);
      this._totalRecords$.next(paged ? result?.size : result?.length);
    });
  }

  public initSearchCodesListener(): void {
    this.searchCompaniesSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.companiesApiService.getCompanyList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._companyList$.next(result);
      this._totalRecords$.next(result?.length);
    });
  }

  public getUserCompanies(userId: any): any {

  }

  public getStreaks(companyId: any, filters?: any): Observable<any> {
    return this.companiesApiService.getCompanyMembershipsStreaks(companyId, filters);
  }

  public removeCreateCompanySubscribe(): void {
    this.createCompanySubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public createEditCompany(data: any): void {
    this._create$.next(data);
  }

  public activate(company: any, modal: any): any {
    company.active = true;
    this.activateDeactivateCompany(company, modal);
  }

  public deactivate(company: any, modal: any): any {
    company.active = false;
    this.activateDeactivateCompany(company, modal);
  }

  public removeDeleteSubscribe(): void {
    this.deleteCompanySubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initCreateListener(): void {
    this.createCompanySubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.companiesApiService.createEditCompany(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_COMPANY, null)
            this._search$.next();
            return result;
          } else {
            this._enableWebappIfMustInsertCode(result, data);
            const message = data?.id ? 'Company edited successfully' : 'Company created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_CREATE_COMPANY, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_COMPANY);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public addCompanyCodes(codes: any[], companyId: any): any {
    let codeApi: any[] = [];
    codes?.map((code) => {
      code.id = null;
      code.companyId = companyId;
      codeApi.push(this.companiesApiService.addCompanyCode(code));
      return code;
    });
    return combineLatest(codeApi);
  }

  public getCompanyData(id: any) {
    return this.companiesApiService.getCompanyData({id: id});
  }

  public getInviteCodes(id: any) {
    return this.companiesApiService.getCompanyInviteCodes({companyId: id, page: 0, size: 1000});
  }

  public editCompanyWebappConfigOptions(id, config) {
    return this.companiesApiService.activateEditWebapp(id, config);
  }

  public getWebapp(id) {
    return this.companiesApiService.getWebapp(id);
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.priority = undefined;
    this.enabled = undefined;
    this.discoverable = undefined;
    this.showUsersCount = undefined;
    this.active = undefined;
    this.createdAt = undefined;
    this.showCompanyLeaderboards = undefined;
    this.mustInsertCode = undefined;
    this.updatedAt = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.companiesApiService.getCompanyList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private _enableWebappIfMustInsertCode(result, data: any) {
    if (result?.id) {
			console.log(result)
      this.getWebapp(result?.id).pipe(take(1)).subscribe((webappConfig: any) => {
        if (!webappConfig?.enabled) {
          this.editCompanyWebappConfigOptions(result?.id, {
            enabled: true,
            title: !!webappConfig?.title ? webappConfig?.title : result?.name
          }).pipe(take(1)).subscribe((company) => {
	          data.updateCompanyLanguages.uniqueCode = company?.uniqueCode;
	          this._enableLanguages(result, data);
          });
        } else {
					console.log('Re')
					this._enableLanguages(result, data);
        }
      });
    }
  }
	
	private _enableLanguages(result, data: any) {
		if (data?.updateCompanyLanguages) {
			let create = data?.updateCompanyLanguages?.new?.filter(lang => !data?.updateCompanyLanguages?.original?.includes(lang));
			let remove = data?.updateCompanyLanguages?.original?.filter(lang => !data?.updateCompanyLanguages?.new?.includes(lang));
			if (create?.length > 0) {
				this.companiesApiService.addLanguages(create, data?.updateCompanyLanguages?.uniqueCode).pipe(take(1)).subscribe();
			}
			if (remove?.length > 0) {
				for (let lang of remove) {
					this.companiesApiService.removeLanguages(lang, data?.updateCompanyLanguages?.uniqueCode).pipe(take(1)).subscribe();
				}
			}
		}
	}

  private activateDeactivateCompany(company: any, modal: any) {
    this._working$.next(true);
    this.companiesApiService.createEditCompany(company).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        const message = company?.active ? 'Company activated successfully' : 'Company deactivated successfully';
        this.toastService.show(message, {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }, (error => {
      this.toastService.show(error, {classname: 'bg-danger text-light'});
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    }));
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._creating$.next(false);
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._creating$.next(false);
    this._search$.next();
    return err;
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        priority: this.priority && this.priority?.length > 0 ? this.priority : undefined,
        enabled: (this.enabled !== undefined && this.enabled !== null) ? [this.enabled] : undefined,
        discoverable: (this.discoverable !== undefined && this.discoverable !== null) ? [this.discoverable] : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        showMembersCount: (this.showUsersCount !== undefined && this.showUsersCount !== null) ? [this.showUsersCount] : undefined,
        showCompanyLeaderboards: (this.showCompanyLeaderboards !== undefined && this.showCompanyLeaderboards !== null) ? [this.showCompanyLeaderboards] : undefined,
        mustInsertCode: (this.mustInsertCode !== undefined && this.mustInsertCode !== null) ? [this.mustInsertCode] : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        lastUpdate: this.updatedAt && this.updatedAt?.length > 0 ? this.updatedAt : undefined,
      },
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
