import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: 'app-ban-summary',
  templateUrl: './ban-summary.component.html',
  styleUrls: ['./ban-summary.component.scss']
})
export class BanSummaryComponent implements OnInit {
  @Input() banData: any;
  @Input() banning$: Observable<boolean>;
  @Output() confirmBan: EventEmitter<any> = new EventEmitter();
  public showAreYouSure: boolean = false;
  public banNotes: string = undefined;
  public usersCollapsed: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  public extractUsers(users: any[]): string {
    const names = Array.from(users, (user) => user.fullName)
    return names.join(', ');
  }

  public confirmBanAction(): void {
    let banData = {...this.banData, ...{note: this.banNotes}, ...{motivationId: this.banData?.motivation?.id}};
    this.confirmBan.emit(banData);
  }

}
