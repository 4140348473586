<table [hidden]="!visible()" class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables" id="basic-datatable">
  <thead>
    <tr>
      <th *ngFor="let column of columns" [sortable]="column?.sortable ? column?.sortKey : null"
          (sort)="sort($event, column)" [ngClass]="{'column-sortable' : column?.sortable}">
        <span *ngIf="column?.text" [ngClass]="{'me-4' : column?.sortable}">{{column?.label | translate}}</span>
        <div *ngIf="column?.checkbox" (click)="selectAllRows()" class="form-check form-check-custom font-size-16 align-middle p-0">
          <input [checked]="allElementsSelected" class="form-check-input m-0" [id]="name" type="checkbox">
        </div>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of list; let i=index;">
    <td *ngFor="let column of columns" [attr.align]="column.align" (click)="column?.clickAction ? clickAction.emit({methodName: column?.clickAction, param: element}) : null;" [class]="column?.class?.join(' ')">
      <div *ngIf="column?.checkbox" class="form-check form-check-custom font-size-16 p-0 m-0">
        <input (click)="element.selected = !element.selected; checkSelectElements()" [checked]="element.selected"
               [value]="element.selected" class="form-check-input p-0 m-0" id="{{name}}-selection-{{i}}" type="checkbox">
      </div>
      <span [class]="column?.elementClasses?.join(' ')"
            *ngIf="
            !column?.translatable &&
            !column?.elementList &&
            !column?.date && !column?.localizableKeys &&
             !column?.boolean &&
              !column?.dateTime &&
               !column?.number &&
               !column?.truncate &&
               !column?.locale &&
                !column?.numberHour &&
                 !column?.notificationActionKey &&
                  !column?.notificationActionDetailKey &&
                  !column?.image &&
                   element[column?.elementKey] &&
                    !column?.multi">
        {{element[column?.elementKey]}}
      </span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && !column?.date && !column?.localizableKeys
       && !column?.boolean && !column?.dateTime && !column?.number && !column?.truncate && !column?.locale && !column?.numberHour &&
        !column?.image && element[column?.elementKey] && column?.multi">
        {{element[column?.elementKey][column?.secondLevelElementKey]}}
      </span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.number && !column?.percentage && !column?.notificationActionDetailKey && !column?.notificationActionKey">
        <span *ngIf="element[column?.elementKey] > 0">{{element[column?.elementKey]}}</span>
        <span class="badge badge-pill badge-soft-danger" *ngIf="element[column?.elementKey] === 0">0</span>
        <span *ngIf="!element[column?.elementKey] && element[column?.elementKey] !== 0">
          <app-missing-label></app-missing-label>
        </span>
      </span>
      <span [class]="column?.elementClasses?.join(' ')"  [ngClass]="{'badge-soft-danger': !element[column?.elementKey] || element[column?.elementKey] <= 33, 'badge-soft-warning': element[column?.elementKey] <= 66}" *ngIf="!column?.translatable && column?.number && column?.percentage">
        <span *ngIf="element[column?.elementKey] >= 0">{{element[column?.elementKey] | number}}  %</span>
<!--        <span *ngIf="!element[column?.elementKey]">-->
<!--          No data-->
<!--        </span>-->
      </span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.locale && !column?.multiLocale">
        <img alt="Lang" class="me-2" height="16" src="{{utils.getSpecificLanguage(element[column?.elementKey])}}">
      </span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.locale && column?.multiLocale">
        <ng-container *ngFor="let lang of element[column?.elementKey]">
          <img  (click)="utils.openLink(lang.translationUrl)"  alt="Lang" class="me-2" height="16" src="{{utils.getSpecificLanguage(lang)}}">
        </ng-container>
      </span>
      <ng-container *ngIf="!column?.translatable && column?.localizableKeys">
        <img (click)="utils.openLink(lang.translationUrl)" *ngFor="let lang of element?.languageToTranslationUrl" alt="Header Language"
             class="me-2" height="16" ngbTooltip="{{lang.language}} translation" placement="bottom"
             src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
      </ng-container>

      <ng-container *ngIf="!column?.translatable && column?.image">
        <span [class]="column?.elementClasses?.join(' ')">
          <ng-container *ngIf="!column.secondLevelElementKey && element[column?.elementKey]">
            <img [class]="column?.elementClasses?.join(' ')" [src]="element[column?.elementKey]" class="avatar-xs">
            <span class="d-inline-block danger ms-2" placement="right" [ngbTooltip]="'URL temporaneo ricarica immagine'" *ngIf="checkUcare(element[column?.elementKey])">
              <i class="mdi mdi-alert-circle-outline"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="column.secondLevelElementKey && element[column?.elementKey] && element[column?.elementKey][column.secondLevelElementKey]">
            <img [class]="column?.elementClasses?.join(' ')" [src]="element[column?.elementKey][column?.secondLevelElementKey]" class="avatar-xs">
            <span class="d-inline-block danger ms-2" placement="right" [ngbTooltip]="'URL temporaneo ricarica immagine'" *ngIf="checkUcare(element[column?.elementKey][column?.secondLevelElementKey])">
              <i class="mdi mdi-alert-circle-outline"></i>
            </span>
          </ng-container>
        </span>
      </ng-container>

      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.numberHour && element[column?.elementKey]">
        {{element[column?.elementKey] * 60 * 1000 * 60| date:'HH:mm':'UTC'}}
      </span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && !column?.notificationActionDetailKey && column?.notificationActionKey && element[column?.elementKey]">{{element[column?.elementKey] | translate}}</span>
      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.notificationActionDetailKey && column?.notificationActionKey && !element[column?.elementKey]"><app-missing-label></app-missing-label></span>

      <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.translatable && column?.truncate && element[column?.elementKey]" [ngbTooltip]="element[column?.elementKey]" placement="bottom">{{element[column?.elementKey] | truncate:[column?.truncateValue]}}</span>

     <ng-container  *ngIf="column?.translatable">
       <span [class]="column?.elementClasses?.join(' ')" *ngIf="column?.translatable && element[column?.elementKey] && !column.secondLevelElementKey">{{element[column?.elementKey] | translate}}</span>
       <span [class]="column?.elementClasses?.join(' ')" *ngIf="column?.translatable && element[column?.elementKey] && column.secondLevelElementKey">{{element[column?.elementKey][column?.secondLevelElementKey] | translate}}</span>
     </ng-container>

      <ng-container *ngIf="column?.elementList">
        <span *ngFor="let option of element[column?.elementKey]" class="badge badge-pill badge-soft-secondary font-size-11 me-2">{{option[column?.secondLevelElementKey]}}</span>
      </ng-container>

      <ng-container *ngIf="!column?.translatable && (column?.date || column?.dateTime) && element[column?.elementKey]">
          <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.secondLevelElementKey && column?.date && element[column?.elementKey]">{{element[column?.elementKey]  | date:'dd/MM/yyyy'}}</span>
          <span [class]="column?.elementClasses?.join(' ')" *ngIf="column?.secondLevelElementKey && column?.date && element[column?.elementKey] && element[column?.elementKey][column?.secondLevelElementKey]">{{element[column?.elementKey][column?.secondLevelElementKey]  | date:'dd/MM/yyyy'}}</span>
          <span [class]="column?.elementClasses?.join(' ')" *ngIf="!column?.secondLevelElementKey && column?.dateTime && element[column?.elementKey]">{{element[column?.elementKey] | date:'dd/MM/yyyy HH:mm'}}</span>
          <span [class]="column?.elementClasses?.join(' ')" *ngIf="column?.secondLevelElementKey && column?.dateTime && element[column?.elementKey] && element[column?.elementKey][column?.secondLevelElementKey]">{{element[column?.elementKey][column?.secondLevelElementKey] | date:'dd/MM/yyyy HH:mm'}}</span>
      </ng-container>

      <ng-container *ngIf="!column?.translatable && column?.boolean">
          <span class="badge badge-pill badge-soft-success" *ngIf="!column?.translatable && element[column?.elementKey]">{{'YES' | translate}}</span>
          <span class="badge badge-pill badge-soft-danger" *ngIf="!column?.translatable  && !element[column?.elementKey]">{{'NO' | translate}}</span>
      </ng-container>



      <ul *ngIf="column?.isRowAction && column?.rowActions?.length > 0" class="list-inline font-size-16 contact-links mb-0">
        <li *ngFor="let action of column?.rowActions" [hidden]="checkHiddenAction(action, element)" (click)="action?.clickAction ? clickAction.emit({methodName: action?.clickAction, param: element}) : null;"
            [ngClass]="{'disabled-opacity': checkDisabledAction(action, element) || selectedElements?.length > 0}" class="list-inline-item px-2">
           <span [class]="action?.class?.join(' ')" [ngbTooltip]="action?.tooltip | translate" [placement]="action?.placement"><i [class]="action?.icon"></i></span>
        </li>
      </ul>
      <app-missing-label *ngIf="!column?.boolean && !column.localizableKeys && !column?.isRowAction && !column?.percentage && !column?.number &&
      !column?.checkbox && !column?.multi && !column?.notificationActionDetailKey && !column?.notificationActionKey && !element[column?.elementKey]"></app-missing-label>
    </td>
  </tr>
  </tbody>
</table>
<!--<div *ngIf="!visible()" class="no-results">-->
<!--  <img alt="" class="max-width-100" src="../../../../assets/images/shared/404.webp">-->
<!--  <p class="no-filters">There are no results</p>-->
<!--</div>-->
