export enum NotificationTableColumnEnum {
  NOTIFICATION_ID = 'NOTIFICATION_ID',
  ACTION = 'ACTION',
  AUDIENCE = 'AUDIENCE',
  AUDIENCE_TARGETS = 'AUDIENCE_TARGETS',
  SELECTED_ACTION = 'SELECTED_ACTION',
  ACTION_PAYLOAD = 'ACTION_PAYLOAD',
  TITLE = 'TITLE',
  MESSAGE = 'MESSAGE',
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  LOCALE = 'LOCALE',
  NOTES = 'NOTES',
  SCHEDULED_AT = 'SCHEDULED_AT',
  SCHEDULED_AT_HOUR = 'SCHEDULED_AT_HOUR',
  SENT_AT = 'SENT_AT'
}
