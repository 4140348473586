<header id="page-topbar-filters">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
      </div>
      <div class="row ms-2">
        <div class="col-sm-12 col-lg-12">
          <form class="app-search d-none d-xl-inline-block">
            <div *ngIf="type !== topbarTypeEnum.CALENDAR_NOTIFICATIONS" class="position-relative">
              <input (ngModelChange)="search($event)" [(ngModel)]="selectedSearchTerm"
                     class="form-control form-control-dark"
                     name="searchTerm" placeholder="{{'SEARCH' | translate}}" type="text">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>
          <div *ngIf="type !== topbarTypeEnum.CALENDAR_NOTIFICATIONS" class="d-inline-block">
            <button aria-controls="offcanvasNavbar" class="btn btn-dark mb-0 ms-2" data-bs-target="#offcanvasNavbar"
                    data-bs-toggle="offcanvas" type="button">
              <i *ngIf="!service.isFilterApplied()" class="bx bx bx-filter me-1"></i>
              <span *ngIf="service.isFilterApplied()" class="badge bg-danger me-2">
                <i class="bx bx bx-filter me-1"></i>
                {{service.isFilterApplied(true)}}
              </span>
              <span *ngIf="service.totalRecords$ | async as records" class="me-2">{{records}} results</span>
              <span *ngIf="!(service.totalRecords$ | async)" class="me-2">0 results</span>
            </button>
          </div>
          <div *ngIf="selectedItems.length > 0" class="d-inline-block">
            <button (click)="deleteNotifications()" *ngIf="type === topbarTypeEnum.SCHEDULED_NOTIFICATIONS"
                    [hidden]="selectedItems.length < 1" class="btn btn-danger mb-0 ms-2"
                    type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete notifications
            </button>
            <button (click)="deleteUsers()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-danger mb-0 ms-2" type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete
            </button>
            <button (click)="banUsers()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-warning mb-0 ms-2" type="button">
              <i class="bx bx-minus-circle mr-2"></i>
              Ban
            </button>
            <button (click)="unbanUsers()" *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.BANNED_LIST"
                    class="btn btn-warning mb-0 ms-2" type="button">
              <i class="bx bx-minus-circle mr-2"></i>
              Unban users
            </button>
            <button (click)="addToWhitelist()" *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.USER_LIST"
                    class="btn btn-dark mb-0 ms-2"
                    type="button">
              <i class="bx bx-check-shield mr-2"></i>
              Add to whitelist
            </button>
            <button (click)="removeFromWhitelist()"
                    *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.WHITE_LIST"
                    class="btn btn-dark mb-0 ms-2"
                    type="button">
              <i class="bx bx-shield mr-2"></i>
              Remove from whitelist
            </button>
            <button (click)="sendUserPush()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-secondary mb-0 ms-2"
                    type="button">
              <i class="bx bx-send mr-2"></i>
              Send notification
            </button>
            <button (click)="addToMembership()"
                    *ngIf="selectedItems.length > 0 && (type === topbarTypeEnum.USER_LIST || type === topbarTypeEnum.WHITE_LIST)"
                    class="btn btn-primary mb-0 ms-2"
                    type="button">
              <i class="bx bx-plus-circle mr-2"></i>
              Add to company
            </button>
            <button (click)="deleteNews()" *ngIf="type === topbarTypeEnum.NEWS_LIST"
                    [hidden]="selectedItems?.length < 1"
                    class="btn btn-danger mb-0 ms-2">
              <i class="bx bx bx-trash mr-2"></i>
              Delete selected news
            </button>
            <button (click)="deleteMotivations()"
                    *ngIf="selectedItems.length > 0 && type === topbarTypeEnum.BAN_MOTIVATIONS"
                    class="btn btn-danger mb-0 ms-2" type="button">
              <i class="bx bx bx-trash mr-2"></i>
              Delete motivations
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="type !== topbarTypeEnum.BAN_MOTIVATIONS && type !== topbarTypeEnum.BLACKLIST_SOURCES " class="d-flex">
      <button (click)="createNews()" *ngIf="type === topbarTypeEnum.NEWS_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create news
      </button>
      <button (click)="createQrCode()" *ngIf="type === topbarTypeEnum.INVITES" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-link mr-2"></i>
        Create new deeplink
      </button>
      <button (click)="createQrCode()" *ngIf="type === topbarTypeEnum.QR_CODES" class="btn btn-primary mb-0 me-2">
        <i class="mdi mdi-qrcode-plus mr-2"></i>
        Create new QR
      </button>
      <button (click)="createNewPrize()" *ngIf="type === topbarTypeEnum.PRIZE_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new prize
      </button>
      <button (click)="createNewFaq()" *ngIf="type === topbarTypeEnum.FAQS_LIST" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new faq
      </button>
      <button (click)="createNewCompany()" *ngIf="type === topbarTypeEnum.COMPANY_LIST"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new company
      </button>
      <button (click)="createNewCompetition()" *ngIf="type === topbarTypeEnum.COMPETITION_LIST"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new competition
      </button>
      <button (click)="createStreakTemplate()" *ngIf="type === topbarTypeEnum.STREAK_TEMPLATES"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new template
      </button>
      <button (click)="createFto()" *ngIf="type === topbarTypeEnum.FTO" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new slot
      </button>
      <button (click)="createStreak()" *ngIf="type === topbarTypeEnum.STREAKS" class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Create new streak
      </button>
      <button (click)="createStreak()" *ngIf="type === topbarTypeEnum.CALENDAR_NOTIFICATIONS"
              class="btn btn-primary mb-0 me-2">
        <i class="bx bx-plus-circle mr-2"></i>
        Send new notification
      </button>
      <app-column-picker (updateVisibleColumns)="updateColumns($event)"
                         *ngIf="type !== topbarTypeEnum.CALENDAR_NOTIFICATIONS" [availableColumns]="availableColumns"
                         [visibleColumns]="selected"></app-column-picker>
    </div>
    <div *ngIf="type === topbarTypeEnum.BAN_MOTIVATIONS" class="d-flex">
      <button (click)="createMotivation()" class="btn btn-primary mb-0">
        <i class="bx bx-plus-circle mr-2"></i>
        Add ban motivation
      </button>
    </div>
    <div *ngIf="type === topbarTypeEnum.BLACKLIST_SOURCES" class="d-flex">
      <button (click)="createBlacklistSource()" class="btn btn-primary mb-0">
        <i class="bx bx-plus-circle mr-2"></i>
        Add source to blacklist
      </button>
    </div>
  </div>
</header>
<app-delete-modal #deleteNotificationModal [service]="notificationsService"
                  [type]="deleteModalType.SCHEDULED_NOTIFICATION"></app-delete-modal>
<app-delete-modal #deleteModal [service]="banMotivationsService"
                  [type]="deleteModalType.BAN_MOTIVATION"></app-delete-modal>
<app-delete-modal #deleteUserModal [service]="service" [type]="deleteModalType.USER"></app-delete-modal>
<app-delete-modal #genericDeleteModal [service]="service" [type]="deleteModalType.BLACKLIST_SOURCE"></app-delete-modal>
