<div class="input-group me-2 page-filter-group single-ng-select w-100">
  <ng-select (change)="select($event)"
             class="w-100"
             id="competition"
             (search)="setSearchTerm($event)"
             [multiple]="false"
             [clearable]="true"
             [items]="data">
    <ng-template let-item="item" ng-label-tmp>
      <span class="badge badge-pill badge-soft-primary font-size-11 mr-1">{{ item?.id }}</span>
      <span>{{ item?.name }}</span>
    </ng-template>
    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
      <span class="font-weight-semibold ms-2">
        <span class="badge badge-pill badge-soft-primary font-size-11 mr-1">{{ item?.id }}</span>
        <span>{{ item?.name }}</span>
      </span>
    </ng-template>
  </ng-select>
</div>