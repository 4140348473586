import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Frequency, Options, RRule} from 'rrule';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import IMask from 'imask';
import {UtilsService} from "@service/utils/utils.service";

export function toNativeDate(ngbDate: NgbDate): Date {
  return new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day));
}

@Component({
  selector: 'app-recurring-event-chooser',
  templateUrl: './recurring-event-picker.component.html',
  styleUrls: ['./recurring-event-picker.component.scss']
})
export class RecurringEventPickerComponent implements OnInit, OnDestroy {
  @Output() selectedDates = new EventEmitter();
  @Input() selection: any;
  public Frequency = Frequency;
  public recurringForm: UntypedFormGroup;
  public dates: Date[] = [];
  public numberMask = { mask: IMask.MaskedDate }
  public frequencyList: Frequency[] = [Frequency.MONTHLY, Frequency.WEEKLY, Frequency.DAILY];
  private today: NgbDate;
  private weekdayMap = [
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
    RRule.SU
  ];
  private destroy$: any = new Subject();

  get form(): any {
    return this.recurringForm.controls;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.today = this.calendar.getToday();
    this.initRecurringForm();
    this.subscribeToFormValue();
    if (this.selection?.dates?.length > 0 && !!this.selection?.form) {
      this.patchForm(this.selection?.form);
      this.patchDates(this.selection?.dates);
    }
  }

  ngOnChanges() {

  }


  ngOnDestroy(): void {
    const selection = {dates: this.dates, form: this.recurringForm.getRawValue()};
    this.selectedDates.emit(selection);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setDateRange(date: any): void {
    if (date?.length === 3) {
      const start = new Date(date[1]);
      const end = new Date(date[2]);
      const startDate = new NgbDate(start.getFullYear(), start.getMonth() +1 , start.getDate());
      const endDate = new NgbDate(end.getFullYear(), end.getMonth() +1 , end.getDate());
      this.form.startDate.setValue(startDate);
      this.form.endDate.setValue(endDate);
    } else {
      this.form.startDate.setValue(null);
      this.form.endDate.setValue(null);
    }
  }

  public setOnMonthDay(date: any): void {
    if (date && date !== '') {
      const jsDate = new Date(date);
      const convertedDate = new NgbDate(jsDate.getFullYear(), jsDate.getMonth() +1 , jsDate.getDate())
      this.form.onMonthday.setValue(convertedDate);
    } else {
      this.form.onMonthday.setValue(null);
    }
  }

  public getCurrentRecurring(): any {
    const options: Partial<Options> = {
      freq: this.recurringForm?.getRawValue()?.frequency || Frequency.DAILY,
      dtstart: toNativeDate(this.recurringForm?.getRawValue()?.startDate || this.today),
      until: toNativeDate(this.recurringForm?.getRawValue()?.endDate || this.today),
      byweekday: this.recurringForm?.getRawValue()?.frequency === Frequency.WEEKLY ? this.getWeekday(this.recurringForm?.getRawValue()?.onWeekday) : null,
      bymonthday: this.recurringForm?.getRawValue()?.frequency === Frequency.MONTHLY ? (this.recurringForm?.getRawValue()?.onMonthday && this.recurringForm?.getRawValue()?.onMonthday.day || this.today.day) : null
    };
    const rule = new RRule(options);
    this.dates = rule.all();
    const selection = {dates: this.dates, form: this.recurringForm.getRawValue()};
    this.selectedDates.emit(selection);
  }

  private initRecurringForm(): void {
    this.recurringForm = this.fb.group({
      startDate: [this.today, Validators.required],
      endDate: [this.calendar.getNext(this.today, 'd', 7), Validators.required],
      frequency: [Frequency.DAILY],
      onWeekday: this.fb.array(
        [false, false, false, false, false, false, false].map(val => this.fb.control(val))
      ),
      onMonthday: [this.today],
      hour: ['09'],
      minutes: ['00'],
      time: ['00:00',Validators.pattern('[0-9]{2,2}[:][0-9]{2,2}')]
    });
  }

  private patchForm(form: any): void {
    this.recurringForm?.patchValue({
      startDate: form?.startDate,
      endDate: form?.endDate,
      frequency: form?.frequency,
      onWeekday: form?.onWeekday,
      onMonthday: form?.onMonthday,
      hour: form?.hour,
      minutes: form?.minutes,
      time: form?.time
    });
  }

  private patchDates(dates: any): void {
    this.dates = dates;
  }

  private subscribeToFormValue(): void {
    this.recurringForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      const options: Partial<Options> = {
        freq: value.frequency || Frequency.DAILY,
        dtstart: toNativeDate(value.startDate || this.today),
        until: toNativeDate(value.endDate || this.today),
        byweekday: value.frequency === Frequency.WEEKLY ?
          this.getWeekday(value.onWeekday) : null,
        bymonthday: value.frequency === Frequency.MONTHLY ?
          (value.onMonthday && value.onMonthday.day || this.today.day) : null
      };
      const rule = new RRule(options);
      this.dates = rule.all();
      this.getCurrentRecurring();
    });
  }

  private getWeekday(byWeekday: boolean[]): any {
    const result = byWeekday
      .map((v, i) => v && this.weekdayMap[i] || null)
      .filter(v => !!v);
    return result.length ? result : null;
  }
}
