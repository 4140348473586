import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class ChallengesApiService {
  constructor(private http: HttpService) {
  }

  public getChallengeList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.challenges.list, params);
  }

  public getUserChallengeList(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.challenges.forUser, params);
  }

  public getChallengeDetail(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.challenges.detail, params);
  }

  public getChallengeDuration(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.challenges.duration, params);
  }
}
