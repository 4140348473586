import {Injectable} from '@angular/core';
import {CompetitionsApiService} from "@service/competitions/competitions-api.service";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, switchMap, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CompetitionIndividualLeaderboardService {
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.competitionsIndividualLeaderboardTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.competitionsIndividualLeaderboardTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchCompetitionIndividualLeaderboard: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private competitionsApiService: CompetitionsApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _competitionIndividualLeaderboard$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get competitionIndividualLeaderboard$() {
    return this._competitionIndividualLeaderboard$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public get contestId() {
    return this._currentTableState.contestId;
  }

  public set contestId(contestId: string) {
    this._setValue({contestId});
  }

  get position() {
    return this._currentTableState.position;
  }

  set position(position: string[]) {
    this._setValue({position});
  }

  get userId(): any {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  get nickname() {
    return this._currentTableState.nickname;
  }

  set nickname(nickname: string | undefined) {
    this._setValue({nickname});
  }

  get firstName() {
    return this._currentTableState.firstName;
  }

  set firstName(firstName: string | undefined) {
    this._setValue({firstName});
  }

  get lastName() {
    return this._currentTableState.lastName;
  }

  set lastName(lastName: string | undefined) {
    this._setValue({lastName});
  }

  get email() {
    return this._currentTableState.email;
  }

  set email(email: string | undefined) {
    this._setValue({email});
  }

  get banned() {
    return this._currentTableState.banned;
  }

  set banned(banned: boolean | undefined | null) {
    this._setValue({banned});
  }

  public get powerLevel() {
    return this._currentTableState.powerLevel;
  }

  public set powerLevel(powerLevel: any) {
    this._setValue({powerLevel});
  }

  get counterProvisionalStart() {
    return this._currentTableState.counterProvisionalStart;
  }

  set counterProvisionalStart(counterProvisionalStart: string[]) {
    this._setValue({counterProvisionalStart});
  }

  get counterLastUpdate() {
    return this._currentTableState.counterLastUpdate;
  }

  set counterLastUpdate(counterLastUpdate: string[]) {
    this._setValue({counterLastUpdate});
  }

  get participantCreatedAt() {
    return this._currentTableState.participantCreatedAt;
  }

  set participantCreatedAt(participantCreatedAt: string[]) {
    this._setValue({participantCreatedAt});
  }

  get participantLastUpdate() {
    return this._currentTableState.participantLastUpdate;
  }

  set participantLastUpdate(participantLastUpdate: string[]) {
    this._setValue({participantLastUpdate});
  }

  public get status() {
    return this._currentTableState.status;
  }

  public set status(status: string[]) {
    this._setValue({status});
  }

  public get score() {
    return this._currentTableState.score;
  }

  public set score(score: string[]) {
    this._setValue({score});
  }

  public get provisional() {
    return this._currentTableState.provisional;
  }

  public set provisional(provisional: string[]) {
    this._setValue({provisional});
  }

  public get consolidated() {
    return this._currentTableState.consolidated;
  }

  public set consolidated(consolidated: string[]) {
    this._setValue({consolidated});
  }

  public removeSearchCompetitionsIndividualLeaderboardSubscribe(): void {
    this.searchCompetitionIndividualLeaderboard?.unsubscribe();
    this._loading$.next(false);
  }

  public initSearchCompetitionsIndividualLeaderboardListener(): void {
    this.searchCompetitionIndividualLeaderboard = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.competitionsApiService.getCompetitionIndividualLeaderboard(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._competitionIndividualLeaderboard$.next(result?.data);
      this._totalRecords$.next(result?.size);
    });
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.position = undefined;
    this.userId = undefined;
    this.nickname = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.email = undefined;
    this.powerLevel = undefined;
    this.counterProvisionalStart = undefined;
    this.counterLastUpdate = undefined;
    this.participantCreatedAt = undefined;
    this.participantLastUpdate = undefined;
    this.status = undefined;
    this.score = undefined;
    this.provisional = undefined;
    this.banned = undefined;
    this.consolidated = undefined;
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.competitionsApiService.getCompetitionIndividualLeaderboard(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        position: this.position && this.position?.length > 0 ? this.position : undefined,
        userId: this.userId && this.userId?.length > 0 ? this.userId : undefined,
        nickname: this.nickname && this.nickname?.length > 0 ? this.nickname : undefined,
        firstName: this.firstName && (this.firstName !== 'undefined') ? this.firstName : undefined,
        lastName: this.lastName && (this.lastName !== 'undefined') ? this.lastName : undefined,
        email: this.email && (this.email !== 'undefined') ? this.email : undefined,
        powerLevel: !!this.powerLevel && this.powerLevel !== 'undefined' ? this.utils.sanitizeArray(this.powerLevel) : undefined,
        counterProvisionalStart: this.counterProvisionalStart && this.counterProvisionalStart?.length > 0 ? this.counterProvisionalStart : undefined,
        counterLastUpdate: this.counterLastUpdate && this.counterLastUpdate?.length > 0 ? this.counterLastUpdate : undefined,
        participantCreatedAt: this.participantCreatedAt && this.participantCreatedAt?.length > 0 ? this.participantCreatedAt : undefined,
        participantLastUpdate: this.participantLastUpdate && this.participantLastUpdate?.length > 0 ? this.participantLastUpdate : undefined,
        status: (this.banned !== undefined && this.banned !== null) ? this.extractBannedFilter() : undefined,
        score: this.score && this.score?.length > 0 ? this.score : undefined,
        provisional: this.provisional && this.provisional?.length > 0 ? this.provisional : undefined,
        consolidated: this.consolidated && this.consolidated?.length > 0 ? this.consolidated : undefined,
      },
      contestId: (this.contestId !== undefined && this.contestId !== null) ? this.contestId : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private extractBannedFilter(): any {
    if (this.banned) {
      return ["BANNED"];
    } else if (!this.banned) {
      return ["MEMBER"];
    }
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
