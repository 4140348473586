<div class="modal-header border-bottom-0">
  <h5 class="modal-title" id="modal-title">
    <span *ngIf="modalData?.title" class="font-weight-bold">{{ modalData?.title | translate }}</span><span
    *ngIf="modalData?.name" class="font-weight-bold">: {{modalData?.name}}</span>
  </h5>
  <button [disabled]="(service?.working$ | async)" class="btn btn-outline-light close-modal-button"
          closeModal="CLOSE_DELETE_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="modalData?.description" class="modal-description">{{ modalData?.description | translate }}</p>
  <ul class="list-inline wizard mb-0">
    <li *ngIf="type === DeleteModalTypeEnum.NOTIFICATION && modalData?.obj?.recurringGroupId"
        class="next list-inline-item float-start d-flex align-items-center mb-3 delete-others">
      <div class="form-check form-check-custom form-check-custom-big p-0 me-3">
        <input [(ngModel)]="deleteOthers" [checked]="deleteOthers" autocomplete="off"
               class="form-check-input m-0 pointer" id="deleteOthers" type="checkbox">
      </div>
      <label class="font-weight-bold" for="deleteOthers">Delete connected recurring notifications</label>
    </li>
    <li class="next list-inline-item float-end d-flex align-items-center mb-3">
      <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-danger">
        <span *ngIf="type === DeleteModalTypeEnum.USER_FROM_COMPETITION">{{'REMOVE' | translate}}</span>
        <span *ngIf="type !== DeleteModalTypeEnum.USER_FROM_COMPETITION">{{'DELETE' | translate}}</span>
      </button>
      <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
      <button (click)="delete()" *ngIf="showAreYouSure" [disabled]="(service?.working$ | async)"
              class="btn btn-danger me-2" type="submit">
        <i *ngIf="service?.working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        <span *ngIf="service?.working$ | async">
          <span *ngIf="type === DeleteModalTypeEnum.USER_FROM_COMPETITION">{{'REMOVING' | translate}}</span>
          <span *ngIf="type !== DeleteModalTypeEnum.USER_FROM_COMPETITION">{{'DELETING' | translate}}</span>
        </span>
        <span [hidden]="(service?.working$ | async)">{{'YES' | translate}}</span>
      </button>
      <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(service?.working$ | async)"
              class="btn btn-outline-light ">{{'NO' | translate}}
      </button>
    </li>
  </ul>
</div>
