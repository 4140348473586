import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {PrizesService} from "@service/prizes/prizes.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-add-partner-modal',
  templateUrl: './add-partner-modal.component.html',
  styleUrls: ['./add-partner-modal.component.scss']
})
export class AddPartnerModalComponent implements OnInit {
  @Input() partner: any;
  public showAreYouSure: boolean = false;
  public addingPartner$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public partnerForm: UntypedFormGroup;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 1000,
    height: 1000
  };

  constructor(private toastService: ToastService, public utils: UtilsService,
              private _formService: FormsService,
              private formBuilder: UntypedFormBuilder, private prizesService: PrizesService) {
  }

  get form() {
    return this.partnerForm.controls;
  }

  get valid() {
    return this.partnerForm.valid
  }

  ngOnInit(): void {
    this.addingPartner$ = this.prizesService.addingPartner$;
    this.initForm();
  }

  public validSubmit() {
    if (this.partnerForm.valid) {
      this.prizesService.addPartner(this.partnerForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.partnerForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.partnerForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.partnerForm.patchValue({
        logoImageUrl: image?.originalUrl,
        imageName: image?.name,
        imageType: image?.mimeType,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.logoImageUrl?.value) {
      return {
        name: this.form?.imageName?.value,
        originalUrl: this.form?.logoImageUrl?.value,
        mimeType: this.form?.imageType?.value
      }
    } else {
      return null;
    }
  }

  public removeImage(): void {
    this.partnerForm.patchValue({
      logoImageUrl: null,
      imageName: null,
      imageType: null,
    });
  }

  public checkImageErrors(): string | undefined {
    if (this.formSubmitted && this.form.logoImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.partnerForm = this.formBuilder.group({
      name: [!this.partner || !this.partner?.name ? null : this.partner?.name, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      websiteUrl: [!this.partner || !this.partner?.websiteUrl ? null : this.partner?.websiteUrl, [Validators.required, Validators.pattern(this.utils.validateUrlRegex)]],
      cardTitle: [!this.partner || !this.partner?.cardTitle ? null : this.partner?.cardTitle, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      cardDescription: [!this.partner || !this.partner?.cardDescription ? null : this.partner?.cardDescription, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      logoImageUrl: [!this.partner || !this.partner?.logoImageUrl ? null : this.partner?.logoImageUrl, [Validators.required]],
      imageName: [!this.partner || !this.partner?.logoImageUrl ? null : this.partner?.name],
      imageType: [null],
      id: [this.partner?.id && !this.partner.duplicate ? this.partner?.id : null]
    });
  }

}
