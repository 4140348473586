<ng-template #modal>
  <div *ngIf="user" class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span *ngIf="add">{{ 'ADD_CREDITS_USER' | translate: {value1: user.fullName} }}</span>
      <span *ngIf="!add">{{ 'REMOVE_CREDITS_USER' | translate: {value1: user.fullName} }}</span>
    </h5>
    <button [disabled]="(working$ | async)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_ADD_REMOVE_CREDITS">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="creditForm" autocomplete="off"
          class="needs-validation">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-8">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="amount">{{'AMOUNT' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.amount.errors}" class="form-control"
                       formControlName="amount" id="amount" min="1" type="number">
                <div *ngIf="formSubmitted && form.amount.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.amount.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="form?.amount?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.amount?.errors?.maxlength?.requiredLength} }}</span>
                  <span
                    *ngIf="form?.amount?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.amount?.errors?.minlength?.requiredLength} }}
                    .</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="font-weight-bold">{{'STREAK_VALID_FOR_COMPETITIONS' | translate}}</label>
                <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                  <input [checked]="form.validForCreditsBasedContests.value" autocomplete="off"
                         class="form-check-input m-0 pointer" formControlName="validForCreditsBasedContests"
                         id="validForCreditsBasedContests" type="checkbox">
                  <label class="form-check-label font-size-11 ms-3" for="validForCreditsBasedContests">
                    <span *ngIf="form?.validForCreditsBasedContests?.value"
                          class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                    <span *ngIf="!form?.validForCreditsBasedContests?.value"
                          class="badge badge-pill badge-soft-danger font-size-11">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="reason">{{'REASON' | translate}}</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.reason.errors}" class="form-control"
                       formControlName="reason" id="reason" type="text">
                <div *ngIf="formSubmitted && form.reason.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.reason.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold">{{'DAY_OF_REFUND' | translate}}</label>
                <app-simple-datepicker (dateSelected)="updateDate($event)"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form.forDay.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.forDay.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && add" class="btn btn-warning">Add credits
          </button>
          <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure && !add" class="btn btn-warning">Remove
            credits
          </button>
          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
          <button (click)="confirmSelection()" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                  class="btn btn-warning me-2"
                  type="submit">
            <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="working$ | async">
            <span *ngIf="add">Adding credits</span>
            <span *ngIf="!add">Removing credits</span>
          </span>
            <span [hidden]="(working$ | async)">
            {{'YES' | translate}}
          </span>
          </button>
          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                  class="btn btn-outline-light ">{{'NO' | translate}}
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
