import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class BenefitsApiService {
  constructor(private http: HttpService) {
  }

  public search(filters?: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.type}];
    const url = this.http.findAndReplacePlaceholders(endpoints.config.benefits.search, placeholders);
    return this.http.get<any>(url, params);
  }

  public add(data?: any): Observable<any> {
    return this.http.post(endpoints.config.benefits.add, data);
  }

  public edit(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.config.benefits.edit, placeholders);
    return this.http.put(url, data);
  }

  public activate(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.config.benefits.edit, placeholders);
    return this.http.put(url, data);
  }

  public deactivate(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.config.benefits.edit, placeholders);
    return this.http.put(url, data);
  }

  public delete(data?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: data?.data?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.config.benefits.delete, placeholders);
    return this.http.delete(url, {});
  }

}
