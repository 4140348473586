import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {BanStatusEnum} from "@enum/ban-status/ban-status.enum";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {FitnessKitEnum} from "@enum/fitness-kit/fitness-kit.enum";
import {BanTypeEnum} from "@enum/ban-type/ban-type.enum";
import {NotificationTypeEnum} from "@enum/notifications/notification-type/notification-type.enum";
import {Router} from "@angular/router";
import {ToastService} from "@service/toast.service";
import {UserListService} from "@service/users/user-list.service";
import {NotificationsService} from "@service/notifications/notifications.service";

@Component({
  selector: 'app-banned-list-page',
  templateUrl: './banned-list-page.component.html',
  styleUrls: ['./banned-list-page.component.scss']
})
export class BannedListPageComponent implements OnInit {
  @Input() service?: any;
  @Input() filters?: any;
  public bans$: Observable<any[]>;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPANY_COMPETITION_BANS;
  public selectedItems: any[] = [];
  public kit = FitnessKitEnum;
  public clearFilters: Subject<boolean> = new Subject<boolean>();
  public searchFilters: any = null;
  public allSelected = false;
  public banType = BanTypeEnum;
  public banStatus = BanStatusEnum;
  public notificationModalType = NotificationTypeEnum;
  @ViewChild('banRemoveModal') private banRemoveModal: any
  @ViewChild('banEditModal') private banEditModal: any

  constructor(private cdr: ChangeDetectorRef, private router: Router, private notificationService: NotificationsService,
              private toastService: ToastService, public userListService: UserListService) {
  }

  ngOnInit(): void {
    this.bans$ = this.service.bans$;
    this.total$ = this.service.totalRecords$;
    this.loading$ = this.service.loading$;
    this.sort({column: "unbannedAt", direction: "asc"});
    this.setVisibleColumns();
    this.service.contestId = this.filters?.contextDetailsId;
    this.service.initSearchBans();
    this.service._search$.next();
    this.cdr.detectChanges();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.service.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.service.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public extractRowNumber(index: any): string {
    return (this.service.page - 1) * this.service.pageSize + index + 1;
  }

  public sort({column, direction}: SortEventInterface) {
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return true;
  }

  public extractSelectedItems(): any {
    this.bans$.pipe().subscribe((users) => {
      this.selectedItems = users.filter((user) => {
        return user.checked;
      });
    }).unsubscribe();
  }

  public copied(event: any): void {
    this.toastService.show('Copied - ' + event?.content, {classname: 'bg-secondary text-light'});
  }

  public userDetail(ban: any) {
    this.router.navigate(['users/detail', String(ban?.user?.id)]);
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }

  public selectAllResults(): void {
    this.allSelected = !this.allSelected;
    this.bans$.pipe().subscribe((users) => {
      users = users.map((user) => {
        if (user.banned) {
          user.checked = this.allSelected;
        }
        return user;
      });
      this.selectedItems = users.filter((user) => {
        return user.checked;
      });
    }).unsubscribe();
  }

  public extractUnbanTooltip(ban: any): string {
    if (!ban?.banned) {
      return 'You can\'t remove this ban because it\'s expired';
    } else {
      return 'Unban'
    }
  }

  public extractEditTooltip(ban: any): string {
    if (ban?.status === BanStatusEnum.EXPIRED) {
      return 'You can\'t edit this ban because it\'s expired';
    } else {
      return 'Edit'
    }
  }

  async banRemove(user: UserElementInterface) {
    if (this.selectedItems?.length > 0) {
      return;
    } else {
      return await this.banRemoveModal.open(user)
    }
  }

  async openUnbanModal(users: UserElementInterface[], fromList?: boolean) {
    return await this.banRemoveModal.open(users);
  }

  async editBan(ban: any, banDetail: any) {
    if (banDetail?.status === BanStatusEnum.EXPIRED) {
      return;
    } else {
      const obj = {user: ban, ...banDetail};
      return await this.banEditModal.open(obj);
    }
  }

  public extractUserFullName(user: any): string {
    let name: string[] = [];
    name.push(user?.profile?.firstName);
    name.push(user?.profile?.lastName);
    name = name.filter(function (string) {
      return !!string;
    });
    return name.join(" ");
  }


  sendUserNotificationModal(user: any[]) {
    this.notificationService.sendNotification(user);
  }

  ngOnDestroy() {
    this.service.removeSearchBans();
  }

}
