import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {Observable} from "rxjs";

@Component({
  selector: 'app-table-topbar',
  templateUrl: './table-topbar.component.html',
  styleUrls: ['./table-topbar.component.scss']
})
export class TableTopbarComponent implements OnInit, OnChanges {
  @Output() emitSearchTerm = new EventEmitter();
  @Output() openPrizeCreate: EventEmitter<any> = new EventEmitter();
  @Output() openCompetitionCreate: EventEmitter<any> = new EventEmitter();
  @Output() processErrorData: EventEmitter<any> = new EventEmitter();
  @Output() openAddMembership: EventEmitter<any> = new EventEmitter();
  @Output() openTeamCreate: EventEmitter<any> = new EventEmitter();
  @Output() openAddCodes: EventEmitter<any> = new EventEmitter();
  @Output() updateColumnsEvent: EventEmitter<any> = new EventEmitter();
  @Input() service?: any;
  @Input() total$: Observable<number>;
  @Input() id: any;
  @Input() type?: any;
  @Input() showColumnPicker?: boolean = false;
  @Input() selected: any[] = [];
  public showAreYouSure: boolean = false;
  public availableColumns: any[] = [];
  public searchTerm: any;
  public visibleColumns: any[] = [];
  public topbarTypeEnum = TopbarTypeEnum;

  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
    if (!!this.service && !!this.type) {
      this.eventService.broadcast(EventEnum.UPDATE_FILTER_MENU, this.type)
      this.eventService.broadcast(EventEnum.UPDATE_FILTER_SERVICE, this.service)
      if (this.showColumnPicker) {
        this.setVisibleColumns();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.service.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.service.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public createNewPrize(): void {
    this.openPrizeCreate.emit();
  }

  public createNewTeam(): void {
    this.openTeamCreate.emit();
  }

  public createNewCompetition(): void {
    this.openCompetitionCreate.emit();
  }

  public addMembers(): void {
    this.openAddMembership.emit();
  }

  public forceProcessErrorData():  void {
    this.processErrorData.emit();
  }

  public addCodes(): void {
    this.openAddCodes.emit();
  }

  public updateColumns(event: any): void {
    this.updateColumnsEvent.emit(event);
  }

  fieldsChange(values: any): void {
    this.emitSearchTerm.emit(this.searchTerm);
  }

}
