import {MenuItem} from '@interface/menu.interface';

export const MENU: MenuItem[] = [
  {
    id: 2,
    label: 'MENUITEMS.USERS.HEADER',
    icon: 'bxs-user-detail',
    link: '/users/list',
  },
  {
    id: 3,
    label: 'MENUITEMS.NOTIFICATIONS.HEADER',
    icon: 'bx bx-send',
    subItems: [
      {
        id: 306,
        label: 'MENUITEMS.NOTIFICATIONS.LIST',
        link: '/notifications/list',
        parentId: 3
      },
      {
        id: 302,
        label: 'MENUITEMS.NOTIFICATIONS.SCHEDULED',
        link: '/notifications/scheduled',
        parentId: 3
      },
      {
        id: 303,
        label: 'MENUITEMS.NOTIFICATIONS.SENT',
        link: '/notifications/sent',
        parentId: 3
      },
      {
        id: 304,
        label: 'MENUITEMS.NOTIFICATIONS.SETTINGS',
        link: '/notifications/settings',
        parentId: 3
      }
    ]
  },
  {
    id: 5,
    label: 'MENUITEMS.ANTIFRAUD.HEADER',
    icon: 'bx bx-error',
    subItems: [
      {
        id: 510,
        label: 'MENUITEMS.USERS.BANNED',
        link: '/users/banned',
        parentId: 505,
      },
      {
        id: 509,
        label: 'MENUITEMS.USERS.BAN_MOTIVATIONS',
        link: '/users/ban-motivations',
        parentId: 505,
      },
      {
        id: 503,
        label: 'MENUITEMS.UTILITY.BLACKLIST',
        link: '/utility/blacklist-sources',
        parentId: 507
      },
      {
        id: 506,
        label: 'MENUITEMS.ANTIFRAUD.AUTOMATIC_BAN',
        parentId: 5,
        subItems: [
          {
            id: 507,
            label: 'MENUITEMS.ANTIFRAUD.BAN_RULES',
            link: '/utility/automatic-ban',
            parentId: 506,
          },
          {
            id: 508,
            label: 'MENUITEMS.USERS.WHITELIST',
            link: '/users/whitelist',
            parentId: 506,
          }
        ]
      }
    ]
  },
  {
    id: 7,
    label: 'MENUITEMS.PRIZES.HEADER',
    icon: 'bx bx-trophy',
    subItems: [
      {
        id: 700,
        label: 'MENUITEMS.PRIZES.LIST',
        link: '/prizes/list',
        parentId: 7
      },
      {
        id: 701,
        label: 'MENUITEMS.PRIZES.ACTIVE_LIST',
        link: '/prizes/active-list',
        parentId: 7
      },
      {
        id: 702,
        label: 'MENUITEMS.PRIZES.SOLD_OUT_LIST',
        link: '/prizes/sold-out-list',
        parentId: 7
      },
      {
        id: 703,
        label: 'MENUITEMS.PRIZES.EXPIRED_LIST',
        link: '/prizes/expired-list',
        parentId: 7
      },
      {
        id: 704,
        label: 'MENUITEMS.PRIZES.PARTNERS',
        link: '/prizes/partners',
        parentId: 7
      },
      {
        id: 705,
        label: 'MENUITEMS.PRIZES.PRECONDITIONS',
        link: '/prizes/preconditions',
        parentId: 7
      },
	    {
		    id: 706,
		    label: 'MENUITEMS.PRIZES.CATEGORIES',
		    link: '/prizes/categories',
		    parentId: 7
	    }
    ]
  },
  {
    id: 8,
    label: 'MENUITEMS.STREAKS.HEADER',
    icon: 'bx bx-doughnut-chart',
    subItems: [
      {
        id: 800,
        label: 'MENUITEMS.STREAKS.LIST',
        link: '/streaks/list',
        parentId: 8
      },
      {
        id: 801,
        label: 'MENUITEMS.STREAKS.TEMPLATES',
        link: '/streaks/templates',
        parentId: 8
      }
    ]
  },
  {
    id: 12,
    label: 'MENUITEMS.CHALLENGES.HEADER',
    icon: 'bx bx-football',
    subItems: [
      {
        id: 1200,
        label: 'MENUITEMS.CHALLENGES.LIST',
        link: '/challenges/list',
        parentId: 12
      },
      {
        id: 1201,
        label: 'MENUITEMS.CHALLENGES.DURATION',
        link: '/challenges/duration',
        parentId: 12
      }
    ]
  },
  {
    id: 9,
    label: 'MENUITEMS.COMPANIES.HEADER',
    icon: 'bx bx-buildings',
    subItems: [
      {
        id: 900,
        label: 'MENUITEMS.COMPANIES.LIST',
        link: '/companies/list',
        parentId: 9
      },
      {
        id: 901,
        label: 'MENUITEMS.COMPANIES.ANALYTICS',
        link: '/companies/analytics',
        parentId: 9
      }
    ]
  },
  {
    id: 10,
    label: 'MENUITEMS.COMPETITIONS.HEADER',
    icon: 'bx bx-bar-chart-alt-2',
    subItems: [
      {
        id: 1000,
        label: 'MENUITEMS.COMPETITIONS.LIST',
        link: '/competitions/list',
        parentId: 10
      },
      {
        id: 1001,
        label: 'MENUITEMS.COMPETITIONS.STARTING',
        link: '/competitions/starting',
        parentId: 10
      },
      {
        id: 1002,
        label: 'MENUITEMS.COMPETITIONS.ENDING',
        link: '/competitions/ending',
        parentId: 10
      }
    ]
  },
  {
    id: 12,
    label: 'MENUITEMS.REWARDS.HEADER',
    icon: 'bx bx-money',
    subItems: [
	    {
		    id: 1200,
		    label: 'MENUITEMS.REWARDS.BADGES',
		    link: '/rewards/badges',
		    parentId: 12
	    },
      {
        id: 1201,
        label: 'MENUITEMS.REWARDS.LIST',
        link: '/rewards/list',
        parentId: 12
      },
      {
        id: 1202,
        label: 'MENUITEMS.REWARDS.FTOCREDITS',
        link: '/rewards/fitness-to-credits',
        parentId: 12
      }
    ]
  },
  {
    id: 11,
    label: 'MENUITEMS.FAQS.HEADER',
    icon: 'bx bx-question-mark',
    subItems: [
      {
        id: 1100,
        label: 'MENUITEMS.FAQS.LIST',
        link: '/faqs/list',
        parentId: 11
      }
    ]
  },
  {
    id: 4,
    label: 'MENUITEMS.UTILITY.HEADER',
    icon: 'bx bx-slider-alt',
    subItems: [
      {
        id: 402,
        label: 'MENUITEMS.MARKETING.NEWS',
        link: '/news/list',
        parentId: 4
      },
      {
        id: 403,
        label: 'MENUITEMS.UTILITY.TAGS',
        link: '/utility/tags',
        parentId: 4
      },
      {
        id: 404,
        label: 'MENUITEMS.UTILITY.CONTEST_METRICS',
        link: '/utility/contest-metrics',
        parentId: 4
      },
      {
        id: 405,
        label: 'MENUITEMS.UTILITY.RAW_DATA',
        link: '/utility/raw-data',
        parentId: 4
      },
      {
        id: 406,
        label: 'MENUITEMS.USERS.OPERATORS',
        link: '/users/operators',
        parentId: 4
      }
    ]
  },
  {
    id: 12,
    label: 'MENUITEMS.CONFIG.APP_CONFIG',
    icon: 'dripicons-view-apps',
    subItems: [
      {
        id: 1202,
        label: 'MENUITEMS.CONFIG.RECOMMENDED_APPS',
        link: '/app-config/recommended-apps',
        parentId: 11
      },
      {
        id: 1204,
        label: 'MENUITEMS.CONFIG.BENEFITS',
        link: '/app-config/benefits',
        parentId: 11
      },
      {
        id: 1205,
        label: 'MENUITEMS.CONFIG.SPORTS',
        link: '/app-config/sports/list',
        parentId: 11
      },
      {
        id: 1206,
        label: 'MENUITEMS.CONFIG.HOW_TO_SYNC',
        link: '/app-config/how-to-sync',
        parentId: 11
      },
      {
        id: 1207,
        label: 'MENUITEMS.CONFIG.ACTIVITIES',
        link: '/app-config/activities',
        parentId: 11
      }
    ]
  },
  {
    id: 13,
    label: 'CONTENTS',
    icon: 'bx bx-news',
    subItems: [
      {
        id: 1302,
        label: 'CATEGORIES',
        link: '/content/categories',
        parentId: 13
      },
      {
        id: 1303,
        label: 'CONTENT_LIST',
        link: '/content/list',
        parentId: 13
      }
    ]
  },
  {
    id: 13,
    label: 'PROJECTS_MANAGEMENT',
    icon: 'bx bxs-grid-alt',
    subItems: [
      {
        id: 1302,
        label: 'PLACES',
        link: '/projects/places',
        parentId: 13
      },
      {
        id: 1302,
        label: 'BENEFITS',
        link: '/projects/benefits',
        parentId: 13
      },
      {
        id: 1303,
        label: 'PARTNERS',
        link: '/projects/partners',
        parentId: 13
      },
      {
        id: 1303,
        label: 'PROJECTS',
        link: '/projects/list',
        parentId: 13
      }
    ]
  },
  {
    id: 15,
    label: 'DEEPLINKS',
    icon: 'bx bx-link',
    subItems: [
      {
        id: 1501,
        label: 'MENUITEMS.UTILITY.INVITES',
        link: '/utility/invites',
        parentId: 15
      },
      {
        id: 1502,
        label: 'MENUITEMS.UTILITY.QR_CODES',
        link: '/utility/qr-code',
        parentId: 15
      }
    ]
  }
];

