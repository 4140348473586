import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {RecommendedAppsApiService} from "@service/app-config/recommended-apps/recommended-apps-api.service";
import {BenefitsApiService} from "@service/app-config/benefits/benefits-api.service";
import {ActivitiesService} from "@service/app-config/activities/activities.service";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-activity-modal',
  templateUrl: './activity-modal.component.html',
  styleUrls: ['./activity-modal.component.scss']
})
export class ActivityModalComponent implements OnInit {
  @Input() activity: any;
  @Input() type: any;
  public list: any = this.utils.getFitnessMetrics();
  public showAreYouSure: boolean = false;
  public addingActivity$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public activityDetailForm: UntypedFormGroup;
  public limit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public activeId: number = 1;
  public recommendedApps$: any;
  public benefits$: any;
  public sports$: any;
  public maxPriority: number | undefined;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
              private recommendedAppsApiService: RecommendedAppsApiService, private benefitsApiService: BenefitsApiService,
              private _formService: FormsService,
              private activitiesService: ActivitiesService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.addingActivity$ = this.activitiesService.addingActivity$;
    this.initForm();
    this.getRecommendedApps();
    this.getBenefits();
    this.getSports();
    (this.activityDetailForm.get('steps') as UntypedFormArray)?.clear();
    if (this.activity) {
      this.activityDetailForm.patchValue(this.activity);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public validSubmit() {
    if (this.activityDetailForm.valid) {
      this.activitiesService.addActivity(this.activityDetailForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.activityDetailForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.activityDetailForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  public setFitnessMetric(metric: any): void {
    this.activityDetailForm.patchValue({['fitnessMetric']: metric});
  }

  get form() {
    return this.activityDetailForm.controls;
  }

  get valid() {
    return this.activityDetailForm.valid
  }

  public setImage(image: any): void {
    this.activityDetailForm.patchValue({
      image: image ? image?.originalUrl : undefined,
      imageName: image ? image?.name : undefined,
      imageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.image?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.image?.value) {
      return {originalUrl: this.form.image?.value, name: [this.form.recommendedAppsTitle?.value?.replace(/\s/g, "_"), 'detail_image'].join('_')}
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.activityDetailForm = this.formBuilder.group({
      recommendedAppsTitle: [!this.activity || !this.activity?.recommendedAppsTitle ? null : this.activity?.recommendedAppsTitle],
      description: [!this.activity || !this.activity?.description ? null : this.activity?.description, [Validators.required]],
      image: [!this.activity || !this.activity?.image ? null : this.activity?.image, [Validators.required]],
      imageName: [!this.activity || !this.activity?.image ? null : [this.activity?.recommendedAppsTitle?.replace(/\s/g, "_"), 'detail_image'].join('_')],
      imageType: [!this.activity || !this.activity?.image ? null : this.activity?.image],
      id: [this.activity?.id && !this.activity.duplicate ? this.activity?.id : null],
      fitnessMetric: [this.activity?.fitnessMetric ? this.activity?.fitnessMetric : 'TotalStepsCount'],
      recommendedAppIds: [this.activity?.recommendedApps ? this.activity?.recommendedApps : []],
      benefitIds: [this.activity?.benefits ? this.activity?.benefits : []],
      sportIds: [this.activity?.sports ? this.activity?.sports : []],
      syncStepsAndroid: this.formBuilder.array([this.addItemGroup()]),
      syncStepsIos: this.formBuilder.array([this.addItemGroup()]),
    });
    if (this.activity) {
      this.checkSyncSteps('syncStepsIos');
      this.checkSyncSteps('syncStepsAndroid');
    }
  }

  private checkSyncSteps(key: string) {
    if (this.activity[key]?.length > 0) {
      const syncSteps = this.activityDetailForm.get(key) as UntypedFormArray;
      syncSteps.clear();
      this.activity[key].forEach(step => {
        const group = this.addItemGroup();
        Object.keys(group.controls).forEach(key => {
          group.patchValue({
            [key]: step[key]
          });
        });
        syncSteps.controls.push(group);
      });
    }
  }

  addItemGroup() {
    return this.formBuilder.group({
      numbering: [],
      title: [],
      description: [],
      languages: [],
      languageToTranslationUrl: [],
      localizableKey: []
    });
  }

  addItemFieldsAndroid() {
    this.itemsArrayAndroid.push(this.addItemGroup());
  }

  removeItemFieldsAndroid(index) {
    this.itemsArrayAndroid.removeAt(index);
  }

  get itemsArrayAndroid(){
    return this.activityDetailForm.get("syncStepsAndroid") as UntypedFormArray;
  }

  get itemsArrayiOS(){
    return this.activityDetailForm.get("syncStepsIos") as UntypedFormArray;
  }

  addItemFieldsiOs() {
    this.itemsArrayiOS.push(this.addItemGroup());
  }

  removeItemFieldsiOs(index) {
    this.itemsArrayiOS.removeAt(index);
  }

  private getRecommendedApps(): void {
    const filters = {page: 1, size: 10000};
    this.recommendedApps$ = this.recommendedAppsApiService.search(filters);
  }

  private getBenefits(): void {
    const filters = {page: 1, size: 10000, type: 'BENEFIT'};
    this.benefits$ = this.benefitsApiService.search(filters);
  }

  private getSports(): void {
    const filters = {page: 1, size: 10000, type: 'SPORT'};
    this.sports$ = this.benefitsApiService.search(filters);
  }
}
