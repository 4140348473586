<label class="form-label font-weight-semibold mb-1 image-label">{{'FILE' | translate}}</label>
<div class="col-md-12">
  <div class="input-group mt-1">
    <input (click)="openUploader()" [placeholder]="selectedFile?.name ? selectedFile?.name : 'Please choose file'"
           class="form-control bg-white" disabled
           id="title">
    <div class="input-group-append">
      <span (click)="openUploader()" *ngIf="!selectedFile"
            class="input-group-text btn btn-primary pointer remove-radius-left">Choose file</span>
      <span (click)="showFile()" *ngIf="selectedFile"
            class="input-group-text btn-primary pointer remove-radius-left remove-radius-right">Show file</span>
    </div>
    <div *ngIf="selectedFile" class="input-group-append">
      <span (click)="clearFile()" class="input-group-text btn-dark pointer remove-radius-left">Remove file</span>
    </div>
  </div>
</div>
<ngx-uploadcare-widget
  #uploader
  (on-upload-complete)="upload($event)"
  [class.d-none]="true"
  clearable="true"
  data-file-types="pdf"
  preview-step="true"
  public-key="demopublickey"
  tabs="file gdrive gphotos dropbox">
</ngx-uploadcare-widget>
