import {Injectable} from '@angular/core';
import {RewardsApiService} from "@service/rewards/rewards-api.service";
import {BehaviorSubject, of, Subject, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class FitnessToCreditsService {
  public _delete$ = new Subject<any>();
  public _create$ = new Subject<string>();
  public _search$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.ftoTableColumn;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.ftoTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchRewardsSubscription: Subscription;
  private createFtoSlotSubscription: Subscription;
  private deleteRewardsSubscription: Subscription;

  constructor(private rewardsApiService: RewardsApiService, private eventService: EventService,
              private toastService: ToastService, private utils: UtilsService) {
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }


  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _fitnessToCredtisList$ = new BehaviorSubject<any[]>([]);

  public get fitnessToCredtisList$() {
    return this._fitnessToCredtisList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public set searchTermLocal(searchTerm: string) {
    this._currentTableState.searchTerm = searchTerm;
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get action() {
    return this._currentTableState.action;
  }

  set action(action: string) {
    this._setValue({action});
  }

  get validForCompetitions() {
    return this._currentTableState.validForCompetitions;
  }

  set validForCompetitions(validForCompetitions: boolean | undefined | null) {
    this._setValue({validForCompetitions});
  }

  get credits() {
    return this._currentTableState.credits;
  }

  set credits(credits: string[]) {
    this._setValue({credits});
  }

  get validityDate() {
    return this._currentTableState.validityDate;
  }

  set validityDate(validityDate: string[]) {
    this._setValue({validityDate});
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public metricDetail(metric: any): any {
    return this.rewardsApiService.getMetricDetail(metric);
  }

  public initDeleteListener(): void {
    this.deleteRewardsSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((streak) => this.rewardsApiService.deleteFtoSlot(streak).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Slot deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public initSearchListener(): void {
    this.searchRewardsSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.rewardsApiService.getFtoList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      const groups = this.orderMetricsByType(result);
      const metrics = this.populateMetrics(groups).sort((a, b) => (a.priority > b.priority ? -1 : 1));
      this._fitnessToCredtisList$.next(metrics);
      this._totalRecords$.next(result?.length);
    });
  }

  public removeSearchRewardsSubscribe(): void {
    this.searchRewardsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public clearFilters(): void {
    this.searchTerm = undefined;
    this.action = undefined;
    this.action = undefined;
    this.validForCompetitions = undefined;
    this.credits = undefined;
    this.validityDate = undefined;
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  public createFtoSlot(data: any): void {
    this._create$.next(data);
  }

  public initCreateListener(): void {
    this.createFtoSlotSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.rewardsApiService.createFtoSlot(data).pipe(
        map((result: any) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_CREATE_FTO, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Slot edited successfully' : 'Slot created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_CREATE_FTO, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_CREATE_FTO);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public removeCreateFtoSlotSubscription(): void {
    this.createFtoSlotSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public removeDeleteSubscribe(): void {
    this.deleteRewardsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  private orderMetricsByType(result) {
    return result.reduce((groups, item) => ({
      ...groups,
      [item.fitnessMetric]: [...(groups[item.fitnessMetric] || []), item]
    }), {});
  }

  private populateMetrics(groups) {
    let metrics: any = [];
    for (const [key, value] of Object.entries(groups)) {
      const slots = this.sortByNearestDates(value);
      metrics.push({fitnessMetric: key, list: slots, priority: slots[0]?.priority, metricImage: slots[0]?.metricImage})
    }
    return metrics;
  }

  private sortByNearestDates(value) {
    return value.sort((a, b) => {
      // @ts-ignore
      return Math.abs(Date.now() - new Date(a.validUntilExclusive)) - Math.abs(Date.now() - new Date(b.validUntilExclusive));
    });
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        action: (this.action !== undefined && this.action !== null) ? [this.action] : undefined,
        validForCompetitions: (this.validForCompetitions !== undefined && this.validForCompetitions !== null) ? [this.validForCompetitions] : undefined,
        credits: this.credits && this.credits?.length > 0 ? this.credits : undefined,
        validityDate: this.validityDate && this.validityDate?.length > 0 ? this.validityDate : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }
}
