import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {ContentCategoriesService} from "@service/content/content-categories.service";
import {PrizesCategoriesSearchService} from "@service/prizes/categories/prizes-categories-search.service";

@Component({
  selector: 'app-add-prize-category-modal',
  templateUrl: './add-prize-category-modal.component.html',
  styleUrls: ['./add-prize-category-modal.component.scss']
})
export class AddPrizeCategoryModalComponent implements OnInit {
	@Input() prizeCategory: any;
	public showAreYouSure: boolean = false;
	public adding$: Observable<boolean>;
	public formSubmitted: boolean = false;
	public prizeCategoryForm: UntypedFormGroup;
	public logoImageLimit: any = {
		fixedSize: true,
		ratio: '1:1',
		width: 500,
		height: 500
	};
	
	constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
	            private prizeCategoryService: PrizesCategoriesSearchService) {
	}
	
	get form() {
		return this.prizeCategoryForm.controls;
	}
	
	get valid() {
		return this.prizeCategoryForm.valid
	}
	
	ngOnInit(): void {
		this.adding$ = this.prizeCategoryService.adding$;
		this.initForm();
	}
	
	public validSubmit() {
		if (this.prizeCategoryForm.valid) {
			this.prizeCategoryService.addCategory(this.prizeCategoryForm.getRawValue());
		}
	}
	
	public checkNextActionBtn(): void {
		if (this.prizeCategoryForm.valid) {
			this.showAreYouSure = true;
		} else {
			this.formSubmitted = true;
		}
	}
	
	public setImage(image: any): void {
		if (!image) {
			this.removeImage();
		} else {
			this.prizeCategoryForm.patchValue({
				iconUrl: image?.originalUrl,
				imageName: image?.title,
				imageType: image?.mimeType,
			});
		}
	}
	
	public checkSelectedImage(): object | undefined {
		if (this.form?.iconUrl?.value) {
			return {
				name: this.form?.imageName?.value,
				originalUrl: this.form?.iconUrl?.value,
				mimeType: this.form?.imageType?.value
			}
		} else {
			return null;
		}
	}
	
	public removeImage(): void {
		this.prizeCategoryForm.patchValue({
			iconUrl: null,
			imageName: null,
			imageType: null,
		});
	}
	
	public checkImageErrors(): string | undefined {
		if (this.formSubmitted && this.form.iconUrl.errors?.required) {
			return 'This value is required';
		} else {
			return undefined;
		}
	}
	
	public patchValue(event: any): void {
		this.prizeCategoryForm?.get('prizeIds')?.patchValue(event?.map((event) => event?.id));
	}
	
	selectedValue(): any {
		return this.prizeCategoryForm?.get('prizeIds')
	}
	
	private initForm(): void {
		this.prizeCategoryForm = this.formBuilder.group({
			name: [!this.prizeCategory || !this.prizeCategory?.name ? null : this.prizeCategory?.name, [Validators.required, Validators.minLength(1), Validators.maxLength(65)]],
			prizeIds: [[104]],
			iconUrl: [!this.prizeCategory || !this.prizeCategory?.iconUrl ? null : this.prizeCategory?.iconUrl, [Validators.required]],
			imageName: [!this.prizeCategory || !this.prizeCategory?.iconUrl ? null : this.prizeCategory?.name],
			imageType: [null],
			id: [this.prizeCategory?.id && !this.prizeCategory.duplicate ? this.prizeCategory?.id : null]
		});
	}
}
