import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, Subject, take} from "rxjs";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {NotificationsApiService} from "@service/notifications/notifications-api.service";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";
import {UserElementInterface} from "@interface/user/user-element.interface";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {ActionsEnum} from "@enum/notifications/actions/actions.enum";
import {NotificationTableColumnEnum} from "@enum/notifications/notification-table-column/notification-table-column.enum";
import {NotificationDeliveryOptionEnum} from "@enum/notifications/notification-delivery-option/notification-delivery-option.enum";
import {Router} from "@angular/router";
import {NotificationModalComponent} from "@component/notifications/notification-modal/notification-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public _newNotification$ = new Subject<void>();
  public _sendNotification$ = new Subject<void>();
  public _search$ = new Subject<void>();
  public _delete$ = new Subject<any>();
  public visibleColumns: { visible: boolean, key: NotificationTableColumnEnum, label: any }[] = this.utils.notificationTableColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.notificationTableFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private notificationLabel = 'Notification sent';
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private notificationsApiService: NotificationsApiService, private eventService: EventService, private modalService: NgbModal,
              private toastService: ToastService, private router: Router, private utils: UtilsService) {
    this.initSendNotificationListener();
    // this.initSearchNotificationListener();
    this.initDeleteListener();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _sending$ = new BehaviorSubject<boolean>(false);

  public get sending$() {
    return this._sending$.asObservable();
  }

  private _notificationList$ = new BehaviorSubject<UserElementInterface[]>([]);

  public get notificationList$() {
    return this._notificationList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get columns() {
    return this.visibleColumns;
  }

  public get filters() {
    return this.visibleFilters;
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  public get alreadySent() {
    return this._currentTableState.alreadySent;
  }

  public set alreadySent(alreadySent: boolean) {
    if (alreadySent === true) {
      this.visibleColumns = this.utils.sentNotificationTableColumns;
    } else {
      this.visibleColumns = this.utils.notificationTableColumns;
    }
    this._setValue({alreadySent})
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  public delete(data?: any): void {
    this._delete$.next(data);
  }

  public newPushNotification(notificationData: any): void {
    this._newNotification$.next(notificationData);
  }

  public sendPushNotification(notificationData: any): void {
    this._sendNotification$.next(notificationData);
  }

  public initSendNotificationListener(): void {
    this._sendNotification$.pipe(
      tap(() => this._sending$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((pushNotificationData) => this.notificationsApiService.sendPushNotification(this.convertSendPushRequest(pushNotificationData)).pipe(
        map((result) => {
          this._sending$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_NOTIFICATION_MODAL, this.notificationLabel, true, pushNotificationData);
        }),
        catchError((err, caught) => {
          this._sending$.next(false);
          return this.modalError(err, EventEnum.CLOSE_NOTIFICATION_MODAL, true);
        })
      )),
      tap(() => this._sending$.next(false))
    ).subscribe((result) => {
    });
  }

  public sendMultipleSingleNotification(notifications: any[]): any {
    this._sending$.next(true);
    let requests: Observable<any>[] = [];
    for (let notification of notifications) {
      requests.push(this.notificationsApiService.sendPushNotification(notification).pipe(catchError(error => of(error))));
    }
    forkJoin(requests.map(o => o.pipe(tap((res) => {
    })))).subscribe((res: any[]) => {
      res?.map((notification) => {
        if (notification?.id) {
          this.toastService.show('Notifications scheduled', {classname: 'bg-success text-light'});
        }
      })
      this._sending$.next(false);
      this.eventService.broadcast(EventEnum.CLOSE_NOTIFICATION_MODAL, null)
    });
  }

  public sendSingleNotification(notificationData: any): any {
    this._sending$.next(true);
    this.notificationsApiService.sendPushNotification(notificationData).pipe(catchError(error => of(error))).subscribe((result: any) => {
      if (result?.id) {
        this.toastService.show('Notifications scheduled', {classname: 'bg-success text-light'});
      }
      this._sending$.next(false);
      this.eventService.broadcast(EventEnum.CLOSE_NOTIFICATION_MODAL, null)
    });
  }

  public sendScheduledNotification(dates: any[], hour: number | null, minutes: number | null, notifications: any, edit?: string): any {
    this._sending$.next(true);
    let requests: Observable<any>[] = [];
    dates?.map((date: any) => {
      if (Array.isArray(notifications)) {
        for (let notification of notifications) {
          this._generateRequest(notification, date, hour, requests);
        }
      } else {
        this._generateRequest(notifications, date, hour, requests);
      }
      return date;
    });
    requests = requests.reverse();
    if (edit) {
      requests.unshift(this.notificationsApiService.deleteRecurringNotification(edit))
    }
    forkJoin(requests.map(o => o.pipe(tap((res) => {
    })))).subscribe((res: any[]) => {
      res?.map((notification) => {
        if (notification?.id) {
          this.toastService.show('Notifications scheduled', {classname: 'bg-success text-light'});
        }
      })
      this._sending$.next(false);
      this.eventService.broadcast(EventEnum.CLOSE_NOTIFICATION_MODAL, null)
    });
  }

  public search(): void {
    this._search$.next();
  }

  public initSearchNotificationListener(): void {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.notificationsApiService.getNotificationList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._notificationList$.next(result.data);
      this._totalRecords$.next(result.size);
    });
  }

  public initDeleteListener(): void {
    this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((notifications) => this.notificationsApiService.deleteNotifications(notifications).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'Notification deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public convertSendPushRequest(data: any): any {
    this.setNotificationLabel(data);
    return {
      id: data?.data?.id ? data?.data?.id : undefined,
      title: data?.data?.title,
      body: data?.data?.message,
      audience: data?.audience?.audience,
      destinationIds: this.extractDestinationIds(data?.audience),
      imageUrl: data?.data?.imageUrl,
      scheduledAt: data?.delivery?.startsAt,
      locale: data?.data?.locale,
      scheduledAtHour: data?.delivery?.startsAtHour,
      link: data?.data?.action === ActionsEnum.OPEN_LINK ? data?.data?.actionDetail : null,
      action: (data?.data?.action !== ActionsEnum.OPEN_LINK) ? data?.data?.action : null,
      actionDetail: this.extractActionDetail(data),
      note: data?.data?.note
    }
  }

  public getNotificationActions() {
    return this.notificationsApiService.getNotificationActionsList();
  }

  public getNotificationAudience() {
    return this.notificationsApiService.getNotificationAudienceList();
  }

  public getAllNotifications() {
    return this.notificationsApiService.getAllNotifications();
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.notificationsApiService.getNotificationList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  public extractSelectedDates(delivery: string, date: string | undefined, hour: string | undefined, minutes: string | undefined, recurring: any): any {
    switch (delivery) {
      case 'NOW':
        return {date: null, hour: null, minutes: null};
        break;
      case 'SPECIFIC_DATE':
        return {
          date: this.utils.sanitizeSimpleDatePickerDate(date),
          hour: Number(hour ? hour : 9),
          minutes: Number(minutes ? minutes : 0)
        };
        break;
      case 'RECURRING':
        return {
          date: this.sanitizeRecurringDates(recurring?.dates),
          hour: Number(recurring?.form?.hour ? recurring?.form?.hour : 9),
          minutes: Number(recurring?.form?.minutes ? recurring?.form?.minutes : 0)
        };
        break;
    }
  }

  public extractNotificationDestinationIds(audienceIds: any): number[] {
    if (Array.isArray(audienceIds)) {
      if (audienceIds.every(element => (typeof element === "number"))) {
        return audienceIds;
      } else {
        return audienceIds.map((element) => {
          return element?.id
        })
      }
    } else {
      return [audienceIds?.id]
    }
  }

  public extractNotificationActionDetail(action: ActionsEnum, detail: any): string | null {
    switch (action) {
      default:
        if (typeof detail === 'object' && detail?.id) {
          return detail?.id?.toString();
        } else if (typeof detail === 'string') {
          return detail;
        } else {
          return null;
        }
        break;
    }
  }

  public extractNotificationRecurringGroup(): string {
    return (Math.random() + 1).toString(36).substring(2);
  }

  public sendNotification(users: any[]): void {
    const edit = this.modalService.open(NotificationModalComponent, {size: 'xl', centered: true, backdrop: false});
    if (users?.length > 0) edit.componentInstance.specificUsers = users;
    edit.result.then((result) => {
    }, (reason) => {
    });
    const close = this.eventService.subscribe(EventEnum.CLOSE_NOTIFICATION_MODAL, (reason: string | undefined) => {
      edit.dismiss();
    });
  }

  private _generateRequest(notificationData: any, date: any, hour: number, requests: Observable<any>[]) {
    let notification = JSON.parse(JSON.stringify(notificationData))
    notification.scheduledAt = date;
    notification.scheduledAtHour = hour ? hour : 9;
    requests.push(this.notificationsApiService.sendPushNotification(notification).pipe(catchError(error => of(error))));
  }

  private sanitizeRecurringDates(dates: Date[]): any {
    return dates?.map((date: Date) => {
      return (typeof date === 'string' || date instanceof String) ? this.utils.sanitizeDate(date) : this.utils.sanitizeDate(date?.toISOString())
    });
  }

  private setNotificationLabel(data: any) {
    if (data?.delivery?.deliveryOption === NotificationDeliveryOptionEnum.FUTURE) {
      this.notificationLabel = 'Notification scheduled'
    } else {
      this.notificationLabel = 'Notification sent'
    }
  }

  private extractActionDetail(data: any) {
    let actionsDetail;
    if (data?.data?.action === ActionsEnum.OPEN_LINK) {
      actionsDetail = null;
    } else {
      actionsDetail = data?.data?.actionDetail?.id?.toString();
    }
    return actionsDetail;
  }

  private extractDestinationIds(audience: { audience: AudienceEnum, data: any }): any {
    switch (audience.audience) {
      case AudienceEnum.ALL_USERS:
        return null;
      case AudienceEnum.ALL_USERS_IN_CHALLENGE:
        return audience?.data?.challenges.map(x => x?.id);
      case AudienceEnum.ALL_USERS_IN_COMPANY:
        return audience?.data?.companies.map(x => x?.id);
      case AudienceEnum.ALL_USERS_IN_COMPETITION:
        return audience?.data?.competitions.map(x => x?.id);
      case AudienceEnum.SINGLE_USERS:
        return audience?.data?.users.map(x => x?.id);
      default:
        return null;
    }
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
      },
      alreadySent: (this.alreadySent !== undefined && this.alreadySent !== null) ? this.alreadySent : undefined,
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private modalError(err, modalEvent: EventEnum, disableSearch?: boolean) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    if (!disableSearch) {
      this._search$.next();
    }
    return err;
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string, disableSearch?: boolean, data?: any) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    if (!disableSearch) {
      this._search$.next();
    }
    if (data?.expanded && data?.delivery?.startsAt) {
      this.router.navigate(['notifications/scheduled']);
    } else if (data?.expanded && !data?.delivery?.startsAt) {
      this.router.navigate(['notifications/sent']);
    }
    return result;
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
