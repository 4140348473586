import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'company'
})
export class CompanyPipe implements PipeTransform {
  public transform(value, keys: string, term: string | number) {
    if (!term) return value;
    return (value || []).filter((obj) => obj?.company?.id === term);

  }
}
