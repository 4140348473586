<ng-container *ngIf="actions?.length > 0">
  <div class="col-12 mb-3 single-ng-select" [formGroup]="parentForm" *ngIf="!hideActionSelect">
    <label class="font-weight-bold" for="action">{{'ACTION' | translate}}</label>
    <ng-select [clearable]="true" [items]="actions"  bindLabel="name" [searchFn]="search"
               bindValue="value" [multiple]="false" [formControlName]="control">
      <ng-template let-item="item" ng-label-tmp>
        <span>{{ item?.label }}</span>
      </ng-template>
      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
        <span class="font-weight-semibold ms-2">{{item?.label}}</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-12 mb-3" *ngIf="!hideActionDetail">
    <ng-container *ngIf="selectedAction(enum.COMPANY)">
      <label class="font-weight-bold" for="action">{{'COMPANY' | translate}}</label>
      <p class="card-title-desc mb-2">If you don't specify any company the user will be redirected to the company section.</p>
      <app-company-select
          (selectedCompany)="patchValue($event)"
          [selected]="selectedValue()"
          [error]="errors()"
      ></app-company-select>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.COMPETITION) || selectedAction(enum.COOPERATIVE_COMPETITION)">
      <label class="font-weight-bold" for="action">{{'COMPETITION' | translate}}</label>
      <app-competition-select
          (selectedCompetition)="patchValue($event)"
          [selected]="selectedValue()"
          [error]="errors()"
      ></app-competition-select>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.PRIZE)">
      <label class="font-weight-bold" for="action">{{'PRIZE' | translate}}</label>
      <p class="card-title-desc mb-2">If you don't specify any prize the user will be redirected to the prize section.</p>
      <app-prize-select
          (selectedPrize)="patchValue($event)"
          [selected]="selectedValue()"
          [complete]="true"
          [restriction]="audience"
          [restrictionId]="audienceIds"
          [error]="errors()"
      ></app-prize-select>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.FAQ)">
      <label class="font-weight-bold" for="action">{{'FAQ' | translate}}</label>
      <p class="card-title-desc mb-2">If you don't specify any faq the user will be redirected to the faq section.</p>
      <app-faq-select
          (selectedFaq)="patchValue($event)"
          [selected]="selectedValue()"
          [error]="errors()"
      ></app-faq-select>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.BADGE)">
      <label class="font-weight-bold" for="action">{{'BADGE' | translate}}</label>
      <p class="card-title-desc mb-2">If you don't specify any badge the user will be redirected to the badge section.</p>
      <app-badge-select (selectedBadge)="patchValue($event)" [selected]="selectedValue()"></app-badge-select>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.OPEN_FIXED_WEBVIEW) || selectedAction(enum.OPEN_CLOSABLE_WEBVIEW) || selectedAction(enum.LINK)">
      <label class="font-weight-bold" for="action">{{'LINK' | translate}}</label>
      <app-link-validator [parentForm]="parentForm" (selectedLink)="patchValue($event)" [selected]="selectedValue()" [error]="errors()"></app-link-validator>
    </ng-container>
    <ng-container *ngIf="selectedAction(enum.CHARITY_PRIZE)">
      <app-prize-select
          (selectedPrize)="patchValue($event)"
          [selected]="selectedValue()"
          [complete]="true"
          [restriction]="AudienceEnum.TYPE"
          [restrictionId]="'CHARITY'"
          [error]="errors()"
      ></app-prize-select>
    </ng-container>
  </div>
</ng-container>