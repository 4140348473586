import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {Observable} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {PrizesService} from "@service/prizes/prizes.service";
import {UtilsService} from "@service/utils/utils.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-prize-list-page',
  templateUrl: './prize-list-page.component.html',
  styleUrls: ['./prize-list-page.component.scss']
})
export class PrizeListPageComponent implements OnInit {
  @Input() filters?: any;
  public selectedItems: any[] = [];
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.PRIZE_LIST_PAGE_DETAIL;
  public allSelected = false;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public prizeList$: Observable<any[]>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  @ViewChild('users') private users: any
  @ViewChild('deactivateModal') private deactivateModal: any
  @ViewChild('activateModal') private activateModal: any
  @ViewChild('prizeCodes') private prizeCodes: any
  @ViewChild('prizeModal') private modalComponent: any
  private mode: string | undefined = undefined;

  constructor(public prizesService: PrizesService, private cdr: ChangeDetectorRef,
              public utils: UtilsService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.prizeList$ = this.prizesService.prizeList$;
    this.total$ = this.prizesService.totalRecords$;
    this.loading$ = this.prizesService.loading$;
    this.sort({column: "createdAt", direction: "desc"});
    this.setVisibleColumns();
    this.prizesService.visibleFilters = this.utils.prizesTableFilters;
    this.prizesService.clearFilters();
    if (this.filters?.contextType) {
      this.prizesService.contextType = this.filters?.contextType;
      this.prizesService.contextDetailsId = this.filters?.contextDetailsId;
    }
    this.prizesService.initSearchPrizesListener();
    this.prizesService._search$.next();
    this.cdr.detectChanges();
  }

  public setVisibleColumns(): void {
    this.visibleColumns = JSON.parse(JSON.stringify(this.prizesService.columns));
    this.availableColumns = JSON.parse(JSON.stringify(this.prizesService.columns));
    this.visibleColumns = this.visibleColumns.filter((column) => {
      return column.visible;
    });
    this.selected = this.visibleColumns.filter((column) => {
      return column.visible;
    });
  }

  public sort({column, direction}: SortEventInterface) {
    this.prizesService.sortColumn = column;
    this.prizesService.sortDirection = direction;
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.prizesService.page - 1) * this.prizesService.pageSize + index + 1;
  }

  public userRedeemed(prize: any): void {

  }

  async editPrize(prize: any, duplicate?: boolean) {
    prize.duplicate = duplicate;
    return await this.modalComponent.open(prize)
  }

  async prizeActivateDeactivate(prize: any, activate: boolean) {
    if (!activate) {
      const title = 'DEACTIVATE_PRIZE';
      const description = 'DEACTIVATE_PRIZE_DESC';
      return await this.deactivateModal.open(prize, title, description)
    } else if (activate) {
      const title = 'ACTIVATE_PRIZE';
      const description = 'ACTIVATE_PRIZE_DESC';
      return await this.activateModal.open(prize, title, description)
    }
  }

  public duplicatePrize(prize: any): void {

  }

  public deletePrize(prize: any): void {

  }

  public async createPrize() {
    const prize = {contextType: this.filters?.contextType, contextDetailsId: this.filters?.contextDetailsId};
    return await this.modalComponent.open(prize);
  }

  public async openPrizeCodes(prize) {
    if (prize?.prizeType === 'code_redemption') {
      return await this.prizeCodes.open(prize);
    }
  }

  public prizeDetail(prize: any): void {
    // return await this.modalComponent.open(streak);
  }

  public extractPrizeDescription(description): string {
    return description.replace(/<[^>]*>?/gm, '');
  }

  public openLink(url: any) {
    window.open(url, '_blank');
  }

  async showUsers(prize: any) {
    return await this.users.open(prize, false, true);
  }


  ngOnDestroy() {
    this.prizesService.removeSearchPrizesSubscribe();
  }

}
