import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, ReplaySubject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {BenefitsService} from "@service/app-config/benefits/benefits.service";
import {BenefitType} from "@service/app-config/benefits/benefit-type";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-benefit-modal',
  templateUrl: './benefit-modal.component.html',
  styleUrls: ['./benefit-modal.component.scss']
})
export class BenefitModalComponent implements OnInit, OnDestroy {
  @Input() benefit: any;
  @Input() type: BenefitType;
  public BenefitType = BenefitType;
  public showAreYouSure: boolean = false;
  public addingBenefit$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public benefitForm: UntypedFormGroup;
  public limit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public maxPriority: number | undefined;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private toastService: ToastService, public utils: UtilsService,
              private _formService: FormsService,
              private formBuilder: UntypedFormBuilder,
              private benefitsService: BenefitsService) { }

  ngOnInit(): void {
    this.addingBenefit$ = this.benefitsService.addingBenefit$;
    this.initForm();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public validSubmit() {
    if (this.benefitForm.valid) {
      this.benefitsService.addBenefit(this.benefitForm.getRawValue());
    } else {
      this._formService.findInvalidControlsRecursive(this.benefitForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.benefitForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.benefitForm.controls;
  }

  get valid() {
    return this.benefitForm.valid
  }

  public setImage(image: any): void {
    this.benefitForm.patchValue({
      imageUrl: image ? image?.originalUrl : undefined,
      imageName: image ? image?.name : undefined,
      imageType: image ? image?.mimeType : undefined,
    });
  }

  public checkLockedImageError(): string | undefined {
    if (this.formSubmitted && this.form.imageUrl?.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedLockedImage(): object | undefined {
    if (this.form.imageUrl?.value) {
      return {originalUrl: this.form.imageUrl?.value, name: [this.form.title?.value?.replace(/\s/g, "_"), 'detail_image'].join('_')}
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.benefitForm = this.formBuilder.group({
      title: [!this.benefit || !this.benefit?.title ? null : this.benefit?.title, [Validators.required]],
      priority: [!this.benefit || !this.benefit?.priority ? null : this.benefit?.priority, [Validators.required]],
      link: [!this.benefit || !this.benefit?.link ? null : this.benefit?.link, [Validators.pattern(this.utils.validateUrlRegex)]],
      imageUrl: [!this.benefit || !this.benefit?.imageUrl ? null : this.benefit?.imageUrl, [Validators.required]],
      imageName: [!this.benefit || !this.benefit?.imageUrl ? null : [this.benefit?.title?.replace(/\s/g, "_"), 'detail_image'].join('_')],
      imageType: [!this.benefit || !this.benefit?.imageUrl ? null : this.benefit?.imageUrl],
      languageToTranslationUrl: [!this.benefit || !this.benefit?.languageToTranslationUrl ? null : this.benefit?.languageToTranslationUrl],
      restrictsLocales: [!this.benefit || !this.benefit?.restrictsLocales ? null : this.benefit?.restrictsLocales],
      id: [this.benefit?.id && !this.benefit.duplicate ? this.benefit?.id : null],
      active: [this.benefit?.active && !this.benefit.duplicate ? this.benefit?.active : false],
      type: [this.type],
    });
    this.takeMaxPriority();
  }


  private takeMaxPriority(): void {
    this.benefitsService.getMaxPriority().pipe(takeUntil(this.destroyed$)).subscribe((result: any) => {
      if (result?.data?.length > 0 && result?.data[0]?.priority) {
        this.maxPriority = result?.data[0]?.priority;
        if (!this?.benefit) {
          this.benefitForm.patchValue({['priority']: this.maxPriority});
        }
      }
    });
  }

}
