import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hour'
})

export class HourPipe implements PipeTransform {

  transform(selectedHour: number): string {
    const minutes = selectedHour * 60;
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutesLeft < 10 ? '0' : ''}${minutesLeft}:00`
  }

}
