<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">{{extractBanTypeTitle() | translate}}</h5>
    <!--    <label class="btn btn-dark mb-0 btn-export">-->
    <!--      <i class="mdi mdi-database-export-outline mr-1"></i>-->
    <!--      Export users-->
    <!--    </label>-->
    <button [disabled]="(banning$ | async)" class="btn close-modal-button" closeModal="CLOSE_BAN_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{'MEDIUM_LOREM' | translate}}</p>
    <aw-wizard #wizard [navBarLayout]="'small'" disableNavigationBar="true" navigationMode="free">
      <aw-wizard-step stepTitle="Selected users">
        <app-users-recap [description]="'USER_RECAP_BAN_DESCRIPTION'" [users]="users"></app-users-recap>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Ban options">
        <app-ban-information (reloadMotivations)="_extractBanMotivations()"
                             (selectedBanInformation)="setBanData($event)"
                             [banData]="banData" [editData]="editingData"
                             [motivations$]="banMotivations$"></app-ban-information>
      </aw-wizard-step>
      <aw-wizard-step [canEnter]="!!banData" stepTitle="Review ban">
        <app-ban-summary (confirmBan)="banUsers($event)" [banData]="banData" [banning$]="banning$"></app-ban-summary>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</ng-template>
