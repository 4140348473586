<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">{{'QR_CODE' | translate}} - <span>{{qrCode?.code}}</span></h5>
    <button class="btn close-modal-button" closeModal="CLOSE_QR_DETAIL_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <qrcode *ngIf="qrCode.deepLink" [allowEmptyString]="false" [attr.id]="'qrCode' + qrCode.id"
            [cssClass]="'qr-detail-modal'"
            [elementType]="'url'"
            [errorCorrectionLevel]="'M'" [qrdata]="qrCode.deepLink"
            [width]="1024"></qrcode>
  </div>
  <div class="modal-footer">
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="downloadQrCode()" class="btn btn-primary">Download</button>
      </li>
    </ul>
  </div>
</ng-template>
