<div class="operation-modal-header">
  <h5 *ngIf="!deeplink">{{'CREATE_DEEPLINK' | translate}}</h5>
  <h5 *ngIf="deeplink && !deeplink?.duplicate">{{'EDIT_DEEPLINK' | translate}}: {{deeplink?.email}}</h5>
  <h5 *ngIf="deeplink && deeplink?.duplicate">{{'DUPLICATE_DEEPLINK' | translate}}: {{deeplink?.email}}</h5>
  <div class="d-flex">
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_DEEPLINK_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="deeplinkForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <app-deeplink-action-select
          [parentForm]="deeplinkForm"
          [control]="'contextAction'"
          [childControl]="'contextDetails'"
          [submitted]="formSubmitted"
      ></app-deeplink-action-select>
      <div class="col-2">
        <div class="mb-3">
          <label class="font-weight-bold" for="active">Active</label>
          <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
            <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                   formControlName="active"
                   id="active" type="checkbox">
            <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
              <span *ngIf="!form?.active?.value"
                    class="badge badge-pill badge-soft-danger font-size-11">No</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !deeplink " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && deeplink && deeplink?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && deeplink && !deeplink?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !deeplink">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && deeplink && !deeplink?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && deeplink && deeplink?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
