<div class="modal-header border-bottom-0">
  <h5 *ngIf="!objective" class="modal-title" id="modal-title-1">{{'CREATE_SUSTAINABILITY_OBJECTIVE' | translate}}</h5>
  <h5 *ngIf="objective && !objective?.duplicate" class="modal-title" id="modal-title-2">{{'EDIT_SUSTAINABILITY_OBJECTIVE' | translate}}: {{objective?.name}}</h5>
  <h5 *ngIf="objective && objective?.duplicate" class="modal-title" id="modal-title-3">{{'DUPLICATE_SUSTAINABILITY_OBJECTIVE' | translate}}: {{objective?.name}}</h5>
  <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_SUSTAINABILITY_OBJECTIVE_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore vero! Consectetur distinctio dolor eos hic veniam.</p>
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="objectiveForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
          <li [ngbNavItem]="1" (click)="activeId = 1">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Base and presentational</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-7">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="name">{{'TITLE' | translate}}</label>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" class="form-control"
                           formControlName="title" id="title" type="text">
                    <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>

                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <app-select-locale [parentForm]="objectiveForm"></app-select-locale>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
                    <textarea  [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control description-textarea" formControlName="description" id="description" rows="4"></textarea>
                    <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
                      <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-9">
                  <div class="mb-3">
                    <app-image-uploader [previousSelectedImage]="checkSelectedImage('image')"
                                        [limit]="detailImageLimit"
                                        [imageErrors]="checkImageErrors('image')"
                                        [showImagePreview]="true"
                                        (imageUploaded)="setImage($event, 'image')"></app-image-uploader>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="active">Active</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="active"
                             id="active" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.active?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" (click)="activeId = 2">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Algorithm & Rules</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
<!--                <div class="col-4 mb-3">-->
<!--                  <label class="font-weight-bold" for="type">{{'TYPE' | translate}}</label>-->
<!--                  <select  class="form-select" formControlName="type" id="type" (change)="checkCompetitionRestriction()">-->
<!--                    <option *ngFor="let type of types" [value]="type">{{type | translate}}</option>-->
<!--                  </select>-->
<!--                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.type?.errors?.required">-->
<!--                    <span>{{'VALUE_REQUIRED' | translate}}</span>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="col-4 mb-3" *ngIf="form.type.value === 'PROJECT'">
                  <label class="font-weight-bold" for="metric">{{'FITNESS_METRIC' | translate}}</label>
                  <select  class="form-select" formControlName="metric" id="metric" [disabled]="form.metric.value === 'COMPETITION'">
                    <option *ngFor="let metric of fitnessMetricList" [value]="metric">{{metric | translate}}</option>
                  </select>
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.metric?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-4 mb-3" *ngIf="form.type.value === 'PROJECT'">
                  <label class="font-weight-bold" for="rule">{{'RULE' | translate}}</label>
                  <select  class="form-select" formControlName="rule" id="rule" [disabled]="form.type.value === 'COMPETITION'">
                    <option *ngFor="let rule of rules" [value]="rule">{{rule | translate}}</option>
                  </select>
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.type?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-4 mb-3" *ngIf="form?.rule?.value === 'ON_REACH'">
                  <label class="font-weight-bold" for="target">Target</label>
                  <input [ngClass]="{'is-invalid': formSubmitted && form?.target?.errors}" class="form-control"
                         formControlName="target" id="target"
                         min="0" type="number">
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.target?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-4 mb-3" *ngIf="form?.rule?.value === 'ON_GOING'">
                  <label class="font-weight-bold" for="multiplier">Multiplier</label>
                  <input [ngClass]="{'is-invalid': formSubmitted && form?.multiplier?.errors}" class="form-control"
                         formControlName="multiplier" id="multiplier"
                         min="0" type="number">
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.multiplier?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-12 mb-3" *ngIf="form?.rule?.value === 'ON_GOING'">
                  <label class="font-weight-bold" for="multiplierText">Multiplier label</label>
                  <input [ngClass]="{'is-invalid': formSubmitted && form?.multiplierText?.errors}" class="form-control"
                         formControlName="multiplierText" id="multiplierText"
                         min="0" type="text">
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.multiplierText?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-6"  *ngIf="form.type.value === 'PROJECT'">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-1" for="startDate">{{'START_DATE' | translate}}</label>
                    <app-simple-datepicker [isInvalid]="formSubmitted && form?.startDate?.errors?.required"
                                           [name]="'Monday'"
                                           [minDateLock]="false" (dateSelected)="setDate($event, 'startDate')"></app-simple-datepicker>
                    <div *ngIf="formSubmitted && form?.startDate?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.startDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6"  *ngIf="form.type.value === 'PROJECT'">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-1" for="endDate">{{'END_DATE' | translate}}</label>
                    <app-simple-datepicker [isInvalid]="formSubmitted && form?.endDate?.errors?.required"
                                           [name]="'Sunday'"
                                           [minDateLock]="false" (dateSelected)="setDate($event, 'endDate')"></app-simple-datepicker>
                    <div *ngIf="formSubmitted && form?.endDate?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.endDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" (click)="activeId = 3">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Partner - Benefits - Place</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-6 mb-3">
                  <label class="font-weight-bold" for="partnerId">{{'PARTNER' | translate}}</label>
                  <select  class="form-select" formControlName="partnerId" id="partnerId">
                    <option *ngFor="let partner of partners" [value]="partner?.id">{{partner?.title | translate}}</option>
                  </select>
                  <div class="invalid-feedback d-block"  *ngIf="formSubmitted && form?.partner?.errors?.required">
                    <span>{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 single-ng-select">
                    <label class="font-weight-bold">Places</label>
                    <ng-select [clearable]="true" [items]="places"  bindLabel="name"
                               bindValue="id" [multiple]="false" formControlName="placeId">
                      <ng-template let-item="item" ng-label-tmp>
                        <span>{{ item?.name }}</span>
                      </ng-template>
                      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                        <span class="font-weight-semibold ms-2">{{item?.name}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 single-ng-select">
                    <label class="font-weight-bold">Benefits</label>
                    <ng-select [clearable]="true" [items]="benefits"  bindLabel="title"
                               bindValue="id" [multiple]="true" formControlName="benefitsIds">
                      <ng-template let-item="item" ng-label-tmp>
                        <span>{{ item?.title }}</span>
                      </ng-template>
                      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                        <span class="font-weight-semibold ms-2">{{item?.title}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !objective " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && objective && objective?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && objective && !objective?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !objective">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && objective && !objective?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && objective && objective?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
