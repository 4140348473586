import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {deeplinkActions} from "../../../shared/data";
import {ActionOptions} from "@component/deeplinks/add-deeplink-modal/deeplink-options.enum";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {Subject, takeUntil} from "rxjs";
import {be} from "@fullcalendar/core/internal-common";
import {Validators} from "@angular/forms";
import {NotificationsService} from "@service/notifications/notifications.service";

@Component({
  selector: 'app-deeplink-action-select',
  templateUrl: './deeplink-action-select.component.html',
  styleUrls: ['./deeplink-action-select.component.scss']
})
export class DeeplinkActionSelectComponent implements OnInit, OnDestroy {
	@Input() parentForm: any;
	@Input() control: string;
	@Input() childControl: string;
	@Input() audience: any;
	@Input() audienceIds: any;
	@Input() submitted: boolean;
	@Input() hideActionSelect: boolean;
	@Input() hideActionDetail: boolean;
	@Input() editMode: boolean;
	public AudienceEnum = AudienceEnum;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	
	public actions: any = deeplinkActions;
	public enum = ActionOptions;
	
  constructor(private _notificationsService: NotificationsService) { }

  ngOnInit(): void {
	  this._loadActions();
  }
	
	private _listenChanges() {
		if (!this.hideActionDetail) {
			this.parentForm.get(this.control)?.valueChanges?.pipe(takeUntil(this._unsubscribeAll)).subscribe((action: ActionOptions) => {
				switch (action) {
					case ActionOptions.COMPETITION:
					case ActionOptions.OPEN_FIXED_WEBVIEW:
					case ActionOptions.OPEN_CLOSABLE_WEBVIEW:
						this.parentForm.get(this.childControl).addValidators([Validators.required]);
						this.parentForm.get(this.childControl).updateValueAndValidity();
						break;
					default:
						this.parentForm.get(this.childControl).patchValue(undefined);
						this.parentForm.get(this.childControl).clearValidators();
						this.parentForm.get(this.childControl).updateValueAndValidity();
						break;
				}
			});
		}
	}
	
	private _loadActions() {
		this._notificationsService.getNotificationActions().subscribe((actions: any) => {
			this.actions = actions?.map((action: any) => {
				action.value = action.action;
				return action;
			});
			this._listenChanges();
		});
	}
	
	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	
	public restrictionId(): any {
		return this.audience === AudienceEnum.ALL_USERS_IN_COMPANY && this.audienceIds ? this.audienceIds : undefined;
	}
	
	public selectedAction(action: any): boolean {
		return this.parentForm?.get(this.control)?.value === action;
	}
	
	public selectedValue() {
		return this.parentForm?.get(this.childControl)?.value;
	}
	
	public search(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return item.label.toLocaleLowerCase().indexOf(term) > -1 || item.label.toLocaleLowerCase().indexOf(term) > -1;
	}
	
	public errors() {
		return this.submitted && this.parentForm?.get(this.childControl)?.errors?.required;
	}
	
	public patchValue(value: any) {
		this.parentForm?.get(this.childControl)?.patchValue(value);
	}
	
}
