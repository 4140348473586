<div class="modal-header border-bottom-0">
  <h5 *ngIf="!partner" class="modal-title" id="modal-title-1">{{'CREATE_PARTNER' | translate}}</h5>
  <h5 *ngIf="partner && !partner?.duplicate" class="modal-title" id="modal-title-2">{{'EDIT_PARTNER' | translate}}
    : {{partner?.name}}</h5>
  <h5 *ngIf="partner && partner?.duplicate" class="modal-title" id="modal-title-3">{{'DUPLICATE_PARTNER' | translate}}
    : {{partner?.name}}</h5>
  <button [disabled]="(addingPartner$ | async)" class="btn close-modal-button" closeModal="CLOSE_PARTNER_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim
    eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos
    error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore
    vero! Consectetur distinctio dolor eos hic veniam.</p>
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="partnerForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="name">{{'NAME' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control"
                 formControlName="name" id="name" type="text">
          <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.name?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.name?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.name?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.name?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="websiteUrl">{{'WEBSITE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.websiteUrl?.errors}" class="form-control"
                 formControlName="websiteUrl" id="websiteUrl" type="text">
          <div *ngIf="formSubmitted && form?.websiteUrl?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.websiteUrl?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form?.websiteUrl?.errors?.pattern">Please insert a valid url</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="cardTitle">{{'CARD_TITLE' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.cardTitle?.errors}" class="form-control"
                 formControlName="cardTitle" id="cardTitle" type="text">
          <div *ngIf="formSubmitted && form?.cardTitle?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.cardTitle?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.cardTitle?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.cardTitle?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.cardTitle?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.cardTitle?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold" for="cardDescription">{{'CARD_DESCRIPTION' | translate}}</label>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.cardDescription?.errors}" class="form-control"
                 formControlName="cardDescription" id="cardDescription" type="text">
          <div *ngIf="formSubmitted && form?.cardDescription?.errors" class="invalid-feedback">
            <span
              *ngIf="formSubmitted && form?.cardDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span
              *ngIf="form?.cardDescription?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.cardDescription?.errors?.maxlength?.requiredLength} }}</span>
            <span
              *ngIf="form?.cardDescription?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.cardDescription?.errors?.minlength?.requiredLength} }}
              .</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <app-image-uploader (imageUploaded)="setImage($event)"
                              [imageErrors]="checkImageErrors()"
                              [limit]="logoImageLimit"
                              [previousSelectedImage]="checkSelectedImage()"
                              [showImagePreview]="true"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !partner " class="btn mb-3 btn-warning">
          Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && partner && partner?.duplicate"
                class="btn mb-3 btn-warning">Create
        </button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && partner && !partner?.duplicate"
                class="btn mb-3 btn-warning">Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingPartner$ | async)" class="btn mb-3 btn-warning me-2"
                type="submit">
          <i *ngIf="addingPartner$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingPartner$ | async) && !partner">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingPartner$ | async) && partner && !partner?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(addingPartner$ | async) && partner && partner?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingPartner$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingPartner$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
