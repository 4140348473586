import {Component, OnInit, ViewChild} from '@angular/core';
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {Subject, Subscription} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {PriceTypeEnum} from "@enum/price-type/price-type.enum";
import {PrizeContextTypeEnum} from "@enum/prize-context-type/prize-context-type.enum";
import {UtilsService} from "@service/utils/utils.service";
import {ActionOptions} from "@component/deeplinks/add-deeplink-modal/deeplink-options.enum";
import {FitnessKitEnum} from "@enum/fitness-kit/fitness-kit.enum";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  public title: string | undefined;
  public topbarTypeEnum = TopbarTypeEnum;
  model;
  public type: TopbarTypeEnum | undefined;
  public service: any;
  public clearFilters: Subject<boolean> = new Subject<boolean>();
  public searchFilters = undefined;
  @ViewChild('exportModal') private modalComponent: any
  private filterSubscription: Subscription;

  constructor(private eventService: EventService, public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.filterSubscription = this.eventService.subscribe(EventEnum.UPDATE_FILTER_MENU, (menu: any) => {
      this.type = menu;
    });
    this.filterSubscription = this.eventService.subscribe(EventEnum.UPDATE_FILTER_SERVICE, (service: any) => {
      this.updateSearchFilters();
      this.service = service;
    });
  }

  public getValue(event: Event, boolean?: boolean): any {
    const value = (event.target as HTMLInputElement).value;
    if (!boolean) return value;
    if (boolean) {
      switch (value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    }
  }

  public extractCardClass(expanded: boolean): string {
    if (expanded) {
      return 'filter-accordion-opened';
    } else {
      return 'filter-accordion-closed';
    }
  }

  public clearSearchFilters(clearSearchTerm?: boolean): void {
    if (!clearSearchTerm) {
      this.service.searchTerm = undefined;
    }
    this.updateSearchFilters();
    this.clearFilters.next(true);
    this.service.clearFilters();
  }

  async openExportModal() {
    return await this.modalComponent.open(this.service, this.type)
  }

  public noFilters(): boolean {
    return !(this.type === TopbarTypeEnum.COMPANY_LIST
      || this.type === TopbarTypeEnum.COMPETITION_LIST
      || this.type === TopbarTypeEnum.FAQS_LIST
      || this.type === TopbarTypeEnum.CHALLENGE_LIST
      || this.type === TopbarTypeEnum.PRIZE_LIST
      || this.type === TopbarTypeEnum.BAN_MOTIVATIONS
      || this.type === TopbarTypeEnum.COMPANY_MEMBERSHIPS
      || this.type === TopbarTypeEnum.BANNED_LIST
      || this.type === TopbarTypeEnum.USER_LIST
      || this.type === TopbarTypeEnum.WHITE_LIST
      || this.type === TopbarTypeEnum.QR_CODES
      || this.type === TopbarTypeEnum.NEWS_LIST
      || this.type === TopbarTypeEnum.PRIZE_LIST_PAGE_DETAIL
      || this.type === TopbarTypeEnum.COMPETITION_INDIVIDUAL_LEADERBOARD
      || this.type === TopbarTypeEnum.COMPETITION_TEAM_LEADERBOARD
      || this.type === TopbarTypeEnum.INVITES
      || this.type === TopbarTypeEnum.INVITES_USES);
  }

  public selectContextDetailId(event: any): void {
    if (!!event && event !== 'null') {
      const selected = JSON.parse(event);
      this.service.contextDetailsId = selected && selected?.id ? selected?.id : null;
    } else {
      this.service.contextDetailsId = null;
    }
  }

  private updateSearchFilters(): void {
    this.searchFilters = {
      companies: [{label: "All", id: null, selected: true}, {
        label: "Healthy Virtuoso",
        id: 3,
        selected: false
      }, {label: "American Express", id: 5, selected: false}],
      status: [{label: "USER.ALL", value: undefined, selected: true}, {
        label: "USER.ACTIVE",
        value: false,
        selected: false
      }, {label: "USER.BANNED", value: true, selected: false}],
      platform: [{label: "USER.ALL", value: undefined, selected: true},
        {
          label: "IOS",
          value: 'IOS',
          selected: false
        },
        {label: "ANDROID", value: 'ANDROID', selected: false}, {
          label: "OTHER",
          value: 'OTHER',
          selected: false
        }],
      boolean: [{label: "USER.ALL", value: undefined, selected: true}, {
        label: "YES",
        value: true,
        selected: false
      }, {label: "NO", value: false, selected: false}],
      challengeType: [
        {label: "ALL", value: undefined, selected: true},
        {label: "DUEL", value: "DUEL", selected: false},
        {label: "LEADERBOARD", value: "LEADERBOARD", selected: false},
        {label: "ALL_TOGETHER", value: "ALL_TOGETHER", selected: false},
      ],
      fitnessMetric: [
        {label: "ALL", value: undefined, selected: true},
        {label: "CREDITS", value: "CREDITS", selected: false},
        {label: "VIRTUOSITY", value: "VIRTUOSITY", selected: false},
        {label: "STEPS", value: "STEPS", selected: false},
        {label: "SPORT_MINUTES", value: "SPORT_MINUTES", selected: false},
        {label: "CYCLING_MINUTES", value: "CYCLING_MINUTES", selected: false},
        {label: "CYCLING_METERS", value: "CYCLING_METERS", selected: false},
        {label: "RUNNING_MINUTES", value: "RUNNING_MINUTES", selected: false},
        {label: "RUNNING_METERS", value: "RUNNING_METERS", selected: false}
      ],
      challengeStatus: [
        {label: "ALL", value: undefined, selected: true},
        {label: "AWAITING_START", value: "AWAITING_START", selected: false},
        {label: "ACTIVE", value: "ACTIVE", selected: false},
        {label: "ACTIVE_LAST_HALF", value: "ACTIVE_LAST_HALF", selected: false},
        {label: "AWAITING_RESULTS", value: "AWAITING_RESULTS", selected: false},
        {label: "COMPLETED", value: "COMPLETED", selected: false},
        {label: "ABORTED", value: "ABORTED", selected: false}
      ],
      competitionType: [
        {label: "ALL", value: undefined, selected: true},
        {label: "INDIVIDUAL", value: "INDIVIDUAL", selected: false},
        {label: "INDIVIDUAL_AND_TEAMS", value: "INDIVIDUAL_AND_TEAMS", selected: false},
        {label: "TEAMS", value: "TEAMS", selected: false},
      ],
      deeplinkType: [
        {label: "ALL", value: undefined, selected: true},
        {label: "COMPETITION", value: ActionOptions.COMPETITION, selected: false},
        {label: "COMPANY", value: ActionOptions.COMPANY, selected: false},
        {label: "HOME", value: ActionOptions.HOME, selected: false},
      ],
      competitionStatus: [
        {label: "ALL", value: undefined, selected: true},
        {label: "AWAITING_START", value: "AWAITING_START", selected: false},
        {label: "COMPLETED", value: "COMPLETED", selected: false},
        {label: "ACTIVE", value: "ACTIVE", selected: false},
      ],
      prizeType: [
        {label: "ALL", value: undefined, selected: true},
        {label: PriceTypeEnum.CODE_REDEMPTION, value: PriceTypeEnum.CODE_REDEMPTION, selected: false},
        {label: PriceTypeEnum.CREDITS, value: PriceTypeEnum.CREDITS, selected: false},
        {label: PriceTypeEnum.SIMPLE, value: PriceTypeEnum.SIMPLE, selected: false},
        {label: PriceTypeEnum.CHARITY, value: PriceTypeEnum.CHARITY, selected: false},
      ],
      prizeContextType: [
        {label: "ALL", value: undefined, selected: true},
        {label: PrizeContextTypeEnum.COMPANY, value: PrizeContextTypeEnum.COMPANY, selected: false},
        {label: PrizeContextTypeEnum.COMPETITION, value: PrizeContextTypeEnum.COMPETITION, selected: false},
        {label: PrizeContextTypeEnum.GLOBAL, value: PrizeContextTypeEnum.GLOBAL, selected: false},
      ],
      powerLevel: [
        {label: "ALL", value: undefined, selected: true},
        {value: "Gold", label: "Gold", selected: false},
        {value: "Silver", label: "Silver", selected: false},
        {value: "Diamond", label: "Diamond", selected: false},
        {value: "Bronze", label: "Bronze", selected: false},
      ],
      fitnessKit: [
        {label: "ALL", value: undefined, selected: true},
        {value: FitnessKitEnum.APPLE, label: "Apple Health", selected: false},
        {value: FitnessKitEnum.GOOGLE, label: "Google Fit", selected: false},
        {value: FitnessKitEnum.GARMIN, label: "Garmin", selected: false},
        {value: FitnessKitEnum.HEALTHCONNECT, label: "Health connect", selected: false},
        {value: FitnessKitEnum.HUAWEIHEALTH, label: "Huawei health", selected: false},
        {value: FitnessKitEnum.UNKNOWN, label: "Unknown", selected: false},
      ]
    }
  }
	
	public emptyString(searchTerm: any) {
		return !searchTerm || /^\s*$/.test(searchTerm);
	}

}
