import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() table: boolean | undefined;
  public tableData: any[] = [{element: 'Data', children: [{element: 'Data'}]}];

  constructor() {
  }

  ngOnInit(): void {
  }

}
