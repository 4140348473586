<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title">
      <span>{{ 'EDIT_TEAM' | translate }}</span>
    </h5>
    <button [disabled]="(loading)" class="btn btn-outline-light close-modal-button"
            closeModal="CLOSE_ACTIVATE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="modal-description">{{ 'EDIT_TEAM_NAME_DESCRIPTION' | translate}}</p>
    <div class="col-12">
      <div class="mb-3">
        <label class="font-weight-bold">Name</label>
        <input [(ngModel)]="team.name" class="form-control" id="reason" type="text">
      </div>
    </div>
    <div class="col-12">
      <div class="mb-3">
        <label class="font-weight-bold">Expected members</label>
        <input [(ngModel)]="team.expectedMembers" class="form-control" id="expectedMembers" type="number">
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-success">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="editTeam()" *ngIf="showAreYouSure" [disabled]="(loading)" class="btn btn-success me-2"
                type="submit">
          <i *ngIf="loading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="loading">
            Working
          </span>
          <span [hidden]="(loading)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(loading)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>
