<div class="input-group">
  <input #date="ngbDatepicker" (ngModelChange)="checkChanges($event)" [(ngModel)]="model"
         [name]="name" [ngClass]="{'is-invalid': isInvalid}" class="form-control datepicker-input"
         id="datepicker{{specificWeekday}}" name="dp{{specificWeekday}}" ngbDatepicker placeholder="dd/mm/yyyy">
  <div class="input-group-prepend">
    <button (click)="date.toggle()" class="btn btn-light datepicker-btn" type="button">
      <i class="bx bx-calendar"></i>
    </button>
  </div>
</div>
