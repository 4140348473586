import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject, Subscription} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {UtilityApiService} from "@service/utility/utility-api.service";
import {ToastService} from "@service/toast.service";
import {EventService} from "@service/common/event.service";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {EventEnum} from "@enum/event/event.enum";

@Injectable({
  providedIn: 'root'
})
export class AutomaticBanService {
  public _search$ = new Subject<void>();
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<any>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.automaticBanRulesColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.automaticBanRulesFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private createAutomaticBanSubscription: Subscription;
  private searchAutomaticBanSubscription: Subscription;

  constructor(private utils: UtilsService, private utilityApiService: UtilityApiService,
              private toastService: ToastService, private eventService: EventService) {
  }

  private _working$ = new BehaviorSubject<boolean>(false);

  get working$() {
    return this._working$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _automaticBanRuleList$ = new BehaviorSubject<any[]>([]);

  get automaticBanRuleList$() {
    return this._automaticBanRuleList$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get page() {
    return this._currentTableState.page;
  }

  set page(page: number) {
    this._setValue({page});
  }

  get pageSize() {
    return this._currentTableState.pageSize;
  }

  set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public createAutomaticBanRule(data: any): void {
    this._create$.next(data);
  }

  public removeCreateSubscribe(): void {
    this.createAutomaticBanSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public removeSearchSubscribe(): void {
    this.searchAutomaticBanSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initCreateListener(): void {
    this.createAutomaticBanSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.utilityApiService.editAutomaticBanRule(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_AUTOMATIC_BAN_RULE_MODAL, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'Rule edited successfully' : 'Rule created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_AUTOMATIC_BAN_RULE_MODAL, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_AUTOMATIC_BAN_RULE_MODAL);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public deactivate(rule: any, modal: any): any {
    const params = {rule: rule?.fitnessMetric, id: rule?.id, banThreshold: null, banThresholdForWhiteList: null};
    this._working$.next(true);
    this.utilityApiService.editAutomaticBanRule(params).subscribe((result) => {
      if (result?.length > 0) {
        result.map((error) => {
          this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
          return error;
        });
      } else {
        this.toastService.show('Rule deactivated successfully', {classname: 'bg-success text-light'});
      }
      this._working$.next(false);
      this._search$.next();
      this.eventService.broadcast(modal, null)
    })
  }

  public initSearchListener(): void {
    this.searchAutomaticBanSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.utilityApiService.getAutomaticBanRules(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe((result) => {
      this._automaticBanRuleList$.next(result);
      this._totalRecords$.next(result?.length);
    });
  }

  public isFilterApplied(): boolean {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      return Object.keys(obj)?.length > 0;
    } else {
      return false;
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }

  private _extractSearchParams(): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
      },
      sort: this.extractSorting(),
      page: this.page,
      size: this.pageSize
    }
  }
}
