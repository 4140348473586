import {EndpointParamEnum} from "@enum/endpoint-param.enum";

const separator = '/';
export const endpoints = {
  user: {
    list: ['users', 'list'].join(separator),
    detail: ['user', EndpointParamEnum.ID].join(separator),
    blocked: ['users', 'blocked', EndpointParamEnum.ID].join(separator),
    blockedBy: ['users', EndpointParamEnum.ID, 'blockedBy'].join(separator),
    assignCredits: ['user', EndpointParamEnum.ID, 'assignCredits'].join(separator),
    memberships: ['companies', 'memberships'].join(separator),
    bans: ['user', 'bans', 'list'].join(separator),
    specificBans: ['user', 'bans'].join(separator),
    banMotivations: ['bans', 'motivation'].join(separator),
    createBanMotivation: ['bans', 'motivation'].join(separator),
    editBanMotivation: ['bans', 'motivation', EndpointParamEnum.ID].join(separator),
    deleteBanMotivation: ['bans', 'motivation', EndpointParamEnum.ID].join(separator),
    defaultBanMotivation: ['bans', 'motivation', 'default', EndpointParamEnum.ID].join(separator),
    deleteUsers: ['users'].join(separator),
    diary: ['user', 'diary'].join(separator),
    fitness: ['fitnessSlots'].join(separator),
    fitnessSummary: ['user', EndpointParamEnum.ID, 'summary'].join(separator),
    challenges: ['challenges', 'forUser'].join(separator),
    notification: ['user', EndpointParamEnum.ID, 'pushMessage'].join(separator),
    credits: ['user', EndpointParamEnum.ID, 'assignCredits'].join(separator),
    ban: ['bans', 'ban'].join(separator),
    unban: ['bans', 'unban'].join(separator),
    unbanForCompetition: ['competitions', EndpointParamEnum.ID, 'unban', EndpointParamEnum.PID].join(separator),
    unbanForCompany: ['companies', EndpointParamEnum.ID, 'unban', EndpointParamEnum.PID].join(separator),
    currentUser: ['users', 'me'].join(separator),
    addRemoveWhiteList: ['fraudPrevention', 'whiteList'].join(separator),
    fitnessActivities: ['fitnessActivities'].join(separator),
    fitnessCharts: ['user', EndpointParamEnum.ID, 'dailyKpis'].join(separator),
    listById: ['users', 'minimalInfo'].join(separator),
    operators: {
      list: ['operators'].join(separator),
      create: ['operator'].join(separator)
    }
  },
  notification: {
    sendPush: ['notifications', 'upsert'].join(separator),
    list: ['notifications', 'scheduled'].join(separator),
    delete: ['notifications', 'scheduled', EndpointParamEnum.ID].join(separator),
    deleteRecurring: ['notifications', 'recurring', EndpointParamEnum.ID].join(separator),
    actions: ['notifications', 'actions'].join(separator),
    audience: ['notifications', 'audience'].join(separator),
    all: ['notifications', 'all'].join(separator),
    settings: {
      list: ['notifications', 'settings'].join(separator),
      edit: ['notifications', 'setting'].join(separator),
    }
  },
  rewards: {
    fitnessToCredits: {
      list: ['fitnessToCreditsConversions'].join(separator),
      detail: ['fitnessToCreditsConversions', 'for', EndpointParamEnum.ID].join(separator),
      delete: ['fitnessToCreditsConversions', EndpointParamEnum.ID].join(separator),
      new: ['fitnessToCreditsConversions', 'new'].join(separator),
      update: ['fitnessToCreditsConversions', EndpointParamEnum.ID, 'details'].join(separator),
    },
    new: ['rewards', 'bonus', 'new'].join(separator),
    list: ['rewards', 'bonus'].join(separator),
    detail: ['rewards', 'bonus', EndpointParamEnum.ID].join(separator),
    edit: ['rewards', 'bonus', EndpointParamEnum.ID,].join(separator)
  },
  utility: {
    automaticBanRules: {
      edit: ['fraudPrevention', 'automaticBan', 'rule'].join(separator),
      detail: ['fraudPrevention', 'automaticBan', 'rule', EndpointParamEnum.ID, 'uses'].join(separator),
      list: ['fraudPrevention', 'automaticBan', 'rules'].join(separator)
    },
    blackListSources: {
      delete: ['fraudPrevention', 'sourceBlacklistItem', EndpointParamEnum.ID].join(separator),
      detail: ['fraudPrevention', 'sourceBlacklistItem', EndpointParamEnum.ID, 'uses'].join(separator),
      add: ['fraudPrevention', 'sourceBlacklistItem'].join(separator),
      list: ['fraudPrevention', 'sourcesBlacklist'].join(separator)
    },
    qr: {
      list: ['credited', 'codes'].join(separator),
      create: ['credited', 'code'].join(separator),
    },
    contestMetrics: {
      list: ['availableContestMetrics'].join(separator)
    },
    tags: {
      list: ['tags'].join(separator)
    },
    rawData: {
      list: ['rawFitness'].join(separator),
      process: ['rawFitness', 'processInError', EndpointParamEnum.ID].join(separator)
    },
	  languages: ['languages'].join(separator),
	  companyLanguages: ['languages', EndpointParamEnum.ID].join(separator),
	  addLanguages: ['languages', 'create'].join(separator),
	  removeLanguages: ['languages', 'delete'].join(separator),
  },
  companies: {
    list: ['companies'].join(separator),
    paged: ['companies', 'paged'].join(separator),
    analytics: ['companies', 'analytics'].join(separator),
    memberships: ['companies', 'memberships'].join(separator),
    addMemberships: ['companies', EndpointParamEnum.ID, 'upsertMembership', 'addMissing'].join(separator),
    validateMembership: ['companies', EndpointParamEnum.ID, 'mail', 'validation'].join(separator),
    codes: ['companies', 'inviteCodes', 'paged'].join(separator),
    teams: ['companies', 'teams'].join(separator),
    create: ['companies'].join(separator),
    addCodes: ['companies', 'inviteCodes?companyId=ID'].join(separator),
    addTeams: ['companies', 'teams', 'creation?companyId=ID'].join(separator),
    editTeams: ['companies', 'teams', EndpointParamEnum.PID, '?companyId=ID'].join(separator),
    deleteTeam: ['companies', 'teams', EndpointParamEnum.PID, '?companyId=ID'].join(separator),
    removeUser: ['companies', EndpointParamEnum.ID, 'bounce', EndpointParamEnum.PID].join(separator),
    detail: ['companies', EndpointParamEnum.ID].join(separator),
    edit: ['companies', EndpointParamEnum.ID].join(separator),
    delete: ['companies', EndpointParamEnum.ID].join(separator),
    webapp: ['companies', EndpointParamEnum.ID, 'webApp'].join(separator),
    usersInTeam: ['companies', 'usersInTeam', EndpointParamEnum.PID, EndpointParamEnum.ID].join(separator),
    membershipsStreaks: ['companies', 'streaks', EndpointParamEnum.ID].join(separator),
  },
  competitions: {
    list: ['competitions'].join(separator),
    paged: ['competitions', 'paged'].join(separator),
    bans: ['competitions', 'bans'].join(separator),
    teamLeaderboard: ['contest', 'leaderboard', 'team'].join(separator),
    individualLeaderboard: ['contest', 'leaderboard', 'individual'].join(separator),
    create: ['competitions'].join(separator),
    recalculate: ['competitions', 'recalculateCreditContestCounter'].join(separator),
    edit: ['competitions', EndpointParamEnum.ID].join(separator),
    delete: ['competitions', EndpointParamEnum.ID].join(separator),
    removeUser: ['competitions', EndpointParamEnum.ID, 'remove', EndpointParamEnum.PID].join(separator),
    detail: ['competitions', EndpointParamEnum.ID].join(separator),
    postpone: ['competitions', EndpointParamEnum.ID, 'moveStartAt'].join(separator),
    createPrize: ['competitions', 'prizes?competitionId=ID'].join(separator),
  },
  challenges: {
    list: ['challenges'].join(separator),
    forUser: ['challenges', 'forUser'].join(separator),
    duration: ['availableChallengeDurations'].join(separator),
    detail: ['challenge', 'leaderboard', 'individual'].join(separator)
  },
  prizes: {
    competition: ['competitions', 'prizes'].join(separator),
    company: ['companies', 'prizes'].join(separator),
    list: ['prizes', 'list'].join(separator),
    forUser: ['prizes', 'forUser', EndpointParamEnum.ID].join(separator),
    create: ['prizes'].join(separator),
    createForCompany: ['companies', 'prizes?companyId=ID'].join(separator),
    listForCompany: ['companies', 'prizes?companyId=ID'].join(separator),
    createForCompetition: ['competitions', 'prizes?competitionId=ID'].join(separator),
    listForCompetition: ['competitions', 'prizes?competitionId=ID'].join(separator),
    editForCompetition: ['competitions', 'prizes', EndpointParamEnum.PID + '?competitionId=ID'].join(separator),
    detailForCompany: ['companies', 'prizes', EndpointParamEnum.ID].join(separator),
    editForCompany: ['companies', 'prizes', 'XE?companyId=ID'].join(separator),
    detailForCompetition: ['competitions', 'prizes', EndpointParamEnum.ID].join(separator),
    detail: ['prizes', EndpointParamEnum.ID].join(separator),
    edit: ['prizes', EndpointParamEnum.ID].join(separator),
    delete: ['prizes', EndpointParamEnum.ID].join(separator),
    deletePrizeCode: ['prizeCode', EndpointParamEnum.ID].join(separator),
    usersWhoRedeemed: ['prizes', 'usersWhoRedeemed', EndpointParamEnum.ID].join(separator),
    codes: ['prizeCodes'].join(separator),
    preconditions: ['prizePreconditions'].join(separator),
    codesEdit: ['prizeCodes', EndpointParamEnum.ID].join(separator),
    partners: {
      list: ['prizes', 'partners'].join(separator),
      create: ['prizes', 'partner'].join(separator),
      edit: ['prizes', 'partner', EndpointParamEnum.ID].join(separator),
    },
    editPreconditions: {
      search: ['prizePreconditions'].join(separator),
      add: ['prizePreconditions'].join(separator),
      edit: ['prizePreconditions', EndpointParamEnum.ID].join(separator),
    }
  },
  news: {
    edit: ['news', EndpointParamEnum.ID].join(separator),
    delete: ['news'].join(separator),
    list: ['paged', 'news'].join(separator),
    create: ['news'].join(separator)
  },
  invites: {
    list: ['codes'].join(separator),
    uses: ['codes', 'uses'].join(separator),
    disable: ['codes', 'disable', EndpointParamEnum.ID].join(separator),
    enable: ['codes', 'enable', EndpointParamEnum.ID].join(separator),
    delete: ['codes', 'delete', EndpointParamEnum.ID].join(separator),
    edit: ['codes', 'edit', EndpointParamEnum.ID].join(separator),
    add: ['codes', 'add'].join(separator)
  },
  faqs: {
    list: ['faqs'].join(separator),
    create: ['faqs'].join(separator),
    delete: ['faqs', EndpointParamEnum.ID].join(separator),
    edit: ['faqs', EndpointParamEnum.ID].join(separator)
  },
  streak: {
    template: {
      list: ['dailyGoalTemplates'].join(separator),
      fitnessScoring: ['fitnessScoringFunctions'].join(separator),
      add: ['dailyGoalTemplates'].join(separator),
      edit: ['dailyGoalTemplates', EndpointParamEnum.ID].join(separator),
    },
    goal: {
      delete: ['dailyGoals', EndpointParamEnum.ID].join(separator),
      list: ['dailyGoals'].join(separator),
      add: ['dailyGoals'].join(separator),
      edit: ['dailyGoals', EndpointParamEnum.ID].join(separator),
    }
  },
  config: {
    benefits: {
      search: ['externalLink', EndpointParamEnum.ID, 'search'].join(separator),
      add: ['externalLink', 'add'].join(separator),
      edit: ['externalLink', 'edit', EndpointParamEnum.ID].join(separator),
      activate: ['externalLink', 'activate', EndpointParamEnum.ID].join(separator),
      deactivate: ['externalLink', 'deactivate', EndpointParamEnum.ID].join(separator),
      delete: ['externalLink', 'delete', EndpointParamEnum.ID].join(separator),
    },
    recommendedApps: {
      search: ['recommendedApps', 'search'].join(separator),
      add: ['recommendedApps', 'add'].join(separator),
      edit: ['recommendedApps', 'edit', EndpointParamEnum.ID].join(separator),
      activate: ['recommendedApps', 'activate', EndpointParamEnum.ID].join(separator),
      deactivate: ['recommendedApps', 'deactivate', EndpointParamEnum.ID].join(separator),
      delete: ['recommendedApps', 'delete', EndpointParamEnum.ID].join(separator),
    },
    howToSync: {
      search: ['howToSync', 'search'].join(separator),
      add: ['howToSync', 'add'].join(separator),
      edit: ['howToSync', 'edit', EndpointParamEnum.ID].join(separator),
      activate: ['howToSync', 'activate', EndpointParamEnum.ID].join(separator),
      deactivate: ['howToSync', 'deactivate', EndpointParamEnum.ID].join(separator),
      delete: ['howToSync', 'delete', EndpointParamEnum.ID].join(separator),
    },
    activities: {
      search: ['fitnessMetricAppConfig', 'search'].join(separator),
      add: ['fitnessMetricAppConfig', 'add'].join(separator),
      edit: ['fitnessMetricAppConfig', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['fitnessMetricAppConfig', 'delete', EndpointParamEnum.ID].join(separator),
    }
  },
  prize: {
    searchForGlobal: ['prizes', 'list'].join(separator),
    searchForCompany: ['companies', 'prizes'].join(separator),
    searchForCompetition: ['competitions', 'prizes'].join(separator),
  },
  content: {
    categories: {
      list: ['paged', 'contentCategories'].join(separator),
      all: ['contentCategories'].join(separator),
      add: ['contentCategory'].join(separator),
      edit: ['contentCategory', EndpointParamEnum.ID].join(separator),
      delete: ['contentCategory', EndpointParamEnum.ID].join(separator),
    },
    list: ['paged', 'highlightedContents'].join(separator),
    add: ['highlightedContents'].join(separator),
    edit: ['highlightedContents', EndpointParamEnum.ID].join(separator),
    delete: ['highlightedContents', EndpointParamEnum.ID].join(separator),
  },
  projects: {
    projects: {
      list: ['project', 'projects', 'list'].join(separator),
      edit: ['project', 'projects', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['project', 'projects', 'delete', EndpointParamEnum.ID].join(separator),
      add: ['project', 'projects', 'add'].join(separator),
    },
    places: {
      list: ['project', 'places', 'list'].join(separator),
      edit: ['project', 'places', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['project', 'places', 'delete', EndpointParamEnum.ID].join(separator),
      add: ['project', 'places', 'add'].join(separator),
    },
    benefits: {
      list: ['project', 'benefits', 'list'].join(separator),
      edit: ['project', 'benefits', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['project', 'benefits', 'delete', EndpointParamEnum.ID].join(separator),
      add: ['project', 'benefits', 'add'].join(separator),
    },
    partners: {
      list: ['project', 'partners', 'list'].join(separator),
      edit: ['project', 'partners', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['project', 'partners', 'delete', EndpointParamEnum.ID].join(separator),
      add: ['project', 'partners', 'add'].join(separator),
    },
    objectives: {
      list: ['project', 'objectives', 'list'].join(separator),
      edit: ['project', 'objectives', 'edit', EndpointParamEnum.ID].join(separator),
      delete: ['project', 'objectives', 'delete', EndpointParamEnum.ID].join(separator),
      add: ['project', 'objectives', 'add'].join(separator),
    }
  },
	badges: {
		fitness: ['fitnessScoringFunctions'].join(separator),
		events: ['rewardableEvents'].join(separator),
		search: ['rewards', 'badges'].join(separator),
		create: ['rewards', 'badges', 'new'].join(separator),
		edit: ['rewards', 'badges', 'edit'].join(separator)
	},
	prizeCategories: {
		search: ['prizeCategories'].join(separator),
		detail: ['prizeCategories', EndpointParamEnum.ID].join(separator),
		create: ['prizeCategories'].join(separator),
		edit: ['prizeCategories',EndpointParamEnum.ID].join(separator),
		delete: ['prizeCategories',EndpointParamEnum.ID].join(separator)
	}
};
