<table *ngIf="users?.length > 0" class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
  <thead>
  <tr>
    <th *ngIf="canBeRemoved"></th>
    <th>
      <span class="me-3">{{'USER_ID' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'EMAIL' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'NICKNAME' | translate}}</span>
    </th>
    <th *ngIf="type === deleteModalTypeEnum.BLACKLIST_SOURCE && !hideBannedColumn">
      <span class="me-3">{{'BANNED' | translate}}</span>
    </th>
    <th *ngIf="leaderboard">
      <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_POSITION' | translate}}</span>
    </th>
    <th *ngIf="leaderboard">
      <span class="me-3">{{'TEAM_LEADERBOARD_TEAM_NAME' | translate}}</span>
    </th>
    <th *ngIf="leaderboard">
      <span class="me-3">{{'INDIVIDUAL_LEADERBOARD_POWER_LEVEL' | translate}}</span>
    </th>
    <th *ngIf="!light">
      <span class="me-3">{{'COMPLETE_NAME' | translate}}</span>
    </th>
    <th *ngIf="!light">
      <span class="me-3">{{'JOINED_COMPANIES' | translate}}</span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users; let i=index;" class="pointer">
    <td (click)="removeUser(user)" *ngIf="canBeRemoved" class="pointer fit-width">
      <i class="dripicons-cross"></i>
    </td>
    <td (click)="userDetail(user)" align="center">
      <span class="badge badge-pill badge-soft-primary">{{extractUserId(user)}}</span>
    </td>
    <td (click)="userDetail(user)">
      <span *ngIf="user?.email">{{user?.email}}</span>
      <app-missing-label *ngIf="!user?.email"></app-missing-label>
    </td>
    <td (click)="userDetail(user)">
      <span>{{extractUserNickname(user)}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="type === deleteModalTypeEnum.BLACKLIST_SOURCE && !hideBannedColumn">
      <span *ngIf="user?.banned">{{'YES' | translate}}</span>
      <span *ngIf="!user?.banned">{{'NO' | translate}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="leaderboard">
      <span>{{user?.position}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="leaderboard">
      <span>{{user?.teamName}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="leaderboard">
      <span>{{user?.powerLevel}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="!light">
      <span>{{extractUserFullName(user)}}</span>
    </td>
    <td (click)="userDetail(user)" *ngIf="!light">
      <span *ngIf="user?.companies">{{user?.companies | companies}}</span>
      <app-missing-label *ngIf="!user?.companies"></app-missing-label>
    </td>
  </tr>
  </tbody>
</table>
