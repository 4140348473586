<app-table-topbar (emitSearchTerm)="prizesService.searchTerm = $event" (openPrizeCreate)="createPrize()" (updateColumnsEvent)="selected = $event"
                  [service]="prizesService"
                  [showColumnPicker]="true"
                  [type]="topBarType"></app-table-topbar>
<div [hidden]="(loading$ | async)" class="col-12 p-0 full-height-table">
  <div *ngIf="prizeList$ | async as prizes" class="wrapper">
    <table *ngIf="prizes?.length > 0"
           class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
           id="basic-datatable">
      <thead>
      <tr>
        <th></th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_ID)" class="column-sortable"
            sortable="id">
          <span class="me-3">{{'PRIZE_ID' | translate}}</span>
        </th>
        <th *ngIf="checkIfColumnIsVisible(column.PRIZE_IMAGE)">
          <span class="me-3">{{'PRIZE_IMAGE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_TITLE)" class="column-sortable"
            sortable="title">
          <span class="me-3">{{'PRIZE_TITLE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_DESCRIPTION)" class="column-sortable"
            sortable="description">
          <span class="me-3">{{'PRIZE_DESCRIPTION' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_CONTEXT_TYPE)" class="column-sortable"
            sortable="contextType">
          <span class="me-3">{{'PRIZE_CONTEXT_TYPE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_CONTEXT_DESCRIPTION)"
            class="column-sortable"
            sortable="contextDetailsDescription">
          <span class="me-3">{{'PRIZE_CONTEXT_DESCRIPTION' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_TYPE)" class="column-sortable"
            sortable="prizeType">
          <span class="me-3">{{'PRIZE_TYPE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_PRIORITY)" class="column-sortable"
            sortable="priority">
          <span class="me-3">{{'PRIZE_PRIORITY' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_PRICE)" class="column-sortable"
            sortable="price">
          <span class="me-3">{{'CREDITS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_FROM)" class="column-sortable"
            sortable="leaderboardFromInclusive">
          <span class="me-3">{{'PRIZE_LEADERBOARD_FROM' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_TO)" class="column-sortable"
            sortable="leaderboardToInclusive">
          <span class="me-3">{{'PRIZE_LEADERBOARD_TO' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_BRACKET)"
            class="column-sortable" sortable="leaderboardBracket">
          <span class="me-3">{{'PRIZE_LEADERBOARD_BRACKET' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_AVAILABLE_UNITS)" class="column-sortable"
            sortable="availableUnits">
          <span class="me-3">{{'PRIZE_AVAILABLE_UNITS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_TOTAL_UNITS)" sortable="totalUnits">
          <span class="me-3">{{'PRIZE_TOTAL_UNITS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_REDEEMED_UNITS)"
            class="column-sortable"
            sortable="redeemedUnits">
          <span class="me-3">{{'PRIZE_REDEEMED_UNITS' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_CREATION_DATE)" class="column-sortable"
            sortable="createdAt">
          <span class="me-3">{{'PRIZE_CREATION_DATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_EXPIRATION_DATE)"
            class="column-sortable"
            sortable="expiresAt">
          <span class="me-3">{{'PRIZE_EXPIRATION_DATE' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_ACTIVE)" class="column-sortable"
            sortable="active">
          <span class="me-3">{{'PRIZE_ACTIVE' | translate}}</span>
        </th>
        <!--        <th *ngIf="checkIfColumnIsVisible(column.PRIZE_TRANSLATIONS)">-->
        <!--          <span class="me-3">{{'PRIZE_TRANSLATIONS' | translate}}</span>-->
        <!--        </th>-->
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_REDEMPTION_URL)"
            class="column-sortable"
            sortable="redemptionUrl">
          <span class="me-3">{{'PRIZE_REDEMPTION_URL' | translate}}</span>
        </th>
        <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_WEBSITE)" class="column-sortable"
            sortable="websiteActionUrl">
          <span class="me-3">{{'PRIZE_WEBSITE' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let prize of prizes; let i=index;">
        <td align="center" class="pointer fit-width">
          <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_ID)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.id" class="badge badge-pill badge-soft-primary">{{prize?.id}}</span>
          <app-missing-label *ngIf="!prize?.id"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_IMAGE)" align="center" class="pointer fit-width">
          <img *ngIf="prize?.cardImageUrl" [src]="prize?.cardImageUrl" class="avatar-sm">
          <app-missing-label *ngIf="!prize?.cardImageUrl"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_TITLE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.title" ngbTooltip="{{prize?.title}}"
                placement="right">{{prize?.title | truncate:[20]}}</span>
          <app-missing-label *ngIf="!prize?.title"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_DESCRIPTION)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.description" ngbTooltip="{{prize?.description}}"
                placement="right">{{extractPrizeDescription(prize?.description) | truncate:[25]}}</span>
          <app-missing-label *ngIf="!prize?.description"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_CONTEXT_TYPE)" align="center" class="pointer fit-width">
              <span *ngIf="prize?.contextType"
                    [ngClass]="{'badge-soft-warning': prize?.contextType === 'COMPANY', 'badge-soft-dark': prize?.contextType === 'COMPETITON'}"
                    class="badge badge-pill badge-soft-primary">{{prize?.contextType}}</span>
          <app-missing-label *ngIf="!prize?.contextType"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_CONTEXT_DESCRIPTION)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.contextDetailsDescription">{{prize?.contextDetailsDescription}}</span>
          <app-missing-label *ngIf="!prize?.contextDetailsDescription"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_TYPE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.prizeType"
                class="badge badge-pill badge-soft-dark">{{prize?.prizeType | translate | uppercase}}</span>
          <app-missing-label *ngIf="!prize?.prizeType"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_PRIORITY)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.priority" class="badge badge-pill badge-soft-secondary">{{prize?.priority}}</span>
          <app-missing-label *ngIf="!prize?.priority"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_PRICE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.price">{{prize?.price}}</span>
          <app-missing-label *ngIf="!prize?.price"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_FROM)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.leaderboardFromInclusive">{{prize?.leaderboardFromInclusive}}</span>
          <app-missing-label *ngIf="!prize?.leaderboardFromInclusive"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_TO)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.leaderboardToInclusive">{{prize?.leaderboardToInclusive}}</span>
          <app-missing-label *ngIf="!prize?.leaderboardToInclusive"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_LEADERBOARD_BRACKET)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.leaderboardBracket">{{prize?.leaderboardBracket}}</span>
          <app-missing-label *ngIf="!prize?.leaderboardBracket"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_AVAILABLE_UNITS)" align="center" class="pointer fit-width">
          <span>{{prize?.totalUnits - prize?.redeemedUnits}}</span>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_TOTAL_UNITS)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.totalUnits">{{prize?.totalUnits}}</span>
          <app-missing-label *ngIf="!prize?.totalUnits"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_REDEEMED_UNITS)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.redeemedUnits">{{prize?.redeemedUnits}}</span>
          <app-missing-label *ngIf="!prize?.redeemedUnits"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_CREATION_DATE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.createdAt">{{prize?.createdAt | date:'dd/MM/yyyy'}}</span>
          <app-missing-label *ngIf="!prize?.createdAt"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_EXPIRATION_DATE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.expiresAt">{{prize?.expiresAt | date:'dd/MM/yyyy'}}</span>
          <app-missing-label *ngIf="!prize?.expiresAt"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_ACTIVE)" align="center" class="pointer fit-width">
          <span *ngIf="prize?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
          <span *ngIf="!prize?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
        </td>
        <!--        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_TRANSLATIONS)" align="center" class="pointer fit-width">-->
        <!--&lt;!&ndash;          <img (click)="utils.goCrowdin(lang)" *ngFor="let lang of prize?.languages" alt="Header Language"&ndash;&gt;-->
        <!--&lt;!&ndash;               class="me-2" height="16" ngbTooltip="{{lang?.text}} translation" placement="bottom"&ndash;&gt;-->
        <!--&lt;!&ndash;               src="{{lang.flag}}">&ndash;&gt;-->
        <!--        </td>-->
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_REDEMPTION_URL)" align="center" class="pointer fit-width">
               <span (click)="openLink(prize?.redemptionUrl)" *ngIf="prize?.redemptionUrl"
                     class="link"
                     ngbTooltip="Clicking here will open the redemption url"
                     placement="bottom">{{prize?.redemptionUrl | truncate:[35]}}</span>
          <app-missing-label *ngIf="!prize?.redemptionUrl"></app-missing-label>
        </td>
        <td *ngIf="checkIfColumnIsVisible(column.PRIZE_WEBSITE)" align="center" class="pointer fit-width">
               <span (click)="openLink(prize?.websiteActionUrl)" *ngIf="prize?.websiteActionUrl"
                     class="link"
                     ngbTooltip="Clicking here will open the website action"
                     placement="bottom">{{prize?.websiteActionUrl | truncate:[35]}}</span>
          <app-missing-label *ngIf="!prize?.websiteActionUrl"></app-missing-label>
        </td>
        <td class="fit-width">
          <ul class="list-inline font-size-20 contact-links mb-0">
            <li (click)="prizeActivateDeactivate(prize, !prize?.active)" class="list-inline-item px-2">
                  <span *ngIf="!prize?.active" class="pointer success"
                        ngbTooltip="Activate prize" placement="left"><i class="bx bx-check"></i></span>
              <span *ngIf="prize?.active" class="pointer danger"
                    ngbTooltip="Deactivate prize" placement="left"><i class="bx bx-x"></i></span>
            </li>
            <li (click)="editPrize(prize, false)"
                class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Edit" placement="left" placement="left"><i
                    class="bx bx bx-pencil"></i></span>
            </li>
            <li (click)="editPrize(prize, true)"
                class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="Duplicate" placement="left"><i
                    class="bx bx-copy"></i></span>
            </li>
            <li (click)="openPrizeCodes(prize)" [ngClass]="{'disabled-opacity': prize?.prizeType !== 'code_redemption'}"
                class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="Codes" placement="left"><i
                    class="bx bx-barcode"></i></span>
            </li>
            <li (click)="showUsers(prize)" class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="Show who redeemed this prize" placement="left">
                    <i class="bx bxs-user-detail"></i>
                  </span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="prizes?.length === 0" class="col-lg-12">
      <div class="">
        <div class="text-center mt-5">
          <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading$ | async" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
<app-pagination-footer [service]="prizesService" [total$]="total$"></app-pagination-footer>
<app-add-prize-modal #prizeModal></app-add-prize-modal>
<app-deactivate-modal #deactivateModal [service]="prizesService"></app-deactivate-modal>
<app-activate-modal #activateModal [service]="prizesService"></app-activate-modal>
<app-prize-codes-modal #prizeCodes></app-prize-codes-modal>
<app-user-uses-modal #users [large]="true" [leaderboard]="false" [showExtractResults]="false"
                     [showPagination]="true" [title]="'USERS_THAT_REDEEMED_THIS_PRICE'" [type]="'USERS_PRIZES'"></app-user-uses-modal>
