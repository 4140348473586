import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {CompetitionsService} from "@service/competitions/competitions.service";

@Component({
  selector: 'app-competition-select',
  templateUrl: './competition-select.component.html',
  styleUrls: ['./competition-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionSelectComponent implements OnInit, OnDestroy {
  @Output() selectedCompetition = new EventEmitter();
  @Input() error: boolean = false;
  @Input() restrictionId: any | undefined;
  @Input() selected: any | undefined;
  @Input() multiple: boolean;
	public data: any[] | undefined;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(public competitionsService: CompetitionsService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.competitionsService.pageSize = 25;
    this.competitionsService.initSearchCompetitionsListener( null, true);
    this.competitionsService._search$.next();
		this.competitionsService.competitionList$.pipe(takeUntil(this._unsubscribeAll)).subscribe((competitions) => {
			this.data = competitions;
			this._changeDetectorRef.markForCheck();
		});
  }
	
	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
		this.competitionsService.removeSearchCompetitionsSubscribe();
		this.selected = null;
	}

  public selectCompany(event: any): void {
    if (event) {
      this.selectedCompetition.emit(event);
    } else {
      this.selectedCompetition.emit(null);
    }
  }
	
	public extractPlaceholder(): string {
		if (this.selected) {
			return this.returnItemDescription(this.data?.find((item) => item.id === this.selected?.id));
		} else {
			return 'Select company'
		}
	}

  public returnItemDescription(item): string {
    if (item?.company?.name) {
      return [`[${item?.id?.toString()?.toUpperCase()}]`, `[${item?.company?.name?.toUpperCase()}]`,  item?.competitionName].join(' - ');
    } else {
      return [`[${item?.id?.toString()?.toUpperCase()}]`,  item?.competitionName].join(' - ');
    }
  }

  public search(term: string, item: any) {
		return item;
  }
}
