import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {PreconditionsService} from "@service/prizes/preconditions/preconditions.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {BadgesApiService} from "@page/rewards/badges/services/badges-api.service";


@Component({
  selector: 'app-preconditions-modal',
  templateUrl: './preconditions-modal.component.html',
  styleUrls: ['./preconditions-modal.component.scss']
})
export class PreconditionsModalComponent implements OnInit, OnDestroy {
  @Input() precondition: any;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
  public Editor = ClassicEditor
  public preconditionTypes = ['USER_PROFILE', 'POWER_LEVEL', 'BADGE', 'LEVEL'];
  public powerLevels = ['Silver', 'Bronze', 'Gold', 'Diamond'];
	public badges = [];
  public genders = ['MALE', 'FEMALE', 'OTHER'];
  public showAreYouSure: boolean = false;
  public addingPrecondition$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public activityForm: UntypedFormGroup;

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
							private _badgeApiService: BadgesApiService,
              private preconditionsService: PreconditionsService, private _changeDetectorRef: ChangeDetectorRef) {
  }

  get form() {
    return this.activityForm.controls;
  }

  get valid() {
    return this.activityForm.valid
  }

  ngOnInit(): void {
    this.addingPrecondition$ = this.preconditionsService.addingPrecondition$;
    this.initForm();
    this._loadBadges();
    (this.activityForm.get('steps') as UntypedFormArray)?.clear();
    if (this.precondition) {
      this.activityForm.patchValue(this.precondition);
    }
  }

  public validSubmit() {
    if (this.activityForm.valid) {
      this.preconditionsService.addPrecondition(this.activityForm.getRawValue());
    }
  }

  public checkNextActionBtn(): void {
    if (this.activityForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  private initForm(): void {
    this.activityForm = this.formBuilder.group({
      title: [!this.precondition || !this.precondition?.title ? null : this.precondition?.title, [Validators.required]],
      description: [!this.precondition || !this.precondition?.description ? null : this.precondition?.description, [Validators.required]],
      type: [!this.precondition || !this.precondition?.type ? null : this.precondition?.type, [Validators.required]],
      id: [this.precondition?.id && !this.precondition.duplicate ? this.precondition?.id : null],
      active: [this.precondition?.active && !this.precondition.duplicate ? this.precondition?.active : false],
      validPowerLevels: [this.precondition?.validPowerLevels ? this.precondition?.validPowerLevels : null],
      validForBadges: [this.precondition?.validForBadges ? this.precondition?.validForBadges : null],
      maximumAge: [this.precondition?.maximumAge ? this.precondition?.maximumAge : null],
      minimumAge: [this.precondition?.minimumAge ? this.precondition?.minimumAge : null],
	    minimumLevel: [this.precondition?.minimumLevel ? this.precondition?.minimumLevel : null],
	    maximumLevel: [this.precondition?.maximumLevel ? this.precondition?.maximumLevel : null],
	    neededGender: [this.precondition?.neededGender ? this.precondition?.neededGender : null],
      pictureTaken: [this.precondition?.pictureTaken ? this.precondition?.pictureTaken : null],
      profileCompleted: [this.precondition?.profileCompleted ? this.precondition?.profileCompleted : null]
    });
  }
	
	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	
	private _loadBadges(): void {
		this._badgeApiService.search({page: 1, size: 500}).pipe(takeUntil(this._unsubscribeAll)).subscribe((badges) => {
			this.badges = badges?.data ? badges?.data : [];
			console.log(badges);
		});
	}
}
