import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {takeUntil} from "rxjs";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit, OnDestroy {
  @Input() type: DeleteModalTypeEnum;
  @Input() service: any | undefined;
  @Input() modalData: { title: string, description: string, name: string, obj: any | any[] } | undefined;
  public showAreYouSure: boolean = false;
  public deleteOthers: boolean = false;
  public deleteList: boolean = false;
  public DeleteModalTypeEnum = DeleteModalTypeEnum;

  constructor(private eventService: EventService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (this.type !== DeleteModalTypeEnum.USER_FROM_COMPETITION) {
      this.service?.initDeleteSubscription();
    }
  }

  public delete(): void {
    if (this.type === DeleteModalTypeEnum.USER_FROM_COMPETITION) {
      this.service?.initRemoveSubscription();
      this.service.removeUser({data: this.modalData?.obj});
      this.service.removed$.pipe(takeUntil(this.service._destroyRemove$)).subscribe((message) => {
        if (message === 'DELETED') {
          this.eventService.broadcast(EventEnum.CLOSE_DELETE_MODAL);
        }
      });
    } else {
      this.service.delete({data: this.modalData?.obj, deleteOthers: this.deleteOthers, deleteList: this.deleteList});
      this.service.deleted$.pipe(takeUntil(this.service._destroyDelete$)).subscribe((message) => {
        if (message === 'DELETED') {
	        this.toastService.show('Deleted', {classname: 'bg-success text-light'});
          this.eventService.broadcast(EventEnum.CLOSE_DELETE_MODAL);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.type === DeleteModalTypeEnum.USER_FROM_COMPETITION) {
      this.service?.removeUserDeleteSubscription();
    } else {
      this.service?.removeDeleteSubscription();
    }
  }

}
