import {Component, Input, OnInit} from '@angular/core';
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {InvitesService} from "@service/utility/invites.service";
import {deeplinkActions} from "../../../shared/data";

@Component({
  selector: 'app-add-deeplink-modal',
  templateUrl: './add-deeplink-modal.component.html',
  styleUrls: ['./add-deeplink-modal.component.scss']
})
export class AddDeeplinkModalComponent implements OnInit {
  @Input() deeplink: any;
  public options = deeplinkActions;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public deeplinkForm: UntypedFormGroup;
  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder, private _invitesService: InvitesService) { }

  ngOnInit(): void {
    this.initForm();
  }

  get form() {
    return this.deeplinkForm.controls;
  }

  get valid() {
    return this.deeplinkForm.valid
  }
	
	public checkNextActionBtn(): void {
		this.showAreYouSure = this.deeplinkForm.valid;
		this.formSubmitted = !this.showAreYouSure;
	}
	
	public validSubmit() {
		if (!this.deeplinkForm.valid) {
			return;
		}
		const data = { ...this.deeplinkForm.getRawValue() };
		if (isNaN(data.contextDetails) && data.contextDetails?.id) {
			data.contextDetails = data.contextDetails.id;
		}
		if (data.contextAction === 'openClosableWebView' || data.contextAction === 'openFixedWebView' || data.contextAction === 'openLink') {
			data.link = data.contextDetails;
			data.contextDetails = null;
		}
		if (!data.id) {
			data.id = undefined;
		}
		this._invitesService.addDeeplink(data);
	}
	
	private initForm(): void {
		const { type, contextDetailsId, duplicate, active } = this.deeplink || {};
		this.deeplinkForm = this.formBuilder.group({
			contextAction: [type || null, [Validators.required]],
			contextDetails: [contextDetailsId || null],
			id: [undefined],
			active: [active && !duplicate || false],
		});
	}
}
