import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil, tap} from "rxjs";
import {CompaniesService} from "@service/companies/companies.service";
import {ExportService} from "@service/export/export.service";
import {ToastService} from "@service/toast.service";

@Component({
  selector: 'app-companies-streaks-list',
  templateUrl: './companies-streaks-list.component.html',
  styleUrls: ['./companies-streaks-list.component.scss']
})
export class CompaniesStreaksListComponent implements OnInit, OnDestroy {
  @Input() filters?: any;
  private _loading$ = new BehaviorSubject<boolean>(true);
  public dates: Date[] = [];
  public streaks: any[] | undefined;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _companiesService: CompaniesService, private exportService: ExportService, private toastService: ToastService) { }

  ngOnInit(): void {
    this._search();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !!date[2]) {
      this.dates = [date[1], date[2]];
    } else {
      this.dates = [];
    }
    this._search();
  }

  public get loading$() {
    return this._loading$.asObservable();
  }

  public downloadResults(event: any): void {
    if (this.streaks?.length > 0) {
      this._loading$.next(true);
      this.exportService.exportAsExcelFile(this.streaks, 'Daily goals')
      this._loading$.next(false);
    } else {
      this.toastService.show('No results to export', {classname: 'bg-danger text-light'});
    }
  }

  private _search(): void {
    if (this.dates?.length === 2) {
      this._companiesService.getStreaks(this.filters?.contextDetailsId, {periodStartInclusive: this.dates[0], periodEndExclusive: this.dates[1]}).pipe(takeUntil(this._unsubscribeAll), tap(() => this._loading$.next(true))).subscribe((result: any) => {
        this.streaks = result;
        this._loading$.next(false);
      });
    }
  }

}
