import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {
  @Input() showOnlyResultTotal?: boolean;
  @Input() service: any;
  @Input() total$: Observable<number>;
  @Input() selectedItems: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
