import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from "@service/toast.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      style="z-index: 1100!important;"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: {'[class.ngb-toasts]': 'true'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastsComponent implements OnInit, OnDestroy{
  public toasts: any[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(public toastService: ToastService, private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._checkToast();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private _checkToast(): void {
    this.toastService.toastUpdate$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.toasts = this.toastService.toasts;
      this._changeDetectorRef.markForCheck();
    })
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
