import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {Observable} from "rxjs";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";
import {CompetitionsService} from "@service/competitions/competitions.service";
import {UtilsService} from "@service/utils/utils.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SortEventInterface} from "@interface/common/sort-event.interface";
import {DeleteComponent} from "@component/common/delete/delete.component";
import {EventEnum} from "@enum/event/event.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";

@Component({
  selector: 'app-competitions-list-page',
  templateUrl: './competitions-list-page.component.html',
  styleUrls: ['./competitions-list-page.component.scss']
})
export class CompetitionsListPageComponent implements OnInit {
  @Input() filters?: any;
  @Input() paged?: any;
  public selectedItems: any[] = [];
  public topBarType: TopbarTypeEnum = TopbarTypeEnum.COMPETITION_LIST;
  public allSelected = false;
  public total$: Observable<number>;
  public loading$: Observable<boolean>;
  public competitionList$: Observable<any[]>;
  public visibleColumns: any[] = [];
  public availableColumns: any[] = [];
  public selected: any[] = [];
  public column = TableColumnEnum;
  public deleteModalType = DeleteModalTypeEnum;
  @ViewChild('deleteModal') private deleteModal: any
  @ViewChild('competitionModal') private modalComponent: any
  @ViewChild('postponeModal') private postponeModal: any
  @ViewChild('deactivateModal') private deactivateModal: any
  @ViewChild('activateModal') private activateModal: any

  constructor(public competitionsService: CompetitionsService, public utils: UtilsService,
              private modalService: NgbModal, private eventService: EventService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.competitionList$ = this.competitionsService.competitionList$;
    this.total$ = this.competitionsService.totalRecords$;
    this.loading$ = this.competitionsService.loading$;
    this.competitionsService.clearFilters();
    if (this.filters?.contextType === 'COMPANY') {
			this.competitionsService.companyId = this.filters?.contextDetailsId;
    }
    if (this.filters?.contextType === 'USER') {
      this.competitionsService.userId = this.filters?.contextDetailsId;
    }
    this.competitionsService.initSearchCompetitionsListener(null, true);
    this.route.data.subscribe((params: any) => {
      const maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + 7);
      if (params?.starting) {
        this.competitionsService.startsAt = ["BETWEEN", new Date().toISOString(), maxDate.toISOString()];
      } else if (params?.ending) {
        this.competitionsService.endsAt = ["BETWEEN", new Date().toISOString(), maxDate.toISOString()];
      }
    });
    this.competitionsService._search$.next();
  }

  public sort({column, direction}: SortEventInterface) {
    this.competitionsService.sortColumn = column;
    this.competitionsService.sortDirection = direction;
  }

  public checkIfColumnIsVisible(column: TableColumnEnum) {
    return this.selected.filter(availableColumns => availableColumns.key === column).length > 0
  }

  public extractRowNumber(index: any): string {
    return (this.competitionsService.page - 1) * this.competitionsService.pageSize + index + 1;
  }

  public competitionDetail(competition: any): void {
    this.router.navigate(['competitions/detail', String(competition?.id)]);
  }

  public extractCompetitionDescription(description): string {
    return description.replace(/<[^>]*>?/gm, '');
  }

  public openLink(url: any) {
    window.open(url, '_blank');
  }

  async competitionActivateDeactivate(competition: any, activate: boolean) {
    if (!activate) {
      const title = 'DEACTIVATE_COMPETITION';
      const description = 'DEACTIVATE_COMPETITION_DESC';
      return await this.deactivateModal.open(competition, title, description)
    } else if (activate) {
      const title = 'ACTIVATE_COMPETITION';
      const description = 'ACTIVATE_COMPETITION_DESC';
      return await this.activateModal.open(competition, title, description)
    }
  }

  async postponeCompetition(competition: any) {
    if (competition?.status === 'ACTIVE') {
      return;
    } else {
      const title = 'POSTPONE_COMPETITION';
      const description = 'POSTPONE_COMPETITION_DESC';
      return await this.postponeModal.open(competition, title, description)
    }
  }

  async deleteCompetitionModal(competition: any) {
    if (competition?.status !== 'ACTIVE') {
      const title = 'DELETE_COMPETITION';
      const description = 'DELETE_COMPETITION_DESC';
      return await this.deleteModal.open(competition, title, description)
    } else {
      return;
    }
  }

  public extractDeleteTootlip(competition: any, postpone?: true): string {
    if (competition?.status === 'ACTIVE') {
      if (postpone) {
        return 'This competition can\'t be postponed because it\'s active';
      } else {
        return 'This competition can\'t be deleted because it\'s active';
      }
    } else {
      if (postpone) {
        return 'Postpone competition';
      } else {
        return 'Delete competition';
      }
    }
  }

  async editCompetition(competition: any, duplicate?: boolean) {
    competition.duplicate = duplicate;
    return await this.modalComponent.open(competition)
  }

  async editCompany(competition: any, duplicate?: boolean) {
    competition.duplicate = duplicate;
    return await this.modalComponent.open(competition)
  }

  public async createCompetition() {
    return await this.modalComponent.open({specificCompetition: this.filters?.contextDetailsId});
  }

  public removeUser(competition: any): void {
    const edit = this.modalService.open(DeleteComponent, {size: 'md', centered: true, backdrop: false});
    edit.componentInstance.modalData = {title: 'REMOVE_USER_FROM_COMPETITION', name: competition?.competitionName, description: 'LOREM', obj: {...competition, ...{userId: this.filters?.contextDetailsId}}};
    edit.componentInstance.service = this.competitionsService;
    edit.componentInstance.type = DeleteModalTypeEnum.USER_FROM_COMPETITION;
    edit.result.then((result) => {}, (reason)=>{});
    const close = this.eventService.subscribe(EventEnum.CLOSE_DELETE_MODAL, (reason: string | undefined) => {
      this.competitionsService._search$.next();
      edit.dismiss();
    });
  }


  ngOnDestroy() {
    this.competitionsService.removeSearchCompetitionsSubscribe();
  }

}
