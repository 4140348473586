<div class="input-group">
  <input #date="ngbDatepicker" (click)="date.toggle()" (ngModelChange)="checkChanges($event)" [(ngModel)]="model"
         [autocomplete]="'off'" [name]="name" [ngClass]="{'is-invalid': invalid}"
         class="form-control datepicker-input pointer"
         id="datepicker{{name}}" name="dp{{name}}" ngbDatepicker placeholder="dd/mm/yyyy">
  <div class="input-group-prepend">
    <!--    <button (click)="reset()" class="btn btn-light datepicker-btn datepicker-btn&#45;&#45;no-radius datepicker-btn&#45;&#45;no-right-border datepicker-btn&#45;&#45;light" type="button">-->
    <!--      <i class="bx bx-x"></i>-->
    <!--    </button>-->
    <button (click)="reset()" class="btn btn-light datepicker-btn datepicker-btn--light" type="button">
      <i class="bx bx-x"></i>
    </button>

  </div>
</div>
