export enum ActionsEnum {
  OPEN_LINK = 'openLink',
  OPEN_PROFILE = 'openProfile',
  OPEN_COMPANY = 'openCompany',
  OPEN_PRIZE = 'openPrize',
  OPEN_COMPETITION = 'openCompetition',
  OPEN_CHALLENGE = 'openChallenge',
  OPEN_ALL_TOGETHER_CHALLENGE = 'openAllTogetherChallenge',
  OPEN_HOME = 'openHome',
}
