import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Observable, Subject, Subscription, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {EventEnum} from "@enum/event/event.enum";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CompaniesService} from "@service/companies/companies.service";
import {ChatRuleEnum} from "@enum/chat-rule.enum";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-add-company-modal',
  templateUrl: './add-company-modal.component.html',
  styleUrls: ['./add-company-modal.component.scss'],
})
export class AddCompanyModalComponent implements OnInit {
  @Input() company: any;
  public Editor = ClassicEditor
  public showAreYouSure: boolean = false;
  public creatingCompany$: Observable<boolean>;
  public formSubmitted: boolean;
  public companyForm: UntypedFormGroup;
  public activeId: number = 1;
  public logoImageLimit: any = {
    fixedSize: true,
    ratio: '1:1',
    width: 288,
    height: 288
  };
  public detailImageLimit: any = {
    fixedSize: true,
    ratio: '21:9',
    width: 1440,
    height: 616
  };
  public prizePreconditions = [];
  public teamScoreStrategies = ['AVG', 'SUM'];
  public countChallengeWhen = ['CHALLENGE_START', 'CHALLENGE_END'];
  public countChallengeStrategy = ['ALL_MEMBERS_IN_COMPANY', 'AT_LEAST_ONE_MEMBER_IN_COMPANY'];
  public prizeCodeType = ['shared', 'individual'];
  public selectedPrizeCodeType: string | undefined = 'individual';
  public selectedPrizeRestriction = 'none';
  public fitnessScoringFunction = undefined;
  public companyTypes = undefined;
  public companyRestriction = undefined;
  public companyPreconditions = undefined;
  public brackets = undefined;
  public companyCodes = undefined;
  public byLeaderboardPosition: boolean = false;
  public byMetricValue: boolean = false;
  public selectedPrecondition: any;
  public validEmails: string | undefined;
  public editField: string | number;
	public originalLanguages = [];
	public languages: any[] = [];
	public availableLanguages = [];
  public chatRuleOptions: ChatRuleEnum[] = [ChatRuleEnum.IGNORE, ChatRuleEnum.ENABLE_CHAT_FOR_COLLEAGUES, ChatRuleEnum.FORBIDD_EVERYONE];
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
	private uniqueCode: any;
  private closeModalSubscription: Subscription;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private modalService: NgbModal, private eventService: EventService, private ngZone: NgZone,
              public datepipe: DatePipe, public utils: UtilsService, private cdr: ChangeDetectorRef,
              private toastService: ToastService,               private _formService: FormsService,
              public formBuilder: UntypedFormBuilder, private companyService: CompaniesService) {
  }

  get form() {
    return this.companyForm.controls;
  }

  public get cardImage(): string | undefined {
    if (this.company?.logoImageUrl) {
      return this.company?.logoImageUrl;
    } else if (this.form?.logoImageUrl?.value) {
      return this.form?.logoImageUrl?.value;
    } else {
      return undefined;
    }
  }

  public get detailImage(): string | undefined {
    if (this.company?.detailsImageUrl) {
      return this.company?.detailsImageUrl;
    } else if (this.form?.detailsImageUrl?.value) {
      return this.form?.detailsImageUrl?.value;
    } else {
      return undefined;
    }
  }

  get valid() {
    return this.companyForm.valid;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingCompany$ = this.companyService?.creating$;
    this.initForm();
  }

  public setCompany(company: any): void {
    this.company = company;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.companyForm.valid) {
      let formData = this.companyForm.getRawValue();
      this.companyService?.initCreateListener();
      if (this.company?.id) {
        formData.id = this.company?.id;
      }
      formData.companyStatsConfiguration = {
        countChallengeStrategy: formData?.countChallengeStrategy,
        countChallengeWhen: formData?.countChallengeWhen,
        co2Improvement: formData?.co2Improvement,
        co2ReductionKg: 80000,
      };
      formData.prizeContext = {
        title: formData?.prizeContextTitle,
        description: formData?.prizeContextDescription,
      }
      formData.title = formData?.name;
      formData.image = formData?.detailsImageUrl;
      formData.logo = formData?.logoImageUrl;
      formData.corporationEmailDomains = this.validEmails?.split('\n');
			formData.updateCompanyLanguages = {
				original: this.originalLanguages,
				new: this.languages,
				uniqueCode: this.uniqueCode
			};
      this.companyService.createEditCompany(JSON.parse(JSON.stringify(formData)));
    } else {
      this._formService.findInvalidControlsRecursive(this.companyForm);
    }
  }

  public createPrize() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'xl', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.setCompany(data);
      }
    })
  }

  public setCardImage(image: any): void {
    this.companyForm.patchValue({
      logoImageUrl: image ? image?.originalUrl : undefined,
      logoImageName: image ? image?.name : undefined,
    });
  }

  public setDetailImage(image: any): void {
    this.companyForm.patchValue({
      detailsImageUrl: image ? image?.originalUrl : undefined,
      detailsImageName: image ? image?.name : undefined,
    });
  }

  public checkCardImageError(): string | undefined {
    if (this.formSubmitted && this.form.logoImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkDetailImageError(): string | undefined {
    if (this.formSubmitted && this.form.detailsImageUrl.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  public checkSelectedCardImage(): object | undefined {
    if (this.company?.logoImageUrl && this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.company?.logoImageUrl,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else if (this.form?.logoImageUrl?.value) {
      return {
        originalUrl: this.form?.logoImageUrl?.value,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkSelectedDetailImage(): object | undefined {
    if (this.company?.detailsImageUrl && this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.company?.detailsImageUrl,
        name: [this.company?.name?.replace(/\s/g, "_"), 'detail_image'].join('_')
      }
    } else if (this.form.detailsImageUrl?.value) {
      return {
        originalUrl: this.form.detailsImageUrl?.value,
        name: [this.company?.name?.replace(/\s/g, "_"), 'card_image'].join('_')
      }
    } else {
      return undefined;
    }
  }

  public checkAlert(type: string): boolean {
    if (!this.formSubmitted) {
      return false;
    }
    switch (type) {
      case 'BASE':
        return !!(this.form?.name?.errors || this.form?.description?.errors || this.form?.teamScoreStrategy?.errors || this.form?.priority?.errors  || this.checkCardImageError() || this.checkDetailImageError());
        break;
      case 'CONFIGURATION':
        return !!(this.form?.countChallengeWhen?.errors || this.form?.countChallengeStrategy?.errors || this.form?.co2Improvement?.errors);
        break;
      case 'PRECONDITIONS':
        return !!(this.form?.prizeContextTitle?.errors || this.form?.prizeContextDescription?.errors);
        break;
      default:
        return false;
    }
  }

  private initForm() {
    this.companyForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      description: [null, [Validators.required]],
      active: [false],
      discoverable: [false],
      enabled: [false],
      mustInsertCode: [false],
      showCompanyLeaderboards: [false],
      enableCompanyChallenge: [false],
      showMembersCount: [false],
      mustInsertName: [false],
      mustInsertCorporateMail: [false],
      mustConfirmCorporateMail: [false],
      validEmails: [null],
      emailCodeExpireInDays: [null],
      teamScoreStrategy: [null, [Validators.required]],
      priority: [null, [Validators.required]],
      logoImageUrl: [null, [Validators.required]],
      logoImageName: [null, [Validators.required]],
      detailsImageUrl: [null, [Validators.required]],
      detailsImageName: [null, [Validators.required]],
      co2Improvement: [null, [Validators.required]],
      countChallengeStrategy: [null, [Validators.required]],
      countChallengeWhen: [null, [Validators.required]],
      prizeContextTitle: [null],
      prizeContextDescription: [null],
      restrictsLocales: [null],
      expectedMembers: [null],
      uniqueCode: [null],
	    showFixedTreesCard: [null],
      languageToTranslationUrl: [null],
      chatRule: [ChatRuleEnum.IGNORE]
    });
  }

  private _loadWebappData(): void {
    this.companyService.getWebapp(this.company?.id).subscribe((result: any) => {
			if (result?.uniqueCode) {
				this.uniqueCode= result?.uniqueCode;
				this.utils.getLanguageForCompany(result?.uniqueCode).pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
					this.originalLanguages = JSON.parse(JSON.stringify(result?.map((lang) => lang?.value)));
					this.languages = JSON.parse(JSON.stringify(result?.map((lang) => lang?.value)));
					this.availableLanguages = this.utils?.languages?.map((lang) => lang?.value);
				});
			} else {
				this.languages = [];
				this.availableLanguages = this.utils?.languages?.map((lang) => lang?.value);
			}
    });
  }

  private patchFormWithEditingData() {
    this.companyForm.patchValue({
      name: this.company?.name,
      description: this.company?.description,
      active: this.company?.active,
      discoverable: this.company?.discoverable,
      enabled: this.company?.enabled,
      mustInsertCode: this.company?.mustInsertCode,
      showCompanyLeaderboards: this.company?.showCompanyLeaderboards,
      showMembersCount: this.company?.showMembersCount,
      teamScoreStrategy: this.company?.teamScoreStrategy,
      priority: this.company?.priority,
      logoImageUrl: this.company?.logo,
      logoImageName: this.company?.logoImageName ? this.company?.logoImageName : [this.company?.name?.replace(/\s/g, "_"), 'logo_image'].join('_'),
      detailsImageUrl: this.company?.image,
      detailsImageName: this.company?.detailsImageName ? this.company?.detailsImageName : [this.company?.name?.replace(/\s/g, "_"), 'detail_image'].join('_'),
      co2Improvement: this.company?.companyStatsConfiguration?.co2Improvement,
      countChallengeStrategy: this.company?.companyStatsConfiguration?.countChallengeStrategy,
      countChallengeWhen: this.company?.companyStatsConfiguration?.countChallengeWhen,
      prizeContextTitle: this.company?.prizeContext?.title,
      prizeContextDescription: this.company?.prizeContext?.description,
      enableCompanyChallenge: this.company?.enableCompanyChallenge,
      expectedMembers: this.company?.expectedMembers,
      mustInsertName: this.company?.mustInsertName,
      mustInsertCorporateMail: this.company?.mustInsertCorporateMail ? this.company?.mustInsertCorporateMail : false,
      mustConfirmCorporateMail: this.company?.mustConfirmCorporateMail ? this.company?.mustConfirmCorporateMail : false,
      emailCodeExpireInDays: this.company?.emailCodeExpireInDays ? this.company?.emailCodeExpireInDays : null,
      validEmails: this.company?.corporationEmailDomains ? this.company?.corporationEmailDomains : [],
      chatRule: this.company?.chatRule,
	    showFixedTreesCard: this.company?.showFixedTreesCard,
      restrictsLocales: this.company?.restrictsLocales ? this.company?.restrictsLocales : null,
      uniqueCode: this.company?.uniqueCode ? this.company?.uniqueCode : undefined,
      languageToTranslationUrl: this.company?.languageToTranslationUrl ? this.company?.languageToTranslationUrl : undefined,
    });
    this._checkValidEmails();
    if (this.company.duplicate) {
      this.company = undefined;
    }
		if (this.company?.id) {
			this._loadWebappData();
		} else {
			this.languages = [];
			this.availableLanguages = this.utils?.languages?.map((lang) => lang?.value);
		}
  }

  private _checkValidEmails() {
    if (this.company?.corporationEmailDomains?.length > 0) {
      this.validEmails = this.company?.corporationEmailDomains.join('\n');
    } else {
      this.validEmails = undefined;
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_COMPANY, (reason: string | undefined) => {
      this.companyService?.removeCreateCompanySubscribe();
      this.initForm();
      this.resetAllVariables();
      this.modalRef?.dismiss(reason);
    });
  }

  private resetAllVariables() {
    this.fitnessScoringFunction = undefined;
    this.formSubmitted = false;
    this.showAreYouSure = false;
    this.byLeaderboardPosition = false;
    this.byMetricValue = false;
    this.company = undefined;
    this.selectedPrizeCodeType = 'individual';
    this.selectedPrizeRestriction = 'none';
    this.companyPreconditions = [];
    this.validEmails = undefined;
  }

  public convertBool(control: string): void {
    this.companyForm.patchValue({[control]: JSON.parse(this.form?.[control]?.value)});
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }
}
