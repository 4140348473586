<div class="operation-modal-header">
  <h5 *ngIf="!partner">{{'CREATE_SUSTAINABILITY_PARTNER' | translate}}</h5>
  <h5 *ngIf="partner && !partner?.duplicate">{{'EDIT_SUSTAINABILITY_PARTNER' | translate}}: {{partner?.title}}</h5>
  <h5 *ngIf="partner && partner?.duplicate">{{'DUPLICATE_SUSTAINABILITY_PARTNER' | translate}}: {{partner?.title}}</h5>
  <div class="d-flex">
    <button (click)="utils.openLink(help)" class="btn close-modal-button close-modal-button-mr-2">
      <i class="mdi mdi-information-outline"></i>
    </button>
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_SUSTAINABILITY_PARTNER_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="common-modal-description">
  <p class="mb-0">{{'partnerModalDescription' | translate}}</p>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="partnerForm" autocomplete="off"
        class="needs-validation">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="title">{{'NAME' | translate}} <span class="text-danger" *ngIf="form?.title?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">Specify the name of the partner</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" [placeholder]="'Save the children'" class="form-control"
                 formControlName="title" id="title" type="text">
          <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>

          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="description">{{'DESCRIPTION' | translate}} <span class="text-danger" *ngIf="form?.description?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">Specify the description of the partner. It will be displayed on the web version of the project or competition</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control"  [placeholder]="'Save the children is a organization that help poor people...'"
                 formControlName="description" id="description" type="text">
          <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="website">{{'WEBSITE' | translate}} <span class="text-danger" *ngIf="form?.website?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">Specify the link of the partner's website.</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.website?.errors}" class="form-control" [placeholder]="'https://www.savethechildren.it/'"
                 formControlName="website" id="website" type="text">
          <div *ngIf="formSubmitted && form?.website?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.website?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="formSubmitted && form?.website?.errors?.pattern">Please insert a valid url</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <app-select-locale [description]="true" [parentForm]="partnerForm"></app-select-locale>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedImage('logo')"
                              [limit]="logoImageLimit"
                              [name]="'LOGO'"
                              [imageErrors]="checkImageErrors('logo')"
                              [showImagePreview]="true"
                              (imageUploaded)="setImage($event, 'logo')"></app-image-uploader>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedImage('backgroundImage')"
                              [limit]="detailImageLimit"
                              [imageErrors]="checkImageErrors('backgroundImage')"
                              [showImagePreview]="true"
                              (imageUploaded)="setImage($event, 'backgroundImage')"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !partner " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && partner && partner?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && partner && !partner?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !partner">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && partner && !partner?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && partner && partner?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
