<div class="operation-modal-header">
  <h5>{{'EDIT_ACTIVITY' | translate}} {{activity?.fitnessMetric | translate}}</h5>
  <div class="d-flex">
    <button [disabled]="(addingActivity$ | async)" class="btn close-modal-button" closeModal="CLOSE_RECOMMENDED_APP_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="activityDetailForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
          <li [ngbNavItem]="1" (click)="activeId = 1">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Details</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-3">
                        <label class="font-weight-bold mb-1" for="rule">{{'RULE_METRIC' | translate}}</label>
                        <p class="card-title-desc mb-2">{{'RULE_METRIC_DESC' | translate}}</p>
                        <input *ngIf="activity" [disabled]="true" [placeholder]="this.activity?.fitnessMetric | translate" class="form-control" id="rule" type="text">
                        <app-fitness-metric-picker *ngIf="!activity" (selectedFitnessMetric)="setFitnessMetric($event)" class="me-2"></app-fitness-metric-picker>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3">
                        <label class="font-weight-bold" for="description">Metric description</label>
                        <textarea [ngClass]="{'is-invalid': formSubmitted && form.description.errors}"
                                  class="form-control description-textarea"
                                  formControlName="description"
                                  id="description"
                                  rows="4"></textarea>
                        <div *ngIf="form.description.errors" class="invalid-feedback">
                          <span *ngIf="formSubmitted && form.description.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                          <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
                          <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label class="font-weight-bold" for="recommendedAppsTitle">{{'RECOMMENDED_APP_TITLE' | translate}}</label>
                      <p class="card-title-desc mb-2">{{'RECOMMENDED_APP_DESC' | translate}}</p>
                      <input [ngClass]="{'is-invalid': formSubmitted && form?.recommendedAppsTitle?.errors}" class="form-control"
                             formControlName="recommendedAppsTitle" id="recommendedAppsTitle" type="text">
                      <div *ngIf="formSubmitted && form?.recommendedAppsTitle?.errors" class="invalid-feedback">
                        <span *ngIf="formSubmitted && form?.recommendedAppsTitle?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                        <span *ngIf="form?.recommendedAppsTitle?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.recommendedAppsTitle?.errors?.maxlength?.requiredLength} }}</span>
                        <span *ngIf="form?.recommendedAppsTitle?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.recommendedAppsTitle?.errors?.minlength?.requiredLength} }}.</span>
                      </div>
                    </div>
                    <div class="col-6 mb-3" *ngIf="(recommendedApps$ | async) as apps">
                      <label class="font-weight-bold">Recommended apps</label>
                      <p class="card-title-desc mb-2">{{'RECOMMENDED_APP_DESC' | translate}}</p>
                      <div class="single-ng-select">
                        <ng-select
                          formControlName="recommendedAppIds"
                          [items]="apps?.data"
                          [multiple]="true"
                          bindLabel="title"></ng-select>
                      </div>
                    </div>
                    <div class="col-6 mb-3"  [ngClass]="{'col-12': activity?.fitnessMetric !== 'TotalSportMinutes'}" *ngIf="(benefits$ | async) as benefits">
                      <label class="font-weight-bold">Benefits</label>
                      <div class="single-ng-select">
                        <ng-select
                          formControlName="benefitIds"
                          [items]="benefits?.data"
                          [multiple]="true"
                          bindLabel="title"></ng-select>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="activity?.fitnessMetric === 'TotalSportMinutes' && (sports$ | async) as sports ">
                      <label class="font-weight-bold">Sport</label>
                      <div class="single-ng-select">
                        <ng-select
                          formControlName="sportIds"
                          [items]="sports?.data"
                          [multiple]="true"
                          bindLabel="title"></ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <app-image-uploader [name]="'IMAGE'"
                                        (imageUploaded)="setImage($event)"
                                        [showImagePreview]="true"
                                        [imageErrors]="checkLockedImageError()"
                                        [limit]="limit" [previousSelectedImage]="checkSelectedLockedImage()"></app-image-uploader>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" (click)="activeId = 2">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Sync steps Android</span>
            </a>
            <ng-template ngbNavContent>
              <div class="col-12">
                <div class="mb-3">
                  How can i sync activities for this metric?
                  <div formArrayName="syncStepsAndroid">
                    <div class="row max-height-50">
                      <div class="col-4" *ngFor="let group of itemsArrayAndroid.controls; let i = index; let first = first;" [formGroupName]="i">
                        <div class="step">
                          <div class="row">
                            <div class="col-4 mb-3">
                              <label class="font-weight-bold" for="numbering">{{'POSITION' | translate}}</label>
                              <input class="form-control" formControlName="numbering" id="numbering" type="number">
                            </div>
                            <div class="col-8">
                              <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                              <input class="form-control" formControlName="title" id="title" type="text">
                            </div>
                            <div class="col-12 mb-3">
                              <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
                              <textarea class="form-control description-textarea" formControlName="description" id="description" rows="4"></textarea>
                            </div>
                            <div class="col-12 mb-3" *ngIf="group?.controls?.languageToTranslationUrl?.value">
                              <label class="font-weight-bold" for="title">{{'LANGUAGES' | translate}}</label>
                              <div class="form-control" >
                                <img (click)="utils.openLink(lang.translationUrl)" *ngFor="let lang of group?.controls?.languageToTranslationUrl?.value" alt="Header Language"
                                     class="me-2" height="16" ngbTooltip="{{lang.language}} translation" placement="bottom"
                                     src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
                              </div>
                            </div>
                          </div>
                          <span class="btn btn-primary me-2" (click)="addItemFieldsAndroid()" *ngIf="first">Add</span>
                          <span class="btn btn-secondary" (click)="removeItemFieldsAndroid(i)" >Remove</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" (click)="activeId = 3">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block">Sync steps iOS</span>
            </a>
            <ng-template ngbNavContent>
              <div class="col-12">
                <div class="mb-3">
                  How can i sync activities for this metric?
                  <div formArrayName="syncStepsIos">
                    <div class="row max-height-50">
                      <div class="col-4" *ngFor="let group of itemsArrayiOS.controls; let i = index; let first = first;" [formGroupName]="i">
                        <div class="step">
                          <div class="row">
                            <div class="col-4 mb-3">
                              <label class="font-weight-bold" for="numbering">{{'POSITION' | translate}}</label>
                              <input class="form-control" formControlName="numbering" id="numbering" type="number">
                            </div>
                            <div class="col-8">
                              <label class="font-weight-bold" for="title">{{'TITLE' | translate}}</label>
                              <input class="form-control" formControlName="title" id="title" type="text">
                            </div>
                            <div class="col-12 mb-3">
                              <label class="font-weight-bold" for="description">{{'DESCRIPTION' | translate}}</label>
                              <textarea class="form-control description-textarea" formControlName="description" id="description" rows="4"></textarea>
                            </div>
                            <div class="col-12 mb-3" *ngIf="group?.controls?.languageToTranslationUrl?.value">
                              <label class="font-weight-bold" for="title">{{'LANGUAGES' | translate}}</label>
                              <div class="form-control" >
                                <img (click)="utils.openLink(lang.translationUrl)" *ngFor="let lang of group?.controls?.languageToTranslationUrl?.value" alt="Header Language"
                                     class="me-2" height="16" ngbTooltip="{{lang.language}} translation" placement="bottom"
                                     src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
                              </div>
                            </div>
                          </div>
                          <span class="btn btn-primary me-2" (click)="addItemFieldsiOs()" *ngIf="first">Add</span>
                          <span class="btn btn-secondary" (click)="removeItemFieldsiOs(i)" >Remove</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !activity " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && activity && activity?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && activity && !activity?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(addingActivity$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="addingActivity$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(addingActivity$ | async) && !activity">{{'CREATING' | translate}}</span>
          <span *ngIf="(addingActivity$ | async) && activity && !activity?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(addingActivity$ | async) && activity && activity?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(addingActivity$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingActivity$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
