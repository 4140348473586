import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UsersApiService} from "@service/users/users-api.service";
import {UtilsService} from "@service/utils/utils.service";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {PeriodOfInterestEnum} from "@enum/period-of-interest/period-of-interest.enum";
import {FitnessMetricLightInterface} from "@interface/metrics/fitness-metric-light.interface";

@Injectable({
  providedIn: 'root'
})
export class UserFitnessMetricsService {
  public _userFitnessMetricsSearch$ = new Subject<void>();
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;

  constructor(private usersApiService: UsersApiService, private utils: UtilsService) {
    this.initUserDiaryListener();
  }

  private _userFitnessMetrics$ = new BehaviorSubject<FitnessMetricLightInterface[]>([]);

  get userFitnessMetrics$() {
    return this._userFitnessMetrics$.asObservable();
  }

  private _userFitnessMetricsTotalRecords$ = new BehaviorSubject<number>(0);

  get userFitnessMetricsTotalRecords$() {
    return this._userFitnessMetricsTotalRecords$.asObservable();
  }

  get aroundInstant() {
    return this._currentTableState.instant;
  }

  set aroundInstant(instant: string) {
    this._setValue({instant});
  }

  get period() {
    return this._currentTableState.period;
  }

  set period(period: PeriodOfInterestEnum) {
    this._setValue({period});
  }

  get userId() {
    return this._currentTableState.userId;
  }

  set userId(userId: number) {
    this._setValue({userId});
  }

  public initUserDiaryListener(): void {
    this._userFitnessMetricsSearch$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.usersApiService.getUserFitnessSummary(String(this.userId), this._extractFitnessMetricsSummaryParams())),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._userFitnessMetrics$.next(result?.creditedActivities);
      this._userFitnessMetricsTotalRecords$.next(result?.creditedActivities?.length);
    });
  }

  private _extractFitnessMetricsSummaryParams(): any {
    return {
      aroundInstant: this.aroundInstant,
      period: this.period !== PeriodOfInterestEnum.ALL ? this.period : undefined
    }
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._userFitnessMetricsSearch$.next();
  }
}
