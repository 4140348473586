<div *ngIf="!single && (list$ | async) as challenges" class="row mb-3">
  <label class="col-md-3 col-form-label font-weight-bold">Select challenges</label>
  <div [ngClass]="{'is-invalid-select': error}" class="col-md-5 single-ng-select">
    <ng-select
      (change)="selectChallenge($event)"
      [(ngModel)]="selected"
      (search)="searchChallenge($event)"
      [items]="challenges"
      (blur)="searchChallenge($event)"
      [multiple]="false"
      bindLabel="bindLabel"></ng-select>
    <div *ngIf="error" class="invalid-feedback d-block mb-0">
      <span>{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="parentForm && single && (list$ | async) as challenges" [formGroup]="parentForm" class="col-md-12">
  <div [ngClass]="{'is-invalid-select': formSubmitted && form.actionDetail.errors}" class="mb-3 single-ng-select">
    <label class="font-weight-bold">Select challenges</label>
    <p class="card-title-desc mb-1">
      Select the challenge that the notification will open.
    </p>
    <ng-select (change)="selectChallenge($event)" [items]="challenges" bindLabel="bindLabel"
               (search)="searchChallenge($event)"
               (blur)="searchChallenge($event)"
               formControlName="actionDetail"></ng-select>
    <div *ngIf="formSubmitted && form.actionDetail.errors" class="invalid-feedback d-block">
      <span *ngIf="formSubmitted && form.actionDetail.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="pageFilter && (list$ | async) as challenges" class="input-group me-2 page-filter-group" [ngClass]="{'is-invalid-select': pageFilterErrors}">
  <ng-select (change)="selectChallenge($event)" [(ngModel)]="selected"
             [placeholder]="'Select challenge'"
             [items]="challenges" bindLabel="bindLabel"></ng-select>
</div>
