import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BadgesApiService} from "@page/rewards/badges/services/badges-api.service";
import {UntypedFormControl} from "@angular/forms";
import {distinctUntilChanged, startWith, Subject, takeUntil} from "rxjs";
import {debounceTime, switchMap, tap} from "rxjs/operators";

@Component({
  selector: 'app-badge-select',
  templateUrl: './badge-select.component.html',
  styleUrls: ['./badge-select.component.scss']
})
export class BadgeSelectComponent implements OnInit, OnDestroy {
	@Input() selected: any | undefined;
	@Output() selectedBadge = new EventEmitter();
	private _params: any = {
		filters: undefined,
		page: 1,
		size: 25
	}
	public _inputControl: UntypedFormControl | undefined = new UntypedFormControl(undefined);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	public data: any[];

  constructor(private _badgesApiService: BadgesApiService) { }

  ngOnInit(): void {
	  this._inputControl.valueChanges.pipe(
		  startWith(''),
		  debounceTime(400),
		  distinctUntilChanged(),
		  takeUntil(this._unsubscribeAll),
			tap((searchTerm) => {
				this._params.filters = {query: [searchTerm]}
			}),
		  switchMap(() => {return this._badgesApiService.search(this._params)}),
		  tap((data: any) => {
				this.data = data?.data?.map((badge) => {
					badge.id = undefined;
					badge.id = badge.badgeId;
					return badge;
				});
		  })
	  ).subscribe();
  }
	
	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	
	public setSearchTerm(event: any): void {
		this._inputControl.patchValue(event?.term);
	}
	
	public select(event: any): void {
		if (event) {
			this.selectedBadge.emit(event);
		} else {
			this.selectedBadge.emit(null);
		}
	}

}
