<app-table-topbar (emitSearchTerm)="date = $event; _loadFitnessSlots()" [type]="type"></app-table-topbar>
<div [hidden]="!slots" class="col-12 p-0">
  <div *ngIf="slots?.length > 0" class="wrapper slots-wrapper">
    <app-table (clickAction)="action($event)" [columns]="columns" [list]="slots" [name]="'slots'"></app-table>
  </div>
  <div *ngIf="slots?.length === 0" class="col-lg-12">
    <div class="">
      <div class="text-center mt-5">
        <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!slots" class="col-12 p-0">
  <div class="wrapper">
    <app-loader [table]="true"></app-loader>
  </div>
</div>
