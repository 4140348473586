export enum ActionOptions {
	HOME = 'openHome', //open app
	COMPETITION = 'openCompetition', //open specific competition using id
	COOPERATIVE_COMPETITION = 'openCooperativeCompetition', //open specific competition using id
	CHALLENGE = 'openChallenge', //open specific challenge using id
	COMPANY = 'openCompany', //open specific challenge using id
	COMPANY_SECTION = 'openCompanySection',
	USER_PROFILE = 'openProfile', //open specific profile using id or mine if userId is empty
	PRIZE = 'openPrize', //open specific prize using id or prize section if id is empty
	PRIZE_SECTION = 'openPrizeSection', //open specific prize using id or prize section if id is empty
	OPEN_CLOSABLE_WEBVIEW = 'openClosableWebView',
	OPEN_FIXED_WEBVIEW = 'openFixedWebView',
	BADGE = 'openBadges',
	FAQ = 'openFaq', //open faq section using id, faq sections if is empty
	FAQ_SECTION = 'openFaq', //open faq section using id, faq sections if is empty
	ARCADE = 'openArcade', //open arcade section
	LEADERBOARD = 'openLeaderboards', //open leaderboards section
	DIARY = 'openDiary',//open diary section
	LINK = 'openLink',
	CHARITY_PRIZE = 'openCharityPrize'
}
