import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "@service/toast.service";
import {UtilsService} from "@service/utils/utils.service";
import {SustainabilityPlacesService} from "@service/sustainability/places/sustainability-places.service";
import {PLACES} from "../../../configs/documentation/clickup";
import {FormsService} from "@service/common/forms.service";

@Component({
  selector: 'app-add-sustainability-place',
  templateUrl: './add-sustainability-place.component.html',
  styleUrls: ['./add-sustainability-place.component.scss']
})
export class AddSustainabilityPlaceComponent implements OnInit {
  @Input() place: any;
  public help = PLACES;
  public showAreYouSure: boolean = false;
  public adding$: Observable<boolean>;
  public formSubmitted: boolean = false;
  public placeForm: UntypedFormGroup;
  public imageLimit: any = {
    fixedSize: true,
    ratio: '16:9',
    width: 1920,
    height: 1080
  };

  constructor(private toastService: ToastService, public utils: UtilsService, private formBuilder: UntypedFormBuilder,
              private _formService: FormsService,
              private placeService: SustainabilityPlacesService) { }

  ngOnInit(): void {
    this.adding$ = this.placeService.adding$;
    this.initForm();
  }

  public validSubmit() {
    if (this.placeForm.valid) {
      const data = this.placeForm.getRawValue();
      this.placeService.addPlace(data);
    } else {
      this._formService.findInvalidControlsRecursive(this.placeForm);
    }
  }

  public checkNextActionBtn(): void {
    if (this.placeForm.valid) {
      this.showAreYouSure = true;
    } else {
      this.formSubmitted = true;
    }
  }

  get form() {
    return this.placeForm.controls;
  }

  get valid() {
    return this.placeForm.valid
  }

  public setImage(image: any): void {
    if (!image) {
      this.removeImage();
    } else {
      this.placeForm.patchValue({
        image: image?.originalUrl,
        imageName: image?.title,
        imageType: image?.mimeType,
      });
    }
  }

  public checkSelectedImage(): object | undefined {
    if (this.form?.image?.value) {
      return {name: this.form?.imageName?.value, originalUrl: this.form?.image?.value, mimeType: this.form?.imageType?.value}
    } else {
      return null;
    }
  }

  public removeImage(): void {
    this.placeForm.patchValue({
      image: null,
      imageName: null,
      imageType: null,
    });
  }

  public checkImageErrors(): string | undefined {
    if (this.formSubmitted && this.form.image.errors?.required) {
      return 'This value is required';
    } else {
      return undefined;
    }
  }

  private initForm(): void {
    this.placeForm = this.formBuilder.group({
      name: [!this.place || !this.place?.name ? null : this.place?.name, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      description: [!this.place || !this.place?.description ? null : this.place?.description, [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      restrictsLocales: [this.place?.restrictsLocales ? this.place?.restrictsLocales : []],
      image: [!this.place || !this.place?.image ? null : this.place?.image, [Validators.required]],
      link: [!this.place || !this.place?.link ? null : this.place?.link],
      imageName: [!this.place || !this.place?.image ? null : this.place?.name],
      imageType: [null],
      // coordinates: [!this.place || !this.place?.coordinates ? null : this.place?.coordinates],
      // latitude: [!this.place || !this.place?.coordinates || this.place?.coordinates?.length < 2 ? null : this.place?.coordinates[0], [Validators.required]],
      // longitude: [!this.place || !this.place?.coordinates || this.place?.coordinates?.length < 2 ? null : this.place?.coordinates[1], [Validators.required]],
      id: [this.place?.id && !this.place.duplicate ? this.place?.id : null]
    });
  }

}
