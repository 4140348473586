import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";

@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements OnInit {
  @Input() availableColumns: any[];
  @Input() columns: any[];
  @Input() service: any;
  @Input() hideColumns: any;
  @Input() hideSearch: any;
  @Input() hideFilters: any;
  @Input() id: any;
  @Input() type: TopbarTypeEnum | undefined;
  @Input() total$: Observable<number>;
  @Output() updateColumnsEvent: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();
  @Output() emit: EventEmitter<any> = new EventEmitter();
  public TopbarTypeEnum = TopbarTypeEnum;
  public searchTerm: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  public search(event: any): void {
    this.searchEvent.emit(event);
  }

  public updateColumns(event: any): void {
    this.updateColumnsEvent.emit(event);
  }
}
