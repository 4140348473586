<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        {{year}} © Healthy Virtuoso.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Manuel Fancello
        </div>
      </div>
    </div>
  </div>
</footer>
