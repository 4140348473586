<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!qrCode">{{'CREATE_QR_CODE' | translate}}</h5>
    <h5 *ngIf="qrCode">{{'EDIT_QR_CODE' | translate}}</h5>
    <div class="d-flex">
      <button [disabled]="(creatingQr$ | async)" class="btn close-modal-button" closeModal="CLOSE_QR_CREATE_MODAL">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="qrForm" autocomplete="off"
          class="needs-validation">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="message">{{'IN_APP_MESSAGE' | translate}}</label>
                <p class="card-title-desc mb-2">{{'IN_APP_MESSAGE_DESC' | translate}}</p>
                <input [ngClass]="{'is-invalid': formSubmitted && form.message.errors}" class="form-control"
                       formControlName="message" id="message" type="text">
                <div *ngIf="formSubmitted && form.message.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.message.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="form?.message?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.message?.errors?.maxlength?.requiredLength} }}</span>
                  <span
                    *ngIf="form?.message?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.message?.errors?.minlength?.requiredLength} }}
                    .</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="message">Code</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.code.errors}" class="form-control"
                       formControlName="code" id="code" type="text">
                <div *ngIf="formSubmitted && form.code.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.code.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold" for="message">Credits</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.amount.errors}" class="form-control"
                       formControlName="amount"
                       id="amount" type="number">
                <div *ngIf="formSubmitted && form.amount.errors" class="invalid-feedback">
                  <span *ngIf="formSubmitted && form.amount.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="mb-3">
                <label class="font-weight-bold" for="privateNote">Private notes</label>
                <textarea [ngClass]="{'is-invalid': formSubmitted && form.privateNote.errors}"
                          class="form-control description-textarea"
                          formControlName="privateNote"
                          id="privateNote"
                          rows="4"></textarea>
                <div *ngIf="form.privateNote.errors" class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form.privateNote.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="form?.privateNote?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.privateNote?.errors?.maxlength?.requiredLength} }}</span>
                  <span
                    *ngIf="form?.privateNote?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.privateNote?.errors?.minlength?.requiredLength} }}
                    .</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="font-weight-bold" for="options">Options</label>
                <div class="form-check form-switch form-switch-md mb-1" id="options">
                  <input class="form-check-input" formControlName="singleUseForUser" id="singleUseForUser"
                         type="checkbox">
                  <label class="form-check-label font-weight-semibold" for="singleUseForUser">Single use for
                    user</label>
                </div>
                <div class="form-check form-switch form-switch-md mb-1">
                  <input class="form-check-input" formControlName="validForCreditsBasedContests"
                         id="validForCreditsBasedContests" type="checkbox">
                  <label class="form-check-label font-weight-semibold" for="validForCreditsBasedContests">Credits valid
                    in competitions</label>
                </div>
                <div class="form-check form-switch form-switch-md ">
                  <input class="form-check-input" formControlName="active" id="active" type="checkbox">
                  <label class="form-check-label font-weight-semibold" for="active">Active</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createQrValidation()" *ngIf="!qrCode" [disabled]="(creatingQr$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingQr$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingQr$ | async) && !qrCode">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingQr$ | async) && !qrCode">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createQrValidation()" *ngIf="qrCode"
                  class="btn btn-warning">
            <i *ngIf="creatingQr$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingQr$ | async) && qrCode">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingQr$ | async) && qrCode">{{'EDIT' | translate}}</span>
          </button>
          <!--          <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>-->
          <!--          <button *ngIf="showAreYouSure" [disabled]="(creatingQr$ | async)" class="btn btn-warning me-2" type="submit">-->
          <!--            <i *ngIf="creatingQr$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>-->
          <!--            <span *ngIf="(creatingQr$ | async) && !qrCode">{{'CREATING' | translate}}</span>-->
          <!--            <span *ngIf="(creatingQr$ | async) && qrCode">{{'EDITING' | translate}}</span>-->
          <!--            <span [hidden]="(creatingQr$ | async)">-->
          <!--            {{'YES' | translate}}-->
          <!--          </span>-->
          <!--          </button>-->
          <!--          <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(creatingQr$ | async)"-->
          <!--                  class="btn btn-outline-light ">{{'NO' | translate}}-->
          <!--          </button>-->
        </li>
      </ul>
    </form>
  </div>
</ng-template>
