export enum DeleteModalTypeEnum {
  BAN_MOTIVATION = 'BAN_MOTIVATION',
  USER = 'USER',
  SCHEDULED_NOTIFICATION = 'SCHEDULED_NOTIFICATION',
  AUTOMATIC_BAN_RULE = 'AUTOMATIC_BAN_RULE',
  BLACKLIST_SOURCE = 'BLACKLIST_SOURCE',
  FAQ = 'FAQ',
  FTO = 'FTO',
  REWARD = 'REWARD',
  STREAK = 'STREAK',
  COMPETITION = 'COMPETITION',
  CODE = 'CODE',
  NOTIFICATION = 'NOTIFICATION',
  RECOMMENDED_APPS = 'RECOMMENDED_APPS',
  TEAM = 'TEAM',
  PLACE = 'PLACE',
  INVITES = 'INVITES',
  USER_FROM_COMPETITION = 'USER_FROM_COMPETITION',
	CONTENT_CATEGORY = 'CONTENT_CATEGORY',
	CONTENT = 'CONTENT',
	PRIZE_CATEGORY = 'PRIZE_CATEGORY'
}
