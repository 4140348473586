import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {DatePipe} from "@angular/common";
import {UtilsService} from "@service/utils/utils.service";
import {EventEnum} from "@enum/event/event.enum";
import {RewardsService} from "@service/rewards/rewards.service";

@Component({
  selector: 'app-add-reward-modal',
  templateUrl: './add-reward-modal.component.html',
  styleUrls: ['./add-reward-modal.component.scss']
})
export class AddRewardModalComponent implements OnInit {
  @Input() reward: any;
  public creatingReward$: Observable<boolean>;
  public formSubmitted: boolean;
  public rewardForm: UntypedFormGroup;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;

  constructor(private modalService: NgbModal, private eventService: EventService, public datepipe: DatePipe,
              private utils: UtilsService,
              public formBuilder: UntypedFormBuilder, private rewardsService: RewardsService) {
  }

  get form() {
    return this.rewardForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingReward$ = this.rewardsService?.creating$;
    this.initForm();
  }

  public setTemplate(streakTemplate: any): void {
    this.reward = streakTemplate;
    this.patchFormWithEditingData();
  }

  public validSubmit() {
    if (this.rewardForm.valid) {
      let formData = this.rewardForm.getRawValue();
      this.rewardsService?.initCreateListener();
      if (this.reward?.update) {
        formData.id = this.reward?.id;
        formData.update = this.reward?.update;
      }
      formData.idText = this.reward?.idText;
      formData.validForCreditsBasedContests = this.reward?.validForCreditsBasedContests;
      formData.validForCreditsBasedContests = this.reward?.validForCreditsBasedContests;
      formData.autoRedeem = this.reward?.autoRedeem;
      formData.maxEver = this.reward?.maxEver;
      formData.name = this.reward?.name;
      formData.inWindow = this.reward?.inWindow;
      formData.maxInWindow = this.reward?.maxInWindow;
      if (formData?.validSince && formData?.validSince?.includes('/')) {
        formData.validSince = this.utils.convertDateFromItalian(formData?.validSince);
      }
      if (formData?.validUntil && formData?.validUntil?.includes('/')) {
        formData.validUntil = this.utils.convertDateFromItalian(formData?.validUntil);
      }
      formData.validSince = new Date(formData.validSince).getTime();
      formData.validUntil = new Date(formData.validUntil).getTime();
      this.rewardsService.createRewards(formData);
    }
  }

  public createStreakTemplate() {
    this.formSubmitted = true;
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.setTemplate(data);
      }
    })
  }

  public setSinceDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.rewardForm.patchValue({['validSince']: convertedDate});
    const validUntil = this.rewardForm?.getRawValue()?.validUntil;
    const sinceToDate = this.rewardForm?.getRawValue()?.validSince;
    if (validUntil) {
      const validUntilString = validUntil.split("/");
      const validSince = sinceToDate.split("/");
      const validUntilObject = new Date(+validUntilString[2], validUntilString[1] - 1, +validUntilString[0]);
      const validSinceObject = new Date(+validSince[2], validSince[1] - 1, +validSince[0]);
      if (validUntilObject < validSinceObject) {
        this.rewardForm.patchValue({['validUntil']: null});
      }
    }
  }

  public setUpToDate(date: any): void {
    let convertedDate = this.datepipe.transform(date, 'dd/MM/yyyy');
    this.rewardForm.patchValue({['validUntil']: convertedDate});
  }

  private initForm() {
    this.rewardForm = this.formBuilder.group({
      validSince: [null, [Validators.required]],
      validUntil: [null, [Validators.required]],
      credits: [null, [Validators.required]],
    });
  }

  private patchFormWithEditingData() {
    this.rewardForm.patchValue({
      validSince: this.reward?.validSince ? new Date(this.reward?.validSince)?.toISOString() : null,
      validUntil: this.reward?.validUntil ? new Date(this.reward?.validUntil)?.toISOString() : null,
      credits: this.reward?.credits ? this.reward?.credits : null,
    });
    // if (this.reward.duplicate) {
    //   this.reward = undefined;
    // }
    // if (!this.reward?.validSince) {
    //   this.reward = undefined;
    // }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_REWARD, (reason: string | undefined) => {
      this.rewardsService?.removeRewardsCreateSubscribe();
      this.initForm();
      this.formSubmitted = false;
      this.reward = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}
