<div class="operation-modal-header">
  <h5 *ngIf="!place">{{'CREATE_PLACE' | translate}}</h5>
  <h5 *ngIf="place && !place?.duplicate">{{'EDIT_PLACE' | translate}}: {{place?.name}}</h5>
  <h5 *ngIf="place && place?.duplicate">{{'DUPLICATE_PLACE' | translate}}: {{place?.name}}</h5>
  <div class="d-flex">
    <button (click)="utils.openLink(help)" class="btn close-modal-button close-modal-button-mr-2">
      <i class="mdi mdi-information-outline"></i>
    </button>
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_SUSTAINABILITY_PLACE_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="common-modal-description">
  <p class="mb-0">{{'placeModalDescription' | translate}}</p>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="placeForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="title">{{'NAME' | translate}} <span class="text-danger" *ngIf="form?.name?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">Specify the name of the place. It should have at least 5 characters and max 100.</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.name?.errors}" class="form-control" [placeholder]="'Milano'" formControlName="name" id="name" type="text">
          <div *ngIf="formSubmitted && form?.name?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.name?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.name?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.name?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.name?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.name?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="description">{{'DESCRIPTION' | translate}} <span class="text-danger" *ngIf="form?.description?.errors?.required">*</span></label>
          <p class="card-title-desc mb-2">Specify the description of the place. It should have at least 5 characters and max 100.</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.description?.errors}" class="form-control" [placeholder]="'Milan, the world capital of fashion and design, is a metropolis in Northern Italy and is the capital of Lombardy'" formControlName="description" id="description" type="text">
          <div *ngIf="formSubmitted && form?.description?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
            <span *ngIf="form?.description?.errors?.maxlength"> {{ 'errorMaxLength' | translate: {length: form?.description?.errors?.maxlength?.requiredLength} }}</span>
            <span *ngIf="form?.description?.errors?.minlength"> {{ 'errorMinLength' | translate: {length: form?.description?.errors?.minlength?.requiredLength} }}.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <app-select-locale [description]="true" [parentForm]="placeForm"></app-select-locale>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="font-weight-bold mb-0" for="link">{{'LINK' | translate}}</label>
          <p class="card-title-desc mb-2">Specify the link of a map for the place.</p>
          <input [ngClass]="{'is-invalid': formSubmitted && form?.link?.errors}" class="form-control" [placeholder]="'https://goo.gl/maps/Zt7CFeY5KN2DNmdB9'" formControlName="link" id="link" type="text">
          <div *ngIf="formSubmitted && form?.link?.errors" class="invalid-feedback">
            <span *ngIf="formSubmitted && form?.link?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <app-image-uploader [previousSelectedImage]="checkSelectedImage()"
                              [limit]="imageLimit"
                              [imageErrors]="checkImageErrors()"
                              [showImagePreview]="true"
                              (imageUploaded)="setImage($event)"></app-image-uploader>
        </div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !place " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && place && place?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && place && !place?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !place">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && place && !place?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && place && place?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
