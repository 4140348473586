<div class="modal-header border-bottom-0">
  <h5 class="modal-title" id="modal-title-1">Webapp</h5>
  <button [disabled]="(working$ | async)" class="btn close-modal-button" closeModal="CLOSE_WEBAPP_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div *ngIf="webappForm" class="modal-body">
  <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim
    eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos
    error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore
    vero! Consectetur distinctio dolor eos hic veniam.</p>
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="webappForm" autocomplete="off"
        class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-3">
        <ul #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column" ngbNav>
          <li (click)="activeId = 1" [ngbNavItem]="1">
            <a class="mb-2" ngbNavLink>
              <span class="d-inline-block font-size-11">Configuration</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="enabled">Enabled</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.enabled?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="enabled"
                                 id="enabled" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="enabled">
                              <span *ngIf="form?.enabled?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.enabled?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="active">Show leaderboards</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showLeaderboards?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showLeaderboards"
                                 id="active" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.showLeaderboards?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showLeaderboards?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showTeams">Show teams</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showTeams?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showTeams"
                                 id="showTeams" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showTeams">
                              <span *ngIf="form?.showTeams?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showTeams?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showMembersCount">Show members</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showMemberships?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showMemberships"
                                 id="showMembersCount" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showMembersCount">
                              <span *ngIf="form?.showMemberships?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showMemberships?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showPrizes">Show prizes</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showPrizes?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showPrizes"
                                 id="showPrizes" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showPrizes">
                              <span *ngIf="form?.showPrizes?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showPrizes?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showStats">Show statistics</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showStats?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showStats"
                                 id="showStats" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showStats">
                              <span *ngIf="form?.showStats?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showStats?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showCompetitions">Show competitions</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showCompetitions?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showCompetitions"
                                 id="showCompetitions" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showCompetitions">
                              <span *ngIf="form?.showCompetitions?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showCompetitions?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showNews">Show contents</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input [checked]="form?.showNews?.value" autocomplete="off"
                                 class="form-check-input m-0 pointer"
                                 formControlName="showNews"
                                 id="showNews" type="checkbox">
                          <label class="form-check-label font-size-11 ms-3" for="showNews">
                              <span *ngIf="form?.showNews?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                            <span *ngIf="!form?.showNews?.value"
                                  class="badge badge-pill badge-soft-danger font-size-11">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showPrizes">Color</label>
                        <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                          <input type="text" [(ngModel)]="color" [ngModelOptions]="{standalone: true}"
                                 [style.background]="color" class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showPrizes">Unique code</label>
                        <span
                          class="d-block py-2 badge badge-pill badge-soft-danger font-size-11">{{form?.uniqueCode?.value}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="joinCode">Visible join code</label>
                        <select class="form-select" formControlName="joinCode" id="joinCode">
                          <option *ngFor="let code of codes" [value]="code?.code">{{code?.code | translate}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showPrizes">ENG Link</label>
                        <span (click)="navigateToWebapp('en')"
                              class="d-block py-2 badge badge-pill badge-soft-primary font-size-11 pointer-hidden">{{companyWebappLink('en')}}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                        <label class="font-weight-bold font-size-11" for="showPrizes">ITA Link</label>
                        <span (click)="navigateToWebapp('it')"
                              class="d-block py-2 badge badge-pill badge-soft-primary font-size-11 pointer-hidden">{{companyWebappLink('it')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <app-image-uploader (imageUploaded)="setImage($event, 'webImageLight')"
                                        [imageErrors]="checkWebappImageError('webImageLight')"
                                        [limit]="webappImageLimit"
                                        [name]="'IMAGE'"
                                        [previousSelectedImage]="checkWebappImageValue('webImageLight')"
                                        [showImagePreview]="true"></app-image-uploader>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li (click)="activeId = 2" [ngbNavItem]="2">
            <a class="mb-2" ngbNavLink>
              <span class="d-inline-block font-size-11">Copy & Translations</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="showNews">Title</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input autocomplete="off" class="form-control m-0 pointer" formControlName="title" id="title"
                             max="250" type="text">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="showNews">Message</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input autocomplete="off" class="form-control m-0 pointer" formControlName="message" id="message"
                             max="250" type="text">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold font-size-11" for="showNews">Translations</label>
                    <div *ngIf="translations" class="form-control">
                      <img (click)="utils.openLink(lang.translationUrl)" *ngFor="let lang of translations"
                           alt="Header Language"
                           class="me-2" height="16" ngbTooltip="{{lang.language}} translation" placement="bottom"
                           src="assets/images/flags/{{lang.language?.toUpperCase()}}.svg">
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-5">
      <li class="next list-inline-item float-end">
        <button (click)="valid ? showAreYouSure = true : formSubmitted = true;" *ngIf="!showAreYouSure"
                class="btn btn-warning">Edit
        </button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(working$ | async)" class="btn btn-warning me-2" type="submit">
          <i *ngIf="working$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(working$ | async)">{{'EDITING' | translate}}</span>
          <span [hidden]="(working$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working$ | async)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
