<div class="modal-header border-bottom-0">
  <h5 class="modal-title" id="modal-title-1">{{'RAW_DATA' | translate}} {{rawData?.id}}</h5>
  <button class="btn close-modal-button" closeModal="CLOSE_RAW_DATA_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <ngx-json-viewer *ngIf="parsedJson" [expanded]="expanded" [json]="parsedJson"></ngx-json-viewer>
</div>
<div class="modal-footer">
  <ul class="list-inline wizard mb-0 d-flex" style="width: 100%; justify-content: space-between;">
    <div class="d-flex">
      <li class="next list-inline-item float-start">
        <button class="btn btn-outline-light" closeModal="CLOSE_RAW_DATA_MODAL">Close</button>
      </li>
      <li class="next list-inline-item">
        <button (click)="copyJson()" class="btn btn-warning">Copy</button>
      </li>
      <li class="previous list-inline-item">
        <button (click)="expanded = !expanded" class="btn btn-success">
          <span *ngIf="!expanded">{{'EXPAND_JSON' |  translate}}</span>
          <span *ngIf="expanded">{{'COLLAPSE_JSON' |  translate}}</span>
        </button>
      </li>
    </div>
    <div class="d-flex">
    </div>
    <div class="d-flex" *ngIf="rawData?.isGarmin">
      <li class="next list-inline-item float-end">
        <button [disabled]="working" *ngIf="!rawData.showSelect" (click)="rawData.showSelect = !rawData?.showSelect" class="btn mb-3 btn-primary">Force process data</button>
        <ng-container  *ngIf="rawData.showSelect">
          <span class="me-3 font-weight-bold">{{'FORCE_PROCESS_LAST_24_HOURS' | translate}} </span>
          <button [disabled]="working" *ngIf="rawData.showSelect" class="btn mb-3 btn-outline-light me-2" (click)="processErrorData(false)">
            <span>
              {{'ALL' | translate}}
            </span>
          </button>
          <button [disabled]="working" *ngIf="rawData.showSelect" class="btn mb-3 btn-outline-light me-2" (click)="processErrorData(true)">
            <span>
              {{'JUST_IN_ERROR' | translate}}
            </span>
          </button>
        </ng-container>
      </li>
    </div>
  </ul>
</div>
