<ng-template #modal>
  <div class="operation-modal-header">
    <h5 *ngIf="!ftoSlot">{{'CREATE_SLOT' | translate}}</h5>
    <h5 *ngIf="ftoSlot">{{'EDIT_SLOT' | translate}}</h5>
    <div class="d-flex">
      <button [disabled]="(creatingFto$ | async)" class="btn close-modal-button" closeModal="CLOSE_CREATE_FTO">
        <i class="mdi mdi-close-circle-outline"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="ftoSlotForm" autocomplete="off"
          class="needs-validation">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3 single-ng-select">
                <label class="font-weight-bold mb-2">{{'FITNESS_METRIC' | translate}}</label>
                <ng-select [(ngModel)]="selectedFitnessMetric"
                           [clearable]="false"
                           [disabled]="true"
                           [items]="fitnessMetricList"
                           [ngClass]="{'is-invalid-select': formSubmitted && form?.fitnessMetric?.errors}"
                           disabled
                           formControlName="fitnessMetric">
                  <ng-template let-item="item" ng-label-tmp>
                    <span>{{ item | translate }}</span>
                  </ng-template>
                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <span class="font-weight-semibold ms-2">{{item | translate}}</span>
                  </ng-template>
                </ng-select>
                <div *ngIf="formSubmitted && form?.fitnessMetric?.errors" class="invalid-feedback d-block">
                  <span
                    *ngIf="formSubmitted && form?.fitnessMetric?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1"
                       for="validSinceInclusive">{{'STREAK_START_DATE' | translate}}</label>
                <app-simple-datepicker (dateSelected)="setSinceDate($event)"
                                       [isInvalid]="formSubmitted && form?.validSinceInclusive?.errors?.required"
                                       [minDateLock]="false"
                                       [name]="'Monday'"
                                       [selectedEndDate]="form?.validUntilExclusive?.value"
                                       [selectedStartDate]="form?.validSinceInclusive?.value"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validSinceInclusive?.errors" class="invalid-feedback d-block">
                  <span
                    *ngIf="formSubmitted && form?.validSinceInclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-1" for="validUntilExclusive">{{'STREAK_END_DATE' | translate}}</label>
                <app-simple-datepicker (dateSelected)="setUpToDate($event)"
                                       [isInvalid]="formSubmitted && form?.validUntilExclusive?.errors?.required"
                                       [minDateLock]="false"
                                       [name]="'Sunday'"
                                       [selectedEndDate]="form?.validUntilExclusive?.value"
                                       [selectedStartDate]="form?.validSinceInclusive?.value"></app-simple-datepicker>
                <div *ngIf="formSubmitted && form?.validUntilExclusive?.errors" class="invalid-feedback d-block">
                  <span
                    *ngIf="formSubmitted && form?.validUntilExclusive?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-2" for="creditableQuantumSize">Creditable quantum size</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.creditableQuantumSize.errors}"
                       class="form-control"
                       formControlName="creditableQuantumSize" id="creditableQuantumSize" min="1" type="number">
                <div *ngIf="formSubmitted && form.creditableQuantumSize.errors" class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form.creditableQuantumSize.errors.required">{{'VALUE_REQUIRED' | translate}}</span>

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="font-weight-bold mb-2" for="creditsPerQuantum">Credits per quantum</label>
                <input [ngClass]="{'is-invalid': formSubmitted && form.creditsPerQuantum.errors}" class="form-control"
                       formControlName="creditsPerQuantum" id="creditsPerQuantum" min="1" type="number">
                <div *ngIf="formSubmitted && form.creditsPerQuantum.errors" class="invalid-feedback">
                  <span
                    *ngIf="formSubmitted && form.creditsPerQuantum.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <app-image-uploader (imageUploaded)="setImage($event)"
                                    [imageErrors]="checkLockedImageError()"
                                    [limit]="limit"
                                    [name]="'IMAGE'"
                                    [previousSelectedImage]="checkSelectedLockedImage()"
                                    [showImagePreview]="true"></app-image-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-inline wizard mb-0">
        <li class="next list-inline-item float-end">
          <button (click)="createStreakTemplate()" *ngIf="!ftoSlot" [disabled]="(creatingFto$ | async)"
                  class="btn btn-warning">
            <i *ngIf="creatingFto$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFto$ | async) && !ftoSlot">
              {{'CREATING' | translate}}
            </span>
            <span *ngIf="!(creatingFto$ | async) && !ftoSlot">{{'CREATE' | translate}}</span>
          </button>
          <button (click)="createStreakTemplate()" *ngIf="ftoSlot"
                  class="btn btn-warning">
            <i *ngIf="creatingFto$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            <span *ngIf="(creatingFto$ | async) && ftoSlot">{{'EDITING' | translate}}</span>
            <span *ngIf="!(creatingFto$ | async) && ftoSlot">{{'EDIT' | translate}}</span>
          </button>
        </li>
      </ul>
    </form>
  </div>
</ng-template>
