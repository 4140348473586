<footer class="footer table-pagination">
  <div class="row justify-content-md-between align-items-md-center">
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div class="input-group input-group-sm">
        <div aria-live="polite" class="dataTables_info" id="bottom-total" role="status">
          <span class="badge badge-pill badge-soft-dark font-size-11"> {{total$ | async}} results</span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 text-center"></div>
    <div *ngIf="!showOnlyResultTotal" class="col-sm-12 col-md-3 col-xl-4">
      <div class="text-md-right float-md-end">
        <ngb-pagination [(page)]="service.page" [class.custom-pagination]="true"
                        [collectionSize]="total$ | async" [maxSize]="3"
                        [pageSize]="service.pageSize">
        </ngb-pagination>
      </div>
    </div>
    <div *ngIf="!showOnlyResultTotal" class="col-md-3 col-xl-2">
      <div class="text-md-right float-md-end">
        <app-table-size [updateMethod]="service"></app-table-size>
      </div>
    </div>
  </div>
</footer>
