<app-table-topbar [type]="topBarType" [service]="competitionsService" [showColumnPicker]="true"
                  (openCompetitionCreate)="createCompetition()"
                  (updateColumnsEvent)="selected = $event"
                  (emitSearchTerm)="competitionsService.searchTerm = $event"></app-table-topbar>
<div class="container-fluid p-0 full-height-table">
  <div class="row">
    <div [hidden]="(loading$ | async)" class="col-12">
      <div *ngIf="competitionList$ | async as competitions" class="wrapper">
        <table *ngIf="competitions?.length > 0"
               class="table table-bordered align-middle table-nowrap table-hover table-striped mb-0 datatables"
               id="basic-datatable">
          <thead>
          <tr>
            <th></th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ID)" class="column-sortable"
                sortable="id">
              <span class="me-3">{{'COMPETITION_ID' | translate}}</span>
            </th>
            <th *ngIf="checkIfColumnIsVisible(column.COMPETITION_IMAGE)">
              <span class="me-3">{{'COMPETITION_IMAGE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_NAME)" class="column-sortable"
                sortable="competitionName">
              <span class="me-3">{{'COMPETITION_NAME' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_DESCRIPTION)"
                class="column-sortable"
                sortable="description">
              <span class="me-3">{{'COMPETITION_DESCRIPTION' | translate}}</span>
            </th>
            <th (sort)="sort($event)" class="column-sortable"
                sortable="company">
              <span class="me-3">{{'PRIZE_CONTEXT' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_TYPE)" class="column-sortable"
                sortable="type">
              <span class="me-3">{{'COMPETITION_TYPE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_START_DATE)" class="column-sortable"
                sortable="startsAt">
              <span class="me-3">{{'COMPETITION_START_DATE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_END_DATE)" class="column-sortable"
                sortable="startsAt">
              <span class="me-3">{{'COMPETITION_END_DATE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_METRIC)" class="column-sortable"
                sortable="metric.actualMetric">
              <span class="me-3">{{'COMPETITION_METRIC' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_STATUS)" class="column-sortable"
                sortable="status">
              <span class="me-3">{{'COMPETITION_STATUS' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ACTIVE)" class="column-sortable"
                sortable="active">
              <span class="me-3">{{'COMPETITION_ACTIVE' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_BY_POWER_LEVEL)"
                class="column-sortable"
                sortable="leaderboardByPowerLevel">
              <span class="me-3">{{'COMPETITION_BY_POWER_LEVEL' | translate}}</span>
            </th>
            <th (sort)="sort($event)" *ngIf="checkIfColumnIsVisible(column.PRIZE_CREATION_DATE)" class="column-sortable"
                sortable="createdAt">
              <span class="me-3">{{'COMPANY_CREATED_AT' | translate}}</span>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="filters?.contextType === 'COMPANY'">
            <tr *ngFor="let competition of competitions; let i=index;">
              <td (click)="competitionDetail(competition)" align="center" class="pointer fit-width">
                <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ID)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.id" class="badge badge-pill badge-soft-primary">{{competition?.id}}</span>
                <app-missing-label *ngIf="!competition?.id"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_IMAGE)"
                  align="center" class="pointer fit-width">
                <img *ngIf="competition?.image" [src]="competition?.image" class="competition-image">
                <app-missing-label *ngIf="!competition?.image"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_NAME)"
                  align="center" class="pointer">
                <span *ngIf="competition?.competitionName">{{competition?.competitionName}}</span>
                <app-missing-label *ngIf="!competition?.competitionName"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_DESCRIPTION)"
                  align="center" class="pointer fit-width">
              <span
                *ngIf="competition?.description">{{extractCompetitionDescription(competition?.description) | truncate:[35]}}</span>
                <app-missing-label *ngIf="!competition?.description"></app-missing-label>
              </td>
              <td align="center" class="pointer fit-width">
              <span *ngIf="competition?.company?.name"
                    class="badge badge-pill badge-soft-secondary">{{competition?.company?.name | translate}}</span>
                <span *ngIf="!competition?.company"
                      class="badge badge-pill badge-soft-primary">{{'GLOBAL' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_TYPE)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.type">{{competition?.type | translate}}</span>
                <app-missing-label *ngIf="!competition?.type"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_START_DATE)" align="center" class="pointer fit-width">
                <span *ngIf="competition?.startsOn">{{competition?.startsOn | date:'dd/MM/yyyy'}}</span>
                <app-missing-label *ngIf="!competition?.startsOn"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_END_DATE)" align="center" class="pointer fit-width">
                <span *ngIf="competition?.endsOn">{{competition?.endsOn | date:'dd/MM/yyyy'}}</span>
                <app-missing-label *ngIf="!competition?.endsOn"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_METRIC)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.metric?.actualMetric">{{competition?.metric?.actualMetric | translate}}</span>
                <app-missing-label *ngIf="!competition?.metric?.actualMetric"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_STATUS)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.status">{{competition?.status | translate}}</span>
                <app-missing-label *ngIf="!competition?.status"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ACTIVE)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                <span *ngIf="!competition?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)"
                  *ngIf="checkIfColumnIsVisible(column.COMPETITION_BY_POWER_LEVEL)" align="center"
                  class="pointer fit-width">
              <span *ngIf="competition?.leaderboardByPowerLevel"
                    class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                <span *ngIf="!competition?.leaderboardByPowerLevel"
                      class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CREATED_AT)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.createdAt">{{competition?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="!competition?.createdAt"></app-missing-label>
              </td>
              <td class="fit-width">
                <ul class="list-inline font-size-20 contact-links mb-0">
                  <li (click)="deleteCompetitionModal(competition)"
                      [ngClass]="{'disabled-opacity': competition?.status === 'ACTIVE'}"
                      class="list-inline-item px-2">
                  <span [ngbTooltip]="extractDeleteTootlip(competition)" class="pointer danger" placement="left"><i
                    class="bx bx bx-trash"></i></span>
                  </li>
                  <li (click)="competitionActivateDeactivate(competition, !competition?.active)"
                      class="list-inline-item px-2">
                  <span *ngIf="!competition?.active" class="pointer success"
                        ngbTooltip="Activate competition" placement="left"><i class="bx bx-check"></i></span>
                    <span *ngIf="competition?.active" class="pointer danger"
                          ngbTooltip="Deactivate competition" placement="left"><i class="bx bx-x"></i></span>
                  </li>
                  <li (click)="editCompetition(competition, false)"
                      class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Edit" placement="left" placement="left"><i
                    class="bx bx bx-pencil"></i></span>
                  </li>
                  <li (click)="editCompetition(competition, true)"
                      class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="Duplicate" placement="left"><i
                    class="bx bx-copy"></i></span>
                  </li>
                  <li (click)="postponeCompetition(competition)"
                      [ngClass]="{'disabled-opacity': competition?.status !== 'ACTIVE'}"
                      class="list-inline-item px-2">
                  <span [ngbTooltip]="extractDeleteTootlip(competition, true)" class="pointer" placement="left"><i
                    class="bx bx-calendar-plus"></i></span>
                  </li>
                </ul>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="filters?.contextType === 'USER'">
            <tr *ngFor="let competition of competitions; let i=index;">
              <td (click)="competitionDetail(competition)" align="center" class="pointer fit-width">
                <span class="badge badge-pill badge-soft-secondary">#{{extractRowNumber(i)}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ID)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.id" class="badge badge-pill badge-soft-primary">{{competition?.id}}</span>
                <app-missing-label *ngIf="!competition?.id"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_IMAGE)"
                  align="center" class="pointer fit-width">
                <img *ngIf="competition?.image" [src]="competition?.image" class="competition-image">
                <app-missing-label *ngIf="!competition?.image"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_NAME)"
                  align="center" class="pointer">
                <span *ngIf="competition?.competitionName">{{competition?.competitionName}}</span>
                <app-missing-label *ngIf="!competition?.competitionName"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_DESCRIPTION)"
                  align="center" class="pointer fit-width">
              <span
                *ngIf="competition?.description">{{extractCompetitionDescription(competition?.description) | truncate:[35]}}</span>
                <app-missing-label *ngIf="!competition?.description"></app-missing-label>
              </td>
              <td align="center" class="pointer fit-width">
              <span *ngIf="competition?.company?.name"
                    class="badge badge-pill badge-soft-secondary">{{competition?.company?.name | translate}}</span>
                <span *ngIf="!competition?.company"
                      class="badge badge-pill badge-soft-primary">{{'GLOBAL' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_TYPE)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.type">{{competition?.type | translate}}</span>
                <app-missing-label *ngIf="!competition?.type"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_START_DATE)" align="center" class="pointer fit-width">
                <span *ngIf="competition?.startsOn">{{competition?.startsOn | date:'dd/MM/yyyy'}}</span>
                <app-missing-label *ngIf="!competition?.startsOn"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_END_DATE)" align="center" class="pointer fit-width">
                <span *ngIf="competition?.endsOn">{{competition?.endsOn | date:'dd/MM/yyyy'}}</span>
                <app-missing-label *ngIf="!competition?.endsOn"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_METRIC)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.metric?.actualMetric">{{competition?.metric?.actualMetric | translate}}</span>
                <app-missing-label *ngIf="!competition?.metric?.actualMetric"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_STATUS)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.status">{{competition?.status | translate}}</span>
                <app-missing-label *ngIf="!competition?.status"></app-missing-label>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPETITION_ACTIVE)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                <span *ngIf="!competition?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)"
                  *ngIf="checkIfColumnIsVisible(column.COMPETITION_BY_POWER_LEVEL)" align="center"
                  class="pointer fit-width">
              <span *ngIf="competition?.leaderboardByPowerLevel"
                    class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
                <span *ngIf="!competition?.leaderboardByPowerLevel"
                      class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
              </td>
              <td (click)="competitionDetail(competition)" *ngIf="checkIfColumnIsVisible(column.COMPANY_CREATED_AT)"
                  align="center" class="pointer fit-width">
                <span *ngIf="competition?.createdAt">{{competition?.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
                <app-missing-label *ngIf="!competition?.createdAt"></app-missing-label>
              </td>
              <td class="fit-width">
                <ul class="list-inline font-size-20 contact-links mb-0" *ngIf="filters?.contextType === 'USER'">
                  <li (click)="removeUser(competition)" class="list-inline-item px-2">
                    <span class="pointer danger" ngbTooltip="Remove this user from competition" placement="left"><i class="bx bx-x"></i></span>
                  </li>
                </ul>
                <ul class="list-inline font-size-20 contact-links mb-0" *ngIf="filters?.contextType !== 'USER'">
                  <li (click)="deleteCompetitionModal(competition)"
                      [ngClass]="{'disabled-opacity': competition?.status === 'ACTIVE'}"
                      class="list-inline-item px-2">
                  <span [ngbTooltip]="extractDeleteTootlip(competition)" class="pointer danger" placement="left"><i
                    class="bx bx bx-trash"></i></span>
                  </li>
                  <li (click)="competitionActivateDeactivate(competition, !competition?.active)"
                      class="list-inline-item px-2">
                  <span *ngIf="!competition?.active" class="pointer success"
                        ngbTooltip="Activate competition" placement="left"><i class="bx bx-check"></i></span>
                    <span *ngIf="competition?.active" class="pointer danger"
                          ngbTooltip="Deactivate competition" placement="left"><i class="bx bx-x"></i></span>
                  </li>
                  <li (click)="editCompetition(competition, false)"
                      class="list-inline-item px-2">
                  <span class="pointer"
                        ngbTooltip="Edit" placement="left" placement="left"><i
                    class="bx bx bx-pencil"></i></span>
                  </li>
                  <li (click)="editCompetition(competition, true)"
                      class="list-inline-item px-2">
                  <span class="pointer" ngbTooltip="Duplicate" placement="left"><i
                    class="bx bx-copy"></i></span>
                  </li>
                  <li (click)="postponeCompetition(competition)"
                      [ngClass]="{'disabled-opacity': competition?.status !== 'ACTIVE'}"
                      class="list-inline-item px-2">
                  <span [ngbTooltip]="extractDeleteTootlip(competition, true)" class="pointer" placement="left"><i
                    class="bx bx-calendar-plus"></i></span>
                  </li>
                </ul>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div *ngIf="competitions?.length === 0" class="col-lg-12">
          <div class="">
            <div class="text-center mt-5">
              <h4 class="text-uppercase">{{'SORRY_NO_RESULTS' | translate}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading$ | async" class="col-12 p-0">
      <div class="wrapper">
        <app-loader [table]="true"></app-loader>
      </div>
    </div>
  </div>
</div>
<app-pagination-footer [service]="competitionsService" [showOnlyResultTotal]="!paged"
                       [total$]="total$"></app-pagination-footer>
<app-activate-modal #activateModal [service]="competitionsService"></app-activate-modal>
<app-deactivate-modal #deactivateModal [service]="competitionsService"></app-deactivate-modal>
<app-add-competition-modal #competitionModal></app-add-competition-modal>
<app-postpone-modal #postponeModal [service]="competitionsService"></app-postpone-modal>
<app-delete-modal #deleteModal [service]="competitionsService" [type]="deleteModalType.COMPETITION"></app-delete-modal>
