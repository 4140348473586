import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from "@service/utils/utils.service";
import {SortEventInterface} from "@interface/common/sort-event.interface";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Input() name: string;
  @Input() list: any;
  @Input() columns: any;
  public allElementsSelected = false;
  public selectedElements: any[] = [];
  constructor(public utils: UtilsService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    this._checkTranslations();
  }

  private _checkTranslations() {
    if (this.list?.length > 0 && this.columns?.length > 0) {
      const translateColumn = this.columns.find(x => x.localizableKeys === true);
      if (!!translateColumn) {
        this.list = this.list.map((element: any) => {
          element.languages = this.utils.getAvailableLang(element?.localizableKey);
          return element;
        });
      }
    }
  }

  public visible(): boolean {
    return !(!this.list || this.list?.length === 0 || !this.columns || this.columns?.length === 0);
  }

  public selectAllRows(): void {
    this.allElementsSelected = !this.allElementsSelected;
    this.list = this.list.map((element: any) => {
      element.selected = this.allElementsSelected;
      return element;
    });
    this.checkSelectElements();
    this.selected.emit(this.selectedElements);
  }

  public checkSelectElements(): void {
    this.selectedElements = this.list.filter((element: any) => {
      return element.selected;
    });
  }

  public checkHiddenAction(action: any, element: any): boolean {
    if (action && "hideIf" in action) {
      return element[action.hideIf];
    } else if (action && "showIf" in action) {
      return !element[action.showIf];
    } else {
      return false;
    }
  }

  public checkDisabledAction(action: any, element: any): boolean {
    if (action && "disabledIf" in action) {
      return !!element[action.disabledIf];
    } else {
      return false;
    }
  }

  public translate(data: any): void {
  }

	public checkUcare(url: string): boolean {
		return url?.includes('https://ucarecdn.com/');
	}
	
  public sort({column, direction}: SortEventInterface, element?: any) {
    if (!element?.sortable) return;
    if (column && direction) {
      this.clickAction.emit({methodName: 'sort', param: {column, direction}})
    } else {
      this.clickAction.emit({methodName: 'sort', param: null})
    }
  }
}
