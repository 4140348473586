import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription, take} from "rxjs";
import {SortDirection} from "@type/common/sort-direction.type";
import {catchError, debounceTime, map, switchMap, tap} from "rxjs/operators";
import {TableStateInterface} from "@interface/common/table-state.interface";
import {UtilsService} from "@service/utils/utils.service";
import {NewsApiService} from "@service/utility/news-api.service";
import {TableColumnEnum} from "@enum/table-column/table-column.enum";
import {TableFilterEnum} from "@enum/table-filter/table-filter.enum";
import {EventEnum} from "@enum/event/event.enum";
import {EventService} from "@service/common/event.service";
import {ToastService} from "@service/toast.service";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  public _search$ = new Subject<void>();
  public _create$ = new Subject<string>();
  public _delete$ = new Subject<void>();
  public visibleColumns: { visible: boolean, key: TableColumnEnum, label: any }[] = this.utils.newsListColumns;
  public visibleFilters: { visible: boolean, key: TableFilterEnum, label: any }[] = this.utils.newsListFilters;
  private _currentTableState: TableStateInterface = this.utils.tableDefaultState;
  private searchNewsSubscription: Subscription;
  private deleteNewsSubscription: Subscription;
  private createNewsSubscription: Subscription;
  private _exporting = new BehaviorSubject<any>(null);

  constructor(private utils: UtilsService, private newsApiService: NewsApiService, private eventService: EventService,
              private toastService: ToastService) {
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  public get loading$() {
    return this._loading$.asObservable();
  }

  private _creating$ = new BehaviorSubject<boolean>(false);

  get creating$() {
    return this._creating$.asObservable();
  }

  private _deleting$ = new BehaviorSubject<boolean>(false);

  get deleting$() {
    return this._deleting$.asObservable();
  }

  private _newsList$ = new BehaviorSubject<any[]>([]);

  public get newsList$() {
    return this._newsList$.asObservable();
  }

  private _totalRecords$ = new BehaviorSubject<number>(0);

  public get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  get priority() {
    return this._currentTableState.priority;
  }

  set priority(priority: string[]) {
    this._setValue({priority});
  }

  public get searchTerm() {
    return this._currentTableState.searchTerm;
  }

  public set searchTerm(searchTerm: string) {
    this._setValue({searchTerm});
  }

  public get pageSize() {
    return this._currentTableState.pageSize;
  }

  public set pageSize(pageSize: number) {
    const page = 1;
    this._setValue({page})
    this._setValue({pageSize});
  }

  public get page() {
    return this._currentTableState.page;
  }

  public set page(page: number) {
    this._setValue({page});
  }

  public get sortColumn() {
    return this._currentTableState.sortColumn;
  }

  public set sortColumn(sortColumn: string) {
    this._setValue({sortColumn});
  }

  get columns() {
    return this.visibleColumns;
  }

  get filters() {
    return this.visibleFilters;
  }

  public get sortDirection() {
    return this._currentTableState.sortDirection;
  }

  public set sortDirection(sortDirection: SortDirection) {
    this._setValue({sortDirection});
  }

  get validForAllCompanies() {
    return this._currentTableState.validForAllCompanies;
  }

  set validForAllCompanies(validForAllCompanies: boolean | undefined | null) {
    this._setValue({validForAllCompanies});
  }

  get active() {
    return this._currentTableState.active;
  }

  set active(active: boolean | undefined | null) {
    this._setValue({active});
  }

  get createdAt() {
    return this._currentTableState.createdAt;
  }

  set createdAt(createdAt: string[]) {
    this._setValue({createdAt});
  }

  get updatedAt() {
    return this._currentTableState.updatedAt;
  }

  set updatedAt(updatedAt: string[]) {
    this._setValue({updatedAt});
  }

  get exporting$() {
    return this._exporting.asObservable();
  }

  public removeSearchSubscribe(): void {
    this.searchNewsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public delete(data: any): void {
    this._delete$.next(data);
  }

  public removeDeleteSubscribe(): void {
    this.deleteNewsSubscription?.unsubscribe();
    this._loading$.next(false);
  }

  public initDeleteListener(): void {
    this.deleteNewsSubscription = this._delete$.pipe(
      tap(() => this._deleting$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((source) => this.newsApiService.deleteNews(source).pipe(
        map((result) => {
          this._deleting$.next(false);
          return this.modalSuccess(result, EventEnum.CLOSE_DELETE_MODAL, 'News deleted successfully');
        }),
        catchError((err, caught) => {
          this._deleting$.next(false);
          return this.modalError(err, EventEnum.CLOSE_DELETE_MODAL);
        })
      )),
      tap(() => this._deleting$.next(false)),
    ).subscribe((result) => {
    });
  }

  public createNews(data: any): void {
    this._create$.next(data);
  }

  public removeCreateSubscribe(): void {
    this.createNewsSubscription?.unsubscribe();
    this._creating$.next(false);
  }

  public initCreateListener(): void {
    this.createNewsSubscription = this._create$.pipe(
      tap(() => this._creating$.next(true)),
      tap(() => this._loading$.next(true)),
      switchMap((data: any) => this.newsApiService.createNews(data).pipe(
        map((result) => {
          if (result?.length > 0) {
            result.map((error) => {
              this.toastService.show(error?.cause, {classname: 'bg-danger text-light'});
              return error;
            });
            this.eventService.broadcast(EventEnum.CLOSE_ADD_NEWS_MODAL, null)
            this._search$.next();
            return result;
          } else {
            const message = data?.id ? 'News edited successfully' : 'News created successfully';
            return this.modalSuccess(result, EventEnum.CLOSE_ADD_NEWS_MODAL, message);
          }
        }),
        catchError((err, caught) => {
          this._creating$.next(false);
          return this.modalError(err, EventEnum.CLOSE_ADD_NEWS_MODAL);
        })
      )),
      tap(() => this._creating$.next(false))
    ).subscribe((result) => {
    });
  }

  public initSearchListener(): void {
    this.searchNewsSubscription = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(50),
      switchMap(() => this.newsApiService.getNewsList(this._extractSearchParams()).pipe(catchError(error => of(error)))),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      let news = result?.data?.map((news) => {
        news.languages = this.utils.getAvailableLang(news?.localizableKey);
        return news;
      });
      this._newsList$.next(news ? news : []);
      this._totalRecords$.next(result?.size);
    });
  }

  public isFilterApplied(length?: boolean): boolean | number {
    const params: any = this._extractSearchParams();
    if (Object.keys(params?.filters)?.length > 0) {
      const obj = this.utils.clearObject(params?.filters);
      if (!length) {
        return Object.keys(obj)?.length > 0;
      } else {
        return Object.keys(obj)?.length
      }
    } else {
      return false;
    }
  }

  public clearFilters(): void {
    this.updatedAt = undefined;
    this.createdAt = undefined;
    this.priority = undefined;
    this.validForAllCompanies = undefined;
    this.active = undefined;
    this.searchTerm = undefined;
  }

  public getMaxPriority() {
    return this.newsApiService.getNewsList({page: 1, size: 1, sort: 'priority,desc'})
  }

  public exportData(): any {
    this.totalRecords$.pipe(take(1)).subscribe(totalRecords => {
      let pages = Math.ceil(totalRecords / 100);
      let requests: Observable<any>[] = [];
      while (pages > 0) {
        requests.push(this.newsApiService.getNewsList(this._extractSearchParams(pages, 100)));
        pages--;
      }
      requests = requests.reverse();
      this.utils.exportData(totalRecords, requests, this._exporting);
    });
  }

  private modalSuccess(result, modalEvent: EventEnum, message: string) {
    this.toastService.show(message, {classname: 'bg-success text-light'});
    this.eventService.broadcast(modalEvent, null)
    this._search$.next();
    return result;
  }

  private modalError(err, modalEvent: EventEnum) {
    this.eventService.broadcast(modalEvent, null)
    this.toastService.show(err, {classname: 'bg-danger text-light'});
    this._search$.next();
    return err;
  }

  private _extractSearchParams(customPage?: any, customSize?: any): any {
    return {
      filters: {
        query: this.searchTerm ? [this.searchTerm] : undefined,
        active: (this.active !== undefined && this.active !== null) ? [this.active] : undefined,
        validForAllCompanies: (this.validForAllCompanies !== undefined && this.validForAllCompanies !== null) ? [this.validForAllCompanies] : undefined,
        priority: this.priority && this.priority?.length > 0 ? this.priority : undefined,
        createdAt: this.createdAt && this.createdAt?.length > 0 ? this.createdAt : undefined,
        updatedAt: this.updatedAt && this.updatedAt?.length > 0 ? this.updatedAt : undefined,
      },
      sort: this.extractSorting(),
      page: !customPage ? this.page : customPage,
      size: !customSize ? this.pageSize : customSize
    }
  }

  private updateDate(date: any[]): any {
    return date.filter(element => element !== "BETWEEN");
  }

  private _setValue(patch: Partial<TableStateInterface>) {
    Object.assign(this._currentTableState, patch);
    this._search$.next();
  }

  private extractSorting(): string {
    return this.utils.extractSorting(this.sortColumn, this.sortDirection);
  }
}
