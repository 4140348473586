import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CompaniesService} from "@service/companies/companies.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {EventEnum} from "@enum/event/event.enum";
import {Subscription} from "rxjs";
import {UtilsService} from "@service/utils/utils.service";
import {ToastService} from "@service/toast.service";
import {Router} from "@angular/router";
import {CompaniesCodesService} from "@service/companies/companies-codes.service";
import {DeleteModalTypeEnum} from "@enum/delete-modal-type/delete-modal-type.enum";

@Component({
  selector: 'app-company-codes-modal',
  templateUrl: './company-codes-modal.component.html',
  styleUrls: ['./company-codes-modal.component.scss']
})
export class CompanyCodesModalComponent implements OnInit {
  @ViewChild('deleteModal') private deleteModal: any
  @ViewChild('deactivateModal') private deactivateModal: any
  @ViewChild('activateModal') private activateModal: any
  @ViewChild('modal') private modalContent: TemplateRef<any>
  @Input() showOnlyAdd?: boolean;
  private modalRef: NgbModalRef;
  public codes: any[] = [];
  public newPrizeCodes: string | undefined;
  public company: any = undefined;
  public showAddCodes = false;
  public deleteModalType = DeleteModalTypeEnum;
  public loading = false;
  private closeModalSubscription: Subscription;
  constructor(private companiesService: CompaniesService, private modalService: NgbModal, private eventService: EventService,
              public companiesCodesService: CompaniesCodesService,
              public utils: UtilsService, private toastService: ToastService, private router: Router) { }

  ngOnInit(): void {
    this._closeModal();
  }


  public open(company: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.company = company;
      if (!this.showOnlyAdd) {
        this.extractCompanyCodes();
      } else {
        this.showAddCodes = true;
      }
    })
  }

  private extractCompanyCodes() {
    this.loading = true;
    this.companiesService.getInviteCodes(this.company?.id).subscribe((result) => {
      this.codes = result?.data;
      this.loading = false;
    });
  }

  public userDetail(user: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['users/detail', String(user?.id)])
    );
    window.open(url, '_blank');
  }

  public addCodes() {
    this.loading = true;
    const newCodes = this.newPrizeCodes.split('\n');
    const finalArray = newCodes?.map((code: string) => {
      return {id: Math.random().toString(36).substr(2, 9), code: code, totalUnits: 1, currentUses: 0};
    })?.filter((code) => !!code?.code);
    this.companiesService.addCompanyCodes(finalArray, this.company?.id).subscribe((result: any) => {
      if (result?.length > 0) {
        if (this.showOnlyAdd) {
          this.toastService.show('Codes added successfully', {classname: 'bg-success text-light'});
          this.closeModalForced(null);
        } else {
          this.showAddCodes = false;
          this.toastService.show('Codes added successfully', {classname: 'bg-success text-light'});
          this.extractCompanyCodes();
        }
      } else {
        this.loading = false;
        this.toastService.show('There was an error adding the codes', {classname: 'bg-danger text-light'});
      }
    });
  }

  public copied(event: any): void {
    this.toastService.show('Copied - ' + event?.content, {classname: 'bg-secondary text-light'});
  }

  private resetAllVariables() {
    this.company = undefined;
    this.showAddCodes = false;
    this.codes = [];
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_COMPANY_CODES_MODAL, (reason: string | undefined) => {
      this.closeModalForced(reason);
    });
  }


  async codeActivateDeactivate(code: any, activate: boolean) {
    if (!activate) {
      const title = 'DEACTIVATE_CODE';
      const description = 'DEACTIVATE_CODE_DESC';
      return await this.deactivateModal.open(code, title, description)
    } else if (activate) {
      const title = 'ACTIVATE_CODE';
      const description = 'ACTIVATE_CODE_DESC';
      return await this.activateModal.open(code, title, description)
    }
  }

  async deleteCode(code) {
    if (code?.usesCount > 0) {
      return;
    } else {
      const title = 'DELETE_CODE';
      const description = 'LOREM';
      return await this.deleteModal.open([code], title, description)
    }
  }


  public extractDeleteTootlip(code: any): string {
    if (code?.usesCount > 0) {
      return 'This code can\'t be deleted because it\'s used by ' + code?.usesCount + ' people';
    } else {
      return 'Delete code';
    }
  }

  private closeModalForced(reason: string) {
    this.resetAllVariables();
    this.modalRef?.dismiss(reason);
  }
}
