<div class="modal-header border-bottom-0">
  <h5 class="modal-title" id="modal-title-1">{{'ADD_USERS_TO_COMPANY' | translate}}
    <span *ngIf="company">: {{company?.name}}</span>
  </h5>
  <button [disabled]="(addingMemberships$ | async)" class="btn close-modal-button" closeModal="CLOSE_ADD_MEMBERSHIPS_MODAL">
    <i class="mdi mdi-close-circle-outline"></i>
  </button>
</div>
<div class="modal-body">
  <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque blanditiis delectus enim eveniet ipsam iure necessitatibus reiciendis sed vitae? Aliquid aspernatur beatae blanditiis consequatur ducimus eos error ex excepturi facere fuga hic minima molestias nulla officiis optio perspiciatis, quae rerum soluta tempore vero! Consectetur distinctio dolor eos hic veniam.</p>
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="membershipForm" autocomplete="off"
        class="needs-validation">
  <div class="row">
    <div class="col-12">
      <label class="font-weight-bold">
        {{'USERS' | translate}}
        <span *ngIf="selectedUsers?.length > 0" class="badge badge-pill badge-soft-success font-size-11">{{selectedUsers?.length}}</span>
      </label>
      <div *ngIf="!showSelectedUsers">
        <textarea [(ngModel)]="tmpUserIds" [ngModelOptions]="{standalone: true}"
                  [ngClass]="{'is-invalid': formSubmitted && form?.userIds?.errors?.required}"
                  class="form-control codes-textarea"
                  placeholder="Insert all the user ids separated with a space"
                  id="userIds"
                  rows="4"></textarea>
        <div *ngIf="formSubmitted && form.userIds.errors" class="invalid-feedback d-block">
          <span *ngIf="formSubmitted && form.userIds.errors.required">{{'VALUE_REQUIRED' | translate}}</span>
        </div>
        <button (click)="addUserIds()" class="btn btn-primary mt-3">Get users by id</button>
      </div>
      <div *ngIf="showSelectedUsers && selectedUsers?.length > 0">
        <div class="user-table-notification">
          <app-users-table (removedUser)="removeUser($event)" [type]="DeleteModalTypeEnum.NOTIFICATION" [canBeRemoved]="true" [light]="true" [users]="selectedUsers"></app-users-table>
        </div>
        <button (click)="addMoreUsers()" class="btn btn-primary mt-3">Add more ids</button>
      </div>
    </div>
    <div class="col-12 mt-3">
      <label class="font-weight-bold">{{'SELECT_COMPANY' | translate}}</label>
      <app-company-select (selectedCompany)="this.membershipForm.controls['company'].setValue($event); this.company = $event; this.extractTeams();"
                          [error]="formSubmitted && form?.company?.errors?.required"
                          [selected]="this.form?.company?.value"></app-company-select>
    </div>
    <div class="col-6" *ngIf="company && this.form?.company?.value && showSelectedUsers && selectedUsers?.length > 0">
      <div class="mt-3 mb-3">
        <label class="font-weight-bold">Select team</label>
        <p class="card-title-desc mb-1">
          If the user is already present in the company this will be the new team.
        </p>
        <select [ngClass]="{'is-invalid': formSubmitted && form?.team?.errors}" formControlName="team" class="form-select">
          <option *ngFor="let team of teams" [value]="team?.teamId">{{team?.name | translate}}</option>
        </select>
        <div *ngIf="formSubmitted && form?.team?.errors" class="invalid-feedback d-block">
          <span *ngIf="formSubmitted && form?.team?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="company && this.form?.company?.value && showSelectedUsers && selectedUsers?.length > 0">
    <div class="mt-3 mb-3">
      <label class="font-weight-bold">Insert join code</label>
      <p class="card-title-desc danger mb-1">
        Warning! This field is required for new members. Please fill when adding for the first time in the company.
      </p>
      <input [ngClass]="{'is-invalid': formSubmitted && form?.code?.errors}" class="form-control" formControlName="code" placeholder="Insert one single code" id="description" type="text">
      <div *ngIf="formSubmitted && form?.code?.errors" class="invalid-feedback d-block">
        <span *ngIf="formSubmitted && form?.code?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
      </div>
    </div>
  </div>
  </div>
  <ul class="list-inline wizard mb-0">
    <li class="next list-inline-item float-end">
      <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure " class="btn mb-3 btn-warning">Add</button>
      <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
      <button *ngIf="showAreYouSure" [disabled]="(addingMemberships$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
        <i *ngIf="addingMemberships$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        <span *ngIf="(addingMemberships$ | async)">{{'ADDING_USERS' | translate}}</span>
        <span [hidden]="(addingMemberships$ | async)">
            {{'YES' | translate}}
          </span>
      </button>
      <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(addingMemberships$ | async)"
              class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
      </button>
    </li>
  </ul>
  </form>
</div>
