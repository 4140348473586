import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {UserDiagnosticService} from "@service/users/user-diagnostic.service";
import {UtilsService} from "@service/utils/utils.service";
import {FitnessMetricEnum} from "@enum/fitness-metric/fitness-metric.enum";

@Component({
  selector: 'app-diagnostic-topbar',
  templateUrl: './diagnostic-topbar.component.html',
  styleUrls: ['./diagnostic-topbar.component.scss']
})
export class DiagnosticTopbarComponent implements OnInit {
  @Input() userId: number;
  @Input() type: TopbarTypeEnum;
  @Input() download: boolean;
  @Input() totalResults: any | undefined;
  @Output() dateRangeSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() metricSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() downloadEvent: EventEmitter<boolean> = new EventEmitter();
  public clearFilters: Subject<boolean> = new Subject<boolean>();
  public topbarTypeEnum = TopbarTypeEnum;
  public fitnessMetricEnum = FitnessMetricEnum;
  public dates: Date[] = [];
  public selectedDates: any = undefined;
  public selectedMetric: any = FitnessMetricEnum.STEPS;
  public list: any = this.utils.getFitnessMetrics();
  private eventsSubscription: Subscription;

  constructor(private userDiagnosticService: UserDiagnosticService, private utils: UtilsService) {
  }

  ngOnInit(): void {
    this.selectedDates = this.userDiagnosticService.selectedDates;
  }

  public setDate(date: any): void {
    if (date && !!date[1] && !!date[2]) {
      this.dates = [date[1], date[2]];
      if (this.type === TopbarTypeEnum.DIAGNOSTIC_RECORDED_ACTIVITIES) {
        this.searchFitnessDataForSelectedRanges();
      }
    } else {
      this.dates = [];
    }
    this._setNgbDates(this.dates)
  }

  public returnOnlyFilteredFitnessMetrics(): any[] {
    return this.list.filter((el: any) => {
      return el.value === FitnessMetricEnum.STEPS || el.value === FitnessMetricEnum.SPORT || el.value === FitnessMetricEnum.SLEEP || el.value === FitnessMetricEnum.MEDITATION || el.value === FitnessMetricEnum.YOGA_MINUTES;
    });
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  private _setNgbDates(dates: any[]): any {
    if (dates?.length < 2) {
      this.selectedDates = undefined;
    } else {
      dates = dates?.map((date: any) => {
        return new Date(date);
      });
      const startDate = {year: dates[0].getFullYear(), month: dates[0].getMonth() + 1, day: dates[0].getDate()};
      const endDate = {year: dates[1].getFullYear(), month: dates[1].getMonth() + 1, day: dates[1].getDate()};
      this.selectedDates = {startDate, endDate};
    }
    this.userDiagnosticService.selectedDates = this.selectedDates;
  }

  private searchFitnessDataForSelectedRanges(): void {
    this.list?.map((el: any) => {
      this.userDiagnosticService.getUserFitnessCharts(this.dates[0], this.dates[1], String(this.userId), el.value).subscribe((result: any) => {
        if (result?.length > 0) {
          el.viewValue = this._extractViewValue(el.value);
          el.total = this.userDiagnosticService.aggregateKpisForTopbar(result);
        }
        return el;
      }, (error => {
      }));
    });
  }

  private _extractViewValue(value: FitnessMetricEnum): string {
    switch (value) {
      case FitnessMetricEnum.STEPS:
        return 'Steps';
      case FitnessMetricEnum.SPORT:
        return 'Sport';
      case FitnessMetricEnum.SLEEP:
        return 'Sleep';
      case FitnessMetricEnum.MEDITATION:
        return 'Meditation';
      case FitnessMetricEnum.YOGA_MINUTES:
        return 'Yoga';
    }
  }

}
