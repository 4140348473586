import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {UntypedFormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {AudienceEnum} from "@enum/audience/audience.enum";
import {PrizesService} from "@service/prizes/prizes.service";

@Component({
  selector: 'app-select-prize',
  templateUrl: './select-prize.component.html',
  styleUrls: ['./select-prize.component.scss']
})
export class SelectPrizeComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Output() selectedPrize = new EventEmitter();
  @Input() users: any;
  @Input() editDetail: any;
  @Input() selectedAudience: any;
  @Input() single: boolean;
  @Input() selectedItems: any;
  @Input() formSubmitted: boolean;
  @Input() parentForm: UntypedFormGroup;
  public selected: any;
  public filtered: any;
  public list$: Observable<any[]>;
  public subscription: Subscription;

  constructor(private prizesService: PrizesService) {
  }

  get form() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm?.controls['actionDetail']?.disabled) {
      this.parentForm.controls['actionDetail'].enable();
    }
    this.prizesService.initSearchPrizesListener();
    if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_COMPETITION && this.users?.data?.competitions?.length > 0 && this.users?.data?.competitions[0]?.company?.id) {
      this.prizesService.setCompetitionFilter(this.users?.data?.competitions[0]?.id)
      this.filtered = this.users?.data?.competitions[0]?.company?.name;
    } else if (this.single && this.users && this.users?.audience === AudienceEnum.ALL_USERS_IN_COMPANY && this.users?.data?.companies?.length > 0) {
      this.prizesService.setCompanyFilter(this.users?.data?.companies[0]?.id)
      this.filtered = this.users?.data?.companies[0]?.name;
    } else {
      this.prizesService.pageSize = 1000;
    }
    this.list$ = this.prizesService.prizeList$;
    if (!!this.editDetail && this.editDetail?.id) {
      this.selected = this.editDetail;
    } else if (!!this.editDetail) {
      this.subscription = this.list$.subscribe((data: any) => {
        if (this.editDetail?.id) {

        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const audience: AudienceEnum = changes?.selectedAudience?.currentValue;
    this.selected = changes?.selectedItems?.currentValue;
  }

  public selectCompanies(event: any): void {
    this.selectedPrize.emit(event);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.selectedItems = null;
    this.selected = null;
    this.prizesService.removeSearchPrizesSubscribe();
  }
}
