import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-user-list-filters',
  templateUrl: './user-list-filters.component.html',
  styleUrls: ['./user-list-filters.component.scss']
})
export class UserListFiltersComponent implements OnInit {
  @Input() service: any;
  @Input() searchFilters: any;
  @Input() filter: any;
  @Input() checkIfFilterIsVisible: any;
  @Input() clearFilters: any;
  @Input() getValue: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
