import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UsersApiService} from "@service/users/users-api.service";
import {Subject, takeUntil} from "rxjs";
import {TopbarTypeEnum} from "@enum/topbar/topbar-type.enum";
import {UtilsService} from "@service/utils/utils.service";

@Component({
  selector: 'app-fitness-slots',
  templateUrl: './fitness-slots.component.html',
  styleUrls: ['./fitness-slots.component.scss']
})
export class FitnessSlotsComponent implements OnInit, OnDestroy {
  @Input() userId;
  public type: TopbarTypeEnum = TopbarTypeEnum.FITNESS_SLOTS;
  public date: undefined | Date;
  public slots: any[] | undefined;
  public slotDetail: any[] | undefined;
  private destroy$: any = new Subject();

  constructor(private _userApiService: UsersApiService, private _utilsService: UtilsService) {
  }

  private static get actions(): any[] {
    return [
      {
        clickAction: 'detail',
        class: ['pointer', 'font-size-18'],
        tooltip: 'View',
        placement: 'bottom',
        icon: 'bx bx-search'
      }
    ]
  }

  public get columns(): any[] {
    return [
      {
        id: 1,
        canHide: true,
        visible: true,
        text: true,
        label: 'DATE',
        align: 'center',
        class: ['pointer'],
        elementKey: 'slotStart',
        sortable: true,
        sortKey: 'slotStart',
        dateTime: true
      },
      {
        id: 2,
        canHide: true,
        visible: true,
        text: true,
        label: 'STEPS',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'steps',
        sortable: true,
        sortKey: 'steps'
      },
      {
        id: 3,
        canHide: true,
        visible: true,
        text: true,
        label: 'BURNED_KCAL',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'burnedKiloCalories',
        sortable: true,
        sortKey: 'burnedKiloCalories'
      },
      {
        id: 4,
        canHide: true,
        visible: true,
        text: true,
        label: 'WeightRecorded',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'weightKg',
        sortable: true,
        sortKey: 'weightKg'
      },
      {
        id: 5,
        canHide: true,
        visible: true,
        text: true,
        label: 'HeightRecorded',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'heightCentimeters',
        sortable: true,
        sortKey: 'heightCentimeters'
      },
      {
        id: 6,
        canHide: true,
        visible: true,
        text: true,
        label: 'BMI',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'bmi',
        sortable: true,
        sortKey: 'bmi'
      },
      {
        id: 7,
        canHide: true,
        visible: true,
        text: true,
        label: 'BloodPressureRecorded',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'isBloodPressureRecorded',
        sortable: true,
        sortKey: 'isBloodPressureRecorded',
        boolean: true
      },
      {
        id: 8,
        canHide: true,
        visible: true,
        text: true,
        label: 'BloodGlucoseRecorded',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'isBloodGlucoseRecorded',
        sortable: true,
        sortKey: 'isBloodGlucoseRecorded',
        boolean: true
      },
      {
        id: 9,
        canHide: true,
        visible: true,
        text: true,
        label: 'TotalSleepMinutes',
        align: 'center',
        class: ['pointer', 'fit-width'],
        elementKey: 'bmi',
        sortable: true,
        sortKey: 'bmi'
      },
      {
        id: 16,
        canHide: false,
        visible: true,
        label: '',
        class: ['fit-width'],
        isRowAction: true,
        rowActions: FitnessSlotsComponent.actions
      },
    ]
  }

  ngOnInit(): void {
    this.date = new Date();
    this._loadFitnessSlots();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public action(event: any) {
    if (!event.methodName) return;
    if (this[event?.methodName]) {
      this[event?.methodName](event?.param);
    }
  }

  public detail(slot: any) {
  }

  public _loadFitnessSlots(): void {
    this._userApiService.getUserFitness(this._generateFilters()).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.slots = res;
    });
  }

  private _generateFilters(): any {
    return {
      userId: Number(this.userId),
      forDay: new Date(this.date).toISOString().slice(0, 10),
      page: 1,
      start: 0,
      limit: 25
    }
  }
}
