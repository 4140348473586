<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a class="logo logo-dark" routerLink="/">
          <span class="logo-sm">
            <img alt="" height="22" src="../../../../assets/images/logo/blue-virtuoso-icon.svg">
          </span>
          <span class="logo-lg">
            <img alt="" height="17" src="../../../../assets/images/logo/white-virtuoso-icon.svg">
          </span>
        </a>
        <a class="logo logo-light" routerLink="/">
          <span class="logo-sm">
            <img alt="" height="22" src="../../../../assets/images/logo/white-virtuoso-icon.svg">
          </span>
          <span class="logo-lg">
            <img alt="" height="19" src="../../../../assets/images/logo/white-horizontal-logo.svg">
          </span>
        </a>
      </div>

      <button (click)="toggleMobileMenu($event)" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              type="button">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <a (click)="openUrl('https://app.sendgrid.com/login')" class="dropdown-icon-item ms-2"
         href="javascript: void(0);">
        <img alt="sendgird" src="assets/images/brands/sendgrid.png">
      </a>

      <a (click)="openUrl('https://crowdin.com/')" class="dropdown-icon-item ms-2" href="javascript: void(0);">
        <img alt="sendgird" src="assets/images/brands/crowdin.png">
      </a>

      <a (click)="openUrl('https://kumulos.app.delivery/')" class="dropdown-icon-item ms-2" href="javascript: void(0);">
        <img alt="sendgird" src="assets/images/brands/kumulos.png">
      </a>

      <a (click)="openUrl('https://app.slack.com/client/T90TN7NER/CKBE07G2Z')" class="dropdown-icon-item ms-2"
         href="javascript: void(0);">
        <img alt="sendgird" src="assets/images/brands/slack.png">
      </a>

      <a (click)="openUrl('https://app.clickup.com/4661473/v/l/4e871-603?pr=8737698')" class="dropdown-icon-item ms-2"
         href="javascript: void(0);">
        <img alt="sendgird" src="assets/images/brands/clickup-logo-gradient.png">
      </a>
    </div>
    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle type="button">
          <img *ngIf="flagvalue !== undefined" alt="Header Language" height="16" src="{{flagvalue}}"> <span
          class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" alt="Header Language" height="16" src="{{valueset}}">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <span (click)="setLanguage(item.text, item.lang, item.flag)" *ngFor="let item of listLang"
                [ngClass]="{'active': cookieValue === item.lang}"
                class="dropdown-item notify-item">
            <img alt="user-image" class="me-1" height="12" src="{{item.flag}}"> <span
            class="align-middle">{{item.text}}</span>
          </span>
        </div>
      </div>
      <div *ngIf="user$ | async as user" class="dropdown d-inline-block" ngbDropdown>
        <button class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle type="button">
          <img [src]="user?.profile?.picture" alt="Header Avatar"
               class="rounded-circle header-profile-user">
          <span class="d-none d-xl-inline-block ms-1">{{user?.profile?.nickname}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a (click)="copyToken()" class="dropdown-item" href="javascript: void(0);">
            {{ 'COPY_TOKEN' | translate}}</a>
          <a (click)="logout()" class="dropdown-item text-danger" href="javascript: void(0);"><i
            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
