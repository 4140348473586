import {Injectable} from '@angular/core';
import {HttpService} from "@service/http/http.service";
import {Observable} from "rxjs";
import {endpoints} from "@endpoint/endpoint-list";
import {EndpointParamEnum} from "@enum/endpoint-param.enum";

@Injectable({
  providedIn: 'root'
})
export class PrizesApiService {
  constructor(private http: HttpService) {
  }

  public getPrizeList(filters: any, companyCompetitionFilter?: any): Observable<any> {
    let url: any;
    if (companyCompetitionFilter?.companyId) {
      url = endpoints.prizes.company;
      filters = {...filters, companyId: companyCompetitionFilter?.companyId};
      filters.size = 1000;
    } else if (companyCompetitionFilter?.competitionId) {
      url = endpoints.prizes.competition;
      filters = {...filters, competitionId: companyCompetitionFilter?.competitionId};
      filters.size = 1000;
    } else {
      url = endpoints.prizes.list
    }
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }
	
	private convertDate(value: any) {
		if (!value) return null;
		if (value instanceof Date) {
			return value.toISOString().split('T')[0];
		}
		if (typeof value === 'string') {
			if (value.includes('/')) {
				return value.split('/').reverse().join('-');
			} else if (value.includes('T')) {
				return value.split('T')[0];
			}
		}
		return value;
	}
	
	private processDateParam(params, key) {
		const value: any = params?.[key];
		if (value && !(value instanceof Date) && !(typeof value === 'number' || value instanceof Number) && value.includes('/')) {
			params[key] = value.split("/")?.reverse()?.join("-");
		}
		if (value && (value instanceof String || typeof value === 'string') && value?.includes('T')) {
			params[key] = value?.split('T')[0];
		} else if (value && (typeof value === 'number' || value instanceof Number)) {
			const date = new Date(value as number);
			params[key] = date?.toISOString()?.split('T')[0];
		}
	}
	
	public createEditPrize(params: any): Observable<any> {
    if (params?.precondition?.length > 0) {
      params.preconditions = params?.precondition?.map((precondition: any) => {
        return precondition?.id;
      });
    }
    if (params?.expiresOn && !(params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn.split("/").reverse().join("-");
    } else if (params?.expiresOn && (params?.expiresOn instanceof Date)) {
      params.expiresAt = params?.expiresOn?.toISOString()?.split('T')[0];
    }
		this.processDateParam(params, 'expiresAt');
		this.processDateParam(params, 'startVisibility');
		this.processDateParam(params, 'endVisibility');
		if (!!params?.leaderboardPosition && (!params?.fromPositionInclusive || !params?.toPositionInclusive)) {
      params.fromPositionInclusive = params?.leaderboardPosition?.fromPositionInclusive;
      params.toPositionInclusive = params?.leaderboardPosition?.toPositionInclusive;
    }
    params.createdAt = null;
    let companyId: any = params?.company?.id ? params?.company?.id : params?.companyId ? params?.companyId : undefined;
    let competitionId: any = params?.competition?.id ? params?.competition?.id : params?.competitionId ? params?.competitionId : undefined;
    if (companyId && !competitionId) {
      if (!params?.id) {
        const placeholders = [{placeholder: EndpointParamEnum.ID, value: companyId}];
        const url = this.http.findAndReplacePlaceholders(endpoints.prizes.createForCompany, placeholders);
        return this.http.post(url, params);
      } else {
        const placeholders = [{
          placeholder: EndpointParamEnum.ID,
          value: companyId
        }, {placeholder: EndpointParamEnum.PID, value: params?.id}];
        const url = this.http.findAndReplacePlaceholders(endpoints.prizes.editForCompany, placeholders);
        return this.http.put(url, params);
      }
    } else if (competitionId) {
      if (!params?.id) {
        const placeholders = [{placeholder: EndpointParamEnum.ID, value: competitionId}];
        const url = this.http.findAndReplacePlaceholders(endpoints.prizes.createForCompetition, placeholders);
        return this.http.post(url, params);
      } else {
        const placeholders = [{
          placeholder: EndpointParamEnum.ID,
          value: competitionId
        }, {placeholder: EndpointParamEnum.PID, value: params?.id}];
        const url = this.http.findAndReplacePlaceholders(endpoints.prizes.editForCompetition, placeholders);
        return this.http.put(url, params);
      }
    } else if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.edit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.prizes.create, params);
    }
  }

  public addPrizeCode(params: any): Observable<any> {
    if (params?.id) {
      const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
      const url = this.http.findAndReplacePlaceholders(endpoints.prizes.codesEdit, placeholders);
      return this.http.put(url, params);
    } else {
      return this.http.post(endpoints.prizes.codes, params);
    }
  }

  public addEditPartner(params: any): Observable<any> {
    return this.http.put(endpoints.prizes.partners.create, params);
  }

  public deletePrizeCode(params: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: params?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.deletePrizeCode, placeholders);
    return this.http.delete(url, params);
  }

  public getPrizeData(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detail, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public usersWhoRedeemed(filters: any, pagination: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.usersWhoRedeemed, placeholders);
    const params = this.http.generateQueryParams(pagination);
    return this.http.get<any>(url, params);
  }

  public getPrizeForCompetition(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detailForCompetition, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public getPrizeForCompany(filters: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: filters?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.detailForCompany, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params);
  }

  public deletePrize(prize: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: prize?.id}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.delete, placeholders);
    return this.http.delete(url, {});
  }

  public getPrizeCodes(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.codes, params ? params : null);
  }

  public getPrizePreconditions(filters: any): Observable<any> {
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(endpoints.prizes.preconditions, params ? params : null);
  }

  public getUserRedeemedPrizes(userId: string, filters?: any): Observable<any> {
    const placeholders = [{placeholder: EndpointParamEnum.ID, value: String(userId)}];
    const url = this.http.findAndReplacePlaceholders(endpoints.prizes.forUser, placeholders);
    const params = this.http.generateQueryParams(filters);
    return this.http.get<any>(url, params ? params : null);
  }
}
