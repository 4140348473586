<div class="row mb-3">
  <div class="col-xl-9">
    <h5 class="card-title mb-2">{{'SELECT_AUDIENCE' | translate}}</h5>
    <p class="">{{'SELECT_AUDIENCE_DESC' | translate}}</p>
  </div>
</div>
<app-select-audience (selectedAction)="updateSelectedAction($event)" [massiveNotification]="true"
                     [selectedAudience]="selectedAudience"></app-select-audience>
<app-select-company (selectedCompany)="updateSelectedCompany($event)" *ngIf="selectedAudience === audienceEnum.ALL_USERS_IN_COMPANY"
                    [error]="audienceErrors.includes(audienceEnum.ALL_USERS_IN_COMPANY)" [selectedAudience]="selectedAudience"
                    [selectedItems]="selectedAudienceData.companies"></app-select-company>
<app-select-competition (selectedCompetition)="updateSelectedCompetition($event)" *ngIf="selectedAudience === audienceEnum.ALL_USERS_IN_COMPETITION"
                        [error]="audienceErrors.includes(audienceEnum.ALL_USERS_IN_COMPETITION)" [selectedAudience]="selectedAudience"
                        [selectedItems]="selectedAudienceData.competitions"></app-select-competition>
<app-select-challenge (selectedChallenge)="updateSelectedChallenge($event)" *ngIf="selectedAudience === audienceEnum.ALL_USERS_IN_CHALLENGE"
                      [error]="audienceErrors.includes(audienceEnum.ALL_USERS_IN_CHALLENGE)" [selectedAudience]="selectedAudience"
                      [selectedItems]="selectedAudienceData.challenges"></app-select-challenge>
<ul class="list-inline wizard mb-0">
  <li class="next list-inline-item float-end">
    <button (click)="confirmSelection()" *ngIf="massiveAction" class="btn btn-primary"
            type="submit">{{'CONTINUE' | translate}}</button>
    <button (click)="confirmSelection()" *ngIf="!massiveAction" awNextStep class="btn btn-primary"
            type="submit">{{'CONTINUE' | translate}}</button>
  </li>
</ul>
