<ng-template #modal>
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title" id="modal-title-2">{{'EXPORT_RESULTS' | translate}}
      for {{type | translate | lowercase}}</h5>
    <button [disabled]="(working)" class="btn close-modal-button" closeModal="CLOSE_EXPORT_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
  <div *ngIf="service" class="modal-body">
    <p class="modal-description">{{'EXPORT_DESCRIPTION' | translate}}</p>
    <div *ngIf="(remainingTime || progress) && progress < 100" class="card-body custom-padding-card">
      <h4 class="card-title">Estimated remaining time {{remainingTime}}</h4>
      <p *ngIf="totalExecutionTime" class="card-title-desc">The total execution time is  {{ totalExecutionTime}}</p>
      <div class="">
        <ngb-progressbar [value]="progress" height="20px" showValue="true"></ngb-progressbar>
      </div>
    </div>
    <div *ngIf="progress === 100" class="card-body custom-padding-card">
      <div>
        <ngb-alert [dismissible]="false" type="success">
          Export completed successfully
        </ngb-alert>
      </div>
    </div>
    <ul class="list-inline wizard mt-4 mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="showAreYouSure = true" *ngIf="!showAreYouSure" class="btn btn-success">Export</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button (click)="export()" *ngIf="showAreYouSure" [disabled]="(working)" class="btn btn-success me-2"
                type="submit">
          <i *ngIf="working" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="working">
            Exporting
          </span>
          <span [hidden]="(working)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(working)"
                class="btn btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>
