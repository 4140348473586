<div class="operation-modal-header">
  <h5 *ngIf="!project">{{'CREATE_SUSTAINABILITY_PROJECT' | translate}}</h5>
  <h5 *ngIf="project && !project?.duplicate">{{'EDIT_SUSTAINABILITY_PROJECT' | translate}}: {{project?.title}}</h5>
  <h5 *ngIf="project && project?.duplicate">{{'DUPLICATE_SUSTAINABILITY_PROJECT' | translate}}: {{project?.title}}</h5>
  <div class="d-flex">
    <button (click)="utils.openLink(help)" class="btn close-modal-button close-modal-button-mr-2">
      <i class="mdi mdi-information-outline"></i>
    </button>
    <button [disabled]="(adding$ | async)" class="btn close-modal-button" closeModal="CLOSE_SUSTAINABILITY_PROJECT_MODAL">
      <i class="mdi mdi-close-circle-outline"></i>
    </button>
  </div>
</div>
<div class="common-modal-description">
  <p class="mb-0">{{'projectModalDescription' | translate}}</p>
</div>
<div class="modal-body">
  <form (ngSubmit)="validSubmit()" [autocomplete]="'off'" [formGroup]="projectForm" autocomplete="off" class="needs-validation">
    <div class="row mb-3">
      <div class="col-md-2">
        <ul ngbNav #verticalNav="ngbNav" [activeId]="activeId" class="nav-pills flex-column">
          <li [ngbNavItem]="1" (click)="activeId = 1">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block danger me-2" *ngIf="checkAlert('PROJECT')">
                  <i class="mdi mdi-alert-circle-outline"></i>
              </span>
              <span class="d-inline-block">Project</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-0" for="title">{{'TITLE' | translate}} <span class="text-danger" *ngIf="form?.title?.errors?.required">*</span></label>
                    <p class="card-title-desc mb-2">Specify the title of the project. It should have at least 5 characters and max 100.</p>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.title?.errors}" placeholder="Let's reforest Madagascar" class="form-control"
                           formControlName="title" id="title" type="text">
                    <div *ngIf="formSubmitted && form?.title?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.title?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.title?.errors?.minlength?.requiredLength} }}.</span>

                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-0" for="shortDescription">{{'SHORT_DESCRIPTION' | translate}} <span class="text-danger" *ngIf="form?.shortDescription?.errors?.required">*</span></label>
                    <p class="card-title-desc mb-2">Specify the short description of the project. It should have at least 5 characters and max 100.</p>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.shortDescription?.errors}" placeholder="Snam and its employees aim to reforest Madagascar through this project" class="form-control"
                           formControlName="shortDescription" id="shortDescription" type="text">
                    <div *ngIf="formSubmitted && form?.shortDescription?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.shortDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.shortDescription?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.shortDescription?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.shortDescription?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.shortDescription?.errors?.minlength?.requiredLength} }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-0" for="longDescription">{{'LONG_DESCRIPTION' | translate}} <span class="text-danger" *ngIf="form?.longDescription?.errors?.required">*</span></label>
                    <p class="card-title-desc mb-2">Specify the description of the project. It will be used on the detail page. It should have at least 5 characters and max 255.</p>
                    <textarea  [ngClass]="{'is-invalid': formSubmitted && form?.longDescription?.errors}" placeholder="This project will be realized to sustain the..." class="form-control description-textarea" formControlName="longDescription" id="longDescription" rows="3"></textarea>
                    <div *ngIf="formSubmitted && form?.longDescription?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.longDescription?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.longDescription?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.longDescription?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.longDescription?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.longDescription?.errors?.minlength?.requiredLength} }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-0" for="message">{{'MESSAGE' | translate}}  <span class="text-danger" *ngIf="form?.message?.errors?.required">*</span></label>
                    <p class="card-title-desc mb-2">Specify the main message of the project. It should have at least 5 characters and max 100.</p>
                    <input [ngClass]="{'is-invalid': formSubmitted && form?.message?.errors}" placeholder="We are going strong! Let's keep going 🥳" class="form-control"
                           formControlName="message" id="message" type="text">
                    <div *ngIf="formSubmitted && form?.message?.errors" class="invalid-feedback d-block">
                      <span *ngIf="formSubmitted && form?.message?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                      <span *ngIf="form?.message?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: form?.message?.errors?.maxlength?.requiredLength} }}</span>
                      <span *ngIf="form?.message?.errors?.minlength">{{ 'errorMinLength' | translate: {length: form?.message?.errors?.minlength?.requiredLength} }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <app-image-uploader [previousSelectedImage]="checkSelectedImage('cardImage')"
                                        [limit]="cardImageLimit"
                                        [name]="'CARD_IMAGE'"
                                        [imageErrors]="checkImageErrors('cardImage')"
                                        [showImagePreview]="true"
                                        (imageUploaded)="setImage($event, 'cardImage')"></app-image-uploader>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <app-image-uploader [previousSelectedImage]="checkSelectedImage('backgroundImage')"
                                        [name]="'BACKGROUND_IMAGE'"
                                        [limit]="backgroundImageLimit"
                                        [imageErrors]="checkImageErrors('backgroundImage')"
                                        [showImagePreview]="true"
                                        (imageUploaded)="setImage($event, 'backgroundImage')"></app-image-uploader>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" (click)="activeId = 2">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block danger me-2" *ngIf="checkAlert('CONFIGURATION')">
                  <i class="mdi mdi-alert-circle-outline"></i>
              </span>
              <span class="d-inline-block">Configuration</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-6">
                  <label class="font-weight-bold">{{'SELECT_COMPANY' | translate}}</label>
                  <app-company-select (selectedCompany)="form['company'].setValue($event);"
                                      [error]="formSubmitted && form?.company?.errors?.required"
                                      [selected]="this.form?.company?.value"></app-company-select>
                </div>
                <div class="col-6">
                  <app-select-locale [parentForm]="projectForm"></app-select-locale>
                </div>
                <div class="col-6 mb-3">
                  <label class="font-weight-bold mb-0" for="privateDeepLink">{{'PRIVATE_DEEPLINK' | translate}}  <span class="text-danger" *ngIf="form?.privateDeepLink?.errors?.required">*</span></label>
                  <p class="card-title-desc mb-2">Deeplink used within the corporate WEB private section</p>
                  <input [ngClass]="{'is-invalid': formSubmitted && form?.privateDeepLink?.errors}" [placeholder]="'https://healthyvirtuosodev.page.link/rUypbsa4ATudXPeE8'" class="form-control"
                         formControlName="privateDeepLink" id="privateDeepLink" type="text">
                  <div *ngIf="formSubmitted && form?.privateDeepLink?.errors" class="invalid-feedback">
                    <span *ngIf="formSubmitted && form?.privateDeepLink?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    <span *ngIf="formSubmitted && form?.privateDeepLink?.errors?.pattern">Please insert a valid url</span>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <label class="font-weight-bold mb-0" for="publicDeepLink">{{'PUBLIC_DEEPLINK' | translate}}  <span class="text-danger" *ngIf="form?.publicDeepLink?.errors?.required">*</span></label>
                  <p class="card-title-desc mb-2">Deeplink used within the public WEB section</p>
                  <input [ngClass]="{'is-invalid': formSubmitted && form?.publicDeepLink?.errors}" [placeholder]="'https://healthyvirtuosodev.page.link/ZTvVL4EK3Li9mAcF9'" class="form-control"
                         formControlName="publicDeepLink" id="publicDeepLink" type="text">
                  <div *ngIf="formSubmitted && form?.publicDeepLink?.errors" class="invalid-feedback">
                    <span *ngIf="formSubmitted && form?.publicDeepLink?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                    <span *ngIf="formSubmitted && form?.publicDeepLink?.errors?.pattern">Please insert a valid url</span>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <label class="font-weight-bold mb-1" for="startDate">{{'START_DATE' | translate}}  <span class="text-danger" *ngIf="form?.startDate?.errors?.required">*</span></label>
                  <p class="card-title-desc mb-2">Date used to start counting project results</p>
                  <app-date-time-picker [datetime]="form?.startDate?.value" [hour]="form?.startDateHour?.value" [invalid]="formSubmitted && form?.startDate?.errors" (dateSelected)="updateDate($event, 'startDate')"></app-date-time-picker>
                  <div *ngIf="formSubmitted && form?.startDate?.errors" class="invalid-feedback d-block">
                    <span *ngIf="formSubmitted && form?.startDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <label class="font-weight-bold mb-1" for="endDate">{{'END_DATE' | translate}}  <span class="text-danger" *ngIf="form?.endDate?.errors?.required">*</span></label>
                  <p class="card-title-desc mb-2">Date used to finish counting project results</p>
                  <app-date-time-picker [datetime]="form?.endDate?.value" [hour]="form?.endDateHour?.value" [invalid]="formSubmitted && form?.endDate?.errors" (dateSelected)="updateDate($event, 'endDate')"></app-date-time-picker>
                  <div *ngIf="formSubmitted && form?.endDate?.errors" class="invalid-feedback d-block">
                    <span *ngIf="formSubmitted && form?.endDate?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <label class="font-weight-bold mb-0" for="partnerId">{{'PARTNER' | translate}}</label>
                  <p class="card-title-desc mb-2">Define a the company who is promoting this project</p>
                  <select  class="form-select" formControlName="partnerId" id="partnerId">
                    <option *ngFor="let partner of partners" [value]="partner?.id">{{partner?.title | translate}}</option>
                  </select>
                </div>
                <div class="col-6 mb-3">
                  <div class="single-ng-select">
                    <label class="font-weight-bold mb-0" for="placeId">{{'PLACE' | translate}}</label>
                    <p class="card-title-desc mb-2">Define a the place were the project will be done</p>
                    <ng-select [clearable]="true" [items]="places"  bindLabel="name" id="placeId"
                               bindValue="id" [multiple]="false" formControlName="placeId">
                      <ng-template let-item="item" ng-label-tmp>
                        <span>{{ item?.name }}</span>
                      </ng-template>
                      <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                        <span class="font-weight-semibold ms-2">{{item?.name}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="font-weight-bold" for="showLeaderboard">Show leaderboards</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.showLeaderboard?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="showLeaderboard"
                             id="showLeaderboard" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="showLeaderboard">
                              <span *ngIf="form?.showLeaderboard?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.showLeaderboard?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="font-weight-bold mb-0" for="active">Active</label>
                    <div class="form-check form-check-custom form-check-custom-big align-middle p-0">
                      <input [checked]="form?.active?.value" autocomplete="off" class="form-check-input m-0 pointer"
                             formControlName="active"
                             id="active" type="checkbox">
                      <label class="form-check-label font-size-11 ms-3" for="active">
                              <span *ngIf="form?.active?.value"
                                    class="badge badge-pill badge-soft-success font-size-11">Yes</span>
                        <span *ngIf="!form?.active?.value"
                              class="badge badge-pill badge-soft-danger font-size-11">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" (click)="activeId = 3">
            <a ngbNavLink class="mb-2">
              <span class="d-inline-block danger me-2" *ngIf="checkAlert('OBJECTIVES')">
                  <i class="mdi mdi-alert-circle-outline"></i>
              </span>
              <span class="d-inline-block">Objectives</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex objective-header">
                    <label class="font-weight-bold">{{'OBJECTIVES' | translate}}</label>
                    <span class="btn btn-primary" (click)="addObjective()">Add an objective</span>
                  </div>
                  <div formArrayName="objectives">
                    <div class="row max-height-50">
                      <div class="col-12 " *ngFor="let group of objectives.controls; let i = index; let first = first;" [formGroupName]="i">
                        <div class="step objective-section">
                          <div class="row">
                            <div class="col-8 mb-3">
                              <p class="font-weight-bold mb-0">#{{i+1}} Objective</p>
                            </div>
                            <div class="col-4 mb-3 d-flex justify-content-end" *ngIf="!first">
                              <button (click)="removeObjective(i)" class="btn close-modal-button">
                                <i class="mdi mdi-close-circle-outline"></i>
                              </button>
                            </div>
                            <div class="mb-3 col-7">
                              <label class="font-weight-bold mb-0" for="title">{{'TITLE' | translate}}  <span class="text-danger" *ngIf="group?.controls?.title?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">The title of the objective. Remember to use a descriptive title for on going objectives. Instead specify the target for the on reach ones</p>
                              <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.title?.errors}" placeholder="Plant a lot of trees" class="form-control" formControlName="title" id="title" type="text">
                              <div *ngIf="formSubmitted && group?.controls?.title?.errors" class="invalid-feedback d-block">
                                <span *ngIf="group?.controls?.title?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                <span *ngIf="group?.controls?.title?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: group?.controls?.title?.errors?.maxlength?.requiredLength} }}</span>
                                <span *ngIf="group?.controls?.title?.errors?.minlength">{{ 'errorMinLength' | translate: {length: group?.controls?.title?.errors?.minlength?.requiredLength} }}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-5">
                              <label class="font-weight-bold mb-0" for="metric">{{'FITNESS_METRIC' | translate}} <span class="text-danger" *ngIf="group.controls?.metric?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">The fitness metric the objective. Il will be used to calculate the results</p>
                              <select [ngClass]="{'is-invalid': formSubmitted && group?.controls?.metric?.errors}" placeholder="Steps" class="form-select" formControlName="metric" id="metric">
                                <option *ngFor="let metric of metrics" [value]="metric">{{metric | translate}}</option>
                              </select>
                              <div *ngIf="formSubmitted && group.controls?.metric?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.metric?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-12">
                              <label class="font-weight-bold mb-0" for="description">{{'DESCRIPTION' | translate}} <span class="text-danger" *ngIf="group.controls?.description?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">Describe the main purpose of this objective.</p>
                              <textarea [ngClass]="{'is-invalid': formSubmitted && group?.controls?.description?.errors}" placeholder="With this objective we are trying to plant at least 10000 trees in madagascar becasue.. " class="form-control description-textarea" formControlName="description" id="description" rows="1"></textarea>
                              <div *ngIf="formSubmitted && group.controls?.description?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.description?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                                <span *ngIf="group?.controls?.description?.errors?.maxlength">{{ 'errorMaxLength' | translate: {length: group?.controls?.description?.errors?.maxlength?.requiredLength} }}</span>
                                <span *ngIf="group?.controls?.description?.errors?.minlength">{{ 'errorMinLength' | translate: {length: group?.controls?.description?.errors?.minlength?.requiredLength} }}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-4">
                              <label class="font-weight-bold mb-0" for="rule">{{'RULE' | translate}} <span class="text-danger" *ngIf="group.controls?.rule?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">Define the rule of the objective.</p>
                              <select (change)="checkRestrictionValidators($event, group)" [ngClass]="{'is-invalid': formSubmitted && group?.controls?.rule?.errors}" class="form-select" formControlName="rule" id="rule">
                                <option *ngFor="let rule of rules" [value]="rule">{{rule | translate}}</option>
                              </select>
                              <div *ngIf="formSubmitted && group.controls?.rule?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.rule?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-4" *ngIf="group.controls.rule.value === 'ON_REACH'">
                              <label class="font-weight-bold mb-0" for="target">{{'TARGET' | translate}} <span class="text-danger" *ngIf="group.controls?.target?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">It will match with the fitness metric result</p>
                              <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.target?.errors}" placeholder="1000000" class="form-control" formControlName="target" id="target" type="number">
                              <div *ngIf="formSubmitted && group.controls?.target?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.target?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-4" *ngIf="group.controls.rule.value === 'ON_GOING'">
                              <label class="font-weight-bold mb-0" for="multiplier">{{'MULTIPLIER' | translate}} <span class="text-danger" *ngIf="group.controls?.multiplier?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">Define a the multiplier of the objective</p>
                              <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.multiplier?.errors}" placeholder="0.001"  class="form-control" formControlName="multiplier" id="multiplier" type="number">
                              <div *ngIf="formSubmitted && group.controls?.multiplier?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.multiplier?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="mb-3 col-4" *ngIf="group.controls.rule.value === 'ON_GOING'">
                              <label class="font-weight-bold mb-0" for="multiplierText">{{'MULTIPLIER_TEXT' | translate}} <span class="text-danger" *ngIf="group.controls?.multiplierText?.errors?.required">*</span></label>
                              <p class="card-title-desc mb-2">Rember to use {{'{0}'}} parameter</p>
                              <input [ngClass]="{'is-invalid': formSubmitted && group?.controls?.multiplierText?.errors}" placeholder="{0} Alberi piantati" class="form-control" formControlName="multiplierText" id="multiplierText" type="text">
                              <div *ngIf="formSubmitted && group.controls?.multiplierText?.errors" class="invalid-feedback d-block">
                                <span *ngIf="formSubmitted && group.controls?.multiplierText?.errors?.required">{{'VALUE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                            <div class="col-4 mb-3" [class.col-12]="group.controls.rule.value === 'ON_GOING'">
                              <div class="single-ng-select">
                                <label class="font-weight-bold mb-0">Benefits</label>
                                <p class="card-title-desc mb-2">Define a the benefits of the objective</p>
                                <ng-select [clearable]="true" [items]="benefits"  bindLabel="title"
                                           bindValue="id" [multiple]="true" formControlName="benefits">
                                  <ng-template let-item="item" ng-label-tmp>
                                    <span>{{ item?.title }}</span>
                                  </ng-template>
                                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <span class="font-weight-semibold ms-2">{{item?.title}}</span>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-12 mb-3">
                              <app-image-uploader [previousSelectedImage]="checkObjectiveSelectedImage(group, 'image')"
                                                  [limit]="objectiveImageLimit"
                                                  [name]="'IMAGE'"
                                                  [imageErrors]="checkObjectiveImageErrors(group, 'image')"
                                                  [showImagePreview]="true"
                                                  (imageUploaded)="setObjectiveImage($event, 'image', group)"></app-image-uploader>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-md-10">
        <div [ngbNavOutlet]="verticalNav"></div>
      </div>
    </div>
    <ul class="list-inline wizard mb-0">
      <li class="next list-inline-item float-end">
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && !project " class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && project && project?.duplicate" class="btn mb-3 btn-warning">Create</button>
        <button (click)="checkNextActionBtn()" *ngIf="!showAreYouSure && project && !project?.duplicate" class="btn mb-3 btn-warning">Edit</button>
        <span *ngIf="showAreYouSure" class="me-3 font-weight-bold">{{'ARE_YOU_SURE' | translate}} </span>
        <button *ngIf="showAreYouSure" [disabled]="(adding$ | async)" class="btn mb-3 btn-warning me-2" type="submit">
          <i *ngIf="adding$ | async" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="(adding$ | async) && !project">{{'CREATING' | translate}}</span>
          <span *ngIf="(adding$ | async) && project && !project?.duplicate">{{'EDITING' | translate}}</span>
          <span *ngIf="(adding$ | async) && project && project?.duplicate">{{'DUPLICATING' | translate}}</span>
          <span [hidden]="(adding$ | async)">
            {{'YES' | translate}}
          </span>
        </button>
        <button (click)="showAreYouSure = false" *ngIf="showAreYouSure" [disabled]="(adding$ | async)"
                class="btn mb-3 btn-outline-light ">{{'NO' | translate}}
        </button>
      </li>
    </ul>
  </form>
</div>
