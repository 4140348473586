<ng-select (change)="selectFaq($event)"
           [clearable]="false"
           [items]="faqs"
           [ngClass]="{'is-invalid-select': formSubmitted && form?.actionDetail?.errors?.required}">
  <ng-template let-item="item" ng-label-tmp>
    <span class="font-weight-semibold ms-2"><span
      class="badge badge-pill badge-soft-primary font-size-11">{{item?.id}}</span> - {{ item?.title | translate }}</span>
  </ng-template>
  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
    <span class="font-weight-semibold ms-2"><span
      class="badge badge-pill badge-soft-primary font-size-11">{{item?.id}}</span> - {{ item?.title | translate }}</span>
  </ng-template>
</ng-select>
<div *ngIf="formSubmitted && form?.actionDetail?.errors?.required" class="invalid-feedback d-block">
  <span>{{'VALUE_REQUIRED' | translate}}</span>
</div>
