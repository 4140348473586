import {Injectable} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {HttpService} from "@service/http/http.service";
import {endpoints} from "@endpoint/endpoint-list";
import {BehaviorSubject, Observable} from "rxjs";
import {UserElementInterface} from "@interface/user/user-element.interface";

@Injectable({providedIn: 'root'})

export class AuthenticationService {
  private user$ = new BehaviorSubject<UserElementInterface>(null);

  constructor(private keycloak: KeycloakService, private http: HttpService) {
  }

  get currentUser$() {
    return this.user$.asObservable();
  }

  public updateCurrentUser(): any {
    this.getCurrentUser().subscribe((user: UserElementInterface) => {
      this.user$.next(user);
    });
  }

  public logout() {
    this.keycloak.logout();
  }

  private getCurrentUser(): Observable<any> {
    return this.http.get<any>(endpoints.user.currentUser, null);
  }
}

