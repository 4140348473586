import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AudienceEnum} from "@enum/audience/audience.enum";

@Component({
  selector: 'app-notification-audience',
  templateUrl: './notification-audience.component.html',
  styleUrls: ['./notification-audience.component.scss']
})
export class NotificationAudienceComponent implements OnInit, AfterViewInit {
  @Output() selectedNotificationAudience = new EventEmitter();
  @Input() notificationUsers: any;
  @Input() massiveAction: any;
  public audienceEnum = AudienceEnum;
  public selectedAudience: AudienceEnum = AudienceEnum.ALL_USERS;
  public selectedAudienceData: { companies: any[]; challenges: any[]; competitions: any[] } = {
    companies: [],
    challenges: [],
    competitions: []
  }
  public audienceErrors: any[] = [];

  constructor(private cdRef : ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.notificationUsers) {
      this.selectedAudience = this.notificationUsers?.audience;
      this.selectedAudienceData = this.notificationUsers?.data;
      if (this.notificationUsers?.data?.destinationIds) {
        this.confirmSelection(this.notificationUsers?.data?.destinationIds)
      }
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  public confirmSelection(selectIds?: number[]): void {
    this.audienceErrors = [];
    switch (this.selectedAudience) {
      case AudienceEnum.ALL_USERS_IN_COMPETITION:
        if (selectIds?.length > 0) {
          this.selectedAudienceData.competitions = selectIds;
        }
        if (this.selectedAudienceData.competitions?.length === 0) {
          this.audienceErrors.push(AudienceEnum.ALL_USERS_IN_COMPETITION);
        }
        break;
      case AudienceEnum.ALL_USERS_IN_COMPANY:
        if (selectIds?.length > 0) {
          this.selectedAudienceData.companies = selectIds;
        }
        if (this.selectedAudienceData.companies?.length === 0) {
          this.audienceErrors.push(AudienceEnum.ALL_USERS_IN_COMPANY);
        }
        break;
      case AudienceEnum.ALL_USERS_IN_CHALLENGE:
        if (selectIds?.length > 0) {
          this.selectedAudienceData.challenges = selectIds;
        }
        if (this.selectedAudienceData.challenges?.length === 0) {
          this.audienceErrors.push(AudienceEnum.ALL_USERS_IN_CHALLENGE);
        }
        break;
    }
    if (this.audienceErrors?.length === 0) {
      this.selectedNotificationAudience.emit({audience: this.selectedAudience, data: this.selectedAudienceData})
    }
  }

  public updateSelectedAction(event: AudienceEnum): void {
    this.selectedAudience = event;
    if (event === AudienceEnum.ALL_USERS) {
      this.resetSelectedAudienceData();
    }
  }

  public updateSelectedCompany(event: any): void {
    if (event?.length > 0) {
      this.selectedAudience = AudienceEnum.ALL_USERS_IN_COMPANY;
      this.selectedAudienceData.companies = event;
      this.selectedAudienceData.competitions = [];
      this.selectedAudienceData.challenges = [];
    } else {
      this.selectedAudienceData.companies = [];
    }
    this.checkSelectedAudienceData();
  }

  public updateSelectedCompetition(event: any): void {
    if (event?.length > 0) {
      this.selectedAudience = AudienceEnum.ALL_USERS_IN_COMPETITION;
      this.selectedAudienceData.competitions = event;
      this.selectedAudienceData.companies = [];
      this.selectedAudienceData.challenges = [];
    } else {
      this.selectedAudienceData.competitions = [];
    }
    this.checkSelectedAudienceData();
  }

  public updateSelectedChallenge(event: any): void {
    if (event?.length > 0) {
      this.selectedAudience = AudienceEnum.ALL_USERS_IN_CHALLENGE;
      this.selectedAudienceData.challenges = event;
      this.selectedAudienceData.competitions = [];
      this.selectedAudienceData.companies = [];
    } else {
      this.selectedAudienceData.challenges = [];
    }
    this.checkSelectedAudienceData();
  }

  private resetSelectedAudienceData() {
    this.selectedAudienceData.companies = [];
    this.selectedAudienceData.competitions = [];
    this.selectedAudienceData.challenges = [];
  }

  private checkSelectedAudienceData(): void {
    this.audienceErrors = [];
    if (this.selectedAudienceData?.companies?.length === 0 && this.selectedAudienceData?.competitions?.length === 0 && this.selectedAudienceData?.challenges?.length === 0) {
      this.selectedAudience = AudienceEnum.ALL_USERS
    }
  }

}
