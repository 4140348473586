<table *ngIf="prizes?.length > 0" class="table table-bordered align-middle table-hover table-striped table-nowrap mb-0">
  <thead>
  <tr>
    <th>
      <div (click)="selectAllResults()" class="form-check form-check-custom font-size-16 align-middle p-0">
        <input [checked]="allSelected" class="form-check-input m-0" id="userSelection01" type="checkbox">
      </div>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_ID' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_IMAGE' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_TITLE' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_TYPE' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'CREDITS' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_EXPIRATION_DATE' | translate}}</span>
    </th>
    <th>
      <span class="me-3">{{'PRIZE_ACTIVE' | translate}}</span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let prize of prizes; let i=index;" class="pointer">
    <td class="fit-width">
      <div class="form-check form-check-custom font-size-16 p-0 m-0">
        <input (click)="prize.checked = !prize.checked; extractSelectedItems()" [checked]="prize.checked"
               [value]="prize.checked" class="form-check-input p-0 m-0"
               id="userSelection{{i}}"
               type="checkbox">
      </div>
    </td>
    <td class="fit-width">
      <span *ngIf="prize?.id" class="badge badge-pill badge-soft-primary">{{prize?.id}}</span>
      <app-missing-label *ngIf="!prize?.id"></app-missing-label>
    </td>
    <td class="fit-width">
      <img *ngIf="prize?.cardImageUrl" [src]="prize?.cardImageUrl" class="avatar-sm">
      <app-missing-label *ngIf="!prize?.cardImageUrl"></app-missing-label>
    </td>
    <td>
      <span *ngIf="prize?.title" ngbTooltip="{{prize?.title}}" placement="right">{{prize?.title | truncate:[50]}}</span>
      <app-missing-label *ngIf="!prize?.title"></app-missing-label>
    </td>
    <td class="fit-width">
      <span *ngIf="prize?.prizeType"
            class="badge badge-pill badge-soft-dark">{{prize?.prizeType | translate | uppercase}}</span>
      <app-missing-label *ngIf="!prize?.prizeType"></app-missing-label>
    </td>
    <td class="fit-width">
      <span *ngIf="prize?.price">{{prize?.price}}</span>
      <app-missing-label *ngIf="!prize?.price"></app-missing-label>
    </td>
    <td class="fit-width">
      <span *ngIf="prize?.expiresAt">{{prize?.expiresAt | date:'dd/MM/yyyy'}}</span>
      <app-missing-label *ngIf="!prize?.expiresAt"></app-missing-label>
    </td>
    <td class="fit-width">
      <span *ngIf="prize?.active" class="badge badge-pill badge-soft-success">{{'YES' | translate}}</span>
      <span *ngIf="!prize?.active" class="badge badge-pill badge-soft-danger">{{'NO' | translate}}</span>
    </td>
  </tr>
  </tbody>
</table>
