import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {EventEnum} from "@enum/event/event.enum";
import {Observable, Subscription} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BanMotivationsService} from "@service/users/ban-motivations.service";

@Component({
  selector: 'app-ban-motivation-modal',
  templateUrl: './ban-motivation-modal.component.html',
  styleUrls: ['./ban-motivation-modal.component.scss']
})
export class BanMotivationModalComponent implements OnInit, OnDestroy {
  public formSubmitted: boolean;
  public motivationForm: UntypedFormGroup;
  public creating$: Observable<boolean>;
  public editingMotivation: any = undefined;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private closeModalSubscription: Subscription;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, private eventService: EventService, private formBuilder: UntypedFormBuilder,
              private banMotivationsService: BanMotivationsService) {
  }

  get controls() {
    return this.motivationForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.motivationForm = this.formBuilder.group({
      reason: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  public open(motivation?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve)
      this.setModalData(motivation);
    })
  }

  public submitForm() {
    this.formSubmitted = true;
    if (this.motivationForm.valid) {
      this.motivationForm.controls['reason'].disable();
      if (this.editingMotivation) {
        this.editingMotivation.reason = this.motivationForm.controls['reason'].value;
        this.banMotivationsService.editBanMotivation(this.editingMotivation);
      } else {
        this.banMotivationsService.createBanMotivation(this.motivationForm.controls['reason'].value);
      }
    }
  }

  ngOnDestroy() {
    this.closeModalSubscription?.unsubscribe();
  }

  private setModalData(motivation: any) {
    if (motivation && motivation.reason) {
      this.editingMotivation = motivation;
      this.motivationForm.controls['reason'].setValue(motivation.reason);
    } else {
      this.editingMotivation = null;
      this.motivationForm.controls['reason'].setValue('');
    }
    this.creating$ = this.banMotivationsService.creating$;
  }

  private resetModalData() {
    this.formSubmitted = false;
    this.editingMotivation = undefined;
    this.motivationForm.controls['reason'].enable();
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_BAN_MOTIVATION_MODAL, (reason: string | undefined) => {
      this.resetModalData();
      this.modalRef?.dismiss(reason);
    });
  }
}
