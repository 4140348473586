import {environment} from "@environment/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError, mergeMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ConfigInitService {

  private config: any;

  constructor(private httpClient: HttpClient) {
  }

  private static getConfigFile(): string {
    return environment.configFile
  }

  public getConfig(): Observable<any> {
    return this.httpClient
      .get(ConfigInitService.getConfigFile(), {
        observe: 'response',
      })
      .pipe(
        catchError(() => {
          return of(null)
        }),
        mergeMap((response) => {
          if (response && response.body) {
            this.config = response.body;
            return of(this.config);
          } else {
            return of(null);
          }
        })
      );
  }
}
