import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, ReplaySubject, Subscription, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "@service/common/event.service";
import {FaqsService} from "@service/faqs/faqs.service";
import {EventEnum} from "@enum/event/event.enum";
import {UtilsService} from "@service/utils/utils.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-faq-modal',
  templateUrl: './add-faq-modal.component.html',
  styleUrls: ['./add-faq-modal.component.scss']
})
export class AddFaqModalComponent implements OnInit, OnDestroy {
  @Input() faq: any;
  public Editor = ClassicEditor
  public creatingFaq$: Observable<boolean>;
  public formSubmitted: boolean;
  public faqForm: UntypedFormGroup;
  public maxPriority: number | undefined;
  @ViewChild('modal') private modalContent: TemplateRef<any>
  private modalRef: NgbModalRef;
  private closeModalSubscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private modalService: NgbModal, private eventService: EventService,
              private utils: UtilsService,
              public formBuilder: UntypedFormBuilder, private faqsService: FaqsService) {
  }

  get form() {
    return this.faqForm.controls;
  }

  ngOnInit(): void {
    this._closeModal();
    this.creatingFaq$ = this.faqsService?.creating$;
    this.initForm();
  }

  public open(data?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', centered: true, backdrop: false})
      this.modalRef.result.then(resolve, resolve);
      if (data?.id) {
        this.setFaq(data);
      }
    })
  }

  public validSubmit() {
    if (this.faqForm.valid) {
      let formData = this.faqForm.getRawValue();
      this.faqsService?.initCreateListener();
      if (this.faq?.id) {
        formData.id = this.faq?.id;
      }
      this.faqsService.createEditFaq(formData);
    }
  }

  public createFaq() {
    this.formSubmitted = true;
  }

  public setFaq(faq: any): void {
    this.faq = faq;
    this.patchFormWithEditingData();
  }

  ngOnDestroy() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initForm() {
    this.faqForm = this.formBuilder.group({
      active: [false],
      title: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      htmlText: [null, [Validators.required, Validators.minLength(3)]],
      priority: [null, [Validators.required]],
      linkText: [null],
      linkUrl: [null, [Validators.pattern(this.utils.validateUrlRegex)]],
    });
    this.takeMaxPriority();
  }


  private takeMaxPriority(): void {
    this.faqsService.getMaxPriority().pipe(takeUntil(this.destroyed$)).subscribe((result: any) => {
      // TODO remove when paginated
      const sorted = result.sort((a, b) => {
        if (a.priority > b.priority) return -1;
        if (a.priority < b.priority) return 1;
        return 0;
      })
      if (sorted?.length > 0 && sorted[0]?.priority) {
        this.maxPriority = sorted[0]?.priority;
        if (!this?.faq) {
          this.faqForm.patchValue({['priority']: this.maxPriority});
        }
      }
    });
  }


  private patchFormWithEditingData() {
    this.faqForm.patchValue({
      active: this.faq?.active,
      title: this.faq?.title,
      htmlText: this.faq?.htmlText,
      priority: this.faq?.priority,
      linkText: this.faq?.linkText,
      linkUrl: this.faq?.linkUrl
    });
    if (this.faq.duplicate) {
      this.faq = undefined;
    }
  }

  private _closeModal(): void {
    this.closeModalSubscription = this.eventService.subscribe(EventEnum.CLOSE_CREATE_FAQ, (reason: string | undefined) => {
      this.faqsService?.removeFaqCreateSubscribe();
      this.initForm();
      this.formSubmitted = false;
      this.faq = undefined;
      this.modalRef?.dismiss(reason);
    });
  }
}
